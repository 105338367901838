<template>
  <layout :title="$t('Groups')">
    <div class="mb-8">
      <breadcrumb-admin :path="breadcrumbs" />
    </div>
    <div class="mb-6 flex items-center justify-between">
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" :filter-show="false" @reset="reset" />

      <InertiaLink class="btn-blue" :href="route('organizations.groups.create', { organization: organization.id })">
        <span>{{ $t('Create') }}</span>
        <span class="hidden md:inline">{{ $t('Group') }}</span>
      </InertiaLink>
    </div>

    <div class="mt-4 rounded bg-white shadow">
      <SimpleTable>
        <template #head>
          <TableHead>{{ $t('Name') }}</TableHead>
          <TableHead>{{ $t('Managers') }}</TableHead>
          <TableHead>{{ $t('Members') }}</TableHead>
          <TableHead />
        </template>
        <TableRow
          v-for="(group, index) in groups.data"
          :key="group.id"
          :clickable="true"
          @click="$inertia.visit(route('groups.show', group.id))"
        >
          <TableData>
            {{ group.name }}
          </TableData>
          <TableData>{{ groupManagers[index] }}</TableData>
          <TableData>{{ group.users_count }}</TableData>
          <TableData class="w-min align-middle">
            <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
          </TableData>
        </TableRow>
        <TableRow v-if="groups.data.length === 0">
          <TableData class="border-t px-6 py-4" colspan="4">{{ $t('No groups found.') }}</TableData>
        </TableRow>
      </SimpleTable>
    </div>
    <pagination v-if="groups && groups.links" :links="groups.links" />
  </layout>
</template>

<script>
import BreadcrumbAdmin from '@/Shared/BreadcrumbAdmin.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
    BreadcrumbAdmin,
    Icon,
    Layout,
    Pagination,
    SearchFilter,
  },
  props: {
    filters: Object,
    organization: Object,
    groups: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
      },
      breadcrumbs: [
        {
          name: this.$t('Organizations'),
          url: this.route('organizations.index'),
        },
        {
          name: this.organization.name,
          url: this.route('organizations.edit', this.organization),
        },
        {
          name: this.$t('Groups'),
          url: this.route('organizations.groups', this.organization),
        },
      ],
    }
  },
  computed: {
    groupManagers: function () {
      return this.groups.data.map((group) => {
        if (!group.managers || group.managers.length === 0) {
          return '-'
        } else if (group.managers.length <= 2) {
          return group.managers.join(', ')
        } else {
          return group.managers.slice(0, 2).join(', ') + ` this.$t('and') ${group.managers.length - 2}  this.$t('more')`
        }
      })
    },
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(
          this.route('organizations.groups', this.organization.id),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
