<template>
  <modal class="rounded text-base" :show="show" style="z-index: 100000" width="50rem" @close="close">
    <div class="max-h-screen max-w-lg overflow-auto rounded bg-white p-6 shadow md:p-10">
      <h3 class="mb-8 mt-8 text-center text-3xl font-semibold text-black">{{ $t('Add New Card') }}</h3>

      <!-- PAYMENT METHOD -->
      <div>
        <div class="p-3">
          <label class="mb-2 block text-lg text-darkGray-700" for="name">{{ $t('Name on card') }}</label>
          <front-text-input v-model="form.name" if="name" placeholder="Name on Card" />
        </div>
        <div class="p-3">
          <label class="mb-2 block text-lg text-darkGray-700">{{ $t('Card information') }}</label>
          <div id="card-element" />
          <div v-if="stripe_error" class="form-error">{{ stripe_error }}</div>
        </div>
      </div>

      <div class="mb-2 mt-12 flex justify-center">
        <front-secondary-button
          class="mx-2 transform px-12 py-4"
          :disabled="form.processing"
          type="button"
          @click="close()"
        >
          {{ $t('Cancel') }}
        </front-secondary-button>
        <front-loading-button class="mx-2 transform px-12 py-4" :loading="form.processing" @click="submit()">
          {{ $t('Add card') }}
        </front-loading-button>
      </div>
    </div>
  </modal>
</template>

<script>
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontSecondaryButton from '@/Shared/FrontSecondaryButton.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import Modal from '@/Shared/Modal.vue'
import Http from '@/Utils/Http'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    FrontLoadingButton,
    FrontSecondaryButton,
    FrontTextInput,
    Modal,
  },
  props: {
    show: Boolean,
    organization: Object,
  },
  data() {
    return {
      intent: null,
      stripe_error: null,
      form: useForm({
        name: null,
      }),
    }
  },
  watch: {
    show: function (newVal) {
      if (newVal && !this.intent) {
        this.setIntent()
      }
    },
  },
  async mounted() {
    this.stripe = Stripe(import.meta.env.VITE_STRIPE_KEY)
    const elements = this.stripe.elements()
    this.cardElement = elements.create('card', {
      classes: {
        base: 'block w-full px-3 py-3 border border-gray-200 bg-ts-front-field-light text-ts-front-label rounded-lg transition duration-150 ease-in-out',
      },
      style: {
        base: {
          fontWeight: '300',
          fontSize: '17px',
          color: '#2d3748',
        },
      },
    })

    this.cardElement.mount('#card-element')
  },
  methods: {
    close() {
      this.$emit('close')
      this.form.reset()
      this.form.clearErrors()
      this.cardElement.clear()
      this.intent = null
    },
    setIntent() {
      Http.get(this.route('front.organization.stripe.intent', this.organization.id)).then(
        (response) => (this.intent = response.data)
      )
    },
    submitLastStep(setupIntent = null) {
      this.form
        .transform(() => ({
          paymentMethodId: setupIntent?.payment_method,
        }))
        .post(this.route('front.admin.payment-method.store'), {
          onSuccess: () => {
            this.close()
          },
        })
    },
    async submit() {
      this.stripe_error = null
      if (!this.intent) {
        this.stripe_error = 'Something went wrong, order cannot be processed.'
      }

      const { setupIntent, error } = await this.stripe.confirmCardSetup(this.intent.client_secret, {
        payment_method: {
          card: this.cardElement,
          billing_details: { name: this.form.name },
        },
      })

      if (error) {
        this.stripe_error = error.message
      } else {
        this.submitLastStep(setupIntent)
      }
    },
  },
}
</script>
