<template>
  <icon
    class="inline h-4 w-4 transform stroke-current"
    :class="{
      'text-gray-600': isActive,
      'text-gray-400': !isActive,
      'rotate-180': isActive && isAscending,
    }"
    name="arrow-long-up"
    stroke-width="2"
  />
</template>
<script>
import Icon from './Icon.vue'

export default {
  components: {
    Icon,
  },
  props: {
    isActive: Boolean,
    isAscending: Boolean,
  },
}
</script>
