<template>
  <component :is="componentType" :common="common" :form-content="formContent">
    <slot />
  </component>
</template>
<script>
import FormPage from './FormPage.vue'
import FormQuestion from './FormQuestion.vue'
import FormSection from './FormSection.vue'

export default {
  components: {
    FormPage,
    FormSection,
    FormQuestion,
  },
  props: {
    formContent: Object,
    depth: Number,
    itemCount: Number,
    common: Object,
  },
  data() {
    return {
      enabled: true,
      dragging: false,
    }
  },
  computed: {
    componentType() {
      switch (this.formContent.type) {
        case 'form-page':
          return 'FormPage'
        case 'form-section':
          return 'FormSection'
        default:
          return 'FormQuestion'
      }
    },
  },
}
</script>
