<template>
  <div class="w-full overflow-hidden rounded-lg border border-ts-gray-500 bg-white">
    <navigation :show-transfer-button="canShowTransferButton" />
    <div class="flex flex-wrap p-6 lg:p-10">
      <slot />
    </div>
  </div>
</template>

<script>
import Navigation from './Navigation.vue'

export default {
  components: { Navigation },
  props: {
    purchases: Object,
    canShowTransferButton: Boolean,
  },
}
</script>
