<template>
  <layout :title="$t('Merge User')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('users')">{{ $t('Users') }}</InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ mergeFromUser.display_name }}
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Merge') }}
    </h1>
    <div class="max-w-sm overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <dynamic-search-input
              v-model="toUser"
              class="w-full"
              :error="form.errors.user"
              :label="$t('Merge Into User')"
              :search-by="['name', 'email']"
              track-by="id"
              :url="
                route('users.search', {
                  organization: mergeFromUser.organization ? mergeFromUser.organization.id : null,
                })
              "
            >
              <div v-if="mergeToUser" class="flex items-center justify-between">
                <div class="truncate">{{ mergeToUser.name }}</div>
                <div class="whitespace-nowrap text-xs text-gray-600">
                  {{ mergeToUser.email }}
                </div>
              </div>
              <template #option="{ option, selected }">
                <div class="flex items-center justify-between">
                  <div>{{ option.name }}</div>
                  <div class="text-xs" :class="{ 'text-gray-600': !selected, 'text-white': selected }">
                    {{ option.email }}
                  </div>
                </div>
              </template>
            </dynamic-search-input>
          </div>
          <div class="w-full pb-8 pr-6">
            <select-input
              v-model="form.email_source_user"
              :disabled="!mergeToUser || Object.keys(emails).length === 0"
              :error="form.errors.email_source_user"
              :label="$t('Confirm Correct Email')"
            >
              <option :value="null" />
              <option v-for="(email, id) in emails" :key="id" :value="id">
                {{ email }}
              </option>
            </select-input>
          </div>
          <div class="w-full pb-8 pr-6">
            <select-input
              v-model="form.username_source_user"
              :disabled="!mergeToUser || Object.keys(usernames).length === 0"
              :error="form.errors.username_source_user"
              :label="$t('Confirm Correct Username')"
            >
              <option :value="null" />
              <option v-for="(username, id) in usernames" :key="id" :value="id">
                {{ username }}
              </option>
            </select-input>
          </div>
          <div class="w-full pb-8 pr-6">
            <select-input
              v-model="form.address_source_user"
              :disabled="!mergeToUser || Object.keys(addresses).length === 0"
              :error="form.errors.address_source_user"
              :label="$t('Confirm Correct Address')"
            >
              <option :value="null" />
              <option v-for="(address, id) in addresses" :key="id" :value="id">
                {{ address }}
              </option>
            </select-input>
          </div>
          <div class="w-full pb-8 pr-6">
            <select-input
              v-model="form.password_source_user"
              :disabled="!mergeToUser || Object.keys(passwords).length === 0"
              :error="form.errors.password_source_user"
              :label="$t('Confirm Correct Password')"
            >
              <option :value="null" />
              <option v-for="(password, id) in passwords" :key="id" :value="id">
                {{ password }}
              </option>
            </select-input>
          </div>
          <div class="w-full pb-8 pr-6">
            <select-input
              v-model="form.organization_source_user"
              :disabled="!mergeToUser || Object.keys(organizations).length === 0"
              :error="form.errors.organization_source_user"
              :label="$t('Confirm Correct Organization')"
            >
              <option :value="null" />
              <option v-for="(organization, id) in organizations" :key="id" :value="id">
                {{ organization }}
              </option>
            </select-input>
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :disabled="!mergeToUser" :loading="form.processing" type="submit">
            {{ $t('Merge') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import DynamicSearchInput from '@/Shared/DynamicSearchInput.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    DynamicSearchInput,
    Layout,
    LoadingButton,
    SelectInput,
  },
  props: {
    mergeFromUser: Object,
    mergeToUser: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      toUser: this.mergeToUser,
      emails: {},
      usernames: {},
      passwords: {},
      addresses: {},
      organizations: {},
      form: useForm(
        {
          email_source_user: null,
          username_source_user: null,
          address_source_user: null,
          password_source_user: null,
          organization_source_user: null,
        },
        'UsersMerge'
      ),
    }
  },
  watch: {
    toUser: function (newUser) {
      if (newUser?.id === this.mergeToUser?.id) return

      if (newUser) {
        return this.$inertia.get(this.route('users.merge', this.mergeFromUser), { to: newUser.id })
      }

      return this.$inertia.get(this.route('users.merge', this.mergeFromUser))
    },
  },
  mounted() {
    if (!this.mergeToUser) {
      this.form.reset()
      this.emails = this.usernames = this.addresses = this.passwords = this.organizations = {}

      return
    }

    this.emails = this.formatEmptyOptions({
      [this.mergeFromUser.id]: this.mergeFromUser.email,
      [this.mergeToUser.id]: this.mergeToUser.email,
    })

    this.usernames = this.formatEmptyOptions({
      [this.mergeFromUser.id]: this.mergeFromUser.username,
      [this.mergeToUser.id]: this.mergeToUser.username,
    })

    this.addresses = this.formatEmptyOptions({
      [this.mergeFromUser.id]: this.mergeFromUser.address
        ? `${this.mergeFromUser.address}, ${this.mergeFromUser.city}, ${this.mergeFromUser.region}, ${this.mergeFromUser.postal_code}`
        : null,
      [this.mergeToUser.id]: this.mergeToUser.address
        ? `${this.mergeToUser.address}, ${this.mergeToUser.city}, ${this.mergeToUser.region}, ${this.mergeToUser.postal_code}`
        : null,
    })

    this.passwords = {
      [this.mergeFromUser.id]: this.$t(":display_name's password", {
        display_name: `${this.mergeFromUser.display_name}`,
      }),
      [this.mergeToUser.id]: this.$t(":display_name's password", { display_name: `${this.mergeToUser.display_name}` }),
    }

    this.organizations = this.formatEmptyOptions({
      [this.mergeFromUser.id ?? null]: this.mergeFromUser.organization?.name,
      [this.mergeToUser.id ?? null]: this.mergeToUser.organization?.name,
    })
  },
  methods: {
    submit() {
      if (
        this.mergeToUser &&
        confirm(this.$t('Please confirm that you would like to complete this user merge. This action is irreversible.'))
      ) {
        this.form.post(
          this.route('users.merge.store', { mergeFromUser: this.mergeFromUser.id, mergeToUser: this.mergeToUser.id })
        )
      }
    },
    formatEmptyOptions(options) {
      for (var key in options) {
        if (options[key] === null || options[key] === undefined) {
          options[key] = 'None'
        }
      }

      if (Object.values(options).every((option) => option === 'None')) {
        return {}
      }

      return options
    },
  },
}
</script>
