<template>
  <content-layout :enrolment-content="enrolmentContent" :is-last="isLast">
    <div>
      <p>{{ enrolmentContent.content.description }}</p>
    </div>

    <template #actions>
      <front-outline-button-link
        v-if="!enrolmentContent.finished_at"
        class="inline-block w-full px-12 text-center"
        :class="{ disabled: !enrolmentContent.content.route }"
        :href="enrolmentContent.content.route ? enrolmentContent.content.route : '#'"
      >
        {{ $t('Start') }}
      </front-outline-button-link>
    </template>
  </content-layout>
</template>
<script>
import ContentLayout from './ContentLayout.vue'
import FrontOutlineButtonLink from '@/Shared/FrontOutlineButtonLink.vue'
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  components: { ContentLayout, FrontOutlineButtonLink },
  props: {
    enrolment: Object,
    enrolmentContent: Object,
    isLast: Boolean,
  },
  computed: {
    isTenantWorksite,
  },
}
</script>
