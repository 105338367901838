<template>
  <div class="ts-bg flex min-h-screen items-center justify-center p-6">
    <DynamicHead :title="$t('Log In | :app_name', { app_name: $page.props.app.name })" />
    <div class="w-full max-w-sm">
      <logo class="mx-auto block w-full max-w-xs fill-white" height="75.65" name="qualify" width="288" />
      <form class="mt-8 overflow-hidden rounded-lg bg-white shadow-lg" @submit.prevent="submit">
        <div class="px-10 py-12">
          <h1 class="text-center text-2xl font-bold">{{ $t('Welcome Back') }}!</h1>
          <div class="mx-auto mt-6 w-24 border-b-2" />
          <div class="mt-10">
            <text-input
              v-model="form.email"
              autocapitalize="off"
              autofocus
              :error="form.errors.email"
              :label="$t('Email')"
              type="email"
            />
          </div>
          <div class="mt-6">
            <label class="form-label" for="password">{{ $t('Password') }}</label>
            <div class="relative">
              <input
                id="password"
                v-model="form.password"
                class="form-input"
                required
                :type="showPassword ? 'text' : 'password'"
              />
              <div class="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5">
                <icon
                  v-if="!showPassword"
                  class="h-5 w-5 cursor-pointer"
                  name="password-eye"
                  @click="showPassword = !showPassword"
                />
                <icon
                  v-else
                  class="h-5 w-5 cursor-pointer"
                  name="password-eye-slash"
                  @click="showPassword = !showPassword"
                />
              </div>
            </div>
            <div v-if="form.errors.password" class="form-error">{{ form.errors.password }}</div>
          </div>
          <label class="mt-6 flex select-none" for="remember">
            <input id="remember" v-model="form.remember" class="form-checkbox mr-2" type="checkbox" />
            <span class="text-sm">{{ $t('Remember Me') }}</span>
          </label>
        </div>
        <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-10 py-4">
          <InertiaLink class="text-gray-700 hover:underline" :href="route('password.request')">
            {{ $t('Reset Password') }}
          </InertiaLink>
          <loading-button class="btn-blue" :loading="form.processing" type="submit">{{ $t('Log In') }}</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import DynamicHead from '@/Shared/DynamicHead.vue'
import Icon from '@/Shared/Icon.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import Logo from '@/Shared/Logo.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    DynamicHead,
    Icon,
    LoadingButton,
    Logo,
    TextInput,
  },
  data() {
    return {
      form: useForm({
        email: null,
        password: null,
        remember: null,
      }),
      showPassword: false,
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('login.attempt'))
    },
  },
}
</script>
