<template>
  <ColorPicker
    :color="color"
    :colors-default="[
      '#c60022',
      '#9c6d1e',
      '#fe8500',
      '#ffb000',
      '#81b532',
      '#13855f',
      '#00b6cb',
      '#648fff',
      '#0044a3',
      '#c22dd5',
      '#dc267f',
      '#707070',
    ]"
    theme="light"
    @change-color="changeColor"
  />
</template>

<script>
import { ColorPicker } from 'vue-color-kit'
import 'vue-color-kit/dist/vue-color-kit.css'

export default {
  components: {
    ColorPicker,
  },
  props: {
    modelValue: String,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      color: this.modelValue,
      suckerCanvas: null,
      suckerArea: [],
      isSucking: false,
    }
  },
  methods: {
    changeColor(color) {
      this.color = color.hex
      this.$emit('update:modelValue', this.color)
    },
  },
}
</script>

<style scoped>
.hu-color-picker {
  width: 100% !important;
  background: #fff !important;
  padding: 0 !important;
  box-shadow: none !important;
}
</style>
