<template>
  <front-layout v-if="organization.access_codes_enabled" :title="$t('Access Codes')">
    <div class="flex flex-col xl:flex-row xl:justify-between">
      <div class="sm:no-flex mb-8 flex w-full items-center justify-between">
        <breadcrumb :name="$t('Access Codes')" />
        <front-button-link class="sm:hidden" :href="route('front.admin.access-codes.create')">
          <span>{{ $t('+ Add') }}</span>
        </front-button-link>
      </div>
      <div class="mb-6 flex w-full items-center justify-end">
        <front-search-filter
          v-model="form.search"
          class="flex w-full flex-col sm:mr-4 sm:flex-row lg:max-w-sm"
          :filter-show="false"
          @reset="reset"
        />
        <front-button-link class="hidden sm:inline" :href="route('front.admin.access-codes.create')">
          <span>{{ $t('+ Add') }}</span>
        </front-button-link>
      </div>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow-lg">
      <table class="front-table">
        <thead class="bg-ts-front-table-header">
          <tr>
            <th>{{ $t('Code') }}</th>
            <th>{{ $t('Courses Enrolled') }}</th>
            <th>{{ $t('Group Assigned') }}</th>
            <th>{{ $t('Uses Remaining') }}</th>
            <th />
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
          <tr v-if="!codes.data.length">
            <td class="border-t px-6 py-4" colspan="6">{{ $t('No access codes found.') }}</td>
          </tr>
          <tr
            v-for="code in codes.data"
            :key="code.id"
            @click="$inertia.visit(route('front.admin.access-codes.edit', code.id))"
          >
            <td class="flex items-center font-semibold text-ts-gray-text-bold">
              {{ code.code }}
              <icon v-if="code.archived_at" class="ml-2 h-4 w-4 flex-shrink-0 fill-gray-500" name="archive" />
            </td>
            <td>{{ code.courses.length ? formatCourseList(code.courses) : '-' }}</td>
            <td>{{ code.group.name || '-' }}</td>
            <td>{{ code.remaining_uses || '-' }}</td>
            <td class="whitespace-nowrap px-6 py-4 text-sm font-medium leading-5 text-gray-900 hover:underline">
              <InertiaLink
                class="font-semibold hover:underline"
                :href="route('front.admin.access-codes.edit', code.id)"
              >
                Edit
              </InertiaLink>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <front-pagination :links="codes.links" />
  </front-layout>
  <front-layout v-else title="Access Codes">
    <div class="w-full rounded bg-white px-6 py-12 text-center shadow">
      <svg class="mx-auto" height="201" viewBox="0 0 201 201" width="201" xmlns="http://www.w3.org/2000/svg">
        <g id="Group_6696" data-name="Group 6696" transform="translate(11284 4951.302)">
          <circle
            id="Ellipse_298"
            cx="100.5"
            cy="100.5"
            data-name="Ellipse 298"
            fill="#eaebeb"
            r="100.5"
            transform="translate(-11284 -4951.302)"
          />
          <g id="vpn_key-24px_1_" data-name="vpn_key-24px (1)" transform="translate(-11248 -4914.336)">
            <path id="Path_5622" d="M0,0H127.2V127.2H0Z" data-name="Path 5622" fill="none" transform="translate(0 0)" />
            <path
              id="Path_5623"
              d="M116.6,78.3H84.8V57.359H70.6A37.108,37.108,0,0,1,37.1,78.3C16.642,78.3,0,61.861,0,41.651S16.642,5,37.1,5A37.166,37.166,0,0,1,70.6,25.943h56.6V57.359H116.6ZM95.4,67.83H106V46.887h10.6V36.415H63.283l-1.219-3.508A26.538,26.538,0,0,0,10.6,41.651a26.538,26.538,0,0,0,51.464,8.744l1.219-3.508H95.4ZM37.1,57.359A15.709,15.709,0,1,1,53,41.651,15.851,15.851,0,0,1,37.1,57.359Zm0-20.943a5.236,5.236,0,1,0,5.3,5.236A5.284,5.284,0,0,0,37.1,36.415Z"
              data-name="Path 5623"
              fill="#fff"
              transform="translate(0 21.95)"
            />
          </g>
        </g>
      </svg>
      <p class="mt-8 text-xl font-semibold leading-relaxed">
        {{ $t('Access Codes are disabled for this organization.') }}
      </p>
      <p class="mt-3 text-lg leading-relaxed">
        <a
          class="leading-relaxed text-qualify-red-500 hover:underline"
          :href="route('front.admin.settings', { selected: 'options' })"
        >
          {{ $t('Click here') }}
        </a>
        {{ $t('to manage organization options.') }}
      </p>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontButtonLink from '@/Shared/FrontButtonLink.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontPagination from '@/Shared/FrontPagination.vue'
import FrontSearchFilter from '@/Shared/FrontSearchFilter.vue'
import Icon from '@/Shared/Icon.vue'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    Breadcrumb,
    FrontButtonLink,
    FrontLayout,
    FrontPagination,
    FrontSearchFilter,
    Icon,
  },
  props: {
    filters: Object,
    organization: Object,
    codes: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
      },
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(
          this.route('front.admin.access-codes'),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
    formatCourseList(courses) {
      if (courses.length === 1) return courses[0]
      return `${courses[0]} (${courses.length - 1} ${courses.length - 1 > 1 ? 'others' : 'other'})`
    },
  },
}
</script>
