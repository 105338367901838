<template>
  <nav class="-mb-px flex border-b border-gray-200 bg-white px-10 pt-4">
    <InertiaLink
      v-for="(tab, i) in tabs"
      :key="i"
      class="hidden whitespace-nowrap border-b-4 px-1 py-4 font-bold leading-5 focus:outline-none lg:inline"
      :class="(href === tab.href ? $options.activeClasses : $options.inactiveClasses) + (i > 0 ? ' ml-8' : '')"
      :href="tab.href"
    >
      {{ tab.text }}
    </InertiaLink>
  </nav>
</template>

<script>
export default {
  activeClasses: 'border-qualify-red-500 text-gray-800 focus:text-gray-800 focus:border-gray-700 font-bold',
  inactiveClasses:
    'text-ts-gray-text-bold border-transparent hover:text-gray-700 hover:border-gray-200 focus:text-gray-700 focus:border-gray-200',
  data() {
    return {
      tabs: [
        // { text: 'Performance', href: '#' },
        { text: this.$t('Courses'), href: this.route('front.manager.reports.courses.show') },
        { text: this.$t('Certificates'), href: this.route('front.manager.reports.certificates.show') },
        { text: this.$t('Access Code'), href: this.route('front.manager.reports.access-codes.show') },
      ],
    }
  },
  computed: {
    href() {
      return window.location.origin + window.location.pathname
    },
  },
}
</script>
