<template>
  <layout :title="form.name">
    <div class="mb-8">
      <breadcrumb :name="form.name" :previous-name="$t('Tenants')" :previous-url="route('tenants')" />
    </div>
    <deleted-message v-if="tenant.deleted_at" class="mb-6">{{ $t('This tenant has been deleted.') }}</deleted-message>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 p-8">
          <div class="pb-8">
            <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" />
          </div>
          <div class="mb-8">
            <label class="form-label">{{ $t('API key:') }}</label>
            <input class="form-input" disabled :value="tenant.api_key" />
          </div>
          <div class="flex">
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input v-model="form.email" :error="form.errors.email" :label="$t('Email')" />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input v-model="form.phone" :error="form.errors.phone" :label="$t('Phone')" />
            </div>
          </div>
          <div class="flex">
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <radio-input
                v-model="form.students_upcoming_classes_notification_enabled"
                :error="form.errors.students_upcoming_classes_notification_enabled"
                :label="$t('Notify Students of Upcoming Class')"
              />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input
                v-model="form.plastic_card_price"
                :error="form.errors.plastic_card_price"
                :label="$t('Plastic Card Price')"
                min="0"
                step=".01"
                type="number"
              />
            </div>
          </div>
          <div class="pb-8">
            <textarea-input
              v-model="form.user_welcome_message_en"
              class="pb-1"
              :error="form.errors.user_welcome_message_en"
              :label="$t('User Welcome Message (EN)')"
              rows="3"
            />
            <small>{{ $t('This gets displayed to students in the student UI.') }}</small>
          </div>
          <div class="pb-8">
            <textarea-input
              v-model="form.user_welcome_message_fr"
              class="pb-1"
              :error="form.errors.user_welcome_message_fr"
              :label="$t('User Welcome Message (FR)')"
              rows="3"
            />
            <small>{{ $t('This gets displayed to students in the student UI.') }}</small>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full pb-8 md:w-1/2 md:pr-2">
              <select-input v-model="form.locale" :error="form.errors.locale" :label="$t('Locale/Language')">
                <option :value="null" />
                <option v-for="(locale, key) in locales" :key="key" :value="locale">
                  {{ key }}
                </option>
              </select-input>
            </div>
            <div class="w-full pb-8 md:w-1/2 md:pl-2">
              <label class="mb-2 block">{{ $t('Available Languages:') }}</label>
              <div class="flex">
                <div class="mr-4">
                  <checkbox-input v-model="form.locale_en" :error="form.errors.locale_en" :label="$t('English')" />
                </div>
                <div class="mr-4">
                  <checkbox-input v-model="form.locale_fr" :error="form.errors.locale_fr" :label="$t('French')" />
                </div>
              </div>
            </div>
          </div>
          <div class="pb-8">
            <label for="logo">{{ $t('Logo') }}</label>
            <file-input
              id="logo"
              accept="image/*"
              class="mt-6"
              :error="form.errors.logo"
              inner-classes="justify-start"
              :url="tenant.logo_url"
              @input="form.logo = $event"
            />
            <a
              v-if="tenant.logo_url"
              class="mt-6 flex cursor-pointer items-center hover:fill-gray-600 hover:text-gray-600"
              @click="deleteLogo"
            >
              <icon class="mr-2 inline h-4 w-4 rounded-full" name="close" />
              Remove logo
            </a>
          </div>
        </div>
        <form-section-header>{{ $t('Business Information') }}</form-section-header>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.tax_number" :error="form.errors.tax_number" :label="$t('Tax Number')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.business_name" :error="form.errors.business_name" :label="$t('Business Name')" />
          </div>
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.address" :error="form.errors.address" :label="$t('Address')" />
          </div>
        </div>
        <form-section-header>Woocommerce Integration</form-section-header>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.wc_url" :error="form.errors.wc_url" :label="$t('URL')" />
          </div>
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.wc_shop_url" :error="form.errors.wc_shop_url" :label="$t('Shop URL')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input
              v-model="form.wc_consumer_key"
              :error="form.errors.wc_consumer_key"
              :label="$t('Consumer Key')"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input
              v-model="form.wc_consumer_secret"
              :error="form.errors.wc_consumer_secret"
              :label="$t('Consumer Secret')"
            />
          </div>
        </div>
        <form-section-header>{{ $t('Domain Configuration') }}</form-section-header>
        <div class="-mb-8 p-8">
          <div class="pb-8">
            <div class="flex items-center justify-between">
              <label class="form-label">{{ $t('Domain:') }}</label>
            </div>
            <text-input v-model="form.domain" :error="form.errors.domain" />
          </div>
        </div>
        <form-section-header>{{ $t('SCORM Cloud Integration') }}</form-section-header>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input
              v-model="form.scorm_cloud_app_id"
              :error="form.errors.scorm_cloud_app_id"
              :label="$t('App Id')"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input
              v-model="form.scorm_cloud_secret_key"
              :error="form.errors.scorm_cloud_secret_key"
              :label="$t('Secret Key')"
            />
          </div>
        </div>
        <form-section-header>{{ $t('Reviews.io Integration') }}</form-section-header>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input
              v-model="form.reviews_io_api_token"
              :error="form.errors.reviews_io_api_token"
              :label="$t('API Token')"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input
              v-model="form.reviews_io_store_id"
              :error="form.errors.reviews_io_store_id"
              :label="$t('Store ID')"
            />
          </div>
        </div>
        <form-section-header>{{ $t('Zendesk Integration') }}</form-section-header>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input
              v-model="form.zendesk_snippet_key"
              :error="form.errors.zendesk_snippet_key"
              :label="$t('Snippet Key')"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.zendesk_key_id" :error="form.errors.zendesk_key_id" :label="$t('Key ID')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input
              v-model="form.zendesk_shared_secret"
              :error="form.errors.zendesk_shared_secret"
              :label="$t('Secret')"
            />
          </div>
        </div>
        <form-section-header>{{ $t('Mailchimp Integration') }}</form-section-header>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.mailchimp_key" :error="form.errors.mailchimp_key" :label="$t('API Key')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input
              v-model="form.mailchimp_list_id"
              :error="form.errors.mailchimp_list_id"
              :label="$t('List Id')"
            />
          </div>
        </div>
        <form-section-header>{{ $t('Bamboo Integration') }}</form-section-header>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input
              v-model="form.bamboo_sub_domain"
              :error="form.errors.bamboo_sub_domain"
              :label="$t('Subdomain')"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.bamboo_api_key" :error="form.errors.bamboo_api_key" :label="$t('Api Key')" />
          </div>
        </div>
        <form-section-header>{{ $t('Canada Post AddressComplete Integration') }}</form-section-header>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input
              v-model="form.address_complete_api_key"
              :error="form.errors.address_complete_api_key"
              :label="$t('API Key')"
            />
          </div>
        </div>
        <form-section-header>{{ $t('GoogleMaps Integration') }}</form-section-header>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input
              v-model="form.google_maps_api_key"
              :error="form.errors.google_maps_api_key"
              :label="$t('API Key')"
            />
          </div>
        </div>
        <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
          <div class="flex items-baseline">
            <button
              v-if="tenant.deleted_at"
              class="text-red-500 hover:underline"
              tabindex="-1"
              type="button"
              @click="restore"
            >
              {{ $t('Restore Tenant') }}
            </button>
            <button v-else class="text-red-500 hover:underline" tabindex="-1" type="button" @click="destroy">
              {{ $t('Delete Tenant') }}
            </button>
          </div>
          <div class="flex items-end">
            <loading-button class="btn-blue ml-3" :loading="form.processing" type="submit">
              {{ $t('Update Tenant') }}
            </loading-button>
          </div>
        </div>
      </form>
    </div>
    <div v-if="tenant.isWoocommerceApiEnabled" class="mt-8 overflow-hidden rounded bg-white shadow">
      <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Woocommerce Webhooks') }}</div>
      <div class="flex flex-wrap">
        <div v-if="tenant.registeredWoocommerceWebhooks.length === 0" class="w-full py-8 text-center">
          <p>{{ $t("This tenant doesn't have any webhooks setup. Click the button to generate them.") }}</p>
          <InertiaLink
            as="button"
            class="btn-blue my-1"
            :href="route('tenants.woocommerce-webhooks.generate', tenant.id)"
            method="post"
            type="button"
          >
            {{ $t('Generate') }}
          </InertiaLink>
        </div>
        <div v-else class="w-full">
          <table class="w-full">
            <thead>
              <tr>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Name') }}</th>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Webhook ID') }}</th>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Topic') }}</th>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Status') }}</th>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Updated At') }}</th>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Created At') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="registeredWoocommerceWebhooks in tenant.registeredWoocommerceWebhooks"
                :key="registeredWoocommerceWebhooks.id"
                class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
              >
                <td class="whitespace-nowrap border-t px-6 py-4">
                  {{ registeredWoocommerceWebhooks.name }}
                </td>
                <td class="whitespace-nowrap border-t px-6 py-4">
                  {{ registeredWoocommerceWebhooks.webhook_id }}
                </td>
                <td class="border-t px-6 py-4">{{ registeredWoocommerceWebhooks.topic }}</td>
                <td class="border-t px-6 py-4 capitalize">{{ registeredWoocommerceWebhooks.status }}</td>
                <td class="border-t px-6 py-4">
                  <show-date :timestamp="registeredWoocommerceWebhooks.updated_at" />
                </td>
                <td class="border-t px-6 py-4">
                  <show-date :timestamp="registeredWoocommerceWebhooks.created_at" />
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex w-full items-end justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
            <InertiaLink
              as="button"
              class="btn-blue mr-3"
              :href="route('tenants.woocommerce-webhooks.reset', tenant)"
              method="post"
              :title="$t('Resets the webhooks according the Qualify configuration')"
            >
              {{ $t('Reset') }}
            </InertiaLink>
            <InertiaLink
              as="button"
              class="btn-blue"
              :href="route('tenants.woocommerce-webhooks.sync', tenant)"
              method="post"
              :title="$t('Updates the webhook data from Woocommerce')"
            >
              {{ $t('Sync') }}
            </InertiaLink>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import CheckboxInput from '@/Shared/CheckboxInput.vue'
import DeletedMessage from '@/Shared/DeletedMessage.vue'
import FileInput from '@/Shared/FileInput.vue'
import FormSectionHeader from '@/Shared/FormSectionHeader.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import RadioInput from '@/Shared/RadioInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import ShowDate from '@/Shared/ShowDate.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    CheckboxInput,
    DeletedMessage,
    FileInput,
    FormSectionHeader,
    Icon,
    Layout,
    LoadingButton,
    RadioInput,
    SelectInput,
    ShowDate,
    TextInput,
    TextareaInput,
  },
  props: {
    tenant: Object,
    locales: Object,
  },
  data() {
    return {
      form: useForm(
        {
          logo: null,
          name: this.tenant.name,
          phone: this.tenant.phone,
          email: this.tenant.email,
          domain: this.tenant.domain,
          locale: this.tenant.locale,
          locale_en: this.tenant.locale_en,
          locale_fr: this.tenant.locale_fr,
          user_welcome_message_en: this.tenant.user_welcome_message_en,
          user_welcome_message_fr: this.tenant.user_welcome_message_fr,
          plastic_card_price: this.tenant.plastic_card_price,
          scorm_cloud_app_id: this.tenant.scorm_cloud_app_id,
          scorm_cloud_secret_key: this.tenant.scorm_cloud_secret_key,
          reviews_io_api_token: this.tenant.reviews_io_api_token,
          reviews_io_store_id: this.tenant.reviews_io_store_id,
          wc_url: this.tenant.wc_url,
          wc_shop_url: this.tenant.wc_shop_url,
          wc_consumer_key: this.tenant.wc_consumer_key,
          wc_consumer_secret: this.tenant.wc_consumer_secret,
          tax_number: this.tenant.tax_number,
          business_name: this.tenant.business_name,
          address: this.tenant.address,
          students_upcoming_classes_notification_enabled: this.tenant.students_upcoming_classes_notification_enabled,
          zendesk_key_id: this.tenant.zendesk_key_id,
          zendesk_shared_secret: this.tenant.zendesk_shared_secret,
          zendesk_snippet_key: this.tenant.zendesk_snippet_key,
          address_complete_api_key: this.tenant.address_complete_api_key,
          mailchimp_key: this.tenant.mailchimp_key,
          mailchimp_list_id: this.tenant.mailchimp_list_id,
          google_maps_api_key: this.tenant.google_maps_api_key,
          bamboo_sub_domain: this.tenant.bamboo_sub_domain,
          bamboo_api_key: this.tenant.bamboo_api_key,
        },
        'TenantsEdit'
      ),
    }
  },
  methods: {
    submit() {
      this.form
        .transform((data) => ({
          ...data,
          _method: 'put',
        }))
        .post(this.route('tenants.update', this.tenant.id))
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this tenant?'))) {
        this.$inertia.delete(this.route('tenants.destroy', this.tenant.id))
      }
    },
    restore() {
      if (confirm(this.$t('Are you sure you want to restore this tenant?'))) {
        this.$inertia.put(this.route('tenants.restore', this.tenant.id))
      }
    },
    deleteLogo() {
      if (this.tenant.logo_url && confirm(this.$t("Are you sure you want to delete this tenant's logo?"))) {
        this.$inertia.delete(this.route('tenants.logo.delete', this.tenant.id), {})
      }
    },
  },
}
</script>
