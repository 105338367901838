<template>
  <layout :title="$t('Certificate Templates')">
    <div class="mb-8">
      <breadcrumb :name="$t('Certificate Templates')" />
    </div>
    <div class="mb-6 flex items-center justify-between">
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
        <label class="block text-gray-800">{{ $t('Type:') }}</label>
        <select v-model="form.type" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="(label, type) in types" :key="type" :value="label">{{ label }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Plastic Card Ordering:') }}</label>
        <select v-model="form.plastic_card_ordering" class="form-select mt-1 w-full">
          <option :value="null" />
          <option :value="true">{{ $t('Enabled') }}</option>
          <option :value="false">{{ $t('Disabled') }}</option>
        </select>
      </search-filter>
      <InertiaLink class="btn-blue" :href="route('certificate-templates.create')">
        <span>{{ $t('Create') }}</span>
        <span class="hidden md:inline">{{ $t('Certificate Template') }}</span>
      </InertiaLink>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow">
      <SimpleTable>
        <template #head>
          <TableHead>{{ $t('Name') }}</TableHead>
          <TableHead>{{ $t('Title (EN)') }}</TableHead>
          <TableHead>{{ $t('Title (FR)') }}</TableHead>
          <TableHead class="text-right">{{ $t('Formats') }}</TableHead>
          <TableHead class="text-right">{{ $t('Prepared Formats') }}</TableHead>
          <TableHead class="text-right">{{ $t('Courses') }}</TableHead>
          <TableHead class="text-right">{{ $t('Years Valid') }}</TableHead>
          <TableHead />
        </template>
        <TableRow v-if="certificate_templates.data.length === 0">
          <TableData colspan="8">{{ $t('No certificate templates found.') }}</TableData>
        </TableRow>
        <TableRow
          v-for="(certificateTemplate, key) in certificate_templates.data"
          :key="key"
          :clickable="true"
          @click="$inertia.visit(route('certificate-templates.edit', certificateTemplate))"
        >
          <TableData>
            <InertiaLink :href="route('certificate-templates.edit', certificateTemplate)">
              {{ certificateTemplate.name }}
            </InertiaLink>
          </TableData>
          <TableData>
            <InertiaLink :href="route('certificate-templates.edit', certificateTemplate)">
              {{ certificateTemplate.title_en }}
            </InertiaLink>
          </TableData>
          <TableData>
            <InertiaLink :href="route('certificate-templates.edit', certificateTemplate)">
              {{ certificateTemplate.title_fr || '-' }}
            </InertiaLink>
          </TableData>
          <TableData class="text-right">
            {{ certificateTemplate.formats_count }}
          </TableData>
          <TableData class="text-right">
            {{ certificateTemplate.prepared_formats_count }}
          </TableData>
          <TableData class="text-right">
            {{ certificateTemplate.courses_count }}
          </TableData>
          <TableData class="text-right">
            {{ certificateTemplate.years_valid }}
          </TableData>
          <TableData class="w-min align-middle">
            <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
          </TableData>
        </TableRow>
      </SimpleTable>
    </div>
    <pagination :links="certificate_templates.links" />
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import _debounce from 'lodash/debounce'
import _isNil from 'lodash/isNil'
import _mapValues from 'lodash/mapValues'
import _omitBy from 'lodash/omitBy'

export default {
  components: {
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
    Breadcrumb,
    Icon,
    Layout,
    Pagination,
    SearchFilter,
  },
  props: {
    types: Object,
    certificate_templates: Object,
    filters: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        type: this.filters.type,
        plastic_card_ordering: this.filters.plastic_card_ordering,
      },
    }
  },
  watch: {
    form: {
      handler: _debounce(function () {
        let query = _omitBy(this.form, _isNil)
        this.$inertia.get(
          this.route('certificate-templates'),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
