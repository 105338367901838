<template>
  <div class="ts-bg flex min-h-screen items-start justify-center p-6">
    <DynamicHead :title="pageTitle" />
    <div class="w-full max-w-xl">
      <div class="mt-12 overflow-hidden rounded-lg bg-white shadow-lg">
        <div class="px-10 pb-6 pt-12">
          <h1 class="text-center text-xl font-bold">{{ $t('Check-in') }}</h1>
          <div class="mt-4 text-center text-xl text-gray-600">{{ student.class.instructor_led_classroom.name }}</div>
          <div class="mt-5 text-center text-sm">
            <span v-if="showOrganizationName(student)">
              {{
                $t('Seat purchased by :customer_name at :organization_name', {
                  customer_name: student.customer.name,
                  organization_name: student.customer.organization.name,
                })
              }}
            </span>
            <span v-else>{{ $t('Seat purchased by :customer_name', { customer_name: student.customer.name }) }}</span>
            <loading-button
              v-if="isStudentUpdateRequest"
              class="btn-blue mx-auto mt-2"
              @click.prevent="selfAssign(student)"
            >
              {{ $t('I am :name', { name: student.customer.name }) }}
            </loading-button>
          </div>
        </div>
        <div
          v-if="$page.props.flash.error"
          class="mx-12 mt-6 rounded bg-red-300 px-6 pb-5 pt-6 text-center leading-tight text-red-800"
        >
          {{ $page.props.flash.error }}
        </div>
        <div
          v-if="student.course.ministry_of_labour_code && !$page.props.flash.error"
          class="mx-12 mt-6 rounded bg-yellow-300 px-6 pb-5 pt-6 text-center leading-tight text-yellow-800"
        >
          {{ $t('The following information is required by the') }}
          <strong>{{ $t('Ontario Ministry of Labour') }}</strong>
          .
        </div>
        <form @submit.prevent="submit">
          <div class="-mb-8 -mr-6 flex flex-wrap p-12">
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input v-model="form.first_name" :error="form.errors.first_name" :label="$t('First name')" />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input v-model="form.last_name" :error="form.errors.last_name" :label="$t('Last name')" />
            </div>
            <div v-if="student.course.is_esc_course" class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input
                v-model="form.birth_day"
                :error="form.errors.birth_day"
                label="Birth Day (DD)"
                type="password"
              />
            </div>
            <div v-if="student.course.is_esc_course" class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input
                v-model="form.birth_month"
                :error="form.errors.birth_month"
                label="Birth Month (MM)"
                type="password"
              />
            </div>
            <div
              v-if="student.course.ministry_of_labour_code || student.course.is_esc_course"
              class="w-full pb-8 pr-6 lg:w-1/2"
            >
              <text-input
                v-model="form.birth_year"
                :error="form.errors.birth_year"
                :label="$t('Birth Year (YYYY)')"
                type="password"
              />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input v-model="form.username" :error="form.errors.username" :label="$t('Username')" />
            </div>
            <div
              v-if="student.course.ministry_of_labour_code && student.course.collects_learner_status"
              class="w-full pb-8 pr-6 lg:w-1/2"
            >
              <select-input
                v-model="form.mol_learner_status"
                :error="form.errors.mol_learner_status"
                :label="$t('Learner Type')"
              >
                <option :value="null" />
                <option v-for="(status, key) in mol_learner_statuses" :key="key" :value="key">
                  {{ status }}
                </option>
              </select-input>
            </div>
            <div
              v-if="student.course.ministry_of_labour_code && student.course.collects_learner_status"
              class="w-full pb-8 pr-6 lg:w-1/2"
            >
              <select-input
                v-model="form.mol_sector"
                :disabled="!form.mol_learner_status || form.mol_learner_status === 'learner'"
                :error="form.errors.mol_sector"
                :label="$t('Sector')"
              >
                <option :value="null" />
                <option v-for="(sector, key) in mol_sectors" :key="key" :value="key">
                  {{ sector }}
                </option>
              </select-input>
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input v-model="form.email" :error="form.errors.email" :label="$t('Email')" />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input v-model="form.phone" :error="form.errors.phone" :label="$t('Phone')" />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <canada-post-address-complete-input
                v-if="$page.props.app.tenant.has_address_complete_api_enabled && canFindAddress"
                ref="address_complete"
                v-model="address_complete"
                class="w-full"
                :label="$t('Address')"
                label-class=""
                :placeholder="form.address"
                required
                :search-by="['Text', 'Description']"
                search-input-class=""
                track-by="Id"
                @update:enable-a-p-i="canFindAddress = false"
              >
                <div v-if="form.address" class="flex items-center justify-between">
                  <div class="truncate">{{ form.address }}</div>
                </div>
                <template #option="{ option, selected }">
                  <div class="flex items-center justify-between">
                    <div class="text-md" :class="{ 'text-gray-600': !selected, 'text-white': selected }">
                      {{ option.Text.concat(' ', option.Description) }}
                    </div>
                    <icon
                      v-if="option.Next == 'Find'"
                      class="ml-2 h-3 w-3 flex-shrink-0 fill-gray-400"
                      name="chevron-right"
                    />
                  </div>
                </template>
              </canada-post-address-complete-input>
              <template v-else>
                <label class="mb-2 block" for="address">{{ $t('Address') }}</label>
                <input
                  id="address"
                  v-model="form.address"
                  class="block w-full rounded border bg-white p-2 focus:outline-none"
                  :placeholder="$t('Type your address...')"
                  required
                  type="text"
                />
              </template>
              <div v-if="form.errors.address" class="form-error">{{ form.errors.address }}</div>
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input v-model="form.city" :error="form.errors.city" :label="$t('City')" />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <select-input
                v-model="form.region"
                :error="form.errors.region"
                :label="$t('Province/State')"
                :options="[null, ...regions]"
              />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input v-model="form.postal_code" :error="form.errors.postal_code" :label="$t('Postal code')" />
            </div>
            <div v-if="isStudentUpdateRequest" class="w-full pb-8 pr-6 lg:w-full">
              <textarea-input v-model="form.comments" :error="form.errors.comments" :label="$t('Comments')" />
            </div>
          </div>
          <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-12 py-6">
            <InertiaLink class="text-gray-700 hover:underline" :href="cancel" replace>{{ $t('Cancel') }}</InertiaLink>
            <loading-button v-if="!isStudentUpdateRequest" class="btn-blue" :loading="form.processing" type="submit">
              {{ $t('Complete check-in') }}
            </loading-button>
            <loading-button v-else class="btn-blue" :loading="form.processing" type="submit">
              {{ $t('Send Student Update Request') }}
            </loading-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CanadaPostAddressCompleteInput from '@/Shared/CanadaPostAddressCompleteInput.vue'
import DynamicHead from '@/Shared/DynamicHead.vue'
import Icon from '@/Shared/Icon.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import Http from '@/Utils/Http'
import { useForm } from '@inertiajs/vue3'
import _debounce from 'lodash/debounce'

export default {
  components: {
    CanadaPostAddressCompleteInput,
    DynamicHead,
    Icon,
    LoadingButton,
    SelectInput,
    TextInput,
    TextareaInput,
  },
  props: {
    student: Object,
    regions: Array,
    cancel: String,
    mol_learner_statuses: Object,
    mol_sectors: Object,
  },
  data() {
    return {
      isStudentUpdateRequest: !this.student.user,
      isEmailError: false,
      isUsernameError: false,
      form: useForm(
        {
          first_name: this.student.user?.first_name,
          last_name: this.student.user?.last_name,
          birth_day: this.student.user?.birth_day,
          birth_month: this.student.user?.birth_month,
          birth_year: this.student.user?.birth_year,
          username: this.student.user?.username?.toLowerCase(),
          email: this.student.user?.email?.toLowerCase(),
          phone: this.student.user?.phone,
          address: this.student.user?.address,
          city: this.student.user?.city,
          region: this.student.user?.region,
          postal_code: this.student.user?.postal_code,
          mol_learner_status: this.student.user?.mol_learner_status,
          mol_sector: this.student.user?.mol_sector,
          comments: null,
        },
        'CheckIn'
      ),
      address_complete: null,
      canFindAddress: true,
    }
  },
  computed: {
    pageTitle() {
      let title = ''
      if (this.student.user?.first_name) {
        title = `${this.student.user.first_name} ${this.student.user.last_name}`
      } else if (this.student.user) {
        title = `${this.student.user.id}`
      } else {
        title = this.$t('Reserved')
      }
      return this.$t('Check-in') + ` | ${title} | ${this.$page.props.app.name}`
    },
  },
  watch: {
    'form.first_name'() {
      this.checkStudentUpdateRequestOrCheckin()
    },
    'form.last_name'() {
      this.checkStudentUpdateRequestOrCheckin()
    },
    'form.email': _debounce(function () {
      this.form.errors.email = ''
      if (this.form.email) this.checkEmailAvailability()
    }, 300),
    'form.username': _debounce(function () {
      this.form.errors.username = ''
      if (this.form.username) this.checkUsernameAvailability()
    }, 300),
    'form.mol_learner_status'($value) {
      if (!$value || $value === 'learner') {
        this.form.mol_sector = null
      }
    },
    address_complete: function (newVal, oldVal) {
      this.form.address = newVal?.Line1
      this.form.city = newVal?.City
      this.form.postal_code = newVal?.PostalCode
      this.form.region = newVal?.ProvinceName
      this.form.country = newVal ? newVal.CountryIso2 : oldVal.CountryIso2
    },
  },
  methods: {
    showOrganizationName(student) {
      return (
        student.customer.organization &&
        student.customer.organization.name.toLowerCase() !== student.customer.name.toLowerCase() &&
        student.customer.organization.name.toLowerCase() !== `${student.customer.name} this.$t('Org')`.toLowerCase()
      )
    },
    checkStudentUpdateRequestOrCheckin() {
      if (
        !this.student.user ||
        this.isEmailError ||
        this.isUsernameError ||
        this.student.user?.first_name !== this.form.first_name ||
        this.student.user?.last_name !== this.form.last_name
      ) {
        this.isStudentUpdateRequest = true
      } else {
        this.isStudentUpdateRequest = false
      }
    },
    checkEmailAvailability() {
      let params = { email: this.form.email }
      if (this.student.user) {
        params.user = this.student.user.id
      }
      Http.get(this.route('email.availability'), { params })
        .then(() => {
          this.isEmailError = false
          this.form.errors.email = ''
        })
        .catch((error) => {
          this.isEmailError = true
          this.form.errors.email = error.response.data.errors.email[0]
        })
        .finally(() => {
          this.checkStudentUpdateRequestOrCheckin()
        })
    },
    checkUsernameAvailability() {
      let params = { username: this.form.username }
      if (this.student.user) {
        params.user = this.student.user.id
      }
      Http.get(this.route('username.availability'), { params })
        .then(() => {
          this.isUsernameError = false
          this.form.errors.username = ''
        })
        .catch((error) => {
          this.isUsernameError = true
          this.form.errors.username = error.response.data.errors.username[0]
        })
        .finally(() => {
          this.checkStudentUpdateRequestOrCheckin()
        })
    },
    submit() {
      if (this.isStudentUpdateRequest) {
        this.form.post(this.route('instructor.student.request.store', this.student), this.form, {
          replace: true,
        })
      } else {
        this.form.put(this.student.url, this.form, { replace: true })
      }
    },
    selfAssign(student) {
      if (confirm(this.$t('Are you sure you want to self-assign this student?'))) {
        this.form.put(this.route('instructor.students.self-assign', student.id), {
          preserveState: false,
        })
      }
    },
  },
}
</script>
