<template>
  <div class="w-full overflow-hidden rounded bg-white shadow md:max-w-lg">
    <form autocomplete="off" @submit.prevent="submit">
      <div class="-mb-8 -mr-6 flex flex-wrap p-8">
        <div class="w-full pb-8 pr-6 lg:w-1/2">
          <text-input
            v-model="form.first_name"
            autocomplete="off"
            :error="form.errors.first_name"
            :label="$t('First name')"
          />
        </div>
        <div class="w-full pb-8 pr-6 lg:w-1/2">
          <text-input
            v-model="form.last_name"
            autocomplete="off"
            :error="form.errors.last_name"
            :label="$t('Last name')"
          />
        </div>
        <div class="w-full pb-8 pr-6 xl:w-1/4">
          <text-input
            v-model="form.birth_day"
            :error="form.errors.birth_day"
            :label="$t('Birth Day')"
            max="31"
            min="1"
            type="number"
          />
          <span class="mt-2 inline-block text-xs text-gray-700">Required for Energy Safety Canada exports.</span>
        </div>
        <div class="w-full pb-8 pr-6 xl:w-1/4">
          <text-input
            v-model="form.birth_month"
            :error="form.errors.birth_month"
            :label="$t('Birth Month')"
            max="12"
            min="1"
            type="number"
          />
          <span class="mt-2 inline-block text-xs text-gray-700">Required for Energy Safety Canada exports.</span>
        </div>
        <div class="w-full pb-8 pr-6 xl:w-1/4">
          <text-input
            v-model="form.birth_year"
            :error="form.errors.birth_year"
            :label="$t('Birth Year')"
            min="1920"
            type="number"
          />
          <span class="mt-2 inline-block text-xs text-gray-700">
            {{ $t('Required to send to the Ministry of Labour') }}
          </span>
        </div>
        <div class="w-full pb-8 pr-6 lg:w-1/2">
          <select-input
            v-model="form.mol_learner_status"
            :error="form.errors.mol_learner_status"
            :label="$t('Learner Status')"
          >
            <option :value="null" />
            <option v-for="(status, key) in ministryOfLabourLearnerStatuses" :key="key" :value="key">
              {{ status }}
            </option>
          </select-input>
          <span class="mt-2 inline-block text-xs text-gray-700">
            {{ $t('Required to send to the MOL') }}
          </span>
        </div>
        <div class="w-full pb-8 pr-6 lg:w-1/2">
          <select-input
            v-model="form.mol_sector"
            :disabled="!form.mol_learner_status || form.mol_learner_status === 'learner'"
            :error="form.errors.mol_sector"
            :label="$t('Sector')"
          >
            <option :value="null" />
            <option v-for="(sector, key) in ministryOfLabourSectors" :key="key" :value="key">
              {{ sector }}
            </option>
          </select-input>
          <span class="mt-2 inline-block text-xs text-gray-700">
            {{ $t('Only enabled for select learner statuses') }}
          </span>
        </div>
        <div class="w-full pb-8 pr-6 lg:w-1/2">
          <select-input v-model="form.locale" :error="form.errors.locale" :label="$t('Locale/Language')">
            <option :value="null" />
            <option v-for="(locale, key) in locales" :key="key" :value="locale">
              {{ key }}
            </option>
          </select-input>
        </div>
        <div v-if="user.merged_to" class="w-full pb-8 pr-6 lg:w-1/2">
          <text-input autocomplete="off" disabled :label="$t('Merged To')" :model-value="user.merged_to.name" />
          <InertiaLink
            class="mt-2 flex items-center text-xs text-gray-700"
            :href="route('users.edit', user.merged_to.id)"
          >
            <icon class="mr-1 h-3 w-3 flex-shrink-0 fill-gray-700" name="link" />
            {{ $t('Go to User') }}
          </InertiaLink>
        </div>
      </div>
      <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Public Profile') }}</div>
      <div class="-mb-8 -mr-6 flex flex-wrap p-8">
        <div class="flex w-full items-center justify-between pb-8 pr-6 lg:w-1/2">
          <label class="mr-3 cursor-pointer font-semibold text-ts-gray-text-bold" for="public-profile">
            {{ $t('Enabled') }}
          </label>
          <front-small-toggle-input
            id="public-profile"
            v-model="form.public_profile_enabled"
            :error="form.errors.public_profile_enabled"
          />
        </div>
        <div class="w-full pb-8 pr-6 lg:w-1/2">
          <div v-if="form.public_profile_enabled" class="flex w-full flex-col">
            <label class="mr-3 cursor-pointer font-semibold text-ts-gray-text-bold" for="public-profile">
              {{ $t('Profile Url') }}
            </label>
            <div class="flex items-center">
              <div>{{ base_url }}/</div>
              <div class="flex flex-col">
                <input v-model="form.public_profile_key" class="border-b" type="text" />
              </div>
            </div>
            <div v-if="form.public_profile_key" class="form-error">
              {{ form.errors.public_profile_key }}
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Contact Information') }}</div>
      <div class="-mb-8 -mr-6 flex flex-wrap p-8">
        <div class="w-full pb-8 pr-6 lg:w-1/2">
          <text-input v-model="form.phone" autocomplete="off" :error="form.errors.phone" :label="$t('Phone')" />
        </div>
        <div class="w-full pb-8 pr-6 lg:w-1/2">
          <text-input v-model="form.address" autocomplete="off" :error="form.errors.address" :label="$t('Address')" />
        </div>
        <div class="w-full pb-8 pr-6 lg:w-1/2">
          <text-input v-model="form.city" autocomplete="off" :error="form.errors.city" :label="$t('City')" />
        </div>
        <div class="w-full pb-8 pr-6 lg:w-1/2">
          <select-input
            v-model="form.region"
            :error="form.errors.region"
            :label="$t('Province/State')"
            :options="[null, ...regions]"
          />
        </div>
        <div class="w-full pb-8 pr-6 lg:w-1/2">
          <text-input
            v-model="form.postal_code"
            autocomplete="off"
            :error="form.errors.postal_code"
            :label="$t('Postal code')"
          />
        </div>
      </div>
      <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">
        {{ $t('Certificate Information') }}
      </div>
      <div class="-mb-8 -mr-6 flex flex-wrap p-8">
        <notice v-if="user.organization">
          {{ $t('The certificate fields are disabled for users within an organization.') }}
        </notice>
        <div class="w-full pb-8 pr-6 lg:w-1/2">
          <text-input
            v-model="form.employer_name"
            autocomplete="off"
            :disabled="user.organization"
            :error="form.errors.employer_name"
            :label="$t('Employer Name')"
          />
        </div>
        <div class="w-full pb-8 pr-6 lg:w-1/2">
          <text-input
            v-model="form.employer_address"
            autocomplete="off"
            :disabled="user.organization"
            :error="form.errors.employer_address"
            :label="$t('Employer Address')"
          />
        </div>
      </div>
      <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Access') }}</div>
      <div class="-mb-8 -mr-6 flex flex-wrap p-8">
        <div class="w-full pb-8 pr-6 lg:w-1/2">
          <text-input
            v-model="form.email"
            autocomplete="off"
            :disabled="!can.updateLoginCredentials"
            :error="form.errors.email"
            :label="$t('Email')"
          />
          <small v-if="user.has_suppressed_email">
            This email is suppressed,
            <button class="hover:underline" @click.prevent.stop="resetEmailSuppression()">click here to reset.</button>
          </small>
        </div>
        <div class="w-full pb-8 pr-6 lg:w-1/2">
          <text-input v-model="form.username" :error="form.errors.username" :label="$t('Username')" />
        </div>
        <div class="w-full pb-8 pr-6 lg:w-1/2">
          <text-input
            v-model="form.password"
            autocomplete="new-password"
            :disabled="!can.updateLoginCredentials"
            :error="form.errors.password"
            :label="$t('Password')"
            type="password"
          />
          <span
            v-if="user.email && user.email === form.email"
            class="mt-2 inline-block cursor-pointer text-xs text-blue-600 underline hover:text-blue-700"
            @click.stop="sendPasswordReset"
          >
            {{ $t('Send password reset') }}
          </span>
        </div>
      </div>
      <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
        <loading-button class="btn-blue" :loading="form.processing" type="submit">
          {{ $t('Update') }}
        </loading-button>
      </div>
    </form>
  </div>
</template>

<script>
import UserEditLayoutVue from './UserEditLayout.vue'
import FrontSmallToggleInput from '@/Shared/FrontSmallToggleInput.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import Notice from '@/Shared/Notice.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import { fetchUser } from '@/Stores/ActiveUser'
import Http from '@/Utils/Http'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    FrontSmallToggleInput,
    Icon,
    LoadingButton,
    Notice,
    SelectInput,
    TextInput,
  },
  layout: [Layout, UserEditLayoutVue],
  props: {
    user: Object,
    can: Object,
    base_url: String,
    regions: Array,
    locales: Object,
    ministryOfLabourLearnerStatuses: Object,
    ministryOfLabourSectors: Object,
  },

  data() {
    return {
      form: useForm({
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        birth_day: this.user.birth_day,
        birth_month: this.user.birth_month,
        birth_year: this.user.birth_year,
        email: this.user.email,
        username: this.user.username,
        phone: this.user.phone,
        address: this.user.address,
        city: this.user.city,
        region: this.user.region,
        postal_code: this.user.postal_code,
        password: null,
        locale: this.user.locale,
        timezone: this.user.timezone,
        employer_name: this.user.employer_name,
        employer_address: this.user.employer_address,
        public_profile_enabled: this.user.public_profile_enabled,
        public_profile_key: this.user.public_profile_key,
        mol_learner_status: this.user.mol_learner_status,
        mol_sector: this.user.mol_sector,
      }),
      passwordResetForm: useForm({
        email: null,
      }),
    }
  },
  watch: {
    'form.public_profile_enabled'($value) {
      if ($value && !this.form.public_profile_key) {
        this.generateProfileKey()
      }
    },
    'form.mol_learner_status'($value) {
      if (!$value || $value === 'learner') {
        this.form.mol_sector = null
      }
    },
  },
  methods: {
    submit() {
      this.form
        .transform((data) => ({
          first_name: data.first_name,
          last_name: data.last_name,
          birth_day: data.birth_day,
          birth_month: data.birth_month,
          birth_year: data.birth_year,
          email: data.email,
          username: data.username,
          phone: data.phone,
          address: data.address,
          city: data.city,
          region: data.region,
          postal_code: data.postal_code,
          password: data.password,
          locale: data.locale,
          timezone: data.timezone,
          employer_name: data.employer_name,
          employer_address: data.employer_address,
          public_profile_enabled: data.public_profile_enabled,
          public_profile_key: data.public_profile_key,
          mol_learner_status: data.mol_learner_status,
          mol_sector: data.mol_sector,
        }))
        .put(this.route('users.update', this.user.id), {
          onSuccess: () => fetchUser(),
        })
    },
    generateProfileKey() {
      return Http.get(this.route('profile-key.generate', this.user)).then((response) => {
        if (!this.form.public_profile_key || this.form.public_profile_key == '') {
          this.form.public_profile_key = response.data.key
        }
      })
    },
    sendPasswordReset() {
      this.passwordResetForm.email = this.user.email
      this.passwordResetForm.post(this.route('front.password.email'))
    },
    resetEmailSuppression() {
      this.$inertia.post(this.route('users.email.reset-suppression', this.user))
    },
  },
}
</script>
