<template>
  <layout title="Subscription">
    <div class="flex flex-col xl:flex-row xl:justify-between">
      <div class="sm:no-flex mb-8 flex w-full items-center justify-between">
        <breadcrumb-admin :path="breadCrumbs" />
      </div>
    </div>
    <div class="w-full max-w-lg">
      <div class="w-full space-y-8">
        <subscription-plan
          v-for="(subscriptionPlan, index) in subscriptionPlans"
          :key="index"
          :is-subscribed="subscriptionPlan.isSubscribed"
          :organization="organization"
          :subscription-plan="subscriptionPlan"
        />
      </div>
    </div>
  </layout>
</template>

<script>
import SubscriptionPlan from './SubscriptionPlan.vue'
import BreadcrumbAdmin from '@/Shared/BreadcrumbAdmin.vue'
import Layout from '@/Shared/Layout.vue'

export default {
  components: { SubscriptionPlan, BreadcrumbAdmin, Layout },
  props: {
    organization: Object,
    subscriptionPlans: Array,
  },
  computed: {
    breadCrumbs() {
      return [
        {
          name: this.$t('Organizations'),
          url: this.route('organizations.index'),
        },
        {
          name: this.organization.name,
          url: this.route('organizations.edit', this.organization.id),
        },
        {
          name: this.$t('Subscriptions'),
          url: this.route('organizations.subscriptions.index', this.organization.id),
        },
        {
          name: this.$t('Add'),
        },
      ]
    },
  },
}
</script>
