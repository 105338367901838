<template>
  <front-modal class="rounded text-base" :show="show" width="auto" @close="close">
    <div class="flex flex-wrap bg-white px-8 pt-8">
      <breadcrumb :name="user.display_name" />
    </div>
    <div class="max-w-sm overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input disabled :label="$t('First name')" :model-value="user.first_name" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input disabled :label="$t('Last name')" :model-value="user.last_name" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.email" :error="form.errors.email" :label="$t('Email')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.username" :error="form.errors.username" :label="$t('Username')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.phone" :error="form.errors.phone" :label="$t('Phone')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input
              v-model="form.password"
              autocomplete="new-password"
              :error="form.errors.password"
              :label="$t('Password')"
              type="password"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <select-input v-model="form.timezone" :error="form.errors.timezone" :label="$t('Timezone')">
              <option :value="null" />
              <option v-for="(timezone, key) in user.timezones" :key="key" :value="timezone">
                {{ timezone }}
              </option>
            </select-input>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <label class="form-label">{{ $t('Groups:') }}</label>
            <Multiselect
              v-model="form.groups"
              deselect-label=""
              :disabled="!user.organization || !groups"
              :hide-label="true"
              :hide-selected="true"
              :label="$t('name')"
              :loading="isLoading"
              :multiple="true"
              :name="$t('Groups')"
              :options="groups"
              :placeholder="$t('Select groups')"
              select-label=""
              track-by="id"
              @search-change="searchGroups"
            >
              <template #noResult>{{ $t('No groups found.') }}</template>
              <template #noOptions>{{ $t('No groups found.') }}</template>
            </Multiselect>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <organization-role-dropdown v-model="form.organization_role" :error="form.errors.organization_role" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <label class="form-label">{{ $t('Job Profiles:') }}</label>
            <Multiselect
              v-model="form.job_profiles"
              deselect-label=""
              :disabled="!user.organization || !jobProfiles"
              :hide-label="true"
              :hide-selected="true"
              :label="$t('name')"
              :loading="isJobProfileLoading"
              :multiple="true"
              :name="$t('Job Profiles')"
              :options="jobProfiles"
              :placeholder="$t('Select job profiles')"
              select-label=""
              track-by="id"
              @search-change="searchJobProfiles"
            >
              <template #noResult>{{ $t('No job profiles found.') }}</template>
              <template #noOptions>{{ $t('No job profiles found.') }}</template>
            </Multiselect>
          </div>
          <div v-if="user.has_organization" class="w-full pb-8 pr-6 lg:w-full">
            <label>
              <span class="form-label text-ts-front-label">{{ $t('Certificate Address') }}</span>
              <select
                v-model="form.certificate_location_id"
                class="form-select mt-3 w-full rounded-lg border border-ts-front-border-light bg-ts-front-field-light p-3 pr-6 text-gray-700 focus:border-ts-front-border-dark focus:text-gray-800 focus:outline-none"
                :class="{ 'border-ts-front-border-error': form.errors.certificate_location_id }"
              >
                <option :value="null">{{ user.organization_location }}</option>
                <option v-for="option in user.organization_locations" :key="option.id" :value="option.id">
                  {{ option.address }}
                </option>
              </select>
            </label>
            <div v-if="form.errors.certificate_location_id" class="form-error">
              {{ form.errors.certificate_location_id }}
            </div>
          </div>
        </div>
        <div class="flex items-center justify-center bg-white px-8 pb-8 pt-1">
          <button class="btn-gray-outline mx-2" type="button" @click="close">{{ $t('Close') }}</button>
          <front-loading-button :loading="form.processing" type="submit">{{ $t('Update') }}</front-loading-button>
        </div>
      </form>
    </div>
  </front-modal>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontModal from '@/Shared/FrontModal.vue'
import OrganizationRoleDropdown from '@/Shared/OrganizationRoleDropdown.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import { query } from '@/Utils/Helpers'
import Http from '@/Utils/Http'
import { useForm } from '@inertiajs/vue3'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect,
    Breadcrumb,
    FrontLoadingButton,
    FrontModal,
    OrganizationRoleDropdown,
    SelectInput,
    TextInput,
  },
  props: {
    user: Object,
    show: Boolean,
    organization: Object,
  },
  data() {
    return {
      isLoading: false,
      isJobProfileLoading: false,
      groups: [],
      jobProfiles: this.organization.job_profiles ?? null,
      form: useForm(
        {
          email: this.user.email,
          username: this.user.username,
          phone: this.user.phone,
          timezone: this.user.timezone,
          groups: this.user.groups,
          organization_role: this.user.organization_role,
          password: '',
          job_profiles: this.user.job_profiles,
          certificate_location_id: this.user.certificate_location_id,
        },
        'UserEditModal'
      ),
    }
  },
  methods: {
    query,
    close() {
      this.$emit('close')
    },
    searchGroups(query) {
      this.isLoading = true
      Http.get(this.route('front.manager.groups.search'), { params: { search: query } })
        .then((response) => {
          this.groups = response.data.data
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    searchJobProfiles(query) {
      this.isJobProfileLoading = true
      Http.get(this.route('front.manager.job-profiles.search'), {
        params: query,
      })
        .then((response) => {
          this.jobProfiles = response.data.data
        })
        .finally(() => {
          this.isJobProfileLoading = false
        })
    },
    submit() {
      this.sending = true
      this.form
        .transform((data) => ({
          ...data,
          groups: data.groups ? data.groups.map((group) => group.id) : [],
          job_profiles: data.job_profiles ? data.job_profiles.map((profile) => profile.id) : [],
        }))
        .put(this.route('front.manager.users.update', this.user.id), {
          onSuccess: () => this.close(),
        })
    },
  },
}
</script>
