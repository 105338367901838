<template>
  <modal class="text-base" :show="!hide" width="800px" @close="close">
    <div class="flex rounded-lg bg-white">
      <div class="w-48 flex-shrink-0 space-y-5 rounded-l-lg bg-gray-200 px-3 py-8">
        <introduce-menu-item :is-active="introduction.courses" @click="show('courses')">
          {{ $t('Courses') }}
        </introduce-menu-item>
        <introduce-menu-item :is-active="introduction.certificates" @click="show('certificates')">
          {{ $t('Certificates') }}
        </introduce-menu-item>
        <introduce-menu-item :is-active="introduction.notifications" @click="show('notifications')">
          {{ $t('Notifications') }}
        </introduce-menu-item>
        <introduce-menu-item :is-active="introduction.settings" @click="show('settings')">
          {{ $t('Settings') }}
        </introduce-menu-item>
        <introduce-menu-item :is-active="introduction.purchases" @click="show('purchases')">
          {{ $t('Purchases') }}
        </introduce-menu-item>
      </div>
      <div class="w-full rounded-r-lg px-12 py-8">
        <div v-if="introduction.courses" class="space-y-4">
          <div class="text-sm text-gray-400">{{ $t('Easy Compliance Management') }}</div>
          <div class="flex items-center justify-between">
            <div class="font-heading text-xl font-bold">{{ $t('Online Learning') }}</div>
            <div class="rounded bg-ts-blue-200">
              <div class="p-2 text-sm text-ts-blue-500">1/5</div>
            </div>
          </div>
          <div>
            <video class="w-full" controls>
              <source
                src="https://touchstone.s3.us-east-2.amazonaws.com/files/U871NLVlJ5g877w2zkfDZK4pul7GQT6tJsLy8QE1.mkv"
              />
            </video>
          </div>
          <div class="text-sm leading-snug text-gray-500">
            {{
              $t(
                'Learn how to navigate your course content, materials, and certificates for both online and classroom training. Add courses to your course list and transfer credits to other account holders.'
              )
            }}
          </div>
          <div class="flex justify-end text-sm">
            <front-secondary-button class="text-gray-500" @click="disable">
              {{ $t('Do not show again') }}
            </front-secondary-button>
            <front-solid-button class="ml-4" @click="show('certificates')">{{ $t('Next') }}</front-solid-button>
          </div>
        </div>
        <div v-else-if="introduction.certificates" class="space-y-4">
          <div class="text-sm text-gray-400">{{ $t('Easy Compliance Management') }}</div>
          <div class="flex items-center justify-between">
            <div class="font-heading text-xl font-bold">{{ $t('Online Learning') }}</div>
            <div class="rounded bg-ts-blue-200">
              <div class="p-2 text-sm text-ts-blue-500">2/5</div>
            </div>
          </div>
          <div>
            <video class="w-full" controls>
              <source
                src="https://touchstone.s3.us-east-2.amazonaws.com/files/U871NLVlJ5g877w2zkfDZK4pul7GQT6tJsLy8QE1.mkv"
              />
            </video>
          </div>
          <div class="text-sm leading-snug text-gray-500">
            {{
              $t(
                'View all of your certificates in one place, see expired certificates and re-enrol to make sure you are always up to date and compliant.'
              )
            }}
          </div>
          <div class="flex justify-end text-sm">
            <front-secondary-button class="text-gray-500" @click="disable">
              {{ $t('Do not show again') }}
            </front-secondary-button>
            <front-solid-button class="ml-4" @click="show('notifications')">{{ $t('Next') }}</front-solid-button>
          </div>
        </div>
        <div v-else-if="introduction.notifications" class="space-y-4">
          <div class="text-sm text-gray-400">{{ $t('Easy Compliance Management') }}</div>
          <div class="flex items-center justify-between">
            <div class="font-heading text-xl font-bold">{{ $t('Online Learning') }}</div>
            <div class="rounded bg-ts-blue-200">
              <div class="p-2 text-sm text-ts-blue-500">3/5</div>
            </div>
          </div>
          <div>
            <video class="w-full" controls>
              <source
                src="https://touchstone.s3.us-east-2.amazonaws.com/files/U871NLVlJ5g877w2zkfDZK4pul7GQT6tJsLy8QE1.mkv"
              />
            </video>
          </div>
          <div class="text-sm leading-snug text-gray-500">
            {{ $t('Learn how to manage your notifications, mark as read, delete, and view important actions.') }}
          </div>
          <div class="flex justify-end text-sm">
            <front-secondary-button class="text-gray-500" @click="disable">
              {{ $t('Do not show again') }}
            </front-secondary-button>
            <front-solid-button class="ml-4" @click="show('settings')">{{ $t('Next') }}</front-solid-button>
          </div>
        </div>
        <div v-else-if="introduction.settings" class="space-y-4">
          <div class="text-sm text-gray-400">{{ $t('Easy Compliance Management') }}</div>
          <div class="flex items-center justify-between">
            <div class="font-heading text-xl font-bold">{{ $t('Online Learning') }}</div>
            <div class="rounded bg-ts-blue-200">
              <div class="p-2 text-sm text-ts-blue-500">4/5</div>
            </div>
          </div>
          <div>
            <video class="w-full" controls>
              <source
                src="https://touchstone.s3.us-east-2.amazonaws.com/files/U871NLVlJ5g877w2zkfDZK4pul7GQT6tJsLy8QE1.mkv"
              />
            </video>
          </div>
          <div class="text-sm leading-snug text-gray-500">
            {{
              $t(
                'Learn how to update your account information, password and printable certificate information - Change how you receive notifications via text, app notifications, and email.'
              )
            }}
          </div>
          <div class="flex justify-end text-sm">
            <front-secondary-button class="text-gray-500" @click="disable">
              {{ $t('Do not show again') }}
            </front-secondary-button>
            <front-solid-button class="ml-4" @click="show('purchases')">{{ $t('Next') }}</front-solid-button>
          </div>
        </div>
        <div v-else-if="introduction.purchases" class="space-y-4">
          <div class="text-sm text-gray-400">{{ $t('Easy Compliance Management') }}</div>
          <div class="flex items-center justify-between">
            <div class="font-heading text-xl font-bold">{{ $t('Online Learning') }}</div>
            <div class="rounded bg-ts-blue-200">
              <div class="p-2 text-sm text-ts-blue-500">5/5</div>
            </div>
          </div>
          <div>
            <video class="w-full" controls>
              <source
                src="https://touchstone.s3.us-east-2.amazonaws.com/files/U871NLVlJ5g877w2zkfDZK4pul7GQT6tJsLy8QE1.mkv"
              />
            </video>
          </div>
          <div class="text-sm leading-snug text-gray-500">
            {{
              $t(
                'Learn how to view your purchase history, how course credits work, how to transfer courses to other accounts and view your transfer history all in one place!'
              )
            }}
          </div>
          <div class="flex justify-end text-sm">
            <front-secondary-button class="text-gray-500" @click="disable">
              {{ $t('Do not show again') }}
            </front-secondary-button>
            <front-solid-button class="ml-4" @click="close">{{ $t('Finish') }}</front-solid-button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import IntroduceMenuItem from './IntroduceMenuItem.vue'
import FrontSecondaryButton from '@/Shared/FrontSecondaryButton.vue'
import FrontSolidButton from '@/Shared/FrontSolidButton.vue'
import Modal from '@/Shared/Modal.vue'
import _mapValues from 'lodash/mapValues'

export default {
  components: { IntroduceMenuItem, FrontSecondaryButton, FrontSolidButton, Modal },
  data() {
    return {
      hide: localStorage.getItem('hideUserIntroduction') || false,
      introduction: {
        courses: true,
        certificates: false,
        notifications: false,
        settings: false,
        purchases: false,
      },
    }
  },
  methods: {
    disable() {
      localStorage.setItem('hideUserIntroduction', true)
      this.close()
    },
    close() {
      this.hide = true
    },
    show(category) {
      this.introduction = _mapValues(this.introduction, () => false)
      this.introduction[category] = true
    },
  },
}
</script>
