<template>
  <modal class="rounded text-base" :show="show" style="z-index: 100000" width="auto" @close="close">
    <div class="max-h-screen max-w-lg overflow-auto rounded bg-white pt-6 shadow">
      <div class="flex flex-wrap">
        <div class="w-full px-6 pb-6 pt-3">
          <select-input v-model="form.course_id" :error="form.errors.course_id" :label="$t('Course')">
            <option :value="null" />
            <option v-for="course in courses" :key="course.id" :value="course.id">
              {{ course.name }}
            </option>
          </select-input>
        </div>
        <div class="flex w-full items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <div class="flex">
            <button class="btn-blue" @click.stop="addCourse">{{ $t('Add Course') }}</button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '@/Shared/Modal.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import Http from '@/Utils/Http'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Modal,
    SelectInput,
  },
  props: {
    show: Boolean,
    subscriptionPlan: Object,
  },
  data() {
    return {
      courses: [],
      form: useForm({
        course_id: null,
      }),
    }
  },
  mounted() {
    this.setCourses()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    setCourses() {
      Http.get(this.route('subscription-plans.courses', this.subscriptionPlan.id)).then((response) => {
        this.courses = response.data
      })
    },
    addCourse() {
      this.form.post(this.route('subscription-plans.courses.store', this.subscriptionPlan.id), {
        onSuccess: () => this.close(),
      })
    },
  },
}
</script>
