<template>
  <layout :title="form.name">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('banks.edit', bank.id)">
        {{ $t('Questions') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Create') }}
    </h1>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <textarea-input
              v-model="form.name"
              :autosize="true"
              :error="form.errors.name"
              :label="$t('Question')"
              rows="2"
            />
          </div>
          <div class="w-full pb-8 pr-6">
            <textarea-input
              v-model="form.explanation"
              :autosize="true"
              :error="form.errors.explanation"
              :label="$t('Explanation')"
              rows="2"
            />
          </div>
          <div class="w-full pb-8 pr-6">
            <search-input
              v-model="form.file"
              :data="files"
              :error="form.errors.file_id"
              :label="$t('Files')"
              search-by="name"
              track-by="id"
            >
              <div v-if="form.file" class="flex items-center justify-between">
                <div class="truncate">{{ form.file.name }}</div>
              </div>
              <template #option="{ option }">
                <div class="flex items-center justify-between">
                  <div class="truncate">{{ option.name }}</div>
                </div>
              </template>
            </search-input>
          </div>
          <div class="w-full pb-8 pr-6">
            <div>{{ $t('Type:') }}</div>
            <div class="mt-4 flex items-center">
              <label class="flex items-center">
                <input v-model="type" class="mr-2" type="checkbox" @change="checked($event)" />
                <span class="">{{ $t('True / False') }}</span>
              </label>
            </div>
          </div>
          <template v-for="(value, key) in form.options" :key="key">
            <div class="w-full pb-8 pr-6">
              <textarea-input
                v-if="key < 2 ? true : !type"
                :key="key"
                v-model="form.options[key]"
                :autosize="true"
                :error="form.errors['options.' + key]"
                :label="$t('Option :key', { key: key + 1 })"
                rows="2"
              />
            </div>
          </template>
          <div class="pb-8 pr-6">
            <div>{{ $t('Answer') }}</div>
            <div v-if="type" class="mt-4 flex items-center">
              <label class="mr-2 inline-flex items-center">
                <input v-model="form.correct" class="form-radio" name="option" type="radio" :value="[0]" />
                <span class="ml-2">{{ $t('True') }}</span>
              </label>
              <label class="inline-flex items-center">
                <input v-model="form.correct" class="form-radio" name="option" type="radio" :value="[1]" />
                <span class="ml-2">{{ $t('False') }}</span>
              </label>
            </div>
            <div v-else class="mt-4 flex items-center">
              <label v-for="(value, key) in form.options" :key="key" class="mr-2 flex items-center">
                <input v-model="form.correct" class="mr-2" type="checkbox" :value="key" />
                {{ key + 1 }}
              </label>
            </div>
            <div v-if="form.errors.correct" class="form-error">
              {{ form.errors.correct }}
            </div>
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Create Question') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import SearchInput from '@/Shared/SearchInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Layout,
    LoadingButton,
    SearchInput,
    TextareaInput,
  },
  props: {
    bank: Object,
    files: Array,
  },
  data() {
    return {
      type: false,
      form: useForm(
        {
          name: null,
          explanation: null,
          file: null,
          options: Array(6),
          correct: [],
        },
        'QuestionCreate'
      ),
    }
  },
  methods: {
    submit() {
      this.form
        .transform((data) => ({
          ...data,
          file_id: data.file ? data.file.id : null,
        }))
        .post(this.route('questions.store', this.bank.id))
    },
    checked($event) {
      if ($event.target.checked) {
        this.form.options = ['True', 'False']

        return
      }
      this.form.options = Array(6)
    },
  },
}
</script>
