<template>
  <guest-layout :tenant="tenant" :title="$t('Certificate Signature Request')">
    <div class="container m-auto flex flex-col items-center">
      <div class="grid w-full grid-cols-1 gap-10">
        <h1 class="text-center font-heading text-3xl font-semibold text-darkGray-1000">Signature Requested</h1>
        <p class="text-center font-sans text-lg font-semibold leading-relaxed text-darkGray-900">
          "{{ certificateSignatureRequest.sender.name }}" {{ $t('has requested your signature for') }}
          <br />
          "{{ certificate.name }}" {{ $t('Certificate') }}
        </p>
        <div class="mx-auto flex w-full flex-col-reverse items-center justify-center px-6 lg:flex-row">
          <div class="f mt-2 w-full max-w-sm lg:mt-0">
            <span class="lg:float-right">
              <front-signature-pad :error="form.errors.signature_image" :show="true" @updated="signatureUpdate" />
            </span>
          </div>
          <div class="mx-16 hidden h-28 border border-gray-400 py-10 lg:block" />
          <div class="flex w-full max-w-sm">
            <plastic-card-preview
              v-if="certificate.plastic_card_preview_format"
              :background-url="certificate.plastic_card_preview_format.background_url"
              class="block max-w-sm rounded-xl bg-gray-100 px-0 shadow"
              :crop="certificate.plastic_card_preview_format.crop"
              :pdf-fields="certificate.pdf_fields"
              :pdf-images="certificate.pdf_images"
              :pdf-settings="certificate.plastic_card_preview_format.pdf_settings"
            />
            <div v-else class="w-full">
              <show-plastic-card-data :certificate="certificate" />
              <div class="h-6 pt-3 font-semibold tracking-wider text-ts-blue-600" />
            </div>
          </div>
        </div>
      </div>
      <div class="mt-10 flex space-x-4">
        <front-outline-button @click.prevent="reject">Decline</front-outline-button>
        <front-loading-button :loading="form.processing" @click.prevent="submit">
          {{ $t('Approve and Sign') }}
        </front-loading-button>
      </div>
    </div>
  </guest-layout>
</template>

<script>
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontOutlineButton from '@/Shared/FrontOutlineButton.vue'
import FrontSignaturePad from '@/Shared/FrontSignaturePad.vue'
import GuestLayout from '@/Shared/GuestLayout.vue'
import PlasticCardPreview from '@/Shared/PlasticCardPreview.vue'
import ShowPlasticCardData from '@/Shared/ShowPlasticCardData.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    FrontLoadingButton,
    FrontOutlineButton,
    FrontSignaturePad,
    GuestLayout,
    PlasticCardPreview,
    ShowPlasticCardData,
  },
  props: {
    tenant: Object,
    certificateSignatureRequest: Object,
    certificate: Object,
    acceptUrl: String,
    declineUrl: String,
  },
  data() {
    return {
      form: useForm(
        {
          signature_image: null,
        },
        'AccessCodeIndex'
      ),
    }
  },
  methods: {
    submit() {
      this.form.post(this.acceptUrl)
    },
    reject() {
      this.$inertia.post(this.declineUrl)
    },
    signatureUpdate(signature) {
      this.form.signature_image = signature
    },
  },
}
</script>
