<template>
  <layout :title="$t('Multiple Choices')">
    <div class="w-full">
      <breadcrumb-admin :path="breadcrumbs" />
      <div class="mb-6 flex items-center justify-between">
        <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
          <label class="mt-4 block text-gray-800">{{ $t('Deleted:') }}</label>
          <select v-model="form.deleted" class="form-select mt-1 w-full">
            <option :value="null" />
            <option value="with">{{ $t('With Deleted') }}</option>
            <option value="only">{{ $t('Only Deleted') }}</option>
          </select>
        </search-filter>
        <InertiaLink class="btn-blue" :href="route('multiple-choices.create')">
          <span>{{ $t('Create') }}</span>
          <span class="hidden md:inline">{{ $t('Multiple Choice') }}</span>
        </InertiaLink>
      </div>
      <div class="rounded bg-white shadow">
        <SimpleTable>
          <template #head>
            <TableHead>{{ $t('Choices') }}</TableHead>
            <TableHead>{{ $t('Updated At') }}</TableHead>
            <TableHead />
          </template>
          <TableRow v-if="selects.data.length === 0">
            <TableData colspan="6">{{ $t('No selects found.') }}</TableData>
          </TableRow>
          <TableRow
            v-for="select in selects.data"
            :key="select.id"
            :clickable="true"
            @click="$inertia.visit(route('multiple-choices.edit', { select: select }))"
          >
            <TableData class="space-x-1">
              <select-name :model-value="select" />
              <archived-badge v-if="select.archived_at" />
              <deleted-badge v-if="select.deleted_at" />
            </TableData>
            <TableData>{{ select.updated_at }}</TableData>
            <TableData class="w-min align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </TableData>
          </TableRow>
        </SimpleTable>
      </div>
      <pagination :links="selects.links" :preserve-scroll="true" />
    </div>
  </layout>
</template>

<script>
import SelectName from '@/Shared/Answerables/SelectName.vue'
import ArchivedBadge from '@/Shared/ArchivedBadge.vue'
import BreadcrumbAdmin from '@/Shared/BreadcrumbAdmin.vue'
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
    SelectName,
    ArchivedBadge,
    BreadcrumbAdmin,
    DeletedBadge,
    Icon,
    Layout,
    Pagination,
    SearchFilter,
  },
  props: {
    filters: Object,
    selects: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        start_date: this.filters.start_date,
        end_date: this.filters.end_date,
        globally_published: this.filters.globally_published,
        deleted: this.filters.deleted,
      },
      breadcrumbs: [
        {
          name: this.$t('Multiple Choices'),
          route: this.route('multiple-choices.index'),
        },
      ],
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)

        this.$inertia.get(
          this.route('multiple-choices.index'),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
