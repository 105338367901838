<template>
  <dynamic-search-input
    v-model="course"
    :label="$t('Course')"
    :required="required"
    :search-by="['name', 'name_en']"
    track-by="id"
    :url="route('courses.search')"
  >
    <div v-if="course" class="flex items-center justify-between">
      <div class="truncate">{{ course.name }}</div>
    </div>
    <template #option="{ option, selected }">
      <div class="flex items-center justify-between">
        <div class="text-xs" :class="{ 'text-gray-600': !selected, 'text-white': selected }">
          {{ option.name }}
        </div>
      </div>
    </template>
  </dynamic-search-input>
</template>
<script>
import DynamicSearchInput from '@/Shared/DynamicSearchInput.vue'

export default {
  components: {
    DynamicSearchInput,
  },
  props: {
    modelValue: [Object],
    label: {
      type: String,
      default: 'User',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      course: this.modelValue,
    }
  },
  watch: {
    course(newCourse) {
      this.$emit('update:modelValue', newCourse)
    },
  },
}
</script>
