<template>
  <div>
    <Disclosure v-if="formAnswer.form_answerable_type === 'form-section-answer'" v-slot="{ open }" as="div">
      <DisclosureButton class="mb-3 w-full columns-1 gap-8 rounded-lg bg-qualify-red-500 px-4 py-2">
        <div class="inline-flex w-full items-center rounded bg-qualify-red-500 text-white">
          <ChevronRightIcon aria-hidden="true" :class="!open ? 'h-4 w-4 transition' : 'h-4 w-4 rotate-90 transition'" />
          <p class="ml-2 py-2">{{ formAnswer.form_content.title }}</p>
        </div>
      </DisclosureButton>
      <DisclosurePanel>
        <front-form-item-view
          v-for="childFormAnswer in formAnswer.children"
          :key="childFormAnswer.id"
          :form-answer="childFormAnswer"
        />
      </DisclosurePanel>
    </Disclosure>
    <div v-else class="mb-3 columns-1 gap-8 rounded-xl bg-gray-100 px-4 py-2">
      <label>
        <div class="mb-4">
          <component :is="answerComponentName" :form-answer="formAnswer" :model-value="formAnswer.value" />
        </div>
      </label>
      <div class="flex flex-wrap">
        <front-media-icon v-for="media in formAnswer.media" :key="media.id" :media="media" @click="openImage(media)" />
      </div>
      <div>
        <p v-if="formAnswer.notes" class="mt-2 cursor-pointer px-1 py-2 hover:rounded hover:bg-gray-100">
          {{ formAnswer.notes }}
        </p>
        <div v-if="notes" class="py-2">
          <p
            class="m-0 mb-4 rounded border bg-white px-2 py-2 placeholder-shown:ring-0 focus:border-0 focus:outline-none focus:ring-2 focus:ring-qualify-red-600"
          >
            {{ formAnswer.notes }}
          </p>
        </div>
      </div>
      <div class="py-2">
        <input
          ref="file"
          accept=".png,.jpeg,.jpg,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          class="hidden"
          type="file"
          @change="changeImage"
        />
      </div>
    </div>
    <styled-modal v-if="currentImage" class="rounded text-base" :show="showImageModal" @close="showImageModal = false">
      <template #title>{{ currentImage.file_name }}</template>
      <template #content>
        <img
          v-if="currentImage.type === 'image'"
          alt="Media"
          class="m-1 mx-auto block object-cover"
          :src="currentImage.url"
        />
      </template>
      <template #footer>
        <div class="w-full max-w-md justify-end">
          <front-solid-button @click="closeImage">{{ $t('Close') }}</front-solid-button>
        </div>
      </template>
    </styled-modal>
  </div>
</template>
<script>
import FrontMediaIcon from './FrontMediaIcon.vue'
import FrontModal from './FrontModal.vue'
import FrontSolidButton from './FrontSolidButton.vue'
import StyledModal from './StyledModal.vue'
import { AnswerableViewEnum } from '@/Enums/AnswerableEnum'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronRightIcon, PencilSquareIcon, PhotoIcon, TrashIcon } from '@heroicons/vue/24/outline'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    PencilSquareIcon,
    ChevronRightIcon,
    PhotoIcon,
    TrashIcon,
    FrontModal,
    FrontSolidButton,
    FrontMediaIcon,
    StyledModal,
    ...Object.values(AnswerableViewEnum).reduce((components, name) => {
      components[name] = defineAsyncComponent(() => import(`./Answerables/${name}.vue`))
      return components
    }, {}),
  },
  props: {
    formAnswer: Object,
  },
  data() {
    return {
      currentImage: null,
      showImageModal: false,
      notes: this.form?.notes,
    }
  },
  computed: {
    answerComponentName: function () {
      return AnswerableViewEnum[this.formAnswer.form_answerable_type]
    },
  },
  methods: {
    openImage(imageObject) {
      this.currentImage = imageObject
      this.showImageModal = true
    },
    closeImage() {
      this.showImageModal = false
    },
  },
}
</script>
