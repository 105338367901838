<template>
  <content-layout :enrolment-content="enrolmentContent" :is-last="isLast">
    <div>
      <p>{{ enrolmentContent.content.description }}</p>
    </div>
    <template #actions>
      <a
        :class="{
          'btn-red-gradient': isTenantWorksite && !enrolmentContent.finished_at,
          'tenant-btn-1': !isTenantWorksite && !enrolmentContent.finished_at,
          'btn-red-outline': isTenantWorksite && enrolmentContent.finished_at,
          'tenant-btn-1-outline': !isTenantWorksite && enrolmentContent.finished_at,
          disabled: isDisabled,
        }"
        :disabled="isDisabled"
        :href="!isDisabled ? enrolmentContent.content.route : '#'"
        :target="!isDisabled ? '_blank' : null"
      >
        {{ $t('Open') }}
      </a>
    </template>
  </content-layout>
</template>
<script>
import ContentLayout from './ContentLayout.vue'
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  components: { ContentLayout },
  props: {
    enrolment: Object,
    enrolmentContent: Object,
    isLast: Boolean,
  },
  computed: {
    isTenantWorksite,
    isDisabled() {
      return !this.enrolmentContent.prerequisite_complete || !this.enrolmentContent.content.route
    },
  },
}
</script>
