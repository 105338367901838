<template>
  <div class="w-full">
    <form @submit.prevent="updateCertificateInformation">
      <div class="-mb-8 -mr-6 flex flex-wrap p-6 lg:p-10">
        <div class="w-1/2 pb-8 pr-6">
          <front-text-input
            v-model="certificateInfoForm.employer_name"
            :error="certificateInfoForm.errors.employer_name"
            :label="$t('Employer Name')"
          />
        </div>
        <div class="w-1/2 pb-8 pr-6">
          <front-text-input
            v-model="certificateInfoForm.employer_address"
            :error="certificateInfoForm.errors.employer_address"
            :label="$t('Employer Address')"
          />
        </div>
      </div>
      <div class="flex items-center justify-between border-t border-gray-200 px-6 py-5 lg:px-10 lg:py-8">
        <front-loading-button :loading="certificateInfoForm.processing" type="submit">
          {{ $t('Update Certificate') }}
        </front-loading-button>
      </div>
    </form>
  </div>
</template>

<script>
import SettingsLayout from './SettingsLayout.vue'
import FrontLayoutVue from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    FrontLoadingButton,
    FrontTextInput,
  },
  layout: [FrontLayoutVue, SettingsLayout],
  props: {
    employer: Object,
  },
  data() {
    return {
      certificateInfoForm: useForm(
        {
          employer_name: this.employer ? this.employer.name : null,
          employer_address: this.employer ? this.employer.address : null,
        },
        'certificateInfoForm'
      ),
    }
  },
  methods: {
    updateCertificateInformation() {
      this.certificateInfoForm.put(this.route('front.settings.certificate-information.update'))
    },
  },
}
</script>
