<template>
  <front-layout :title="$t('Edit Folder')">
    <div class="item-center mb-8 flex max-w-2xl justify-between">
      <breadcrumb
        class="font-heading text-2xl font-semibold"
        :name="form.name ?? $t('Edit')"
        :previous-name="$t('Folders')"
        :previous-url="route('front.admin.folders')"
      />
      <div class="flex">
        <button v-if="folder.deleted_at" class="hover:underline" tabindex="-1" type="button" @click="restore">
          {{ $t('Restore') }}
        </button>
        <button v-else class="hover:underline" tabindex="-1" type="button" @click="destroy">
          {{ $t('Archive') }}
        </button>
        <front-loading-button class="ml-4" :disabled="!form.isDirty" :loading="form.processing" @click="submit">
          {{ $t('Save') }}
        </front-loading-button>
      </div>
    </div>
    <form @submit.prevent="submit">
      <!-- Folder Name -->
      <div class="max-w-2xl overflow-hidden rounded bg-white shadow">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <front-text-input v-model="form.name" :error="form.errors.name" :label="$t('Folder Name')" />
          </div>
        </div>
      </div>
      <!-- Files -->
      <div class="flex max-w-2xl items-center justify-between pb-6 pt-8">
        <span class="font-heading text-xl">Files</span>
        <front-outline-button-link class="flex" :href="route('front.admin.files.create', folder)">
          <icon class="fill-qualify-red mr-2 h-4 w-4 flex-shrink-0" name="plus" />
          Add File
        </front-outline-button-link>
      </div>
      <div v-if="files.length > 0">
        <div v-for="file in files" :key="file.id">
          <div class="flex max-w-2xl flex-col overflow-hidden bg-white p-6 shadow">
            <div class="flex items-center">
              <div class="flex w-full flex-col pl-4 text-lg">
                <span>{{ file.name }}</span>
                <span class="text-sm">Uploaded on {{ file.created_at }}</span>
              </div>
              <div class="flex items-center justify-end">
                <a
                  class="flex justify-end text-gray-500 hover:text-gray-600"
                  :href="route('front.admin.files.edit', [folder, file])"
                >
                  Edit
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="max-w-2xl bg-white py-8 text-center shadow">No files found.</div>
    </form>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontOutlineButtonLink from '@/Shared/FrontOutlineButtonLink.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import Icon from '@/Shared/Icon.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    FrontLayout,
    FrontLoadingButton,
    FrontOutlineButtonLink,
    FrontTextInput,
    Icon,
  },
  props: {
    folder: Object,
    files: Object,
  },
  data() {
    return {
      form: useForm({
        name: this.folder.name,
      }),
    }
  },
  methods: {
    submit() {
      this.form.put(this.route('front.admin.folders.update', this.folder))
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to archive this folder?'))) {
        this.$inertia.post(this.route('front.admin.folders.archive', this.folder))
      }
    },
    restore() {
      if (confirm(this.$t('Are you sure you want to restore this folder?'))) {
        this.$inertia.post(this.route('front.admin.folders.restore', this.folder))
      }
    },
  },
}
</script>
