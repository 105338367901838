<template>
  <front-layout title="Current Subscription">
    <div class="flex flex-col xl:flex-row xl:justify-between">
      <div class="sm:no-flex mb-4 flex w-full items-center justify-between">
        <breadcrumb :name="$t('Manage Subscriptions')" />
      </div>
    </div>

    <div class="flex w-full flex-col justify-between gap-x-6 md:flex-row">
      <main class="w-full space-y-12">
        <section>
          <subscriptions :plans="subscriptionPlans" :subscriptions="subscriptions" />
        </section>

        <section>
          <h2 class="font-heading text-2xl font-semibold">{{ $t('Payment Methods') }}</h2>
          <div class="mt-2">
            {{
              $t('Add or update your payment methods. You can also set a default payment method for your organization.')
            }}
          </div>
          <payment-methods
            :default-payment-method="defaultPaymentMethod"
            :organization="organization"
            :payment-methods="paymentMethods"
          />
        </section>

        <section>
          <h2 class="font-heading text-2xl font-semibold">{{ $t('Invoices') }}</h2>
          <upcoming-invoice
            v-if="upcomingInvoice && defaultPaymentMethod"
            :payment-method="defaultPaymentMethod"
            :upcoming-invoice="upcomingInvoice"
          />
          <invoices :invoice-data="invoices" />
        </section>
      </main>
    </div>
  </front-layout>
</template>

<script>
import Invoices from './Invoices.vue'
import PaymentMethods from './PaymentMethods.vue'
import Subscriptions from './Subscriptions.vue'
import UpcomingInvoice from './UpcomingInvoice.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'

export default {
  components: { Invoices, UpcomingInvoice, Subscriptions, PaymentMethods, Breadcrumb, FrontLayout },
  props: {
    organization: Object,
    defaultPaymentMethod: Object,
    paymentMethods: Array,
    subscriptions: Array,
    subscriptionPlans: Array,
    invoices: Array,
    upcomingInvoice: Object,
  },
  computed: {
    breadCrumbs() {
      return [
        {
          name: this.$t('Subscriptions'),
        },
      ]
    },
  },
}
</script>
