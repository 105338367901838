<template>
  <div class="w-full" @click="onClickInside">
    <div
      class="text-m z-1 relative flex flex-row border-x border-b border-ts-front-border-light bg-white text-gray-400"
    >
      <button class="absolute -right-8 h-full w-5 cursor-pointer text-gray-500" @click="deleteQuestion">
        <TrashIcon aria-hidden="true" />
      </button>
      <div class="flex w-full">
        <div class="flex flex-1 items-center border-r">
          <Bars3Icon
            aria-hidden="true"
            class="handle z-20 mx-3 flex h-6 w-6 flex-none cursor-move items-center justify-center text-gray-400"
          />
          <div class="flex w-full items-center">
            <div class="w-3">
              <span v-if="questionData.options.is_required">*</span>
            </div>
            <div class="flex-1">
              <front-text-input
                v-model="questionData.title"
                class="placeholder-shown:text-bold placeholder-shown:text-m m-0 mt-0 border-0 bg-white px-2 py-3 ring-0 ring-inset placeholder-shown:border-0 placeholder-shown:ring-0 focus:outline-none focus:ring-2 focus:ring-qualify-red-600"
                maxlength="255"
                :placeholder="$t('Type question')"
              />
            </div>
          </div>
        </div>
        <div class="basis-96">
          <Listbox>
            <div class="bg-white">
              <div class="max-w-7xl mx-auto">
                <ListboxButton
                  class="w-full cursor-default bg-white px-2 py-3 text-left text-gray-700 ring-inset focus:outline-none focus:ring-2 focus:ring-qualify-red-600 sm:px-4 sm:text-sm sm:leading-6"
                >
                  <span v-if="selectedOption" class="flex items-center">
                    <span v-if="selectedOption.type === 'form-select'" class="block">
                      <SelectName :model-value="currentSelect" />
                    </span>
                    <div v-else class="inline-flex items-center justify-center">
                      <div class="mr-3 flex h-6 w-6 flex-none items-center justify-center rounded-full bg-gray-200">
                        <component :is="selectedOption.icon" class="h-4 w-4" />
                      </div>
                      <span class="block">{{ selectedOption.name }}</span>
                    </div>
                  </span>
                  <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDownIcon aria-hidden="true" class="h-5 w-5 text-gray-400" />
                  </span>
                </ListboxButton>
              </div>
            </div>
            <transition
              enter-active-class="transition ease-out duration-200"
              enter-from-class="opacity-0 -translate-y-1"
              enter-to-class="opacity-100 translate-y-0"
              leave-active-class="transition ease-in duration-150"
              leave-from-class="opacity-100 translate-y-0"
              leave-to-class="opacity-0 -translate-y-1"
            >
              <ListboxOptions
                class="absolute bottom-0 right-0 z-30 max-h-96 w-full overflow-hidden border-t bg-white shadow-lg focus:outline-none"
              >
                <div class="mx-auto flex">
                  <!-- Left side pane -->
                  <div class="flex max-h-96 flex-1 flex-col border-r py-2">
                    <div>
                      <front-search-filter
                        v-model="search"
                        class="static flex w-full flex-col px-4 py-2"
                        :filter-show="false"
                      />
                    </div>
                    <div class="flex-1 overflow-y-auto">
                      <div class="flex items-center justify-between px-4 py-2">
                        <h3 class="text-sm text-gray-500">{{ $t('Multiple choice responses') }}</h3>
                        <h3
                          class="cursor-pointer text-sm text-qualify-red-500 hover:underline"
                          @click="createMultipleChoice"
                        >
                          {{ $t('+ Responses') }}
                        </h3>
                      </div>
                      <div class="overflow-auto">
                        <ListboxOption
                          v-for="response in filteredFormTemplateSelects"
                          :key="response.id"
                          v-slot="{ active }"
                          as="template"
                          :value="response.type"
                        >
                          <li
                            :class="[
                              'relative cursor-pointer select-none px-4 py-2',
                              active ? 'bg-gray-50' : '',
                              'relative cursor-pointer select-none px-4 py-2',
                            ]"
                            @click="onClickOptionSelect(response)"
                          >
                            <div class="flex items-center justify-between py-1">
                              <SelectName :model-value="response" />
                              <PencilIcon
                                aria-hidden="true"
                                class="h-4 w-4 cursor-pointer text-gray-300 hover:text-gray-600"
                                @click.stop="openMultipleChoiceModal(response)"
                              />
                            </div>
                          </li>
                        </ListboxOption>
                      </div>
                      <template v-if="!isGlobalTemplate">
                        <hr class="my-2" />
                        <div class="flex items-center justify-between px-4 py-2">
                          <h3 class="text-sm text-gray-500">{{ $t('Global Response Sets') }}</h3>
                          <h3
                            class="cursor-pointer text-sm text-qualify-red-500 hover:underline"
                            @click="createResponseSet"
                          >
                            {{ $t('+ Create') }}
                          </h3>
                        </div>
                        <div class="overflow-auto">
                          <ListboxOption
                            v-for="response in filteredGlobalFormTemplateSelects"
                            :key="response.id"
                            v-slot="{ active, selected }"
                            as="template"
                            :value="response.type"
                          >
                            <li
                              :class="[
                                'relative cursor-pointer select-none px-4 py-2',
                                active ? 'bg-gray-50' : '',
                                'relative cursor-pointer select-none px-4 py-2',
                              ]"
                              @click="onClickOptionSelect(response)"
                            >
                              <div
                                class="flex items-center justify-between py-1"
                                :class="[selected ? 'font-semibold' : 'font-normal']"
                              >
                                <p class="text-sm font-semibold">{{ response.name ?? $t('Untitled Select Option') }}</p>
                                <PencilIcon
                                  aria-hidden="true"
                                  class="h-4 w-4 cursor-pointer text-gray-300 hover:text-gray-600"
                                  @click.stop="openResponseSetModal(response)"
                                />
                              </div>
                            </li>
                          </ListboxOption>
                        </div>
                      </template>
                    </div>
                  </div>
                  <!-- Right side pane -->
                  <div class="max-h-96 basis-96 overflow-auto py-2">
                    <div>
                      <h3 class="px-4 py-2 text-sm font-medium leading-6 text-gray-500">{{ $t('Other responses') }}</h3>
                      <ListboxOption
                        v-for="response in filteredResponses"
                        :key="response.id"
                        v-slot="{ active, selected }"
                        as="template"
                        :value="response.type"
                      >
                        <li
                          v-show="response.type !== 'form-select'"
                          :class="[
                            selected ? 'bg-gray-50 text-gray-700' : '',
                            active ? 'bg-gray-50 text-gray-700' : '',
                            'relative cursor-pointer select-none px-4 py-2 text-gray-700',
                          ]"
                          @click="onClickOptionSelect(response)"
                        >
                          <div class="flex items-center">
                            <div
                              class="mr-3 flex h-6 w-6 flex-none items-center justify-center rounded-full bg-gray-200"
                            >
                              <component :is="response.icon" aria-hidden="true" class="h-4 w-4 text-gray-700" />
                            </div>
                            <span class="'block text-sm" :class="[selected ? 'font-semibold' : 'font-normal']">
                              {{ response.name }}
                            </span>
                          </div>

                          <span
                            v-if="selected"
                            class="absolute inset-y-0 right-0 flex items-center pr-4"
                            :class="[active ? 'text-qualify-red-600' : 'text-qualify-red-600']"
                          >
                            <CheckIcon aria-hidden="true" class="h-5 w-5" />
                          </span>
                        </li>
                      </ListboxOption>
                    </div>
                  </div>
                </div>
              </ListboxOptions>
            </transition>
          </Listbox>
        </div>
      </div>
    </div>
    <div
      v-show="isActive"
      class="flex flex-row border-x border-b border-ts-front-border-light bg-gray-50 px-1 py-1 text-sm text-gray-700"
    >
      <component
        :is="selectedOption.component"
        v-if="selectedOption?.component"
        v-model:flagged-choices="questionData.flaggedChoices"
        v-model:logics="questionData.logics"
        v-model:options="questionData.options"
        :choices="formContent?.select?.choices"
        :form-content-options="formContent.options"
      />
    </div>
    <MultipleChoiceModal
      v-if="showMultipleChoiceModal"
      :form-content="formContent"
      :show="showMultipleChoiceModal"
      :value="selectedMultipleChoice"
      @close="closeMultipleChoiceModal"
    />
    <ResponseSetModal
      v-if="showResponseSetModal"
      :form-content="formContent"
      :show="showResponseSetModal"
      :value="selectedResponseSet"
      @close="closeResponseSetModal"
    />
  </div>
</template>
<script>
import MultipleChoiceModal from './MultipleChoiceModal.vue'
import FormCheckbox from './Questionables/FormCheckbox.vue'
import FormDateTime from './Questionables/FormDateTime.vue'
import FormDocumentNumber from './Questionables/FormDocumentNumber.vue'
import FormLocation from './Questionables/FormLocation.vue'
import FormMedia from './Questionables/FormMedia.vue'
import FormNumber from './Questionables/FormNumber.vue'
import FormPerson from './Questionables/FormPerson.vue'
import FormSelect from './Questionables/FormSelect.vue'
import FormSlider from './Questionables/FormSlider.vue'
import FormText from './Questionables/FormText.vue'
import ResponseSetModal from './ResponseSetModal.vue'
import { FormQuestionOptions } from '@/Enums/QuestionableEnums.js'
import SelectScope from '@/Enums/SelectScope.js'
import SelectName from '@/Shared/Answerables/SelectName.vue'
import FrontSearchFilter from '@/Shared/FrontSearchFilter.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import ActiveQuestionStore from '@/Stores/ActiveQuestion.js'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import {
  AdjustmentsHorizontalIcon,
  Bars3Icon,
  CalendarIcon,
  CheckIcon,
  ChevronDownIcon,
  EllipsisHorizontalIcon,
  HashtagIcon,
  MapPinIcon,
  PencilIcon,
  PhotoIcon,
  PlusIcon,
  TrashIcon,
  UserIcon,
} from '@heroicons/vue/20/solid'

export default {
  components: {
    AdjustmentsHorizontalIcon,
    Bars3Icon,
    CalendarIcon,
    CheckIcon,
    ChevronDownIcon,
    EllipsisHorizontalIcon,
    HashtagIcon,
    MapPinIcon,
    PhotoIcon,
    PlusIcon,
    UserIcon,
    FormText,
    FormNumber,
    FormSlider,
    FormDocumentNumber,
    FormPerson,
    FormDateTime,
    FormSelect,
    FormMedia,
    FormCheckbox,
    FormLocation,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    TrashIcon,
    PencilIcon,
    SelectName,
    MultipleChoiceModal,
    ResponseSetModal,
    FrontSearchFilter,
    FrontTextInput,
  },
  inject: {
    updateFormContent: {},
    deleteFormContent: {},
    isGlobalTemplate: {
      default: () => false,
    },
  },
  props: {
    formContent: Object,
    common: Object,
  },
  data() {
    return {
      SCOPE_LEVEL: SelectScope,
      questionData: {
        title: this.formContent.title,
        type: this.formContent.type,
        options: this.formContent.options,
        select_id: this.formContent.select_id,
        flaggedChoices: this.formContent.select?.flaggedChoices,
        logics: this.formContent.logics,
      },
      responses: FormQuestionOptions,
      search: null,
      selectOptionsForm: this.$inertia.form({
        id: null,
        name: null,
        scope_level: null,
        choices: [],
      }),
      showMultipleChoiceModal: false,
      showResponseSetModal: false,
      selectedMultipleChoice: null,
      selectedResponseSet: null,
      ActiveQuestionStore,
    }
  },
  computed: {
    selectedTypeName() {
      return this.responses.find((response) => response.type === this.questionData.type)?.name
    },
    filteredResponses() {
      if (this.search) {
        const regex = new RegExp(this.search, 'i')
        return this.responses.filter((response) => response.name.search(regex) >= 0)
      } else {
        return this.responses
      }
    },
    filteredFormTemplateSelects() {
      if (this.search) {
        const regex = new RegExp(this.search, 'i')
        return this.common.formTemplate.selects?.filter((response) => response.name.search(regex) >= 0)
      } else {
        return this.common.formTemplate.selects
      }
    },
    filteredGlobalFormTemplateSelects() {
      if (this.search) {
        const regex = new RegExp(this.search, 'i')
        return this.common.formTemplate.selects_organization?.filter((response) => response.name.search(regex) >= 0)
      } else {
        return this.common.formTemplate.selects_organization
      }
    },
    selectedOption() {
      var el = FormQuestionOptions.find((element) => {
        return element.type === this.questionData.type
      })
      return el
    },
    currentSelect() {
      if (this.questionData.select_id === null) {
        return null
      }
      if (this.questionData.select_id === this.formContent.select_id) {
        return this.formContent.select
      }
      return this.common.formTemplate.selects.find((select) => select.id === this.questionData.select_id)
    },
    isActive() {
      return this.ActiveQuestionStore?.id === this.formContent?.id
    },
  },
  watch: {
    questionData: {
      handler: function () {
        this.update()
      },
      deep: true,
    },
  },
  methods: {
    onClickInside() {
      this.ActiveQuestionStore.id = this.formContent.id
    },
    update() {
      const cloneableQuestionData = JSON.parse(JSON.stringify(this.questionData))
      this.updateFormContent(this.formContent, cloneableQuestionData)
    },
    deleteQuestion() {
      this.deleteFormContent(this.formContent)
    },
    onClickOptionSelect(response) {
      this.questionData.type = response.type
      this.questionData.logics = []
      this.questionData.select_id = response.id
      this.questionData.choices = response.choices
    },
    createMultipleChoice() {
      this.selectedMultipleChoice = null
      this.showMultipleChoiceModal = true
    },
    openMultipleChoiceModal(multipleChoice) {
      this.selectedMultipleChoice = multipleChoice
      this.showMultipleChoiceModal = true
    },
    closeMultipleChoiceModal() {
      this.showMultipleChoiceModal = false
    },
    createResponseSet() {
      this.selectedResponseSet = null
      this.showResponseSetModal = true
    },
    openResponseSetModal(multipleChoice) {
      this.selectedResponseSet = multipleChoice
      this.showResponseSetModal = true
    },
    closeResponseSetModal() {
      this.showResponseSetModal = false
    },
  },
}
</script>
