<template>
  <layout title="Link Location">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('instructor-led-classrooms')">
        {{ $t('Instructor Led Classes') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('instructor-led-classrooms.edit', classroom)">
        {{ classroom.name }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Link Location') }}
    </h1>
    <div class="flex max-w-3xl flex-wrap">
      <div class="w-full lg:w-3/5 xl:w-2/3">
        <div class="overflow-hidden rounded bg-white shadow">
          <form @submit.prevent="submit">
            <div class="w-full">
              <form-section-header>{{ $t('Location') }}</form-section-header>
              <div class="flex flex-wrap p-8">
                <div class="flex w-full justify-between">
                  <label class="form-label" for="location">{{ $t('Location:') }}</label>
                  <label class="form-label">
                    {{ $t('Include Client Sites') }}
                    <input
                      v-model="include_client_sites"
                      name="client_sites"
                      type="checkbox"
                      @change="fetchLocations"
                    />
                  </label>
                </div>

                <select-input v-model="form.location_id" :error="form.errors.location_id" name="location">
                  <option :value="null" />
                  <option v-for="location in locations" :key="location.id" :value="location.id">
                    {{ location.name }} {{ location.client_site ? $t('(Client Site)') : '' }}
                  </option>
                </select-input>
              </div>
            </div>
            <div v-if="form.location_id" class="w-full">
              <form-section-header>{{ $t('Marketplace Integration') }}</form-section-header>
              <div class="-mb-8 -mr-6 flex flex-wrap p-8">
                <div class="w-full pb-8 pr-6">
                  <text-input v-model="form.store_url" :error="form.errors.store_url" :label="$t('Store URL')" />
                </div>
              </div>
              <form-section-header>{{ $t('Reviews.io Integration') }}</form-section-header>
              <div class="-mb-8 -mr-6 flex flex-wrap p-8">
                <notice v-if="!can.has_reviews_io_enabled_in_account">
                  {{ $t("Your account doesn't have the reviews.io integration enabled yet.") }}
                  <InertiaLink class="underline" :href="route('tenants.edit', $page.props.app.tenant)">
                    {{ $t('Click here to do so.') }}
                  </InertiaLink>
                </notice>
                <div v-if="can.has_reviews_io_enabled_in_account" class="w-full">
                  <div class="w-full pb-8 pr-6">
                    <select-input
                      v-model="form.reviews_io_branch_name"
                      :error="form.errors.reviews_io_branch_name"
                      :label="$t('Branch name')"
                    >
                      <option :value="null" />
                      <option v-for="(branch, index) in branches.items" :key="index" :value="branch.name">
                        {{ branch.name }}
                      </option>
                    </select-input>
                  </div>
                  <div class="w-full pb-8 pr-6">
                    <text-input
                      v-model="form.reviews_io_template_id"
                      :error="form.errors.reviews_io_template_id"
                      :label="$t('Template ID')"
                    />
                  </div>
                  <div class="w-full pb-8 pr-6">
                    <checkbox-input
                      v-model="form.reviews_io_send_automatically"
                      :error="form.errors.reviews_io_send_automatically"
                      :label="$t('Automatically request reviews when class is completed')"
                    />
                  </div>
                  <div v-if="form.reviews_io_send_automatically" class="w-full pb-8 pr-6">
                    <text-input
                      v-model="form.reviews_io_send_delay_minutes"
                      :error="form.errors.reviews_io_send_delay_minutes"
                      :label="$t('How many minutes of delay?')"
                    />
                  </div>
                </div>
              </div>
              <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
                <loading-button class="btn-blue" :loading="form.processing" type="submit">
                  {{ $t('Link Location') }}
                </loading-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import CheckboxInput from '@/Shared/CheckboxInput.vue'
import FormSectionHeader from '@/Shared/FormSectionHeader.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import Notice from '@/Shared/Notice.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

const STATUSES = {
  loading: 'LOADING',
  ready: 'READY',
  failed: 'FAILED',
}

export default {
  components: {
    CheckboxInput,
    FormSectionHeader,
    Layout,
    LoadingButton,
    Notice,
    SelectInput,
    TextInput,
  },
  props: {
    classroom: Object,
    locations: Array,
    can: Object,
  },
  data() {
    return {
      status: STATUSES.ready,
      include_client_sites: new URLSearchParams(window.location.search).get('includeClientSites') === 'true',
      form: useForm({
        location_id: null,
        store_url: null,
        reviews_io_branch_name: null,
        reviews_io_template_id: null,
        reviews_io_send_automatically: false,
        reviews_io_send_delay_minutes: 0,
      }),
      branches: {
        status: STATUSES.ready,
        items: null,
      },
    }
  },
  watch: {
    'form.reviews_io_send_automatically'(newValue) {
      if (!newValue) {
        this.form.reviews_io_send_delay_minutes = 0
      }
    },
  },
  mounted() {
    if (this.can.has_reviews_io_enabled_in_account) {
      this.getBranches()
    }
  },
  methods: {
    submit() {
      this.status = STATUSES.loading
      this.form.post(this.route('instructor-led-classrooms.locations.store', [this.classroom]), {
        onSuccess: () => (this.status = STATUSES.ready),
      })
    },
    getBranches() {
      this.branches.status = STATUSES.loading

      axios
        .get(this.route('course_locations.reviews-io-branches.index'))
        .then((response) => {
          this.branches.status = STATUSES.ready
          this.branches.items = response.data
        })
        .catch(() => (this.branches.status = STATUSES.failed))
    },
    fetchLocations() {
      let route = this.route('instructor-led-classrooms.locations.create', [this.classroom])
      route += this.include_client_sites ? `?includeClientSites=${this.include_client_sites}` : ''

      this.$inertia.visit(route, { only: ['locations'] })
    },
  },
}
</script>
