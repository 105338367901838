<template>
  <layout :title="$t('Triggers')">
    <h1 class="mb-8 text-xl font-bold">{{ $t('Triggers') }}</h1>
    <div class="mb-6 flex items-center justify-between">
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
        <label class="block text-gray-800">{{ $t('Type:') }}</label>
        <select v-model="form.type" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="(value, key) in types" :key="key" :value="key">{{ value }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Events:') }}</label>
        <select v-model="form.onEvent" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="(value, key) in events" :key="key" :value="key">{{ value }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Deleted:') }}</label>
        <select v-model="form.deleted" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="with">{{ $t('With Deleted') }}</option>
          <option value="only">{{ $t('Only Deleted') }}</option>
        </select>
      </search-filter>
      <InertiaLink class="btn-blue" :href="route('triggers.create')">
        <span>{{ $t('Create') }}</span>
        <span class="hidden md:inline">{{ $t('trigger') }}</span>
      </InertiaLink>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow">
      <SimpleTable>
        <template #head>
          <TableHead>{{ $t('Name') }}</TableHead>
          <TableHead>{{ $t('Event') }}</TableHead>
          <TableHead>{{ $t('Type') }}</TableHead>
          <TableHead>{{ $t('Mailchimp Event') }}</TableHead>
          <TableHead />
        </template>
        <TableRow v-if="triggers.data.length === 0">
          <TableData colspan="4">{{ $t('No triggers found.') }}</TableData>
        </TableRow>
        <TableRow
          v-for="trigger in triggers.data"
          :key="trigger.id"
          :clickable="true"
          @click="$inertia.visit(route('triggers.edit', trigger.id))"
        >
          <TableData>
            <div class="flex items-center">
              {{ trigger.name }}
              <deleted-badge v-if="trigger.deleted_at" />
            </div>
          </TableData>
          <TableData>{{ trigger.on_event }}</TableData>
          <TableData>{{ trigger.type }}</TableData>
          <TableData>{{ trigger.mailchimp_event_name }}</TableData>
          <TableData class="w-min border-t px-4 align-middle">
            <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
          </TableData>
        </TableRow>
      </SimpleTable>
    </div>
    <pagination :links="triggers.links" />
  </layout>
</template>

<script>
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
    DeletedBadge,
    Icon,
    Layout,
    Pagination,
    SearchFilter,
  },
  props: {
    triggers: Object,
    filters: Object,
    events: Object,
    types: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        type: this.filters.type,
        onEvent: this.filters.onEvent,
        deleted: this.filters.deleted,
      },
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(this.route('triggers'), Object.keys(query).length ? query : { remember: 'forget' }, {
          preserveState: true,
          replace: true,
        })
      }, 300),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
