<template>
  <layout :title="$t('Requests')">
    <div class="mb-8">
      <breadcrumb :name="$t('Requests')" />
    </div>
    <div class="mb-6 flex items-center justify-between">
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
        <label class="block text-gray-800">{{ $t('Instructor Led Class:') }}</label>
        <select v-model="form.instructor_led_classroom" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="ilc in instructor_led_classrooms" :key="ilc.id" :value="ilc.id">{{ ilc.name }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Access:') }}</label>
        <select v-model="form.access" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="public">{{ $t('Public') }}</option>
          <option value="private">{{ $t('Private') }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Between:') }}</label>
        <date-input v-model="form.local_start_date" class="mt-1 w-full" />
        <date-input v-model="form.local_end_date" class="mt-1 w-full" />
        <label class="mt-4 block text-gray-800">{{ $t('Location:') }}</label>
        <select v-model="form.location" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="location in locations" :key="location.id" :value="location.id">{{ location.name }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Instructor:') }}</label>
        <select v-model="form.instructor" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="instructor in instructors" :key="instructor.id" :value="instructor.id">
            {{ instructor.last_name }}, {{ instructor.first_name }}
          </option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Cancelled:') }}</label>
        <select v-model="form.cancelled" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="only">{{ $t('Cancelled') }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Deleted:') }}</label>
        <select v-model="form.deleted" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="with">{{ $t('With Deleted') }}</option>
          <option value="only">{{ $t('Only Deleted') }}</option>
        </select>
      </search-filter>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow">
      <table class="w-full">
        <thead>
          <tr>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Class') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Date') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('User') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Instructor') }}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-if="requests.data.length === 0">
            <td class="border-t px-6 py-4" colspan="6">{{ $t('No requests found.') }}</td>
          </tr>
          <tr
            v-for="request in requests.data"
            :key="request.id"
            class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
            @click="$inertia.visit(route('requests.show', request.id))"
          >
            <td class="flex border-t px-6 py-4">
              <div v-if="request.student">
                <div class="max-w-xs truncate leading-tight">{{ request.student.class.name }}</div>
                <div class="mt-1 flex whitespace-nowrap text-sm text-gray-600">
                  {{ request.student.class.location_name }}
                </div>
              </div>
              <deleted-badge v-if="request.deleted_at" />
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <div class="flex items-center">
                <span class="m-1 rounded bg-gray-400 px-2 py-1 text-sm text-white">
                  {{ request.student.class.sessions_count }}
                </span>
                <span>{{ request.student.class.dates }}</span>
              </div>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <div class="flex items-center">
                <span v-if="request.student.user">{{ request.student.user.name }}</span>
              </div>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <span>{{ request.instructor.name }}</span>
            </td>
            <td class="w-min border-t px-4 align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination :links="requests.links" />
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import DateInput from '@/Shared/DateInput.vue'
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import _debounce from 'lodash/debounce'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'

export default {
  components: {
    Breadcrumb,
    DateInput,
    DeletedBadge,
    Icon,
    Layout,
    Pagination,
    SearchFilter,
  },
  props: {
    requests: Object,
    courses: Array,
    locations: Array,
    instructors: Array,
    instructor_led_classrooms: Array,
    filters: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        instructor_led_classroom: this.filters.instructor_led_classroom,
        access: this.filters.access,
        local_start_date: this.filters.local_start_date,
        local_end_date: this.filters.local_end_date,
        location: this.filters.location,
        instructor: this.filters.instructor,
        cancelled: this.filters.cancelled,
        deleted: this.filters.deleted,
      },
    }
  },
  watch: {
    form: {
      handler: _debounce(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(this.route('requests'), Object.keys(query).length ? query : { remember: 'forget' }, {
          preserveState: true,
          replace: true,
          only: ['requests'],
        })
      }, 300),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
