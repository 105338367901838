<template>
  <front-layout title="Subscriptions">
    <div class="flex flex-col xl:flex-row xl:justify-between">
      <div class="flex flex-col lg:mb-8 lg:w-1/2">
        <div class="flex items-center space-x-2">
          <breadcrumb
            class="font-heading text-2xl font-semibold"
            :name="subscription_plan.title"
            :previous-name="$t('Subscriptions')"
            :previous-url="route('front.manager.subscriptions')"
          />
        </div>
        <div v-if="subscription_plan.description" class="mb-4 max-w-md pt-2 text-left lg:mb-0">
          <p class="pb-2 leading-normal">{{ subscription_plan.description }}</p>
        </div>
      </div>
      <div v-if="is_subscription_plan_type_training">
        <front-button-link :href="route('front.manager.subscription-plans.enrolment.step1.create', subscription_plan)">
          {{ $t('Assign Training') }}
        </front-button-link>
      </div>
    </div>
    <div class="overflow-x-auto overflow-y-hidden rounded bg-white shadow-lg">
      <table class="front-table">
        <thead>
          <tr>
            <th>{{ $t('Name') }}</th>
            <th>{{ $t('Assigned By') }}</th>
            <th>{{ $t('Assigned At') }}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-if="subscription_plan_accesses.data.length === 0">
            <td class="border-t px-6 py-4" colspan="5">{{ $t('Plan not available.') }}</td>
          </tr>
          <tr v-for="subscriptionPlanAccess in subscription_plan_accesses.data" :key="subscriptionPlanAccess.id">
            <td class="whitespace-nowrap border-t px-6 py-4">
              <div v-if="subscriptionPlanAccess.user" class="flex items-center space-x-2">
                <span>{{ subscriptionPlanAccess.user.name }}</span>
                <icon
                  v-if="subscriptionPlanAccess.user.archived_at"
                  class="mr-2 inline-flex h-3 w-3 flex-shrink-0 fill-gray-400 align-baseline"
                  name="trash"
                  title="Deleted"
                />
              </div>
              <template v-else>
                <dynamic-search-input
                  v-model="users[subscriptionPlanAccess.id]"
                  class="w-full"
                  required
                  :search-by="['name', 'email']"
                  track-by="id"
                  :url="route('front.manager.users.search')"
                >
                  <div v-if="users[subscriptionPlanAccess.id]" class="flex items-center justify-between">
                    <div class="truncate">{{ users[subscriptionPlanAccess.id].name }}</div>
                    <div class="whitespace-nowrap text-xs text-gray-600">
                      {{ users[subscriptionPlanAccess.id].email }}
                    </div>
                  </div>
                  <template #option="{ option, selected }">
                    <div class="flex items-center justify-between">
                      <div>{{ option.name }}</div>
                      <div class="text-xs" :class="{ 'text-gray-600': !selected, 'text-white': selected }">
                        {{ option.email }}
                      </div>
                    </div>
                  </template>
                </dynamic-search-input>
              </template>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <span v-if="subscriptionPlanAccess.assignedBy">{{ subscriptionPlanAccess.assignedBy.name }}</span>
              <span v-else>-</span>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <show-date v-if="subscriptionPlanAccess.assigned_at" :timestamp="subscriptionPlanAccess.assigned_at" />
              <span v-else>-</span>
            </td>
            <td class="w-min">
              <button
                v-if="!subscriptionPlanAccess.user"
                class="font-semibold hover:underline"
                @click="assignUser(subscriptionPlanAccess, users[subscriptionPlanAccess.id])"
              >
                {{ $t('Assign') }}
              </button>
              <button v-else class="font-semibold hover:underline" @click="unassignUser(subscriptionPlanAccess)">
                {{ $t('Unassign') }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <front-pagination :links="subscription_plan_accesses.links" :preserve-scroll="true" />
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import DynamicSearchInput from '@/Shared/DynamicSearchInput.vue'
import FrontButtonLink from '@/Shared/FrontButtonLink.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontPagination from '@/Shared/FrontPagination.vue'
import Icon from '@/Shared/Icon.vue'
import ShowDate from '@/Shared/ShowDate.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    DynamicSearchInput,
    FrontButtonLink,
    FrontLayout,
    FrontPagination,
    Icon,
    ShowDate,
  },
  props: {
    subscription_plan: Object,
    subscription_plan_accesses: Object,
    is_subscription_plan_type_training: Boolean,
  },
  data() {
    let users_list = {}
    this.subscription_plan_accesses.data.map((access) => {
      users_list[access.id] = null
    })

    return {
      users: users_list,
      form: useForm({
        user_id: null,
      }),
    }
  },
  methods: {
    assignUser(subscriptionPlanAccess, user) {
      if (!user) {
        return alert('Please select a user to assign.')
      }

      this.form.user_id = user.id
      this.form.post(this.route('front.manager.subscription-plan-access.assign', subscriptionPlanAccess.id))
      this.form.reset()
    },
    unassignUser(subscriptionPlanAccess) {
      this.form.delete(this.route('front.manager.subscription-plan-access.assign.delete', subscriptionPlanAccess.id))
    },
  },
}
</script>
