<template>
  <div v-if="value === 1" :class="`${cssClasses} bg-blue-500 bg-opacity-8 text-blue-900`">
    {{ $t('Low') }}
  </div>
  <div v-else-if="value === 2" :class="`${cssClasses} bg-yellow-500 bg-opacity-8 text-yellow-500`">
    {{ $t('Medium') }}
  </div>
  <div v-else-if="value === 3" :class="`${cssClasses} bg-orange-500 bg-opacity-8 text-ts-orange-500`">
    {{ $t('High') }}
  </div>
  <div v-else-if="value === 4" :class="`${cssClasses} text-bold bg-red-500 bg-opacity-8 text-ts-red-500`">
    {{ $t('Critical') }}
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      cssClasses: 'inline-block px-1 py-px rounded text-center',
    }
  },
}
</script>
