<template>
  <layout :title="$t('Reports')">
    <h1 class="mb-8 text-xl font-bold">{{ $t('Reports') }}</h1>
    <div class="grid grid-cols-3 gap-8">
      <InertiaLink class="rounded bg-white p-4 shadow hover:shadow-lg" :href="route('reports.locations')">
        {{ $t('Course Enrolment By Locations') }}
      </InertiaLink>
      <InertiaLink class="rounded bg-white p-4 shadow hover:shadow-lg" :href="route('reports.days')">
        {{ $t('Course Enrolment By Days') }}
      </InertiaLink>
      <InertiaLink class="rounded bg-white p-4 shadow hover:shadow-lg" :href="route('enrolments')">
        {{ $t('Enrolments') }}
      </InertiaLink>
      <InertiaLink class="rounded bg-white p-4 shadow hover:shadow-lg" :href="route('reports.instructors.hours')">
        {{ $t('Paid Hours') }}
      </InertiaLink>
      <InertiaLink class="rounded bg-white p-4 shadow hover:shadow-lg" :href="route('certificate-templates')">
        {{ $t('Create Certificate Templates') }}
      </InertiaLink>
      <InertiaLink class="rounded bg-white p-4 shadow hover:shadow-lg" :href="route('reports.subscriptions.index')">
        {{ $t('Subscription Reports') }}
      </InertiaLink>
      <InertiaLink class="rounded bg-white p-4 shadow hover:shadow-lg" :href="route('live-dashboard')">
        {{ $t('Live Classes') }}
      </InertiaLink>
    </div>
  </layout>
</template>
