<template>
  <div class="relative h-screen w-screen">
    <button
      class="fixed flex items-center rounded-full px-3 py-2 text-xs text-gray-300"
      style="right: 20px; top: 20px; z-index: 99999"
      @click="$inertia.get(route('front.contents', enrolment))"
    >
      <icon class="block h-3 w-3 fill-current" name="close" />
      <span class="ml-1">{{ $t('Close') }}</span>
    </button>
    <iframe class="absolute left-0 top-0 h-full w-full" :src="file" />
  </div>
</template>

<script>
import Icon from '@/Shared/Icon.vue'

export default {
  components: {
    Icon,
  },
  props: {
    enrolment: Number,
    file: String,
  },
}
</script>
