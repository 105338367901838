<template>
  <front-layout :title="$t('Record Certifications')">
    <div class="mb-8">
      <breadcrumb
        :name="$t('Record Certifications')"
        :previous-name="$t('Users')"
        :previous-url="route('front.manager.users.show', user.id)"
      />
    </div>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <front-select-input
              v-model="form.training_record_template_id"
              :disabled="!training_record_templates"
              :error="form.errors.training_record_template_id"
              :label="$t('Training Record')"
            >
              <option :value="null" />
              <option
                v-for="training_record_template in training_record_templates"
                :key="training_record_template.id"
                :value="training_record_template.id"
              >
                {{ training_record_template.name }}
              </option>
            </front-select-input>
          </div>
          <div class="w-full pb-8 pr-6">
            <front-date-input
              v-model="form.completed_at"
              :error="form.errors.completed_at"
              :label="$t('Completed At')"
              min-year="2011"
              :placeholder="$t('Select Completion Date')"
            />
          </div>
          <div class="w-full pb-8 pr-6">
            <span class="text-xl">{{ $t('Training Documents') }}</span>
            <div class="flex items-center justify-between pb-3 pt-6">
              <div class="flex flex-col">
                <span class="mb-2 font-semibold">{{ $t('Record of Completion') }}</span>
                <span v-if="form.primary_file && form.primary_file.name" class="flex items-center">
                  <span class="mr-2">{{ form.primary_file.name }}</span>
                  <button @click="form.primary_file = null">
                    <icon class="h-4 w-4 fill-gray-600 hover:fill-gray-800" name="close" />
                  </button>
                </span>
                <span v-else>{{ $t('No file selected.') }}</span>
                <div v-if="form.errors.primary_file" class="form-error text-ts-front-label-error">
                  {{ form.errors.primary_file }}
                </div>
              </div>
              <div class="">
                <label class="rounded-full border-4 px-6 py-3 font-semibold" for="primary-file">
                  {{ form.primary_file ? $t('Replace Document') : $t('Upload Document') }}
                </label>
                <input
                  id="primary-file"
                  accept=".pdf,.jpg,.jpeg,.png,.gif,.xlsx,.docx"
                  style="display: none"
                  type="file"
                  @input="form.primary_file = $event.target.files[0]"
                />
              </div>
            </div>
            <div class="flex items-center justify-between py-3">
              <div class="flex flex-col">
                <span class="mb-2 font-semibold">{{ $t('Additional Record') }}</span>
                <span v-if="form.secondary_file && form.secondary_file.name" class="flex items-center">
                  <span class="mr-2">{{ form.secondary_file.name }}</span>
                  <button @click="form.secondary_file = null">
                    <icon class="h-4 w-4 fill-gray-600 hover:fill-gray-800" name="close" />
                  </button>
                </span>
                <span v-else>{{ $t('No file selected.') }}</span>
                <div v-if="form.errors.secondary_file" class="form-error text-ts-front-label-error">
                  {{ form.errors.secondary_file }}
                </div>
              </div>
              <div class="">
                <label class="rounded-full border-4 px-6 py-3 font-semibold" for="secondary-file">
                  {{ form.secondary_file ? 'Replace Document' : 'Upload Document' }}
                </label>
                <input
                  id="secondary-file"
                  accept=".pdf,.jpg,.jpeg,.png,.gif,.xlsx,.docx"
                  style="display: none"
                  type="file"
                  @input="form.secondary_file = $event.target.files[0]"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 px-8 py-4">
          <front-loading-button :loading="form.processing" type="submit">{{ $t('Save') }}</front-loading-button>
        </div>
      </form>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontDateInput from '@/Shared/FrontDateInput.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontSelectInput from '@/Shared/FrontSelectInput.vue'
import Icon from '@/Shared/Icon.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    FrontDateInput,
    FrontLayout,
    FrontLoadingButton,
    FrontSelectInput,
    Icon,
  },
  props: {
    user: Object,
    training_record_templates: Array,
  },
  data() {
    return {
      form: useForm(
        {
          training_record_template_id: null,
          completed_at: null,
          primary_file: null,
          secondary_file: null,
        },
        'TrainingRecordsCreate'
      ),
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('front.manager.training-records.store', this.user.id))
    },
  },
}
</script>
