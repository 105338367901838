<template>
  <front-layout :title="$t('Start Course')">
    <div class="mb-8">
      <breadcrumb
        :name="$t('My Course Enrolments')"
        :previous-name="$t('Courses')"
        :previous-url="route('front.courses')"
      />
    </div>
    <div class="max-w-lg rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="overflow-x-auto">
          <table class="w-full">
            <thead>
              <tr>
                <th class="whitespace-nowrap p-6 text-left font-bold" style="min-width: 220px">{{ $t('Course') }}</th>
                <th class="w-min whitespace-nowrap p-6 font-bold">{{ $t('Available Enrolments') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="courses.length === 0">
                <td class="border-t border-gray-100 px-6 py-6 text-center" colspan="4">
                  {{ $t('You have no available enrolments.') }}
                </td>
              </tr>
              <tr v-for="course in courses" :key="course.id" class="pl-6">
                <td class="border-t border-gray-100 px-6 py-6">
                  <div class="items-left flex flex-col">
                    <div class="flex items-center">
                      <input
                        :id="'course-' + course.id"
                        v-model="form.courses"
                        class="form-checkbox h-4 w-4 rounded-full text-ts-red-500"
                        :class="{ 'form-input.error': form.errors[`courses.${course.id}.id`] }"
                        type="checkbox"
                        :value="course.id"
                        @change="onChange(course.id)"
                      />
                      <label class="ml-2" :for="'course-' + course.id">
                        {{ course.name }}
                        <span v-if="course.has_unfinished_enrolments" class="text-xs italic">
                          ({{ $t('You have an enrolment in progress') }})
                        </span>
                        <template v-if="course.has_subscription_access">
                          <br />
                          <span class="text-xs italic">({{ $t('Subscription') }})</span>
                        </template>
                      </label>
                    </div>
                    <label v-if="form.errors[`courses.${course.id}.id`]" class="form-error mt-2 block">
                      {{ form.errors[`courses.${course.id}.id`] }}
                    </label>
                  </div>
                </td>
                <td class="whitespace-nowrap border-t border-gray-100 px-6 py-2 text-center">
                  {{ course.enrolments_count }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="flex flex-col items-center justify-end border-t border-gray-100 px-2 py-4 md:flex-row md:px-8">
          <template v-if="courses.length > 0">
            <InertiaLink
              v-if="canViewManagementSection"
              class="text-base hover:underline"
              :href="route('front.manager.courses.enrol.step1')"
            >
              {{ $t('Send a Course to Someone Else') }}
            </InertiaLink>
            <InertiaLink
              v-else
              class="text-base hover:underline"
              :href="route('front.enrolment-transfers.step1.create')"
            >
              {{ $t('Send a Course to Someone Else') }}
            </InertiaLink>
          </template>
          <front-loading-button class="ml-4 mt-4 md:mt-0" :loading="form.processing" type="submit">
            {{ $t('Start Course') }}
          </front-loading-button>
        </div>
      </form>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    FrontLayout,
    FrontLoadingButton,
  },
  props: {
    courses: Array,
  },
  data() {
    return {
      form: useForm({
        courses: [],
      }),
    }
  },
  computed: {
    canViewManagementSection: function () {
      return (
        this.$page.props.auth.user.organization &&
        ['admin', 'manager', 'groupManager', 'locationManager'].includes(this.$page.props.auth.user.organization_role)
      )
    },
  },
  methods: {
    submit() {
      this.form
        .transform((data) => {
          var tranformedData = {}
          data.courses.forEach((courseId) => {
            tranformedData[courseId] = { id: courseId }
          })
          return { courses: tranformedData }
        })
        .post(this.route('front.courses.enrol.step1.store'))
    },
    onChange(courseId) {
      this.form.clearErrors(`courses.${courseId}.id`)
    },
  },
}
</script>
