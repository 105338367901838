<template>
  <div class="flex w-full flex-col items-center">
    <div class="ml-3 flex w-full items-center">
      <button class="mr-3 flex items-center justify-center" @click="addLogic">
        <Icon class="mr-2 h-5 w-5 fill-current text-gray-500" name="preferences" />
        <span>{{ $t('Add logic') }}</span>
      </button>
      <div class="mr-3 text-sm">
        <front-checkbox-input v-model="optionsData.is_required" :label="$t('Required')" />
      </div>
      <div class="mr-3">
        <div class="inline-flex items-center">
          <label class="text-m">{{ $t('Format') }}:</label>
          <Listbox as="div" class="relative ml-2">
            <ListboxButton class="cursor-pointer text-qualify-red-600 underline focus:outline-none">
              {{ optionsData.format_type }}
            </ListboxButton>

            <transition
              leave-active-class="transition ease-in duration-100"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <div>
                <ListboxOptions
                  class="absolute z-30 max-h-60 overflow-auto border bg-white text-base shadow focus:outline-none sm:text-sm"
                >
                  <ListboxOption
                    v-for="(val, key, index) in types"
                    :key="index"
                    v-slot="{ active, selected }"
                    as="template"
                    :value="val"
                  >
                    <li
                      :class="[
                        active ? 'bg-qualify-red-600 text-white' : 'text-gray-700',
                        'relative cursor-pointer select-none px-2 py-2 pr-9 sm:px-4',
                      ]"
                      @click="onClickFormatType(val)"
                    >
                      <div class="flex items-center">
                        <span class="'block truncate" :class="[selected ? 'font-semibold' : 'font-normal']">
                          {{ val }}
                        </span>
                      </div>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </div>
            </transition>
          </Listbox>
          <button class="cursor-pointer px-4 text-qualify-red-600 focus:outline-none" @click="openFormModal()">
            {{ numberRangeText }}
          </button>
        </div>
      </div>
    </div>
    <div class="flex w-full items-center">
      <LogicTabs ref="logicTabs" v-model="logicsData">
        <LogicTab
          v-for="(logic, index) in logicsData"
          :id="`tab-${index + 1}`"
          :key="index"
          :name="logicNames[index]"
          @delete="removeLogic(index)"
        >
          {{ $t('If value') }}
          <select v-model="logic.operator" class="ml-2 mr-4">
            <option v-for="(value, key) in operators" :key="key" :value="key">{{ $t(value) }}</option>
          </select>
          <span>
            <input v-model="logic.value_one" placeholder="blank" type="text" />
          </span>
          <span class="pr-2">{{ $t('then') }}</span>
          <span>
            <TriggerAction v-model:action-required="logic.action_required" :logic-name="logicNames[index]" />
            <span class="pr-2">,</span>
            <TriggerEvidence v-model:evidence="logic.evidence" :logic-name="logicNames[index]" />
            <span class="px-2">or</span>
            <TriggerNotify v-model:notify="logic.notify" :logic-name="logicNames[index]" />
          </span>
        </LogicTab>
      </LogicTabs>
    </div>
    <form-modal :show="showFormModal" @close="closeFormModal">
      <template #content>
        <div class="px-4 py-8 sm:px-6">
          <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
            {{ $t('Temp range') }}
          </DialogTitle>
        </div>
        <div class="relative border bg-white px-4 py-10 sm:px-6">
          <div class="text-sm">
            <p>Temp should be</p>
          </div>
          <div class="mt-3 inline-flex items-center">
            <form-dropdown class="mr-2">
              <template #selected>
                <span class="block truncate">{{ temp_range }}</span>
              </template>
              <template #options>
                <ListboxOption
                  v-for="(val, key, index) in tempRanges"
                  :key="index"
                  v-slot="{ active, selected }"
                  as="template"
                  :value="val"
                >
                  <li
                    :class="[
                      active ? 'bg-qualify-red-600 text-white' : 'text-gray-700',
                      'relative cursor-pointer select-none px-2 py-2 pr-9 sm:px-4',
                    ]"
                    @click="temp_range = val"
                  >
                    <div class="flex items-center">
                      <span class="'block truncate" :class="[selected ? 'font-semibold' : 'font-normal']">
                        {{ val }}
                      </span>
                    </div>
                  </li>
                </ListboxOption>
              </template>
            </form-dropdown>
            <front-text-input
              v-if="temp_range !== tempRanges.LESS_THAN_OR_EQUAL_TO"
              v-model="temp_min"
              class="mr-2 hover:border-red-500 focus:border-red-500 focus:outline-none"
              type="number"
            />
            <MinusIcon v-if="temp_range === tempRanges.BETWEEN" aria-hidden="true" class="ml-2 h-5 w-5" />
            <front-text-input
              v-if="temp_range !== tempRanges.GREATER_THAN_OR_EQUAL_TO"
              v-model="temp_max"
              class="mx-2 hover:border-red-500 focus:border-red-500 focus:outline-none"
              type="number"
            />
            <form-dropdown class="ml-4">
              <template #selected>
                <span class="block truncate">{{ temp_unit === 'C' ? '\u2103' : '\u2109' }}</span>
              </template>
              <template #options>
                <ListboxOption
                  v-for="(val, key, index) in tempUnits"
                  :key="index"
                  v-slot="{ active, selected }"
                  as="template"
                  :value="key"
                >
                  <li
                    :class="[
                      active ? 'bg-qualify-red-600 text-white' : 'text-gray-700',
                      'relative cursor-pointer select-none px-2 py-2 pr-9 sm:px-4',
                    ]"
                    @click="temp_unit = key"
                  >
                    <div class="flex items-center">
                      <span class="'block truncate" :class="[selected ? 'font-semibold' : 'font-normal']">
                        {{ val }}
                      </span>
                    </div>
                  </li>
                </ListboxOption>
              </template>
            </form-dropdown>
          </div>
        </div>
        <div class="px-4 py-6 sm:px-6">
          <button
            class="mr-4 inline-flex items-center rounded-md bg-qualify-red-500 px-3 py-3 text-sm font-semibold leading-4 text-white shadow-sm hover:bg-qualify-red-600 focus:outline-none"
            type="button"
            @click="onSave()"
          >
            {{ $t('Save and apply') }}
          </button>
          <button
            class="mr-4 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-3 text-sm font-semibold leading-4 text-qualify-red-500 shadow-sm hover:bg-gray-50 focus:outline-none"
            type="button"
            @click="closeFormModal()"
          >
            {{ $t('Cancel') }}
          </button>
        </div>
      </template>
    </form-modal>
  </div>
</template>
<script>
import FormDropdown from './FormDropdown.vue'
import FormModal from './FormModal.vue'
import LogicTab from './LogicTab.vue'
import LogicTabs from './LogicTabs.vue'
import TriggerAction from './TriggerAction.vue'
import TriggerEvidence from './TriggerEvidence.vue'
import TriggerNotify from './TriggerNotify.vue'
import { getOperators } from '@/Enums/Operators'
import { FormatTypeEnum, TempRangeEnum, TempUnitEnum } from '@/Enums/QuestionableEnums.js'
import FrontCheckboxInput from '@/Shared/FrontCheckboxInput.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import { DialogTitle, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { MinusIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    FormModal,
    FormDropdown,
    MinusIcon,
    DialogTitle,
    LogicTab,
    LogicTabs,
    TriggerAction,
    TriggerEvidence,
    TriggerNotify,
    FrontCheckboxInput,
    FrontTextInput,
  },
  props: {
    options: Object,
    logics: Array,
  },
  emits: ['update:options', 'update:logics'],
  data() {
    return {
      operators: getOperators(['=', '!=']),
      optionsData: {
        is_required: this.options?.is_required ?? false,
        format_type: this.options?.format_type ?? FormatTypeEnum.NUMBER,
        temp_range: this.options?.temp_range ?? TempRangeEnum.BETWEEN,
        temp_unit: this.options?.temp_unit ?? Object.keys(TempUnitEnum)[0],
        temp_min: this.options?.temp_min ?? 0,
        temp_max: this.options?.temp_max ?? 10,
      },
      logicsData: this.logics ?? [],
      types: FormatTypeEnum,
      tempRanges: TempRangeEnum,
      tempUnits: TempUnitEnum,
      showFormModal: false,
      temp_range: this.optionsData?.temp_range ?? TempRangeEnum.BETWEEN,
      temp_unit: this.optionsData?.temp_unit ?? Object.keys(TempUnitEnum)[0],
      temp_min: this.optionsData?.temp_min ?? 0,
      temp_max: this.optionsData?.temp_max ?? 10,
    }
  },
  computed: {
    logicNames() {
      return this.logicsData.map((logic) => {
        return logic ? `value ${this.operators[logic.operator]} ${logic.value_one ?? 'blank'}` : ''
      })
    },
    defaultLogicData() {
      return {
        operator: '=',
        value_one: null,
        action_required: false,
        evidence: {
          notes_required: false,
          media_required: false,
        },
      }
    },
    numberRangeText() {
      if (this.optionsData.format_type === 'Temperature') {
        let text = this.optionsData.temp_range
        const temperatureUnitSymbol = this.tempUnits[this.optionsData.temp_unit] ?? ''
        if (
          this.optionsData.temp_range === this.tempRanges.BETWEEN ||
          this.optionsData.temp_range === this.tempRanges.GREATER_THAN_OR_EQUAL_TO
        ) {
          text += ` ${this.optionsData.temp_min}${temperatureUnitSymbol}`
        }
        if (this.optionsData.temp_range === this.tempRanges.BETWEEN) {
          text += ` -`
        }
        if (
          this.optionsData.temp_range === this.tempRanges.BETWEEN ||
          this.optionsData.temp_range === this.tempRanges.LESS_THAN_OR_EQUAL_TO
        ) {
          text += ` ${this.optionsData.temp_max}${temperatureUnitSymbol}`
        }
        return text
      }
      return null
    },
  },
  watch: {
    optionsData: {
      handler: function () {
        this.$emit('update:options', this.optionsData)
      },
      deep: true,
    },
    logicsData: {
      handler: function () {
        this.$emit('update:logics', this.logicsData)
      },
      deep: true,
    },
  },
  methods: {
    onClickFormatType(val) {
      this.optionsData.format_type = val
      if (val === 'Temperature') {
        this.openFormModal()
      }
    },
    openFormModal() {
      this.temp_range = this.optionsData.temp_range
      this.temp_unit = this.optionsData.temp_unit
      this.temp_min = this.optionsData.temp_min
      this.temp_max = this.optionsData.temp_max
      this.showFormModal = true
    },
    closeFormModal() {
      this.showFormModal = false
    },
    onSave() {
      if (this.temp_range === this.tempRanges.GREATER_THAN_OR_EQUAL_TO) {
        this.temp_max = 0
      } else if (this.temp_range === this.tempRanges.LESS_THAN_OR_EQUAL_TO) {
        this.temp_min = 0
      }

      this.optionsData.temp_range = this.temp_range
      this.optionsData.temp_unit = this.temp_unit
      this.optionsData.temp_min = this.temp_min
      this.optionsData.temp_max = this.temp_max

      this.$emit('update:modelValue', this.optionsData)

      this.closeFormModal()
    },
    addLogic() {
      this.$refs.logicTabs.add(this.defaultLogicData)
    },
    removeLogic(index) {
      this.$refs.logicTabs.remove(index)
    },
  },
}
</script>
