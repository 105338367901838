<template>
  <layout :title="$t('Edit Certificate Settings')">
    <breadcrumb-admin :path="breadcrumb" />
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-1/2 pb-8 pr-6">
            <text-input disabled :label="$t('Certificate Name')" :model-value="certificate_template.name" />
          </div>
          <div class="w-1/2 pb-8 pr-6">
            <text-input
              v-model="form.years_valid"
              :error="form.errors.years_valid"
              :label="$t('Years Valid')"
              min="0"
              type="number"
            />
          </div>
        </div>
        <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
          <button v-if="can.delete" class="text-red-500 hover:underline" tabindex="-1" type="button" @click="destroy">
            {{ $t('Delete') }}
          </button>
          <div v-else />
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Update Settings') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import BreadcrumbAdmin from '@/Shared/BreadcrumbAdmin.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    BreadcrumbAdmin,
    Layout,
    LoadingButton,
    TextInput,
  },
  props: {
    user: Object,
    certificate_template: Object,
    can: Object,
  },
  data() {
    return {
      form: useForm({
        years_valid: this.certificate_template.years_valid,
      }),
      breadcrumb: [
        {
          name: this.$t('Users'),
          url: this.route('users'),
        },
        {
          name: this.user.name,
          url: this.route('users.edit', this.user.id),
        },
        {
          name: this.certificate_template.name,
        },
      ],
    }
  },
  methods: {
    submit() {
      this.form.put(this.route('users.certificate-templates.update', [this.user, this.certificate_template]))
    },
    destroy() {
      if (this.can.delete && confirm(this.$t('Are you sure you want to delete this user certificate settings?'))) {
        this.form.reset()
        this.form.delete(this.route('users.certificate-templates.destroy', [this.user, this.certificate_template]))
      }
    },
  },
}
</script>
