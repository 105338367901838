<template>
  <layout :title="$t('Create User')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('worksite-users.index')">
        {{ $t('Back-Office Users') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Create') }}
    </h1>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.first_name" :error="form.errors.first_name" :label="$t('First Name')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.last_name" :error="form.errors.last_name" :label="$t('Last Name')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <select-input v-model="form.timezone" :error="form.errors.timezone" :label="$t('Timezone')">
              <option :value="null" />
              <option v-for="(timezone, key) in timezones" :key="key" :value="timezone">
                {{ timezone }}
              </option>
            </select-input>
          </div>
        </div>
        <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Contact Information') }}</div>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.phone" :error="form.errors.phone" :label="$t('Phone')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.address" :error="form.errors.address" :label="$t('Address')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.city" :error="form.errors.city" :label="$t('City')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <select-input
              v-model="form.region"
              :error="form.errors.region"
              :label="`${$t('Province')}/${$t('State')}`"
              :options="[null, ...regions]"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.postal_code" :error="form.errors.postal_code" :label="$t('Postal code')" />
          </div>
        </div>
        <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Login') }}</div>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.email" :error="form.errors.email" :label="$t('Email')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.username" :error="form.errors.username" :label="$t('Username')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input
              v-model="form.password"
              autocomplete="new-password"
              :error="form.errors.password"
              :label="$t('Password')"
              type="password"
            />
          </div>
        </div>
        <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Back-Office Access') }}</div>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <select-input
              v-if="can.makeAdmin || can.makeSupport || can.makeSuper"
              v-model="form.access"
              :error="form.errors.access"
              :label="$t('Admin access')"
            >
              <option :value="null" />
              <option v-if="can.makeAdmin" value="Admin">{{ $t('Admin') }}</option>
              <option v-if="can.makeSupport" value="Support">{{ $t('Support') }}</option>
              <option v-if="can.makeSuper" value="Super">{{ $t('Super') }}</option>
            </select-input>
          </div>
          <div v-if="can.makeTenantOwner && ['Super', 'Admin'].includes(form.access)" class="w-full pb-8 pr-6 lg:w-1/2">
            <radio-input
              v-model="form.is_tenant_owner"
              :error="form.errors.is_tenant_owner"
              :label="$t('Is an tenant owner')"
              type="radio"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <radio-input
              v-model="form.is_instructor"
              :error="form.errors.is_instructor"
              :label="$t('Is an instructor')"
              type="radio"
            />
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">{{ $t('Create') }}</loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import RadioInput from '@/Shared/RadioInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Layout,
    LoadingButton,
    RadioInput,
    SelectInput,
    TextInput,
  },
  props: {
    can: Object,
    regions: Array,
    timezones: Object,
  },
  data() {
    return {
      form: useForm(
        {
          first_name: null,
          last_name: null,
          email: null,
          username: null,
          phone: null,
          address: null,
          city: null,
          region: null,
          postal_code: null,
          timezone: null,
          password: null,
          access: null,
          is_tenant_owner: false,
          is_instructor: false,
        },
        'BackOfficeUsersCreate'
      ),
    }
  },
  methods: {
    submit() {
      this.form
        .transform((data) => ({
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          username: data.username,
          phone: data.phone,
          address: data.address,
          city: data.city,
          region: data.region,
          postal_code: data.postal_code,
          timezone: data.timezone,
          password: data.password,
          access: data.access,
          is_tenant_owner: ['Super', 'Admin'].includes(data.access) ? data.is_tenant_owner : false,
          is_instructor: data.is_instructor,
        }))
        .post(this.route('worksite-users.store'))
    },
  },
}
</script>
