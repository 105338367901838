<template>
  <div class="h-screen w-screen">
    <DynamicHead :title="$t('Classroom Attendance | :app_name', { app_name: $page.props.app.name })" />
    <div class="flex h-full w-full flex-col items-center justify-center">
      <div class="relative max-w-xl rounded-md bg-white shadow-md">
        <div class="absolute right-2 flex justify-end" :style="{ top: '-2rem' }">
          <button
            class="absolute flex items-center px-3 py-2 text-xs text-ts-blue-500"
            @click="$inertia.visit(route('front.courses'))"
          >
            <icon class="block h-3 w-3 fill-current" name="arrow-left" />
            <span class="ml-1">{{ $t('Back') }}</span>
          </button>
        </div>
        <div class="grid grid-cols-1 gap-4 p-12 lg:grid-cols-3">
          <div class="col-span-1 space-y-4">
            <div class="-mb-3 text-xs text-gray-400">{{ $t('Class Information') }}</div>
            <div class="font-bold">{{ class_.course }}</div>
            <div class="flex flex-col space-y-1 text-xs">
              <div class="flex items-center rounded">
                <icon class="h-4 w-4 flex-shrink-0 fill-ts-red-500 opacity-75" name="calendar" />
                <span class="ml-1 text-gray-400">{{ class_.date }}</span>
              </div>
              <div class="flex items-center rounded">
                <icon class="h-4 w-4 flex-shrink-0 fill-ts-red-500 opacity-75" name="time" />
                <span class="ml-1 text-gray-400">{{ class_.time }}</span>
              </div>
            </div>
            <div>
              <span class="font-bold">{{ $t('Location:') }}</span>
              <span class="text-sm leading-snug text-gray-500">{{ class_.location }}</span>
            </div>
            <div v-if="class_.room">
              <span class="font-bold">{{ $t('Room:') }}</span>
              <span class="text-sm text-gray-500">{{ class_.room }}</span>
            </div>
            <div v-if="class_.instructors">
              <span class="font-bold">{{ $t('Instructors:') }}</span>
              <span class="text-sm text-gray-500">{{ class_.instructors }}</span>
            </div>
          </div>
          <div class="col-span-2">
            <div ref="map" class="w-full" :style="{ height: '400px' }" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicHead from '@/Shared/DynamicHead.vue'
import Icon from '@/Shared/Icon.vue'
import Gmaps from '@/Utils/Gmaps'

export default {
  components: {
    DynamicHead,
    Icon,
  },
  props: {
    class_: Object,
  },
  data() {
    return {
      sending: false,
      google: null,
    }
  },
  async mounted() {
    this.google = await Gmaps()
    var worksite = new this.google.maps.LatLng(this.class_.latitude, this.class_.longitude)
    let map = new this.google.maps.Map(this.$refs.map, {
      center: worksite,
      zoom: 15,
    })
    var marker = new this.google.maps.Marker({
      position: worksite,
      map: map,
    })
    var infoWindow = new this.google.maps.InfoWindow()
    infoWindow.setContent(this.createInfoWindowContent())
    infoWindow.open(map, marker)

    this.google.maps.event.addListener(marker, 'click', function () {
      infoWindow.open(map, marker)
    })
  },
  methods: {
    createInfoWindowContent() {
      return this.class_.location
    },
  },
}
</script>
