<template>
  <layout :title="$t('Create Groups')">
    <breadcrumb-admin :path="breadcrumbs" />
    <div class="max-w-sm overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" />
          </div>
          <div class="w-full pb-8 pr-6">
            <textarea-input v-model="form.description" :error="form.errors.description" :label="$t('Description')" />
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Create Group') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import BreadcrumbAdmin from '@/Shared/BreadcrumbAdmin.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    BreadcrumbAdmin,
    Layout,
    LoadingButton,
    TextInput,
    TextareaInput,
  },
  props: {
    regions: Array,
    organization: Object,
  },
  data() {
    return {
      form: useForm(
        {
          name: null,
          description: null,
          organization_id: this.organization.id,
        },
        'GroupsCreate'
      ),
      breadcrumbs: [
        {
          name: this.$t('Organizations'),
          url: this.route('organizations.index'),
        },
        {
          name: this.organization.name,
          url: this.route('organizations.edit', this.organization),
        },
        {
          name: `${this.$t('Create')}`,
        },
      ],
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('groups.store'))
    },
  },
}
</script>
