<template>
  <div>
    <div class="relative flex">
      <input ref="autocomplete" class="form-input" :placeholder="placeholder" type="text" @keydown.enter.prevent />
      <button
        v-if="showLocation"
        class="absolute right-0 h-full w-10 cursor-pointer overflow-clip rounded-r-md bg-qualify-red-500 hover:bg-qualify-red-600"
        :title="$t('Locate me')"
        @click="onlocatorPressed"
      >
        <ViewfinderCircleIcon aria-hidden="true" class="h-5 w-full text-white" />
      </button>
    </div>
    <div v-if="showMap" id="map" class="mt-4 h-72 w-full" />
  </div>
</template>

<script>
import Gmaps from '@/Utils/Gmaps'
import { ViewfinderCircleIcon } from '@heroicons/vue/20/solid'
import axios from 'axios'

export default {
  components: {
    ViewfinderCircleIcon,
  },
  props: {
    modelValue: Object,
    placeholder: String,
    showMap: {
      type: Boolean,
      default: false,
    },
    showLocation: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      address: {
        street_number: null,
        route: null,
        locality: null,
        administrative_area_level_1: null,
        country: null,
        postal_code: null,
        timeZone: null,
        latitude: null,
        longitude: null,
      },
      componentForm: {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'long_name',
        country: 'short_name',
        postal_code: 'short_name',
      },
      marker: null,
      map: null,
    }
  },
  async mounted() {
    const google = await Gmaps({
      libraries: 'places',
    })

    if (this.showMap) {
      const position = { lat: 56.611, lng: -104.124 }

      this.map = new google.maps.Map(document.getElementById('map'), {
        zoom: 4,
        center: position,
        streetViewControl: false,
        mapTypeControl: false,
      })

      this.marker = new google.maps.Marker({
        map: this.map,
        position: position,
      })
    }

    let autocomplete = new google.maps.places.Autocomplete(this.$refs.autocomplete, {
      componentRestrictions: { country: ['ca', 'us'] },
      types: ['geocode'],
    })

    autocomplete.setFields(['address_component', 'geometry'])
    autocomplete.addListener('place_changed', () => {
      if (this.showMap) {
        this.marker.setVisible(false)
      }

      let place = autocomplete.getPlace()

      for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0]
        if (this.componentForm[addressType]) {
          let val = place.address_components[i][this.componentForm[addressType]]
          this.address[addressType] = val
        }
      }

      this.address.latitude = place.geometry.location.lat()
      this.address.longitude = place.geometry.location.lng()

      if (this.showMap) {
        if (place.geometry.viewport) {
          this.map.fitBounds(place.geometry.viewport)
        } else {
          this.map.setCenter(place.geometry.location)
          this.map.setZoom(10)
        }

        this.marker.setPosition(place.geometry.location)
        this.marker.setVisible(true)
      }

      this.getTimezone().then((timezone) => {
        this.address.timeZone = timezone
        this.$emit('update:modelValue', this.address)
      })
    })
  },
  methods: {
    getTimezone() {
      const timestamp = Math.floor(Date.now() / 1000)

      const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${this.address.latitude},${this.address.longitude}&timestamp=${timestamp}&key=${window.GOOGLEMAPS_API_KEY}`

      return axios
        .get(url, {
          transformRequest: [
            (data, headers) => {
              delete headers['X-Requested-With']
              delete headers['X-Socket-Id']
              return data
            },
          ],
        })
        .then((response) => {
          return response.data.timeZoneId
        })
    },
    onlocatorPressed() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.getStreetAddressFrom(position.coords.latitude, position.coords.longitude)
        },
        (error) => {
          console.log(error.message)
        }
      )
    },
    getStreetAddressFrom(lat, lng) {
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${window.GOOGLEMAPS_API_KEY}`

      axios
        .get(url, {
          transformRequest: [
            (data, headers) => {
              delete headers.common['X-Requested-With']
              delete headers['X-Socket-Id']

              return data
            },
          ],
        })
        .then((response) => {
          let place = response.data.results[0]

          Object.keys(this.address).forEach((k) => (this.address[k] = null))

          for (let i = 0; i < place.address_components.length; i++) {
            let addressType = place.address_components[i].types[0]
            if (this.componentForm[addressType]) {
              let val = place.address_components[i][this.componentForm[addressType]]
              this.address[addressType] = val
            }
          }

          this.address.latitude = place.geometry.location.lat
          this.address.longitude = place.geometry.location.lng

          if (this.showMap) {
            this.map.setCenter(place.geometry.location)
            this.map.setZoom(10)

            this.marker.setPosition(place.geometry.location)
            this.marker.setVisible(true)
          }

          this.$refs.autocomplete.value = place.formatted_address

          this.getTimezone().then((timezone) => {
            this.address.timeZone = timezone
            this.$emit('update:modelValue', this.address)
          })
        })
    },
  },
}
</script>
