<template>
  <div :class="{ 'bg-slate-800': isActive }">
    <InertiaLink
      cache-for="5s"
      class="group:text-white group flex items-center hover:bg-slate-900"
      :class="{
        'text-gray-300': !isActive,
        'py-3 pl-18 text-sm': isSubMenu,
        'px-12 py-5': !isSubMenu,
        'text-white': isActive && $slots.submenu,
        'text-blue-400': isActive && !$slots.submenu,
      }"
      :href="href"
    >
      <icon v-if="icon" class="mr-2 h-4 w-4 fill-current" :name="icon" />
      <div><slot /></div>
    </InertiaLink>
    <div v-if="$slots.submenu && isActive" class="-mt-2">
      <slot name="submenu" />
    </div>
  </div>
</template>

<script>
import Icon from './Icon.vue'
import { isUrl } from '@/Utils/Helpers'

export default {
  components: {
    Icon,
  },
  props: {
    href: {
      type: String,
      required: true,
    },
    path: {
      type: [String, Array],
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    isSubMenu: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showContents: false,
      showCourseDropdown: false,
    }
  },
  computed: {
    isActive() {
      const currentUrl = this.$page.url // Get the current route URL
      if (Array.isArray(this.path)) {
        const prefixedUrls = this.path.map((path) => `admin/${path}`)
        return isUrl(prefixedUrls, currentUrl)
      }
      return isUrl(`admin/${this.path}`, currentUrl)
    },
  },
}
</script>
