<template>
  <div class="relative w-full">
    <div class="mx-auto max-w-2xl px-6 lg:px-16">
      <button v-if="!timeLimitMinutes" class="absolute right-0 top-0 px-2 py-2 md:px-3 md:py-3" @click="close">
        <Icon class="h-8 w-8 flex-shrink-0 md:h-6 md:w-6" name="close" />
      </button>
      <div class="flex min-h-screen items-center justify-center relative z-20">
        <div class="mt-10 flex w-full flex-col space-y-4 py-5 sm:mt-0">
          <nav :aria-label="$t('Quiz question menu')" class="flex w-full flex-wrap space-x-2">
            <slot name="question-links" />
          </nav>

          <div class="w-full rounded-xl bg-white shadow-md">
            <div
              class="flex flex-wrap items-center justify-between border-b-2 border-gray-100 px-6 py-4 md:px-16 md:pb-8 md:pt-10"
            >
              <div class="w-full pb-8 lg:w-1/2 lg:pb-0 xl:w-2/3">
                <h1 class="text-2xl font-semibold leading-8">{{ quizName }}</h1>
              </div>
              <div class="flex w-full flex-col space-y-2 lg:w-1/2 xl:w-1/3">
                <div v-if="timeLimitMinutes" class="flex items-center border border-transparent pl-3">
                  <div class="">
                    <quiz-clock
                      :created-at="createdAt"
                      :enrolment-content="$page.props.enrolmentContent"
                      :expires-at="expiresAt"
                      :quiz="$page.props.quiz"
                      :time-limit-minutes="timeLimitMinutes"
                    />
                  </div>
                  <div class="pl-2">{{ $t('remaining') }}</div>
                </div>
                <div class="flex items-center border border-transparent">
                  <div class="flex-1 pl-4">
                    <slot name="progress" />
                  </div>
                </div>
              </div>
            </div>
            <div class="px-8 py-10 md:px-16 md:py-12">
              <slot />
            </div>
          </div>
        </div>
      </div>
      <div v-if="proctoringEnabled" class="webcam-overlay">
        <Camera ref="webcam" :facing-mode="'user'" :resolution="{ width: 500, height: 500 }" />
      </div>
    </div>
    <front-confirm-modal
      v-if="showConfirmClose"
      :show="showConfirmClose"
      @cancel="cancelConfirmClose"
      @confirm="onConfirmClose"
    >
      <template #title>{{ $t('Confirm close') }}</template>
      <template #content>
        <div class="flex flex-col space-y-4">
          <div>{{ $t("You're closing the quiz. Are you sure you want to do that?") }}</div>
        </div>
      </template>
    </front-confirm-modal>
  </div>
</template>

<script>
import QuizClock from './QuizClock.vue'
import FrontConfirmModal from '@/Shared/FrontConfirmModal.vue'
import Icon from '@/Shared/Icon.vue'
import zendesk from '@/mixins/zendesk.js'
import axios from 'axios'
import Camera from 'simple-vue-camera'

export default {
  name: 'QuizClockLayout',
  components: {
    QuizClock,
    Camera,
    FrontConfirmModal,
    Icon,
  },
  mixins: [zendesk],
  props: {
    proctoringEnabled: Boolean,
  },
  data() {
    return {
      quizName: this.$page.props.quiz.title,
      createdAt: new Date(this.$page.props.attempt.created_at),
      expiresAt: new Date(
        new Date(this.$page.props.attempt.created_at).getTime() + this.$page.props.quiz.time_limit_minutes * 60000
      ),
      timeLimitMinutes: this.$page.props.quiz.time_limit_minutes,
      showConfirmClose: false,
      uploadInterval: null,
    }
  },
  mounted() {
    // Hide Zendesk Chat Widget
    this.zE_hideWidget()

    // Set up interval to capture and upload photo every 30 seconds
    if (this.proctoringEnabled) {
      this.startPhotoUploadInterval()
    }
  },
  beforeUnmount() {
    this.clearPhotoUploadInterval()
  },
  methods: {
    close() {
      this.showConfirmClose = true
    },
    onConfirmClose() {
      this.zE_showWidget()
      this.showConfirmClose = false
      if (this.timeLimitMinutes) return

      this.$inertia.get(this.route('front.quizzes', [this.$page.props.enrolmentContent, this.$page.props.quiz]))
    },
    cancelConfirmClose() {
      this.showConfirmClose = false
    },
    startPhotoUploadInterval() {
      if (!this.proctoringEnabled) {
        return
      }

      this.uploadInterval = setInterval(this.captureAndUploadPhoto, 6000)
    },
    clearPhotoUploadInterval() {
      if (this.uploadInterval) {
        clearInterval(this.uploadInterval)
      }
    },
    async captureAndUploadPhoto() {
      const camera = this.$refs.webcam

      if (camera) {
        const blob = await camera.snapshot()

        const formData = new FormData()
        formData.append('photo', blob)

        axios
          .post(this.route('front.attempts.photo-feed-upload', this.$page.props.attempt.id), formData)
          .catch((error) => {
            console.error(error)
          })
      }
    },
  },
}
</script>

<style scoped>
.webcam-overlay {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 125px;
  height: 125px;
  overflow: hidden;
  border-radius: 50%;
  z-index: 10;
}

.webcam-overlay :deep(video) {
  width: 125px;
  height: 125px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .webcam-overlay {
    bottom: auto;
    left: auto;
    top: 20px;
    right: 20px;
    width: 85px;
    height: 85px;
  }

  .webcam-overlay :deep(video) {
    width: 85px;
    height: 85px;
  }
}
</style>
