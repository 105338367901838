<template>
  <div class="justify-content-between row">
    <Draggable v-bind="dragOptions" :list="list" tag="div" @end="onDragEnd">
      <div v-for="element in list" :key="element.uuid">
        <form-content :common="common" :form-content="element">
          <form-list
            v-if="element.children"
            class="col-8"
            :common="common"
            :list="element.children"
            :parent-id="element.uuid"
          />
        </form-content>
      </div>
    </Draggable>
  </div>
</template>
<script>
import FormContent from './FormContent.vue'
import { v4 as uuidv4 } from 'uuid'
import { VueDraggableNext as Draggable } from 'vue-draggable-next'

export default {
  components: {
    Draggable,
    FormContent,
  },
  inject: ['saveChanges'],
  provide() {
    return {
      addPage: this.addPage,
      addSection: this.addSection,
      addQuestion: this.addQuestion,
      updateFormContent: this.updateFormContent,
      deleteFormContent: this.deleteFormContent,
    }
  },
  props: {
    name: String,
    list: {
      required: true,
      type: Array,
      default: () => [],
    },
    isPage: {
      type: Boolean,
      default: false,
    },
    parentId: {
      type: String,
    },
    common: {
      type: Object,
    },
  },
  computed: {
    dragOptions() {
      return {
        group: { name: 'items', pull: !this.isPage, put: !this.isPage },
        sort: true,
        disabled: this.isPage,
      }
    },
  },
  methods: {
    addFormContent(list, formContent, afterUuid = null) {
      let position = list.length
      if (afterUuid) {
        position = list.findIndex((content) => content.uuid === afterUuid)
      }

      if (position >= 0) {
        list.splice(position + 1, 0, formContent)
        this.saveChanges()
      }
    },
    updateFormContent(formContent, updatedFormContent) {
      Object.keys(updatedFormContent).forEach((key) => {
        formContent[key] = updatedFormContent[key]
      })
      this.saveChanges()
    },
    deleteFormContent(formContent) {
      let position = this.list.findIndex((content) => content.uuid === formContent.uuid)
      if (position >= 0) {
        this.list.splice(position, 1)
        this.saveChanges()
      }
    },
    addPage(afterUuid = null) {
      const pageObject = {
        id: null,
        uuid: uuidv4(),
        type: 'form-page',
        children: [],
        parent_id: null,
        title: null,
        options: {},
      }
      this.addFormContent(this.list, pageObject, afterUuid)
    },
    addSection(list, afterUuid = null) {
      const sectionObject = {
        id: null,
        uuid: uuidv4(),
        parent_id: this.parentId,
        type: 'form-section',
        children: [],
        title: null,
        options: {},
      }

      this.addFormContent(list, sectionObject, afterUuid)
    },
    addQuestion(list, afterUuid = null) {
      const questionObject = {
        id: null,
        uuid: uuidv4(),
        type: 'form-text',
        parent_id: this.parentId,
        title: null,
        options: {},
      }

      this.addFormContent(list, questionObject, afterUuid)
    },
    onDragEnd() {
      this.saveChanges()
    },
  },
}
</script>
