<template>
  <guest-layout :tenant="tenant" title="Certificate signed!">
    <div class="container m-auto flex flex-col items-center">
      <div class="grid w-full grid-cols-1 gap-10 lg:pt-5 xl:pt-10">
        <div class="mx-auto rounded-full bg-ts-blue-50 p-3">
          <div class="rounded-full bg-ts-blue-600 p-4 text-white">
            <icon class="h-10 w-10 fill-current" name="checkmark" />
          </div>
        </div>
        <h1 class="text-center font-heading text-3xl font-semibold text-darkGray-1000">
          {{ $t('Signature added successfully!!') }}
        </h1>
        <div>
          <plastic-card-preview
            v-if="certificate.plastic_card_preview_format"
            :background-url="certificate.plastic_card_preview_format.background_url"
            class="mx-auto block max-w-sm rounded-xl bg-gray-100 px-0 shadow"
            :crop="certificate.plastic_card_preview_format.crop"
            :pdf-fields="certificate.pdf_fields"
            :pdf-images="certificate.pdf_images"
            :pdf-settings="certificate.plastic_card_preview_format.pdf_settings"
          />
          <div v-else class="mx-auto max-w-sm">
            <show-plastic-card-data :certificate="certificate" />
          </div>
        </div>
        <div class="mx-auto mt-10 flex">
          <front-button-link :href="route('front.login')">{{ $t('Log In / Sign up') }}</front-button-link>
        </div>
      </div>
    </div>
  </guest-layout>
</template>

<script>
import FrontButtonLink from '@/Shared/FrontButtonLink.vue'
import GuestLayout from '@/Shared/GuestLayout.vue'
import Icon from '@/Shared/Icon.vue'
import PlasticCardPreview from '@/Shared/PlasticCardPreview.vue'
import ShowPlasticCardData from '@/Shared/ShowPlasticCardData.vue'

export default {
  components: {
    FrontButtonLink,
    GuestLayout,
    Icon,
    PlasticCardPreview,
    ShowPlasticCardData,
  },
  props: {
    tenant: Object,
    certificate: Object,
  },
}
</script>
