<template>
  <signature-type-layout :form-filled="formFilled" :is-last="isLast" :signature="signature">
    <div class="mb-4 flex w-full flex-col justify-between xl:flex-row xl:items-center">
      <div>
        <small class="block uppercase text-gray-500">
          {{
            $t(':signature_type Signature', {
              signature_type: signature.signature_type,
            })
          }}
        </small>
        <div v-if="signatureRequests.length" class="mb-6 mt-2">
          <div v-for="(signatureRequest, index) in signatureRequests" :key="index" class="my-2 flex items-center">
            <p class="mr-2 text-sm font-semibold text-gray-500">
              {{
                $t(':name (:email)', {
                  name: signatureRequest.receiver_name,
                  email: signatureRequest.receiver_email,
                })
              }}
            </p>
            <signature-status :signature-request="signatureRequest" />
            <button
              v-if="signatureRequest.expired_at || isSignatureRequestPending(signatureRequest)"
              class="ml-2 cursor-pointer text-xs uppercase text-sky-700 hover:underline"
              @click="resendRequest(signatureRequest)"
            >
              {{ $t('Resend Request') }}
            </button>
          </div>
        </div>
        <div v-if="filteredSignatureRequestUsers.length">
          <p class="text-sm font-semibold text-ts-gray-text-bold">
            {{ $t('Email will be sent to the below user(s) for a review.') }}
          </p>
          <div v-for="(user, index) in filteredSignatureRequestUsers" :key="index" class="my-2 flex items-center">
            <p class="mr-2 text-sm font-semibold text-gray-500">
              {{
                $t(':name (:email)', {
                  name: user.name,
                  email: user.email,
                })
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </signature-type-layout>
</template>
<script>
import SignatureStatus from './SignatureStatus.vue'
import SignatureTypeLayout from './SignatureTypeLayout.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: { SignatureTypeLayout, SignatureStatus },
  props: {
    signature: Object,
    formFilled: Object,
    signatureRequests: Object,
    isLast: Boolean,
  },
  data() {
    return {
      resendRequestform: useForm({
        requests: [
          {
            users: [],
            signatureType: this.signature.signature_type,
          },
        ],
      }),
    }
  },
  methods: {
    isSignatureRequestPending(request) {
      return !request.accepted_at && !request.expired_at
    },
    resendRequest(signatureRequest) {
      this.resendRequestform.requests[0].users.push({
        id: signatureRequest.receiver_id,
        name: signatureRequest.receiver_name,
        email: signatureRequest.receiver_email,
      })

      this.resendRequestform.post(this.route('front.filledForms.signature-request.store', this.formFilled?.id), {
        preserveScroll: true,
        onSuccess: () => {
          this.resendRequestform.reset()
          this.resendRequestform.clearErrors()
        },
      })
    },
  },
  computed: {
    filteredSignatureRequestUsers() {
      return this.signature.users.filter(
        (user) => !this.signatureRequests.some((request) => user.id === request.receiver_id)
      )
    },
  },
}
</script>
