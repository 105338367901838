<template>
  <front-layout :title="$t('My Purchases')">
    <div class="sm:no-flex mb-8 flex items-center justify-between">
      <breadcrumb name="Transaction History" />
      <front-button-link class="xl:hidden" :href="route('front.enrolment-transfers.step1.create')">
        {{ $t('Transfer') }}
        <span class="hidden lg:inline">{{ $t('Enrolments') }}</span>
      </front-button-link>
    </div>

    <purchases-layout :can-show-transfer-button="user.can.transfer_enrolments">
      <table class="front-table">
        <thead class="bg-ts-front-table-header">
          <tr>
            <th class="font-semibold">{{ $t('Order No') }}</th>
            <th>{{ $t('Purchased Date') }}</th>
            <th>{{ $t('Total') }}</th>
            <th>{{ $t('Payment Method') }}</th>
            <th />
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
          <tr v-if="!purchases.data.length">
            <td class="border-t px-6 py-4" colspan="6">{{ $t('No purchases found.') }}</td>
          </tr>
          <tr v-for="purchase in purchases.data" :key="purchase.id">
            <td class="font-semibold text-ts-gray-text-bold">{{ purchase.order_no || purchase.id }}</td>
            <td>{{ purchase.purchased_at }}</td>
            <td>${{ purchase.total }}</td>
            <td>{{ purchase.total > 0 ? purchase.method : '-' }}</td>
            <td class="whitespace-nowrap px-6 py-4 text-right text-sm font-medium leading-5">
              <div class="inline-flex items-center">
                <InertiaLink
                  class="font-semibold hover:underline"
                  :href="route('front.purchases.receipt', purchase.id)"
                >
                  {{ $t('View') }}
                </InertiaLink>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <front-pagination :links="purchases.links" />
    </purchases-layout>
  </front-layout>
</template>

<script>
import PurchasesLayout from './PurchasesLayout.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontButtonLink from '@/Shared/FrontButtonLink.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontPagination from '@/Shared/FrontPagination.vue'

export default {
  components: { PurchasesLayout, Breadcrumb, FrontButtonLink, FrontLayout, FrontPagination },
  props: {
    purchases: Object,
    user: Object,
  },
}
</script>
