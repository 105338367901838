<template>
  <div class="p-8">
    <div class="max-w-md overflow-hidden rounded bg-white shadow">
      <div class="-mb-8 p-8">
        <div class="pb-8">
          <label class="form-label">{{ $t('Input:') }}</label>
          <input v-model="input" class="form-input" type="text" />
        </div>
        <div class="pb-8">
          <label class="form-label">{{ $t('Result:') }}</label>
          <input ref="result" class="form-input" type="text" :value="result" />
        </div>
      </div>
      <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
        <button class="btn-blue" @click="copy">{{ $t('Copy') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: null,
      result: null,
    }
  },
  methods: {
    copy() {
      this.$refs.result.select()

      document.execCommand('copy')
    },
  },
}
</script>
