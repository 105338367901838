<template>
  <layout :title="$t('Access Codes')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('organizations.edit', code.organization_id)">
        {{ $t('Organization') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Edit Access Code') }}
    </h1>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input
              v-model="form.code"
              :disabled="code.enrolments_count > 0"
              :error="form.errors.code"
              :label="$t('Access Code')"
            />
          </div>
          <div class="w-full pb-8 pr-6">
            <dynamic-search-input
              v-if="code.enrolments_count === 0"
              v-model="selectedOrganization"
              :error="form.errors.organization_id"
              :label="$t('Organization')"
              :search-by="['name']"
              track-by="id"
              :url="route('organizations.search', { access_codes_enabled: true })"
            >
              <div v-if="selectedOrganization" class="truncate">{{ selectedOrganization.name }}</div>
              <template #option="{ option }">
                {{ option.name }}
              </template>
            </dynamic-search-input>
            <text-input
              v-else
              disabled
              :error="form.errors.organization_id"
              :label="$t('Organization')"
              :model-value="selectedOrganization.name"
            />
          </div>
          <div class="w-full pb-8 pr-6">
            <user-search-input
              v-model="issuer"
              class="w-full"
              :disabled="code.enrolments_count > 0"
              :error="form.errors.issuer_id"
              :label="$t('Issued By (This should be the organization user who has the enrolments)')"
              :organization-id="form.organization_id"
              @click.stop="true"
            />
          </div>
          <div v-if="organizationGroups.length > 0" class="w-full pb-8 pr-6">
            <select-input
              v-model="form.group_id"
              :disabled="code.enrolments_count > 0"
              :error="form.errors.group_id"
              :label="$t('Group (optional)')"
            >
              <option :value="null" />
              <option v-for="group in organizationGroups" :key="group.id" :value="group.id">
                {{ group.name }}
              </option>
            </select-input>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input
              v-model="form.usage_limit"
              :error="form.errors.usage_limit"
              :label="$t('Usage Limit (optional)')"
              :min="code.enrolments_count"
              type="number"
            />
            <span v-if="code.enrolments_count > 0" class="text-sm text-gray-600">
              {{ $t('The usage limit must be :count or greater.', { count: code.enrolments_count }) }}
            </span>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <radio-input
              v-model="form.is_archived"
              :error="form.errors.is_archived"
              :label="$t('Archive')"
              type="radio"
            />
          </div>
          <div class="w-full pb-8 pr-6">
            <span class="form-label">{{ $t('Courses') }}:</span>
            <div v-for="course in form.courses" :key="course.id" class="my-6 flex items-center">
              <input
                :id="`course-${course.id}`"
                v-model="course.selected"
                class="form-checkbox mr-4"
                :disabled="code.enrolments_count > 0"
                type="checkbox"
                :value="course.id"
              />
              <label :for="`course-${course.id}`">{{ course.name }}</label>
            </div>
            <div v-if="form.errors.courses" class="form-error">
              {{ form.errors.courses }}
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
          <button
            v-if="code.enrolments_count === 0"
            class="text-red-500 hover:underline"
            tabindex="-1"
            type="button"
            @click="destroy"
          >
            {{ $t('Delete Access Code') }}
          </button>
          <span v-else>&nbsp;</span>
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Update Access Code') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import DynamicSearchInput from '@/Shared/DynamicSearchInput.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import RadioInput from '@/Shared/RadioInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import UserSearchInput from '@/Shared/UserSearchInput.vue'
import Http from '@/Utils/Http'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    DynamicSearchInput,
    Layout,
    LoadingButton,
    RadioInput,
    SelectInput,
    TextInput,
    UserSearchInput,
  },
  props: {
    code: Object,
    organizations: Array,
  },
  data() {
    return {
      selectedOrganization: this.code.organization,
      organizationGroups: this.code.organization.groups || [],
      form: useForm({
        code: this.code.code,
        usage_limit: this.code.usage_limit,
        organization_id: this.code.organization_id,
        issuer_id: this.code.issuer.id,
        group_id: this.code.group_id,
        courses: this.code.courses,
        is_archived: this.code.is_archived,
      }),
      issuer: this.code.issuer,
    }
  },
  watch: {
    selectedOrganization(organization) {
      this.form.organization_id = organization ? organization.id : null

      if (organization && organization.id === this.code.organization?.id) {
        this.form.group_id = this.code.group_id
        this.organizationGroups = this.code.organization.groups || []
      } else if (!organization?.id) {
        this.form.group_id = null
        this.organizationGroups = []
      } else {
        this.form.group_id = null
        Http.get(this.route('groups.search'), { params: { search: '', organization: organization?.id } }).then(
          (response) => {
            this.organizationGroups = response.data.data
          }
        )
      }
    },
    issuer(newIssuer) {
      this.form.issuer_id = newIssuer?.id
    },
  },
  methods: {
    submit() {
      this.form.put(this.route('access-codes.update', this.code.id))
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this access code?'))) {
        this.$inertia.delete(this.route('access-codes.destroy', this.code.id))
      }
    },
  },
}
</script>
