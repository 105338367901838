<template>
  <front-layout title="Plans and Pricing">
    <div class="flex flex-wrap">
      <main class="w-full space-y-12">
        <section class="w-full">
          <div class="mb-16 text-center">
            <h1 class="ts-gray-text-bold mb-4 font-heading text-3xl font-semibold">
              {{ $t('Plans and Pricing') }}
            </h1>
            <h4 class="mx-auto w-1/2 text-center text-lg leading-normal">
              {{ $t('You may select more than one plan of different types. View quantity discount pricing.') }}
            </h4>
          </div>
          <div class="mb-16 flex justify-center gap-8">
            <subscription-plan-card
              v-for="plan in subscriptionPlans"
              :key="plan.id"
              :is-selected="isPlanSelected(plan)"
              :plan="plan"
              @add-plan="addPlan"
            />
          </div>
          <div class="text-center">
            <front-solid-button
              class="h-20 w-1/3"
              :disabled="!selectedPlans.length"
              @click="showReviewSubscriptionModal"
            >
              {{ $t('Review and Pay') }}
            </front-solid-button>
          </div>
        </section>
      </main>
      <review-subscription-modal
        :default-payment-method="defaultPaymentMethod"
        :organization="organization"
        :payment-methods="paymentMethods"
        :regions="regions"
        :selected-plans="selectedPlans"
        :show="isReviewSubscriptionModal"
        @close="hideReviewSubscriptionModal"
      />
    </div>
  </front-layout>
</template>

<script>
import ReviewSubscriptionModal from './ReviewSubscriptionModal.vue'
import SubscriptionPlanCard from './SubscriptionPlanCard.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontSolidButton from '@/Shared/FrontSolidButton.vue'

export default {
  components: { ReviewSubscriptionModal, SubscriptionPlanCard, FrontLayout, FrontSolidButton },
  props: {
    organization: Object,
    paymentMethods: Array,
    defaultPaymentMethod: Object,
    subscriptionPlans: Array,
    regions: Array,
  },
  data() {
    return {
      isReviewSubscriptionModal: false,
      selectedPlans: [],
    }
  },
  methods: {
    showReviewSubscriptionModal() {
      this.isReviewSubscriptionModal = true
    },
    hideReviewSubscriptionModal() {
      this.isReviewSubscriptionModal = false
    },
    isPlanSelected(plan) {
      return this.selectedPlans.find((item) => item.id === plan.id)
    },
    addPlan(plan) {
      const index = this.selectedPlans.findIndex((item) => item.id === plan.id)
      if (index < 0) {
        plan.quantity = 1
        this.selectedPlans.push(plan)
      } else {
        this.selectedPlans.splice(index, 1)
      }
    },
  },
}
</script>

<style scoped>
/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type='range'] {
    -webkit-appearance: none;
    background-color: #cdcdcd;
    height: 10px;
    border-radius: 10px;
  }

  input[type='range']::-webkit-slider-thumb {
    width: 22px;
    height: 22px;
    margin-top: -11px;
    border-radius: 11px;
    -webkit-appearance: none;
    cursor: ew-resize;
    background: #dd163d;
  }

  datalist {
    display: grid;
    grid-auto-flow: column;
  }
}
</style>
