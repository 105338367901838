<template>
  <div class="items-top relative flex min-h-screen justify-center bg-white sm:items-center sm:pt-0">
    <DynamicHead :title="pageTitle" />
    <div class="mx-auto w-full" :class="{ 'max-w-3xl sm:px-6 lg:px-8': status !== 403, 'h-screen': status === 403 }">
      <not-found v-if="status === 404" :description="description" :title="title" @go-back="goBack" />
      <forbidden v-if="status === 403" :description="description" :title="title" @go-back="goBack" />
      <too-many-requests v-if="status === 429" :description="description" :title="title" @go-back="goBack" />
      <server-error v-if="status === 500" :description="description" :title="title" @go-back="goBack" />
      <server-error v-if="status === 503" :description="description" :title="title" @go-back="goBack" />
    </div>
  </div>
</template>

<script>
import Forbidden from './Errors/Forbidden.vue'
import NotFound from './Errors/NotFound.vue'
import ServerError from './Errors/ServerError.vue'
import TooManyRequests from './Errors/TooManyRequests.vue'
import DynamicHead from '@/Shared/DynamicHead.vue'

export default {
  components: {
    NotFound,
    Forbidden,
    ServerError,
    TooManyRequests,
    DynamicHead,
  },
  props: {
    status: Number,
  },
  computed: {
    title() {
      return {
        503: this.$t('WHOOPS!'),
        500: this.$t('WHOOPS!'),
        429: this.$t('WHOOPS!'),
        404: this.$t('OOPS!'),
        403: this.$t('SORRY!'),
      }[this.status]
    },
    pageTitle() {
      return (this.title ? this.title : this.$t('Error')) + ` | ${this.$page.props?.app?.name}`
    },
    description() {
      return {
        503: this.$t('The service is unavailable. Please check back soon.'),
        500: this.$t('Something went wrong on our servers.'),
        429: this.$t('Rate limit exceeded, please try again later.'),
        404: this.$t('The page you are looking for could not be found.'),
        403: this.$t('You are forbidden from accessing this page.'),
      }[this.status]
    },
  },
  methods: {
    goBack() {
      if (history.length === 1) {
        window.location = 'https://worksitesafety.ca'
      } else {
        history.back()
      }
    },
  },
}
</script>
