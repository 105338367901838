import { reactive } from 'vue'

const activeId = reactive({
  value: null,
})

function setActiveId(id) {
  activeId.value = id
}

export { activeId, setActiveId }
