<template>
  <layout :title="$t('Create Certificate')">
    <div class="mb-8">
      <breadcrumb
        :middle-name="certificate_template.name"
        :middle-url="route('certificate-templates.edit', certificate_template)"
        :name="$t('Add Formats')"
        :previous-name="$t('Certificates')"
        :previous-url="route('certificate-templates')"
      />
    </div>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form enctype="multipart/form-data" @submit.prevent="submit">
        <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Template Files') }}</div>
        <div v-for="(format, i) in missing_formats" :key="i" class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <div>
              <label :for="`${format}-template-file`">
                {{ $t(':format Template File', { format: _startCase(format) }) }}
              </label>
              <file-input
                :id="`${format}-template-file`"
                accept="image/*"
                class="mt-4"
                :error="form.errors[`formats.${format}`]"
                @input="form.formats[format] = $event"
              />
              <div v-if="form.errors.formats" class="form-error mb-2">
                {{ form.errors.formats }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Add Formats') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FileInput from '@/Shared/FileInput.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import { useForm } from '@inertiajs/vue3'
import _startCase from 'lodash/startCase'

export default {
  components: {
    Breadcrumb,
    FileInput,
    Layout,
    LoadingButton,
  },
  props: {
    missing_formats: Array,
    certificate_template: Object,
  },
  data() {
    return {
      form: useForm({
        formats: {},
      }),
    }
  },
  methods: {
    _startCase,
    submit() {
      this.form.post(this.route('certificate-template-format.store', this.certificate_template))
    },
  },
}
</script>
