<template>
  <front-layout :title="$t('Access Codes')">
    <div class="mb-8">
      <breadcrumb
        class="font-heading text-2xl font-semibold"
        :name="$t('Create')"
        :previous-name="$t('Access Codes')"
        :previous-url="route('front.manager.access-codes')"
      />
    </div>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-col p-8">
          <div v-if="group_required" class="w-full pb-8 pr-6 lg:w-2/3">
            <front-select-input
              v-model="form.group_id"
              :disabled="groups.length === 0"
              :error="form.errors.group_id"
              :label="group_required ? $t('Group') : $t('Group (optional)')"
            >
              <option :value="null">
                <span v-if="groups.length === 0 && group_required">{{ $t('No Groups Found') }}</span>
              </option>
              <option v-for="group in groups" :key="group.id" :value="group.id">
                {{ group.name }}
              </option>
            </front-select-input>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-2/3">
            <front-text-input
              v-model="form.usage_limit"
              :error="form.errors.usage_limit"
              :label="$t('Usage Limit (optional)')"
              min="0"
              type="number"
            />
          </div>
          <h2 class="py-5 text-xl">{{ $t('Courses') }}</h2>
          <span class="mb-4 text-sm" :class="form.errors.courses ? 'text-qualify-red-500' : 'text-ts-front-label'">
            {{ $t('At least one course must be selected.') }}
          </span>
          <div v-for="(course, key) in form.courses" :key="key" class="my-3 w-full pr-6 lg:w-2/3">
            <div
              class="flex cursor-pointer items-center rounded-lg border px-4 py-4 hover:bg-ts-front-field-light"
              :class="{ 'border-ts-red-500': course.selected }"
              @click="course.selected = !course.selected"
            >
              <icon
                class="ml-2 mr-8 h-6 w-6 flex-shrink-0 rounded-full bg-gray-300 p-1"
                :class="course.selected ? 'bg-ts-red-500 fill-white' : 'fill-gray-300'"
                name="checkmark"
              />
              <label>{{ course.name }}</label>
            </div>
          </div>
        </div>
        <div class="mt-6 flex items-center justify-end border-t border-gray-100 px-8 py-8">
          <front-loading-button :loading="form.processing" type="submit">{{ $t('Create Code') }}</front-loading-button>
        </div>
      </form>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontSelectInput from '@/Shared/FrontSelectInput.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import Icon from '@/Shared/Icon.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    FrontLayout,
    FrontLoadingButton,
    FrontSelectInput,
    FrontTextInput,
    Icon,
  },
  props: {
    courses: Array,
    groups: Array,
    group_required: Boolean,
  },
  data() {
    return {
      previous: {
        url: this.route('front.manager.access-codes'),
        name: this.$t('Access Codes'),
      },
      form: useForm({
        code: this.code,
        usage_limit: null,
        courses: this.courses,
        group_id: null,
      }),
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('front.manager.access-codes.store'))
    },
  },
}
</script>
