<template>
  <div class="space-y-8 p-8">
    <div class="">
      <h2 class="text-lg">{{ $t('Learning') }}</h2>
      <div class="mt-6 shadow">
        <SimpleTable>
          <template #head>
            <TableHead class="text-left">{{ $t('Course') }}</TableHead>
            <TableHead>{{ $t('Owned By') }}</TableHead>
            <TableHead>{{ $t('Enrolled by') }}</TableHead>
            <TableHead>{{ $t('Code') }}</TableHead>
            <TableHead>{{ $t('Status') }}</TableHead>
            <TableHead>{{ $t('Created at') }}</TableHead>
            <TableHead />
          </template>
          <TableRow v-if="enrolments.data.length === 0">
            <TableData colspan="2">
              {{ $t('No enrolments found') }}
            </TableData>
          </TableRow>
          <TableRow
            v-for="enrolment in enrolments.data"
            :key="enrolment.id"
            :class="{ 'opacity-50': enrolment.is_deleted || enrolment.is_archived }"
            @click="$inertia.visit(route('enrolments.edit', enrolment.id), { preserveScroll: true })"
          >
            <TableData>
              <span>{{ enrolment.course.name }}</span>
              <cancelled-badge v-if="enrolment.is_cancelled" />
              <archived-badge v-if="enrolment.archived_at" />
              <deleted-badge v-if="enrolment.deleted_at" />
            </TableData>
            <TableData>
              <span v-if="enrolment.owner">{{ enrolment.owner.name }}</span>
              <span v-else>-</span>
            </TableData>
            <TableData class="whitespace-nowrap">
              <span v-if="enrolment.enroller">{{ enrolment.enroller.name }}</span>
              <span v-else>-</span>
            </TableData>
            <TableData class="whitespace-nowrap">
              <span v-if="enrolment.access_code">{{ enrolment.access_code.code }}</span>
              <span v-else>-</span>
            </TableData>
            <TableData class="whitespace-nowrap">
              <front-course-status :status="enrolment.status" />
            </TableData>
            <TableData class="whitespace-nowrap">
              <show-date :timestamp="enrolment.created_at" />
            </TableData>
            <TableData class="w-min align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </TableData>
          </TableRow>
        </SimpleTable>
      </div>
      <pagination :links="enrolments.links" :preserve-scroll="true" />
    </div>

    <div>
      <div v-if="courses.data.length">
        <div class="mt-4 flex items-center justify-between">
          <h2 class="text-lg">{{ $t('Available Enrolments (Summary)') }}</h2>
          <div class="mb-6 flex items-center justify-between">
            <InertiaLink class="btn-blue" :href="route('users.enrolment-transfers.step1.create', userId)">
              <span>{{ $t('Transfer') }}</span>
              <span class="hidden md:inline">{{ $t('Enrollments') }}</span>
            </InertiaLink>
          </div>
        </div>
        <div class="shadow">
          <SimpleTable>
            <template #head>
              <TableHead class="text-left">{{ $t('Course') }}</TableHead>
              <TableHead class="text-right">{{ $t('Quantity') }}</TableHead>
              <TableHead />
            </template>
            <TableRow v-for="course in courses.data" :key="course.id">
              <TableData>{{ course.name }}</TableData>
              <TableData class="text-right">{{ course.unassigned_enrolments_count }}</TableData>
              <TableData />
            </TableRow>
          </SimpleTable>
        </div>
        <pagination :links="courses.links" :preserve-scroll="true" />
      </div>

      <div class="mt-4 flex items-center justify-between">
        <h2 class="text-lg">{{ $t('Owned enrolments') }}</h2>
        <div class="mb-6 flex items-center justify-between">
          <InertiaLink class="btn-blue" :href="route('enrolments.create', { owner: userId })">
            <span>{{ $t('New') }}</span>
            <span class="hidden md:inline">{{ $t('enrolment') }}</span>
          </InertiaLink>
        </div>
      </div>
      <div class="w-full shadow">
        <SimpleTable>
          <template #head>
            <TableHead class="text-left">{{ $t('Course') }}</TableHead>
            <TableHead>{{ $t('User') }}</TableHead>
            <TableHead>{{ $t('Enrolled by') }}</TableHead>
            <TableHead>{{ $t('Code') }}</TableHead>
            <TableHead>{{ $t('Payment') }}</TableHead>
            <TableHead>{{ $t('Status') }}</TableHead>
            <TableHead>{{ $t('Created at') }}</TableHead>
            <TableHead />
          </template>
          <TableRow v-if="ownedEnrolments.data.length === 0">
            <TableData colspan="7">{{ $t('No enrolments found.') }}</TableData>
          </TableRow>
          <TableRow
            v-for="enrolment in ownedEnrolments.data"
            :key="enrolment.id"
            class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
            :class="{ 'opacity-50': enrolment.is_cancelled || enrolment.is_deleted || enrolment.is_archived }"
            @click="!isSelectingEnrolments && $inertia.visit(route('enrolments.edit', enrolment.id))"
          >
            <TableData>{{ enrolment.course.name }}</TableData>
            <TableData>
              <span v-if="enrolment.user">{{ enrolment.user.name }}</span>
              <span v-else>-</span>
            </TableData>
            <TableData class="whitespace-nowrap">
              <span v-if="enrolment.enroller">{{ enrolment.enroller.name }}</span>
              <span v-else>-</span>
            </TableData>
            <TableData class="whitespace-nowrap">
              <span v-if="enrolment.access_code">{{ enrolment.access_code.code }}</span>
              <span v-else>-</span>
            </TableData>
            <TableData>
              <front-enrolment-payment-status :enrolment="enrolment" />
              <cancelled-badge v-if="enrolment.is_cancelled" />
              <archived-badge v-if="enrolment.archived_at" />
              <deleted-badge v-if="enrolment.deleted_at" />
            </TableData>
            <TableData>
              <front-course-status v-if="enrolment.payment_status === 'Paid'" :status="enrolment.status" />
            </TableData>
            <TableData>
              <show-date :timestamp="enrolment.created_at" />
            </TableData>
            <TableData class="w-min align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </TableData>
          </TableRow>
        </SimpleTable>
      </div>
      <pagination :links="ownedEnrolments.links" :preserve-scroll="true" :preserve-state="true" />
    </div>

    <div class="mt-4 w-full">
      <div class="flex items-end justify-between">
        <div>
          <h2 class="mb-4 text-lg">{{ $t('Enrolment Transfers') }}</h2>
        </div>
      </div>
      <div class="shadow">
        <SimpleTable>
          <template #head>
            <TableHead>
              <div class="flex justify-between">
                <div class="pr-8">{{ $t('Enrolments') }}</div>
                <div class="text-right">{{ $t('Qty') }}</div>
              </div>
            </TableHead>
            <TableHead>{{ $t('Created At') }}</TableHead>
            <TableHead>{{ $t('Sent To') }}</TableHead>
            <TableHead>{{ $t('Receiver User') }}</TableHead>
            <TableHead>{{ $t('Status') }}</TableHead>
            <TableHead />
          </template>
          <TableRow v-if="enrolmentTransfers.data.length === 0">
            <TableData colspan="5">{{ $t('No enrolment transfers found.') }}</TableData>
          </TableRow>
          <TableRow v-for="transfer in enrolmentTransfers.data" :key="transfer.id">
            <TableData>
              <div
                v-for="(enrolment, courseName, index) in transfer.enrolments"
                :key="enrolment.id"
                class="flex justify-between"
                :class="{ 'pb-4': index !== Object.keys(transfer.enrolments).length - 1 }"
              >
                <div class="pr-8">{{ courseName }}</div>
                <div class="text-right">{{ enrolment.length }}</div>
              </div>
            </TableData>
            <TableData>{{ transfer.created_at }}</TableData>
            <TableData class="flex flex-col space-y-1">
              <div v-if="transfer.receiver_email">
                <icon class="mr-1 inline-block h-4 w-4" name="email" />
                <span>{{ transfer.receiver_email }}</span>
              </div>
              <div v-if="transfer.receiver_phone_number">
                <icon class="mr-1 inline-block h-4 w-4" name="phone" />
                <span>{{ transfer.receiver_phone_number }}</span>
              </div>
            </TableData>
            <TableData>{{ transfer.receiver.name }}</TableData>
            <TableData class="capitalize">{{ transfer.transfer_status }}</TableData>
            <TableData class="whitespace-nowrap text-right">
              <button
                v-if="transfer.transfer_status === 'pending'"
                class="btn-red"
                @click.prevent="expireEnrolmentTransfer(transfer)"
              >
                {{ $t('Force Expire') }}
              </button>
            </TableData>
          </TableRow>
        </SimpleTable>
      </div>
      <pagination :links="enrolmentTransfers.links" :preserve-scroll="true" />
    </div>
  </div>
</template>

<script>
import UserEditLayoutVue from './UserEditLayout.vue'
import ArchivedBadge from '@/Shared/ArchivedBadge.vue'
import CancelledBadge from '@/Shared/CancelledBadge.vue'
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import FrontCourseStatus from '@/Shared/FrontCourseStatus.vue'
import FrontEnrolmentPaymentStatus from '@/Shared/FrontEnrolmentPaymentStatus.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import ShowDate from '@/Shared/ShowDate.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import { activeUser } from '@/Stores/ActiveUser'

export default {
  components: {
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
    ArchivedBadge,
    CancelledBadge,
    DeletedBadge,
    FrontCourseStatus,
    FrontEnrolmentPaymentStatus,
    Icon,
    Pagination,
    ShowDate,
  },
  layout: [Layout, UserEditLayoutVue],
  props: {
    enrolments: Object,
    enrolmentTransfers: Object,
    courses: Object,
    ownedEnrolments: Object,
  },
  data() {
    return {
      userId: activeUser.value.id,
    }
  },
  methods: {
    expireEnrolmentTransfer(enrolmentTransfer) {
      if (
        confirm(
          this.$t(`Are you sure you want to expire this transfer that was sent to ${enrolmentTransfer.receiver_email}?`)
        )
      ) {
        this.$inertia.post(this.route('enrolment-transfers.expire', enrolmentTransfer.id))
      }
    },
  },
}
</script>
