<template>
  <front-layout :title="$t('Action : :action_name', { action_name: action.title })">
    <div class="flex flex-col xl:flex-row xl:justify-between">
      <div class="sm:no-flex flex w-full items-center justify-between" :class="'mb-8'">
        <front-page-title
          :back-url="route('front.actions')"
          :value="$t('Action : :action_name', { action_name: action.title })"
        />
      </div>
    </div>
    <div class="flex w-full items-start gap-x-8 gap-y-4">
      <div class="w-1/4 space-y-8">
        <form class="block flex flex-col gap-4 rounded-xl border bg-white p-8" @submit.prevent="submitForm">
          <text-input v-model="formAction.title" :error="formAction.errors.title" :label="$t('Title')" />
          <textarea-input
            v-model="formAction.description"
            :error="formAction.errors.description"
            :label="$t('Description')"
          />
          <front-user-search-input v-model="formAction.assignee" :label="$t('Assigned To')" />
          <select-input
            v-model="formAction.priority"
            :error="formAction.errors.priority"
            :label="$t('Priority')"
            :options="priorityOptions"
          />
          <front-date-input
            v-model="formAction.start_at"
            :error="formAction.errors.start_at"
            :label="$t('Starts at')"
          />
          <front-date-input v-model="formAction.due_at" :error="formAction.errors.due_at" :label="$t('Due at')" />
          <div class="flex justify-center">
            <front-loading-button :loading="formAction.processing">{{ $t('Update') }}</front-loading-button>
          </div>
        </form>
        <form class="block flex flex-col gap-4 rounded-xl border bg-white p-8" @submit.prevent="submitForm">
          <select-input
            v-model="formStatus.status"
            :error="formStatus.errors.status"
            label="Status"
            :options="actionStatuses"
            @change="updateActionStatus"
          />
        </form>
      </div>
      <div class="flex flex-1 flex-col overflow-hidden rounded-xl border bg-white">
        <div class="flex-1 bg-gray-100">
          <div v-if="loadingMoreComments" class="flex w-full items-center justify-center gap-4 py-8">
            <div class="spinner-red h-8 w-8" name="loading" />
            <div>Loading</div>
          </div>
          <div class="flex max-h-screen flex-col-reverse overflow-y-scroll px-8">
            <template v-if="commentsList.length === 0">
              <div class="flex flex-col border-b border-gray-400 px-4 py-12 first:border-b-0">
                {{ $t('No comments.') }}
              </div>
            </template>
            <template v-else>
              <template v-for="comment in commentsList" :key="comment.id">
                <div class="flex flex-col border-b border-gray-400 px-4 py-8 first:border-b-0">
                  <div v-if="comment.content">{{ comment.content }}</div>
                  <div
                    v-if="comment.attachments.length > 0"
                    class="flex flex-wrap items-center gap-2 rounded bg-white p-2"
                  >
                    <template v-for="attachment in comment.attachments" :key="attachment.id">
                      <front-media-icon class="h-16 w-16" :media="attachment" @click="openFile(attachment)" />
                    </template>
                  </div>
                  <div class="mt-2 flex items-center gap-4 text-xs text-gray-600">
                    <div>{{ comment.user }}</div>
                    <show-date format=" DD MMM, YYYY hh:mm A" :timestamp="comment.created_at" />
                  </div>
                </div>
              </template>
            </template>
            <div ref="loadMoreIntersect" />
          </div>
        </div>
        <div class="w-full">
          <form class="flex w-full items-center gap-4 p-8" @submit.prevent="submit">
            <button @click.prevent="() => (showCommentsAttachmentsModal = true)">
              <paper-clip-icon class="h-6 w-6" />
            </button>
            <div class="flex-1">
              <textarea-input v-model="form.content" rows="2" />
            </div>
            <front-solid-button type="submit">{{ $t('Submit') }}</front-solid-button>
          </form>
        </div>
      </div>
    </div>
    <front-comment-attachments-modal
      :action="action"
      :show="showCommentsAttachmentsModal"
      @close="() => (showCommentsAttachmentsModal = false)"
    />
  </front-layout>
</template>

<script>
import FrontCommentAttachmentsModal from '@/Shared/FrontCommentAttachmentsModal.vue'
import FrontDateInput from '@/Shared/FrontDateInput.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontMediaIcon from '@/Shared/FrontMediaIcon.vue'
import FrontPageTitle from '@/Shared/FrontPageTitle.vue'
import FrontSolidButton from '@/Shared/FrontSolidButton.vue'
import FrontUserSearchInput from '@/Shared/FrontUserSearchInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import ShowDate from '@/Shared/ShowDate.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { PaperClipIcon } from '@heroicons/vue/24/solid'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    SelectInput,
    ShowDate,
    FrontSolidButton,
    TextareaInput,
    FrontPageTitle,
    PaperClipIcon,
    FrontCommentAttachmentsModal,
    FrontMediaIcon,
    TextInput,
    FrontUserSearchInput,
    FrontLoadingButton,
    FrontDateInput,
    FrontLayout,
  },
  props: {
    action: Object,
    comments: Object,
    actionStatuses: Object,
    priorityOptions: Object,
  },
  data() {
    return {
      commentsList: this.comments.data,
      form: useForm({
        content: null,
      }),
      formAction: useForm({
        title: this.action.title,
        description: this.action.description,
        priority: this.action.priority,
        status: this.action.status,
        assignee: this.action.assignee,
        start_at: this.action.start_at,
        due_at: this.action.due_at,
      }),
      formStatus: useForm({
        status: this.action.status,
      }),
      initialUrl: this.$page.url,
      loadingMoreComments: false,
      showCommentsAttachmentsModal: false,
    }
  },
  mounted() {
    const observer = new IntersectionObserver((entries) =>
      entries.forEach((entry) => entry.isIntersecting && this.loadMoreComments(), {
        rootMargin: '-150px 0px 0px 0px',
      })
    )

    observer.observe(this.$refs.loadMoreIntersect)
  },
  methods: {
    submit() {
      this.form.post(this.route('front.comments.store', this.action), {
        preserveState: false,
        preserveScroll: true,
      })
    },
    loadMoreComments() {
      if (this.comments.next_page_url === null) {
        return
      }

      this.$inertia.get(
        this.comments.next_page_url,
        {},
        {
          preserveState: true,
          preserveScroll: true,
          only: ['comments'],
          onSuccess: () => {
            this.commentsList = [...this.commentsList, ...this.comments.data]
            window.history.replaceState(null, null, this.initialUrl)
          },
        }
      )
    },
    openFile(file) {
      window.open(file.url, '_blank')
    },
    submitForm() {
      this.formAction
        .transform(function (data) {
          return {
            ...data,
            assignee: data.assignee?.id,
          }
        })
        .put(this.route('front.actions.update', this.action))
    },
    updateActionStatus() {
      this.formStatus.put(this.route('front.actions.status.update', this.action), {
        preserveScroll: true,
      })
    },
  },
}
</script>
