<template>
  <layout :title="form.name">
    <div class="mb-6 flex max-w-3xl items-center justify-between">
      <h1 class="text-xl font-bold">
        <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('instructor-led-classrooms')">
          {{ $t('Instructor Led Classes') }}
        </InertiaLink>
        <span class="font-medium text-blue-600">/</span>
        {{ form.name }}
      </h1>
      <InertiaLink
        v-if="related_contentable"
        class="btn-blue"
        :href="route('instructor-led-classrooms.edit', related_contentable.id)"
      >
        {{ $t('Edit Related ILC (:lang)', { lang: related_contentable.lang }) }}
      </InertiaLink>
    </div>
    <archived-message v-if="classroom.archived_at" class="mb-6">
      {{ $t('This instructor led classroom has been archived.') }}
    </archived-message>
    <div class="flex max-w-3xl flex-wrap">
      <div class="w-full lg:w-3/5 xl:w-2/3">
        <div class="overflow-hidden rounded bg-white shadow">
          <form @submit.prevent="submit">
            <div class="-mb-8 -mr-6 flex flex-wrap p-8">
              <div class="w-full pb-8 pr-6">
                <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name (Internal)')" />
              </div>
              <div class="w-full pb-8 pr-6">
                <text-input v-model="form.title" :error="form.errors.title" :label="$t('Title')" />
              </div>
              <div class="w-full pb-8 pr-6">
                <textarea-input
                  v-model="form.description"
                  :autosize="true"
                  :error="form.errors.description"
                  :label="$t('Description')"
                  rows="2"
                />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <radio-input
                  v-model="form.need_preparation_time"
                  :error="form.errors.need_preparation_time"
                  :label="$t('Instructor need preparation time')"
                />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input v-model="form.capacity" :error="form.errors.capacity" :label="$t('Capacity')" />
              </div>
              <div class="pb-8 pr-6 lg:w-1/2">
                <select-input
                  v-model="form.blackout_period"
                  :error="form.errors.blackout_period"
                  :label="$t('Restrict booking in last (Days)')"
                >
                  <option key="0" :value="0">{{ $t('No') }}</option>
                  <option v-for="day in 10" :key="day" :value="day">
                    {{ day }}
                  </option>
                </select-input>
              </div>
              <div class="pb-8 pr-6 lg:w-1/2">
                <select-input
                  v-model="form.unenrol_period"
                  :error="form.errors.unenrol_period"
                  :label="$t('Restrict unenroling in last (Days)')"
                >
                  <option key="0" :value="0">{{ $t('No') }}</option>
                  <option v-for="day in 10" :key="day" :value="day">
                    {{ day }}
                  </option>
                </select-input>
              </div>
              <div class="pb-8 pr-6 lg:w-full">
                <editor
                  v-model="form.email_content"
                  :error="form.errors.email_content"
                  :label="$t('Booking confirmation email content')"
                />
              </div>
            </div>
            <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
              <div>
                <button class="mr-3 text-red-500 hover:underline" tabindex="-1" type="button" @click="destroy">
                  {{ $t('Delete') }}
                </button>
                <button
                  v-if="!classroom.archived_at"
                  class="text-red-500 hover:underline"
                  tabindex="-1"
                  type="button"
                  @click="archive"
                >
                  {{ $t('Archive') }}
                </button>
                <button v-else class="text-red-500 hover:underline" tabindex="-1" type="button" @click="unarchive">
                  {{ $t('Unarchive') }}
                </button>
              </div>
              <loading-button class="btn-blue" :loading="form.processing" type="submit">
                {{ $t('Update Classroom') }}
              </loading-button>
            </div>
          </form>
        </div>
      </div>
      <div class="flex w-full flex-col pt-12 lg:w-2/5 lg:pl-8 lg:pt-0 xl:w-1/3">
        <div class="mb-12 overflow-hidden rounded bg-white shadow lg:mb-8">
          <div class="flex items-center justify-between px-6 pb-3 pt-4">
            <div class="font-bold">{{ $t('Locations') }}</div>
            <InertiaLink class="btn-blue py-2" :href="route('instructor-led-classrooms.locations.create', classroom)">
              {{ $t('Add') }}
            </InertiaLink>
          </div>
          <table class="w-full">
            <tbody>
              <tr v-if="locations.length === 0">
                <td class="border-t px-6 py-4">{{ $t('No locations for this class.') }}</td>
              </tr>
              <tr
                v-for="location in locations"
                :key="location.id"
                class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
                @click="$inertia.visit(route('instructor-led-classrooms.locations.edit', [classroom.id, location.id]))"
              >
                <td class="whitespace-nowrap border-t px-6 py-4">
                  {{ location.name }}
                  <small v-if="location.client_site" class="text-gray-500">{{ $t('(Client Site)') }}</small>
                </td>
                <td class="w-min border-t px-4 align-middle">
                  <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <card class="mb-5 p-0" :name="$t('Subclasses')">
          <div class="flex items-center justify-between px-6 pb-3 pt-4">
            <select-input v-model="subInstructorLedClassroom" :disabled="classrooms.length === 0">
              <option :value="null" />
              <option
                v-for="instructorLedClassroom in classrooms"
                :key="instructorLedClassroom.id"
                :value="instructorLedClassroom.id"
              >
                {{ instructorLedClassroom.name }}
              </option>
            </select-input>
            <button
              class="btn-blue ml-2 py-2"
              :disabled="!subInstructorLedClassroom"
              @click="
                $inertia.put(
                  route('instructor-led-classrooms.subclasses.update', [classroom, subInstructorLedClassroom])
                )
              "
            >
              {{ $t('Add') }}
            </button>
          </div>
          <table class="w-full">
            <tbody>
              <tr v-if="subclassrooms.length === 0">
                <td class="border-t px-6 py-4">{{ $t('No subclasses linked to this class.') }}</td>
              </tr>
              <tr v-for="class_ in subclassrooms" :key="class_.id" class="hover:bg-gray-100 focus:bg-gray-100">
                <td class="flex items-center justify-between whitespace-nowrap border-t px-6 py-4">
                  <p>{{ class_.name }}</p>
                  <icon
                    class="h-4 w-4 cursor-pointer fill-gray-400"
                    name="trash"
                    @click="
                      $inertia.delete(route('instructor-led-classrooms.subclasses.destroy', [classroom, class_.id]))
                    "
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </card>

        <div class="mt-5">
          <card class="p-0" name="Courses">
            <div class="flex w-full flex-col">
              <div v-for="course in courses" :key="course.id" class="border-b last:border-b-0">
                <InertiaLink class="block w-full p-4" :href="route('courses.edit', course.id)">
                  {{ course.name }}
                </InertiaLink>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <h2 class="mt-12 text-lg">{{ $t('Classes') }}</h2>
    <div class="mt-6 overflow-x-auto rounded bg-white shadow">
      <table class="w-full">
        <thead>
          <tr>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Name') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Sessions') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Location') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold" colspan="2">{{ $t('Instructors') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(class_, key) in classes.data"
            :key="key"
            class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
            @click="$inertia.visit(route('classes.show', class_.id))"
          >
            <td class="whitespace-nowrap border-t px-6 py-4">{{ class_.name }}</td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <div v-if="class_.sessions_count">
                <span class="mr-1 rounded bg-gray-400 px-2 py-1 text-sm text-white">
                  {{ class_.sessions_count }}
                </span>
                {{ class_.dates }}
              </div>
              <div v-else class="text-red-500">{{ $t('None set') }}</div>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <div class="max-w-15 truncate">
                {{ class_.location.name }}
                <span v-if="class_.room" class="text-sm">({{ class_.room }})</span>
                <span v-if="class_.location.client_site" class="ml-2 rounded bg-gray-400 px-2 py-1 text-sm text-white">
                  {{ $t('Client') }}
                </span>
              </div>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <span v-if="class_.instructors.length > 0">{{ class_.instructors }}</span>
              <span v-else>{{ $t('None Assigned') }}</span>
            </td>
            <td class="w-min border-t px-4 align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </td>
          </tr>
          <tr v-if="classes.data.length === 0">
            <td class="py-6 text-center" colspan="5">{{ $t('No classes found.') }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination :links="classes.links" />
    <div class="mt-12 flex items-end justify-between">
      <h2 class="text-lg">{{ $t('Tests') }}</h2>
      <InertiaLink class="btn-blue" :href="route('instructor-led-classrooms.offline-tests.create', classroom)">
        <span>{{ $t('Create') }}</span>
        <span class="hidden md:inline">{{ $t('Test') }}</span>
      </InertiaLink>
    </div>
    <div class="mt-6 overflow-x-auto rounded bg-white shadow">
      <table class="w-full">
        <thead>
          <tr>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Name') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Total Score') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold" colspan="2">{{ $t('Passing Score') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="tests.length === 0">
            <td class="py-6 text-center" colspan="5">{{ $t('No tests found.') }}</td>
          </tr>
          <tr
            v-for="(test, key) in tests"
            :key="key"
            class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
            @click="$inertia.visit(route('instructor-led-classrooms.offline-tests.edit', [classroom, test]))"
          >
            <td class="whitespace-nowrap border-t px-6 py-4">{{ test.name }}</td>
            <td class="whitespace-nowrap border-t px-6 py-4">{{ test.total_score || '-' }}</td>
            <td class="whitespace-nowrap border-t px-6 py-4">{{ test.passing_score || '-' }}</td>
            <td class="w-min border-t px-4 align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </layout>
</template>

<script>
import ArchivedMessage from '@/Shared/ArchivedMessage.vue'
import Card from '@/Shared/Card.vue'
import Editor from '@/Shared/Editor.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import Pagination from '@/Shared/Pagination.vue'
import RadioInput from '@/Shared/RadioInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    ArchivedMessage,
    Card,
    Editor,
    Icon,
    Layout,
    LoadingButton,
    Pagination,
    RadioInput,
    SelectInput,
    TextInput,
    TextareaInput,
  },
  props: {
    classroom: Object,
    classes: Object,
    courses: Array,
    locations: Array,
    classrooms: Object,
    subclassrooms: Array,
    tests: Array,
    related_contentable: Object,
  },
  data() {
    return {
      form: useForm(
        {
          name: this.classroom.name,
          title: this.classroom.title,
          capacity: this.classroom.capacity,
          description: this.classroom.description,
          blackout_period: this.classroom.blackout_period || 0,
          unenrol_period: this.classroom.unenrol_period || 0,
          need_preparation_time: this.classroom.need_preparation_time,
          email_content: this.classroom.email_content,
        },
        'ILCEdit'
      ),
      subInstructorLedClassroom: null,
    }
  },
  methods: {
    submit() {
      this.form.put(this.route('instructor-led-classrooms.update', this.classroom.id))
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this classroom?'))) {
        this.$inertia.delete(this.route('instructor-led-classrooms.destroy', this.classroom.id))
      }
    },
    archive() {
      if (confirm(this.$t('Are you sure you want to archive this classroom?'))) {
        this.$inertia.post(this.route('instructor-led-classrooms.archive', this.classroom.id))
      }
    },
    unarchive() {
      if (confirm(this.$t('Are you sure you want to unarchive this classroom?'))) {
        this.$inertia.post(this.route('instructor-led-classrooms.unarchive', this.classroom.id))
      }
    },
  },
}
</script>
