<template>
  <form-modal :show="show" @close="() => {}">
    <template #content>
      <div class="px-4 py-8 sm:px-6">
        <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
          {{ $t('Notify') }}
        </DialogTitle>
        <div class="inline-flex w-full items-center justify-between text-sm">
          <p>{{ 'if ' + logicName }}</p>
        </div>
      </div>
      <div class="relative bg-white p-6">
        <p class="pb-3">Choose the evidence that’ll be required when this answer is selected.</p>
        <front-checkbox-input v-model="evidenceData.notes_required" :label="$t('Notes')" />
        <front-checkbox-input v-model="evidenceData.media_required" :label="$t('Media')" />
      </div>
      <div class="flex justify-end px-4 py-5 sm:px-6">
        <button
          class="mr-4 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-3 text-sm font-semibold leading-4 text-qualify-red-500 shadow-sm hover:bg-gray-50 focus:outline-none"
          type="button"
          @click="close()"
        >
          {{ $t('Close') }}
        </button>
        <button
          class="mr-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold leading-4 text-qualify-red-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          @click="save()"
        >
          {{ $t('Save & Apply') }}
        </button>
      </div>
    </template>
  </form-modal>
</template>
<script>
import FormModal from '../FormModal.vue'
import FrontCheckboxInput from '@/Shared/FrontCheckboxInput.vue'
import { DialogTitle } from '@headlessui/vue'

export default {
  components: {
    FormModal,
    DialogTitle,
    FrontCheckboxInput,
  },
  props: {
    evidence: Object,
    logicName: String,
    show: Boolean,
  },
  data() {
    return {
      evidenceData: this.evidenceData ?? {
        notes_required: false,
        media_required: false,
      },
    }
  },
  methods: {
    save() {
      this.$emit('save', this.evidenceData)
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>
