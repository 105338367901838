<template>
  <layout :title="$t('Create Room')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('locations')">
        {{ $t('Locations') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('locations.edit', location.id)">
        {{ location.name }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Add room') }}
    </h1>
    <div class="max-w-md overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" />
          </div>
          <text-input
            v-model="form.capacity"
            class="w-full pb-8 pr-6 lg:w-1/2"
            :error="form.errors.capacity"
            :label="$t('Capacity')"
          />
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Create Room') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Layout,
    LoadingButton,
    TextInput,
  },
  props: {
    location: Object,
  },
  data() {
    return {
      form: useForm(
        {
          name: null,
          capacity: null,
        },
        'RoomCreate'
      ),
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('rooms.store', this.location.id))
    },
  },
}
</script>
