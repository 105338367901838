<template>
  <form-list :common="common" :is-page="true" :list="list" />
</template>
<script>
import FormList from './FormList.vue'

export default {
  components: { FormList },
  provide() {
    return {
      formTemplate: this.formTemplate,
    }
  },
  props: {
    formTemplate: Object,
    list: Array,
    common: Object,
    formTemplateSelects: Array,
  },
}
</script>
