<template>
  <form class="flex w-full flex-col items-center justify-center" @submit.prevent="updateProfileImage">
    <input ref="file" class="hidden" type="file" @change="changeProfileImage" />
    <div class="relative flex h-40 w-40 justify-center rounded-full">
      <img
        v-if="(user && user.image_path) || imagePreview"
        :alt="$t('Image preview...')"
        class="h-40 w-40 cursor-pointer rounded-full object-cover"
        :src="imagePreview || user.image_path"
        @click="$refs.file.click()"
      />
      <button
        v-else
        class="flex h-40 w-40 cursor-pointer items-center justify-center rounded-full bg-gray-400 text-6xl font-bold tracking-wider text-white"
        type="button"
        @click="$refs.file.click()"
      >
        {{ firstInitial }}
      </button>
      <div v-if="user && !user.image_path && !imageChanged" @click="$refs.file.click()">
        <icon
          class="absolute bottom-0 right-0 mx-auto mb-1 mr-1 h-10 w-10 flex-shrink-0 cursor-pointer rounded-full border-4 border-white stroke-white p-1"
          :class="isTenantWorksite ? 'bg-qualify-red-500' : 'bg-blue-700'"
          name="camera"
        />
      </div>
      <div v-if="user && user.image_path && !imageChanged" @click="removeSavedProfileImage">
        <icon
          class="absolute bottom-0 right-0 mx-auto mb-1 mr-1 h-10 w-10 flex-shrink-0 cursor-pointer rounded-full border-4 border-white bg-qualify-red-500 fill-white stroke-white p-2"
          name="close"
        />
      </div>
    </div>
    <div v-if="imageChanged" class="-mx-64 mb-2 mt-6 text-center">
      <button class="btn-red" type="submit">{{ $t('Save') }}</button>
      <button class="ml-2 text-sm text-gray-700 hover:underline" type="button" @click="resetUpload">
        {{ $t('Cancel') }}
      </button>
    </div>
    <div v-if="form.errors.image" class="-mx-64 mb-2 whitespace-nowrap px-4 text-center">
      {{ form.errors.image }}
    </div>
  </form>
</template>
<script>
import Icon from './Icon.vue'
import { isTenantWorksite } from '@/Utils/Helpers'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Icon,
  },
  props: {
    user: Object,
    updateUrl: {
      type: String,
      required: true,
    },
    deleteUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      imagePreview: this.user?.image_path,
      imageChanged: false,
      form: useForm(
        {
          image: null,
        },
        'ProfileImageCreate'
      ),
    }
  },
  computed: {
    isTenantWorksite,
    firstInitial: function () {
      return this.user?.display_name?.charAt(0) ?? this.user?.first_name.charAt(0)
    },
  },
  methods: {
    changeProfileImage(event) {
      this.form.image = event.target.files[0] || null

      if (this.form.image && this.form.image.type.startsWith('image')) {
        let reader = new FileReader()
        reader.onload = (e) => (this.imagePreview = e.target.result)
        reader.readAsDataURL(this.form.image)
        this.imageChanged = true
      } else {
        this.imagePreview = this.user?.image_path
      }
    },
    resetUpload() {
      this.imageChanged = false
      this.form.image = this.image = null
    },
    updateProfileImage() {
      this.form
        .transform((data) => {
          return {
            ...data,
            _method: 'PUT',
          }
        })
        .post(this.updateUrl, { onSuccess: () => (this.imageChanged = false) })
    },
    removeSavedProfileImage() {
      this.$inertia.delete(this.deleteUrl, { preserveState: false })
    },
  },
}
</script>
