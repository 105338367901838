<template>
  <div class="ts-bg flex min-h-screen items-center justify-center p-6">
    <DynamicHead :title="$t('Reset Password | :app_name', { app_name: $page.props.app.name })" />
    <div class="w-full max-w-sm">
      <form class="mt-8 overflow-hidden rounded-lg bg-white shadow-lg" @submit.prevent="submit">
        <div class="px-10 py-12">
          <h1 class="text-center text-2xl font-bold">{{ $t('Reset Password') }}</h1>
          <div class="mx-auto mt-6 w-24 border-b-2" />
          <text-input
            v-model="form.email"
            autocapitalize="off"
            autofocus
            class="mt-10"
            :error="form.errors.email"
            :label="$t('Email')"
            type="email"
          />
          <text-input
            v-model="form.password"
            autocomplete="new-password"
            class="mt-6"
            :error="form.errors.password"
            :label="$t('Password')"
            type="password"
          />
          <text-input
            v-model="form.password_confirmation"
            autocomplete="new-password"
            class="mt-6"
            :error="form.errors.password_confirmation"
            :label="$t('Confirm password')"
            type="password"
          />
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-10 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Reset Password') }}
          </loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import DynamicHead from '@/Shared/DynamicHead.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    DynamicHead,
    LoadingButton,
    TextInput,
  },
  props: {
    token: String,
    email: String,
  },
  data() {
    return {
      form: useForm(
        {
          token: this.token,
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
        },
        'ResetPassword'
      ),
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('password.update'))
    },
  },
}
</script>
