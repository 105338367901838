<template>
  <layout :title="`Group : ${group.name}`">
    <div class="mb-8 flex justify-between">
      <div class="flex items-start">
        <breadcrumb-admin :path="breadcrumbs" />
        <icon v-if="group.archived_at" class="ml-4 h-4 w-4 flex-shrink-0 fill-gray-400" name="archive" />
      </div>
      <div>
        <InertiaLink class="btn-blue" :href="route('groups.edit', group.id)">
          <span class="whitespace-nowrap py-4">{{ $t('Edit') }}</span>
        </InertiaLink>
      </div>
    </div>
    <div class="flex-coln flex justify-between lg:flex-row">
      <div>
        <div
          v-if="group.description"
          class="mb-6 flex max-w-md flex-col justify-between rounded bg-white px-6 pb-4 pt-6 text-left shadow"
        >
          <h2 class="font-bold">{{ $t('Description') }}</h2>
          <p class="pb-2 pt-2">{{ group.description }}</p>
        </div>
      </div>
      <div>
        <div class="mb-6 flex max-w-md flex-col justify-between rounded bg-white px-6 pb-4 pt-6 text-left shadow">
          <div class="space-y-3 text-sm">
            <dl class="flex space-x-2">
              <dt class="col-span-3 font-semibold">{{ $t('Total Users:') }}</dt>
              <dd>{{ usersCount }}</dd>
            </dl>
            <dl class="flex space-x-2">
              <dt class="col-span-3 font-semibold">{{ $t('Group Managers:') }}</dt>
              <dd>{{ managersCount }}</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-6 flex flex-col-reverse items-center justify-between lg:flex-row">
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
        <label class="block text-gray-800">{{ $t('Role:') }}</label>
        <select v-model="form.role" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="">{{ $t('Member') }}</option>
          <option value="Manager">{{ $t('Manager') }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Archived:') }}</label>
        <select v-model="form.archived" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="with">{{ $t('With Archived') }}</option>
          <option value="only">{{ $t('Only Archived') }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Deleted:') }}</label>
        <select v-model="form.deleted" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="with">{{ $t('With Deleted') }}</option>
          <option value="only">{{ $t('Only Deleted') }}</option>
        </select>
      </search-filter>
      <div class="w-full overflow-hidden lg:w-1/3">
        <form class="w-full" @submit.prevent="addUserToGroup">
          <div class="flex w-full flex-col items-start md:flex-row">
            <dynamic-search-input
              v-model="addUser.user"
              class="mb-2 w-full"
              :error="addUser.form.errors.user_id"
              :search-by="['name', 'email']"
              track-by="id"
              :url="route('users.search', { organization: organization.id, archived: 'with' })"
            >
              <div v-if="addUser.user" class="flex items-center justify-between">
                <div class="truncate">{{ addUser.user.name }}</div>
                <div class="whitespace-nowrap text-xs text-gray-600">
                  {{ addUser.user.email }}
                </div>
              </div>
              <template #option="{ option, selected }">
                <div class="flex items-center justify-between">
                  <div>{{ option.name }}</div>
                  <div class="text-xs" :class="{ 'text-gray-600': !selected, 'text-white': selected }">
                    {{ option.email }}
                  </div>
                </div>
              </template>
            </dynamic-search-input>
            <loading-button class="btn-blue mb-2" :loading="addUser.form.processing" type="submit">
              {{ $t('Add User') }}
            </loading-button>
          </div>
        </form>
      </div>
    </div>
    <div class="rounded bg-white shadow">
      <SimpleTable class="w-full">
        <template #head>
          <TableHead>{{ $t('Name') }}</TableHead>
          <TableHead>{{ $t('Email') }}</TableHead>
          <TableHead />
        </template>
        <TableRow v-if="users.data.length === 0">
          <TableData class="border-t px-6 py-4" colspan="5">{{ $t('No members yet.') }}</TableData>
        </TableRow>
        <TableRow v-for="user in users.data" :key="user.id" class="hover:bg-gray-100 focus:bg-gray-100">
          <TableData class="border-t px-6 py-0">
            <div class="flex items-center space-x-2">
              <InertiaLink class="hover:underline focus:underline" :href="route('users.edit', user)">
                <span class="whitespace-nowrap py-4">{{ user.name }}</span>
              </InertiaLink>
              <span v-if="user.organization_role !== 'basic'" class="rounded bg-gray-300 px-2 py-1 text-xs">
                {{ user.organization_role_label }}
              </span>
              <archived-badge v-if="user.archived_at" />
              <deleted-badge v-if="user.deleted_at" />
            </div>
          </TableData>
          <TableData class="whitespace-nowrap border-t px-6 py-4">{{ user.email }}</TableData>
          <TableData class="w-min border-t px-4 align-middle text-red-500 hover:text-red-500 focus:text-red-500">
            <button @click="removeUserFromGroup(user)">{{ $t('Delete') }}</button>
          </TableData>
        </TableRow>
      </SimpleTable>
    </div>
    <pagination v-if="users && users.links" :links="users.links" />
  </layout>
</template>

<script>
import ArchivedBadge from '@/Shared/ArchivedBadge.vue'
import BreadcrumbAdmin from '@/Shared/BreadcrumbAdmin.vue'
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import DynamicSearchInput from '@/Shared/DynamicSearchInput.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import { useForm } from '@inertiajs/vue3'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
    ArchivedBadge,
    BreadcrumbAdmin,
    DeletedBadge,
    DynamicSearchInput,
    Icon,
    Layout,
    LoadingButton,
    Pagination,
    SearchFilter,
  },
  props: {
    filters: Object,
    organization: Object,
    group: Object,
    users: Object,
    usersCount: Number,
    managersCount: Number,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        role: this.filters.role,
        archived: this.filters.archived,
        deleted: this.filters.deleted,
      },
      addUser: {
        user: null,
        form: useForm({
          user_id: null,
        }),
      },
      breadcrumbs: [
        {
          name: this.$t('Organizations'),
          url: this.route('organizations.index'),
        },
        {
          name: this.organization.name,
          url: this.route('organizations.edit', this.organization),
        },
        {
          name: this.$t('Groups'),
          url: this.route('organizations.groups', this.organization),
        },
        {
          name: this.group.name,
        },
      ],
    }
  },
  watch: {
    'addUser.user': function (newUser) {
      this.addUser.form.user_id = newUser?.id
    },
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(
          this.route('groups.show', this.group.id),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
  },
  methods: {
    addUserToGroup() {
      if (this.addUser.form.user_id) {
        this.addUser.form.post(this.route('groups.members.store', [this.group, this.addUser.form.user_id]), {
          onSuccess: () => (this.addUser.user = null),
        })
      }
    },
    removeUserFromGroup(user) {
      this.$inertia.delete(this.route('groups.members.destroy', [this.group, user]))
    },
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
