<template>
  <front-layout :title="$t('Edit File')">
    <div class="item-center mb-8 flex max-w-2xl justify-between">
      <breadcrumb
        class="font-heading text-2xl font-semibold"
        :name="form.name ?? $t('Edit')"
        :previous-name="folder.name"
        :previous-url="route('front.admin.folders')"
      />
      <div class="flex">
        <button v-if="file.deleted_at" class="hover:underline" tabindex="-1" type="button" @click="restore">
          {{ $t('Restore') }}
        </button>
        <button v-else class="hover:underline" tabindex="-1" type="button" @click="destroy">
          {{ $t('Archive') }}
        </button>
        <front-loading-button class="ml-4" :disabled="!form.isDirty" :loading="form.processing" @click="submit">
          {{ $t('Save') }}
        </front-loading-button>
      </div>
    </div>
    <form @submit.prevent="submit">
      <!-- Manual Name -->
      <div class="max-w-2xl overflow-hidden rounded bg-white shadow">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <front-text-input v-model="form.name" :error="form.errors.name" :label="$t('Manual Name')" />
          </div>
          <div class="w-full pb-8 pr-6">
            <front-file-upload-input
              v-model="form.file"
              :initial-value="form.file"
              label="Upload New Version"
              :max-items="1"
              :min-items="1"
              ref-name="editFolderFileUploadInput"
              :validation-rules="{
                maxSizeInKB: 1024 * 100,
              }"
            />
          </div>
        </div>
      </div>
      <!-- Files -->
      <div class="flex max-w-2xl items-center justify-between pb-6 pt-8">
        <span class="font-heading text-xl">Versions</span>
      </div>
      <div v-for="(upload, index) in file.uploads" :key="upload.id">
        <div class="flex max-w-2xl flex-col overflow-hidden bg-white p-6 shadow">
          <div class="flex items-center">
            <div class="flex w-full flex-col pl-4 text-lg">
              <div class="flex items-center">
                <div>{{ upload.file_name }}</div>
                <div
                  v-if="index == 0"
                  class="ml-2 rounded-full px-2 py-1 text-xs text-qualify-red-500 outline outline-1"
                >
                  current
                </div>
              </div>
              <span class="text-sm">Uploaded on {{ upload.created_at }}</span>
            </div>
            <div class="flex items-center justify-end">
              <a class="flex justify-end text-gray-500 hover:text-gray-600" :href="upload.url" target="_blank">
                <icon class="inline h-6 w-6 rounded-full fill-darkGray-1000" name="download-modern" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </form>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontFileUploadInput from '@/Shared/FrontFileUploadInput.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import Icon from '@/Shared/Icon.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    FrontFileUploadInput,
    FrontLayout,
    FrontLoadingButton,
    FrontTextInput,
    Icon,
  },
  props: {
    file: Object,
    folder: Object,
  },
  data() {
    return {
      form: useForm({
        name: this.file.name,
        file: null,
      }),
    }
  },
  methods: {
    submit() {
      this.form.put(this.route('front.admin.files.update', [this.folder, this.file]))
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to archive this file?'))) {
        this.$inertia.post(this.route('front.admin.files.archive', [this.folder, this.file]))
      }
    },
    restore() {
      if (confirm(this.$t('Are you sure you want to restore this file?'))) {
        this.$inertia.post(this.route('front.admin.files.restore', [this.folder, this.file]))
      }
    },
  },
}
</script>
