<template>
  <front-layout :title="course.name">
    <div class="flex justify-between">
      <div class="ts-gray-text-bold mb-8 text-3xl font-semibold">
        {{ course.name }}
      </div>

      <div v-if="enrolment.status !== 'Complete'">
        <front-locale-toggle
          v-if="enrolment.can.changeLocale"
          :current-locale="enrolment.locale"
          :locale-options="course.available_locales"
          @change="onLocaleChange"
        />
      </div>
    </div>

    <div class="flex flex-col lg:flex-row">
      <!-- Certificate Progress -->
      <section class="w-full lg:w-2/5 xl:w-1/3">
        <div class="rounded-lg bg-cover" style="background-image: url('/images/gradient-bg.svg')">
          <div
            class="flex flex-col px-4 pt-5 text-white sm:flex-row sm:space-y-0 sm:py-5 lg:flex-col lg:space-y-8"
            :class="{ 'space-y-12': !certificateSectionCollapsed }"
          >
            <div class="pb w-full md:w-full">
              <h3 v-if="enrolment.status !== 'Complete'" class="mb-5 mt-4 text-center text-2xl font-semibold">
                {{ $t('Certificate Progress') }}
              </h3>
              <h3 v-else class="mb-5 mt-4 text-center text-2xl font-semibold">{{ $t('Certificate Earned') }}</h3>

              <div class="mt-8 px-5 sm:block" :class="{ hidden: certificateSectionCollapsed }">
                <svg
                  class="mx-auto"
                  height="121.196"
                  viewBox="0 0 126.967 121.196"
                  width="126.967"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Group_6472" data-name="Group 6472" transform="translate(-677.231 -628.397)">
                    <g id="Group_6471" data-name="Group 6471" transform="translate(677.231 628.397)">
                      <path
                        id="Path_7207"
                        d="M804.2,688.937l-14.082-16.1,1.962-21.3-20.834-4.733L760.337,628.4l-19.622,8.426L721.093,628.4l-10.908,18.41-20.834,4.675,1.962,21.354-14.082,16.1,14.082,16.1-1.962,21.354,20.834,4.732,10.908,18.467,19.622-8.484,19.622,8.426,10.908-18.41,20.834-4.732-1.962-21.3Zm-22.8,8.542-3.231,3.751.461,4.905,1.039,11.254-10.965,2.482-4.848,1.1-2.539,4.271-5.713,9.7-10.274-4.444-4.617-1.963-4.56,1.963-10.273,4.444L720.17,725.3l-2.539-4.271-4.848-1.1-10.966-2.481,1.039-11.312.462-4.905-3.232-3.751L692.64,689l7.445-8.542,3.232-3.751-.519-4.964-1.039-11.2,10.964-2.482,4.848-1.1,2.539-4.271,5.714-9.7,10.273,4.444,4.617,1.962,4.56-1.962L755.547,643l5.714,9.7,2.539,4.271,4.847,1.1,10.965,2.482L778.574,671.8l-.461,4.906,3.232,3.751,7.445,8.483Z"
                        data-name="Path 7207"
                        fill="#fff"
                        transform="translate(-677.231 -628.397)"
                      />
                      <path
                        id="Path_7208"
                        d="M726.347,695.067,712.958,681.62l-8.542,8.6,21.931,21.988,42.361-42.476-8.541-8.6Z"
                        data-name="Path 7208"
                        fill="#fff"
                        transform="translate(-673.886 -624.369)"
                      />
                    </g>
                  </g>
                </svg>

                <div v-if="finishedContentsCount === 0 && enrolment.status !== 'Complete'" class="mt-5">
                  <p class="mb-2 inline-block text-center font-nexa leading-relaxed sm:hidden md:inline-block">
                    {{
                      $t(
                        'Once the course content has been completed, you will have access to your certificate of completion.'
                      )
                    }}
                  </p>

                  <div class="mb-2 text-right font-semibold">0 / 100%</div>
                  <progress-bar
                    class="h-4"
                    fill-bg-class="bg-white"
                    :model-value="certificateProgress"
                    track-bg-class="bg-white bg-opacity-8"
                  />
                </div>

                <div v-else-if="enrolment.status === 'Complete'" class="mt-5">
                  <div class="mt-10 flex flex-col xl:justify-center">
                    <a
                      v-if="certificate"
                      class="mb-4 flex w-full items-center rounded-lg bg-white py-4 pl-3 text-left text-lg font-semibold text-darkGray-1000 hover:cursor-pointer hover:bg-gray-200"
                      :href="route('front.certificates.download.all', [certificate])"
                    >
                      <icon class="block h-6 w-6 fill-darkGray-1000" name="download-modern" />
                      <span class="ml-2">{{ $t('Download') }}</span>
                    </a>
                    <button
                      v-if="certificate && !certificate.user_signature_id"
                      class="mb-4 flex w-full items-center rounded-lg bg-white py-4 pl-3 text-left text-lg font-semibold text-darkGray-1000 hover:cursor-pointer hover:bg-gray-200"
                      @click="showSignaturePadModal = true"
                    >
                      <icon class="mr-2 block h-6 w-6 text-black" name="pencil" />
                      {{ $t('Sign Certificate') }}
                    </button>
                    <button
                      v-if="certificate && !certificate.manager_signature_id"
                      class="mb-4 flex w-full items-center rounded-lg bg-white py-4 pl-3 text-left text-lg font-semibold text-darkGray-1000 hover:cursor-pointer hover:bg-gray-200"
                      @click="onClickCreateEmployerSignatureRequest()"
                    >
                      <icon class="mr-2 block h-6 w-6 text-black" name="pencil" />
                      {{ $t('Request Signature') }}
                    </button>
                    <button
                      v-if="certificate"
                      class="mb-4 flex w-full items-center rounded-lg bg-white py-4 pl-3 text-left text-lg font-semibold text-darkGray-1000 hover:cursor-pointer hover:bg-gray-200"
                      @click=";(showEmailExportModal = true), (desktopDropdownOpened = false)"
                    >
                      <icon class="block h-6 w-6 text-darkGray-1000" name="email" />
                      <span class="ml-2">{{ $t('Send by Email') }}</span>
                    </button>
                    <button
                      class="mb-4 flex w-full items-center rounded-lg bg-white py-4 pl-3 text-left text-lg font-semibold text-darkGray-1000 hover:cursor-pointer hover:bg-gray-200"
                      @click="recertify"
                    >
                      <icon class="mr-2 block h-6 w-6 fill-darkGray-1000" name="certificate" />
                      {{ $t('Recertify') }}
                    </button>
                  </div>
                </div>

                <div v-else class="my-4 hidden w-full px-2 sm:inline-block xl:px-8">
                  <p class="mb-2 inline-block text-center font-nexa leading-relaxed sm:hidden md:inline-block">
                    {{
                      $t(
                        'Once the course content has been completed, you will have access to your certificate of completion.'
                      )
                    }}
                  </p>

                  <div class="mb-2 text-right font-semibold">
                    {{ $t(':progress / 100%', { progress: certificateProgress }) }}
                  </div>
                  <progress-bar
                    v-if="certificateProgress !== 100"
                    class="h-4"
                    fill-bg-class="bg-white"
                    :model-value="certificateProgress"
                    track-bg-class="bg-white bg-opacity-8"
                  />
                </div>

                <div v-if="enrolment.resets_in_days !== null" class="my-2 hidden w-full px-2 sm:inline-block xl:px-8">
                  <div class="w-full rounded bg-white px-2 py-2 text-center text-black opacity-75">
                    <span v-if="enrolment.resets_in_days == 0">
                      {{ $t('Progress resets soon') }}
                    </span>
                    <span v-else>
                      {{ $t('Progress resets in :days_count days', { days_count: enrolment.resets_in_days }) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="!certificateSectionCollapsed">
              <p class="font-nexa leading-relaxed sm:inline-block md:hidden">
                {{
                  $t(
                    'Once the course content has been completed, you will have access to your certificate of completion.'
                  )
                }}
              </p>

              <div v-if="enrolment.resets_in_days !== null" class="my-2 inline-block w-full px-2 sm:hidden xl:px-8">
                <div class="w-full rounded bg-white px-2 py-2 text-center text-black opacity-75">
                  <span v-if="enrolment.resets_in_days == 0">
                    {{ $t('Progress resets soon') }}
                  </span>
                  <span v-else>
                    {{ $t('Progress resets in :days_count days', { days_count: enrolment.resets_in_days }) }}
                  </span>
                </div>
              </div>

              <div v-if="enrolment.status === 'Complete'" class="px-3">
                <div class="mb-6 mt-2 flex flex-col md:hidden">
                  <a
                    class="mb-4 flex w-full items-center rounded-lg bg-white py-4 pl-3 text-left text-lg font-semibold text-darkGray-1000 hover:cursor-pointer hover:bg-gray-200"
                    :href="route('front.certificates.download.all', [certificate])"
                  >
                    <icon class="block h-6 w-6 fill-darkGray-1000" name="download-modern" />
                    <span class="ml-2">{{ $t('Download') }}</span>
                  </a>
                  <button
                    class="relative flex w-full items-center rounded-lg bg-white py-4 pl-3 text-left text-lg font-semibold text-darkGray-1000 hover:cursor-pointer hover:bg-gray-200"
                    @click="mobileDropdownOpened = !mobileDropdownOpened"
                  >
                    <div
                      class="absolute bottom-0 right-0 top-0 flex w-12 items-center rounded-br-lg rounded-tr-lg bg-gray-300"
                    >
                      <icon
                        class="mx-auto h-8 w-8 transform fill-darkGray-1000 transition-transform duration-100 ease-in-out"
                        :class="{ 'rotate-180': !mobileDropdownOpened }"
                        name="chevron-down"
                      />
                    </div>
                    <icon class="block h-5 w-5 fill-darkGray-1000" name="share" />
                    <span class="ml-2">{{ $t('View Options') }}</span>
                    <div v-if="mobileDropdownOpened" class="absolute left-0 right-0 top-0 mt-16 rounded-lg">
                      <button
                        class="flex w-full items-center rounded-lg bg-white py-5 pl-3 text-left text-lg font-semibold text-darkGray-1000 hover:cursor-pointer hover:bg-gray-200"
                        @click=";(showEmailExportModal = true), (mobileDropdownOpened = false)"
                      >
                        {{ $t('Email') }}
                      </button>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="pb-4 sm:hidden">
            <span @click="certificateSectionCollapsed = !certificateSectionCollapsed">
              <icon
                class="mx-auto h-10 w-10 transform fill-white transition-transform duration-100 ease-in-out"
                :class="{ 'rotate-180': !certificateSectionCollapsed }"
                name="chevron-down"
              />
            </span>
          </div>
        </div>
      </section>

      <!-- Enrolment Contents -->
      <section class="mt-2 w-full lg:ml-4 lg:mt-0 lg:w-3/5 xl:ml-12 xl:w-2/3">
        <div class="bg-white px-8 py-6 text-ts-gray-text">
          <h3 class="mb-5 mt-4 border-b border-ts-gray-300 pb-5 text-xl font-semibold text-ts-gray-text-bold">
            {{ $t('Course Content') }}
          </h3>

          <div v-for="(enrolmentContent, index) in enrolmentContents" :key="enrolmentContent.id">
            <content-class
              v-if="enrolmentContent.content.type === 'instructor-led-classroom'"
              :enrolment="enrolment"
              :enrolment-content="enrolmentContent"
              :is-last="index === enrolmentContents.length - 1"
            />
            <content-link
              v-else-if="enrolmentContent.content.type === 'link'"
              :enrolment="enrolment"
              :enrolment-content="enrolmentContent"
              :is-last="index === enrolmentContents.length - 1"
            />
            <content-file
              v-else-if="enrolmentContent.content.type === 'file'"
              :enrolment="enrolment"
              :enrolment-content="enrolmentContent"
              :is-last="index === enrolmentContents.length - 1"
            />
            <content-quiz
              v-else-if="enrolmentContent.content.type === 'quiz'"
              :enrolment="enrolment"
              :enrolment-content="enrolmentContent"
              :is-last="index === enrolmentContents.length - 1"
            />
            <content-e-learning
              v-else-if="enrolmentContent.content.type === 'elearning'"
              :enrolment="enrolment"
              :enrolment-content="enrolmentContent"
              :is-last="index === enrolmentContents.length - 1"
            />
            <content-checkin-form
              v-else-if="enrolmentContent.content.type === 'check-in-form'"
              :enrolment="enrolment"
              :enrolment-content="enrolmentContent"
              :is-last="index === enrolmentContents.length - 1"
            />
          </div>
        </div>
      </section>
    </div>
    <front-email-export-modal
      v-if="certificate"
      :certificate="certificate"
      :show="showEmailExportModal"
      @close="showEmailExportModal = false"
    />
    <create-employer-signature-request-for-organization-modal
      v-if="certificate && $page.props.auth.user.organization?.id"
      :certificate="certificate"
      :show="showCreateEmployerSignatureRequestModal"
      @close="showCreateEmployerSignatureRequestModal = false"
    />
    <create-employer-signature-request-modal
      v-else-if="certificate && !$page.props.auth.user.organization?.id"
      :certificate="certificate"
      :show="showCreateEmployerSignatureRequestModal"
      @close="showCreateEmployerSignatureRequestModal = false"
    />
    <front-signature-modal
      v-if="certificate && showSignaturePadModal"
      :certificates="[certificate.id]"
      role="user"
      :show="showSignaturePadModal"
      @close="showSignaturePadModal = false"
      @signed="submitSignature"
    />
    <recertify :show="showRecertify" @close="closeRecertify" />
    <front-confirm-modal
      :show="showConfirmLocaleChange"
      @cancel="showConfirmLocaleChange = false"
      @confirm="onConfirmLocaleChange"
    >
      <template #title>{{ $t('Confirm Language Change') }}</template>
      <template #content>
        <div class="flex flex-col space-y-4 text-center">
          <p>
            {{ $t("You're about to reset your enrolment progress for the course") }}
            <b>{{ course.name }}</b>
            {{ $t(', with this change.') }}
          </p>
          <p>
            {{ $t('All your progress') }}
            <span v-if="enrolment.has_students">
              {{ $t('and class reservation will be removed with this change') }}
            </span>
          </p>
          <p>{{ $t('Are you sure you want to do that?') }}</p>
        </div>
      </template>
    </front-confirm-modal>
  </front-layout>
</template>

<script>
import ContentCheckinForm from './ContentCheckinForm.vue'
import ContentClass from './ContentClass.vue'
import ContentELearning from './ContentELearning.vue'
import ContentFile from './ContentFile.vue'
import ContentLink from './ContentLink.vue'
import ContentQuiz from './ContentQuiz.vue'
import CreateEmployerSignatureRequestForOrganizationModal from './CreateEmployerSignatureRequestForOrganizationModal.vue'
import CreateEmployerSignatureRequestModal from './CreateEmployerSignatureRequestModal.vue'
import FrontConfirmModal from '@/Shared/FrontConfirmModal.vue'
import FrontEmailExportModal from '@/Shared/FrontEmailExportModal.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLocaleToggle from '@/Shared/FrontLocaleToggle.vue'
import FrontSignatureModal from '@/Shared/FrontSignatureModal.vue'
import Icon from '@/Shared/Icon.vue'
import ProgressBar from '@/Shared/ProgressBar.vue'
import Recertify from '@/Shared/Recertify.vue'
import { isTenantWorksite, query } from '@/Utils/Helpers'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    ContentClass,
    ContentLink,
    ContentFile,
    ContentQuiz,
    ContentELearning,
    ContentCheckinForm,
    CreateEmployerSignatureRequestModal,
    CreateEmployerSignatureRequestForOrganizationModal,
    FrontConfirmModal,
    FrontEmailExportModal,
    FrontLayout,
    FrontLocaleToggle,
    FrontSignatureModal,
    Icon,
    ProgressBar,
    Recertify,
  },
  props: {
    organization: Object,
    course: Object,
    enrolment: Object,
    certificate: Object,
    enrolmentContents: Array,
    certificateProgress: Number,
  },
  data() {
    return {
      certificateSectionCollapsed: true,
      desktopDropdownOpened: false,
      mobileDropdownOpened: false,
      showEmailExportModal: false,
      showRecertify: false,
      showSignaturePadModal: false,
      showCreateEmployerSignatureRequestModal: false,
      showConfirmLocaleChange: false,
      exportEmail: 'test@test.com',
      selectedLocale: null,
      form: useForm({
        certificates: [this.certificate?.id],
        image: null,
      }),
    }
  },
  computed: {
    isTenantWorksite,
    finishedContentsCount() {
      return this.enrolmentContents.filter((enrolmentContent) => !!enrolmentContent.finished_at).length
    },
  },
  mounted() {
    Echo.private(`elearning.${this.enrolment.id}`).listen('ElearningProgressUpdated', (e) => {
      this.enrolmentContents.forEach((enrolmentContent) => {
        if (enrolmentContent.id === e.enrolmentContent.id) {
          enrolmentContent.finished_at = Date.now()
        }
      })
    })
  },
  methods: {
    query,
    sendEmailExport() {
      this.$inertia.visit(
        this.route('front.certificates.email', { certificate: this.certificate.id, email: this.exportEmail })
      )
    },
    recertify() {
      if (this.certificate && this.certificate.can.recertify) {
        this.$inertia.visit(this.route('front.courses'))
      } else {
        this.showRecertify = true
      }
    },
    submitSignature(image) {
      this.form.image = image
      this.form.post(this.route('front.certificates.signature.store'), {
        onSuccess: () => {
          this.form.reset()
        },
      })
    },
    closeRecertify() {
      this.showRecertify = false
    },
    onClickCreateEmployerSignatureRequest() {
      if (this.certificate) {
        this.showCreateEmployerSignatureRequestModal = true
      }
    },
    onLocaleChange(locale) {
      this.selectedLocale = locale
      if (locale) {
        if (this.enrolment.is_started) {
          this.showConfirmLocaleChange = true
        } else {
          this.$inertia.put(this.route('front.enrolments.locale.update', this.enrolment), {
            locale: this.selectedLocale,
          })
        }
      }
    },
    onConfirmLocaleChange() {
      this.showConfirmLocaleChange = false
      this.$inertia.put(this.route('front.enrolments.locale.update', this.enrolment), { locale: this.selectedLocale })
    },
  },
}
</script>
