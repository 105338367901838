<template>
  <layout :title="$t('Tenants')">
    <div class="mb-8">
      <breadcrumb :name="$t('Tenants')" />
    </div>
    <div class="mb-6 flex items-center justify-between">
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
        <label class="block text-gray-800">{{ $t('Deleted:') }}</label>
        <select v-model="form.deleted" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="with">{{ $t('With Deleted') }}</option>
          <option value="only">{{ $t('Only Deleted') }}</option>
        </select>
      </search-filter>
      <InertiaLink class="btn-blue" :href="route('tenants.create')">
        <span>{{ $t('Create') }}</span>
        <span class="hidden md:inline">{{ $t('Tenant') }}</span>
      </InertiaLink>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow">
      <table class="w-full">
        <thead>
          <tr>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Name') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Domain') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Users') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Notifications Enabled') }}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-if="tenants.data.length === 0">
            <td class="border-t px-6 py-4" colspan="4">{{ $t('No tenants found.') }}</td>
          </tr>
          <tr
            v-for="tenant in tenants.data"
            :key="tenant.id"
            class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
            @click="$inertia.visit(route('tenants.edit', tenant.id))"
          >
            <td class="whitespace-nowrap border-t px-6 py-4">
              <div class="flex items-center">
                {{ tenant.name }}
                <deleted-badge v-if="tenant.deleted_at" />
              </div>
            </td>
            <td class="border-t px-6 py-4">{{ tenant.domain }}</td>
            <td class="border-t px-6 py-4">{{ tenant.users_count }}</td>
            <td class="border-t px-6 py-4">{{ tenant.students_upcoming_classes_notification_enabled }}</td>
            <td class="w-min border-t px-4 align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination :links="tenants.links" />
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    Breadcrumb,
    DeletedBadge,
    Icon,
    Layout,
    Pagination,
    SearchFilter,
  },
  props: {
    tenants: Object,
    filters: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        deleted: this.filters.deleted,
      },
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)

        this.$inertia.get(this.route('tenants'), Object.keys(query).length ? query : { remember: 'forget' }, {
          preserveState: true,
          replace: true,
        })
      }, 300),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
