<template>
  <layout :title="$t('Instructor Led Classes')">
    <h1 class="mb-8 text-xl font-bold">{{ $t('Instructor Led Classes') }}</h1>
    <div class="mb-6 flex items-center justify-between">
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
        <label class="block text-gray-800">{{ $t('Archived:') }}</label>
        <select v-model="form.archived" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="with">{{ $t('With Archived') }}</option>
          <option value="only">{{ $t('Only Archived') }}</option>
        </select>
      </search-filter>
      <InertiaLink class="btn-blue" :href="route('instructor-led-classrooms.create')">
        <span>{{ $t('Create') }}</span>
        <span class="hidden md:inline">{{ $t('Instructor Led Class') }}</span>
      </InertiaLink>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow">
      <SimpleTable>
        <template #head>
          <TableHead>{{ $t('Name') }}</TableHead>
          <TableHead>{{ $t('Title') }}</TableHead>
          <TableHead class="text-right">{{ $t('Num. of Classes') }}</TableHead>
          <TableHead class="text-right">{{ $t('Capacity') }}</TableHead>
          <TableHead />
        </template>
        <TableRow v-if="classrooms.data.length === 0">
          <TableData colspan="3">{{ $t('No instructor led classrooms found.') }}</TableData>
        </TableRow>
        <TableRow
          v-for="(classroom, key) in classrooms.data"
          :key="key"
          :clickable="true"
          @click="$inertia.visit(route('instructor-led-classrooms.edit', classroom.id))"
        >
          <TableData>
            {{ classroom.name }}
            <icon v-if="classroom.archived_at" class="ml-2 h-4 w-4 flex-shrink-0 fill-gray-400" name="archive" />
          </TableData>
          <TableData>{{ classroom.title }}</TableData>
          <TableData class="text-right">{{ classroom.classes_count }}</TableData>
          <TableData class="text-right">{{ classroom.capacity }}</TableData>
          <TableData class="w-min align-middle">
            <icon class="ml-auto h-6 w-6 fill-gray-400" name="chevron-right" />
          </TableData>
        </TableRow>
      </SimpleTable>
    </div>
    <pagination :links="classrooms.links" />
  </layout>
</template>

<script>
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import _debounce from 'lodash/debounce'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'

export default {
  components: {
    SimpleTable,
    TableHead,
    TableData,
    TableRow,
    Icon,
    Layout,
    Pagination,
    SearchFilter,
  },
  props: {
    classrooms: Object,
    filters: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        archived: this.filters.archived,
      },
    }
  },
  watch: {
    form: {
      handler: _debounce(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(
          this.route('instructor-led-classrooms'),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
