<template>
  <dynamic-search-input
    v-model="selectedClass"
    :error="error"
    :label="label"
    :search-by="['location.name', 'location.client_site', 'room', 'dates', 'available_seats']"
    track-by="id"
    :url="route('front.classes.search', { course: courseId })"
  >
    <div v-if="selectedClass" class="flex items-center justify-between">
      <div class="truncate">{{ selectedClass.formatted_name }}</div>
    </div>
    <template #option="{ option, selected }">
      <div class="flex items-center justify-between">
        <div class="text-xs" :class="{ 'text-gray-600': !selected, 'text-white': selected }">
          {{ option.formatted_name }}
        </div>
      </div>
    </template>
  </dynamic-search-input>
</template>

<script>
import DynamicSearchInput from './DynamicSearchInput.vue'

export default {
  components: {
    DynamicSearchInput,
  },
  props: {
    modelValue: [Object],
    courseId: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      default: 'Class',
    },
    error: String,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      selectedClass: this.modelValue,
    }
  },
  watch: {
    selectedClass(newSelectedClass) {
      this.$emit('update:modelValue', newSelectedClass)
    },
  },
}
</script>
