<template>
  <div class="m-8 overflow-hidden rounded bg-white shadow md:w-7/12">
    <form autocomplete="off" @submit.prevent="submit">
      <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Organization') }}</div>
      <div class="-mb-8 -mr-6 flex flex-wrap p-8">
        <div class="w-full pb-8 pr-6 lg:w-1/2">
          <dynamic-search-input
            v-model="selectedOrganization"
            :error="form.errors.organization_id"
            :label="$t('Organization')"
            :search-by="['name']"
            track-by="id"
            :url="route('organizations.search')"
          >
            <div v-if="selectedOrganization" class="truncate">{{ selectedOrganization.name }}</div>
            <template #option="{ option }">
              {{ option.name }}
            </template>
          </dynamic-search-input>
        </div>
        <div class="w-full pb-8 pr-6 lg:w-1/2">
          <label class="form-label">{{ $t('Groups:') }}</label>
          <Multiselect
            v-model="form.groups"
            deselect-label=""
            :disabled="!selectedOrganization"
            :hide-label="true"
            :hide-selected="true"
            :label="$t('name')"
            :loading="isGroupSearching"
            :multiple="true"
            :name="$t('Groups')"
            :options="organizationGroups"
            :options-limit="30"
            :placeholder="$t('Select groups')"
            select-label=""
            track-by="id"
            @search-change="searchGroups"
          >
            <template #noResult>{{ $t('No groups found.') }}</template>
            <template #noOptions>{{ $t('No groups found.') }}</template>
          </Multiselect>
          <div v-if="groupErrors.length" class="form-error">
            <span v-for="(groupError, index) in groupErrors" :key="index">{{ groupError }}</span>
          </div>
        </div>
      </div>
      <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Roles & Access') }}</div>
      <div class="-mb-8 -mr-6 flex flex-wrap p-8">
        <div class="w-full pb-8 pr-6 lg:w-1/2">
          <organization-role-dropdown v-model="form.organization_role" :error="form.errors.organization_role" />
        </div>
        <div v-if="selectedOrganization" class="w-full pb-8 pr-6 lg:w-1/2">
          <radio-input
            v-model="form.is_owner"
            :error="form.errors.is_owner"
            :label="$t('Is an organization owner')"
            type="radio"
          />
        </div>
      </div>
      <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
        <loading-button class="btn-blue" :loading="form.processing" type="submit">
          {{ $t('Update') }}
        </loading-button>
      </div>
    </form>
  </div>
</template>

<script>
import UserEditLayoutVue from './UserEditLayout.vue'
import DynamicSearchInput from '@/Shared/DynamicSearchInput.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import OrganizationRoleDropdown from '@/Shared/OrganizationRoleDropdown.vue'
import RadioInput from '@/Shared/RadioInput.vue'
import { fetchUser } from '@/Stores/ActiveUser'
import Http from '@/Utils/Http'
import { useForm } from '@inertiajs/vue3'
import Multiselect from 'vue-multiselect'

export default {
  components: { Multiselect, DynamicSearchInput, LoadingButton, OrganizationRoleDropdown, RadioInput },
  layout: [Layout, UserEditLayoutVue],
  props: {
    user: Object,
  },
  data() {
    return {
      selectedOrganization: this.user.organization,
      organizationGroups: this.user.organization?.groups || [],
      isGroupSearching: false,
      form: useForm({
        organization_id: this.user.organization_id,
        groups: this.user.groups || [],
        is_owner: this.user.is_owner,
        organization_role: this.user.organization_role,
      }),
    }
  },
  computed: {
    groupErrors: function () {
      const groupErrors = []
      this.form.groups.forEach((group, index) => {
        if (this.form.errors[`groups.${index}`]) {
          groupErrors.push(this.form.errors[`groups.${index}`])
        }
      })
      return groupErrors
    },
  },
  watch: {
    selectedOrganization(organization) {
      this.form.is_owner = false
      this.form.organization_id = organization ? organization.id : null

      if (organization && organization.id === this.user.organization?.id) {
        this.form.groups = this.user.groups
        this.organizationGroups = this.user.groups
      } else if (!organization?.id) {
        this.form.groups = []
        this.organizationGroups = []
      } else {
        this.form.groups = []
        Http.get(this.route('groups.search'), { params: { search: '', organization: organization?.id } }).then(
          (response) => {
            this.organizationGroups = response.data.data
          }
        )
      }
    },
  },
  methods: {
    submit() {
      this.form
        .transform((data) => ({
          organization_id: data.organization_id,
          groups: data.groups ? data.groups.map((group) => group.id) : [],
          is_owner: data.organization_id ? data.is_owner : false,
          organization_role: data.organization_role,
        }))
        .put(this.route('users.organization.update', this.user.id), {
          preserveScroll: true,
          onSuccess: () => {
            fetchUser()
          },
        })
    },
    searchGroups(searchParam) {
      this.isGroupSearching = true
      this.users = []

      Http.get(this.route('groups.search'), {
        params: { search: searchParam, organization: this.user.organization?.id },
      })
        .then((response) => {
          this.organizationGroups = response.data.data
        })
        .finally(() => {
          this.isGroupSearching = false
        })
    },
  },
}
</script>
