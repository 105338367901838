<template>
  <TransitionRoot as="template" :show="open">
    <HeadlessUiDialog as="div" class="relative z-40">
      <div class="fixed inset-0 overflow-hidden backdrop-brightness-50">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="flex h-full flex-col overflow-y-auto bg-gray-50 shadow-xl">
                  <slot name="content" />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </HeadlessUiDialog>
  </TransitionRoot>
</template>

<script>
import { DialogPanel, Dialog as HeadlessUiDialog, TransitionChild, TransitionRoot } from '@headlessui/vue'

export default {
  components: {
    HeadlessUiDialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
  },
  props: {
    open: Boolean,
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>
