<template>
  <div class="p-8">
    <app-head title="Organization EHS" />
    <div>
      <!-- Subscription Access -->
      <div class="mt-4 flex items-end justify-between">
        <h2 class="text-lg">{{ $t('User Subscription Access') }}</h2>
      </div>
      <div class="mt-4 overflow-x-auto rounded bg-white shadow">
        <SimpleTable>
          <template #head>
            <TableHead>{{ $t('Name') }}</TableHead>
            <TableHead>{{ $t('User') }}</TableHead>
            <TableHead>{{ $t('Assigned By') }}</TableHead>
            <TableHead>{{ $t('Assigned At') }}</TableHead>
            <TableHead>{{ $t('Created At') }}</TableHead>
            <TableHead />
          </template>
          <TableRow v-if="subscriptionPlanAccesses.data.length === 0">
            <TableData colspan="6">{{ $t('No user subscriptions found.') }}</TableData>
          </TableRow>
          <TableRow
            v-for="subscriptionPlanAccess in subscriptionPlanAccesses.data"
            :key="subscriptionPlanAccess.id"
            :clickable="true"
            @click="$inertia.visit(route('subscription-plan-access.edit', subscriptionPlanAccess))"
          >
            <TableData
              class="space-x-1"
              :class="{
                'opacity-50':
                  subscriptionPlanAccess.is_cancelled ||
                  subscriptionPlanAccess.is_deleted ||
                  subscriptionPlanAccess.is_archived,
              }"
            >
              <span>{{ subscriptionPlanAccess.subscriptionPlan.title }}</span>
              <cancelled-badge v-if="subscriptionPlanAccess.cancelled_at" />
              <deleted-badge v-if="subscriptionPlanAccess.deleted_at" />
              <archived-badge v-if="subscriptionPlanAccess.archived_at" />
            </TableData>
            <TableData>{{ subscriptionPlanAccess.user?.name }}</TableData>
            <TableData>{{ subscriptionPlanAccess.assignedBy?.name }}</TableData>
            <TableData>
              <show-date :timestamp="subscriptionPlanAccess.assigned_at" />
            </TableData>
            <TableData>
              <show-date :timestamp="subscriptionPlanAccess.created_at" />
            </TableData>
            <TableData class="w-min align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </TableData>
          </TableRow>
        </SimpleTable>
      </div>
      <pagination :links="subscriptionPlanAccesses.links" :preserve-scroll="true" />
    </div>
    <!-- Actions -->
    <div>
      <div class="mt-4 flex items-end justify-between">
        <h2 class="text-lg">{{ $t('Actions') }}</h2>
        <InertiaLink
          class="btn-blue"
          :href="route('organizations.actions.create', { organization: activeOrganization.id })"
        >
          <span>{{ $t('Create') }}</span>
          <span class="hidden md:inline">{{ $t('Action') }}</span>
        </InertiaLink>
      </div>
      <div class="mt-4 rounded bg-white shadow">
        <SimpleTable>
          <template #head>
            <TableHead>{{ $t('Title') }}</TableHead>
            <TableHead>{{ $t('Accepted') }}</TableHead>
            <TableHead>{{ $t('Finished') }}</TableHead>
            <TableHead>{{ $t('Approved') }}</TableHead>
            <TableHead>{{ $t('Created') }}</TableHead>
            <TableHead />
          </template>
          <TableRow v-if="actions.data.length === 0">
            <TableData colspan="6">{{ $t('No actions found.') }}</TableData>
          </TableRow>
          <TableRow
            v-for="action in actions.data"
            :key="action.id"
            :clickable="true"
            @click="
              $inertia.visit(
                route('organizations.actions.edit', { organization: activeOrganization.id, action: action.id })
              )
            "
          >
            <TableData class="space-x-1">
              <span>{{ action.title }}</span>
              <archived-badge v-if="action.archived_at" />
              <deleted-badge v-if="action.deleted_at" />
            </TableData>
            <TableData>{{ action.accepted_at }}</TableData>
            <TableData>{{ action.finished_at }}</TableData>
            <TableData>{{ action.approved_at }}</TableData>
            <TableData>{{ action.created_at }}</TableData>
            <TableData class="w-min align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </TableData>
          </TableRow>
        </SimpleTable>
      </div>
      <pagination :links="actions.links" :preserve-scroll="true" />
    </div>
    <!-- Folders -->
    <div>
      <div class="mt-4 flex items-end justify-between">
        <h2 class="text-lg">{{ $t('Folders') }}</h2>
        <InertiaLink
          class="btn-blue"
          :href="route('organizations.folders.create', { organization: activeOrganization.id })"
        >
          <span>{{ $t('Create') }}</span>
          <span class="hidden md:inline">{{ $t('Folder') }}</span>
        </InertiaLink>
      </div>
      <div class="mt-4 rounded bg-white shadow">
        <SimpleTable>
          <template #head>
            <TableHead>{{ $t('Name') }}</TableHead>
            <TableHead>{{ $t('Files') }}</TableHead>
            <TableHead>{{ $t('Created') }}</TableHead>
            <TableHead />
          </template>
          <TableRow v-if="folders.data.length === 0">
            <TableData colspan="6">{{ $t('No folders found.') }}</TableData>
          </TableRow>
          <TableRow
            v-for="folder in folders.data"
            :key="folder.id"
            :clickable="true"
            @click="$inertia.visit(route('organizations.folders.edit', folder.id))"
          >
            <TableData class="space-x-1">
              <span>{{ folder.name }}</span>
              <archived-badge v-if="folder.archived_at" />
              <deleted-badge v-if="folder.deleted_at" />
            </TableData>
            <TableData>{{ folder.files_count }}</TableData>
            <TableData>{{ folder.created_at }}</TableData>
            <TableData class="w-min align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </TableData>
          </TableRow>
        </SimpleTable>
      </div>
      <pagination :links="folders.links" :preserve-scroll="true" />
    </div>
    <!-- Form Selects -->
    <div>
      <div class="mt-4 flex items-end justify-between">
        <h2 class="text-lg">{{ $t('Response Sets') }}</h2>
        <InertiaLink
          class="btn-blue"
          :href="route('organizations.response-sets.create', { organization: activeOrganization })"
        >
          <span>{{ $t('Create') }}</span>
          <span class="hidden md:inline">{{ $t('Response Set') }}</span>
        </InertiaLink>
      </div>
      <div class="mt-4 rounded bg-white shadow">
        <SimpleTable>
          <template #head>
            <TableHead>{{ $t('Title') }}</TableHead>
            <TableHead>{{ $t('Number Of Options') }}</TableHead>
            <TableHead>{{ $t('Responses') }}</TableHead>
            <TableHead>{{ $t('Updated') }}</TableHead>
            <TableHead />
          </template>
          <TableRow v-if="selects.data.length === 0">
            <TableData colspan="6">{{ $t('No response sets found.') }}</TableData>
          </TableRow>
          <TableRow
            v-for="select in selects.data"
            :key="select.id"
            :clickable="true"
            @click="
              $inertia.visit(
                route('organizations.response-sets.edit', { organization: activeOrganization, select: select })
              )
            "
          >
            <TableData class="space-x-1">
              {{ select.name ?? $t('Untitled Set') }}
              <archived-badge v-if="select.archived_at" />
              <deleted-badge v-if="select.deleted_at" />
            </TableData>
            <TableData>{{ select.choices_count }}</TableData>
            <TableData class="max-w-sm truncate">{{ select.choices }}</TableData>
            <TableData>{{ select.updated_at }}</TableData>
            <TableData class="w-min align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </TableData>
          </TableRow>
        </SimpleTable>
      </div>
      <pagination :links="selects.links" :preserve-scroll="true" />
    </div>
  </div>
</template>
<script>
import OrganizationEditLayout from './OrganizationEditLayout.vue'
import AppHead from '@/Shared/AppHead.vue'
import ArchivedBadge from '@/Shared/ArchivedBadge.vue'
import CancelledBadge from '@/Shared/CancelledBadge.vue'
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import ShowDate from '@/Shared/ShowDate.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'

export default {
  components: {
    AppHead,
    ArchivedBadge,
    CancelledBadge,
    DeletedBadge,
    Icon,
    Pagination,
    ShowDate,
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
  },
  layout: [Layout, OrganizationEditLayout],
}
</script>
<script setup>
import { activeOrganization } from '@/Stores/ActiveOrganization'

defineProps({
  subscriptionPlanAccesses: Object,
  actions: Object,
  jobProfiles: Object,
  folders: Object,
  selects: Object,
})
</script>
