<template>
  <layout :title="subscription_plan.title">
    <div class="mb-8">
      <breadcrumb
        :name="subscription_plan.title"
        previous-name="Subscription Plans"
        :previous-url="route('subscription-plans')"
      />
    </div>
    <archived-message v-if="subscription_plan.archived_at" class="mb-6">
      {{ $t('This subscription plan has been archived.') }}
    </archived-message>
    <div class="flex max-w-3xl flex-wrap justify-between">
      <div class="w-full lg:w-3/5 xl:w-1/2">
        <div class="overflow-hidden rounded bg-white shadow">
          <form @submit.prevent="submit">
            <div class="-mb-8 -mr-6 flex flex-wrap p-8">
              <div class="w-full pb-8 pr-6">
                <text-input v-model="form.identifier" :error="form.errors.identifier" :label="$t('Identifier')" />
              </div>
              <div class="w-full pb-8 pr-6">
                <text-input
                  v-model="form.stripe_product"
                  :error="form.errors.stripe_product"
                  :label="$t('Stripe Product ID')"
                />
              </div>
              <div class="w-full pb-8 pr-6">
                <text-input
                  v-model="form.stripe_price"
                  :error="form.errors.stripe_price"
                  :label="$t('Stripe Price ID')"
                />
              </div>
              <div class="w-full pb-8 pr-6">
                <text-input v-model="form.title" :error="form.errors.title" :label="$t('Title')" />
              </div>
              <div class="w-full pb-8 pr-6">
                <textarea-input
                  v-model="form.description"
                  :error="form.errors.description"
                  :label="$t('Description')"
                  :placeholder="$t('Plan description')"
                />
              </div>
              <div class="w-full pb-8 pr-6">
                <textarea-input
                  v-model="form.features"
                  :error="form.errors.features"
                  :label="$t('Features')"
                  :placeholder="$t('For eg: Feature One | Feature Two')"
                />
                <small class="text-xs text-ts-blue-500">
                  {{ $t("Type features separated by a '|'.") }}
                </small>
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input
                  v-model="form.free_trial_days"
                  :error="form.errors.free_trial_days"
                  :label="$t('Free Trial Days')"
                />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input
                  v-model="form.display_order"
                  :error="form.errors.display_order"
                  :label="$t('Display Order')"
                />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <select-input v-model="form.theme" :error="form.errors.theme" :label="$t('Theme')">
                  <option
                    v-for="(subscription_plan_theme, index) in subscription_plan_themes"
                    :key="index"
                    :value="index"
                  >
                    {{ subscription_plan_theme }}
                  </option>
                </select-input>
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <select-input
                  v-model="form.subscription_plan_type"
                  :error="form.errors.subscription_plan_type"
                  :label="$t('Subscription Plan Type')"
                >
                  <option
                    v-for="(subscription_plan_type, index) in subscription_plan_types"
                    :key="index"
                    :value="index"
                  >
                    {{ subscription_plan_type }}
                  </option>
                </select-input>
              </div>
              <div class="flex w-full items-center justify-between pb-8 pr-6">
                <label class="mr-3 cursor-pointer">
                  {{ $t('Enable Subscription Plan') }}
                </label>
                <front-small-toggle-input v-model="form.is_active" :error="form.errors.is_active" />
              </div>
            </div>
            <div class="flex items-center border-t border-gray-100 bg-gray-100 px-8 py-4">
              <button
                v-if="subscription_plan.deleted_at"
                class="text-red-500 hover:underline"
                tabindex="-1"
                type="button"
                @click="restore"
              >
                {{ $t('Restore') }}
              </button>
              <button v-else class="text-red-500 hover:underline" tabindex="-1" type="button" @click="destroy">
                {{ $t('Delete') }}
              </button>
              <button
                v-if="subscription_plan.archived_at"
                class="ml-2 text-red-500 hover:underline"
                tabindex="-1"
                type="button"
                @click="unArchive"
              >
                {{ $t('Unarchive') }}
              </button>
              <button v-else class="ml-2 text-red-500 hover:underline" tabindex="-1" type="button" @click="archive">
                {{ $t('Archive') }}
              </button>
              <loading-button class="btn-blue ml-auto" :loading="form.processing" type="submit">
                {{ $t('Update Subscription Plan') }}
              </loading-button>
            </div>
          </form>
        </div>
      </div>
      <div class="flex w-full flex-col pt-12 lg:w-2/5 lg:pl-8 lg:pt-0 xl:w-1/3">
        <version-card
          :last-modified="subscription_plan.versionSummary.last_modified"
          :link="route('subscription-plans.versions', subscription_plan.id)"
        />
      </div>
    </div>

    <div v-if="form.subscription_plan_type === 'online-training'">
      <div class="mb-6 mt-12 flex items-center justify-between">
        <h2 class="text-lg">{{ $t('Courses') }}</h2>
        <div class="flex items-center justify-between">
          <button class="btn-blue" @click="showAddCourseToPlanModal = true">
            <span>{{ $t('New') }}</span>
            <span class="hidden md:inline">{{ $t('Course') }}</span>
          </button>
        </div>
      </div>
      <div class="overflow-x-auto rounded bg-white shadow">
        <table class="w-full">
          <thead>
            <tr>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Name') }}</th>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold" colspan="2">{{ $t('Added') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="subscription_plan.courses.data.length === 0">
              <td class="border-t px-6 py-4" colspan="5">{{ $t('No enrolments found.') }}</td>
            </tr>
            <tr
              v-for="course in subscription_plan.courses.data"
              :key="course.id"
              class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
            >
              <td class="whitespace-nowrap border-t px-6 py-4">{{ course.name }}</td>
              <td class="whitespace-nowrap border-t px-6 py-4">{{ course.created_at }}</td>
              <td class="whitespace-nowrap border-t px-6 py-4">
                <button class="text-blue-600 hover:text-blue-700" @click.stop="removeCourse(course)">
                  {{ $t('Remove') }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination :links="subscription_plan.courses.links" :preserve-scroll="true" />
    </div>
    <add-course-to-plan-modal
      v-if="showAddCourseToPlanModal"
      :show="showAddCourseToPlanModal"
      :subscription-plan="subscription_plan"
      @close="showAddCourseToPlanModal = false"
    />
  </layout>
</template>

<script>
import AddCourseToPlanModal from './AddCourseToPlanModal.vue'
import ArchivedMessage from '@/Shared/ArchivedMessage.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontSmallToggleInput from '@/Shared/FrontSmallToggleInput.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import Pagination from '@/Shared/Pagination.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import VersionCard from '@/Shared/VersionCard.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    AddCourseToPlanModal,
    ArchivedMessage,
    Breadcrumb,
    FrontSmallToggleInput,
    Layout,
    LoadingButton,
    Pagination,
    SelectInput,
    TextInput,
    TextareaInput,
    VersionCard,
  },
  props: {
    subscription_plan: Object,
    subscription_plan_types: Object,
    subscription_plan_themes: Object,
  },
  data() {
    return {
      showAddCourseToPlanModal: false,
      form: useForm(
        {
          identifier: this.subscription_plan.identifier,
          stripe_price: this.subscription_plan.stripe_price,
          stripe_product: this.subscription_plan.stripe_product,
          title: this.subscription_plan.title,
          description: this.subscription_plan.description,
          features: this.subscription_plan.features,
          free_trial_days: this.subscription_plan.free_trial_days,
          theme: this.subscription_plan.theme,
          subscription_plan_type: this.subscription_plan.subscription_plan_type,
          display_order: this.subscription_plan.display_order,
          is_active: this.subscription_plan.is_active,
        },
        'SubscriptionPlanEdit'
      ),
    }
  },
  methods: {
    submit() {
      this.form.put(this.route('subscription-plans.update', this.subscription_plan.id))
    },
    removeCourse(course) {
      this.$inertia.delete(
        this.route('subscription-plans.courses.delete', {
          subscriptionPlan: this.subscription_plan.id,
          course: course.id,
        })
      )
    },
    archive() {
      if (confirm('Are you sure you want to archive this subscription plan?')) {
        this.$inertia.put(this.route('subscription-plans.archive', this.subscription_plan.id))
      }
    },
    unArchive() {
      if (confirm('Are you sure you want to restore this archived subscription plan?')) {
        this.$inertia.put(this.route('subscription-plans.unarchive', this.subscription_plan.id))
      }
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this subscription plan?'))) {
        this.$inertia.delete(this.route('subscription-plans.destroy', this.subscription_plan.id))
      }
    },
    restore() {
      if (confirm(this.$t('Are you sure you want to restore this subscription plan?'))) {
        this.$inertia.put(this.route('subscription-plans.restore', this.subscription_plan.id))
      }
    },
  },
}
</script>
