<template>
  <front-layout title="Check-in">
    <div class="mb-8">
      <breadcrumb :name="$t('Check-in')" :previous-name="course" :previous-url="route('front.courses')" />
    </div>
    <div
      v-if="class_.course.ministry_of_labour_code"
      class="-mt-2 mb-4 max-w-md rounded bg-yellow-300 py-3 text-center leading-tight text-yellow-800"
    >
      {{ $t('The following information is required by the ') }}
      <strong>{{ $t('Ontario Ministry of Labour') }}</strong>
      .
    </div>
    <div class="max-w-md overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.first_name" :error="form.errors.first_name" :label="$t('First name')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.last_name" :error="form.errors.last_name" :label="$t('Last name')" />
          </div>
          <div v-if="class_.course.ministry_of_labour_code" class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input
              v-model="form.birth_year"
              :error="form.errors.birth_year"
              :label="$t('Birth year')"
              type="password"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.email" :error="form.errors.email" :label="$t('Email')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.phone" :error="form.errors.phone" :label="$t('Phone')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.address" :error="form.errors.address" :label="$t('Address')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.city" :error="form.errors.city" :label="$t('City')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <select-input
              v-model="form.region"
              :error="form.errors.region"
              :label="$t('Province/State')"
              :options="[null, ...regions]"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.postal_code" :error="form.errors.postal_code" :label="$t('Postal code')" />
          </div>
          <div v-if="isClasstime" class="w-full pb-8 pr-6 lg:w-1/2">
            <select-input v-model="form.present" :error="form.errors.present" :label="$t('Present')">
              <option :value="null" />
              <option :value="true">{{ $t('true') }}</option>
              <option :value="false">{{ $t('false') }}</option>
            </select-input>
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 px-8 py-4">
          <front-loading-button :loading="form.processing" type="submit">{{ $t('Check-in') }}</front-loading-button>
        </div>
      </form>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    FrontLayout,
    FrontLoadingButton,
    SelectInput,
    TextInput,
  },
  props: {
    course: String,
    class_: Object,
    student: Object,
    regions: Array,
    isClasstime: Boolean,
  },
  data() {
    return {
      form: useForm(
        {
          first_name: this.student.first_name || this.$page.props.auth.user.first_name,
          last_name: this.student.last_name || this.$page.props.auth.user.last_name,
          email: this.student.email || this.$page.props.auth.user.email,
          birth_year: this.student.birth_year,
          phone: this.student.phone,
          address: this.student.address,
          city: this.student.city,
          region: this.student.region,
          postal_code: this.student.postal_code,
          present: this.student.present,
        },
        'CheckInCreate'
      ),
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('front.courses.classes.checkin.store', this.student.id))
    },
  },
}
</script>
