<template>
  <layout :title="form.name">
    <div class="mb-6 flex max-w-lg items-center justify-between">
      <h1 class="text-xl font-bold">
        <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('quizzes')">
          {{ $t('Quizzes') }}
        </InertiaLink>
        <span class="font-medium text-blue-600">/</span>
        {{ form.name }}
      </h1>
      <InertiaLink v-if="related_contentable" class="btn-blue" :href="route('quizzes.edit', related_contentable.id)">
        {{ $t('Edit Related Quiz (:lang)', { lang: related_contentable.lang }) }}
      </InertiaLink>
      <InertiaLink v-else class="btn-blue" :href="route('quizzes.create', { contentable: quiz.id })">
        {{ $t('Create Related Quiz') }}
      </InertiaLink>
    </div>
    <div class="flex w-full flex-col gap-x-8 md:flex-row">
      <div class="w-full max-w-lg overflow-hidden rounded bg-white shadow">
        <form @submit.prevent="submit">
          <div class="-mb-8 -mr-6 flex flex-wrap p-8">
            <div class="w-full pb-8 pr-6">
              <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name (Internal)')" />
            </div>
            <div class="w-full pb-8 pr-6">
              <text-input v-model="form.title" :error="form.errors.title" :label="$t('Title')" />
            </div>
            <div class="w-1/2 pb-8 pr-6">
              <text-input
                v-model="form.time_limit_minutes"
                :error="form.errors.time_limit_minutes"
                :label="$t('Time limit (minutes)')"
                type="number"
              />
            </div>
            <div class="w-1/2 pb-8 pr-6">
              <text-input
                v-model="form.passing_score_percentage"
                :error="form.errors.passing_score_percentage"
                :label="$t('Passing score percent (%)')"
                type="number"
              />
            </div>
            <div class="w-1/2 pb-8 pr-6">
              <text-input disabled :label="$t('Total score')" :model-value="quiz.total_score" type="number" />
            </div>
            <div class="w-1/2 pb-8 pr-6">
              <text-input disabled :label="$t('Passing score')" :model-value="quiz.passing_score" type="number" />
            </div>
            <div class="w-1/2 pb-8 pr-6">
              <text-input
                v-model="form.max_attempts"
                class="mb-1"
                :label="$t('Maximum Attempts')"
                min="1"
                type="number"
              />
              <small>{{ $t('The number of attempts before the enrolment is failed.') }}</small>
            </div>
            <div class="w-full pb-8 pr-6">
              <textarea-input
                v-model="form.description"
                :autosize="true"
                :error="form.errors.description"
                :label="$t('Description')"
                rows="2"
              />
            </div>
            <div class="flex items-center justify-between pb-8 pr-6 lg:w-1/2">
              <label class="mr-3 cursor-pointer text-ts-gray-text-bold" for="show_questions_random_order">
                {{ $t('Display questions in random order') }}
              </label>
              <front-small-toggle-input
                id="show_questions_random_order"
                v-model="form.show_questions_in_random_order_enabled"
                :error="form.errors.show_questions_in_random_order_enabled"
              />
            </div>
          </div>
          <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Quiz Results') }}</div>
          <div class="-mb-8 -mr-6 flex w-full flex-wrap p-8 lg:w-1/2">
            <div class="flex w-full items-center justify-between pb-8 pr-6">
              <label class="mr-3 cursor-pointer text-ts-gray-text-bold" for="show_answers_to_users">
                {{ $t('Show answers to users') }}
              </label>
              <front-small-toggle-input
                id="show_answers_to_users"
                v-model="form.show_answers_to_users_enabled"
                :error="form.errors.show_answers_to_users_enabled"
              />
            </div>
            <div class="flex w-full items-center justify-between pb-8 pr-6">
              <label class="mr-3 cursor-pointer text-ts-gray-text-bold" for="show_answers_to_managers">
                {{ $t('Show answers to managers') }}
              </label>
              <front-small-toggle-input
                id="show_answers_to_managers"
                v-model="form.show_answers_to_managers_enabled"
                :error="form.errors.show_answers_to_managers_enabled"
              />
            </div>
          </div>
          <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Virtual Proctoring') }}</div>
          <div class="-mb-8 -mr-6 flex w-full flex-wrap p-8">
            <div class="flex w-1/2 items-center justify-between pb-8 pr-6">
              <label class="mr-3 cursor-pointer text-ts-gray-text-bold" for="show_answers_to_users">
                {{ $t('Enable virtual proctoring') }}
              </label>
              <front-small-toggle-input
                id="show_answers_to_users"
                v-model="form.proctoring_enabled"
                :error="form.errors.proctoring_enabled"
              />
            </div>
            <div v-if="form.proctoring_enabled" class="w-full pb-8 pr-6">
              <textarea-input
                v-model="form.proctoring_rules"
                :autosize="true"
                :error="form.errors.proctoring_rules"
                :label="$t('Rules')"
                rows="4"
              />
            </div>
            <div v-if="form.proctoring_enabled" class="w-full pb-8 pr-6">
              <textarea-input
                v-model="form.proctoring_privacy_policy"
                :autosize="true"
                :error="form.errors.proctoring_privacy_policy"
                :label="$t('Privacy Policy')"
                rows="4"
              />
            </div>
          </div>
          <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
            <button class="text-red-500 hover:underline" tabindex="-1" type="button" @click="destroy">
              {{ $t('Delete Quiz') }}
            </button>
            <loading-button class="btn-blue" :loading="form.processing" type="submit">
              {{ $t('Update Quiz') }}
            </loading-button>
          </div>
        </form>
      </div>
      <div class="mt-8 w-full space-y-4 md:mt-0 md:max-w-sm">
        <card class="p-0" name="Courses">
          <div class="flex w-full flex-col">
            <div v-for="course in courses" :key="course.id" class="border-b last:border-b-0">
              <InertiaLink class="block w-full p-4" :href="route('courses.edit', course.id)">
                {{ course.name }}
              </InertiaLink>
            </div>
          </div>
        </card>
      </div>
    </div>
    <div class="mt-12 flex items-end justify-between">
      <div class="flex items-center">
        <h2 class="text-lg">{{ $t('Banks') }}</h2>
      </div>
      <InertiaLink class="btn-blue" :href="route('banks.create', quiz.id)">
        <span>{{ $t('Create') }}</span>
        <span class="hidden md:inline">{{ $t('Bank') }}</span>
      </InertiaLink>
    </div>
    <div class="mt-4 overflow-x-auto rounded bg-white shadow">
      <table class="w-full">
        <thead>
          <tr>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Name') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Quantity') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold" colspan="2">
              {{ $t('Total Questions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(bank, key) in quiz.banks"
            :key="bank.id"
            :class="Object.keys(banks[key].errors).length ? 'bg-red-100' : 'hover:bg-gray-100'"
          >
            <td class="max-w-px border-t px-6 py-4">
              <div class="flex cursor-pointer items-center" @click="$inertia.visit(route('banks.edit', bank.id))">
                <span class="truncate">{{ bank.name }}</span>
              </div>
            </td>
            <td class="w-min whitespace-nowrap border-t px-6 py-2">
              <input
                v-model="banks[key].quantity"
                class="form-input w-16"
                :class="{ error: banks[key].errors.quantity }"
                type="number"
                @change="save(banks[key])"
              />
            </td>
            <td class="w-min whitespace-nowrap border-t px-6 py-4">{{ bank.questions_count }}</td>
            <td class="w-min border-t px-4 align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </td>
          </tr>
          <tr v-if="quiz.banks.length === 0">
            <td class="border-t px-6 py-4" colspan="4">{{ $t('No banks found.') }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </layout>
</template>

<script>
import Card from '@/Shared/Card.vue'
import FrontSmallToggleInput from '@/Shared/FrontSmallToggleInput.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import Http from '@/Utils/Http'
import { useForm } from '@inertiajs/vue3'
import _pick from 'lodash/pick'

export default {
  components: {
    Card,
    FrontSmallToggleInput,
    Icon,
    Layout,
    LoadingButton,
    TextInput,
    TextareaInput,
  },
  props: {
    quiz: Object,
    courses: Array,
    related_contentable: Object,
  },
  data() {
    return {
      form: useForm(
        {
          name: this.quiz.name,
          title: this.quiz.title,
          passing_score_percentage: this.quiz.passing_score_percentage,
          time_limit_minutes: this.quiz.time_limit_minutes,
          max_attempts: this.quiz.max_attempts,
          description: this.quiz.description,
          show_answers_to_users_enabled: this.quiz.show_answers_to_users_enabled,
          show_answers_to_managers_enabled: this.quiz.show_answers_to_managers_enabled,
          show_questions_in_random_order_enabled: this.quiz.show_questions_in_random_order_enabled,
          proctoring_enabled: this.quiz.proctoring_enabled,
          proctoring_rules: this.quiz.proctoring_rules,
          proctoring_privacy_policy: this.quiz.proctoring_privacy_policy,
        },
        'QuizEdit'
      ),
      banks: null,
    }
  },
  watch: {
    'quiz.banks': {
      handler: function () {
        this.banks = this.quiz.banks.map((bank) => {
          return {
            ..._pick(bank, ['id', 'quantity']),
            errors: {},
          }
        })
      },
      immediate: true,
    },
  },
  methods: {
    submit() {
      this.form
        .transform((data) => ({
          ...data,
          proctoring_rules: this.form.proctoring_enabled ? this.form.proctoring_rules : null,
          proctoring_privacy_policy: this.form.proctoring_enabled ? this.form.proctoring_privacy_policy : null,
        }))
        .put(this.route('quizzes.update', this.quiz.id))
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this quiz?\nBanks and questions will also be deleted.'))) {
        this.$inertia.delete(this.route('quizzes.destroy', this.quiz.id))
      }
    },
    save(bank) {
      Http.put(this.route('banks.update', bank.id), {
        quantity: bank.quantity,
      })
        .then((response) => {
          bank.errors = {}
          this.$page.props.flash.error = null
          this.$page.props.quiz.total_score = response.data.total_score
          this.$page.props.quiz.passing_score = response.data.passing_score
          this.$page.props.quiz.passing_score_percentage = response.data.passing_score_percentage
        })
        .catch((error) => {
          bank.errors = error.response.data.errors
          this.$page.props.flash.error = bank.errors.quantity[0]
        })
    },
  },
}
</script>
