<template>
  <div class="p-8">
    <div>
      <div class="mt-4 flex items-end justify-between">
        <div>
          <h2 class="text-lg">{{ $t('Certificates') }}</h2>
        </div>
        <button class="btn-blue" @click="showAddCertificateModal = true">
          <span>{{ $t('Add') }}</span>
          <span class="hidden md:inline">{{ $t('Certificates') }}</span>
        </button>
      </div>
      <div class="mt-4 shadow">
        <SimpleTable>
          <template #head>
            <TableHead>{{ $t('Name') }}</TableHead>
            <TableHead>{{ $t('Number') }}</TableHead>
            <TableHead>{{ $t('Enrolment') }}</TableHead>
            <TableHead class="text-right">{{ $t('Issue Date') }}</TableHead>
            <TableHead class="text-right">{{ $t('Expire Date') }}</TableHead>
            <TableHead class="text-right">{{ $t('Status') }}</TableHead>
            <TableHead class="text-right">{{ $t('Actions') }}</TableHead>
          </template>
          <TableRow v-if="certificates.data.length === 0">
            <TableData class="border-t px-6 py-4" colspan="5">{{ $t('No certificates found.') }}</TableData>
          </TableRow>
          <TableRow
            v-for="certificate in certificates.data"
            :key="certificate.id"
            class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
          >
            <TableData
              @click="certificate.enrolment && $inertia.visit(route('enrolments.edit', certificate.enrolment.id))"
            >
              <div class="flex items-center" :title="certificate.name">
                <span>{{ truncate(certificate.name) }}</span>
                <archived-badge v-if="certificate.archived_at" />
                <deleted-badge v-if="certificate.deleted_at" />
              </div>
            </TableData>
            <TableData
              @click="certificate.enrolment && $inertia.visit(route('enrolments.edit', certificate.enrolment.id))"
            >
              {{ certificate.certificate_number }}
            </TableData>
            <TableData
              @click="certificate.enrolment && $inertia.visit(route('enrolments.edit', certificate.enrolment.id))"
            >
              {{ certificate.enrolment.id }}
            </TableData>
            <TableData
              class="text-right"
              @click="certificate.enrolment && $inertia.visit(route('enrolments.edit', certificate.enrolment.id))"
            >
              <show-date :timestamp="certificate.created_at" />
            </TableData>
            <TableData class="flex justify-end text-right">
              <show-date :timestamp="certificate.expires_at" />
              <InertiaLink
                v-if="certificate.can.edit"
                class="pl-2 text-blue-600 hover:text-blue-700"
                :href="route('users.certificate-templates.edit', [userId, certificate.certificate_template_id])"
              >
                Edit
              </InertiaLink>
            </TableData>
            <TableData
              class="whitespace-nowrap text-right"
              @click="certificate.enrolment && $inertia.visit(route('enrolments.edit', certificate.enrolment.id))"
            >
              <front-certificate-status :status="certificate.status" />
            </TableData>
            <TableData
              v-if="!certificate.deleted_at && !certificate.archived_at && certificate.certificate_template_id"
              class="whitespace-nowrap text-right"
            >
              <button
                class="mr-1 inline-block text-sm text-ts-blue-500 no-underline"
                @click.prevent="emailCertificate(certificate)"
              >
                <icon class="h-8 w-8" name="email" />
              </button>
              <front-certificate-download v-if="certificate.formats" :certificate="certificate" route-name="admin" />
            </TableData>
            <TableData v-else class="whitespace-nowrap text-right" />
          </TableRow>
        </SimpleTable>
      </div>
      <pagination :links="certificates.links" :preserve-scroll="true" />
      <add-certificate-modal
        :show="showAddCertificateModal"
        :user-id="userId"
        @close="showAddCertificateModal = false"
      />
    </div>
    <div class="">
      <div class="mt-4 flex items-center justify-between">
        <div>
          <h2 class="text-lg">{{ $t('Certificate Settings') }}</h2>
        </div>
        <InertiaLink class="btn-blue" :href="route('users.certificate-templates.create', userId)">
          <span>{{ $t('Add') }}</span>
          <span class="hidden md:inline">{{ $t('Certificate Settings') }}</span>
        </InertiaLink>
      </div>
      <div class="mt-4 shadow">
        <SimpleTable>
          <template #head>
            <TableHead>{{ $t('Name') }}</TableHead>
            <TableHead>{{ $t('Description') }}</TableHead>
            <TableHead>{{ $t('Years Valid') }}</TableHead>
            <TableHead />
          </template>
          <TableRow v-if="certificateTemplates.data.length === 0">
            <TableData colspan="4">{{ $t('No certificate settings found.') }}</TableData>
          </TableRow>
          <TableRow
            v-for="certificateTemplate in certificateTemplates.data"
            :key="certificateTemplate.id"
            :clickable="true"
            @click="
              $inertia.visit(
                route('users.certificate-templates.edit', [userId, certificateTemplate.id], {
                  preserveScroll: true,
                })
              )
            "
          >
            <TableData>{{ certificateTemplate.name }}</TableData>
            <TableData>{{ truncate(certificateTemplate.description, 70) }}</TableData>
            <TableData>{{ certificateTemplate.years_valid ? certificateTemplate.years_valid : '-' }}</TableData>
            <TableData class="w-min align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </TableData>
          </TableRow>
        </SimpleTable>
      </div>
      <pagination :links="certificateTemplates.links" :preserve-scroll="true" />
    </div>
  </div>
</template>

<script>
import AddCertificateModal from './AddCertificateModal.vue'
import UserEditLayoutVue from './UserEditLayout.vue'
import ArchivedBadge from '@/Shared/ArchivedBadge.vue'
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import FrontCertificateDownload from '@/Shared/FrontCertificateDownload.vue'
import FrontCertificateStatus from '@/Shared/FrontCertificateStatus.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import ShowDate from '@/Shared/ShowDate.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import { activeUser } from '@/Stores/ActiveUser'
import { truncate } from 'lodash'

export default {
  components: {
    AddCertificateModal,
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
    ArchivedBadge,
    DeletedBadge,
    FrontCertificateDownload,
    FrontCertificateStatus,
    Icon,
    Pagination,
    ShowDate,
  },
  layout: [Layout, UserEditLayoutVue],
  props: {
    certificates: Object,
    certificateTemplates: Object,
  },
  data() {
    return {
      showAddCertificateModal: false,
      userId: activeUser.value?.id,
    }
  },
  methods: {
    truncate,
    emailCertificate(certificate) {
      this.$inertia.visit(this.route('certificates.email', certificate))
    },
  },
}
</script>
