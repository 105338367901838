<template>
  <front-layout :title="$t('Purchases')">
    <div class="flex flex-col xl:flex-row xl:justify-between">
      <div class="sm:no-flex mb-8 flex w-full items-center justify-between">
        <breadcrumb :name="$t('Purchases')" />
      </div>
      <form
        class="mb-6 flex w-full items-center justify-end space-x-4"
        @change.prevent="search"
        @submit.prevent="search"
      >
        <front-search-filter
          v-model="form.search"
          class="flex w-full flex-col sm:flex-row lg:max-w-sm"
          :filter-show="false"
          @reset="reset"
        />
        <front-loading-button :loading="form.processing">{{ $t('Search') }}</front-loading-button>
      </form>
    </div>
    <table class="front-table">
      <thead class="bg-ts-front-table-header">
        <tr>
          <th class="font-semibold">{{ $t('Name') }}</th>
          <th>{{ $t('Order No') }}</th>
          <th>{{ $t('Purchased Date') }}</th>
          <th>{{ $t('Total') }}</th>
          <th>{{ $t('Payment Method') }}</th>
          <th />
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
        <tr v-if="!purchases.data.length">
          <td class="border-t px-6 py-4">{{ $t('No purchases found.') }}</td>
        </tr>
        <tr v-for="purchase in purchases.data" :key="purchase.id">
          <td class="font-semibold text-ts-gray-text-bold">{{ purchase.name }}</td>
          <td>{{ purchase.order_no || purchase.id }}</td>
          <td><front-date :timestamp="purchase.purchased_at" /></td>
          <td>${{ purchase.total }}</td>
          <td>{{ purchase.total > 0 ? purchase.method : '-' }}</td>
          <td class="whitespace-nowrap px-6 py-4 text-right text-sm font-medium leading-5">
            <div class="inline-flex items-center">
              <InertiaLink
                class="font-semibold hover:underline"
                :href="route('front.admin.purchases.receipt', purchase.id)"
              >
                {{ $t('View') }}
              </InertiaLink>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <front-pagination :links="purchases.links" />
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontDate from '@/Shared/FrontDate.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontPagination from '@/Shared/FrontPagination.vue'
import FrontSearchFilter from '@/Shared/FrontSearchFilter.vue'
import { useForm } from '@inertiajs/vue3'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'

export default {
  components: { FrontLoadingButton, Breadcrumb, FrontDate, FrontLayout, FrontPagination, FrontSearchFilter },
  props: {
    filters: Object,
    purchases: Object,
    user: Object,
  },
  data() {
    return {
      form: useForm({
        search: this.filters.search,
      }),
    }
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
    search() {
      let query = _pickBy(this.form)
      this.form.get(this.route('front.admin.purchases'), Object.keys(query).length ? query : { remember: 'forget' }, {
        preserveState: true,
        replace: true,
      })
    },
  },
}
</script>
