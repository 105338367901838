<template>
  <label class="flex items-center justify-center border" :class="{ 'border-blue-600': isFocussed }">
    <div class="mr-3">
      <input
        :checked="isChecked"
        class="height-0 width-0 pointer absolute opacity-0"
        type="checkbox"
        :value="value"
        @blur="isFocussed = false"
        @focus="isFocussed = true"
        @input="updateInput"
      />
      <Icon v-if="isChecked" class="h-6 w-6 flex-1 rounded border-2 bg-ts-gray-text-bold fill-white" name="checkmark" />
      <div
        v-else
        class="h-6 w-6 flex-1 rounded border-2 bg-white"
        :class="isFocussed ? 'border-blue-600' : 'border-ts-gray-300'"
      />
    </div>
    <div class="flex-1">
      <span>{{ label }}</span>
      <span v-if="correct" class="text-green-500 px-2 font-semibold">(Correct)</span>
    </div>
  </label>
</template>

<script>
import Icon from './Icon.vue'

export default {
  components: {
    Icon,
  },
  props: {
    value: {
      type: [String, Number, Object],
      default: null,
    },
    modelValue: {
      type: [Boolean, Array, Object],
      default: null,
    },
    correct: { type: Boolean },
    label: { type: String, required: true },
    trueValue: {
      type: [Boolean, Object, String, Number],
      default: true,
    },
    falseValue: {
      type: [Boolean, Object, String, Number],
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      isFocussed: false,
    }
  },
  computed: {
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value)
      }
      // Note that `true-value` and `false-value` are camelCase in the JS
      return this.modelValue === this.trueValue
    },
  },
  methods: {
    updateInput(event) {
      let isChecked = event.target.checked
      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue]
        if (isChecked) {
          newValue.push(this.value)
        } else {
          newValue.splice(newValue.indexOf(this.value), 1)
        }
        this.$emit('update:modelValue', newValue)
      } else {
        this.$emit('update:modelValue', isChecked ? this.trueValue : this.falseValue)
      }
    },
  },
}
</script>
