<template>
  <div class="h-8 w-8 overflow-hidden rounded-full border-4 border-qualify-red-500 bg-qualify-red-500">
    <div v-if="finished" class="h-full w-full fill-white shadow">
      <icon class="border-2 border-transparent" name="checkmark" />
    </div>
    <div v-else class="h-full w-full bg-white shadow"></div>
  </div>
</template>
<script>
import Icon from '@/Shared/Icon.vue'

export default {
  components: {
    Icon,
  },
  props: {
    locked: Boolean,
    finished: Boolean,
  },
}
</script>
