<template>
  <layout :title="form.name">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink
        class="text-blue-600 hover:text-blue-700"
        :href="route('instructor-led-classrooms.edit', instructor_led_classroom)"
      >
        {{ $t('Offline Tests') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Create') }}
    </h1>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.name" class="pb-2" :error="form.errors.name" :label="$t('Name')" required />
            <small>{{ $t('E.g. Test Version 1.0') }}</small>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input
              v-model="form.total_score"
              :error="form.errors.total_score"
              :label="$t('Total score')"
              required
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input
              v-model="form.passing_score"
              :error="form.errors.passing_score"
              :label="$t('Passing score')"
              required
            />
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Create Test') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Layout,
    LoadingButton,
    TextInput,
  },
  props: {
    instructor_led_classroom: Object,
  },
  data() {
    return {
      form: useForm({
        name: null,
        total_score: null,
        passing_score: null,
      }),
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('instructor-led-classrooms.offline-tests.store', [this.instructor_led_classroom]))
    },
  },
}
</script>
