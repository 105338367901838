<template>
  <guest-layout :tenant="tenant" :title="$t('Form Signature Request')">
    <front-modal class="rounded text-base" :show="show" style="z-index: 100000" width="50rem">
      <div class="max-h-screen max-w-lg overflow-auto rounded bg-white p-6 shadow md:p-10">
        <h3 class="mb-5 mt-4 w-full border-b border-ts-gray-300 pb-5 text-center text-xl font-semibold text-black">
          {{ $t('Signature Requested') }}
        </h3>
        <div class="mt-4 w-full">
          <div class="w-full">
            <div class="mb-1 flex justify-between">
              <button class="link text-sm" @click.prevent="clear">
                {{ $t('Clear Signature') }}
              </button>
            </div>
            <template v-if="show">
              <div class="h-32 w-full sm:h-64" style="max-height: 65vh">
                <vue-signature-pad ref="signaturePad" class="h-full w-full border" :options="{ onEnd }" />
              </div>
              <div class="mt-8 flex w-full items-center justify-between">
                <front-outline-button
                  class="inline-block px-12"
                  :disabled="!form.image"
                  type="button"
                  @click="acceptSignature"
                >
                  {{ $t('Approve and Sign') }}
                </front-outline-button>
              </div>
            </template>
          </div>
        </div>
      </div>
    </front-modal>
  </guest-layout>
</template>

<script>
import FrontModal from '@/Shared/FrontModal.vue'
import FrontOutlineButton from '@/Shared/FrontOutlineButton.vue'
import GuestLayout from '@/Shared/GuestLayout.vue'
import { useForm } from '@inertiajs/vue3'
import trimCanvas from 'trim-canvas'
import { VueSignaturePad } from 'vue-signature-pad'

export default {
  components: {
    VueSignaturePad,
    FrontModal,
    FrontOutlineButton,
    GuestLayout,
  },
  props: {
    tenant: Object,
    formFilled: Object,
    signatureSubmitToUrl: String,
  },
  data() {
    return {
      show: true,
      form: useForm({
        image: null,
        signatureType: null,
      }),
    }
  },
  methods: {
    clear() {
      this.$refs.signaturePad.clearSignature()
      this.form.image = null
    },
    getTrimmedCanvas() {
      let copy = document.createElement('canvas')
      const signaturePadCanvas = this.$refs.signaturePad.$refs.signaturePadCanvas
      copy.width = signaturePadCanvas.width
      copy.height = signaturePadCanvas.height
      copy.getContext('2d').drawImage(signaturePadCanvas, 0, 0)
      return trimCanvas(copy)
    },
    acceptSignature() {
      this.form.post(this.signatureSubmitToUrl)
    },
    onEnd() {
      let trimmedCanvas = this.getTrimmedCanvas()
      this.form.image = trimmedCanvas.toDataURL('image/png')
    },
  },
}
</script>
