<template>
  <div v-if="enrolmentTransfers.data.length > 0" class="py-6">
    <h3 class="pb-4 font-semibold">{{ $t('Sent Enrolment Transfer') }}</h3>
    <table class="front-table">
      <thead class="bg-ts-front-table-header">
        <tr>
          <th class="font-semibold">{{ $t('Sent To') }}</th>
          <th>{{ $t('Date') }}</th>
          <th>
            <div class="flex justify-between">
              <div>{{ $t('Enrolments') }}</div>
              <div class="text-right">{{ $t('Quantity') }}</div>
            </div>
          </th>
          <th>{{ $t('Status') }}</th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
        <tr v-if="!enrolmentTransfers.data.length">
          <td class="border-t px-6 py-4" colspan="6">{{ $t('No transfers found.') }}</td>
        </tr>
        <tr v-for="transfer in enrolmentTransfers.data" :key="transfer.id">
          <td class="font-semibold text-ts-gray-text-bold">{{ transfer.receiver_email }}</td>
          <td>{{ transfer.created_at }}</td>
          <td>
            <div
              v-for="(enrolment, key, i) in transfer.enrolments"
              :key="key"
              class="flex justify-between"
              :class="i !== Object.keys(transfer.enrolments).length - 1 ? 'pb-4' : ''"
            >
              <div>{{ enrolment[0].course.name }}</div>
              <div>
                <span class="rounded px-2 py-1" :class="statusColor(transfer.transfer_status)">
                  {{ enrolment.length }}
                </span>
              </div>
            </div>
          </td>
          <td>
            <span
              class="inline-flex items-center justify-center rounded px-2 py-1"
              :class="statusColor(transfer.transfer_status)"
            >
              <icon
                v-if="transfer.transfer_status === 'expired'"
                class="mr-1 inline h-5 w-5"
                name="exclamation-circle"
              />
              <span>
                {{ transfer.transfer_status }}
              </span>
              <span v-if="transfer.can.cancel" class="ml-1">
                -
                <button class="underline" @click="$emit('cancelEnrolmentTransfer', transfer)">
                  {{ $t('cancel') }}
                </button>
              </span>
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <front-pagination :links="enrolmentTransfers.links" />
  </div>
</template>

<script>
import statusColor from './mixins/statusColor.js'
import FrontPagination from '@/Shared/FrontPagination.vue'
import Icon from '@/Shared/Icon.vue'

export default {
  components: {
    FrontPagination,
    Icon,
  },
  mixins: [statusColor],
  props: {
    enrolmentTransfers: Object,
  },
}
</script>
