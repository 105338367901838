<template>
  <button
    class="rounded-full text-center align-middle text-xs font-semibold"
    :class="{ 'bg-green-200 px-2 py-1': !!actionRequiredData }"
    @click="toggle"
  >
    <CheckCircleIcon v-if="!!actionRequiredData" aria-hidden="true" class="mb-px mr-px inline h-4 w-4" />
    <PlusIcon v-else aria-hidden="true" class="mb-1 inline h-3 w-3" />
    <span>Require Action</span>
  </button>
</template>
<script>
import { CheckCircleIcon, PlusIcon } from '@heroicons/vue/20/solid'

export default {
  components: {
    CheckCircleIcon,
    PlusIcon,
  },
  props: {
    logicName: String,
    actionRequired: Boolean,
  },
  emits: ['update:actionRequired'],
  data() {
    return {
      actionRequiredData: this.actionRequired ?? false,
    }
  },
  methods: {
    toggle() {
      this.actionRequiredData = !this.actionRequiredData
      this.$emit('update:actionRequired', this.actionRequiredData)
    },
  },
}
</script>
