<template>
  <front-layout :title="$t('Assign Training')">
    <div class="mb-8">
      <breadcrumb
        class="font-heading text-2xl font-semibold"
        :middle-name="$t('Course Selection')"
        :middle-url="route('front.manager.enrol.user.step1', user.id)"
        :name="$t('Class Selection')"
        :previous-name="user.name"
        :previous-url="route('front.manager.users.show', user.id)"
      />
    </div>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="flex flex-wrap p-8">
          <div class="w-full space-y-8">
            <div v-for="(course, key) in courses" :key="course.id" class="flex flex-col">
              <div>
                <div class="mb-8 flex items-center justify-between">
                  <span class="font-bold">{{ course.name }}</span>
                </div>
                <div class="ml-4">
                  <label v-if="true || form.errors[`courses.${course.id}`]" class="form-error mb-4 block">
                    {{ form.errors[`courses.${course.id}`] }}
                  </label>
                  <div :key="course.id" class="flex">
                    <div class="w-1/2 pb-8 pr-6">
                      <front-class-search-input
                        v-if="courses[key].can.selectAllClasses"
                        v-model="form.courses[course.id].class"
                        :course-id="course.id"
                        :error="form.errors[`courses.${course.id} .class`]"
                        :label="$t('Class')"
                      />
                      <front-select-input
                        v-else
                        v-model="form.courses[course.id].class"
                        :error="form.errors[`courses.${course.id}.class`]"
                        :label="$t('Class')"
                      >
                        <option disabled :value="null">{{ $t('Select') }}</option>
                        <option v-for="class_ in course.classes" :key="class_.id" :value="class_.id">
                          {{ class_.formatted_name }}
                        </option>
                        <option v-if="course.openEnrolmentsCount" :value="null">
                          {{
                            $t('Open Date - :number_of_class Seat(s)', { number_of_class: course.openEnrolmentsCount })
                          }}
                        </option>
                      </front-select-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 px-8 py-4">
          <front-loading-button :loading="form.processing" type="submit">
            {{ $t('Assign Training') }}
          </front-loading-button>
        </div>
      </form>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontClassSearchInput from '@/Shared/FrontClassSearchInput.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontSelectInput from '@/Shared/FrontSelectInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    FrontClassSearchInput,
    FrontLayout,
    FrontLoadingButton,
    FrontSelectInput,
  },
  props: {
    user: Object,
    courses: Array,
  },
  data() {
    var course_list = {}
    this.courses?.map((course) => {
      course_list[course.id] = {
        id: course.id,
        class: null,
      }
    })

    return {
      form: useForm({
        courses: course_list,
      }),
      isSearching: false,
    }
  },
  methods: {
    submit() {
      this.form
        .transform(function (data) {
          const courseIdArray = Object.keys(data.courses)
          const courseList = {}

          courseIdArray.forEach(function (courseId) {
            const classData = data.courses[courseId].class
            if (typeof classData === 'object' && classData !== null && !Array.isArray(classData)) {
              courseList[courseId] = {
                ...data.courses[courseId],
                class: classData.id,
              }
              return
            }
            courseList[courseId] = {
              ...data.courses[courseId],
            }
          })

          return { courses: courseList }
        })
        .post(this.route('front.manager.enrol.user.step2.store', this.user))
    },
    onChange(courseId) {
      this.form.clearErrors(`courses.${courseId}`)
    },
  },
}
</script>
