<template>
  <layout :title="$t('Create Training Records')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink
        class="text-blue-600 hover:text-blue-700"
        :href="route('training-record-templates.edit', template.id)"
      >
        {{ $t('Training Record Template') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Create Training Record') }}
    </h1>
    <div class="flex w-full">
      <div class="w-full max-w-lg overflow-hidden rounded bg-white shadow">
        <form @submit.prevent="submit">
          <div class="-mb-8 -mr-6 flex flex-wrap p-8">
            <div class="w-full pb-8 pr-6">
              <date-input
                v-model="form.completed_at"
                :error="form.errors.completed_at"
                :label="$t('Completed At')"
                placeholder="Select Completion Date"
              />
            </div>
            <div class="w-full pb-8 pr-6">
              <select-input v-model="form.user_id" :error="form.errors.user_id" :label="$t('Student')">
                <option value="" />
                <option v-for="user in users" :key="user.id" :value="user.id">
                  {{ user.first_name }} {{ user.last_name }}
                </option>
              </select-input>
            </div>
            <div class="w-full pb-8 pr-6">
              <span class="text-xl">{{ $t('Training Documents') }}</span>
              <div class="flex items-center justify-between pb-3 pt-6">
                <div class="flex flex-col">
                  <span class="mb-2 font-semibold">{{ $t('Record of Completion') }}</span>
                  <span v-if="form.primary_file && form.primary_file.name" class="flex items-center">
                    <span class="mr-2">{{ form.primary_file.name }}</span>
                    <button @click="form.primary_file = null">
                      <icon class="h-4 w-4 fill-gray-600 hover:fill-gray-800" name="close" />
                    </button>
                  </span>
                  <span v-else>{{ $t('No file selected.') }}</span>
                  <div v-if="form.errors.primary_file" class="form-error text-ts-front-label-error">
                    {{ form.errors.primary_file }}
                  </div>
                </div>
                <div class="">
                  <label class="btn-gray-outline px-4 py-3" for="primary-file">
                    {{ form.primary_file ? $t('Replace Document') : $t('Upload Document') }}
                  </label>
                  <input
                    id="primary-file"
                    accept=".pdf,.jpg,.jpeg,.png,.gif,.xlsx,.docx"
                    style="display: none"
                    type="file"
                    @input="form.primary_file = $event.target.files[0]"
                  />
                </div>
              </div>
              <div class="flex items-center justify-between py-3">
                <div class="flex flex-col">
                  <span class="mb-2 font-semibold">{{ $t('Additional Record') }}</span>
                  <span v-if="form.secondary_file && form.secondary_file.name" class="flex items-center">
                    <span class="mr-2">{{ form.secondary_file.name }}</span>
                    <button @click="form.secondary_file = null">
                      <icon class="h-4 w-4 fill-gray-600 hover:fill-gray-800" name="close" />
                    </button>
                  </span>
                  <span v-else>{{ $t('No file selected.') }}</span>
                  <div v-if="form.errors.secondary_file" class="form-error text-ts-front-label-error">
                    {{ form.errors.secondary_file }}
                  </div>
                </div>
                <div class="">
                  <label class="btn-gray-outline px-4 py-3" for="secondary-file">
                    {{ form.secondary_file ? 'Replace Document' : 'Upload Document' }}
                  </label>
                  <input
                    id="secondary-file"
                    accept=".pdf,.jpg,.jpeg,.png,.gif,.xlsx,.docx"
                    style="display: none"
                    type="file"
                    @input="form.secondary_file = $event.target.files[0]"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
            <loading-button class="btn-blue" :loading="form.processing" type="submit">
              {{ $t('Save Record') }}
            </loading-button>
          </div>
        </form>
      </div>
    </div>
  </layout>
</template>

<script>
import DateInput from '@/Shared/DateInput.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    DateInput,
    Icon,
    Layout,
    LoadingButton,
    SelectInput,
  },
  props: {
    template: Object,
    users: Array,
  },
  data() {
    return {
      form: useForm({
        user_id: null,
        completed_at: null,
        primary_file: null,
        secondary_file: null,
      }),
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('training-records.store', this.template.id))
    },
  },
}
</script>
