<template>
  <modal class="rounded text-base" :show="show" style="z-index: 100000" width="auto" @close="close">
    <div class="max-h-screen max-w-lg overflow-auto rounded bg-white pt-6 shadow">
      <div class="flex flex-wrap">
        <success-message v-if="form.wasSuccessful" class="mx-6 mb-1 mt-6 w-full">
          {{ $t('Note created successfully!') }}
        </success-message>
        <div v-if="notes.length > 0" class="w-full px-6 py-4">
          <div v-for="note in notes" :key="note.id" class="py-3">
            <div
              class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 leading-normal transition duration-150 ease-in-out"
              type="text"
            >
              {{ note.content }}
            </div>
            <div class="mt-2 flex items-center justify-between">
              <span class="text-sm text-gray-700">{{ note.user.name || '-' }}</span>
              <span class="text-sm text-gray-700">{{ note.created_at }}</span>
            </div>
          </div>
        </div>
        <div v-else class="w-full px-6 py-4">{{ $t('No notes have been created.') }}</div>
        <div class="w-full px-6 pb-6 pt-3">
          <textarea-input
            v-model="form.content"
            :autosize="true"
            :error="form.errors.content"
            :placeholder="$t('New note..')"
            :rows="3"
          />
        </div>
        <div class="flex w-full items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <div class="flex">
            <button class="btn-blue" @click.stop="addNote">{{ $t('Add Note') }}</button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '@/Shared/Modal.vue'
import SuccessMessage from '@/Shared/SuccessMessage.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import Http from '@/Utils/Http'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Modal,
    SuccessMessage,
    TextareaInput,
  },
  props: {
    show: Boolean,
    student: Object,
  },
  data() {
    return {
      notes: [],
      form: useForm({
        content: null,
      }),
    }
  },
  mounted() {
    this.setNotes()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    setNotes() {
      Http.get(this.route('instructor.notes', this.student)).then((response) => {
        this.notes = response.data
      })
    },
    addNote() {
      this.form.post(this.route('notes.store', { noteTypesEnum: 'enrolment', id: this.student.enrolment.id }), {
        onSuccess: () => {
          this.setNotes()
          this.form.content = null
        },
      })
    },
  },
}
</script>
