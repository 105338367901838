<template>
  <div class="p-8">
    <div class="mt-4 flex items-end justify-between">
      <h2 class="hidden text-lg">{{ $t('Subscriptions') }}</h2>
    </div>
    <div class="mt-4 overflow-x-auto rounded bg-white shadow">
      <SimpleTable>
        <template #head>
          <TableHead>{{ $t('Name') }}</TableHead>
          <TableHead />
        </template>
        <TableRow v-if="subscriptionPlanAccesses.data.length === 0">
          <TableData class="border-t px-6 py-4" colspan="5">{{ $t('No Subscription Access found.') }}</TableData>
        </TableRow>
        <TableRow
          v-for="subscriptionPlanAccess in subscriptionPlanAccesses.data"
          :key="subscriptionPlanAccess.id"
          :class="{ 'opacity-50': subscriptionPlanAccess.deleted_at || subscriptionPlanAccess.archived_at }"
          :clickable="true"
          @click="$inertia.visit(route('subscription-plan-access.edit', subscriptionPlanAccess.id))"
        >
          <TableData>
            <span>{{ subscriptionPlanAccess.subscriptionPlan.title }}</span>
            <cancelled-badge v-if="subscriptionPlanAccess.canceled_at" />
            <archived-badge v-if="subscriptionPlanAccess.archived_at" />
            <deleted-badge v-if="subscriptionPlanAccess.deleted_at" />
          </TableData>
          <TableData class="w-min">
            <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
          </TableData>
        </TableRow>
      </SimpleTable>
    </div>
    <pagination :links="subscriptionPlanAccesses.links" :preserve-scroll="true" />
  </div>
</template>

<script>
import UserEditLayoutVue from './UserEditLayout.vue'
import ArchivedBadge from '@/Shared/ArchivedBadge.vue'
import CancelledBadge from '@/Shared/CancelledBadge.vue'
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'

export default {
  components: {
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
    ArchivedBadge,
    CancelledBadge,
    DeletedBadge,
    Icon,
    Pagination,
  },
  layout: [Layout, UserEditLayoutVue],
  props: {
    subscriptionPlanAccesses: Object,
  },
}
</script>
