<template>
  <a
    class="grid grid-rows-3 items-center gap-6 rounded-lg bg-white p-6 shadow hover:shadow-lg"
    href="#"
    @click="onClickTile"
  >
    <div class="relative flex items-start justify-between">
      <front-course-status v-if="enrolment.user_id" :status="enrolment.status" />
      <div
        v-else
        class="inline-block w-24 rounded-md bg-green-900 bg-opacity-8 px-2 py-3 text-center text-sm font-semibold text-green-900"
      >
        {{ $t('Available') }}
      </div>
      <button v-if="enrolment.can.unenrol" class="pb-4 pl-6" @click.stop.prevent="onClickMoreDetails">
        <icon class="h-4 w-4 fill-current text-darkGray-400" name="dots-horizontal" />
      </button>
      <front-extra-menu
        v-if="enrolment.can.unenrol"
        class="right-0 -mr-8 mt-6"
        :show="showMenu"
        @close="hideMoreDetails"
      >
        <button
          class="border border-gray-100 bg-white px-6 py-3 text-ts-red-500 shadow-sm"
          @click.stop.prevent="unenrol"
        >
          {{ $t('Unenrol') }}
        </button>
      </front-extra-menu>
    </div>
    <div class="-mb-2 font-semibold leading-normal text-ts-gray-text-bold">
      {{ enrolment.name }}
      <div v-if="enrolment.session_date_range && enrolment.status !== 'New'" class="flex items-center">
        <icon class="h-4 w-4 flex-shrink-0 fill-ts-front-label" name="calendar" />
        <span class="ml-1 text-sm text-ts-front-label">{{ enrolment.session_date_range }}</span>
      </div>
    </div>
    <div v-if="enrolment.user_id && enrolment.finished_contents === 0 && enrolment.status !== 'Complete'">
      <p class="mb-1 flex justify-end text-sm font-semibold text-ts-orange-900">0 / 100%</p>
      <div class="h-3 w-full rounded-full bg-ts-orange-900 opacity-8" />
    </div>
    <div v-else-if="enrolment.status === 'Complete'">
      <p class="mb-1 flex justify-end text-sm font-semibold text-ts-gray-text-bold opacity-75">{{ $t('Completed') }}</p>
      <div class="h-3 w-full rounded-full bg-ts-gray-text-bold opacity-8" />
    </div>
    <div v-else-if="enrolment.finished_contents < enrolment.total_contents">
      <p class="mb-1 flex justify-end text-sm font-semibold text-ts-blue-500">
        {{ $t(':progress / 100%', { progress: enrolment.progress }) }}
      </p>
      <progress-bar
        class="h-3"
        fill-bg-class="bg-ts-blue-500"
        :model-value="enrolment.progress"
        track-bg-class="bg-ts-front-border-light"
      />
    </div>
  </a>
</template>
<script>
import FrontCourseStatus from '@/Shared/FrontCourseStatus.vue'
import FrontExtraMenu from '@/Shared/FrontExtraMenu.vue'
import Icon from '@/Shared/Icon.vue'
import ProgressBar from '@/Shared/ProgressBar.vue'

export default {
  components: {
    FrontCourseStatus,
    FrontExtraMenu,
    Icon,
    ProgressBar,
  },
  props: {
    enrolment: Object,
  },
  data() {
    return {
      showMenu: false,
    }
  },
  methods: {
    onClickMoreDetails() {
      this.showMenu = true
    },
    hideMoreDetails() {
      this.showMenu = false
    },
    onClickTile() {
      if (this.enrolment.user_id) {
        this.$inertia.visit(this.route('front.contents', this.enrolment.id))
      } else {
        this.$emit('open:unassignedEnrolmentModal', this.enrolment)
      }
    },
    unenrol() {
      this.$inertia.post(this.route('front.enrolments.unenrol', this.enrolment.id))
    },
  },
}
</script>
