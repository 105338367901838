<template>
  <div
    class="min-h-screen w-full bg-bottom font-nexa"
    style="
      background-image: url(/images/patterned-bg-cropped.svg);
      background-position: 100% 100%;
      background-size: 70%;
      background-repeat: no-repeat;
    "
  >
    <DynamicHead :title="pageTitle" />
    <div class="w-full" :class="isTenantWorksite ? 'bg-gradient-to-r from-ts-orange-900 to-ts-red-900' : 'bg-blue-700'">
      <InertiaLink class="block" href="/">
        <img
          v-if="$page.props.app.tenant.logo"
          alt="Account Logo"
          class="mx-auto h-24 py-5"
          :src="$page.props.app.tenant.logo"
        />
        <front-qualify v-else class="mx-auto h-24 py-8" />
      </InertiaLink>
    </div>

    <div class="flex w-full flex-col items-center overflow-hidden px-4 py-8 md:px-20 md:py-16">
      <div
        v-if="$page.props.flash.success && showFlash"
        class="no-print mb-8 flex max-w-lg items-center justify-between rounded bg-green-400"
      >
        <div class="flex items-center">
          <icon class="ml-4 mr-2 h-4 w-4 flex-shrink-0 fill-white" name="checkmark" />
          <div class="py-4 text-sm font-medium text-white">{{ $page.props.flash.success }}</div>
        </div>
        <button class="group mr-2 p-2" type="button" @click="showFlash = false">
          <icon class="block h-3 w-3 fill-green-300 group-hover:fill-green-100" name="close" />
        </button>
      </div>
      <div
        v-if="$page.props.flash.error && showFlash"
        class="no-print mb-8 flex max-w-lg items-center justify-between rounded bg-red-400"
      >
        <div class="flex items-center">
          <icon class="ml-4 mr-2 h-4 w-4 flex-shrink-0 fill-white" name="close-outline" />
          <div class="py-4 text-sm font-medium text-white">{{ $page.props.flash.error }}</div>
        </div>
        <button class="group mr-2 p-2" type="button" @click="showFlash = false">
          <icon class="block h-3 w-3 fill-red-300 group-hover:fill-red-100" name="close" />
        </button>
      </div>
      <div
        v-if="$page.props.errors.length > 0 && showFlash"
        class="no-print mb-8 flex max-w-lg items-center justify-between rounded bg-red-400"
      >
        <div class="flex items-center">
          <icon class="ml-4 mr-2 h-4 w-4 flex-shrink-0 fill-white" name="close-outline" />
          <div class="py-4 text-sm font-medium text-white">
            <span v-if="$page.props.errors.length === 1">{{ $t('There is one form error.') }}</span>
            <span v-else>{{ $t('There are :length form errors.', { length: $page.props.errors.length }) }}</span>
          </div>
        </div>
        <button class="group mr-2 p-2" type="button" @click="showFlash = false">
          <icon class="block h-3 w-3 fill-red-300 group-hover:fill-red-100" name="close" />
        </button>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import DynamicHead from './DynamicHead.vue'
import Icon from './Icon.vue'
import FrontQualify from '@/Shared/Logo/FrontQualify.vue'
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  components: {
    DynamicHead,
    Icon,
    FrontQualify,
  },
  props: {
    title: String,
    tenant: Object,
  },
  data() {
    return {
      showFlash: true,
      open: false,
    }
  },
  computed: {
    isTenantWorksite,
    pageTitle() {
      return this.title ? `${this.title} | ${this.$page.props.app.name}` : this.$page.props.app.name
    },
  },
  watch: {
    '$page.props.flash': {
      handler() {
        this.showFlash = true
      },
      deep: true,
    },
  },
}
</script>
