<template>
  <front-layout :title="$t('Enrolment Details')">
    <div class="flex flex-col md:flex-row">
      <div class="w-full">
        <breadcrumb
          class="hidden md:inline"
          :name="$t('Enrolment Details (:course_name)', { course_name: enrolment.course.name })"
        />
        <breadcrumb
          class="md:hidden"
          :name="$t('Enrolment Details (:course_name)', { course_name: enrolment.course.name })"
        />
        <InertiaLink
          class="text-darkGray flex items-center px-3 pt-4 hover:font-semibold"
          :href="route('front.manager.reports.courses.show')"
        >
          <icon class="block h-3 w-3 fill-current" name="arrow-left" />
          <span class="ml-1">{{ $t('Back') }}</span>
        </InertiaLink>
      </div>
    </div>
    <div>
      <div class="-mb-8 -mr-8 mt-3 flex w-full flex-wrap md:mt-8">
        <div class="flex justify-between">
          <h2 class="text-xl font-semibold">{{ $t('Content Progress') }}</h2>
        </div>
        <div class="mt-6 w-full overflow-x-auto rounded bg-white shadow-lg">
          <table class="front-table w-full">
            <thead>
              <tr>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
                  {{ $t('Id') }}
                </th>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
                  {{ $t('Name') }}
                </th>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
                  {{ $t('Type') }}
                </th>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
                  {{ $t('Required for Completion') }}
                </th>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">{{ $t('Finished At') }}</th>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">{{ $t('Result') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="enrolmentContents.length === 0" class="h-24">
                <td class="border-t px-6 py-4" colspan="5">
                  {{
                    $t(
                      'This user has not made any progress with the content in this course, or the course is missing content.'
                    )
                  }}
                </td>
              </tr>
              <tr
                v-for="(enrolmentContentObject, index) in enrolmentContents"
                :key="enrolmentContentObject.id"
                class="mt-6 h-16 align-top hover:bg-gray-100"
              >
                <td class="capitalize text-ts-gray-text-bold">{{ index + 1 }}</td>
                <td class="text-ts-gray-text-bold">{{ enrolmentContentObject.name }}</td>
                <td class="capitalize text-ts-gray-text-bold">{{ enrolmentContentObject.contentable_type }}</td>
                <td class="text-ts-gray-text-bold">{{ enrolmentContentObject.is_required ? 'Yes' : '' }}</td>
                <td class="text-ts-gray-text-bold">
                  <show-date :timestamp="enrolmentContentObject.finished_at" />
                </td>
                <td class="text-ts-gray-text-bold">
                  <passed-status
                    :finished-at="enrolmentContentObject.finished_at"
                    :passed="enrolmentContentObject.passed"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <template v-for="(enrolmentContentObject, index) in enrolmentContents" :key="index">
          <div v-if="enrolmentContentObject.contentable_type === 'quiz'" class="w-full">
            <div class="mt-12">
              <h2 class="text-xl font-semibold">{{ $t('Quiz Results') }}</h2>
            </div>
            <div class="mt-6 w-full overflow-x-auto rounded bg-white shadow-lg">
              <table class="front-table w-full">
                <thead>
                  <tr>
                    <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
                      {{ $t('Attempt') }}
                    </th>
                    <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
                      {{ $t('Status') }}
                    </th>
                    <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
                      {{ $t('Score') }}
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="enrolmentContentObject.attempts.data.length === 0" class="h-24">
                    <td class="px-6 py-4" colspan="4">
                      {{
                        $t(
                          'This user has not made any progress with the content in this course, or the course is missing content.'
                        )
                      }}
                    </td>
                  </tr>
                  <tr
                    v-for="attempt in enrolmentContentObject.attempts.data"
                    :key="attempt.id"
                    class="h-16 cursor-pointer align-top hover:bg-gray-100 focus:bg-gray-100"
                    @click="
                      $inertia.get(
                        route('front.manager.quizzes.show', [enrolmentContentObject.id, attempt.quiz.id, attempt.id])
                      )
                    "
                  >
                    <td class="capitalize text-ts-gray-text-bold">{{ attempt.count }}</td>
                    <td class="text-ts-gray-text-bold">
                      <QuizStatus :status="attempt.status" />
                    </td>
                    <td class="capitalize text-ts-gray-text-bold">
                      <div v-if="attempt.score">{{ attempt.score }} / {{ attempt.total_score }}</div>
                      <div v-else>-</div>
                    </td>
                    <td class="w-min px-4">
                      <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <front-pagination
              class="flex w-full justify-end"
              :links="enrolmentContentObject.attempts.links"
              :preserve-state="true"
            />
          </div>
        </template>
      </div>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontPagination from '@/Shared/FrontPagination.vue'
import Icon from '@/Shared/Icon.vue'
import PassedStatus from '@/Shared/PassedStatus.vue'
import QuizStatus from '@/Shared/Quiz/QuizStatus.vue'
import ShowDate from '@/Shared/ShowDate.vue'

export default {
  components: {
    QuizStatus,
    Breadcrumb,
    FrontLayout,
    FrontPagination,
    Icon,
    PassedStatus,
    ShowDate,
  },
  props: {
    enrolment: Object,
    enrolmentContent: Object,
    enrolmentContents: Array,
    attempts: Array,
  },
}
</script>

<style scoped>
.front-table td {
  padding-top: 1.25rem !important;
}
</style>
