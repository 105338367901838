<template>
  <div class="flex w-full flex-col items-center">
    <div class="ml-3 flex w-full items-center">
      <button class="mr-3 flex items-center justify-center" @click="addLogic">
        <Icon class="mr-2 h-5 w-5 fill-current text-gray-500" name="preferences" />
        <span>{{ $t('Add logic') }}</span>
      </button>
      <div class="mr-3 text-sm">
        <front-checkbox-input v-model="optionsData.is_required" :label="$t('Required')" />
      </div>
    </div>
    <div class="flex w-full items-center">
      <LogicTabs ref="logicTabs" v-model:model-value="logicsData">
        <LogicTab
          v-for="(logic, index) in logicsData"
          :id="`tab-${index + 1}`"
          :key="index"
          :name="logicNames[index]"
          @delete="removeLogic(index)"
        >
          {{ $t('If value') }}
          <select v-model="logic.operator" class="ml-2 mr-4">
            <option v-for="(value, key) in operators" :key="key" :value="key">{{ $t(value) }}</option>
          </select>
          <span>
            <input v-model="logic.value_one" placeholder="blank" type="text" />
          </span>
          <span class="pr-2">{{ $t('then') }}</span>
          <span>
            <TriggerAction v-model:action-required="logic.action_required" :logic-name="logicNames[index]" />
            <span class="pr-2">,</span>
            <TriggerEvidence v-model:evidence="logic.evidence" :logic-name="logicNames[index]" />
            <span class="px-2">or</span>
            <TriggerNotify v-model:notify="logic.notify" :logic-name="logicNames[index]" />
          </span>
        </LogicTab>
      </LogicTabs>
    </div>
  </div>
</template>
<script>
import LogicTab from './LogicTab.vue'
import LogicTabs from './LogicTabs.vue'
import TriggerAction from './TriggerAction.vue'
import TriggerEvidence from './TriggerEvidence.vue'
import TriggerNotify from './TriggerNotify.vue'
import FrontCheckboxInput from '@/Shared/FrontCheckboxInput.vue'
import Icon from '@/Shared/Icon.vue'

export default {
  components: {
    LogicTab,
    LogicTabs,
    TriggerAction,
    TriggerEvidence,
    TriggerNotify,
    FrontCheckboxInput,
    Icon,
  },
  props: {
    options: Object,
    logics: Array,
  },
  emits: ['update:options', 'update:logics'],
  data() {
    return {
      optionsData: {
        is_required: this.options?.is_required ?? false,
      },
      logicsData: this.logics ?? [],
    }
  },
  computed: {
    logicNames() {
      return this.logicsData.map((logic) => {
        return logic ? `value ${logic.operator} ${logic.value_one ?? 'blank'}` : ''
      })
    },
    defaultLogicData() {
      return {
        operator: '=',
        value_one: null,
        action_required: false,
        evidence: {
          notes_required: false,
          media_required: false,
        },
      }
    },
  },
  watch: {
    optionsData: {
      handler: function () {
        this.$emit('update:options', this.optionsData)
      },
      deep: true,
    },
    logicsData: {
      handler: function () {
        this.$emit('update:logics', this.logicsData)
      },
      deep: true,
    },
  },
  methods: {
    addLogic() {
      this.$refs.logicTabs.add(this.defaultLogicData)
    },
    removeLogic(index) {
      this.$refs.logicTabs.remove(index)
    },
  },
}
</script>
