<template>
  <div class="mt-6 flex max-w-lg flex-wrap">
    <template v-for="(link, key) in links">
      <InertiaLink
        v-if="!(link.label === $t('Previous') || link.label === $t('Next'))"
        :key="key"
        class="mb-2 mr-1 rounded border px-4 py-3 text-sm hover:bg-white"
        :class="{
          'bg-white': link.active,
          'font-bold': highlight(key),
        }"
        :href="link.url"
        replace
      >
        {{ link.label }}
      </InertiaLink>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    links: Array,
    incomplete: Array,
  },
  methods: {
    highlight(number) {
      return this.incomplete.includes(number)
    },
  },
}
</script>
