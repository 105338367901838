<template>
  <div>
    <div class="mt-8 flex items-end justify-between">
      <h2 class="text-lg">{{ $t('Content Progress') }}</h2>
      <div class="flex items-center">
        <template v-if="enrolmentContentsOutdated">
          <p class="cursor-default p-2 text-sm text-qualify-red-500">
            <span class="dot" />
            Contents Outdated
          </p>
          <button class="btn-blue ml-3" type="button" @click.stop="$emit('resetContents')">
            {{ $t('Reset Contents') }}
          </button>
        </template>
        <button v-if="markAsFailedEnabled" class="btn-blue ml-3" type="button" @click.stop="$emit('markFailed')">
          {{ $t('Mark As Failed') }}
        </button>
      </div>
    </div>
    <div class="mt-4 overflow-x-auto rounded bg-white shadow">
      <table class="front-table w-full">
        <thead>
          <tr>
            <th v-if="hasUnfinishedEnrolmentContent">
              <row-select-checkbox id="selectAll" v-model="selectAll" @update:model-value="selectAllUpdated" />
            </th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Id') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Name') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Type') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Required') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Progress') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Finished At') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Result') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold" />
          </tr>
        </thead>
        <tbody>
          <tr v-if="enrollmentContents.length === 0">
            <td class="border-t px-6 py-4" colspan="5">
              {{
                $t(
                  'This user has not made any progress with the content in this course, or the course is missing content.'
                )
              }}
            </td>
          </tr>
          <tr
            v-for="(enrollmentContent, index) in enrollmentContents"
            :key="enrollmentContent.id"
            class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
          >
            <td v-if="hasUnfinishedEnrolmentContent">
              <row-select-checkbox
                v-if="!enrollmentContent.finished_at"
                :id="'checkbox-' + enrollmentContent.id"
                v-model="form.enrollment_contents"
                :value="enrollmentContent.id"
              />
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4 capitalize">{{ index + 1 }}</td>
            <td class="flex h-auto flex-col whitespace-nowrap border-t px-6 py-4">
              <div>
                <span>{{ enrollmentContent.title }}</span>
                <span v-if="enrollmentContent.name" class="ml-2 text-xs">({{ enrollmentContent.name }})</span>
              </div>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4 capitalize">{{ enrollmentContent.contentable_type }}</td>
            <td class="whitespace-nowrap border-t px-6 py-4">{{ enrollmentContent.is_required ? 'Yes' : '' }}</td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <div v-if="enrollmentContent.progress" class="m-1 flex flex-wrap space-x-2 text-xs">
                <div
                  v-if="enrollmentContent.progress.time_tracked"
                  class="flex rounded-full bg-gray-500 px-2 text-white"
                >
                  <span>{{ $t('Time tracked') }} :</span>
                  <span>{{ enrollmentContent.progress.time_tracked }}</span>
                </div>
                <div v-if="enrollmentContent.progress.url">
                  <a class="link" :href="enrollmentContent.progress.url" target="_blank">Link</a>
                </div>
              </div>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <show-date :timestamp="enrollmentContent.finished_at" />
            </td>
            <td class="border-t px-6 py-4 leading-6">
              <passed-status :finished-at="enrollmentContent.finished_at" :passed="enrollmentContent.passed" />
            </td>
            <td>
              <template v-if="enrollmentContent.contentable_type === 'quiz'">
                <InertiaLink
                  class="rounded border-2 px-3 py-1"
                  :href="route('enrolment-contents.attempts', enrollmentContent)"
                >
                  More
                </InertiaLink>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex">
      <button
        v-if="markAllCompeletedEnabled"
        class="btn-blue float-right mt-2"
        :disabled="form.enrollment_contents.length == 0"
        type="button"
        @click.stop="$emit('markComplete', form.enrollment_contents)"
      >
        {{ $t('Mark Complete') }}
      </button>
    </div>
  </div>
</template>
<script>
import PassedStatus from '@/Shared/PassedStatus.vue'
import RowSelectCheckbox from '@/Shared/RowSelectCheckbox.vue'
import ShowDate from '@/Shared/ShowDate.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    PassedStatus,
    RowSelectCheckbox,
    ShowDate,
  },
  props: {
    markAllCompeletedEnabled: {
      type: Boolean,
      required: true,
    },
    markAsFailedEnabled: {
      type: Boolean,
      required: true,
    },
    enrolmentContentsOutdated: {
      type: Boolean,
      required: true,
    },
    enrollmentContents: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectAll: false,
      form: useForm({
        enrollment_contents: [],
      }),
    }
  },
  computed: {
    hasUnfinishedEnrolmentContent() {
      return this.enrollmentContents.filter((enrollmentContent) => !enrollmentContent.finished_at).length > 0
    },
  },
  watch: {
    'form.enrollment_contents': {
      handler() {
        if (
          this.enrollmentContents.every((enrollmentContent) =>
            this.form.enrollment_contents.includes(enrollmentContent.id)
          )
        ) {
          return (this.selectAll = true)
        }
        this.selectAll = false
      },
      deep: true,
    },
  },
  methods: {
    selectAllUpdated() {
      if (this.selectAll) {
        this.selectAllEnrolmentContents()
      } else {
        this.unSelectAllEnrolmentContents()
      }
    },
    selectAllEnrolmentContents() {
      this.form.enrollment_contents = this.enrollmentContents
        .filter((enrollmentContent) => !enrollmentContent.finished_at)
        .map(function (enrollmentContent) {
          return enrollmentContent.id
        })
    },
    unSelectAllEnrolmentContents() {
      this.form.enrollment_contents = []
    },
  },
}
</script>
<style scoped>
.dot {
  height: 10px;
  width: 10px;
  background-color: #dd163d;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
</style>
