<template>
  <layout :title="$t('Request - View')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('requests')">
        {{ $t('Requests') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ studentUpdateRequest.id }}
    </h1>
    <deleted-message v-if="studentUpdateRequest.deleted_at" class="mb-6">
      {{ $t('This request has been deleted.') }}
    </deleted-message>
    <div class="flex max-w-3xl flex-wrap">
      <div class="w-full lg:w-3/5 xl:w-2/3">
        <div class="max-w-lg overflow-hidden rounded bg-white shadow">
          <form @submit.prevent="submit">
            <div class="-mb-8 -mr-6 flex flex-wrap p-8">
              <div class="lg:w-1/2">
                <div class="mb-8 mr-6 bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">
                  {{ $t('User Information') }}
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input v-model="user.first_name" disabled :label="$t('First Name')" />
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input v-model="user.last_name" disabled :label="$t('Last Name')" />
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input v-model="user.birth_day" disabled :label="$t('Birth Day')" />
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input v-model="user.birth_month" disabled :label="$t('Birth Month')" />
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input v-model="user.birth_year" disabled :label="$t('Birth Year')" />
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input v-model="user.username" disabled :label="$t('Username')" />
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input v-model="user.email" disabled :label="$t('Email')" />
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input v-model="user.phone" disabled :label="$t('Phone')" />
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input v-model="user.address" disabled :label="$t('Address')" />
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input v-model="user.city" disabled :label="$t('City')" />
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input v-model="user.region" disabled :label="$t('Region')" />
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input v-model="user.country" disabled :label="$t('Country')" />
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input v-model="user.postal_code" disabled :label="$t('Postal Code')" />
                </div>
                <div class="w-full pb-8 pr-6">
                  <select-input v-model="user.mol_learner_status" disabled :label="$t('Learner Status')">
                    <option :value="null" />
                    <option v-for="(status, key) in mol_learner_statuses" :key="key" :value="key">
                      {{ status }}
                    </option>
                  </select-input>
                </div>
                <div class="w-full pb-8 pr-6">
                  <select-input v-model="user.mol_sector" disabled :label="$t('Sector')">
                    <option :value="null" />
                    <option v-for="(sector, key) in mol_sectors" :key="key" :value="key">
                      {{ sector }}
                    </option>
                  </select-input>
                </div>
              </div>
              <div class="lg:w-1/2">
                <div class="mb-8 mr-6 bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">
                  {{ $t('Update Request data') }}
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input v-model="form.first_name" :error="form.errors.first_name" :label="$t('First Name')" />
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input v-model="form.last_name" :error="form.errors.last_name" :label="$t('Last Name')" />
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input
                    v-model="form.birth_day"
                    :error="form.errors.birth_day"
                    :label="$t('Birth Day')"
                    max="31"
                    min="1"
                    type="number"
                  />
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input
                    v-model="form.birth_month"
                    :error="form.errors.birth_month"
                    :label="$t('Birth Month')"
                    max="12"
                    min="1"
                    type="number"
                  />
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input
                    v-model="form.birth_year"
                    :error="form.errors.birth_year"
                    :label="$t('Birth Year')"
                    min="1920"
                    type="number"
                  />
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input v-model="form.username" :error="form.errors.username" :label="$t('Username')" />
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input v-model="form.email" :error="form.errors.email" :label="$t('Email')" />
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input v-model="form.phone" :error="form.errors.phone" :label="$t('Phone')" />
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input v-model="form.address" :error="form.errors.phone" :label="$t('Address')" />
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input v-model="form.city" :error="form.errors.city" :label="$t('City')" />
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input v-model="form.region" :error="form.errors.region" :label="$t('Region')" />
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input v-model="form.country" :error="form.errors.region" :label="$t('Country')" />
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input v-model="form.postal_code" :error="form.errors.postal_code" :label="$t('Postal Code')" />
                </div>
                <div class="w-full pb-8 pr-6">
                  <select-input
                    v-model="form.mol_learner_status"
                    :error="form.errors.mol_learner_status"
                    :label="$t('Learner Status')"
                  >
                    <option :value="null" />
                    <option v-for="(status, key) in mol_learner_statuses" :key="key" :value="key">
                      {{ status }}
                    </option>
                  </select-input>
                </div>
                <div class="w-full pb-8 pr-6">
                  <select-input
                    v-model="form.mol_sector"
                    :disabled="!form.mol_learner_status || form.mol_learner_status === 'learner'"
                    :error="form.errors.mol_sector"
                    :label="$t('Sector')"
                  >
                    <option :value="null" />
                    <option v-for="(sector, key) in mol_sectors" :key="key" :value="key">
                      {{ sector }}
                    </option>
                  </select-input>
                </div>
                <div class="w-full pb-8 pr-6">
                  <p>Comments: {{ studentUpdateRequest.comments }}</p>
                </div>
              </div>
            </div>
            <div class="flex items-center justify-between gap-2 border-t border-gray-100 bg-gray-100 px-8 py-4">
              <template v-if="!studentUpdateRequest.deleted_at">
                <button class="text-red-500 hover:underline" tabindex="-1" type="button" @click="destroy">
                  {{ $t('Decline') }}
                </button>
                <div class="flex items-center justify-end gap-2">
                  <div class="max-w-md">
                    <select-input v-model="selectedOption" :options="optionValues" />
                  </div>
                  <loading-button class="btn-blue" :loading="form.processing" type="submit">
                    {{ $t('Submit') }}
                  </loading-button>
                </div>
              </template>
              <template v-else>
                <button class="text-red-500 hover:underline" tabindex="-1" type="button" @click="restore">
                  {{ $t('Restore') }}
                </button>
                <div v-if="studentUpdateRequest.completed_at" class="flex text-ts-green-500">
                  <icon class="mx-1 h-3 w-3 text-ts-green-500" name="checkmark" />
                  <span>{{ $t('Completed') }}</span>
                </div>
                <div v-if="studentUpdateRequest.declined_at" class="flex text-ts-red-500">
                  <span>{{ $t('Declined') }}</span>
                </div>
              </template>
            </div>
          </form>
        </div>
        <model-timestamps :model="studentUpdateRequest" />
      </div>

      <div
        v-if="studentUpdateRequest.student.enrolment"
        class="flex w-full flex-col pt-12 lg:w-2/5 lg:pl-8 lg:pt-0 xl:w-1/3"
      >
        <div class="mb-12 overflow-hidden rounded bg-white shadow lg:mb-8">
          <div class="bg-gray-400 px-4 py-4 text-sm font-bold text-gray-700">{{ $t('Enrolment') }}</div>
          <div class="grid w-full grid-cols-4 gap-4 px-6 pb-3 pt-4 capitalize">
            <div>{{ $t('Owner') }}</div>
            <div class="col-span-3 flex flex-row">
              <div class="mr-1">:</div>
              <div v-if="studentUpdateRequest.student.enrolment.owner" class="flex w-full flex-col">
                <InertiaLink
                  class="text-blue-600 underline"
                  :href="route('users.edit', studentUpdateRequest.student.enrolment.owner.id)"
                >
                  {{
                    `${studentUpdateRequest.student.enrolment.owner.first_name} ${studentUpdateRequest.student.enrolment.owner.last_name}`
                  }}
                </InertiaLink>
                <span
                  v-if="studentUpdateRequest.student.enrolment.owner.organization"
                  class="w-full truncate text-sm text-gray-500"
                >
                  {{ studentUpdateRequest.student.enrolment.owner.organization.name }}
                </span>
              </div>
              <span v-else>-</span>
            </div>
            <div>{{ $t('User') }}</div>
            <div class="col-span-3 flex flex-row">
              <div class="mr-1">:</div>
              <div v-if="studentUpdateRequest.student.enrolment.user" class="flex w-full flex-col">
                <InertiaLink
                  v-if="studentUpdateRequest.student.enrolment.user"
                  class="text-blue-600 underline"
                  :href="route('users.edit', studentUpdateRequest.student.enrolment.user.id)"
                >
                  {{
                    `${studentUpdateRequest.student.enrolment.user.first_name} ${studentUpdateRequest.student.enrolment.user.last_name}`
                  }}
                </InertiaLink>
                <span
                  v-if="studentUpdateRequest.student.enrolment.owner.organization"
                  class="w-full truncate text-sm text-gray-500"
                >
                  {{ studentUpdateRequest.student.enrolment.owner.organization.name }}
                </span>
              </div>
              <span v-else>-</span>
            </div>
            <div>{{ $t('Payment') }}</div>
            <div class="col-span-3 capitalize">: {{ studentUpdateRequest.student.enrolment.payment_status }}</div>
            <div>{{ $t('Order') }}</div>
            <div class="col-span-3">
              :
              <span v-if="studentUpdateRequest.student.enrolment.payment_order_number">
                #{{ studentUpdateRequest.student.enrolment.payment_order_number }}
              </span>
            </div>
          </div>
          <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-6 py-2">
            <InertiaLink class="btn-blue" :href="route('enrolments.edit', studentUpdateRequest.student.enrolment.id)">
              {{ $t('Go To Enrolment') }}
            </InertiaLink>
          </div>
        </div>

        <div class="mb-12 overflow-hidden rounded bg-white shadow lg:mb-8">
          <div class="bg-gray-400 px-4 py-4 text-sm font-bold text-gray-700">{{ $t('Request By') }}</div>
          <div class="grid w-full grid-cols-4 gap-4 px-6 pb-3 pt-4 capitalize">
            <div>{{ $t('Instructor') }}</div>
            <div class="col-span-3">
              : {{ `${studentUpdateRequest.instructor.first_name} ${studentUpdateRequest.instructor.last_name}` }}
            </div>
            <div>{{ $t('Class') }}</div>
            <div class="col-span-3 flex flex-row">
              <div class="mr-1">:</div>
              <div class="flex flex-col">
                <span class="text-sm">{{ studentUpdateRequest.student.class.name }}</span>
                <span class="mt-1 text-xs">{{ studentUpdateRequest.student.class.location_name }}</span>
                <span class="mt-1 text-xs">{{ studentUpdateRequest.student.class.dates }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import DeletedMessage from '@/Shared/DeletedMessage.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import ModelTimestamps from '@/Shared/ModelTimestamps.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    DeletedMessage,
    Icon,
    Layout,
    LoadingButton,
    ModelTimestamps,
    SelectInput,
    TextInput,
  },
  props: {
    studentUpdateRequest: Object,
    mol_learner_statuses: Object,
    mol_sectors: Object,
  },
  data() {
    return {
      selectedOption: 'update',
      user: {
        first_name: this.studentUpdateRequest.student.enrolment.user?.first_name,
        last_name: this.studentUpdateRequest.student.enrolment.user?.last_name,
        birth_day: this.studentUpdateRequest.student.enrolment.user?.birth_day,
        birth_month: this.studentUpdateRequest.student.enrolment.user?.birth_month,
        birth_year: this.studentUpdateRequest.student.enrolment.user?.birth_year,
        username: this.studentUpdateRequest.student.enrolment.user?.username,
        email: this.studentUpdateRequest.student.enrolment.user?.email,
        phone: this.studentUpdateRequest.student.enrolment.user?.phone,
        address: this.studentUpdateRequest.student.enrolment.user?.address,
        city: this.studentUpdateRequest.student.enrolment.user?.city,
        region: this.studentUpdateRequest.student.enrolment.user?.region,
        country: this.studentUpdateRequest.student.enrolment.user?.country,
        postal_code: this.studentUpdateRequest.student.enrolment.user?.postal_code,
        mol_learner_status: this.studentUpdateRequest.student.enrolment.user?.mol_learner_status,
        mol_sector: this.studentUpdateRequest.student.enrolment.user?.mol_sector,
      },
      form: useForm(
        {
          first_name: this.studentUpdateRequest.first_name,
          last_name: this.studentUpdateRequest.last_name,
          birth_day: this.studentUpdateRequest.birth_day,
          birth_month: this.studentUpdateRequest.birth_month,
          birth_year: this.studentUpdateRequest.birth_year,
          username: this.studentUpdateRequest.username,
          email: this.studentUpdateRequest.email,
          phone: this.studentUpdateRequest.phone,
          address: this.studentUpdateRequest.address,
          city: this.studentUpdateRequest.city,
          region: this.studentUpdateRequest.region,
          country: this.studentUpdateRequest.country,
          postal_code: this.studentUpdateRequest.postal_code,
          mol_learner_status: this.studentUpdateRequest.mol_learner_status,
          mol_sector: this.studentUpdateRequest.mol_sector,
        },
        'RequestForm'
      ),
    }
  },
  computed: {
    hasUser: function () {
      return !!this.studentUpdateRequest.student?.enrolment?.user?.id
    },
    optionValues: function () {
      let data = {}
      if (this.hasUser) {
        data['update'] = this.$t('Accept Change Request')
      }

      data['create'] = this.$t('Create New User with Submitted Information')
      return data
    },
    showRequestDataError: function () {
      return !Object.keys(this.form.errors).length > 0
    },
  },
  watch: {
    'form.mol_learner_status'($value) {
      if (!$value || $value === 'learner') {
        this.form.mol_sector = null
      }
    },
    hasUser: function () {
      if (this.hasUser) {
        return (this.selectedOption = 'update')
      }
      this.selectedOption = 'create'
    },
  },
  methods: {
    submit() {
      switch (this.selectedOption) {
        case 'update':
          this.updateUserDataWithFormValues()
          break
        case 'create':
          this.createNewUserWithFormValues()
          break
        default:
          return
      }
    },
    updateUserDataWithFormValues() {
      this.form.put(this.route('requests.user.update', this.studentUpdateRequest.id))
    },
    createNewUserWithFormValues() {
      this.form.post(this.route('requests.user.store', this.studentUpdateRequest.id))
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to decline this request?'))) {
        this.$inertia.delete(this.route('requests.decline', this.studentUpdateRequest.id))
      }
    },
    restore() {
      if (confirm(this.$t('Are you sure you want to restore this request?'))) {
        this.$inertia.put(this.route('requests.restore', this.studentUpdateRequest.id))
      }
    },
  },
}
</script>
