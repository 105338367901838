<template>
  <div class="mx-auto flex flex-col text-gray-400">
    <div class="flex">
      <!-- Timeline -->
      <div class="relative mr-8">
        <content-progress-icon
          :finished="!!enrolmentContent.finished_at"
          :locked="!enrolmentContent.prerequisite_complete"
        />
        <div class="flex h-full w-8 items-center justify-center">
          <div
            v-if="!isLast"
            class="h-full w-1"
            :class="
              !!enrolmentContent.finished_at
                ? isTenantWorksite
                  ? 'bg-qualify-red-500'
                  : 'bg-blue-700'
                : 'bg-darkGray-500'
            "
          />
        </div>
      </div>
      <!-- content -->
      <div
        class="mb-4 flex w-full flex-col justify-between border-b border-ts-gray-300 pb-6 xl:flex-row xl:items-center"
      >
        <div>
          <h3 class="mb-4 font-semibold text-ts-gray-text-bold">
            <small class="mb-2 block uppercase text-gray-500">{{ enrolmentContent.content.type_for_display }}</small>
            {{ enrolmentContent.content.name_for_display }}
          </h3>
          <slot />
        </div>
        <div class="mt-4 w-full pl-0 xl:mt-0 xl:w-auto xl:pl-4">
          <slot name="actions" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ContentProgressIcon from './ContentProgressIcon.vue'
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  components: { ContentProgressIcon },
  props: {
    enrolmentContent: Object,
    isLast: Boolean,
  },
  computed: {
    isTenantWorksite,
  },
}
</script>
