<template>
  <div>
    <div class="group relative h-24 w-24 overflow-hidden rounded-md bg-white">
      <img v-if="imageUrl" :alt="file.name" class="h-full w-full object-cover" :src="imageUrl" />
      <div v-else class="h-full w-full border-gray-500 bg-qualify-red-900 p-3 text-white">
        <div ref="imageNameIcon" class="h-full w-full overflow-hidden text-xs">{{ file.name }}</div>
      </div>
      <TrashIcon
        class="absolute right-0 top-0 m-1 h-8 w-8 rounded-full bg-red-700 bg-opacity-50 fill-current p-2 text-white hover:bg-red-500"
        @click="$emit('delete', file)"
      />
    </div>
    <div v-if="error" class="form-error w-full text-xs">{{ error }}</div>
  </div>
</template>
<script>
import { TrashIcon } from '@heroicons/vue/24/solid'

export default {
  components: { TrashIcon },
  props: {
    file: {
      type: Object,
      required: true,
    },
    error: String,
  },
  emits: ['delete'],
  data() {
    return {
      imageUrl: null,
    }
  },
  mounted() {
    if (this.file.type.startsWith('image')) {
      let reader = new FileReader()
      reader.onload = () => {
        this.imageUrl = reader.result
      }

      reader.readAsDataURL(this.file)
    }
    this.imageUrl = null
  },
}
</script>
