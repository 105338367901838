<template>
  <layout title="Quizzes">
    <breadcrumb-admin :path="breadcrumb" />
    <div class="mt-6 grid gap-6 xl:grid-cols-3">
      <div class="col-span-2 col-start-1">
        <div class="flex justify-between rounded-lg bg-white p-6 text-lg shadow sm:p-8">
          <div class="flex w-full flex-col space-y-4 sm:flex-row sm:space-x-2 sm:space-y-0 lg:space-x-6">
            <div class="flex items-center justify-center sm:items-start">
              <icon class="h-8 w-8 flex-shrink-0 rounded-full fill-gray-500 p-1" name="quiz" />
            </div>
            <div class="flex flex-1 flex-col">
              <div class="mb-1">
                <span class="leading-normal">{{ quiz.name }}</span>
              </div>
              <div class="flex flex-col flex-wrap sm:flex-row">
                <div v-if="quiz.time_limit_minutes" class="mb-1 mr-4 text-sm text-gray-600">
                  <strong>{{ $t('Time limit:') }}</strong>
                  {{ $t(':minutes minutes', { minutes: quiz.time_limit_minutes }) }}
                </div>
                <div class="mb-1 text-sm text-gray-600">
                  <strong>{{ $t('Passing Score:') }}</strong>
                  {{ quiz.passing_score }}/{{ quiz.total_score }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-12 flex justify-between">
      <h2 class="text-xl font-semibold">{{ $t('Results') }}</h2>
    </div>
    <div class="mt-6 w-full rounded bg-white shadow xl:w-2/3">
      <table class="w-full">
        <thead>
          <tr>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">{{ $t('Attempt') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
              {{ $t('Status') }}
            </th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold" colspan="2">
              {{ $t('Score') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="attempt in attempts"
            :key="attempt.id"
            class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
            @click="$inertia.get(route('instructor.quizzes.attempt.show', [attempt.id]))"
          >
            <td class="whitespace-nowrap border-t px-6 py-4">
              <div>{{ attempt.count }}</div>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <QuizStatus :status="attempt.status" />
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <div v-if="attempt.score">{{ attempt.score }} / {{ attempt.total_score }}</div>
              <div v-else>-</div>
            </td>
            <td class="w-min border-t px-4 align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </td>
          </tr>
          <tr v-if="attempts.length === 0">
            <td class="border-t px-6 py-4" colspan="3">{{ $t('No results found.') }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </layout>
</template>

<script>
import BreadcrumbAdmin from '@/Shared/BreadcrumbAdmin.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import QuizStatus from '@/Shared/Quiz/QuizStatus.vue'

export default {
  components: { QuizStatus, BreadcrumbAdmin, Icon, Layout },
  props: {
    class_: Object,
    course: Object,
    enrolment: Object,
    enrolmentContent: Object,
    quiz: Object,
    attempts: Array,
    pass: Boolean,
  },
  data() {
    return {
      requesting: false,
      showConfirmStartQuizModal: false,
    }
  },
  computed: {
    breadcrumb() {
      return [
        {
          name: this.$t('Classes'),
          url: this.route('instructor.classes'),
        },
        {
          name: this.$t(this.class_.name),
          url: this.route('instructor.classes.show', this.class_.id),
        },
        {
          name: this.enrolment.id,
          url: this.route('instructor.enrolments.show', this.enrolment.id),
        },
        {
          name: `${this.$t('Quiz Attempts')}`,
        },
      ]
    },
  },
}
</script>
