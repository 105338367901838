<template>
  <front-layout title="Subscriptions">
    <div class="flex flex-col xl:flex-row xl:justify-between">
      <div class="sm:no-flex mb-8 flex w-full items-center justify-between">
        <breadcrumb name="Subscriptions" />
      </div>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow-lg">
      <table class="front-table">
        <thead>
          <tr>
            <th>{{ $t('Name') }}</th>
            <th>{{ $t('Total') }}</th>
            <th>{{ $t('Assigned') }}</th>
            <th>{{ $t('Available') }}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-if="subscription_plans.data.length === 0">
            <td class="border-t px-6 py-4" colspan="5">{{ $t('No subscription plans found.') }}</td>
          </tr>
          <tr
            v-for="plan in subscription_plans.data"
            :key="plan.id"
            class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
          >
            <td class="font-semibold text-ts-gray-text-bold">{{ plan.title }}</td>
            <td>{{ plan.total_accesses_count }}</td>
            <td>{{ plan.assigned_accesses_count }}</td>
            <td>{{ plan.unassigned_accesses_count }}</td>
            <td>
              <span
                class="font-semibold hover:underline"
                @click="$inertia.visit(route('front.manager.subscriptions.show', plan.id))"
              >
                {{ $t('Manage Plan') }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination :links="subscription_plans.links" />
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import Pagination from '@/Shared/Pagination.vue'

export default {
  components: {
    Breadcrumb,
    FrontLayout,
    Pagination,
  },
  props: {
    subscription_plans: Object,
  },
}
</script>
