<template>
  <div v-if="enrolmentTransfers.data.length > 0" class="py-2">
    <h3 class="pb-4 font-semibold">{{ $t('Received Enrolment Transfer') }}</h3>
    <table class="front-table">
      <thead class="bg-ts-front-table-header">
        <tr>
          <th class="font-semibold">{{ $t('Received From') }}</th>
          <th>{{ $t('Date') }}</th>
          <th>
            <div class="flex justify-between">
              <div>{{ $t('Enrolments') }}</div>
              <div class="text-right">{{ $t('Quantity') }}</div>
            </div>
          </th>
          <th>{{ $t('Status') }}</th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
        <tr v-if="!enrolmentTransfers.data.length">
          <td class="border-t px-6 py-4" colspan="6">{{ $t('No transfers found.') }}</td>
        </tr>
        <tr v-for="transfer in enrolmentTransfers.data" :key="transfer.id">
          <td>
            <span class="font-semibold text-ts-gray-text-bold">{{ transfer.sender.email }}</span>
            <span
              v-if="isPending(transfer)"
              class="ml-4 rounded px-2 py-1"
              :class="statusColor(transfer.transfer_status)"
            >
              {{ $t('NEW') }}
            </span>
          </td>
          <td>{{ transfer.created_at }}</td>
          <td>
            <div
              v-for="(enrolment, key, i) in transfer.enrolments"
              :key="key"
              class="flex justify-between"
              :class="i !== Object.keys(transfer.enrolments).length - 1 ? 'pb-4' : ''"
            >
              <div>{{ enrolment[0].course.name }}</div>
              <div>
                <span class="rounded px-2 py-1" :class="statusColor(transfer.transfer_status)">
                  {{ enrolment.length }}
                </span>
              </div>
            </div>
          </td>
          <td :class="!isPending(transfer) ? 'align-text-top' : ''">
            <div
              class="inline-flex items-center justify-center rounded px-2 py-1"
              :class="statusColor(transfer.transfer_status)"
            >
              <icon
                v-if="transfer.transfer_status === 'expired'"
                class="mr-1 inline h-5 w-5"
                name="exclamation-circle"
              />
              <span>{{ status(transfer) }}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <front-pagination :links="enrolmentTransfers.links" />
  </div>
</template>

<script>
import statusColor from './mixins/statusColor.js'
import FrontPagination from '@/Shared/FrontPagination.vue'
import Icon from '@/Shared/Icon.vue'

export default {
  components: {
    FrontPagination,
    Icon,
  },
  mixins: [statusColor],
  props: {
    enrolmentTransfers: Object,
  },
  methods: {
    isPending(transfer) {
      return transfer.transfer_status === 'pending'
    },
    status(enrolmentTransfer) {
      switch (enrolmentTransfer.transfer_status) {
        case 'accepted':
          return 'Accepted'
        case 'pending':
          return 'Pending'
        case 'expired':
          return 'Expired'
      }
    },
  },
}
</script>
