<template>
  <layout :title="$t('Edit Action')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('organizations.edit', organization)">
        {{ $t('Organizations') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Edit Action') }}
    </h1>
    <deleted-message v-if="action.deleted_at" class="mb-6">
      {{ $t('This action has been deleted.') }}
    </deleted-message>
    <archived-message v-if="action.archived_at" class="mb-6">
      {{ $t('This action has been archived.') }}
    </archived-message>
    <div class="max-w-xl overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pr-6">
            <text-input v-model="form.title" :error="form.errors.title" :label="$t('Title')" />
          </div>
        </div>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pr-6">
            <text-input v-model="form.description" :error="form.errors.description" :label="$t('Description')" />
          </div>
        </div>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pr-6">
            <front-user-search-input
              v-model="form.assignee"
              :error="form.errors.description"
              :label="$t('Assigned To')"
            />
          </div>
        </div>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pr-6">
            <select-input
              v-model="form.priority"
              :error="form.errors.priority"
              :label="$t('Priority')"
              :options="priority_options"
            />
          </div>
        </div>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pr-6">
            <date-input v-model="form.start_at" :error="form.errors.start_at" :label="$t('Starts at')" />
          </div>
        </div>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <date-input v-model="form.due_at" :error="form.errors.due_at" :label="$t('Due at')" />
          </div>
        </div>
        <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
          <div>
            <button
              v-if="action.deleted_at"
              class="text-red-500 hover:underline"
              tabindex="-1"
              type="button"
              @click="restore"
            >
              {{ $t('Restore') }}
            </button>
            <button v-else class="text-red-500 hover:underline" tabindex="-1" type="button" @click="destroy">
              {{ $t('Delete') }}
            </button>
            <button
              v-if="action.archived_at"
              class="ml-4 text-red-500 hover:underline"
              tabindex="-1"
              type="button"
              @click="unarchive"
            >
              {{ $t('Restore Archived') }}
            </button>
            <button v-else class="ml-4 text-red-500 hover:underline" tabindex="-1" type="button" @click="archive">
              {{ $t('Archive') }}
            </button>
          </div>
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Update Action') }}
          </loading-button>
        </div>
      </form>
    </div>
    <div class="mt-8 max-w-xl overflow-x-auto rounded bg-white shadow">
      <table class="w-full">
        <thead>
          <tr>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('User') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Content') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Attachments') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Created') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="comments.data.length === 0">
            <td class="border-t px-6 py-4" colspan="4">{{ $t('No comments found.') }}</td>
          </tr>
          <tr v-for="(comment, index) in comments.data" :key="index" class="hover:bg-gray-100 focus:bg-gray-100">
            <td class="cursor-pointer whitespace-nowrap border-t px-6 py-4">
              {{ comment.user }}
            </td>
            <td class="cursor-pointer whitespace-nowrap border-t px-6 py-4">
              {{ comment.content || '-' }}
            </td>
            <td class="cursor-pointer whitespace-nowrap border-t px-6 py-4">
              <div v-if="comment.attachments.length > 0" class="flex flex-wrap items-center gap-2 rounded bg-white p-2">
                <template v-for="attachment in comment.attachments" :key="attachment.id">
                  <front-media-icon class="h-16 w-16" :media="attachment" @click="openFile(attachment)" />
                </template>
              </div>
            </td>
            <td class="flex cursor-pointer items-center whitespace-nowrap border-t px-6 py-4">
              {{ comment.created_at }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </layout>
</template>

<script>
import ArchivedMessage from '@/Shared/ArchivedMessage.vue'
import DateInput from '@/Shared/DateInput.vue'
import DeletedMessage from '@/Shared/DeletedMessage.vue'
import FrontMediaIcon from '@/Shared/FrontMediaIcon.vue'
import FrontUserSearchInput from '@/Shared/FrontUserSearchInput.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    ArchivedMessage,
    DateInput,
    DeletedMessage,
    FrontMediaIcon,
    FrontUserSearchInput,
    Layout,
    LoadingButton,
    SelectInput,
    TextInput,
  },
  props: {
    organization: Object,
    priority_options: Object,
    action: Object,
    comments: Array,
  },
  data() {
    return {
      form: useForm({
        title: this.action.title,
        description: this.action.description,
        assignee: this.action.assignee,
        start_at: this.action.start_at,
        due_at: this.action.due_at,
        priority: this.action.priority,
      }),
    }
  },
  methods: {
    submit() {
      this.form
        .transform(function (data) {
          return {
            ...data,
            assignee: data.assignee?.id,
          }
        })
        .put(this.route('organizations.actions.update', [this.organization, this.action]))
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this action?'))) {
        this.$inertia.delete(this.route('organizations.actions.destroy', [this.organization, this.action]))
      }
    },
    restore() {
      if (confirm(this.$t('Are you sure you want to restore this action?'))) {
        this.$inertia.put(this.route('organizations.actions.restore', [this.organization, this.action]))
      }
    },
    archive() {
      if (confirm(this.$t('Are you sure you want to archive this action?'))) {
        this.$inertia.put(this.route('organizations.actions.archive', [this.organization, this.action]))
      }
    },
    unarchive() {
      if (confirm(this.$t('Are you sure you want to restore this action?'))) {
        this.$inertia.put(this.route('organizations.actions.unarchive', [this.organization, this.action]))
      }
    },
  },
}
</script>

<style scoped>
.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
}
</style>
