<template>
  <div class="w-full">
    <form @submit.prevent="updateNotifications">
      <div class="-mb-8 -mr-6 flex flex-wrap p-6 lg:p-10">
        <div class="w-full pb-5 pr-6">
          <span class="text-gray-700">{{ $t('Receive general notifications by:') }}</span>
        </div>
        <div class="flex w-full items-center py-4">
          <front-small-toggle-input id="email-toggle" v-model="notificationsForm.can_receive_emails" />
          <label class="ml-3 cursor-pointer font-semibold text-ts-gray-text-bold" for="email-toggle">
            {{ $t('Email') }}
          </label>
        </div>
        <div class="flex w-full items-center pb-8">
          <front-small-toggle-input id="sms-toggle" v-model="notificationsForm.can_receive_sms" />
          <label class="ml-3 cursor-pointer font-semibold text-ts-gray-text-bold" for="sms-toggle">
            {{ $t('SMS Message') }}
          </label>
        </div>
        <template v-if="notificationsPreferences && notificationsPreferences.length > 0">
          <div class="w-full pb-5 pr-6">
            <span class="text-gray-700">{{ $t('Notification preferences:') }}</span>
          </div>
          <div class="flex w-full items-center py-4">
            <table class="w-full">
              <thead>
                <tr>
                  <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Name') }}</th>
                  <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Email') }}</th>
                  <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('SMS') }}</th>
                  <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('App') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(notification, index) in notificationsForm.notification_settings"
                  :key="index"
                  class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
                >
                  <td class="whitespace-nowrap border-t px-6 py-4">
                    {{ notification.label }}
                  </td>
                  <td class="whitespace-nowrap border-t px-6 py-4">
                    <front-small-toggle-input :id="`${index}-email-toggle`" v-model="notification.mail_enabled" />
                  </td>
                  <td class="whitespace-nowrap border-t px-6 py-4">
                    <front-small-toggle-input :id="`${index}-sms-toggle`" v-model="notification.sms_enabled" />
                  </td>
                  <td class="whitespace-nowrap border-t px-6 py-4">
                    <front-small-toggle-input
                      :id="`${index}-database-toggle`"
                      v-model="notification.database_enabled"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </div>
      <div class="flex items-center justify-between border-t border-gray-200 px-6 py-5 lg:px-10 lg:py-8">
        <front-loading-button :loading="notificationsForm.processing" type="submit">
          {{ $t('Update Notifications') }}
        </front-loading-button>
      </div>
    </form>
  </div>
</template>

<script>
import SettingsLayout from './SettingsLayout.vue'
import FrontLayoutVue from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontSmallToggleInput from '@/Shared/FrontSmallToggleInput.vue'
import { useForm } from '@inertiajs/vue3'
import _map from 'lodash/map'
import _pick from 'lodash/pick'

export default {
  components: {
    FrontLoadingButton,
    FrontSmallToggleInput,
  },
  layout: [FrontLayoutVue, SettingsLayout],
  props: {
    notifications: Object,
    notificationsPreferences: Array,
  },
  data() {
    return {
      notificationsForm: useForm(
        {
          can_receive_emails: this.notifications ? this.notifications.can_receive_emails : null,
          can_receive_sms: this.notifications ? this.notifications.can_receive_sms : null,
          notification_settings: _map(this.notificationsPreferences, (preference) => {
            return {
              ..._pick(preference, ['id', 'name', 'label', 'sms_enabled', 'mail_enabled', 'database_enabled']),
            }
          }),
        },
        'notificationsForm'
      ),
    }
  },
  methods: {
    updateNotifications() {
      this.notificationsForm.put(this.route('front.settings.notifications.update'))
    },
  },
}
</script>
