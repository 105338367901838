<template>
  <span
    class="mr-1 rounded-full px-3 py-1 text-xs"
    :style="`background-color:${backgroundColor}; color:${modelValue.color}`"
  >
    {{ modelValue.name }}
  </span>
</template>
<script>
export default {
  props: {
    modelValue: Object,
  },
  computed: {
    backgroundColor() {
      return this.hexToRGB(this.modelValue.color, 0.1)
    },
  },
  methods: {
    hexToRGB(hex, alpha) {
      var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16)

      if (alpha) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
      } else {
        return 'rgb(' + r + ', ' + g + ', ' + b + ')'
      }
    },
  },
}
</script>
