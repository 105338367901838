<template>
  <modal class="rounded text-base" :show="show" style="z-index: 100000" width="auto" @close="close">
    <div class="max-h-screen max-w-lg overflow-auto rounded bg-white pt-6 shadow">
      <div class="flex flex-wrap">
        <success-message v-if="form.wasSuccessful" class="mx-6 mb-1 mt-6 w-full">
          {{ $t('Check-in invite sent successfully.') }}
        </success-message>

        <h1 class="w-full px-6 py-4 text-center text-xl font-bold">{{ $t('Check-In Invite') }}</h1>
        <div class="w-full text-center">{{ student.name }}</div>

        <form class="w-full" @submit.prevent="submit">
          <div class="-mb-8 -mr-6 flex flex-wrap p-12">
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input v-model="form.email" :error="form.errors.email" :label="$t('Email')" />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input v-model="form.phone" :error="form.errors.phone" :label="$t('Phone')" />
            </div>
          </div>
          <div class="flex w-full items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
            <loading-button class="btn-blue" :loading="form.processing" type="submit">{{ $t('Send') }}</loading-button>
          </div>
        </form>
      </div>
    </div>
  </modal>
</template>

<script>
import LoadingButton from '@/Shared/LoadingButton.vue'
import Modal from '@/Shared/Modal.vue'
import SuccessMessage from '@/Shared/SuccessMessage.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    LoadingButton,
    Modal,
    SuccessMessage,
    TextInput,
  },
  props: {
    show: Boolean,
    student: Object,
  },
  data() {
    return {
      form: useForm({
        email: this.student.user?.email,
        phone: this.student.user?.phone,
      }),
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    submit() {
      this.form.post(this.route('instructor.checkin.invite', this.student), {
        onSuccess: () => {
          this.form.reset()
        },
      })
    },
  },
}
</script>
