<template>
  <front-layout :title="user.display_name">
    <div class="mb-8 flex justify-between">
      <breadcrumb :name="$t(':name\'s Profile', { name: user.display_name })" />
      <front-dropdown class="relative" placement="bottom-end">
        <div class="relative z-10 flex cursor-pointer select-none items-center">
          <span class="mr-3">{{ $t('Actions') }}</span>
          <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
              clip-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              fill-rule="evenodd"
            />
          </svg>
        </div>
        <template #dropdown>
          <div class="relative">
            <div class="absolute right-0 z-20 mt-2 w-48 rounded-md bg-white py-2 shadow-xl">
              <front-dropdown-option @click="assignTraining">{{ $t('Assign Training') }}</front-dropdown-option>
              <front-dropdown-option v-if="organization.training_records_enabled" @click="assignTrainingRecord">
                {{ $t('Record Certifications') }}
              </front-dropdown-option>
              <front-dropdown-option v-if="!user.archived_at" @click="archive">
                {{ $t('Delete User') }}
              </front-dropdown-option>
              <front-dropdown-option v-else @click="unarchive">{{ $t('Restore User') }}</front-dropdown-option>
              <front-dropdown-option v-if="user.email" @click="sendPasswordReset">
                {{ $t('Send Password Reset') }}
              </front-dropdown-option>
            </div>
          </div>
        </template>
      </front-dropdown>
    </div>
    <div class="flex flex-wrap">
      <div class="flex w-full flex-col items-start xl:flex-row">
        <profile-card :organization="organization" :user="user" />
        <div class="flex w-full flex-col xl:w-3/4 xl:pl-16">
          <div class="flex-1">
            <h2 class="text-md sr-only">{{ $t('Courses') }}</h2>
            <div class="mb-8 rounded-md bg-white shadow-md">
              <table class="front-table">
                <thead>
                  <tr>
                    <th>{{ $t('Course') }}</th>
                    <th>{{ $t('Status') }}</th>
                    <th />
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="user.enrolments.data.length === 0">
                    <td colspan="3">{{ $t('No courses found.') }}</td>
                  </tr>
                  <tr
                    v-for="enrolment in user.enrolments.data"
                    :key="enrolment.id"
                    class="hover:bg-gray-100 focus:bg-gray-100"
                  >
                    <td class="highlight">
                      <InertiaLink class="link" :href="route('front.manager.enrolment.show', enrolment.id)">
                        <front-truncate-text class="mr-2 max-w-md" :text="enrolment.name" />
                      </InertiaLink>
                    </td>
                    <td><front-course-status :status="enrolment.status" /></td>
                    <td>
                      <button
                        v-if="enrolment.can.unenrol"
                        class="bg-transparent px-6 py-3 text-ts-red-500"
                        @click="showConfirmUnenrolModal(enrolment)"
                      >
                        {{ $t('Unenrol') }}
                      </button>
                    </td>
                    <td>
                      <template v-for="enrolmentContent in enrolment.enrolmentContents" :key="enrolmentContent.id">
                        <InertiaLink
                          v-if="
                            enrolment.can.changeClass && enrolmentContent.content.type === 'instructor-led-classroom'
                          "
                          class="underline"
                          :href="route('front.manager.contents.classes', [enrolment.id, enrolmentContent.id])"
                        >
                          {{ enrolmentContent.content.dates ? $t('Edit Class') : $t('Select Class') }}
                        </InertiaLink>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <front-pagination :links="user.enrolments.links" />
          </div>

          <div class="mt-5 flex-1">
            <h2 class="text-md sr-only">{{ $t('Certificates') }}</h2>
            <div class="overflow-x-auto rounded-md bg-white shadow-md">
              <table class="front-table">
                <thead>
                  <tr>
                    <td v-if="isSelectingCertificates">
                      <front-row-select-checkbox
                        id="selectAll"
                        v-model="selectAll"
                        @update:model-value="selectAllUpdated"
                      />
                    </td>
                    <th>{{ $t('Certification') }}</th>
                    <th class="hidden lg:table-cell" />
                    <th class="hidden lg:table-cell">{{ $t('Issued At') }}</th>
                    <th class="hidden lg:table-cell">{{ $t('Expires At') }}</th>
                    <th class="w-4">
                      <front-outline-button v-if="!isSelectingCertificates" @click="isSelectingCertificates = true">
                        {{ $t('Sign') }}
                      </front-outline-button>
                      <front-outline-button v-else @click="cancelCertificateSelection">
                        {{ $t('Cancel') }}
                      </front-outline-button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="user.certificates.data.length === 0">
                    <td class="border-t px-6 py-4" colspan="7">{{ $t('No certificates found.') }}</td>
                  </tr>
                  <tr
                    v-for="certificate in user.certificates.data"
                    :key="certificate.id"
                    class="hover:bg-gray-100 focus:bg-gray-100"
                  >
                    <td v-if="isSelectingCertificates">
                      <front-row-select-checkbox
                        v-if="!certificate.is_training_record"
                        :id="'checkbox-' + certificate.id"
                        v-model="form.certificates"
                        :disabled="certificate.manager_signature"
                        :value="certificate.id"
                      />
                    </td>
                    <td class="highlight flex items-center">
                      <front-truncate-text class="mr-2" :text="certificate.name" />
                      <span
                        v-if="certificate.is_training_record"
                        class="cursor-default rounded bg-ts-blue-600 bg-opacity-8 p-1 text-xs font-semibold leading-5 text-ts-blue-600"
                      >
                        {{ $t('Training Record') }}
                      </span>
                      <span
                        v-else
                        class="cursor-default rounded bg-ts-blue-600 bg-opacity-8 p-1 text-xs font-semibold leading-5 text-ts-blue-600"
                      >
                        {{ $t('Course') }}
                      </span>
                    </td>
                    <td class="hidden lg:table-cell">
                      <span
                        v-if="certificate.manager_signature"
                        class="mx-1 cursor-default rounded bg-ts-blue-600 bg-opacity-8 p-1 text-xs font-semibold leading-5 text-ts-blue-600"
                      >
                        {{ $t('Signed') }}
                      </span>
                    </td>
                    <td class="hidden lg:table-cell">
                      <front-date
                        :correct-to-local="!certificate.is_training_record"
                        :timestamp="certificate.issued_at"
                      />
                    </td>
                    <td class="hidden lg:table-cell">
                      <front-certificate-status :status="certificate.status">
                        <front-date
                          :correct-to-local="!certificate.is_training_record"
                          :timestamp="certificate.expires_at"
                        />
                      </front-certificate-status>
                    </td>
                    <td>
                      <template v-if="!certificate.is_training_record">
                        <front-certificate-download
                          v-if="certificate.formats"
                          :certificate="certificate"
                          class="mx-auto block"
                          route-name="manager"
                        >
                          <icon class="h-6 w-6 text-sm text-ts-blue-500" name="download" :title="$t('download')" />
                        </front-certificate-download>
                      </template>
                      <div v-else class="flex items-center justify-center">
                        <a
                          v-if="certificate.can.downloadProofOfTraining"
                          class="mr-2"
                          :href="route('front.manager.training-records.download', certificate)"
                        >
                          <icon class="h-6 w-6 text-sm text-ts-blue-500" name="download" :title="$t('download')" />
                        </a>
                        <button class="bg-transparent text-red-500" @click="showDeleteTrainingRecordModal(certificate)">
                          <span class="text-ts-blue-500">
                            <icon class="inline h-5 w-5 rounded-full fill-ts-blue-500" name="close" />
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="flex justify-between">
              <front-solid-button
                v-if="isSelectingCertificates"
                class="mt-4"
                :disabled="form.certificates.length == 0"
                @click="showSignaturePadModal = true"
              >
                {{ $t('Sign Now') }}
              </front-solid-button>
              <div v-else class="mt-4" />
              <front-pagination :links="user.certificates.links" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <front-confirm-modal
      v-if="showConfirmUnenrol"
      :show="showConfirmUnenrol"
      @cancel="hideConfirmUnenrolModal"
      @confirm="onConfimedUnenrolment"
    >
      <template #title>{{ $t('Confirm unenrol') }}</template>
      <template #content>
        <div class="flex flex-col space-y-4">
          <div>
            {{ $t("You're about to unenrol") }}
            <b>{{ user.name }}</b>
            {{ $t('from the course') }}
            <b>{{ selectedEnrolment.name }}</b>
            .
          </div>
          <div>{{ $t('Are you sure you want to do that?') }}</div>
        </div>
      </template>
    </front-confirm-modal>
    <front-confirm-modal
      v-if="isDeleteTrainingRecordModal"
      :show="isDeleteTrainingRecordModal"
      @cancel="hideDeleteTrainingRecordModal"
      @confirm="onConfirmedDeleteTrainingRecord"
    >
      <template #title>{{ $t('Confirm Delete') }}</template>
      <template #content>
        <div class="flex flex-col space-y-4">
          <div>{{ $t("You're about to delete the training record.") }}</div>
          <div>{{ $t('Are you sure you want to do that?') }}</div>
        </div>
      </template>
    </front-confirm-modal>
    <front-signature-modal
      v-if="showSignaturePadModal"
      :certificates="form.certificates"
      role="manager"
      :show="showSignaturePadModal"
      @close="showSignaturePadModal = false"
      @signed="submitSignature"
    />
  </front-layout>
</template>

<script>
import ProfileCard from './ProfileCard.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontCertificateDownload from '@/Shared/FrontCertificateDownload.vue'
import FrontCertificateStatus from '@/Shared/FrontCertificateStatus.vue'
import FrontConfirmModal from '@/Shared/FrontConfirmModal.vue'
import FrontCourseStatus from '@/Shared/FrontCourseStatus.vue'
import FrontDate from '@/Shared/FrontDate.vue'
import FrontDropdown from '@/Shared/FrontDropdown.vue'
import FrontDropdownOption from '@/Shared/FrontDropdownOption.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontOutlineButton from '@/Shared/FrontOutlineButton.vue'
import FrontPagination from '@/Shared/FrontPagination.vue'
import FrontRowSelectCheckbox from '@/Shared/FrontRowSelectCheckbox.vue'
import FrontSignatureModal from '@/Shared/FrontSignatureModal.vue'
import FrontSolidButton from '@/Shared/FrontSolidButton.vue'
import FrontTruncateText from '@/Shared/FrontTruncateText.vue'
import Icon from '@/Shared/Icon.vue'
import { query } from '@/Utils/Helpers'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    ProfileCard,
    Breadcrumb,
    FrontCertificateDownload,
    FrontCertificateStatus,
    FrontConfirmModal,
    FrontCourseStatus,
    FrontDate,
    FrontDropdown,
    FrontDropdownOption,
    FrontLayout,
    FrontOutlineButton,
    FrontPagination,
    FrontRowSelectCheckbox,
    FrontSignatureModal,
    FrontSolidButton,
    FrontTruncateText,
    Icon,
  },
  props: {
    organization: Object,
    user: Object,
  },
  data() {
    return {
      selectAll: false,
      selectedEnrolment: null,
      selectedTrainingRecord: null,
      showConfirmUnenrol: false,
      isDeleteTrainingRecordModal: false,
      isSelectingCertificates: false,
      showSignaturePadModal: false,
      form: useForm({
        certificates: [],
        image: null,
      }),
      passwordResetForm: useForm({
        email: this.user.email,
      }),
    }
  },
  watch: {
    'form.certificates': {
      handler() {
        if (this.user.certificates.data.every((certificate) => this.form.certificates.includes(certificate.id))) {
          return (this.selectAll = true)
        }
        this.selectAll = false
      },
      deep: true,
    },
  },
  methods: {
    query,
    selectAllUpdated() {
      if (this.selectAll) {
        this.selectAllCertificates()
      } else {
        this.unSelectAllCertificates()
      }
    },
    selectAllCertificates() {
      this.form.certificates = this.user.certificates.data
        .filter((certificate) => !certificate.is_training_record && !certificate.manager_signature)
        .map(function (certificates) {
          return certificates.id
        })
    },
    unSelectAllCertificates() {
      this.form.certificates = []
    },
    submitSignature(image) {
      this.form.image = image
      this.form.post(this.route('front.manager.certificates.signature.store'), {
        onSuccess: () => {
          this.form.reset()
          this.isSelectingCertificates = false
        },
      })
    },
    showConfirmUnenrolModal(enrolment) {
      this.showConfirmUnenrol = true
      this.selectedEnrolment = enrolment
    },
    hideConfirmUnenrolModal() {
      this.showConfirmUnenrol = false
    },
    showDeleteTrainingRecordModal(trainingRecord) {
      this.isDeleteTrainingRecordModal = true
      this.selectedTrainingRecord = trainingRecord
    },
    hideDeleteTrainingRecordModal() {
      this.isDeleteTrainingRecordModal = false
      this.selectedTrainingRecord = null
    },
    onConfirmedDeleteTrainingRecord() {
      this.$inertia.delete(this.route('front.manager.training-records.delete', this.selectedTrainingRecord.id))
      this.hideDeleteTrainingRecordModal()
    },
    cancelCertificateSelection() {
      this.isSelectingCertificates = false
      this.form.certificates = []
    },
    onConfimedUnenrolment() {
      this.$inertia.delete(this.route('front.manager.enrol.user.destroy', this.selectedEnrolment.id))
      this.hideConfirmUnenrolModal()
    },
    assignTraining() {
      this.$inertia.visit(this.route('front.manager.enrol.user.step1', this.user.id))
    },
    assignTrainingRecord() {
      this.$inertia.visit(this.route('front.manager.training-records.create', this.user.id))
    },
    sendPasswordReset() {
      this.passwordResetForm.post(this.route('front.password.email'), {
        onSuccess: () => {
          this.$page.props.flash.success = this.$t('Password reset notification sent.')
        },
      })
    },
    archive() {
      this.$inertia.put(this.route('front.manager.users.archive', this.user.id))
    },
    unarchive() {
      this.$inertia.put(this.route('front.manager.users.unarchive', this.user.id))
    },
  },
}
</script>
