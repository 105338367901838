<template>
  <front-layout :title="$t('Actions')">
    <div class="flex flex-col xl:flex-row xl:justify-between">
      <div class="sm:no-flex mb-8 flex w-full items-center justify-between">
        <breadcrumb name="Actions" />
        <front-solid-button class="sm:hidden" @click="openCreateModal">
          {{ $t('New Action') }}
        </front-solid-button>
      </div>
      <div class="mb-6 flex w-full items-center justify-between xl:justify-end">
        <search-filter v-model="form.search" class="mr-4 w-full max-w-md" :filter-show="true" @reset="reset">
          <label class="block text-gray-800">{{ $t('Priority:') }}</label>
          <select v-model="form.priority" class="form-select mt-1 w-full">
            <option disabled :value="null">{{ $t('Filter Priority') }}</option>
            <option :value="null">{{ $t('All') }}</option>
            <option v-for="(priority, key) in priorityOptions" :key="key" :value="key">
              {{ priority }}
            </option>
          </select>
          <label class="mt-4 block text-gray-800">{{ $t('Is Complete:') }}</label>
          <select v-model="form.is_completed" aria-placeholder="Is Complete" class="form-select mt-1 w-full">
            <option :value="null">{{ $t('All') }}</option>
            <option :value="true">{{ $t('Yes') }}</option>
            <option :value="false">{{ $t('No') }}</option>
          </select>
          <label class="mt-4 block text-gray-800">{{ $t('Starts Between:') }}</label>
          <date-input v-model="form.start_date" class="mt-1 w-full" />
          <date-input v-model="form.end_date" class="mt-1 w-full" />
          <label class="mt-4 block text-gray-800">{{ $t('Due Between:') }}</label>
          <date-input v-model="form.due_start_date" class="mt-1 w-full" />
          <date-input v-model="form.due_end_date" class="mt-1 w-full" />
        </search-filter>
        <front-solid-button class="hidden sm:inline" @click="openCreateModal">
          {{ $t('New Action') }}
        </front-solid-button>
      </div>
    </div>

    <div class="w-full">
      <div v-if="actions.data.length === 0" class="border pb-4 pl-4 pt-4">
        <div class="" colspan="5">{{ $t("You don't have any actions.") }}</div>
      </div>
      <div
        v-for="action in actions.data"
        :key="action.id"
        class="last-border-b-0 cursor-pointer border-x border-b bg-white px-6 pb-4 pt-6 first:rounded-t-lg first:border-t last:rounded-b-lg"
        @click="onActionClick(action)"
      >
        <div class="text-bold mb-2 w-full font-semibold text-darkGray-1000">
          {{ action.title }}
        </div>
        <div class="flex w-full items-center justify-between text-xs">
          <div class="flex items-center gap-2">
            <div class="">
              <front-priority-status :value="action.priority" />
            </div>
            <div>
              <front-action-status :value="action.status" />
            </div>
            <div
              v-if="action.due_at"
              class="px-1 py-px"
              :class="{ 'rounded bg-red-800 bg-opacity-25 text-red-800': action.is_past_due }"
            >
              <span>{{ $t('Due') + ' ' + action.due_human_readable_format }}</span>
            </div>
          </div>
          <div v-if="action.assignee" class="px-1 py-px">
            <span>Assigned To:{{ action.assignee.display_name }}</span>
          </div>
          <div class="flex flex-1 justify-end">
            <div>{{ $t('Last updated :time_diff', { time_diff: action.last_updated_at }) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between">
      <front-pagination :links="actions.links" />
    </div>
    <create-action-modal
      :priority-options="priorityOptions"
      :show="showCreateModal"
      :user="user"
      @close="showCreateModal = false"
    />
  </front-layout>
</template>

<script>
import CreateActionModal from './CreateActionModal.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import DateInput from '@/Shared/DateInput.vue'
import FrontActionStatus from '@/Shared/FrontActionStatus.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontPagination from '@/Shared/FrontPagination.vue'
import FrontPriorityStatus from '@/Shared/FrontPriorityStatus.vue'
import FrontSolidButton from '@/Shared/FrontSolidButton.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    FrontPriorityStatus,
    CreateActionModal,
    Breadcrumb,
    DateInput,
    FrontActionStatus,
    FrontLayout,
    FrontPagination,
    FrontSolidButton,
    SearchFilter,
  },
  props: {
    actions: Object,
    filters: Object,
    priorityOptions: Object,
    user: Object,
  },
  data() {
    return {
      showCreateModal: false,
      form: {
        search: this.filters.search,
        priority: this.filters.priority,
        is_completed: this.filters.is_completed,
        start_date: this.filters.start_date,
        end_date: this.filters.end_date,
        due_start_date: this.filters.due_start_date,
        due_end_date: this.filters.due_end_date,
      },
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)

        this.$inertia.get(
          this.route('front.manager.actions'),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveScroll: true,
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
  },
  methods: {
    openCreateModal() {
      this.showCreateModal = true
    },
    onActionClick(action) {
      if (action.can.edit) {
        this.$inertia.get(this.route('front.actions.edit', action.id))
      } else {
        this.$inertia.get(this.route('front.actions.show', action.id))
      }
    },
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
