<template>
  <styled-modal class="rounded text-base" max-width="md" :show="show" @close="close">
    <template #title>
      <h3 class="mt-4 text-center text-xl font-semibold leading-snug">{{ $t('Attach Media') }}</h3>
    </template>
    <template #content>
      <input
        ref="file"
        accept=".png,.jpeg,.jpg,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        class="hidden"
        multiple
        type="file"
        @change="onFileChange"
      />
      <template v-if="form.files.length === 0">
        <div v-if="form.errors.files" class="error">{{ form.errors.files }}</div>
        <div
          class="link flex h-48 w-full cursor-pointer items-center justify-center gap-2 rounded-lg bg-gray-100 p-8 hover:bg-gray-200"
          @click="uploadFile"
        >
          <cloud-arrow-up-icon class="text-qualify-red h-8 w-8" />
          <span>{{ $t('Upload Media') }}</span>
        </div>
      </template>
      <template v-else>
        <button class="link mb-3" @click="uploadFile">
          <span>{{ $t('Upload more files') }}</span>
        </button>
        <div class="flex w-full flex-wrap items-center justify-start gap-2 bg-gray-100 p-2">
          <FrontLocalFileThumb
            v-for="(file, key) in form.files"
            :key="`${file.name}_${key}`"
            :error="form.errors[`files.${key}`]"
            :file="file"
            @delete="removeFile(key)"
          />
        </div>
      </template>
    </template>
    <template #footer>
      <div class="flex justify-end space-x-4">
        <button @click="close">{{ $t('Close') }}</button>
        <front-loading-button :disabled="form.files.length === 0" :loading="form.processing" @click="submit">
          {{ $t('Upload') }}
        </front-loading-button>
      </div>
    </template>
  </styled-modal>
</template>

<script>
import FrontLoadingButton from './FrontLoadingButton.vue'
import FrontLocalFileThumb from './FrontLocalFileThumb.vue'
import StyledModal from './StyledModal.vue'
import { CloudArrowUpIcon } from '@heroicons/vue/24/solid'
import { useForm } from '@inertiajs/vue3'

export default {
  components: { CloudArrowUpIcon, FrontLocalFileThumb, FrontLoadingButton, StyledModal },
  props: {
    action: {
      type: Object,
      require: true,
    },
    show: Boolean,
  },
  data() {
    return {
      form: useForm({
        files: [],
      }),
    }
  },
  methods: {
    close() {
      this.form.reset()
      this.form.clearErrors()
      this.$emit('close')
    },
    onFileChange(e) {
      var selectedFiles = e.target.files
      this.form.files.push(...selectedFiles)
    },
    removeFile(key) {
      this.form.files.splice(key, 1)
      this.form.clearErrors()
    },
    uploadFile() {
      this.$refs.file.click()
    },
    submit() {
      if (this.form.files) {
        this.form.post(this.route('front.comments.store', this.action), {
          preserveScroll: true,
          preserveState: (page) => Object.keys(page.props.errors).length,
          onSuccess: () => {
            this.close()
          },
        })
      }
    },
  },
}
</script>
