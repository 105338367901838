<template>
  <layout :title="$t('Plastic Card Orders')">
    <h1 class="mb-8 text-xl font-bold">{{ $t('Plastic Card Orders') }}</h1>
    <div class="mb-6 flex items-center justify-between">
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
        <label class="block text-gray-800">{{ $t('Processed:') }}</label>
        <select v-model="form.processed" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="false">{{ $t('No (:locale)', { locale: unfulfilledCount.toLocaleString('en-US') }) }}</option>
          <option value="true">{{ $t('Yes') }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Course:') }}</label>
        <select v-model="form.course" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="course in courses" :key="course.id" :value="course.id">{{ course.name }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Template Type:') }}</label>
        <select v-model="form.type" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="(label, type) in types" :key="type" :value="label">{{ label }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Deleted:') }}</label>
        <select v-model="form.deleted" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="with">{{ $t('With Deleted') }}</option>
          <option value="only">{{ $t('Only Deleted') }}</option>
        </select>
      </search-filter>
      <button v-if="selectedPlasticCardOrders.length > 0" class="btn-blue" @click="openProcessPlasticCardOrdersModal">
        <span>{{ $t('Fulfill') }}</span>
        <span class="hidden md:inline">{{ $t('Orders') }}</span>
      </button>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow">
      <SimpleTable>
        <template #head>
          <TableHead>
            <div v-if="plasticCardOrders.data.length > 0" class="backoffice-round round mb-2">
              <input
                id="checkbox-select-all-orders"
                :checked="selectAllChecked"
                type="checkbox"
                @change="selectAllPlasticCardOrders"
              />
              <label for="checkbox-select-all-orders" />
            </div>
          </TableHead>
          <TableHead>{{ $t('Name') }}</TableHead>
          <TableHead>{{ $t('Course') }}</TableHead>
          <TableHead>{{ $t('Employer') }}</TableHead>
          <TableHead>{{ $t('Processed') }}</TableHead>
          <TableHead>{{ $t('Created') }}</TableHead>
          <TableHead />
        </template>
        <TableRow v-if="plasticCardOrders.data.length === 0">
          <TableData colspan="7">{{ $t('No plastic card orders found.') }}</TableData>
        </TableRow>
        <TableRow v-for="plasticCardOrder in plasticCardOrders.data" :key="plasticCardOrder.id">
          <TableData>
            <div class="backoffice-round round -mt-2 mr-6">
              <input
                :id="'checkbox-' + plasticCardOrder.id"
                v-model="selectedPlasticCardOrders"
                type="checkbox"
                :value="plasticCardOrder"
              />
              <label :for="'checkbox-' + plasticCardOrder.id" />
            </div>
          </TableData>
          <TableData class="cursor-pointer" @click="goToEditPage(plasticCardOrder)">
            <div class="flex items-center">
              {{ plasticCardOrder.first_name }} {{ plasticCardOrder.last_name }}
              <deleted-badge v-if="plasticCardOrder.deleted_at" />
            </div>
          </TableData>
          <TableData class="cursor-pointer" @click="goToEditPage(plasticCardOrder)">
            <div class="flex items-center">
              {{ plasticCardOrder.course_name }}
            </div>
          </TableData>
          <TableData class="cursor-pointer" @click="goToEditPage(plasticCardOrder)">
            <div>{{ plasticCardOrder.employer_name }}</div>
            <div class="text-sm text-gray-600">
              {{ plasticCardOrder.employer_address }}
            </div>
          </TableData>
          <TableData class="cursor-pointer" @click="goToEditPage(plasticCardOrder)">
            <div class="flex items-center">
              <icon
                v-if="!!plasticCardOrder.fulfilled_at"
                class="mr-2 h-5 w-5 flex-shrink-0 fill-green-500"
                name="checkmark"
              />
              <icon v-else class="mr-2 inline h-5 w-5 fill-red-500" name="close" />
              {{ !!plasticCardOrder.fulfilled_at ? plasticCardOrder.fulfilled_at : 'No' }}
            </div>
          </TableData>
          <TableData class="cursor-pointer" @click="goToEditPage(plasticCardOrder)">
            <div class="flex items-center">
              {{ plasticCardOrder.created_at }}
            </div>
          </TableData>
          <TableData class="w-min cursor-pointer align-middle" @click="goToEditPage(plasticCardOrder)">
            <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
          </TableData>
        </TableRow>
      </SimpleTable>
    </div>
    <pagination :links="plasticCardOrders.links" />
    <process-plastic-cards-modal
      :locales="locales"
      :orders="selectedPlasticCardOrders"
      :show="showProcessPlasticCardOrdersModal"
      @close="closeProcessPlasticCardOrdersModal"
    />
  </layout>
</template>

<script>
import ProcessPlasticCardsModal from './ProcessPlasticCardsModal.vue'
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    ProcessPlasticCardsModal,
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
    DeletedBadge,
    Icon,
    Layout,
    Pagination,
    SearchFilter,
  },
  props: {
    filters: Object,
    courses: Array,
    types: Object,
    locales: Object,
    plasticCardOrders: Object,
    unfulfilledCount: Number,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        processed: this.filters.processed,
        course: this.filters.course,
        type: this.filters.type,
        deleted: this.filters.deleted,
      },
      selectedPlasticCardOrders: [],
      showProcessPlasticCardOrdersModal: false,
      selectAllChecked: false,
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(
          this.route('plastic-card-orders'),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
    selectedPlasticCardOrders() {
      this.selectAllChecked = this.selectedPlasticCardOrders.length === this.plasticCardOrders.data.length
    },
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
    openProcessPlasticCardOrdersModal() {
      this.showProcessPlasticCardOrdersModal = true
    },
    closeProcessPlasticCardOrdersModal() {
      this.selectedPlasticCardOrders = []
      this.showProcessPlasticCardOrdersModal = false
    },
    selectAllPlasticCardOrders() {
      if (this.selectedPlasticCardOrders.length === this.plasticCardOrders.data.length) {
        this.selectedPlasticCardOrders = []
        this.selectAllChecked = false
      } else {
        this.selectedPlasticCardOrders = this.plasticCardOrders.data
        this.selectAllChecked = true
      }
    },
    goToEditPage(plasticCardOrder) {
      this.$inertia.visit(this.route('plastic-card-orders.edit', plasticCardOrder.id))
    },
  },
}
</script>
