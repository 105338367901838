<template>
  <layout :title="$t('Resell')">
    <h1 class="mb-8 text-xl font-bold">{{ $t('Resellers') }}</h1>
    <div v-if="tenants.length" class="overflow-x-auto rounded bg-white shadow">
      <SimpleTable>
        <template #head>
          <TableHead>{{ $t('Name') }}</TableHead>
          <TableHead colspan="2">{{ $t('Reselling') }}</TableHead>
        </template>
        <TableRow
          v-for="tenant in tenants"
          :key="tenant.id"
          :clickable="true"
          @click="$inertia.visit(route('resellers.show', tenant.id))"
        >
          <TableData>
            {{ tenant.name }}
          </TableData>
          <TableData>
            <div class="flex items-center">
              <span>
                {{ tenant.courses_count === 1 ? '1 course' : `${tenant.courses_count} courses` }}
              </span>
              <span v-if="tenant.courses_pending" class="ml-2 rounded-full bg-orange-500 px-3 py-1 text-xs text-white">
                {{ $t('Pending Request') }}
              </span>
            </div>
          </TableData>
          <TableData class="w-min align-middle">
            <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
          </TableData>
        </TableRow>
      </SimpleTable>
    </div>
    <div v-else class="mt-4 flex max-w-3xl flex-col items-center justify-center rounded bg-white py-48 shadow">
      <div class="text-center text-lg text-gray-600">{{ $t('No tenants are reselling your courses.') }}</div>
      <InertiaLink class="btn-blue mt-8" :href="route('courses')">{{ $t('Update Course Settings') }}</InertiaLink>
    </div>
  </layout>
</template>

<script>
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'

export default {
  components: {
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
    Icon,
    Layout,
  },
  props: {
    tenants: Array,
  },
}
</script>
