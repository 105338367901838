<template>
  <guest-layout :tenant="tenant" :title="$t('Form signed!')">
    <div class="container m-auto flex flex-col items-center">
      <div class="grid w-full grid-cols-1 gap-10 lg:pt-5 xl:pt-10">
        <div class="mx-auto rounded-full bg-ts-blue-50 p-3">
          <div class="rounded-full bg-ts-blue-600 p-4 text-white">
            <icon class="h-10 w-10 fill-current" name="checkmark"></icon>
          </div>
        </div>
        <h1 class="text-center font-heading text-3xl font-semibold text-darkGray-1000">
          {{ $t('Signature added successfully!!') }}
        </h1>
      </div>
    </div>
  </guest-layout>
</template>

<script>
import GuestLayout from '@/Shared/GuestLayout.vue'
import Icon from '@/Shared/Icon.vue'

export default {
  components: {
    GuestLayout,
    Icon,
  },
  props: {
    tenant: Object,
  },
}
</script>
