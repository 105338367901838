export default {
  methods: {
    statusColor(status) {
      status = status.toLowerCase()

      const colors = {
        accepted: 'bg-gray-300 text-gray-900',
        expired: 'bg-qualify-red-500 bg-opacity-8 text-qualify-red-500',
        pending: 'bg-ts-orange-100 text-ts-orange-900',
      }

      return colors[status]
    },
  },
}
