<template>
  <div class="flex items-center">
    <div class="mr-3 text-sm">
      <front-checkbox-input v-model="optionsData.is_required" :label="$t('Required')" />
    </div>
  </div>
</template>
<script>
import FrontCheckboxInput from '@/Shared/FrontCheckboxInput.vue'

export default {
  components: {
    FrontCheckboxInput,
  },
  props: {
    options: Object,
  },
  emits: ['update:optionsData'],
  data() {
    return {
      optionsData: {
        is_required: this.options?.is_required ?? false,
      },
    }
  },
  watch: {
    optionsData: {
      handler: function () {
        this.$emit('update:optionsData', this.optionsData)
      },
      deep: true,
    },
  },
}
</script>
