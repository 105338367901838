<template>
  <layout :title="$t('Form Templates')">
    <div class="mb-8">
      <breadcrumb :name="$t('Form Templates')" />
    </div>
    <div class="mb-6 flex items-center justify-between">
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
        <label class="block text-gray-800">{{ $t('Created:') }}</label>
        <date-input v-model="form.start_date" class="mt-1 w-full" />
        <date-input v-model="form.end_date" class="mt-1 w-full" />
        <label class="mt-4 block text-gray-800">{{ $t('Published:') }}</label>
        <select v-model="form.globally_published" class="form-select mt-1 w-full">
          <option :value="null" />
          <option :value="true">{{ $t('Yes') }}</option>
          <option :value="false">{{ $t('No') }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Deleted:') }}</label>
        <select v-model="form.deleted" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="with">{{ $t('With Deleted') }}</option>
          <option value="only">{{ $t('Only Deleted') }}</option>
        </select>
      </search-filter>
      <InertiaLink class="btn-blue" :href="route('form-templates.create')">
        <span>{{ $t('Create') }}</span>
        <span class="hidden md:inline">{{ $t('Template') }}</span>
      </InertiaLink>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow">
      <SimpleTable>
        <template #head>
          <TableHead>{{ $t('Template') }}</TableHead>
          <TableHead>{{ $t('Published') }}</TableHead>
          <TableHead>{{ $t('Created') }}</TableHead>
          <TableHead>{{ $t('Deleted') }}</TableHead>
        </template>
        <TableRow v-if="templates.data.length === 0">
          <TableData class="border-t px-6 py-4" colspan="12">{{ $t('No templates found.') }}</TableData>
        </TableRow>
        <template v-for="(template, key) in templates.data" :key="key">
          <TableRow
            class="cursor-pointer align-top hover:bg-gray-100 focus:bg-gray-100"
            :clickable="true"
            @click="$inertia.visit(route('form-templates.edit', { formTemplate: template.series_id }))"
          >
            <TableData>{{ template.title }}</TableData>
            <TableData>{{ template.globally_published ? 'Yes' : 'No' }}</TableData>
            <TableData>{{ template.created_at }}</TableData>
            <TableData>{{ template.deleted_at || '-' }}</TableData>
          </TableRow>
        </template>
      </SimpleTable>
    </div>
    <pagination :links="templates.links" :preserve-scroll="true" />
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import DateInput from '@/Shared/DateInput.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
    Breadcrumb,
    DateInput,
    Layout,
    Pagination,
    SearchFilter,
  },
  props: {
    filters: Object,
    templates: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        start_date: this.filters.start_date,
        end_date: this.filters.end_date,
        globally_published: this.filters.globally_published,
        deleted: this.filters.deleted,
      },
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)

        this.$inertia.get(this.route('form-templates'), Object.keys(query).length ? query : { remember: 'forget' }, {
          preserveState: true,
          replace: true,
        })
      }, 300),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
