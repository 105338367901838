<template>
  <layout :title="$t('Transactions')">
    <div class="mb-8">
      <breadcrumb :name="$t('Transactions')" />
    </div>
    <div class="mb-6 flex items-center justify-between">
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
        <label class="mt-4 block text-gray-800">{{ $t('Between:') }}</label>
        <date-input v-model="form.start_date" class="mt-1 w-full" />
        <date-input v-model="form.end_date" class="mt-1 w-full" />
        <label class="mt-4 block text-gray-800">{{ $t('Source:') }}</label>
        <select v-model="form.source" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="(source, key) in sources" :key="key" :value="source">{{ source }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Currency:') }}</label>
        <select v-model="form.currency" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="(currency, key) in currencies" :key="key" :value="currency">{{ currency }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Payment Methods:') }}</label>
        <select v-model="form.payment_method" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="(payment_method, key) in payment_methods" :key="key" :value="payment_method">
            {{ payment_method }}
          </option>
        </select>
      </search-filter>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow">
      <SimpleTable>
        <template #head>
          <TableHead />
          <TableHead>{{ $t('User') }}</TableHead>
          <TableHead>{{ $t('Source') }}</TableHead>
          <TableHead>{{ $t('Billing Address') }}</TableHead>
          <TableHead>{{ $t('Shipping Address') }}</TableHead>
          <TableHead>{{ $t('Status') }}</TableHead>
          <TableHead>{{ $t('Order No.') }}</TableHead>
          <TableHead>{{ $t('Tax') }}</TableHead>
          <TableHead>{{ $t('Total') }}</TableHead>
          <TableHead>{{ $t('Created') }}</TableHead>
          <TableHead>{{ $t('Currency') }}</TableHead>
          <TableHead>{{ $t('Payment') }}</TableHead>
        </template>
        <TableRow v-if="transactions.data.length === 0">
          <TableData class="border-t px-6 py-4" colspan="12">{{ $t('No transactions found.') }}</TableData>
        </TableRow>
        <template v-for="(transaction, key) in transactions.data" :key="key">
          <TableRow
            class="cursor-pointer align-top hover:bg-gray-100 focus:bg-gray-100"
            :class="{ 'bg-gray-100': transactionSelected(transaction) }"
            :clickable="true"
            @click="toggleTransaction(transaction)"
          >
            <TableData>
              <icon
                v-if="transaction.items.length > 0"
                class="-my-1 h-8 w-8 fill-gray-400"
                :class="{
                  'rotate-180 transform transition duration-200 ease-in-out': transactionSelected(transaction),
                }"
                name="chevron-down"
              />
            </TableData>
            <TableData>{{ transaction.name }}</TableData>
            <TableData>{{ transaction.source }}</TableData>
            <TableData v-if="transaction.billing_address">
              <div>{{ transaction.billing_address.address }},</div>
              <div class="whitespace-nowrap">
                {{ transaction.billing_address.city }}, {{ transaction.billing_address.region }},
              </div>
              <div>
                {{ transaction.billing_address.postal_code }}
              </div>
            </TableData>
            <TableData v-else>-</TableData>
            <TableData v-if="transaction.shipping_address">
              <div>{{ transaction.shipping_address.address }},</div>
              <div class="whitespace-nowrap">
                {{ transaction.shipping_address.city }}, {{ transaction.shipping_address.region }},
              </div>
              <div>
                {{ transaction.shipping_address.postal_code }}
              </div>
            </TableData>
            <TableData v-else>-</TableData>
            <TableData>{{ transaction.status || '-' }}</TableData>
            <TableData>{{ transaction.order_number || '-' }}</TableData>
            <TableData>${{ transaction.tax }}</TableData>
            <TableData>${{ transaction.total }}</TableData>
            <TableData>{{ transaction.created_at }}</TableData>
            <TableData>{{ transaction.currency }}</TableData>
            <TableData>{{ transaction.payment_method }}</TableData>
          </TableRow>
          <tr v-show="transaction.items.length > 0 && transactionSelected(transaction)">
            <TableData colspan="12">
              <table class="mb-4 w-full">
                <thead>
                  <tr>
                    <th class="whitespace-nowrap pb-4 pl-4 pr-6 pt-6 text-left font-semibold" />
                    <th class="whitespace-nowrap pb-4 pl-4 pr-6 pt-6 text-left font-semibold">{{ $t('Name') }}</th>
                    <th class="whitespace-nowrap pb-4 pl-4 pr-6 pt-6 text-left font-semibold">{{ $t('Quantity') }}</th>
                    <th class="whitespace-nowrap pb-4 pl-4 pr-6 pt-6 text-left font-semibold">{{ $t('Price') }}</th>
                    <th class="whitespace-nowrap pb-4 pl-4 pr-6 pt-6 text-left font-semibold">{{ $t('Subtotal') }}</th>
                    <th class="whitespace-nowrap pb-4 pl-4 pr-6 pt-6 text-left font-semibold">{{ $t('Total') }}</th>
                    <th class="whitespace-nowrap pb-4 pl-4 pr-6 pt-6 text-left font-semibold">{{ $t('Tax') }}</th>
                    <th class="whitespace-nowrap pb-4 pl-4 pr-6 pt-6 text-left font-semibold">
                      {{ $t('Product ID') }}
                    </th>
                    <th class="whitespace-nowrap pb-4 pl-4 pr-6 pt-6 text-left font-semibold">{{ $t('Tax ID') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in transaction.items" :key="index">
                    <TableData class="whitespace-nowrap border-t-2 border-white py-4 pl-4 pr-6" />
                    <TableData class="whitespace-nowrap border-t-2 border-white py-4 pl-4 pr-6">
                      {{ item.name }}
                    </TableData>
                    <TableData class="whitespace-nowrap border-t-2 border-white py-4 pl-4 pr-6">
                      {{ item.quantity }}
                    </TableData>
                    <TableData class="whitespace-nowrap border-t-2 border-white py-4 pl-4 pr-6">
                      {{ item.price }}
                    </TableData>
                    <TableData class="whitespace-nowrap border-t-2 border-white py-4 pl-4 pr-6">
                      ${{ item.subtotal }}
                    </TableData>
                    <TableData class="whitespace-nowrap border-t-2 border-white py-4 pl-4 pr-6">
                      ${{ item.total }}
                    </TableData>
                    <TableData class="whitespace-nowrap border-t-2 border-white py-4 pl-4 pr-6">
                      ${{ item.tax }}
                    </TableData>
                    <TableData class="whitespace-nowrap border-t-2 border-white py-4 pl-4 pr-6">
                      {{ item.product_id || '-' }}
                    </TableData>
                    <TableData class="whitespace-nowrap border-t-2 border-white py-4 pl-4 pr-6">
                      {{ item.tax_id || '-' }}
                    </TableData>
                  </tr>
                </tbody>
              </table>
            </TableData>
          </tr>
        </template>
      </SimpleTable>
    </div>
    <pagination :links="transactions.links" :preserve-scroll="true" />
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import DateInput from '@/Shared/DateInput.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import _debounce from 'lodash/debounce'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'

export default {
  components: {
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
    Breadcrumb,
    DateInput,
    Icon,
    Layout,
    Pagination,
    SearchFilter,
  },
  props: {
    filters: Object,
    sources: Array,
    currencies: Array,
    payment_methods: Array,
    transactions: Object,
  },
  data() {
    return {
      selectedTransactions: [],
      form: {
        search: this.filters.search,
        start_date: this.filters.start_date,
        end_date: this.filters.end_date,
        source: this.filters.source,
        currency: this.filters.currency,
        payment_method: this.filters.payment_method,
      },
    }
  },
  watch: {
    form: {
      handler: _debounce(function () {
        this.selectedTransactions = []
        let query = _pickBy(this.form)
        this.$inertia.get(this.route('transactions'), Object.keys(query).length ? query : { remember: 'forget' }, {
          preserveState: true,
          replace: true,
          only: ['transactions'],
        })
      }, 300),
      deep: true,
    },
  },
  methods: {
    toggleTransaction(transaction) {
      if (this.transactionSelected(transaction)) {
        const index = this.selectedTransactions.indexOf(transaction.id)
        if (index > -1) {
          this.selectedTransactions.splice(index, 1)
        }
        return
      }
      this.selectedTransactions.push(transaction.id)
    },
    transactionSelected(transaction) {
      return this.selectedTransactions.includes(transaction.id)
    },
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
