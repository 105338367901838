<template>
  <layout :title="$t('Training Record Templates')">
    <div class="w-full">
      <h1 class="mb-8 w-full text-xl font-bold">
        <InertiaLink
          class="text-blue-600 hover:text-blue-700"
          :href="route('organizations.edit', template.organization_id)"
        >
          {{ $t('Organization') }}
        </InertiaLink>
        <span class="font-medium text-blue-600">/</span>
        {{ $t('Edit Training Record Template') }}
      </h1>
      <deleted-message v-if="template.deleted_at" class="mb-6">
        {{ $t('This record has been deleted.') }}
      </deleted-message>
      <archived-message v-if="template.archived_at" class="mb-6">
        <div class="flex w-full justify-between">
          <div>{{ $t('This record has been archived.') }}</div>
          <button
            v-if="template.archived_at"
            class="ml-4 text-yellow-800 hover:underline"
            tabindex="-1"
            type="button"
            @click="unArchive"
          >
            {{ $t('Restore') }}
          </button>
        </div>
      </archived-message>
    </div>
    <div class="w-full">
      <div class="max-w-lg rounded bg-white shadow">
        <form @submit.prevent="submit">
          <div class="-mb-8 -mr-6 flex flex-wrap p-8">
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input
                v-model="form.years_valid"
                :error="form.errors.years_valid"
                :label="$t('Years Valid (optional)')"
                :min="1"
                type="number"
              />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <select-input
                v-model="form.source_type"
                :error="form.errors.source_type"
                :label="$t('Training Document Type')"
                :options="source_types"
              />
            </div>
            <div v-if="form.source_type == 'link'" class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input v-model="form.link" :error="form.errors.link" :label="$t('Training Link')" type="url" />
            </div>
            <div v-if="form.source_type == 'document'" class="w-full pb-8 pr-6 lg:w-1/2">
              <div class="w-full">
                <span>{{ $t('Training Document') }}</span>
                <div class="flex items-center justify-between pb-3 pt-6">
                  <div class="flex">
                    <div v-if="form.training_document" class="flex items-center">
                      <div class="mr-2 w-40 truncate">{{ form.training_document.name }}</div>
                      <button @click="form.training_document = null">
                        <icon class="h-4 w-4 fill-gray-600 hover:fill-gray-800" name="close" />
                      </button>
                    </div>
                    <div v-else-if="template.training_document" class="flex items-center">
                      <a class="w-40 truncate underline" :href="template.training_document.url">
                        <span class="mr-2">{{ template.training_document.name }}</span>
                      </a>
                    </div>
                    <div v-else class="flex items-center">
                      <span>{{ $t('No file selected.') }}</span>
                    </div>
                    <div class="">
                      <label class="btn-gray-outline px-4 py-3" for="training_document">
                        {{
                          form.training_document || template.training_document
                            ? $t('Replace Document')
                            : $t('Upload Document')
                        }}
                      </label>
                      <input
                        id="training_document"
                        accept=".pdf,.jpg,.jpeg,.png,.gif,.doc,.docx,.xls"
                        style="display: none"
                        type="file"
                        @input="form.training_document = $event.target.files[0]"
                      />
                    </div>
                  </div>
                  <div v-if="form.errors.training_document" class="form-error text-ts-front-label-error">
                    {{ form.errors.training_document }}
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full pb-8 pr-6">
              <textarea-input v-model="form.description" :error="form.errors.description" :label="$t('Description')" />
            </div>
          </div>
          <div
            class="flex justify-between border-t border-gray-100 bg-gray-100 px-8 py-4"
            :class="template.has_training_records ? 'justify-end' : 'justify-between'"
          >
            <div class="flex justify-start">
              <button
                v-if="!template.has_training_records"
                class="text-red-500 hover:underline"
                tabindex="-1"
                type="button"
                @click="destroy"
              >
                {{ $t('Delete') }}
              </button>
              <button
                v-if="template.archived_at"
                class="ml-4 text-red-500 hover:underline"
                tabindex="-1"
                type="button"
                @click="unArchive"
              >
                {{ $t('Restore Archived') }}
              </button>
              <button v-else class="ml-4 text-red-500 hover:underline" tabindex="-1" type="button" @click="archive">
                {{ $t('Archive') }}
              </button>
            </div>
            <!-- <span v-else>&nbsp;</span> -->
            <loading-button class="btn-blue" :loading="form.processing" type="submit">
              {{ $t('Update Template') }}
            </loading-button>
          </div>
        </form>
      </div>
    </div>
    <div class="mt-12 flex items-center justify-between">
      <h2 class="text-lg">{{ $t('Training Records') }}</h2>
      <InertiaLink class="btn-blue" :href="route('training-records.create', template.id)">
        <span>{{ $t('Create') }}</span>
        <span class="hidden md:inline">{{ $t('Record') }}</span>
      </InertiaLink>
    </div>
    <div class="mt-4 overflow-x-auto rounded bg-white shadow">
      <table class="w-full">
        <thead>
          <tr>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Student') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Created') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Completed') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Expires') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="training_records.data.length === 0">
            <td class="border-t px-6 py-4" colspan="4">{{ $t('No training records found.') }}</td>
          </tr>
          <tr
            v-for="training_record in training_records.data"
            :key="training_record.id"
            class="hover:bg-gray-100 focus:bg-gray-100"
            @click="$inertia.visit(route('training-records.edit', training_record.id))"
          >
            <td class="cursor-pointer whitespace-nowrap border-t px-6 py-4">
              {{ training_record.user.first_name }} {{ training_record.user.last_name }}
            </td>
            <td class="cursor-pointer whitespace-nowrap border-t px-6 py-4">
              <front-date :timestamp="training_record.created_at" />
            </td>
            <td class="cursor-pointer whitespace-nowrap border-t px-6 py-4">
              <front-date :timestamp="training_record.completed_at" />
            </td>
            <td class="cursor-pointer whitespace-nowrap border-t px-6 py-4">
              <front-certificate-status v-if="training_record.expires_at" :status="training_record.status">
                <front-date :timestamp="training_record.expires_at" />
              </front-certificate-status>
              <span v-else>-</span>
            </td>
            <td class="w-min cursor-pointer border-t px-4 align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination :links="training_records.links" />
  </layout>
</template>

<script>
import ArchivedMessage from '@/Shared/ArchivedMessage.vue'
import DeletedMessage from '@/Shared/DeletedMessage.vue'
import FrontCertificateStatus from '@/Shared/FrontCertificateStatus.vue'
import FrontDate from '@/Shared/FrontDate.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import Pagination from '@/Shared/Pagination.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    ArchivedMessage,
    DeletedMessage,
    FrontCertificateStatus,
    FrontDate,
    Icon,
    Layout,
    LoadingButton,
    Pagination,
    SelectInput,
    TextInput,
    TextareaInput,
  },
  props: {
    template: Object,
    training_records: Object,
    source_types: Object,
  },
  data() {
    return {
      form: useForm({
        name: this.template.name,
        description: this.template.description,
        years_valid: this.template.years_valid,
        source_type: this.template.source_type,
        link: this.template.link,
        training_document: null,
      }),
    }
  },
  methods: {
    submit() {
      this.form
        .transform((data) => ({
          ...data,
          _method: 'put',
          link: data.source_type === 'link' ? data.link : null,
          training_document: data.source_type === 'document' ? data.training_document : null,
        }))
        .post(this.route('training-record-templates.update', this.template.id))
    },
    archive() {
      if (confirm(this.$t('Are you sure you want to archive this record?'))) {
        this.$inertia.post(this.route('front.admin.training-record-templates.archive', this.template.id))
      }
    },
    unArchive() {
      if (confirm(this.$t('Are you sure you want to restore this archived record?'))) {
        this.$inertia.post(this.route('front.admin.training-record-templates.unarchive', this.template.id))
      }
    },
    destroy() {
      if (!this.template.has_training_records && confirm(this.$t('Are you sure you want to delete this template?'))) {
        this.$inertia.delete(this.route('training-record-templates.destroy', this.template.id))
      }
    },
  },
}
</script>
