<template>
  <layout :title="$t('e-Learning')">
    <div class="-mt-4 mb-8 flex items-end justify-between">
      <h1 class="text-xl font-bold">{{ $t('e-Learning') }}</h1>
      <dropdown class="btn-blue" placement="bottom-end">
        <div class="flex cursor-pointer select-none items-center">
          <div>
            <span>{{ $t('Actions') }}</span>
          </div>
          <icon class="-my-1 h-5 w-5 fill-white" name="chevron-down" />
        </div>
        <template #dropdown>
          <div class="mt-2 rounded border bg-white py-2 text-sm shadow-lg">
            <InertiaLink
              class="block px-4 py-2 hover:bg-gray-200 hover:text-gray-700"
              :href="route('elearnings.scorm')"
            >
              <span>{{ $t('Scorm Settings') }}</span>
            </InertiaLink>
          </div>
        </template>
      </dropdown>
    </div>
    <div class="mb-6 flex items-center justify-between">
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
        <label class="mt-4 block text-gray-800">{{ $t('Status:') }}</label>
        <select v-model="form.failed" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="only">{{ $t('Only Failed') }}</option>
        </select>
      </search-filter>

      <div class="hidden lg:flex">
        <InertiaLink class="btn-blue ml-2" :href="route('elearnings.create')">
          <span>{{ $t('Upload') }}</span>
          <span class="hidden md:inline">{{ $t('e-Learning') }}</span>
        </InertiaLink>
      </div>
      <dropdown class="btn-blue lg:hidden" placement="bottom-end">
        <div class="flex cursor-pointer select-none items-center">
          <div>
            <span>{{ $t('Actions') }}</span>
          </div>
          <icon class="-my-1 h-5 w-5 fill-white" name="chevron-down" />
        </div>
        <template #dropdown>
          <div class="mt-2 rounded border bg-white py-2 text-sm shadow-lg">
            <InertiaLink
              class="block px-4 py-2 hover:bg-gray-200 hover:text-gray-700"
              :href="route('elearnings.scorm')"
            >
              <span>{{ $t('Scorm Setting') }}</span>
            </InertiaLink>
            <InertiaLink
              class="block px-4 py-2 hover:bg-gray-200 hover:text-gray-700"
              :href="route('elearnings.create')"
            >
              <span>{{ $t('Upload e-Learning') }}</span>
            </InertiaLink>
          </div>
        </template>
      </dropdown>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow">
      <SimpleTable class="w-full">
        <template #head>
          <TableHead>{{ $t('Name') }}</TableHead>
          <TableHead>{{ $t('Title') }}</TableHead>
          <TableHead>{{ $t('Available') }}</TableHead>
          <TableHead>{{ $t('Preview') }}</TableHead>
          <TableHead />
        </template>
        <TableRow v-if="elearnings.data.length === 0">
          <TableData colspan="4">{{ $t('No elearnings found.') }}</TableData>
        </TableRow>
        <TableRow
          v-for="(elearning, key) in elearnings.data"
          v-else
          :key="key"
          :clickable="true"
          @click="$inertia.visit(route('elearnings.edit', elearning.id))"
        >
          <TableData>{{ elearning.name }}</TableData>
          <TableData>{{ elearning.title }}</TableData>
          <TableData>
            <icon v-if="elearning.is_uploaded" class="h-5 w-5" name="checkmark" />
          </TableData>
          <TableData>
            <a
              v-if="elearning.can.preview"
              class="text-ts-blue-500 hover:cursor-pointer hover:underline"
              :href="route('elearnings.preview', elearning)"
              target="_blank"
              @click.stop
            >
              {{ $t('Preview') }}
            </a>
          </TableData>
          <TableData class="w-min border-t px-4 align-middle">
            <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
          </TableData>
        </TableRow>
      </SimpleTable>
    </div>
    <pagination :links="elearnings.links" />
  </layout>
</template>

<script>
import Dropdown from '@/Shared/Dropdown.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    SimpleTable,
    TableData,
    TableRow,
    Dropdown,
    Icon,
    Layout,
    Pagination,
    SearchFilter,
    TableHead,
  },
  props: {
    elearnings: Object,
    filters: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        failed: this.filters.failed,
      },
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(this.route('elearnings'), Object.keys(query).length ? query : { remember: 'forget' }, {
          preserveState: true,
          replace: true,
        })
      }, 300),
      deep: true,
    },
  },
  mounted() {
    Echo.channel('elearning').listen('ElearningUploadStatusUpdated', (e) => {
      this.elearnings.data.map((elearning) => {
        if (elearning.uuid !== e.elearning.uuid) {
          return
        }

        elearning.is_uploaded = e.elearning.is_uploaded
        elearning.can.preview = e.elearning.is_uploaded
      })
    })
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
