<template>
  <Listbox v-slot="{ open }" as="div" class="relative">
    <ListboxButton
      class="w-full rounded-lg border border-ts-front-border-light bg-ts-front-field-light p-3.5 text-ts-front-label hover:border-red-500 focus:border-red-500 focus:outline-none"
    >
      <span class="flex items-center">
        <slot name="selected" />
        <ChevronDownIcon aria-hidden="true" :class="!open ? 'h-5 w-5 transition' : 'h-5 w-5 rotate-180 transition'" />
      </span>
    </ListboxButton>
    <transition
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div>
        <ListboxOptions
          class="absolute right-0 z-30 max-h-60 w-auto overflow-auto border bg-white text-base shadow focus:outline-none sm:text-sm"
        >
          <slot name="options" />
        </ListboxOptions>
      </div>
    </transition>
  </Listbox>
</template>

<script>
import { Listbox, ListboxButton, ListboxOptions } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ChevronDownIcon,
  },
  props: {
    list: Object,
    selected: String,
    modelValue: Number,
  },
  emits: ['update:modelValue'],
}
</script>
