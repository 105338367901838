<template>
  <div class="flex items-center">
    <div class="mr-3 text-sm">
      <front-checkbox-input v-model="optionsData.is_required" :label="$t('Required')" />
    </div>
    <div class="mr-3 text-sm">
      <front-checkbox-input v-model="optionsData.is_multiple" :label="$t('Multiple Selection')" />
    </div>
    <div class="mr-3 text-sm">
      <div class="inline-flex items-center">
        <Listbox as="div" class="relative">
          <ListboxButton class="flex cursor-pointer items-center focus:outline-none">
            <front-checkbox-input
              :disable="true"
              :label="$t('Flagged Responses')"
              :model-value="flaggedChoicesData.length > 0"
            />
            <div v-if="choiceOptions?.length > 0" class="flex gap-1">
              <template v-for="choiceOption in choiceOptions" :key="choiceOption.id">
                <option-tag v-if="choiceOption.is_flagged" :key="choiceOption.id" :model-value="choiceOption" />
              </template>
            </div>
          </ListboxButton>
          <transition
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <div>
              <ListboxOptions
                class="absolute z-30 max-h-60 w-80 overflow-auto border bg-white text-base shadow focus:outline-none sm:text-sm"
              >
                <ListboxOption
                  v-for="choiceOption in choiceOptions"
                  :key="choiceOption.id"
                  as="template"
                  :value="choiceOption.id"
                >
                  <li>
                    <div class="flex items-center p-3">
                      <input
                        :id="`flaggedOptions-${choiceOption.id}`"
                        :checked="choiceOption.is_flagged"
                        class="form-checkbox mr-3 h-5 w-5 cursor-pointer rounded-xl accent-ts-red-500 outline-none"
                        type="checkbox"
                        :value="choiceOption.id"
                        @click.stop="onSelectOptionClick(choiceOption)"
                      />
                      <label :for="`flaggedOptions-${choiceOption.id}`">
                        <option-tag :key="choiceOption.id" :model-value="choiceOption" />
                      </label>
                    </div>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </div>
          </transition>
        </Listbox>
      </div>
    </div>
  </div>
</template>
<script>
import OptionTag from '@/Shared/Answerables/OptionTag.vue'
import FrontCheckboxInput from '@/Shared/FrontCheckboxInput.vue'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    OptionTag,
    FrontCheckboxInput,
  },
  props: {
    options: Object,
    flaggedChoices: Array,
    choices: Array,
  },
  emits: ['update:options', 'update:flaggedChoices'],
  data() {
    return {
      optionsData: {
        is_required: this.options?.is_required ?? false,
        is_multiple: this.options?.is_multiple ?? false,
      },
      flaggedChoicesData: this.flaggedChoices ?? [],
    }
  },
  computed: {
    choiceOptions() {
      return this.choices?.map((choice) => ({
        ...choice,
        is_flagged: this.flaggedChoices?.some((choiceId) => choiceId === choice.id),
      }))
    },
  },
  watch: {
    optionsData: {
      handler: function () {
        this.saveData()
      },
      deep: true,
    },
  },
  methods: {
    onSelectOptionClick(choice) {
      if (this.flaggedChoicesData.includes(choice.id)) {
        this.flaggedChoicesData = this.flaggedChoicesData.filter((id) => id !== choice.id)
      } else {
        this.flaggedChoicesData.push(choice.id)
      }
      this.saveFlaggedChoices()
    },
    saveData() {
      this.$emit('update:options', this.optionsData)
    },
    saveFlaggedChoices() {
      this.$emit('update:flaggedChoices', this.flaggedChoicesData)
    },
  },
}
</script>
