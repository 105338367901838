<template>
  <front-modal class="rounded text-base" :show="show" width="auto" @close="close">
    <div class="flex flex-wrap bg-white px-8 pt-8">
      <breadcrumb :name="$t('Add Member to :location_name', { location_name: location.name })" />
    </div>
    <div class="overflow-hidden bg-white">
      <div class="mb-6 flex flex-col-reverse items-center justify-between lg:flex-row">
        <div class="mx-auto w-10/12 overflow-hidden py-10">
          <form class="w-full" @submit.prevent="submit">
            <div class="space-y-4">
              <div class="space-y-1">
                <label class="form-label">{{ $t('User:') }}</label>
                <dynamic-search-input
                  v-model="form.user"
                  required
                  :search-by="['name', 'email']"
                  track-by="id"
                  :url="route('front.manager.users.search')"
                >
                  <div v-if="form.user" class="flex items-center justify-between">
                    <div class="truncate">{{ form.user.name }}</div>
                    <div class="whitespace-nowrap text-xs text-gray-600">
                      {{ form.user.email }}
                    </div>
                  </div>
                  <template #option="{ option, selected }">
                    <div class="flex items-center justify-between">
                      <div>{{ option.name }}</div>
                      <div class="text-xs" :class="{ 'text-gray-600': !selected, 'text-white': selected }">
                        {{ option.email }}
                      </div>
                    </div>
                  </template>
                </dynamic-search-input>
              </div>

              <front-loading-button class="mx-auto" :loading="form.processing" type="submit">
                {{ $t('Assign User') }}
              </front-loading-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </front-modal>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import DynamicSearchInput from '@/Shared/DynamicSearchInput.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontModal from '@/Shared/FrontModal.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    DynamicSearchInput,
    FrontLoadingButton,
    FrontModal,
  },
  props: {
    show: Boolean,
    location: Object,
  },
  data() {
    return {
      form: useForm(
        {
          user: null,
        },
        'LocationAssignUsersManagerModal'
      ),
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    submit() {
      if (this.form.user)
        this.form.post(this.route('front.manager.locations.members.store', [this.location, this.form.user]), {
          onSuccess: () => {
            this.close()
            this.form.user = null
          },
        })
    },
  },
}
</script>
