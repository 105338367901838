<template>
  <front-layout :title="$t('Subscription Invoice')">
    <div class="no-print mb-8">
      <breadcrumb
        name="Invoice"
        :previous-name="$t('Subscriptions')"
        :previous-url="route('front.admin.subscriptions')"
      />
    </div>
    <div id="receipt" class="max-w-xl rounded-lg bg-white px-8 py-8 text-sm md:px-16">
      <div class="no-print mb-6 flex justify-end" data-html2canvas-ignore="true">
        <button
          v-if="showPrintLink"
          class="no-print mr-1 rounded border bg-gray-100 p-2 hover:bg-gray-200"
          title="Print receipt"
          @click="printReceipt()"
        >
          <icon class="h-5 w-5 fill-gray-500" name="printer" />
        </button>
        <a
          v-if="invoice.can.email_invoice"
          class="no-print mx-1 rounded border bg-gray-100 p-2 hover:bg-gray-200"
          :href="route('front.admin.invoice.email', invoice)"
          title="Email receipt"
        >
          <icon class="h-5 w-5 text-gray-500" name="email" />
        </a>
        <a
          class="no-print ml-1 rounded border bg-gray-100 p-2 hover:bg-gray-200"
          :href="route('front.admin.invoice.download', invoice)"
          title="Download receipt"
        >
          <icon class="h-5 w-5 text-gray-500" name="download" />
        </a>
      </div>
      <div class="mb-4 flex items-center justify-between py-4 sm:flex-row">
        <div>
          <worksite-dark class="mx-auto sm:mx-0" />
        </div>
        <div>
          <div v-if="tenant.business_name" class="mb-2">{{ tenant.business_name }}</div>
          <div v-if="tenant.address">{{ tenant.address }}</div>
        </div>
      </div>
      <div class="flex justify-between border-t border-gray-200 py-4 sm:flex-row">
        <div class="mb-6 md:mb-0">
          <div class="mb-4 text-gray-400">{{ $t('Name') }}</div>
          <div>{{ invoice.name }}</div>
        </div>
        <div class="mb-6 md:mb-0">
          <div class="mb-4 text-gray-400">{{ $t('Order Date') }}</div>
          <div>{{ invoice.purchased_at }}</div>
        </div>
        <div class="mb-6 md:mb-0">
          <div class="mb-4 text-gray-400">{{ $t('Order No.') }}</div>
          <div>{{ invoice.order_no }}</div>
        </div>
        <div class="mb-6 md:mb-0">
          <div class="mb-4 text-gray-400">{{ $t('Payment Method') }}</div>
          <div class="flex items-center">{{ invoice.payment_method }}</div>
        </div>
        <div class="mb-6 md:mb-0">
          <div class="mb-4 text-gray-400">{{ $t('Billing Address') }}</div>
          <div v-if="invoice.organization_name" class="max-w-10 leading-snug">
            {{ invoice.organization_name }}
          </div>
          <div class="max-w-10 leading-snug">{{ invoice.billing_address }}</div>
        </div>
      </div>
      <div class="border-b border-t border-gray-200 py-8">
        <table class="w-full">
          <thead>
            <tr>
              <th class="text-left font-semibold">{{ $t('Name') }}</th>
              <th class="text-left font-semibold">{{ $t('Quantity') }}</th>
              <th class="text-left font-semibold">{{ $t('Price') }}</th>
              <th class="text-right font-semibold">{{ $t('Total') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!items.length">
              <td class="px-6 py-4" colspan="6">{{ $t('No items found.') }}</td>
            </tr>
            <tr v-for="item in items" :key="item.id" style="padding-top: 10px; padding-bottom: 10px">
              <td class="flex-col pt-8">
                <p>{{ item.name }}</p>
                <p v-if="item.start && item.end" class="mt-2 text-xs text-gray-600">
                  {{ item.start }} - {{ item.end }}
                </p>
              </td>
              <td class="pt-8">{{ item.quantity }}</td>
              <td class="pt-8">${{ item.price }}</td>
              <td class="pt-8 text-right">${{ item.subtotal }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex justify-end pt-8">
        <div class="w-48 space-y-4">
          <div class="flex justify-between text-gray-500">
            <span>{{ $t('Subtotal') }}</span>
            <span>${{ invoice.subtotal }}</span>
          </div>
          <div class="flex justify-between text-gray-500">
            <span>{{ $t('Tax') }}</span>
            <span>${{ invoice.tax }}</span>
          </div>
          <div class="flex justify-between">
            <span>{{ $t('Total') }}</span>
            <span class="font-bold text-ts-red-500">${{ invoice.total }}</span>
          </div>
        </div>
      </div>
      <div v-if="tenant.tax_number" class="pt-12 text-center">
        {{ $t('VAT Number : GST/HST Number : :tax_number', { tax_number: tenant.tax_number }) }}
      </div>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import Icon from '@/Shared/Icon.vue'
import WorksiteDark from '@/Shared/Logo/WorksiteDark.vue'

export default {
  components: {
    Breadcrumb,
    FrontLayout,
    Icon,
    WorksiteDark,
  },
  props: {
    tenant: Object,
    invoice: Object,
    items: Array,
  },
  data() {
    return {
      showPrintLink: typeof window.print === 'function',
    }
  },
  methods: {
    printReceipt() {
      if (this.showPrintLink) {
        window.print()
      }
    },
  },
}
</script>
