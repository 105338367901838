<template>
  <layout :title="class_.instructor_led_classroom.name">
    <div class="-mt-4 mb-8">
      <breadcrumb
        :middle-name="class_.instructor_led_classroom.name"
        :middle-url="route('classes.show', class_.id)"
        :name="$t('Duplicate')"
        :previous-name="$t('Classes')"
        :previous-url="route('classes')"
      />
    </div>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <div class="form-label flex justify-between">
              <label>{{ $t('Location:') }}</label>
              <div class="flex items-center">
                <span class="mr-2">{{ $t('With client sites') }}</span>
                <input v-model="with_client_site" type="checkbox" />
              </div>
            </div>
            <search-input
              v-model="form.location"
              :data="locationData"
              :error="form.errors.location_id"
              :search-by="['name', 'room.name']"
              track-by="key"
            >
              <div v-if="form.location" class="flex items-center justify-between">
                <div class="truncate">{{ form.location.name }}</div>
                <div v-if="form.location.room" class="whitespace-nowrap text-xs text-gray-600">
                  {{ form.location.room.name }}
                </div>
              </div>
              <template #option="{ option, selected }">
                <div class="flex items-center justify-between">
                  <div class="truncate">{{ option.name }}</div>
                  <div
                    v-if="option.room"
                    class="whitespace-nowrap text-xs"
                    :class="{ 'text-gray-600': !selected, 'text-white': selected }"
                  >
                    {{ option.room.name }}
                  </div>
                </div>
              </template>
            </search-input>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.capacity" :error="form.errors.capacity" :label="$t('Capacity')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <radio-input v-model="form.private" :error="form.errors.private" :label="$t('Private')" type="radio" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.price" :error="form.errors.price" :label="$t('Price')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <select-input v-model="form.locale" :error="form.errors.locale" :label="$t('Locale')">
              <option v-for="(locale, key) in locales" :key="key" :value="locale">
                {{ key }}
              </option>
            </select-input>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <radio-input
              v-model="form.equipment_enabled"
              :error="form.errors.equipment_enabled"
              :label="$t('Equipment Tracking Enabled')"
              type="radio"
            />
          </div>
          <div v-if="form.equipment_enabled" class="flex w-full">
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input
                v-model="form.equipment_1"
                :error="form.errors.default_equipment_types"
                :label="$t('Equipment #1')"
              />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input
                v-model="form.equipment_2"
                :error="form.errors.default_equipment_types"
                :label="$t('Equipment #2')"
              />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input
                v-model="form.equipment_3"
                :error="form.errors.default_equipment_types"
                :label="$t('Equipment #3')"
              />
            </div>
          </div>
          <div class="w-full pb-8 pr-6">
            <textarea-input
              v-model="form.instructor_notes"
              :autosize="true"
              :error="form.errors.instructor_notes"
              :label="$t('Instructor notes')"
              :rows="2"
            />
          </div>
          <div v-if="class_.sessions.length" class="w-full">
            <!-- Parent Session -->
            <p class="pb-6">{{ $t('Parent Sessions') }}</p>
            <div v-for="(session, index) in class_.sessions" :key="session.id" class="flex">
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <date-input
                  v-model="form.class_sessions[index].date"
                  :error="form.errors[`class_sessions.${index}.date`]"
                  :label="$t('Date')"
                />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/4">
                <time-input
                  v-model="form.class_sessions[index].start_time"
                  :error="form.errors[`class_sessions.${index}.start_time`]"
                  :label="$t('Start time')"
                />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/4">
                <time-input
                  v-model="form.class_sessions[index].end_time"
                  :error="form.errors[`class_sessions.${index}.end_time`]"
                  :label="$t('End time')"
                />
              </div>
            </div>
            <!-- Subclasses -->
            <div v-for="(subclass, subclassIndex) in class_.subclasses" :key="subclass.id" class="w-full">
              <!-- Subclass Sessions -->
              <p class="pb-6">
                {{ $t('Subclass Sessions (:name)', { name: subclass.instructor_led_classroom.name }) }}
              </p>
              <div v-for="(session, sessionIndex) in subclass.sessions" :key="session.id" class="flex">
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <date-input
                    v-model="form.subclass_sessions[subclassIndex][sessionIndex].date"
                    :error="form.errors[`subclass_sessions.${subclassIndex}.${sessionIndex}.date`]"
                    :label="$t('Date')"
                  />
                </div>
                <div class="w-full pb-8 pr-6 lg:w-1/4">
                  <time-input
                    v-model="form.subclass_sessions[subclassIndex][sessionIndex].start_time"
                    :error="form.errors[`subclass_sessions.${subclassIndex}.${sessionIndex}.start_time`]"
                    :label="$t('Start time')"
                  />
                </div>
                <div class="w-full pb-8 pr-6 lg:w-1/4">
                  <time-input
                    v-model="form.subclass_sessions[subclassIndex][sessionIndex].end_time"
                    :error="form.errors[`subclass_sessions.${subclassIndex}.${sessionIndex}.end_time`]"
                    :label="$t('End time')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue ml-auto" :loading="form.processing" type="submit">
            {{ $t('Duplicate Class') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import DateInput from '@/Shared/DateInput.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import RadioInput from '@/Shared/RadioInput.vue'
import SearchInput from '@/Shared/SearchInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import TimeInput from '@/Shared/TimeInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    DateInput,
    Layout,
    LoadingButton,
    RadioInput,
    SearchInput,
    SelectInput,
    TextInput,
    TextareaInput,
    TimeInput,
  },
  props: {
    class_: Object,
    locales: Object,
    locations: Array,
    offline_tests: Array,
  },
  data() {
    return {
      with_client_site: this.class_.location.client_site,
      locationData: this.locationsWithKeys(this.class_.location.client_site),
      form: useForm(
        {
          location: this.locationsWithKeys(this.class_.location.client_site).find((location) => {
            // The null safe operator returns `undefined`, and we want to compare
            // against `null` when the room isn't defined in the class itself.
            const roomId = location.room?.id || null

            return location.id === this.class_.location.id && roomId === this.class_.room_id
          }),
          locale: this.class_.locale,
          class_sessions: this.class_.sessions,
          subclass_sessions: this.class_.subclasses.map((subclass) => subclass.sessions),
          capacity: this.class_.capacity,
          private: this.class_.private,
          price: this.class_.price,
          course_id: this.class_.course_id,
          instructor_notes: this.class_.instructor_notes,
          equipment_enabled: this.class_.equipment_enabled,
          equipment_1: this.class_.default_equipment_types?.[0],
          equipment_2: this.class_.default_equipment_types?.[1],
          equipment_3: this.class_.default_equipment_types?.[2],
        },
        'ClassDuplicate'
      ),
    }
  },
  watch: {
    with_client_site() {
      this.locationData = this.locationsWithKeys(this.with_client_site)
    },
    'form.equipment_enabled'(newVal) {
      if (!newVal) {
        this.form.equipment_1 = null
        this.form.equipment_2 = null
        this.form.equipment_3 = null
      }
    },
  },
  methods: {
    locationsWithKeys(withClientSite) {
      return this.locations
        .filter((location) => {
          // If we want to include client site locations, we can return true. Otherwise,
          // we are only returning true for those that are not client site locations.
          if (withClientSite) {
            return true
          }

          return !location.client_site
        })
        .map((location, key) => {
          return { key: key, ...location }
        })
    },
    submit() {
      this.form
        .transform((data) => {
          return {
            class_id: this.class_.id,
            location_id: data.location ? data.location.id : null,
            room_id: data.location && data.location.room ? data.location.room.id : null,
            capacity: data.capacity,
            class_sessions: data.class_sessions,
            subclass_sessions: data.subclass_sessions,
            private: data.private,
            price: data.price,
            course_id: data.course_id,
            locale: data.locale,
            instructor_notes: data.instructor_notes,
            instructor_led_classroom_id: this.class_.instructor_led_classroom.id,
            equipment_enabled: this.form.equipment_enabled,
            default_equipment_types: this.form.equipment_enabled
              ? [this.form.equipment_1, this.form.equipment_2, this.form.equipment_3]
              : null,
          }
        })
        .post(this.route('classes.duplicate.store', { class: this.class_ }))
    },
    getFormattedTestName(test) {
      if (test.name) {
        return `${test.name}, Total: ${test.total_score}, Passing: ${test.passing_score}`
      }
      return `Total: ${test.total_score}, Passing: ${test.passing_score}`
    },
  },
}
</script>
