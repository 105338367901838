<template>
  <div class="font-heading font-medium">
    <div>
      <menu-item class="text-ts-gray-bold mb-2 font-semibold" :name="$t('Training')" />
      <div class="mb-1">
        <MenuItem
          :name="$t('Courses')"
          :route-link="route('front.courses')"
          :url="['account/courses*', 'account/enrolments*', 'account/contents*', 'account/']"
        >
          <Icon class="mr-3 h-5 w-5" fill="currentColor" name="book" />
        </MenuItem>
      </div>
      <div class="mb-1">
        <MenuItem :name="$t('Certificates')" :route-link="route('front.certificates')" url="account/certificates*">
          <icon class="mr-3 h-5 w-5" fill="currentColor" name="certificate" />
        </MenuItem>
      </div>
      <template v-if="$page.props.auth.user.organization && hasEhsAccess">
        <MenuItem class="text-ts-gray-bold mb-2 font-semibold" :name="$t('EHS')" />
        <div>
          <MenuItem :name="$t('Actions')" :route-link="route('front.actions')" url="account/actions*">
            <icon class="mr-3 h-5 w-5" fill="none" name="queue-list" />
          </MenuItem>
          <MenuItem :name="$t('Forms')" :route-link="route('front.filledForms.index')" url="account/forms*">
            <icon class="mr-3 h-5 w-5" name="document" />
          </MenuItem>
        </div>
        <div>
          <MenuItem :name="$t('Manuals')" :route-link="route('front.manuals')" url="account/manuals*">
            <icon class="mr-3 h-5 w-5" name="stack" />
          </MenuItem>
        </div>
        <div class="mb-3">
          <MenuItem :name="$t('Files')" :route-link="route('front.folders')" url="account/folders*">
            <icon class="mr-3 h-5 w-5" name="document" />
          </MenuItem>
        </div>
      </template>
      <menu-item class="text-ts-gray-bold mb-2 font-semibold" :name="$t('Account')" />
      <div class="mb-1">
        <MenuItem :name="$t('Settings')" :route-link="route('front.settings')" url="account/settings*">
          <icon class="mr-3 h-5 w-5" name="setting" />
        </MenuItem>
      </div>
      <div class="mb-1">
        <MenuItem :name="$t('Purchases')" :route-link="route('front.purchases')" url="account/purchases*">
          <icon class="mr-3 h-5 w-5" fill="currentColor" name="purchase" />
        </MenuItem>
      </div>
      <div class="mb-3">
        <front-back-office-admin-menu-item />
        <front-logout-menu-item />
      </div>
      <MenuItem
        v-if="$page.props.app.tenant.phone && $page.props.app.tenant.email"
        class="text-ts-gray-bold mb-2 font-semibold"
        :name="$t('Support')"
      />
      <FrontPhoneMenuItem />
      <FrontEmailMenuItem />
    </div>
  </div>
</template>

<script>
import FrontBackOfficeAdminMenuItem from './FrontBackOfficeAdminMenuItem.vue'
import FrontEmailMenuItem from './FrontEmailMenuItem.vue'
import FrontLogoutMenuItem from './FrontLogoutMenuItem.vue'
import FrontPhoneMenuItem from './FrontPhoneMenuItem.vue'
import Icon from './Icon.vue'
import MenuItem from './MenuItem.vue'
import { isUrl } from '@/Utils/Helpers'

export default {
  components: {
    Icon,
    MenuItem,
    FrontBackOfficeAdminMenuItem,
    FrontLogoutMenuItem,
    FrontPhoneMenuItem,
    FrontEmailMenuItem,
  },
  computed: {
    hasEhsAccess() {
      return this.$page.props.auth.user.organization?.has_subscription && this.$page.props.auth.user.has_ehs_access
    },
  },
  methods: {
    isUrl,
  },
}
</script>
