<template>
  <layout :title="$t('Reports')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('reports')">
        {{ $t('Reports') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Course Enrolment By Location') }}
    </h1>
    <div class="mb-6">
      <div class="flex w-1/3 lg:hidden">
        <dropdown
          class="flex items-baseline rounded bg-white px-6 py-4 shadow hover:bg-gray-100 focus:z-10 focus:border-white focus:ring"
          placement="bottom-start"
        >
          <span class="text-gray-800">{{ $t('Filter') }}</span>
          <svg class="h-2 w-2 fill-gray-700 md:ml-2" viewBox="0 0 961.243 599.998">
            <path
              d="M239.998 239.999L0 0h961.243L721.246 240c-131.999 132-240.28 240-240.624 239.999-.345-.001-108.625-108.001-240.624-240z"
            />
          </svg>
          <template #dropdown>
            <div class="mt-2 w-full max-w-sm rounded bg-white px-4 py-6 shadow-lg">
              <label class="block text-gray-800">{{ $t('Courses (Instructor-led):') }}</label>
              <select v-model="form.course" class="form-select mt-1 w-full">
                <option :value="null" />
                <option v-for="course in courses" :key="course.id" :value="course.id">{{ course.name }}</option>
              </select>
              <label class="mt-4 block text-gray-800">{{ $t('Client Site:') }}</label>
              <select v-model="form.client_site" class="form-select mt-1 w-full">
                <option value="hide" />
                <option value="with">{{ $t('With Client Sites') }}</option>
              </select>
              <label class="mt-4 block text-gray-800">{{ $t('Between:') }}</label>
              <date-input v-model="form.local_start_date" class="mt-1 w-full" />
              <date-input v-model="form.local_end_date" class="mt-1 w-full" />
              <label class="mt-4 block text-gray-800">{{ $t('Threshold:') }}</label>
              <text-input v-model="form.threshold" autocomplete="off" :error="$page.props.errors.threshold" />
            </div>
          </template>
        </dropdown>
        <button class="ml-3 text-sm text-gray-600 hover:text-gray-700" type="button" @click="reset">
          {{ $t('Reset') }}
        </button>
      </div>
      <div class="hidden lg:flex">
        <div class="mr-4 w-4/12">
          <label class="form-label">{{ $t('Course (Instructor-led):') }}</label>
          <select v-model="form.course" class="form-select mt-1 w-full">
            <option :value="null" />
            <option v-for="course in courses" :key="course.id" :value="course.id">{{ course.name }}</option>
          </select>
        </div>
        <div class="mr-4 w-2/12">
          <label class="form-label">{{ $t('Client Site:') }}</label>
          <select v-model="form.client_site" class="form-select mt-1 w-full">
            <option :value="null" />
            <option value="with">{{ $t('With Client Sites') }}</option>
          </select>
        </div>
        <div class="flex w-4/12">
          <date-input v-model="form.local_start_date" class="mr-4 w-1/2" :label="$t('From')" />
          <date-input v-model="form.local_end_date" class="mr-4 w-1/2" :label="$t('To')" />
        </div>
        <div class="w-1/12">
          <text-input
            v-model="form.threshold"
            autocomplete="off"
            :error="$page.props.errors.threshold"
            :label="$t('Threshold')"
          />
        </div>
        <div>
          <label class="form-label invisible">{{ $t('Reset') }}</label>
          <button class="ml-3 py-3 text-sm text-gray-600 hover:text-gray-700" type="button" @click="reset">
            {{ $t('Reset') }}
          </button>
        </div>
      </div>
      <locations :locations="locations" :threshold="form.threshold" />
    </div>
  </layout>
</template>

<script>
import Locations from './Locations.vue'
import DateInput from '@/Shared/DateInput.vue'
import Dropdown from '@/Shared/Dropdown.vue'
import Layout from '@/Shared/Layout.vue'
import TextInput from '@/Shared/TextInput.vue'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: { Locations, DateInput, Dropdown, Layout, TextInput },
  props: {
    filters: Object,
    courses: Array,
    locations: Object,
  },
  data() {
    return {
      form: {
        course: this.filters.course,
        client_site: this.filters.client_site,
        local_start_date: this.filters.local_start_date,
        local_end_date: this.filters.local_end_date,
        threshold: this.filters.threshold,
      },
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(this.route('reports.locations'), Object.keys(query).length ? query : { remember: 'forget' }, {
          preserveState: true,
          replace: true,
        })
      }, 300),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
