<template>
  <button class="flex items-center" :disabled="loading" @click="(e) => $emit('click', e)">
    <div v-if="loading" :class="'mr-2 ' + spinnerColor" />
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    spinnerColor: {
      type: String,
      default: 'spinner',
    },
    loading: Boolean,
  },
  emits: ['click'],
}
</script>
