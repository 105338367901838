<template>
  <layout :title="location.name">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('instructor-led-classrooms')">
        {{ $t('Instructor Led Classes') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('instructor-led-classrooms.edit', classroom)">
        {{ classroom.name }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ location.name }}
    </h1>
    <div class="flex max-w-3xl flex-wrap">
      <div class="w-full lg:w-3/5 xl:w-2/3">
        <div class="overflow-hidden rounded bg-white shadow">
          <form @submit.prevent="submit">
            <form-section-header>{{ $t('Marketplace Integration') }}</form-section-header>
            <div class="-mb-8 -mr-6 flex flex-wrap p-8">
              <div class="w-full pb-8 pr-6">
                <text-input v-model="form.store_url" :error="form.errors.store_url" :label="$t('Store URL')" />
              </div>
            </div>
            <form-section-header>{{ $t('Reviews.io Integration') }}</form-section-header>
            <div class="-mb-8 -mr-6 flex flex-wrap p-8">
              <notice v-if="!can.has_reviews_io_enabled_in_account">
                {{ $t("Your account doesn't have the reviews.io integration enabled yet.") }}
                <InertiaLink class="underline" :href="route('tenants.edit', $page.props.app.tenant)">
                  {{ $t('Click here to do so.') }}
                </InertiaLink>
              </notice>
              <div v-if="can.has_reviews_io_enabled_in_account" class="w-full">
                <div class="w-full pb-8 pr-6">
                  <select-input
                    v-model="form.reviews_io_branch_name"
                    :error="form.errors.reviews_io_branch_name"
                    :label="$t('Branch name')"
                  >
                    <option :value="null" />
                    <option v-for="(branch, index) in branches.items" :key="index" :value="branch.name">
                      {{ branch.name }}
                    </option>
                  </select-input>
                </div>
                <div class="w-full pb-8 pr-6">
                  <text-input
                    v-model="form.reviews_io_template_id"
                    :error="form.errors.reviews_io_template_id"
                    :label="$t('Template ID')"
                  />
                </div>
                <div class="w-full pb-8 pr-6">
                  <checkbox-input
                    v-model="form.reviews_io_send_automatically"
                    :error="form.errors.reviews_io_send_automatically"
                    :label="$t('Automatically request reviews when class is completed')"
                  />
                </div>
                <div v-if="form.reviews_io_send_automatically" class="w-full pb-8 pr-6">
                  <text-input
                    v-model="form.reviews_io_send_delay_minutes"
                    :error="form.errors.reviews_io_send_delay_minutes"
                    :label="$t('How many minutes of delay?')"
                  />
                </div>
              </div>
            </div>
            <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
              <button class="text-red-500 hover:underline" tabindex="-1" type="button" @click="destroy">
                {{ $t('Unlink Location') }}
              </button>
              <loading-button class="btn-blue" :loading="form.processing" type="submit">
                {{ $t('Update Location') }}
              </loading-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import CheckboxInput from '@/Shared/CheckboxInput.vue'
import FormSectionHeader from '@/Shared/FormSectionHeader.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import Notice from '@/Shared/Notice.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

const STATUSES = {
  loading: 'LOADING',
  ready: 'READY',
  failed: 'FAILED',
}

export default {
  components: {
    CheckboxInput,
    FormSectionHeader,
    Layout,
    LoadingButton,
    Notice,
    SelectInput,
    TextInput,
  },
  name: 'Edit',
  props: {
    classroom: Object,
    location: Object,
    can: Object,
  },
  data() {
    return {
      status: STATUSES.ready,
      form: useForm({
        store_url: this.location.course_location.store_url,
        reviews_io_branch_name: this.location.course_location.reviews_io_branch_name,
        reviews_io_template_id: this.location.course_location.reviews_io_template_id,
        reviews_io_send_automatically: this.location.course_location.reviews_io_send_automatically,
        reviews_io_send_delay_minutes: this.location.course_location.reviews_io_send_delay_minutes,
      }),
      branches: {
        status: STATUSES.ready,
        items: null,
      },
    }
  },
  computed: {
    sending() {
      return this.status === STATUSES.loading
    },
  },
  watch: {
    'form.reviews_io_send_automatically'(newValue) {
      if (!newValue) {
        this.form.reviews_io_send_delay_minutes = 0
      }
    },
  },
  mounted() {
    if (this.can.has_reviews_io_enabled_in_account) {
      this.getBranches()
    }
  },
  methods: {
    submit() {
      this.status = STATUSES.loading
      this.form.put(this.route('instructor-led-classrooms.locations.update', [this.classroom, this.location]), {
        onSuccess: () => (this.status = STATUSES.loading),
      })
    },
    destroy() {
      this.status = STATUSES.loading

      this.$inertia
        .delete(this.route('instructor-led-classrooms.locations.destroy', [this.classroom, this.location]))
        .finally(() => (this.status = STATUSES.ready))
    },
    getBranches() {
      this.branches.status = STATUSES.loading

      axios
        .get(this.route('course_locations.reviews-io-branches.index'))
        .then((response) => {
          this.branches.status = STATUSES.ready
          this.branches.items = response.data
        })
        .catch(() => (this.branches.status = STATUSES.failed))
    },
  },
}
</script>
