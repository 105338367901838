<template>
  <div class="mb-4 mt-8 grid w-1/3 gap-8 rounded-2xl bg-[#f8eff2] p-6">
    <div class="w-1/2 font-semibold">{{ $t('Upcoming Invoice') }}</div>
    <div class="flex items-center justify-between">
      <div>
        <div class="text-sm font-semibold">{{ $t('Subscription Plan') }}</div>
        <div class="mt-1 text-xs text-[#3E484F]">{{ upcomingInvoice.title }}</div>
      </div>
      <div>
        <div class="text-sm font-semibold">{{ $t('Billing Period') }}</div>
        <div class="mt-1 flex text-xs text-[#3E484F]">
          <show-date :timestamp="upcomingInvoice.period_start" />
          <span class="px-1">-</span>
          <show-date :timestamp="upcomingInvoice.period_end" />
        </div>
      </div>
    </div>
    <div class="flex items-center">
      <div class="pr-3">
        <img
          alt="`${paymentMethod.card.brand} card icon`"
          class="w-10"
          :src="`/images/${paymentMethod.card.brand}-icon.svg`"
        />
      </div>
      <div class="flex w-full items-center justify-between">
        <div class="text-xs text-[#3E484F]">
          <div class="font-semibold">{{ $t('Card ending in :last4', { last4: paymentMethod.card.last4 }) }}</div>
          <div class="mt-1">
            {{
              $t('Expires: :exp_month/:exp_year', {
                exp_month: String(paymentMethod.card.exp_month).padStart(2, '0'),
                exp_year: paymentMethod.card.exp_year,
              })
            }}
          </div>
        </div>
        <div class="text-right">
          <div class="text-xs text-[#3E484F]">{{ $t('Amount Due') }}</div>
          <div class="text-lg font-semibold text-qualify-red-500">{{ upcomingInvoice.amount_due }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ShowDate from '@/Shared/ShowDate.vue'

export default {
  components: {
    ShowDate,
  },
  props: {
    upcomingInvoice: Object,
    paymentMethod: Object,
  },
}
</script>
