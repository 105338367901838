<template>
  <a
    v-if="$page.props.app.tenant.email"
    class="group relative -ml-3 flex items-center whitespace-nowrap text-lg text-ts-gray-text-bold hover:text-white focus:text-white"
    :class="{
      'hover:bg-qualify-red-500 focus:bg-qualify-red-500': isTenantWorksite,
      'hover:bg-blue-600 focus:bg-blue-600': !isTenantWorksite,
    }"
    :href="`mailto:${$page.props.app.tenant.email}`"
  >
    <div class="ml-10 flex items-center py-2">
      <icon class="mr-3 h-5 w-5" color="currentColor" name="email" stroke-width="2" />
      <span>
        {{ $t('Send an Email') }}
      </span>
    </div>
  </a>
</template>
<script>
import Icon from './Icon.vue'
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  components: {
    Icon,
  },
  computed: {
    isTenantWorksite,
  },
}
</script>
