<template>
  <layout title="Subscribe">
    <div class="flex flex-col xl:flex-row xl:justify-between">
      <div class="sm:no-flex mb-8 flex w-full items-center justify-between">
        <breadcrumb name="Subscribe" />
      </div>
    </div>
    <div class="space-y-8">
      <section class="w-full space-y-8">
        <div class="w-full max-w-lg overflow-hidden rounded-lg border bg-white shadow">
          <div class="flex flex-col p-4">
            <h2 class="mb-5 mt-0 text-xl">{{ subscriptionPlan.title }}</h2>
            <p class="mb-1 text-darkGray-800">{{ subscriptionPlan.description }}</p>
          </div>
        </div>
      </section>

      <section class="w-full max-w-lg overflow-hidden rounded-lg border bg-white shadow">
        <div class="p-4">
          <div class="w-full pb-8 pr-6 lg:w-2/3">
            <front-select-input
              v-model="form.paymentMethod"
              :error="form.errors.paymentMethod"
              :label="$t('Payment Method')"
            >
              <option disabled selected value="">{{ $t('Select your payment method') }}</option>
              <option v-for="paymentMethod in paymentMethods" :key="paymentMethod.id" :value="paymentMethod.id">
                <span>{{ `XXXX XXXXX XXXX ${paymentMethod.card.last4}` }}</span>
                <span>{{ `(${paymentMethod.card.brand})` }}</span>
              </option>
            </front-select-input>
            <button class="link" @click="showAddPaymentMethodModal">{{ $t('Add Payment method') }}</button>
          </div>
        </div>

        <div class="flex items-center justify-end bg-gray-100 p-4">
          <button class="btn-red-outline" :disabled="!form.paymentMethod" type="button" @click="subscribe">
            {{ $t('Subscribe') }}
          </button>
        </div>
      </section>

      <add-payment-method-modal :show="isAddPaymentMethodModal" @close="hideAddPaymentMethodModal" />
    </div>
  </layout>
</template>

<script>
import AddPaymentMethodModal from '../PaymentMethods/AddPaymentMethodModal.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontSelectInput from '@/Shared/FrontSelectInput.vue'
import Layout from '@/Shared/Layout.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: { AddPaymentMethodModal, Breadcrumb, FrontSelectInput, Layout },
  props: {
    organization: Object,
    subscriptionPlan: Object,
    paymentMethods: Array,
    defaultPaymentMethod: Object,
  },
  data() {
    return {
      isAddPaymentMethodModal: false,
      form: useForm({
        paymentMethod: this.defaultPaymentMethod?.id,
      }),
    }
  },
  computed: {
    paymentMethodOptions() {
      const paymentMethodsObject = {}
      this.paymentMethods.forEach((paymentMethod) => {
        paymentMethodsObject[paymentMethod.id] =
          `XXXX XXXXX XXXX ${paymentMethod.card.last4} (${paymentMethod.card.brand})`
      })

      return paymentMethodsObject
    },
  },
  methods: {
    subscribe() {
      this.form.post(
        this.route('organizations.subscription-plans.store', [this.organization.id, this.subscriptionPlan.id])
      )
    },
    showAddPaymentMethodModal() {
      this.isAddPaymentMethodModal = true
    },
    hideAddPaymentMethodModal() {
      this.isAddPaymentMethodModal = false
    },
  },
}
</script>
