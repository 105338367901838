<template>
  <layout :title="$t('Create Session')">
    <div v-if="form.errors.overlap_error" class="mb-8">
      <div class="flex max-w-lg items-center rounded bg-red-500 p-4">
        <icon class="mr-2 h-4 w-4 flex-shrink-0 fill-red-100" name="exclamation-circle" />
        <div class="w-full text-sm font-medium text-red-100">
          <span v-html="form.errors.overlap_error" />
        </div>
      </div>
    </div>
    <div class="mb-8">
      <breadcrumb
        :middle-name="instructor_led_classroom.name"
        :middle-url="route('classes.show', class_.id)"
        :name="$t('Create Session')"
        :previous-name="$t('Classes')"
        :previous-url="route('classes')"
      />
    </div>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <date-input v-model="form.date" :error="form.errors.date" :label="$t('Date')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/4">
            <time-input v-model="form.start_time" :error="form.errors.start_time" :label="$t('Start time')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/4">
            <time-input v-model="form.end_time" :error="form.errors.end_time" :label="$t('End time')" />
          </div>
          <div v-if="class_.is_webinar" class="w-full pb-8 pr-6">
            <text-input v-model="form.webinar_url" :error="form.errors.webinar_url" :label="$t('Webinar URL')" />
          </div>
          <div v-if="class_.is_webinar" class="w-full pb-8 pr-6 lg:w-1/2">
            <radio-input
              v-model="form.webinar_notify_3_days_before"
              :error="form.errors.webinar_notify_3_days_before"
              :label="$t('Send a reminder to users 3 days in advance')"
              type="radio"
            />
          </div>
          <div v-if="class_.is_webinar" class="w-full pb-8 pr-6 lg:w-1/2">
            <radio-input
              v-model="form.webinar_notify_30_mins_before"
              :error="form.errors.webinar_notify_30_mins_before"
              :label="$t('Send a reminder to users 30 mins in advance')"
              type="radio"
            />
          </div>
          <div class="w-full pb-8 pr-6">
            <label class="form-label">{{ $t('Instructors:') }}</label>
            <div class="flex flex-wrap rounded border bg-white px-4 pb-1 pt-4 text-sm">
              <div v-for="instructor in instructors" :key="instructor.id" class="mb-3 w-1/3">
                <label class="inline-flex select-none">
                  <input v-model="form.instructors" type="checkbox" :value="instructor.id" />
                  <span class="ml-1">
                    {{ instructor.first_name }} {{ instructor.last_name }}
                    <span v-if="instructor.instructor_location_distance" class="ml-1 text-xs text-gray-600">
                      ({{ instructor.instructor_location_distance }})
                    </span>
                  </span>
                  <InertiaLink
                    v-if="!getAvailability(instructor)"
                    class="ml-1 flex items-center text-gray-700 hover:underline"
                    :href="route('users.availability', instructor.id)"
                    target="_blank"
                  >
                    ({{ $t('Unavailable') }})
                  </InertiaLink>
                </label>
                <div v-if="vacations && vacations[instructor.id]" class="rounded bg-yellow-200 px-2">
                  <div v-for="(vacation, index) in vacations[instructor.id]" :key="`${instructor.id}_${index}`">
                    <span>{{ vacation['type'] }}</span>
                    <span class="mx-1">:</span>
                    <span>{{ vacation['hours'] }} hours</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="form.errors.instructors" class="form-error">
              {{ form.errors.instructors }}
            </div>
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Create Session') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import DateInput from '@/Shared/DateInput.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import RadioInput from '@/Shared/RadioInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import TimeInput from '@/Shared/TimeInput.vue'
import Http from '@/Utils/Http'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    DateInput,
    Icon,
    Layout,
    LoadingButton,
    RadioInput,
    TextInput,
    TimeInput,
  },
  props: {
    class_: Object,
    instructors: Array,
    instructor_led_classroom: Object,
  },
  data() {
    return {
      form: useForm(
        {
          date: null,
          start_time: '8:30am',
          end_time: '4:00pm',
          instructors: [],
          webinar_url: null,
          webinar_notify_3_days_before: false,
          webinar_notify_30_mins_before: false,
        },
        'SessionCreate'
      ),
      vacations: null,
      availabilities: null,
    }
  },
  watch: {
    'form.date': function (newValue) {
      if (newValue) {
        this.getVacationData()
        this.getAvailabilityData()
        return
      }
      this.vacations = null
      return
    },
    'form.start_time': function () {
      this.getAvailabilityData()
    },
    'form.end_time': function () {
      this.getAvailabilityData()
    },
  },
  methods: {
    submit() {
      this.form.post(this.route('sessions.store', this.class_.id))
    },
    getVacationData() {
      Http.get(this.route('worksite-users.vacations'), {
        params: { class: this.class_.id, date: this.form.date },
      }).then((response) => {
        this.vacations = response.data
      })
    },
    getAvailabilityData() {
      if (!this.form.date || !this.form.start_time || !this.form.end_time) return

      Http.get(this.route('worksite-users.availabilities'), {
        params: {
          class: this.class_.id,
          date: this.form.date,
          start_time: this.form.start_time,
          end_time: this.form.end_time,
        },
      }).then((response) => {
        this.availabilities = response.data
      })
    },
    getAvailability(instructor) {
      if (!this.availabilities) return true

      return this.availabilities[instructor.id]
    },
  },
}
</script>
