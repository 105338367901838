<template>
  <div class="w-full">
    <div class="divide-y divide-gray-900/10">
      <Disclosure v-slot="{ open }" as="div" class="items-center pt-6" :default-open="true">
        <div class="flex items-center justify-start">
          <DisclosureButton class="flex items-center text-gray-900">
            <span class="mr-2 mt-1 flex h-6 items-center">
              <ChevronRightIcon
                aria-hidden="true"
                :class="!open ? 'h-6 w-6 transition' : 'h-6 w-6 rotate-90 transition'"
              />
            </span>
          </DisclosureButton>
          <div class="w-full">
            <front-text-input
              v-model="pageForm.title"
              class="placeholder-shown:border-0 placeholder-shown:bg-transparent placeholder-shown:text-xl"
              :placeholder="$t('Untitled Page')"
            />
          </div>
          <Popover v-if="open" class="relative">
            <PopoverButton class="inline-flex items-center gap-x-1 text-sm text-gray-600">
              <EllipsisVerticalIcon aria-hidden="true" class="h-8 w-8 text-gray-400" />
            </PopoverButton>

            <transition
              enter-active-class="transition ease-out duration-200"
              enter-from-class="opacity-0 translate-y-1"
              enter-to-class="opacity-100 translate-y-0"
              leave-active-class="transition ease-in duration-150"
              leave-from-class="opacity-100 translate-y-0"
              leave-to-class="opacity-0 translate-y-1"
            >
              <PopoverPanel class="max-w-min absolute left-1/2 z-30 mt-5 flex -translate-x-1/2">
                <div class="w-28 shrink rounded bg-white text-sm text-gray-600 shadow-lg ring-1 ring-gray-900/5">
                  <a class="block cursor-pointer p-4 pb-2 hover:bg-gray-100" @click="onMovePage">
                    {{ $t('Move page') }}
                  </a>
                  <a class="block cursor-pointer p-4 pt-2 hover:bg-gray-100" @click="onDelete">
                    {{ $t('Delete page') }}
                  </a>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>
        </div>
        <DisclosurePanel as="dd">
          <div class="mt-3">
            <div>
              <div
                class="flex flex-row overflow-hidden rounded-t-xl border border-ts-front-border-light bg-gray-50 text-sm text-gray-500"
              >
                <p class="flex-1 border-r px-2 py-2 sm:px-4">{{ $t('Question') }}</p>
                <p class="basis-96 px-2 py-2 sm:px-4">{{ $t('Type of response') }}</p>
              </div>

              <div>
                <slot />
              </div>
            </div>
          </div>
          <div class="mt-5 max-w-lg">
            <button
              class="mr-4 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold leading-4 text-qualify-red-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              @click="onAddQuestion()"
            >
              <PlusIcon aria-hidden="true" class="mr-2 h-4 w-4 text-qualify-red-500" />
              {{ $t('Add question') }}
            </button>
            <button
              class="mr-4 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold leading-4 text-qualify-red-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              @click="onAddSection()"
            >
              <QueueListIcon aria-hidden="true" class="mr-2 h-4 w-4 text-qualify-red-500" />
              {{ $t('Add section') }}
            </button>
          </div>

          <div class="mt-5 max-w-lg">
            <p class="cursor-pointer text-sm text-gray-500 underline" @click="onAddPage">{{ $t('Add page') }}</p>
          </div>
        </DisclosurePanel>
      </Disclosure>
    </div>
  </div>
</template>
<script>
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import { Disclosure, DisclosureButton, DisclosurePanel, Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { EllipsisVerticalIcon, QueueListIcon } from '@heroicons/vue/20/solid'
import { ChevronRightIcon, PlusIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronRightIcon,
    EllipsisVerticalIcon,
    Popover,
    PopoverButton,
    PopoverPanel,
    PlusIcon,
    QueueListIcon,
    FrontTextInput,
  },
  inject: ['addPage', 'addSection', 'addQuestion', 'updateFormContent', 'deleteFormContent', 'showMovePageModal'],
  props: {
    formContent: Object,
  },
  data() {
    return {
      pageForm: {
        title: this.formContent.title,
      },
    }
  },
  watch: {
    pageForm: {
      handler: function () {
        this.update()
      },
      deep: true,
    },
  },
  methods: {
    update() {
      this.updateFormContent(this.formContent, this.pageForm)
    },
    onDelete() {
      this.deleteFormContent(this.formContent)
    },
    onMovePage() {
      this.showMovePageModal()
    },
    onAddPage() {
      this.addPage(this.uuid)
    },
    onAddQuestion() {
      this.addQuestion(this.formContent.children)
    },
    onAddSection() {
      this.addSection(this.formContent.children)
    },
  },
}
</script>
