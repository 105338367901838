<template>
  <div class="flex min-h-screen justify-center bg-white">
    <DynamicHead :title="$t('Register | :app_name', { app_name: $page.props.app.name })" />
    <div class="px-4 pt-20 lg:w-1/2 lg:pl-20 lg:pr-8">
      <div class="ml-auto max-w-md">
        <worksite-dark class="mx-auto sm:mx-0" />
        <h2 class="mb-8 mt-20 text-2xl leading-9 text-gray-900">{{ $t('Register') }}</h2>
        <form @submit.prevent="submit">
          <div class="flex w-full flex-wrap py-3 pr-6">
            <div
              v-if="$page.props.errors['token'] && showFlash"
              class="mb-4 flex w-full items-center justify-between rounded bg-red-400"
            >
              <div class="flex items-center">
                <icon class="ml-4 mr-2 h-4 w-4 flex-shrink-0 fill-white" name="close-outline" />
                <div class="py-4 text-sm font-medium text-white">
                  <span>{{ $page.props.errors['token'] }}</span>
                </div>
              </div>
              <button class="group mr-2 p-2" type="button" @click="showFlash = false">
                <icon class="block h-3 w-3 fill-red-300 group-hover:fill-red-100" name="close" />
              </button>
            </div>

            <div class="w-full py-3 pr-3 md:w-1/2">
              <label class="mb-2 block text-lg text-darkGray-700" for="first_name">{{ $t('First Name') }}</label>
              <input
                id="first_name"
                v-model="form.first_name"
                class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                required
                type="text"
              />
              <div v-if="form.errors.first_name" class="form-error">{{ form.errors.first_name }}</div>
            </div>

            <div class="w-full py-3 pr-3 md:w-1/2">
              <label class="mb-2 block text-lg text-darkGray-700" for="last_name">{{ $t('Last Name') }}</label>
              <input
                id="last_name"
                v-model="form.last_name"
                class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                required
                type="text"
              />
              <div v-if="form.errors.last_name" class="form-error">{{ form.errors.last_name }}</div>
            </div>

            <div class="w-full py-3 pr-3">
              <label class="mb-2 block text-lg text-darkGray-700" for="email">{{ $t('Email') }}</label>
              <input
                id="email"
                v-model="form.email"
                class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                type="email"
              />
              <div v-if="form.errors.email" class="form-error">{{ form.errors.email }}</div>
            </div>

            <div class="w-full py-3 pr-3">
              <label class="mb-2 block text-lg text-darkGray-700" for="username">{{ $t('Username') }}</label>
              <input
                id="username"
                v-model="form.username"
                class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                type="text"
              />
              <div v-if="form.errors.username" class="form-error">{{ form.errors.username }}</div>
            </div>

            <div class="w-full py-3 pr-3">
              <label class="mb-2 block text-lg text-darkGray-700" for="access_code">
                {{ $t('Access Code (Optional)') }}
              </label>
              <input
                id="access_code"
                v-model="form.access_code"
                class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                type="text"
              />
              <div v-if="form.errors.access_code" class="form-error">{{ form.errors.access_code }}</div>
            </div>

            <div class="w-full py-3 pr-3 md:w-1/2">
              <label class="mb-2 block text-lg text-darkGray-700" for="password">{{ $t('Password') }}</label>
              <input
                id="password"
                v-model="form.password"
                class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                required
                type="password"
              />
              <div v-if="form.errors.password" class="form-error">{{ form.errors.password }}</div>
            </div>
            <div class="w-full py-3 pr-3 md:w-1/2">
              <label class="mb-2 block text-lg text-darkGray-700" for="password_confirmation">
                {{ $t('Confirm Password') }}
              </label>
              <input
                id="password_confirmation"
                v-model="form.password_confirmation"
                class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                required
                type="password"
              />
            </div>
          </div>

          <front-loading-button class="mt-6" :loading="form.processing" type="submit">
            {{ $t('Create Account') }}
          </front-loading-button>
        </form>

        <InertiaLink
          class="hover-animate text-darkGray mt-10 block text-lg hover:no-underline"
          :href="route('front.login')"
        >
          Sign in
          <icon class="currentColor inline h-5 w-5 transform transition duration-500" name="chevron-right" />
        </InertiaLink>
        <div class="py-10">
          <span class="text-darkGray-700 opacity-65">{{ $t('Powered By') }}</span>
          <qualify-dark class="ml-2 inline opacity-25" />
        </div>
      </div>
    </div>
    <div
      class="hidden items-center justify-center bg-cover lg:flex lg:w-1/2"
      style="background-image: url('/images/Qualify_LOGIN_BG.svg')"
    >
      <img alt="$t('Login')" class="w-3/4" src="/images/login.svg" />
    </div>
  </div>
</template>

<script>
import DynamicHead from '@/Shared/DynamicHead.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import Icon from '@/Shared/Icon.vue'
import QualifyDark from '@/Shared/Logo/QualifyDark.vue'
import WorksiteDark from '@/Shared/Logo/WorksiteDark.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    DynamicHead,
    FrontLoadingButton,
    Icon,
    QualifyDark,
    WorksiteDark,
  },
  props: {
    timezones: Object,
  },
  data() {
    return {
      form: useForm({
        first_name: '',
        last_name: '',
        email: '',
        username: '',
        access_code: '',
        password: '',
        password_confirmation: '',
        timezone: this.getTimezone(),
      }),
    }
  },
  watch: {
    '$page.props.flash': {
      handler() {
        this.showFlash = true
      },
      deep: true,
    },
  },
  methods: {
    submit() {
      this.form.post(this.route('register'))
    },
    getTimezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    },
  },
}
</script>

<style scoped>
.hover-animate:hover > svg {
  --transform-translate-x: 0.5rem;
}
</style>
