<template>
  <layout :title="$t('Edit Folder')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('organizations.edit', organization)">
        {{ $t('Organizations') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Edit Folder') }}
    </h1>
    <deleted-message v-if="folder.deleted_at" class="mb-6">
      {{ $t('This folder has been deleted.') }}
    </deleted-message>
    <div class="max-w-xl overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" />
          </div>
        </div>
        <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
          <div>
            <button
              v-if="folder.deleted_at"
              class="text-red-500 hover:underline"
              tabindex="-1"
              type="button"
              @click="restore"
            >
              {{ $t('Restore') }}
            </button>
            <button v-else class="text-red-500 hover:underline" tabindex="-1" type="button" @click="destroy">
              {{ $t('Delete') }}
            </button>
          </div>
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Update Select') }}
          </loading-button>
        </div>
      </form>
    </div>
    <div class="mt-12 flex max-w-xl items-end justify-between">
      <h2 class="text-lg">{{ $t('Files') }}</h2>
      <InertiaLink
        v-if="!folder.deleted_at"
        class="btn-blue"
        :href="route('organizations.folders.files.create', [organization.id, folder.id])"
      >
        <span>{{ $t('Add') }}</span>
        <span class="hidden md:inline">{{ $t('File') }}</span>
      </InertiaLink>
    </div>
    <div class="mt-4 max-w-xl overflow-x-auto rounded bg-white shadow">
      <table class="w-full">
        <thead>
          <tr>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Name') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Uploaded') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="files.length === 0">
            <td class="border-t px-6 py-4" colspan="3">{{ $t('No files found.') }}</td>
          </tr>
          <tr
            v-for="file in files"
            :key="file.id"
            class="hover:bg-gray-100 focus:bg-gray-100"
            @click="$inertia.visit(route('organizations.folders.files.edit', [organization.id, folder.id, file.id]))"
          >
            <td class="cursor-pointer whitespace-nowrap border-t px-6 py-4">
              <div class="flex items-center">
                {{ file.name }}
                <deleted-badge v-if="file.deleted_at" />
              </div>
            </td>
            <td class="flex cursor-pointer items-center whitespace-nowrap border-t px-6 py-4">
              {{ file.created_at }}
            </td>
            <td class="w-min cursor-pointer border-t px-4 align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </layout>
</template>

<script>
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import DeletedMessage from '@/Shared/DeletedMessage.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    DeletedBadge,
    DeletedMessage,
    Icon,
    Layout,
    LoadingButton,
    TextInput,
  },
  props: {
    organization: Object,
    folder: Object,
    files: Object,
  },
  data() {
    return {
      form: useForm({
        name: this.folder.name,
      }),
    }
  },
  methods: {
    submit() {
      this.form.put(this.route('organizations.folders.update', [this.organization, this.folder]))
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this folder?'))) {
        this.$inertia.delete(this.route('organizations.folders.destroy', [this.organization, this.folder]))
      }
    },
    restore() {
      if (confirm(this.$t('Are you sure you want to restore this folder?'))) {
        this.$inertia.put(this.route('organizations.folders.restore', [this.organization, this.folder]))
      }
    },
  },
}
</script>

<style scoped>
.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
}
</style>
