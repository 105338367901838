<template>
  <front-layout :title="$t('Manuals')">
    <div class="flex flex-col xl:flex-row xl:justify-between">
      <div class="sm:no-flex flex w-full items-center justify-between pb-8">
        <breadcrumb :name="$t('Manuals')" />
      </div>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow-lg">
      <table class="front-table w-full">
        <thead>
          <tr>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
              {{ $t('Name') }}
            </th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">{{ $t('Sections') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="manuals.data.length === 0">
            <td class="border-t px-6 py-4" colspan="5">{{ $t('No results found.') }}</td>
          </tr>
          <tr v-for="manual in manuals.data" :key="manual.id" @click="visitManual(manual)">
            <td class="font-semibold text-ts-gray-text-bold">
              <front-truncate-text class="max-w-md" :text="manual.name" />
            </td>
            <td class="font-semibold text-ts-gray-text-bold">{{ manual.sections_count }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontTruncateText from '@/Shared/FrontTruncateText.vue'

export default {
  components: {
    Breadcrumb,
    FrontLayout,
    FrontTruncateText,
  },
  props: {
    manuals: Object,
  },
  methods: {
    visitManual(manual) {
      if (manual.sections_count > 0) {
        this.$inertia.visit(this.route('front.manuals.show', manual.id))
      }
    },
  },
}
</script>
