<template>
  <layout :title="$t('Edit: Multiple Choice')">
    <div>
      <breadcrumb-admin :path="breadcrumbs" />
      <deleted-message v-if="select.deleted_at" />
      <div class="max-w-lg overflow-hidden rounded bg-white shadow">
        <form @submit.prevent="submit">
          <div class="bg-slate-400 px-4 py-2 text-sm sm:px-6">
            <h3 class="p-3 text-lg font-semibold text-white">{{ $t('Responses') }}</h3>
          </div>
          <div class="relative bg-white">
            <div class="inline-flex w-full items-center">
              <Draggable class="list-group w-full" handle=".handle" :list="form.choices" tag="ul">
                <li
                  v-for="(element, index) in form.choices"
                  :key="index"
                  class="flex items-center border-b border-t py-1"
                >
                  <div class="flex-1">
                    <div class="flex w-full items-center">
                      <Bars3Icon
                        aria-hidden="true"
                        class="handle z-20 mx-3 flex h-6 w-6 flex-none cursor-move items-center justify-center text-gray-400"
                      />
                      <div
                        class="flex w-full items-center justify-between"
                        @click.prevent="element.focus = !element.focus"
                      >
                        <div class="flex-1">
                          <front-text-input
                            v-model="element.name"
                            class="placeholder-shown:text-bold placeholder-shown:text-m m-0 mt-0 w-full border-0 bg-white ring-0 ring-inset placeholder-shown:border-0 placeholder-shown:ring-0 focus:outline-none focus:ring-2 focus:ring-qualify-red-600"
                            :error="form.errors[`choices.${index}.name`]"
                            :placeholder="$t('Response :num', { num: index + 1 })"
                          />
                        </div>

                        <form-dropdown class="mr-3">
                          <template #selected>
                            <div class="mr-3 h-6 w-6 rounded-full" :style="`background:${element.color}`" />
                            <p v-if="form.errors[`choices.${index}.color`]">
                              {{ form.errors[`choices.${index}.name`] }}
                            </p>
                          </template>
                          <template #options>
                            <div class="w-56 p-2">
                              <color-picker v-model="element.color" />
                            </div>
                          </template>
                        </form-dropdown>
                      </div>
                    </div>
                    <div class="flex items-center justify-end pr-3">
                      <div class="text-sm">
                        <front-checkbox-input
                          v-if="element.focus"
                          v-model="element.is_flagged"
                          :error="form.errors[`choices.${index}.is_flagged`]"
                          :label="$t('Mark as flagged')"
                        />
                        <p v-if="element.is_flagged && !element.focus" class="p-2">{{ $t('Marked as flagged') }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="h-full">
                    <button
                      class="stretch h-full w-8 cursor-pointer text-gray-500"
                      type="button"
                      @click.prevent="deleteOption(element)"
                    >
                      <TrashIcon aria-hidden="true" class="h-4 w-4" />
                    </button>
                  </div>
                </li>
              </Draggable>
            </div>
          </div>
          <div class="px-4 py-5 text-sm sm:px-6">
            <button class="inline-flex items-center" type="button  " @click.prevent="addOption">
              <PlusIcon aria-hidden="true" class="mr-1 h-3 w-3" />
              <p>{{ $t('Add Response') }}</p>
            </button>
          </div>
          <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
            <button v-if="!select.deleted_at" class="text-red-500" type="button" @click.prevent="destroy">
              {{ $t('Delete') }}
            </button>
            <button v-else class="text-red-500" type="button" @click.prevent="restore">
              {{ $t('Restore') }}
            </button>
            <loading-button class="btn-blue" :loading="form.processing" type="submit">
              {{ $t('Save') }}
            </loading-button>
          </div>
        </form>
      </div>
    </div>
  </layout>
</template>
<script>
import FormDropdown from '../Front/Admin/FormTemplates/Components/Questionables/FormDropdown.vue'
import BreadcrumbAdmin from '@/Shared/BreadcrumbAdmin.vue'
import ColorPicker from '@/Shared/ColorPicker.vue'
import DeletedMessage from '@/Shared/DeletedMessage.vue'
import FrontCheckboxInput from '@/Shared/FrontCheckboxInput.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import { Bars3Icon, TrashIcon } from '@heroicons/vue/20/solid'
import { useForm } from '@inertiajs/vue3'
import { VueDraggableNext as Draggable } from 'vue-draggable-next'

export default {
  components: {
    FormDropdown,
    Draggable,
    Bars3Icon,
    TrashIcon,
    BreadcrumbAdmin,
    ColorPicker,
    DeletedMessage,
    FrontCheckboxInput,
    FrontTextInput,
    Layout,
    LoadingButton,
  },
  props: {
    select: Object,
    organization: Object,
  },
  data() {
    return {
      form: useForm({
        id: this.select.id,
        scope_level: this.select.scope_level,
        choices: [...this.select.choices],
      }),
    }
  },
  computed: {
    breadcrumbs() {
      const multipleChoiceName = this.form.choices.map((choice) => choice.name).join(', ') ?? 'Edit'

      return [
        {
          name: this.$t('Multiple Choices'),
          url: this.route('multiple-choices.index'),
        },
        {
          name: '(' + multipleChoiceName + ')',
        },
      ]
    },
  },
  methods: {
    addOption() {
      this.form.choices.push({
        text: null,
        color: '#A8A8A8',
        is_flagged: false,
      })
    },
    deleteOption(option) {
      this.form.choices = this.form.choices.filter((element) => element.id != option.id)
    },
    submit() {
      if (
        confirm(
          this.$t(
            'Are you sure you want to update this multiple choice? This change will only be reflected on future form submissions.'
          )
        )
      ) {
        this.form.put(this.route('multiple-choices.update', [this.select]))
      }
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this multiple choice?'))) {
        this.$inertia.delete(this.route('multiple-choices.destroy', [this.select]))
      }
    },
    restore() {
      if (confirm(this.$t('Are you sure you want to restore this multiple choice?'))) {
        this.$inertia.put(this.route('multiple-choices.restore', [this.select]))
      }
    },
  },
}
</script>
