<template>
  <div class="p-8">
    <app-head title="Organization Certificate Settings" />
    <!-- Training Records -->
    <div>
      <div v-if="!trainingRecordsEnabled">
        <p>Training records are disabled for this organization</p>
      </div>
      <div v-else>
        <div class="mt-12 flex items-end justify-between">
          <h2 class="text-lg">{{ $t('Training Record Templates') }}</h2>
          <InertiaLink class="btn-blue" :href="route('training-record-templates.create', activeOrganization.id)">
            <span>{{ $t('Create') }}</span>
            <span class="hidden md:inline">{{ $t('Template') }}</span>
          </InertiaLink>
        </div>
        <div v-if="trainingRecordsEnabled" class="mt-4 overflow-hidden rounded bg-white shadow">
          <SimpleTable>
            <template #head>
              <TableHead>{{ $t('Name') }}</TableHead>
              <TableHead>{{ $t('Years Valid') }}</TableHead>
              <TableHead>{{ $t('Earned') }}</TableHead>
              <TableHead />
            </template>
            <TableRow v-if="trainingRecords.data.length === 0">
              <TableData colspan="4">{{ $t('No training record templates found.') }}</TableData>
            </TableRow>
            <TableRow
              v-for="trainingRecord in trainingRecords.data"
              :key="trainingRecord.id"
              :clickable="true"
              @click="$inertia.visit(route('training-record-templates.edit', trainingRecord))"
            >
              <TableData>{{ trainingRecord.name }}</TableData>
              <TableData>
                {{ trainingRecord.years_valid ? trainingRecord.years_valid : '-' }}
              </TableData>
              <TableData>
                {{ trainingRecord.training_records_count }}
              </TableData>
              <TableData class="w-min align-middle">
                <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
              </TableData>
            </TableRow>
          </SimpleTable>
        </div>
        <pagination :links="trainingRecords.links" :preserve-scroll="true" />
      </div>
    </div>
    <!-- Certificate Templates-->
    <div>
      <div class="mt-12 flex items-end justify-between">
        <h2 class="text-lg">{{ $t('Certificates Settings') }}</h2>
        <InertiaLink
          class="btn-blue"
          :href="route('organizations.certificate-templates.create', activeOrganization.id)"
        >
          <span>{{ $t('Add') }}</span>
          <span class="hidden md:inline">{{ $t('Certificate') }}</span>
        </InertiaLink>
      </div>
      <div class="mt-4 overflow-x-auto rounded bg-white shadow">
        <SimpleTable class="w-full">
          <template #head>
            <TableHead>{{ $t('Name') }}</TableHead>
            <TableHead>{{ $t('Years Valid') }}</TableHead>
            <TableHead>{{ $t('Earned') }}</TableHead>
            <TableHead />
          </template>
          <TableRow v-if="certificateTemplates.data.length === 0">
            <TableData colspan="4">{{ $t('No certificates found.') }}</TableData>
          </TableRow>
          <TableRow
            v-for="certificateTemplate in certificateTemplates.data"
            :key="certificateTemplate.id"
            :clickable="true"
            @click="
              $inertia.visit(
                route('organizations.certificate-templates.edit', [activeOrganization.id, certificateTemplate.id])
              )
            "
          >
            <TableData>
              {{ certificateTemplate.name }}
            </TableData>
            <TableData>
              {{ certificateTemplate.years_valid ? certificateTemplate.years_valid : '-' }}
            </TableData>
            <TableData>
              {{ certificateTemplate.certificates_count }}
            </TableData>
            <TableData class="w-min align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </TableData>
          </TableRow>
        </SimpleTable>
      </div>
      <pagination :links="certificateTemplates.links" :preserve-scroll="true" />
    </div>
  </div>
</template>

<script>
import OrganizationEditLayout from './OrganizationEditLayout.vue'
import AppHead from '@/Shared/AppHead.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'

export default {
  components: {
    AppHead,
    Icon,
    Pagination,
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
  },
  layout: [Layout, OrganizationEditLayout],
  props: {
    trainingRecordsEnabled: Boolean,
    trainingRecords: Object,
    certificateTemplates: Object,
  },
}
</script>
<script setup>
import { activeOrganization } from '@/Stores/ActiveOrganization'
</script>
