<template>
  <front-layout :title="$t('Reports')">
    <div class="mb-8">
      <breadcrumb :name="$t('Reports')" />
    </div>

    <div class="w-full overflow-hidden rounded-lg border border-ts-gray-500 bg-ts-gray-100">
      <tab-layout :menu-list="menuList">
        <div class="flex flex-wrap bg-ts-gray-100 p-6 lg:p-10">
          <slot />
        </div>
      </tab-layout>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import TabLayout from '@/Shared/TabLayout.vue'

export default {
  components: {
    Breadcrumb,
    FrontLayout,
    TabLayout,
  },
  computed: {
    menuList() {
      const menuList = [
        {
          name: this.$t('Courses'),
          url: this.route('front.manager.reports.courses.show'),
          matchUrl: 'account/manager/reports/courses',
        },
        {
          name: this.$t('Certificates'),
          url: this.route('front.manager.reports.certificates.show'),
          matchUrl: 'account/manager/reports/certificates',
        },
        {
          name: this.$t('Training Records'),
          url: this.route('front.manager.reports.training-records.show'),
          matchUrl: 'account/manager/reports/training-records',
        },
        {
          name: this.$t('Access Code'),
          url: this.route('front.manager.reports.access-codes.show'),
          matchUrl: 'account/manager/reports/access-codes',
        },
      ]

      if (this.$page.props.auth.user.has_ehs_access) {
        menuList.push({
          name: this.$t('Forms'),
          url: this.route('front.manager.reports.ehs-forms.show'),
          matchUrl: 'account/manager/reports/forms',
        })
      }
      return menuList
    },
  },
}
</script>
