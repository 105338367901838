<template>
  <form @submit.prevent="(e) => $emit('submit', e)">
    <div class="flex flex-1 items-center">
      <div class="form-input flex w-full rounded p-0">
        <div class="absolute inset-y-0 left-0 z-20 flex items-center justify-center" @click="focusInput">
          <icon class="ml-3 block h-5 w-5 text-ts-gray-400" name="search" />
        </div>
        <input
          autocomplete="off"
          class="relative m-px w-full rounded-l rounded-r px-6 py-3 pl-10 leading-normal focus:z-10 focus:ring"
          name="search"
          :placeholder="placeholder"
          type="text"
          :value="searchValue"
          @input="onInput"
          @keydown="onKeyDown"
        />
        <div class="absolute inset-y-0 right-0 z-20 flex items-center justify-center" @click="reset">
          <icon class="mr-3 block h-4 w-4 text-ts-gray-400" name="close" />
        </div>
        <slot name="searchByField" />
      </div>
    </div>
  </form>
</template>

<script>
import Icon from './Icon.vue'

export default {
  components: {
    Icon,
  },
  props: {
    modelValue: String,
    maxWidth: {
      type: Number,
      default: 300,
    },
    placeholder: {
      type: String,
      default: 'Search…',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      searchValue: this.modelValue,
    }
  },
  watch: {
    searchValue(value) {
      this.$emit('update:modelValue', value)
    },
  },
  methods: {
    reset() {
      this.searchValue = null
    },
    onInput(event) {
      this.searchValue = event.target.value
    },
    onKeyDown(event) {
      if (event.key === 'Escape') {
        this.reset()
      }
    },
  },
}
</script>
