<template>
  <div class="p-8">
    <app-head title="Organization Structure" />
    <!-- Groups -->
    <div>
      <div class="mt-4 flex items-end justify-between">
        <h2 class="text-lg">{{ $t('Groups') }}</h2>
        <InertiaLink
          class="btn-blue"
          :href="route('organizations.groups.create', { organization: activeOrganization.id })"
        >
          <span>{{ $t('Create') }}</span>
          <span class="hidden md:inline">{{ $t('Group') }}</span>
        </InertiaLink>
      </div>
      <div class="mt-4 overflow-x-auto rounded bg-white shadow">
        <SimpleTable>
          <template #head>
            <TableHead>{{ $t('Name') }}</TableHead>
            <TableHead>{{ $t('Group Managers') }}</TableHead>
            <TableHead>{{ $t('Members Count') }}</TableHead>
            <TableHead />
          </template>
          <TableRow v-if="groups.data.length === 0">
            <TableData colspan="6">{{ $t('No groups found.') }}</TableData>
          </TableRow>
          <TableRow
            v-for="group in groups.data"
            :key="group.id"
            :clickable="true"
            @click="$inertia.visit(route('groups.show', group.id))"
          >
            <TableData class="space-x-1">
              <span>{{ group.name }}</span>
              <archived-badge v-if="group.archived_at" />
              <deleted-badge v-if="group.deleted_at" />
            </TableData>
            <TableData class="whitespace-nowrap">
              {{ group.managers.length > 0 ? group.managers.map(({ name }) => name).join(', ') : '--' }}
            </TableData>
            <TableData>{{ group.users_count }}</TableData>
            <TableData class="w-min align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </TableData>
          </TableRow>
        </SimpleTable>
      </div>
      <pagination :links="groups.links" :preserve-scroll="true" />
    </div>
    <!-- Locations -->
    <div>
      <div class="mt-4 flex items-end justify-between">
        <h2 class="text-lg">{{ $t('Locations') }}</h2>
        <InertiaLink class="btn-blue" :href="route('locations.create', { organization: activeOrganization.id })">
          <span>{{ $t('Create') }}</span>
          <span class="hidden md:inline">{{ $t('Location') }}</span>
        </InertiaLink>
      </div>
      <div class="mt-4 overflow-x-auto rounded bg-white shadow">
        <SimpleTable>
          <template #head>
            <TableHead>{{ $t('Name') }}</TableHead>
            <TableHead>{{ $t('Address') }}</TableHead>
            <TableHead>{{ $t('Rooms') }}</TableHead>
            <TableHead>{{ $t('Users') }}</TableHead>
            <TableHead />
          </template>
          <TableRow v-if="locations.data.length === 0">
            <TableData colspan="6">{{ $t('No locations found.') }}</TableData>
          </TableRow>
          <TableRow
            v-for="location in locations.data"
            :key="location.id"
            :clickable="true"
            @click="$inertia.visit(route('locations.edit', location.id))"
          >
            <TableData class="space-x-1">
              <span>{{ location.name }}</span>
              <archived-badge v-if="location.archived_at" />
              <deleted-badge v-if="location.deleted_at" />
            </TableData>
            <TableData class="whitespace-nowrap">{{ location.address }}, {{ location.city }}</TableData>
            <TableData>{{ location.rooms_count }}</TableData>
            <TableData>{{ location.users_count }}</TableData>
            <TableData class="w-min align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </TableData>
          </TableRow>
        </SimpleTable>
      </div>
      <pagination :links="locations.links" :preserve-scroll="true" />
    </div>
    <!-- Job profiles -->
    <div>
      <div class="mt-4 flex items-end justify-between">
        <h2 class="text-lg">{{ $t('Job Profiles') }}</h2>
        <!--
        TODO: Add create job profile button
        <InertiaLink class="btn-blue" :href="route('locations.create', { organization: activeOrganization.id })">
          <span>{{ $t('Create') }}</span>
          <span class="hidden md:inline">{{ $t('Location') }}</span>
        </InertiaLink> -->
      </div>
      <div class="mt-4 overflow-x-auto rounded bg-white shadow">
        <SimpleTable>
          <template #head>
            <TableHead>{{ $t('Name') }}</TableHead>
            <TableHead>{{ $t('Users') }}</TableHead>
            <TableHead />
          </template>
          <TableRow v-if="jobProfiles.data.length === 0">
            <TableData colspan="6">{{ $t('No job-profiles found.') }}</TableData>
          </TableRow>
          <TableRow v-for="jobProfile in jobProfiles.data" :key="jobProfile.id" :clickable="true">
            <TableData class="space-x-1">
              <span>{{ jobProfile.name }}</span>
              <archived-badge v-if="jobProfile.archived_at" />
              <deleted-badge v-if="jobProfile.deleted_at" />
            </TableData>
            <TableData>{{ jobProfile.users_count }}</TableData>
            <TableData class="w-min align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </TableData>
          </TableRow>
        </SimpleTable>
      </div>
      <pagination :links="locations.links" :preserve-scroll="true" />
    </div>
  </div>
</template>
<script>
import OrganizationEditLayout from './OrganizationEditLayout.vue'
import AppHead from '@/Shared/AppHead.vue'
import ArchivedBadge from '@/Shared/ArchivedBadge.vue'
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'

export default {
  components: {
    AppHead,
    ArchivedBadge,
    DeletedBadge,
    Icon,
    Pagination,
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
  },
  layout: [Layout, OrganizationEditLayout],
}
</script>
<script setup>
import { activeOrganization } from '@/Stores/ActiveOrganization'

defineProps({
  groups: Object,
  locations: Object,
  jobProfiles: Object,
})
</script>
