<template>
  <front-modal class="rounded text-base" :show="show" width="auto" @close="close">
    <div class="max-w-sm overflow-hidden rounded bg-white p-6 shadow md:p-10">
      <div class="mb-4 text-center">
        <h1 class="mt-4 text-3xl font-bold">{{ $t('Manual Export') }}</h1>
        <h3 class="mt-4 text-lg leading-snug">
          {{ $t('Please select what the PDF should include.') }}
        </h3>
        <front-select-input v-model="form.section" class="mt-4">
          <option :value="null">{{ $t('All Sections') }}</option>
          <option v-for="section in sections" :key="section.id" :value="section.id">
            {{ section.name }}
          </option>
        </front-select-input>
      </div>
      <div class="flex items-center justify-center bg-white px-8 pb-6 pt-4">
        <a
          class="font-bold"
          :class="isTenantWorksite ? 'btn-red-gradient' : 'tenant-btn-1'"
          :href="route('front.manuals.export', { manual: manual.id, section: form.section })"
        >
          {{ $t('Download') }}
        </a>
        <front-secondary-button class="ml-4" @click="close">
          {{ $t('Close') }}
        </front-secondary-button>
      </div>
    </div>
  </front-modal>
</template>

<script>
import FrontModal from '@/Shared/FrontModal.vue'
import FrontSecondaryButton from '@/Shared/FrontSecondaryButton.vue'
import FrontSelectInput from '@/Shared/FrontSelectInput.vue'
import { isTenantWorksite, query } from '@/Utils/Helpers'

export default {
  components: {
    FrontModal,
    FrontSecondaryButton,
    FrontSelectInput,
  },
  props: {
    show: Boolean,
    manual: Object,
    sections: Object,
  },
  data() {
    return {
      processing: false,
      form: this.$inertia.form({
        section: null,
      }),
    }
  },
  computed: {
    isTenantWorksite,
  },
  methods: {
    query,
    close() {
      this.form.reset()
      this.$emit('close:exportManualModal')
    },
  },
}
</script>
