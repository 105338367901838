<template>
  <layout :title="$t('Edit Content')">
    <div class="mb-8">
      <breadcrumb
        :name="$t('Edit Course Contents')"
        :previous-name="course.name"
        :previous-url="route('courses.edit', course.id)"
      />
    </div>
    <div class="w-auto">
      <form @submit.prevent="submit">
        <div class="">
          <table class="w-full overflow-hidden rounded bg-white shadow">
            <thead>
              <tr class="text-left">
                <th class="p-4" />
                <th class="p-4">Type</th>
                <th class="p-4">Contents</th>
                <th class="p-4">Prerequisite</th>
                <th class="p-4">Required For Completion</th>
                <th class="p-4">Excluded from progress</th>
              </tr>
            </thead>

            <Draggable v-model="form.contents" tag="tbody">
              <tr
                v-for="(content, index) in form.contents"
                :key="content.id"
                :class="content.is_archived ? 'bg-gray-100' : ''"
              >
                <td class="px-4 py-1">
                  <icon class="h-4 w-4 text-gray-400" name="menu" />
                </td>
                <td class="px-4 py-1">
                  <select-input
                    v-model="content.contentable_type"
                    class="w-40"
                    :disabled="!content.is_new"
                    :error="form.errors.contentable_type"
                    required
                    @change="getContentList(index)"
                  >
                    <option value="" />
                    <option
                      v-for="(value, key, contentTypeIndex) in contentTypes"
                      :key="contentTypeIndex"
                      :value="value"
                    >
                      {{ key }}
                    </option>
                  </select-input>
                </td>
                <td class="px-4 py-1">
                  <div v-if="content.isFetchingContents">{{ $t('Loading Contents...') }}</div>

                  <div v-if="content.contentable_type && !content.isFetchingContents">
                    <search-input
                      v-model="content.contentable"
                      class="w-40"
                      :data="content.contentList"
                      :disabled="!content.is_new"
                      :error="form.errors.contentable_id"
                      search-by="name"
                      track-by="id"
                    >
                      <div v-if="content.contentable" class="flex items-center justify-between">
                        <div class="truncate">{{ content.contentable.name }}</div>
                      </div>
                      <template #option="{ option }">
                        <div class="flex items-center justify-between">
                          <div class="truncate">{{ option.name }}</div>
                        </div>
                      </template>
                    </search-input>
                  </div>
                </td>
                <td class="px-4 py-1">
                  <select-input
                    v-if="index > 0"
                    v-model="content.prerequisite_id"
                    class="w-40"
                    :disabled="isClassContent"
                    :error="form.errors.prerequisite_id"
                  >
                    <option value="">{{ $t('None') }}</option>
                    <option v-for="value in getPreRequisite(index)" :key="value.id" :value="value.id">
                      {{ `${value.contentable_type} : ${value.name}` }}
                    </option>
                  </select-input>
                </td>
                <td class="px-4 py-1">
                  <radio-input v-model="content.is_required" class="w-40" :error="form.errors.is_required" />
                </td>
                <td class="px-4 py-1">
                  <radio-input
                    v-model="content.is_excluded_from_progress"
                    class="w-40"
                    :error="form.errors.is_excluded_from_progress"
                  />
                </td>
                <td class="px-4 py-1">
                  <div class="flex">
                    <button
                      v-if="content.is_new || !content.is_archived"
                      class="mx-2 text-red-500 hover:underline"
                      tabindex="-1"
                      type="button"
                      @click="archive(index)"
                    >
                      {{ $t('Archive') }}
                    </button>
                    <button
                      v-else
                      class="mx-2 text-red-500 hover:underline"
                      tabindex="-1"
                      type="button"
                      @click="unArchived(index)"
                    >
                      {{ $t('Restore (Archived)') }}
                    </button>

                    <button
                      v-if="content.is_new"
                      class="mx-2 text-red-500 hover:underline"
                      tabindex="-1"
                      type="button"
                      @click="destroy(index)"
                    >
                      {{ $t('Delete') }}
                    </button>
                  </div>
                </td>
              </tr>
            </Draggable>
          </table>
        </div>
        <div class="flex items-center justify-end space-x-4 border-t border-gray-100 bg-gray-100 px-8 py-4">
          <button @click="addContent">Add Content</button>
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Submit') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>
<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import RadioInput from '@/Shared/RadioInput.vue'
import SearchInput from '@/Shared/SearchInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import getContentsForType from '@/mixins/getContentsForType.js'
import { useForm } from '@inertiajs/vue3'
import { v4 as uuidv4 } from 'uuid'
import { VueDraggableNext as Draggable } from 'vue-draggable-next'

export default {
  components: {
    Draggable,
    RadioInput,
    Breadcrumb,
    Icon,
    Layout,
    LoadingButton,
    SearchInput,
    SelectInput,
  },
  mixins: [getContentsForType],
  props: {
    course: Object,
    contentTypes: Object,
  },
  data() {
    return {
      form: useForm({
        contents: this.course.contents.map((content) => ({
          ...content,
          is_new: false,
          isFetchingContents: false,
          contentList: [],
        })),
      }),
    }
  },
  computed: {
    isClassContent: function () {
      return this.form.type === 'class'
    },
    prerequisiteId: {
      get: function () {
        return this.isClassContent ? '' : this.form.prerequisite_id
      },
      set: function (value) {
        if (!this.isClassContent) {
          this.form.prerequisite_id = value
        }
      },
    },
    secondaryContent: function () {
      if (!this.form.content) return

      if (this.form.content.related_english_contentable) {
        return {
          name: this.form.content.related_english_contentable.name,
          locale: 'EN',
        }
      }

      if (this.form.content.related_french_contentable) {
        return {
          name: this.form.content.related_french_contentable.name,
          locale: 'FR',
        }
      }
      return null
    },
  },
  watch: {
    'form.type'() {
      this.form.content = null
    },
  },
  methods: {
    addContent() {
      this.form.contents.push({
        id: uuidv4(),
        is_new: true,
        contentable_type: null,
        contentable: null,
        prerequisite_id: null,
        is_required: false,
        is_excluded_from_progress: false,
        is_archived: false,
        isFetchingContents: false,
        contentList: [],
      })
    },
    getContentList(index) {
      let formContent = this.form.contents[index]
      axios
        .get(this.route('contents.index'), {
          params: {
            type: formContent.contentable_type,
          },
        })
        .then((response) => {
          formContent.isFetchingContents = false
          formContent.contentList = response.data
        })
        .catch(() => {
          formContent.contentList = []
        })
        .finally(() => (formContent.isFetchingContents = false))
    },
    getPreRequisite(index) {
      return this.form.contents.slice(0, index).map((content) => {
        return {
          id: content.id,
          name: content.contentable.name,
          contentable_type: content.contentable_type,
        }
      })
    },
    submit() {
      this.form
        .transform((data) => {
          const newData = {
            contents: data.contents.map((contentData) => ({
              id: contentData.id,
              contentable_type: contentData.contentable_type,
              contentable_id: contentData.contentable.id,
              prerequisite_id: contentData.prerequisite_id,
              is_required: contentData.is_required,
              is_excluded_from_progress: contentData.is_excluded_from_progress,
              is_archived: contentData.is_archived,
              is_new: contentData.is_new,
            })),
          }
          return newData
        })
        .put(this.route('course.contents.update', this.course))
    },
    archive(index) {
      if (confirm(this.$t('Are you sure you want to archive this course content?'))) {
        this.form.contents[index].is_archived = true
      }
    },
    unArchived(index) {
      if (confirm(this.$t('Are you sure you want to restore this archived course content?'))) {
        this.form.contents[index].is_archived = false
      }
    },
    destroy(index) {
      this.form.contents.splice(index, 1)
    },
  },
}
</script>
