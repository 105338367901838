<template>
  <div class="w-full">
    <form @submit.prevent="updatePassword">
      <div class="-mb-8 -mr-6 flex flex-wrap p-6 lg:p-10">
        <div class="w-full pb-8 pr-6 sm:w-1/2">
          <front-text-input
            v-model="passwordForm.current_password"
            :error="passwordForm.errors.current_password"
            :label="$t('Current Password')"
            type="password"
          />
        </div>
        <div class="w-full pb-8 pr-6 sm:w-1/2">
          <front-text-input
            v-model="passwordForm.password"
            :error="passwordForm.errors.password"
            :label="$t('Password')"
            type="password"
          />
        </div>
        <div class="w-full pb-8 pr-6 sm:w-1/2">
          <front-text-input
            v-model="passwordForm.password_confirmation"
            :error="passwordForm.errors.password_confirmation"
            :label="$t('Confirm New Password')"
            type="password"
          />
        </div>
      </div>
      <div class="flex items-center justify-between border-t border-gray-200 px-6 py-5 lg:px-10 lg:py-8">
        <front-loading-button :loading="passwordForm.processing" type="submit">
          {{ $t('Update Password') }}
        </front-loading-button>
      </div>
    </form>
  </div>
</template>

<script>
import SettingsLayout from './SettingsLayout.vue'
import FrontLayoutVue from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    FrontLoadingButton,
    FrontTextInput,
  },
  layout: [FrontLayoutVue, SettingsLayout],
  data() {
    return {
      passwordForm: useForm({
        current_password: null,
        password: null,
        password_confirmation: null,
      }),
    }
  },
  methods: {
    updatePassword() {
      this.passwordForm.put(this.route('front.settings.password.update'), {
        onSuccess: () => {
          this.passwordForm.reset()
        },
      })
    },
  },
}
</script>
