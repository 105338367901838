<template>
  <nav class="flex items-center gap-x-6 overflow-x-auto border-b border-ts-gray-500 px-10 pt-2">
    <tab-menu-item
      v-for="menuItem in menuList"
      :key="menuItem.name"
      :match-url="menuItem.matchUrl"
      :name="menuItem.name"
      :url="menuItem.url"
    />
  </nav>
</template>
<script>
import TabMenuItem from './TabMenuItem.vue'

export default {
  components: {
    TabMenuItem,
  },
  props: {
    menuList: Array,
  },
}
</script>
