<template>
  <front-modal-full-height
    class="rounded text-base"
    modal-classes="max-h-screen"
    :show="show"
    width="64rem"
    @close="closeModal"
  >
    <div class="flex flex-wrap justify-center bg-white px-8 pt-8">
      <breadcrumb :name="$t('Form Template Library')" />
    </div>
    <div class="overflow-hidden bg-white">
      <div class="mb-6 flex flex-col-reverse items-center justify-between lg:flex-row">
        <div class="mx-auto w-10/12 overflow-hidden py-10">
          <form class="w-full" @submit.prevent="submit">
            <div class="space-y-4">
              <div class="space-y-1">
                <front-search-input v-model="form.search" />
              </div>
              <section class="space-y-2">
                <div v-if="!searchResultsFormTemplates?.data?.length">
                  <span class="px-6 py-4" colspan="6">{{ $t('No Forms found.') }}</span>
                </div>
                <template v-else>
                  <div
                    v-for="formTemplate in searchResultsFormTemplates.data"
                    :key="formTemplate.id"
                    class="flex cursor-pointer flex-col rounded-lg border bg-white px-6 pb-4 pt-6 lg:flex-row lg:items-start"
                  >
                    <div class="flex-1 space-y-4 lg:pr-8">
                      <h3 class="line-clamp-2 font-semibold">{{ formTemplate.title }}</h3>
                      <div class="flex flex-col justify-start md:flex-row">
                        <div class="flex gap-2 pr-4 md:w-1/3 md:flex-col md:gap-0">
                          <span class="text-xs text-ts-gray-700">{{ formTemplate.description }}</span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="flex justify-center whitespace-nowrap text-sm font-medium leading-5 lg:w-1/4 lg:justify-end xl:w-1/5"
                    >
                      <FrontOutlineButton class="my-2 inline-block" @click="selectTemplate(formTemplate)">
                        {{ $t('Select') }}
                      </FrontOutlineButton>
                    </div>
                  </div>
                </template>
              </section>
            </div>
          </form>
        </div>
      </div>
    </div>
  </front-modal-full-height>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontModalFullHeight from '@/Shared/FrontModalFullHeight.vue'
import FrontOutlineButton from '@/Shared/FrontOutlineButton.vue'
import FrontSearchInput from '@/Shared/FrontSearchInput.vue'
import Http from '@/Utils/Http'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    FrontOutlineButton,
    Breadcrumb,
    FrontModalFullHeight,
    FrontSearchInput,
  },
  props: {
    show: Boolean,
  },
  data() {
    return {
      form: useForm(
        {
          search: null,
        },
        'FormSearchModal'
      ),
      searchResultsFormTemplates: null,
    }
  },
  watch: {
    'form.search': {
      handler() {
        this.search()
      },
      deep: true,
    },
  },
  mounted() {
    this.search()
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    search() {
      Http.get(this.route('front.forms.search.global'), { params: { search: this.form.search } }).then((response) => {
        this.searchResultsFormTemplates = response.data
      })
    },
    selectTemplate(template) {
      this.$inertia.post(
        this.route('front.admin.form-templates.store-from-global', { formTemplate: template.series_id })
      )
      this.closeModal()
    },
  },
}
</script>
