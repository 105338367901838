<template>
  <div
    class="certificate-line-item"
    :class="{ highlight: highlight }"
    :data-key="keyName"
    :style="style"
    tabindex="0"
    @mousedown.prevent="onMouseDown"
  >
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    modelValue: Object,
    keyName: String,
    highlight: Boolean,
    pdfFields: Object,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      pos: {
        x_position: parseInt(this.modelValue.x_position),
        y_position: parseInt(this.modelValue.y_position),
      },
      offset: {
        x: 0,
        y: 0,
      },
    }
  },
  computed: {
    initial() {
      return {
        x_position: parseInt(this.modelValue.x_position),
        y_position: parseInt(this.modelValue.y_position),
      }
    },
    style() {
      const x = Object.keys(this.modelValue)
        .filter((key) => {
          return !(key in this.modelValue) || this.modelValue[key]
        })
        .map((key) => {
          let value = this.modelValue[key]
          const field = this.pdfFields[key]
          let cssProperty = ''

          if (key === 'x_position') {
            value = this.pos.x_position
          } else if (key === 'y_position') {
            value = this.pos.y_position
          }

          if (field) {
            cssProperty = `${field.css_property}:${value}`
            if ('append' in field) cssProperty += field.append
          }
          return cssProperty
        })

      if (x && x.length > 0) return x.join(';')
      return 'display:none;'
    },
  },
  watch: {
    initial() {
      this.pos.x_position = this.initial.x_position
      this.pos.y_position = this.initial.y_position
    },
  },
  methods: {
    mouseup: function () {
      this.$emit('update:modelValue', { ...this.modelValue, ...this.pos })
      // remove event listener
      window.removeEventListener('mousemove', this.mousemove)
      window.removeEventListener('mouseup', this.mouseup)
    },
    mousemove: function (e) {
      if (e.clientX) {
        this.pos.x_position = this.initial.x_position - (this.offset.x - e.clientX)
        this.pos.y_position = this.initial.y_position - (this.offset.y - e.clientY)
      }
    },
    onMouseDown: function (e) {
      this.offset.x = e.clientX
      this.offset.y = e.clientY

      window.addEventListener('mousemove', this.mousemove)
      window.addEventListener('mouseup', this.mouseup)
    },
  },
}
</script>
<style scoped>
.certificate-line-item {
  position: absolute;
  z-index: 100;
  word-break: break-word;
  overflow: hidden;
  cursor: grab;
  font-family: Helvetica, Times-Roman, Courier;
}

.highlight {
  background-color: #ffef00;
}
</style>
