<template>
  <layout :title="$t('Locations')">
    <h1 class="mb-8 text-xl font-bold">{{ $t('Locations') }}</h1>
    <div class="mb-6 flex items-center justify-between">
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
        <label class="block text-gray-800">{{ $t('Type:') }}</label>
        <select v-model="form.type" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="class">{{ $t('Classroom') }}</option>
          <option value="webinar">{{ $t('Webinar') }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Archived:') }}</label>
        <select v-model="form.archived" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="with">{{ $t('With Archived') }}</option>
          <option value="only">{{ $t('Only Archived') }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Deleted:') }}</label>
        <select v-model="form.deleted" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="with">{{ $t('With Deleted') }}</option>
          <option value="only">{{ $t('Only Deleted') }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Client site:') }}</label>
        <select v-model="form.client_site" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="with">{{ $t('With Client Sites') }}</option>
          <option value="only">{{ $t('Only Client Sites') }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Region:') }}</label>
        <select v-model="form.region" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="(region, index) in regions" :key="index" :value="region">{{ region }}</option>
        </select>
      </search-filter>
      <InertiaLink class="btn-blue" :href="route('locations.create')">
        <span>{{ $t('Create') }}</span>
        <span class="hidden md:inline">{{ $t('Location') }}</span>
      </InertiaLink>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow">
      <SimpleTable>
        <template #head>
          <TableHead>{{ $t('Name') }}</TableHead>
          <TableHead>{{ $t('Type') }}</TableHead>
          <TableHead>{{ $t('Timezone') }}</TableHead>
          <TableHead>{{ $t('Address') }}</TableHead>
          <TableHead>{{ $t('City') }}</TableHead>
          <TableHead>{{ $t('Region') }}</TableHead>
          <TableHead class="text-right">{{ $t('Rooms') }}</TableHead>
          <TableHead />
        </template>
        <TableRow v-if="locations.data.length === 0" colspan="6">
          <TableData colspan="4">{{ $t('No locations found.') }}</TableData>
        </TableRow>
        <TableRow
          v-for="location in locations.data"
          :key="location.id"
          class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
          @click="$inertia.visit(route('locations.edit', location.id))"
        >
          <TableData>
            <div class="flex items-center">
              {{ location.name }}
              <archived-badge v-if="location.archived_at" />
              <deleted-badge v-if="location.deleted_at" />
            </div>
          </TableData>
          <TableData>
            <div class="flex items-center">
              {{ types[location.type] }}
            </div>
          </TableData>
          <TableData>
            {{ location.timezone }}
          </TableData>
          <TableData>
            {{ location.address }}
            <span v-if="location.client_site" class="ml-2 rounded bg-gray-400 px-2 py-1 text-sm text-white">
              {{ $t('Client') }}
            </span>
          </TableData>
          <TableData>
            {{ location.city }}
          </TableData>
          <TableData>
            {{ location.region }}
          </TableData>
          <TableData class="text-right">
            {{ location.rooms_count }}
          </TableData>
          <TableData class="w-min align-middle">
            <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
          </TableData>
        </TableRow>
      </SimpleTable>
    </div>
    <pagination :links="locations.links" />
  </layout>
</template>

<script>
import ArchivedBadge from '@/Shared/ArchivedBadge.vue'
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    TableData,
    TableRow,
    TableHead,
    ArchivedBadge,
    DeletedBadge,
    Icon,
    Layout,
    Pagination,
    SearchFilter,
    SimpleTable,
  },
  props: {
    filters: Object,
    types: Object,
    locations: Object,
    regions: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        archived: this.filters.archived,
        deleted: this.filters.deleted,
        client_site: this.filters.client_site,
        type: null,
        region: null,
      },
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(this.route('locations'), Object.keys(query).length ? query : { remember: 'forget' }, {
          preserveState: true,
          replace: true,
        })
      }, 300),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
