<template>
  <div class="p-8">
    <app-head title="Organization Users" />
    <div>
      <div class="mt-4 flex items-end justify-between">
        <h2 class="text-lg">{{ $t('Users') }}</h2>
      </div>
      <div class="mt-4 overflow-x-auto rounded bg-white shadow">
        <SimpleTable>
          <template #head>
            <TableHead>{{ $t('Name') }}</TableHead>
            <TableHead>{{ $t('Email') }}</TableHead>
            <TableHead>{{ $t('Role') }}</TableHead>
            <TableHead />
          </template>
          <TableRow v-if="users.data.length === 0">
            <TableData colspan="6">{{ $t('No users found.') }}</TableData>
          </TableRow>
          <TableRow
            v-for="user in users.data"
            :key="user.id"
            :clickable="true"
            @click="$inertia.visit(route('users.edit', user.id))"
          >
            <TableData class="space-x-1">
              <span>{{ user.name }}</span>
              <archived-badge v-if="user.archived_at" />
              <deleted-badge v-if="user.deleted_at" />
            </TableData>
            <TableData>{{ user.email }}</TableData>
            <TableData>
              <span v-if="user.is_owner" class="ml-2 rounded bg-gray-300 px-2 py-1 text-xs">{{ $t('Owner') }}</span>
              <span v-if="user.organization_role !== 'basic'" class="rounded bg-gray-300 px-2 py-1 text-xs">
                {{ user.organization_role_label }}
              </span>
            </TableData>
            <TableData class="w-min align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </TableData>
          </TableRow>
        </SimpleTable>
      </div>
      <pagination :links="users.links" :preserve-scroll="true" />
    </div>
  </div>
</template>

<script>
import OrganizationEditLayout from './OrganizationEditLayout.vue'
import AppHead from '@/Shared/AppHead.vue'
import ArchivedBadge from '@/Shared/ArchivedBadge.vue'
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'

export default {
  components: {
    AppHead,
    ArchivedBadge,
    DeletedBadge,
    Icon,
    Pagination,
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
  },
  layout: [Layout, OrganizationEditLayout],
  props: {
    users: Object,
  },
}
</script>
