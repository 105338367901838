<template>
  <styled-modal max-width="lg" :show="show" @close="$emit('close')">
    <template #title>{{ $t('Request Students Review Confirmation') }}</template>

    <template #content>
      <div class="mt-6 flex flex-col space-y-4">
        <div v-if="class_.sessions.length">
          <p>{{ $t("You're about to invite all students of this class to review the course location.") }}</p>

          <p>{{ $t('Are you sure you want to do that?') }}</p>
        </div>
        <div v-else>{{ $t('You cannot request reviews for this course location until the class has sessions.') }}</div>
      </div>
    </template>

    <template #footer>
      <button class="text-gray-500" @click.prevent="$emit('close')">{{ $t('Cancel') }}</button>
      <button v-if="class_.sessions.length" class="btn-blue ml-4" :disabled="requesting" @click.prevent="sendRequest">
        <span v-if="!requesting">{{ $t('Request Review') }}</span>
        <span v-else>{{ $t('Requesting...') }}</span>
      </button>
    </template>
  </styled-modal>
</template>

<script>
import StyledModal from '@/Shared/StyledModal.vue'

export default {
  components: {
    StyledModal,
  },
  name: 'ConfirmRequestReview',
  props: {
    show: Boolean,
    class_: Object,
  },
  data() {
    return {
      requesting: false,
    }
  },
  methods: {
    sendRequest() {
      this.requesting = true

      window.axios.post(this.route('classes.request-reviews.store', this.class_)).finally(() => {
        this.requesting = false
        this.$emit('requestSent')
      })
    },
  },
}
</script>
