<template>
  <front-layout :title="$t('Courses')">
    <div class="flex flex-col xl:flex-row xl:justify-between">
      <div class="sm:no-flex mb-8 flex w-full items-center justify-between">
        <breadcrumb :name="course.name" />
      </div>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow-lg">
      <table class="front-table">
        <thead>
          <tr>
            <th>{{ $t('Class Date(s)') }}</th>
            <th>{{ $t('Location') }}</th>
            <th>{{ $t('Total Seats') }}</th>
            <th>{{ $t('Assigned') }}</th>
            <th>{{ $t('Unassigned') }}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-if="classes.data.length === 0">
            <td class="border-t px-6 py-4" colspan="5">{{ $t('No classes found.') }}</td>
          </tr>
          <tr
            v-for="class_ in classes.data"
            :key="class_.id"
            :class="{ 'bg-ts-gray-100': class_.is_in_blackout_period }"
          >
            <td class="font-semibold text-ts-gray-text-bold">{{ class_.date_range }}</td>
            <td>
              <div>{{ class_.location[0] }}</div>
              <div>{{ class_.location[1] }}</div>
            </td>
            <td>{{ class_.enrolment_counts.total }}</td>
            <td>{{ class_.enrolment_counts.assigned }}</td>
            <td>{{ class_.enrolment_counts.unassigned }}</td>
            <td>
              <InertiaLink
                class="font-semibold hover:underline"
                :href="route('front.manager.courses-classes.edit', { course: course.id, class: class_.id })"
              >
                <span v-if="!class_.is_in_blackout_period">{{ $t('Assign Seats') }}</span>
                <span v-else>{{ $t('View') }}</span>
              </InertiaLink>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination :links="classes.links" />
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import Pagination from '@/Shared/Pagination.vue'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    Breadcrumb,
    FrontLayout,
    Pagination,
  },
  props: {
    course: Object,
    classes: Object,
    filters: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
      },
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(this.route('courses'), Object.keys(query).length ? query : { remember: 'forget' }, {
          preserveState: true,
          replace: true,
        })
      }, 300),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
