<template>
  <front-layout :title="$t('Access Codes')">
    <div class="mb-8 max-w-lg">
      <breadcrumb
        class="font-heading text-2xl font-semibold"
        :name="code.code"
        :previous-name="$t('Access Codes')"
        :previous-url="route('front.manager.access-codes')"
      />
    </div>
    <div class="flex flex-wrap">
      <div class="flex w-full flex-col items-start xl:flex-row">
        <div class="mb-8 mr-0 rounded-md bg-white shadow lg:w-2/4 xl:mb-0 xl:w-1/4">
          <div class="border-t px-8 pb-10 pt-10">
            <dl class="grid w-full gap-y-8 overflow-hidden break-words text-ts-gray-text-bold">
              <div class="w-full overflow-hidden">
                <dt class="mb-2 font-semibold">{{ $t('Access Code') }}</dt>
                <dd class="text-ts-gray-700">{{ code.code }}</dd>
              </div>
              <div v-if="code.group" class="w-full overflow-hidden">
                <dt class="mb-2 font-semibold">{{ $t('Group') }}</dt>
                <dd class="text-ts-gray-700">{{ code.group.name }}</dd>
              </div>
              <div v-if="code.group" class="w-full overflow-hidden">
                <dt class="mb-2 font-semibold">{{ $t('Usage') }}</dt>
                <dd v-if="code.usage_limit" class="text-ts-gray-700">{{ code.usage_limit }}</dd>
                <dd v-else class="text-ts-gray-700">{{ $t('Unlimited') }}</dd>
              </div>
              <div v-if="code.is_archived" class="w-full overflow-hidden">
                <dt class="mb-2 font-semibold">{{ $t('Archived') }}</dt>
                <dd class="flex flex-col text-ts-gray-700">{{ $t('Yes') }}</dd>
              </div>
              <div class="w-full overflow-hidden">
                <dt class="mb-2 font-semibold">{{ $t('Courses') }}</dt>
                <dd class="flex flex-col text-ts-gray-700">
                  <template v-for="(course, key) in code.courses" :key="key">
                    <span class="my-1 w-full">{{ course.name }}</span>
                  </template>
                </dd>
              </div>
            </dl>
            <div class="flex w-full justify-center overflow-hidden">
              <front-button-link class="mt-6" :href="route('front.manager.access-codes.edit', code.id)">
                {{ $t('Edit') }}
              </front-button-link>
            </div>
          </div>
        </div>
        <div class="flex w-full flex-col xl:w-3/4 xl:pl-16">
          <div class="flex-1">
            <h2 class="text-md sr-only">{{ $t('Usage History') }}</h2>
            <div class="mb-8 rounded-md bg-white shadow-md">
              <table class="front-table">
                <thead>
                  <tr>
                    <th>{{ $t('User') }}</th>
                    <th>{{ $t('Course') }}</th>
                    <th>{{ $t('Enrolled At') }}</th>
                    <th>{{ $t('Status') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="code.enrolments.data.length === 0">
                    <td colspan="3">{{ $t('No enrolments found.') }}</td>
                  </tr>
                  <tr
                    v-for="enrolment in code.enrolments.data"
                    :key="enrolment.id"
                    class="hover:bg-gray-100 focus:bg-gray-100"
                  >
                    <td class="highlight">
                      <InertiaLink
                        v-if="enrolment.user"
                        class="text-blue-600 hover:text-blue-700"
                        :href="route('front.manager.users.show', enrolment.user.id)"
                      >
                        {{ enrolment.user.name }}
                      </InertiaLink>
                      <span v-else>-</span>
                    </td>
                    <td>{{ enrolment.name }}</td>
                    <td>
                      <front-date :timestamp="enrolment.enrolled_at" />
                    </td>
                    <td><front-course-status :status="enrolment.status" /></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <front-pagination :links="code.enrolments.links" />
          </div>
        </div>
      </div>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontButtonLink from '@/Shared/FrontButtonLink.vue'
import FrontCourseStatus from '@/Shared/FrontCourseStatus.vue'
import FrontDate from '@/Shared/FrontDate.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontPagination from '@/Shared/FrontPagination.vue'

export default {
  components: {
    Breadcrumb,
    FrontButtonLink,
    FrontCourseStatus,
    FrontDate,
    FrontLayout,
    FrontPagination,
  },
  props: {
    code: Object,
    groups: Array,
    courses: Array,
  },
  data() {
    return {
      previous: {
        url: this.route('front.manager.access-codes'),
        name: this.$t('Access Codes'),
      },
    }
  },
  methods: {
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this access code?'))) {
        this.$inertia.delete(this.route('front.manager.access-codes.destroy', this.code.id))
      }
    },
  },
}
</script>
