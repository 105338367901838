<template>
  <div>
    <menu-item name="Log out" :route-link="route('front.logout')" url="account/logout*">
      <icon class="mr-3 h-5 w-5" name="logout" />
    </menu-item>
  </div>
</template>

<script>
import Icon from './Icon.vue'
import MenuItem from './MenuItem.vue'

export default {
  components: {
    Icon,
    MenuItem,
  },
}
</script>
