<template>
  <div class="mb-8 mr-0 rounded-md bg-white shadow lg:w-2/4 xl:mb-0 xl:w-1/4">
    <div class="flex w-full flex-col items-center justify-center px-6 pb-6 pt-10">
      <front-profile-image
        :delete-url="route('front.manager.users.profile-image.destroy', user.id)"
        :update-url="route('front.manager.users.profile-image.update', user.id)"
        :user="user"
      />
      <div class="mb-6 mt-6 w-full">
        <div class="w-full break-words text-center text-lg font-semibold text-ts-gray-text-bold">
          {{ user.first_name }} {{ user.last_name }}
          <icon
            v-if="user.archived_at"
            class="inline-flex h-4 w-4 flex-shrink-0 fill-gray-500 align-baseline"
            name="trash"
          />
        </div>
        <div v-if="user.groups.length > 0" class="mt-3 flex w-full flex-wrap justify-center space-x-2">
          <div
            v-for="userGroup in user.groups"
            :key="userGroup.id"
            class="rounded-full bg-gray-200 px-3 py-1 text-sm text-gray-600"
          >
            {{ userGroup.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="border-t px-8 pb-10 pt-6">
      <dl class="grid w-full gap-y-8 overflow-hidden break-words text-ts-gray-text-bold">
        <div v-if="user.username" class="flex w-full overflow-hidden">
          <div class="w-1/6">
            <icon class="block h-6 w-6" name="user" />
          </div>
          <div class="w-5/6">
            <dt class="mb-2 font-semibold">{{ $t('Username') }}</dt>
            <dd class="leading-tight text-ts-gray-700">{{ user.username }}</dd>
          </div>
        </div>
        <div class="flex w-full overflow-hidden">
          <div class="w-1/6">
            <icon class="block h-6 w-6" name="email" />
          </div>
          <div class="w-5/6">
            <dt class="mb-2 font-semibold">{{ $t('Email') }}</dt>
            <dd class="leading-tight text-ts-gray-700">{{ user.email }}</dd>
          </div>
        </div>
        <div class="flex w-full overflow-hidden">
          <div class="w-1/6">
            <icon class="block h-6 w-6" name="phone" />
          </div>
          <div class="w-5/6">
            <dt class="mb-2 font-semibold">{{ $t('Phone') }}</dt>
            <dd class="text-ts-gray-700">{{ user.phone || '-' }}</dd>
          </div>
        </div>
        <div class="flex w-full overflow-hidden">
          <div class="w-1/6">
            <icon class="block h-6 w-6" name="eye" />
          </div>
          <div class="w-5/6">
            <dt class="mb-2 font-semibold">{{ $t('Last Seen') }}</dt>
            <dd class="text-ts-gray-700">{{ user.last_login_at || '-' }}</dd>
          </div>
        </div>
      </dl>
    </div>
  </div>
</template>
<script>
import FrontProfileImage from '@/Shared/FrontProfileImage.vue'
import Icon from '@/Shared/Icon.vue'

export default {
  components: {
    FrontProfileImage,
    Icon,
  },
  props: {
    user: Object,
  },
}
</script>
