<template>
  <div class="w-full">
    <form @submit.prevent="updatePublicProfile">
      <div class="-mb-8 -mr-6 flex flex-wrap p-6 lg:p-10">
        <div class="flex items-center justify-between pb-8 pr-6 sm:w-1/2">
          <label class="mr-3 cursor-pointer font-semibold text-ts-gray-text-bold" for="public-profile">
            {{ $t('Enable Public Certification Profile') }}
          </label>
          <front-small-toggle-input
            id="public-profile"
            v-model="publicProfileForm.public_profile_enabled"
            :error="publicProfileForm.errors.public_profile_enabled"
          />
        </div>
        <div v-if="publicProfileForm.public_profile_enabled" class="flex flex-col pb-8 pr-6 sm:w-1/2">
          <label class="mr-3 cursor-pointer font-semibold text-ts-gray-text-bold" for="public-profile">
            {{ $t('Profile Url') }}
          </label>
          <div class="flex items-center">
            <div class="flex items-center">
              <div>{{ base_url }}/</div>
              <div class="flex flex-col">
                <input v-model="publicProfileForm.public_profile_key" class="border-b" type="text" />
              </div>
            </div>

            <span v-if="publicProfileForm.errors.public_profile_key" class="form-error">
              {{ publicProfileForm.errors.public_profile_key }}
            </span>
            <a
              v-if="user.public_profile_enabled && user.public_profile_key"
              :href="route('public.user.profile', user.public_profile_key)"
              target="_blank"
            >
              <icon class="fill-none h-4 w-4" name="external-link" />
            </a>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between border-t border-gray-200 px-6 py-5 lg:px-10 lg:py-8">
        <front-loading-button :loading="publicProfileForm.processing" type="submit">
          {{ $t('Update') }}
        </front-loading-button>
      </div>
    </form>
  </div>
</template>

<script>
import SettingsLayout from './SettingsLayout.vue'
import FrontLayoutVue from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontSmallToggleInput from '@/Shared/FrontSmallToggleInput.vue'
import Icon from '@/Shared/Icon.vue'
import Http from '@/Utils/Http'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    FrontLoadingButton,
    FrontSmallToggleInput,
    Icon,
  },
  layout: [FrontLayoutVue, SettingsLayout],
  props: {
    user: Object,
    base_url: String,
  },

  data() {
    return {
      publicProfileForm: useForm({
        public_profile_enabled: this.user.public_profile_enabled,
        public_profile_key: this.user.public_profile_key,
      }),
    }
  },
  watch: {
    'publicProfileForm.public_profile_enabled'(value) {
      if (value && !this.publicProfileForm.public_profile_key) {
        this.generateProfileKey()
      }
    },
  },
  methods: {
    generateProfileKey() {
      return Http.get(this.route('profile-key.generate', this.user)).then((response) => {
        if (!this.publicProfileForm.public_profile_key || this.publicProfileForm.public_profile_key == '') {
          this.publicProfileForm.public_profile_key = response.data.key
        }
      })
    },
    updatePublicProfile() {
      this.publicProfileForm.put(this.route('front.settings.public-profile.update'))
    },
  },
}
</script>
