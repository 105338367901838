<template>
  <layout :title="$t('Create Trigger')">
    <div class="mb-8">
      <breadcrumb-admin :path="[{ name: $t('Triggers'), url: route('triggers') }, { name: $t('Create') }]" />
    </div>
    <div class="max-w-sm overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" required />
          </div>
        </div>
        <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Trigger On') }}</div>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <select-input
              v-model="form.onEvent"
              :error="form.errors.onEvent"
              :label="$t('On Event')"
              :options="events"
              required
            />
          </div>
          <template v-if="form.onEvent">
            <div v-if="isShowCourseField" class="w-full pb-8 pr-6">
              <multi-select-input
                v-model="form.courses"
                deselect-label=""
                :error="form.errors.course"
                :hide-label="true"
                :hide-selected="true"
                label="name"
                :multiple="true"
                :name="$t('Courses')"
                :placeholder="$t('Search courses')"
                required
                select-label=""
                track-by="id"
                :url="route('courses.search')"
              >
                <template #noResult>{{ $t('No courses found.') }}</template>
                <template #noOptions>{{ $t('No courses found.') }}</template>
              </multi-select-input>
            </div>
            <div v-if="isShowDaysBeforeField" class="w-full pb-8 pr-6">
              <text-input
                v-model="form.daysBefore"
                :error="form.errors.daysBefore"
                :label="$t('Days Before')"
                min="0"
                type="number"
              />
            </div>
            <div v-if="isShowDaysAfterField" class="w-full pb-8 pr-6">
              <text-input
                v-model="form.daysAfter"
                :error="form.errors.daysAfter"
                :label="$t('Days After')"
                min="0"
                type="number"
              />
            </div>
            <div v-if="isShowInstructorledClassroomField" class="w-full pb-8 pr-6">
              <multi-select-input
                v-model="form.instructorLedClassrooms"
                deselect-label=""
                :error="form.errors.instructorLedClassrooms"
                :hide-label="true"
                :hide-selected="true"
                label="name"
                :multiple="true"
                :name="$t('Instructor Led Classroom')"
                :placeholder="$t('Search classrooms')"
                select-label=""
                track-by="id"
                :url="route('instructor-led-classrooms.search')"
              >
                <template #noResult>{{ $t('No classrooms found.') }}</template>
                <template #noOptions>{{ $t('No classrooms found.') }}</template>
              </multi-select-input>
            </div>
            <div v-if="isShowLocationField" class="w-full pb-8 pr-6">
              <multi-select-input
                v-model="form.locations"
                deselect-label=""
                :error="form.errors.locations"
                :hide-label="true"
                :hide-selected="true"
                label="name"
                :multiple="true"
                :name="$t('Locations')"
                :placeholder="$t('Search locations')"
                select-label=""
                track-by="id"
                :url="route('locations.search')"
              >
                <template #noResult>{{ $t('No locations found.') }}</template>
                <template #noOptions>{{ $t('No locations found.') }}</template>
              </multi-select-input>
            </div>
            <div v-if="isShowSubscriptionPlanField" class="w-full pb-8 pr-6">
              <multi-select-input
                v-model="form.subscriptionPlans"
                deselect-label=""
                :error="form.errors.subscriptionPlans"
                :hide-label="true"
                :hide-selected="true"
                label="title"
                :multiple="true"
                :name="$t('Subscription Plans')"
                :placeholder="$t('Search plans')"
                required
                select-label=""
                track-by="id"
                :url="route('subscription-plans.search')"
              >
                <template #noResult>{{ $t('No subscription plans found.') }}</template>
                <template #noOptions>{{ $t('No subscription plans found.') }}</template>
              </multi-select-input>
            </div>
          </template>
        </div>
        <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Target') }}</div>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <select-input v-model="form.type" :error="form.errors.type" :label="$t('Type')" :options="types" required />
          </div>
          <template v-if="form.type === 'mailchimp'">
            <div class="w-full pb-8 pr-6">
              <text-input
                v-model="form.mailchimpEventName"
                :error="form.errors.mailchimpEventName"
                :label="$t('Mailchimp Event')"
                required
              />
            </div>
          </template>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Create') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import BreadcrumbAdmin from '@/Shared/BreadcrumbAdmin.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import MultiSelectInput from '@/Shared/MultiSelectInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'
import _map from 'lodash/map'

export default {
  components: { SelectInput, MultiSelectInput, BreadcrumbAdmin, Layout, LoadingButton, TextInput },
  props: {
    events: Object,
    types: Object,
  },
  data() {
    return {
      selectedLocation: null,
      form: useForm({
        name: null,
        type: null,
        onEvent: null,
        courses: null,
        daysBefore: null,
        daysAfter: null,
        instructorLedClassrooms: [],
        locations: [],
        subscriptionPlans: [],
        mailchimpEventName: null,
      }),
    }
  },
  computed: {
    isShowCourseField() {
      let isShowCourse = false
      switch (this.form.onEvent) {
        case 'certificate-expired':
        case 'certificate-earned':
        case 'enrolment-failed':
        case 'enroled-elearning-course':
          isShowCourse = true
          break
      }
      return isShowCourse
    },
    isShowDaysBeforeField() {
      let isShowDaysBefore = false
      switch (this.form.onEvent) {
        case 'certificate-expired':
          isShowDaysBefore = true
          break
      }
      return isShowDaysBefore
    },
    isShowDaysAfterField() {
      let isShowDaysAfter = false
      switch (this.form.onEvent) {
        case 'certificate-expired':
        case 'certificate-earned':
        case 'enrolment-failed':
        case 'enroled-elearning-course':
        case 'enroled-instructor-led-course':
          isShowDaysAfter = true
          break
      }
      return isShowDaysAfter
    },
    isShowInstructorledClassroomField() {
      let isShowInstructorLedClassroom = false
      switch (this.form.onEvent) {
        case 'enroled-instructor-led-course':
          isShowInstructorLedClassroom = true
          break
      }
      return isShowInstructorLedClassroom
    },
    isShowLocationField() {
      let isShowLocation = false
      switch (this.form.onEvent) {
        case 'enroled-instructor-led-course':
          isShowLocation = true
          break
      }
      return isShowLocation
    },
    isShowSubscriptionPlanField() {
      let isShowSubscriptionPlanField = false
      switch (this.form.onEvent) {
        case 'subscription-trial-started':
        case 'subscription-plan-started':
        case 'subscription-plan-restarted':
        case 'subscription-plan-expired':
        case 'subscription-plan-cancelled':
          isShowSubscriptionPlanField = true
          break
      }
      return isShowSubscriptionPlanField
    },
  },
  watch: {
    selectedLocation(location) {
      this.form.location = location ? location.id : null
    },
  },
  methods: {
    _map,
    submit() {
      this.form
        .transform((data) => ({
          ...data,
          courses: _map(data.courses, 'id'),
          subscriptionPlans: _map(data.subscriptionPlans, 'id'),
          locations: _map(data.locations, 'id'),
          instructorLedClassrooms: _map(data.instructorLedClassrooms, 'id'),
        }))
        .post(this.route('triggers.store'))
    },
  },
}
</script>
