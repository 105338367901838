<template>
  <div v-if="error" class="mb-8 flex max-w-lg items-start justify-start rounded bg-red-400">
    <icon class="ml-4 mr-2 h-4 w-4 mt-5 flex-shrink-0 fill-white" name="close-outline" />
    <div class="flex-1">
      <div v-if="errorType === 'object'">
        <ul class="my-4">
          <li v-for="(value, key) in error" :key="key" class="text-sm font-medium text-white my-1">
            {{ key }}:
            <template v-if="Array.isArray(value)">
              <span v-for="(item, index) in value" :key="index">
                <span v-if="index !== 0">,</span>
                {{ item }}
              </span>
            </template>
            <template v-else>
              {{ value }}
            </template>
          </li>
        </ul>
      </div>
      <div v-else-if="errorType === 'array'">
        <ul class="my-4">
          <li v-for="(value, mainIndex) in error" :key="mainIndex" class="text-sm font-medium text-white my-1">
            {{ key }}:
            <template v-if="Array.isArray(value)">
              <span v-for="(item, index) in value" :key="index">
                <span v-if="index !== 0">,</span>
                {{ item }}
              </span>
            </template>
            <template v-else>
              {{ value }}
            </template>
          </li>
        </ul>
      </div>
      <div v-else>
        <div class="py-4 text-sm font-medium text-white">{{ $page.props.flash.error }}</div>
      </div>
    </div>
    <button class="group mr-2 p-2 mt-3" type="button" @click="close">
      <icon class="block h-3 w-3 fill-red-300 group-hover:fill-red-100" name="close" />
    </button>
  </div>
</template>

<script>
import Icon from './Icon.vue'
import zendesk from '@/mixins/zendesk.js'

export default {
  components: {
    Icon,
  },
  mixins: [zendesk],
  props: {
    error: {
      type: [String, Object, Array],
      default: null,
    },
  },
  emits: ['close'],
  computed: {
    errorType() {
      return typeof this.error
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>
