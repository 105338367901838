<template>
  <FrontLayout :title="$t('Courses')">
    <div
      class="relative flex items-center justify-between rounded-2xl px-8"
      style="background-color: rgb(248, 239, 242)"
    >
      <div class="w-full py-12 pl-3 md:pl-6 lg:w-1/2">
        <div class="text-4xl font-semibold">{{ $t('Welcome, :name', { name: displayName }) }}</div>
        <div class="mt-6 leading-6">{{ tenant.user_welcome_message }}</div>
        <div
          v-if="tenant.wc_shop_url"
          class="browse__button mt-6 flex flex-col pr-3 xl:flex-row xl:items-center xl:pr-0"
        >
          <purchase-link>{{ $t('Browse Courses') }}</purchase-link>
          <span class="mx-4 mt-2 text-center text-lg xl:mt-0 xl:text-left">{{ $t('or') }}</span>
          <div class="flex items-center">
            <input
              v-model="search"
              class="mt-3 w-full rounded-full px-6 py-4 text-base xl:mt-0 xl:w-260px"
              :placeholder="$t('Find a course...')"
              type="text"
              @keyup.enter="$refs.browseLink.click()"
            />
            <button
              class="mt-3 inline-block rounded-full px-4 py-4 xl:mt-0"
              style="margin-left: -55px"
              @click="$refs.browseLink.click()"
            >
              <icon class="block h-5 w-5 text-ts-gray-text-bold opacity-50" name="search" />
            </button>
          </div>
          <a ref="browseLink" class="hidden" :href="getBrowseCoursesLink()" target="_blank" />
        </div>
      </div>
      <div class="hidden w-2/5 lg:block">
        <img :alt="$t('Welcome')" src="/images/desktop.svg" />
      </div>
    </div>
    <div class="mt-8 flex flex-col lg:flex-row lg:items-center lg:justify-between">
      <div class="mb-2 whitespace-nowrap text-xl font-semibold lg:mb-0">{{ $t('My Courses') }}</div>
      <div class="flex items-center">
        <button
          v-if="isDesktopWidth && !$page.props.auth.user.organization"
          class="mr-4 text-sm leading-normal underline"
          @click="startTour()"
        >
          Take Tour
        </button>
        <div>
          <div class="rounded bg-white">
            <form @submit.prevent="submit">
              <div class="flex items-center rounded-lg border border-gray-400 pl-3 text-sm leading-normal">
                <input
                  v-model="form.code"
                  class="transition-all duration-500 ease-in-out"
                  :placeholder="$t('Enter access code')"
                />
                <button
                  class="rounded-r-lg bg-ts-front-label px-3 py-2 font-semibold text-white"
                  :disabled="form.processing"
                  type="submit"
                >
                  {{ $t('Apply') }}
                </button>
              </div>
            </form>
          </div>
          <div v-if="form.errors.code" class="form-error">{{ form.errors.code }}</div>
        </div>
        <div class="ml-4 hidden sm:block">
          <select
            v-model="status"
            class="form-select rounded-lg border border-gray-400 py-2 pr-10 text-sm leading-normal"
          >
            <option :value="null">{{ $t('Sort by Progress') }}</option>
            <option value="complete">{{ $t('Complete') }}</option>
            <option value="in progress">{{ $t('In Progress') }}</option>
            <option value="new">{{ $t('New') }}</option>
          </select>
        </div>
      </div>
    </div>
    <div
      v-if="enrolments.length > 0 || unassigned_courses_count > 0"
      class="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-3 xl:grid-cols-4"
    >
      <a
        class="red__tile grid grid-rows-2 items-center gap-2 rounded-lg bg-cover px-2 py-8 shadow hover:shadow-lg"
        :href="route('front.courses.enrol.step1')"
        style="background-image: url(/images/gradient-tile-bg.svg)"
      >
        <div class="mx-auto">
          <icon class="h-24 w-24 flex-shrink-0 fill-white opacity-50" name="play" />
        </div>
        <div class="mx-auto grid grid-rows-2 gap-2 font-bold text-white">
          <div class="mx-auto text-center">
            {{ $t('Start a Course') }}
          </div>
          <div class="mx-auto mt-1 text-center text-sm">
            {{ $t('(You have :enrolments courses available)', { enrolments: unassigned_courses_count }) }}
          </div>
        </div>
      </a>
      <course-card
        v-for="enrolment in enrolments"
        :key="enrolment.id"
        :enrolment="enrolment"
        @open:unassigned-enrolment-modal="openUnassignedEnrolmentModal"
      />
    </div>
    <div v-else class="mt-8 w-full rounded bg-white px-6 py-16 text-center shadow">
      <icon
        class="mx-auto rounded-full px-8 pb-6 pt-10"
        height="180"
        name="graduation-cap"
        style="fill: #fff; background-color: #eaebeb"
        width="180"
      />
      <p class="mt-8 text-xl font-semibold leading-relaxed">{{ $t("You don't have any courses available") }}</p>
      <p class="mt-3 text-lg leading-relaxed">{{ $t('Click browse courses or search for a course above') }}</p>
    </div>
    <unassigned-enrolment-modal
      :enrolment="selectedEnrolment"
      :show="Boolean(showUnassignedEnrolmentModal && selectedEnrolment)"
      @close:unassigned-enrolment-modal="closeUnassignedEnrolmentModal"
    />
  </FrontLayout>
</template>

<script>
import CourseCard from './CourseCard.vue'
import UnassignedEnrolmentModal from './UnassignedEnrolmentModal.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import Icon from '@/Shared/Icon.vue'
import PurchaseLink from '@/Shared/PurchaseLink.vue'
import Http from '@/Utils/Http'
import { useForm } from '@inertiajs/vue3'
import introJs from 'intro.js/intro.js'

export default {
  components: {
    FrontLayout,
    CourseCard,
    UnassignedEnrolmentModal,
    Icon,
    PurchaseLink,
  },
  props: {
    tenant: Object,
    organization: Object,
    enrolments: Object,
    unassigned_courses_count: Number,
  },
  data() {
    return {
      introJS: introJs(),
      search: null,
      status: null,
      showUnassignedEnrolmentModal: false,
      selectedEnrolment: null,
      form: useForm({
        code: null,
      }),
    }
  },
  computed: {
    displayName() {
      return this.$page.props.auth.user.display_name.split(' ')[0]
    },
    shouldShowAndSaveTour() {
      return (
        !this.$page.props.auth.user.tour_taken_at &&
        !this.$page.props.auth.user.organization?.id &&
        !this.$page.props.isImpersonating &&
        this.isDesktopWidth
      )
    },
    isDesktopWidth() {
      return !window.matchMedia('(max-width: 40rem)').matches
    },
  },
  watch: {
    status() {
      this.$inertia.get(this.route('front.courses'), { status: this.status }, { replace: true, preserveState: true })
    },
  },
  mounted() {
    if (this.shouldShowAndSaveTour) {
      window.setTimeout(() => {
        this.startTour()
      }, 500)
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('front.register.access-code.store'))
    },
    disable() {
      localStorage.setItem('hideCourseWelcome', true)

      this.hide = true
    },
    checkIn(class_) {
      this.$inertia.get(this.route('front.courses.classes.checkin', class_))
    },
    showClass(class_) {
      this.$inertia.get(this.route('front.courses.classes.show', class_))
    },
    getBrowseCoursesLink() {
      return this.search ? this.tenant.wc_shop_url + '?s=' + this.search?.split(' ').join('+') : this.tenant.wc_shop_url
    },
    openUnassignedEnrolmentModal(enrolment) {
      this.selectedEnrolment = enrolment
      this.showUnassignedEnrolmentModal = true
    },
    closeUnassignedEnrolmentModal() {
      this.selectedEnrolment = null
      this.showUnassignedEnrolmentModal = false
    },
    startTour() {
      this.introJS
        .setOptions({
          showBullets: false,
          steps: [
            {
              title: 'Need to Manage Training?',
              element: document.querySelector('.org__create'),
              intro: 'An organization will allow you to create accounts for other people and assign them training.',
              position: 'right',
            },
            {
              title: 'Start Your Course',
              element: document.querySelector('.red__tile'),
              intro:
                'If you have purchased training for yourself, you can select and start a course by clicking on this red tile.',
              position: 'right',
            },
            {
              title: 'Browse & Search the Store',
              element: document.querySelector('.browse__button'),
              intro:
                'To purchase more courses, click here to be returned to our training store or search our training store here.',
              position: 'right',
            },
            {
              title: 'Your Profile',
              element: document.querySelector('.avatar__menu'),
              intro: 'Explore Settings to update your email, password, and enable your public certification profile.',
              position: 'left',
            },
            {
              title: "We're Here to Help",
              intro:
                'If you need more assistance, you can reach our client services team by <a href="tel:+18667565552" class="underline">phone</a> or <a href="mailto:support@worksitesafety.ca" class="underline">email</a>.',
            },
          ],
        })
        .oncomplete(() => {
          if (this.shouldShowAndSaveTour) {
            Http.put(this.route('front.users.tour-completion', this.$page.props.auth.user.id))
          }
        })
        .start()
    },
  },
}
</script>

<style scoped>
.no-courses {
  background-image: url('/images/no-courses.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
@media (min-width: 1280px) {
  .no-courses {
    background-size: 55%;
  }
}
</style>
