<template>
  <layout :title="$t('Edit Certificate Template')">
    <div class="mb-8">
      <breadcrumb
        :name="certificate_template_name"
        :previous-name="$t('Certificate Templates')"
        :previous-url="route('certificate-templates')"
      />
    </div>
    <div class="flex w-full flex-col gap-x-8 md:flex-row">
      <div class="w-full max-w-lg overflow-hidden rounded bg-white shadow">
        <form enctype="multipart/form-data" @submit.prevent="submit">
          <div class="-mb-8 -mr-6 flex flex-wrap p-8">
            <div class="w-full pb-8 pr-6">
              <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" type="text" />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input v-model="form.title_en" :error="form.errors.title_en" :label="$t('Title (EN)')" type="text" />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input v-model="form.title_fr" :error="form.errors.title_fr" :label="$t('Title (FR)')" type="text" />
            </div>
            <div class="w-full pb-8 pr-6">
              <select-input v-model="form.type" :error="form.errors.type" :label="$t('Template Type')">
                <option :value="null">{{ $t('None') }}</option>
                <option v-for="(label, type) in types" :key="type" :value="label">
                  {{ label }}
                </option>
              </select-input>
            </div>
            <div class="w-full pb-8 pr-6">
              <checkbox-input
                v-model="form.plastic_card_ordering"
                :error="form.errors.plastic_card_ordering"
                :label="$t('Plastic Card Ordering')"
              />
            </div>
            <div class="w-full pb-8 pr-6">
              <textarea-input v-model="form.description" :error="form.errors.description" :label="$t('Description')" />
            </div>
            <div class="w-full pb-8 pr-6">
              <label for="preview-image-file">{{ $t('Preview Image:') }}</label>
              <file-input
                id="preview-image-file"
                accept="image/*"
                class="mt-4"
                :error="form.errors.preview_image"
                :url="certificate_template.preview_image_url"
                @input="form.preview_image = $event"
              />
              <small class="mt-1 inline-block">{{ $t('Click the image to choose a new one.') }}</small>
            </div>
          </div>
          <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Validity') }}</div>
          <div class="flex flex-wrap px-8 py-4">
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input
                v-model="form.years_valid"
                :error="form.errors.years_valid"
                :label="$t('Years')"
                type="number"
              />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <select-input v-model="form.validity_type" :error="form.errors.validity_type" :label="$t('Type')">
                <option v-for="(label, type) in validity_types" :key="type" :value="type">
                  {{ label }}
                </option>
              </select-input>
            </div>
          </div>
          <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Formats') }}</div>
          <div class="flex flex-wrap px-8 py-4">
            <table class="w-full">
              <thead>
                <tr>
                  <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Format') }}</th>
                  <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Prepared') }}</th>
                  <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Preview') }}</th>
                  <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold" />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="format in certificate_template.formats"
                  :key="format.id"
                  class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
                  @click="$inertia.visit(route('certificate-template-format.edit', format))"
                >
                  <td class="whitespace-nowrap border-t px-6 py-4">{{ _startCase(format.style) }}</td>
                  <td class="whitespace-nowrap border-t px-6 py-4">
                    <div v-if="format.is_prepared">
                      <icon class="inline h-5 w-5 rounded-full bg-green-500 fill-white p-1" name="checkmark" />
                      Yes
                    </div>
                    <div v-else>
                      <span class="text-red-500">
                        <icon class="inline h-5 w-5 rounded-full bg-red-500 fill-white p-1" name="close" />
                      </span>
                      No
                    </div>
                  </td>
                  <td class="whitespace-nowrap border-t px-6 py-4">
                    <a
                      v-if="format.is_prepared && (format.style === 'wall' || format.style === 'wallet')"
                      class="mr-6 text-blue-500 hover:text-blue-700 hover:underline"
                      :href="route('certificate-template-format-preview', format) + '?preview_as_pdf=true'"
                      rel="noopener noreferrer"
                      target="_blank"
                      @click.stop
                    >
                      {{ $t('Preview') }}
                    </a>
                  </td>
                  <td class="w-min border-t px-4 align-middle">
                    <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
            <div>
              <button
                v-if="can.delete"
                class="text-red-500 hover:underline"
                tabindex="-1"
                type="button"
                @click="destroy"
              >
                {{ $t('Delete Certificate') }}
              </button>
            </div>
            <div class="flex items-center">
              <InertiaLink class="mr-4" :href="route('certificate-template-format.create', certificate_template)">
                {{ $t('Add Format') }}
              </InertiaLink>
              <loading-button class="btn-blue" :loading="form.processing" type="submit">
                {{ $t('Update Certificate') }}
              </loading-button>
            </div>
          </div>
        </form>
      </div>
      <div class="mt-8 w-full space-y-4 md:mt-0 md:max-w-sm">
        <card class="p-0" name="Courses">
          <div class="flex w-full flex-col">
            <div v-for="course in courses" :key="course.id" class="border-b last:border-b-0">
              <InertiaLink class="block w-full p-4" :href="route('courses.edit', course.id)">
                {{ course.name }}
              </InertiaLink>
            </div>
          </div>
        </card>
      </div>
    </div>
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import Card from '@/Shared/Card.vue'
import CheckboxInput from '@/Shared/CheckboxInput.vue'
import FileInput from '@/Shared/FileInput.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'
import _startCase from 'lodash/startCase'

export default {
  components: {
    Breadcrumb,
    Card,
    CheckboxInput,
    FileInput,
    Icon,
    Layout,
    LoadingButton,
    SelectInput,
    TextInput,
    TextareaInput,
  },
  props: {
    certificate_template: Object,
    certificate_template_name: String,
    types: Object,
    courses: Array,
    validity_types: Object,
    can: Object,
  },
  data() {
    return {
      form: useForm({
        name: this.certificate_template.name,
        title_en: this.certificate_template.title_en,
        title_fr: this.certificate_template.title_fr,
        type: this.certificate_template.type,
        plastic_card_ordering: this.certificate_template.plastic_card_ordering,
        description: this.certificate_template.description,
        years_valid: this.certificate_template.years_valid,
        validity_type: this.certificate_template.validity_type,
        preview_image: null,
      }),
    }
  },
  methods: {
    _startCase,
    submit() {
      this.form
        .transform((data) => ({
          ...data,
          _method: 'put',
        }))
        .post(this.route('certificate-templates.update', this.certificate_template))
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this certificate template?'))) {
        this.$inertia.delete(this.route('certificate-templates.destroy', this.certificate_template))
      }
    },
  },
}
</script>
