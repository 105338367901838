<template>
  <div class="w-full">
    <form @submit.prevent="updateProfile">
      <div class="-mb-5 -mr-5 flex flex-wrap p-6 lg:-mb-5 lg:-mr-5 lg:p-10">
        <div class="w-full pb-8 pr-6 sm:w-1/2">
          <front-text-input disabled :label="$t('First Name')" :model-value="profile.first_name" />
        </div>
        <div class="w-full pb-8 pr-6 sm:w-1/2">
          <front-text-input disabled :label="$t('Last Name')" :model-value="profile.last_name" />
        </div>
        <div class="w-full pb-8 pr-6 sm:w-1/2">
          <front-text-input v-model="profileForm.email" :error="profileForm.errors.email" :label="$t('Email')" />
        </div>
        <div class="w-full pb-8 pr-6 sm:w-1/2">
          <front-text-input
            v-model="profileForm.username"
            :error="profileForm.errors.username"
            :label="$t('Username')"
          />
        </div>
        <div class="w-full pb-8 pr-6 sm:w-1/2">
          <front-text-input v-model="profileForm.phone" :error="profileForm.errors.phone" :label="$t('Phone')" />
        </div>
        <div class="w-full pb-8 pr-6 sm:w-1/2">
          <front-text-input v-model="profileForm.address" :error="profileForm.errors.address" :label="$t('Address')" />
        </div>
        <div class="w-full pb-8 pr-6 sm:w-1/2">
          <front-text-input v-model="profileForm.city" :error="profileForm.errors.city" :label="$t('City')" />
        </div>
        <div class="w-full pb-8 pr-6 lg:w-1/2">
          <front-select-input
            v-model="profileForm.region"
            :error="profileForm.errors.region"
            :label="$t('Province/State')"
            :options="[null, ...regions]"
          />
        </div>
        <div class="w-full pb-8 pr-6 sm:w-1/2">
          <front-text-input
            v-model="profileForm.postal_code"
            :error="profileForm.errors.postal_code"
            :label="$t('Postal Code')"
          />
        </div>
        <div class="w-full pb-8 pr-6 sm:w-1/2">
          <label>
            <span class="form-label text-ts-front-label">{{ $t('Timezone') }}</span>
            <select-input
              v-model="profileForm.timezone"
              class="form-select mt-3 w-full rounded-lg border border-ts-front-border-light bg-ts-front-field-light p-3 pr-6 text-gray-700 focus:border-ts-front-border-dark focus:text-gray-800 focus:outline-none"
              :class="{ 'border-ts-front-border-error': profileForm.errors.timezone }"
            >
              <option :value="null" />
              <option v-for="(timezone, key) in timezones" :key="key" :value="timezone">
                {{ timezone }}
              </option>
            </select-input>
          </label>
        </div>
      </div>
      <div class="flex items-center justify-between border-t border-gray-200 px-6 py-5 lg:px-10 lg:py-8">
        <div v-if="has_organization" class="w-full pb-8 pr-6 lg:w-full">
          <label>
            <span class="form-label text-ts-front-label">{{ $t('Certificate Address') }}</span>
            <select
              v-model="profileForm.certificate_location_id"
              class="form-select mt-3 w-full rounded-lg border border-ts-front-border-light bg-ts-front-field-light p-3 pr-6 text-gray-700 focus:border-ts-front-border-dark focus:text-gray-800 focus:outline-none"
              :class="{ 'border-ts-front-border-error': profileForm.errors.certificate_location_id }"
            >
              <option :value="null">{{ organization_location }}</option>
              <option v-for="option in user_organization_locations" :key="option.id" :value="option.id">
                {{ option.address }}
              </option>
            </select>
          </label>
          <div v-if="profileForm.errors.certificate_location_id" class="form-error">
            {{ profileForm.errors.certificate_location_id }}
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between border-t border-gray-200 px-6 py-5 lg:px-10 lg:py-8">
        <front-loading-button :loading="profileForm.processing" type="submit">
          {{ $t('Update Profile') }}
        </front-loading-button>
      </div>
    </form>
  </div>
</template>

<script>
import SettingsLayout from './SettingsLayout.vue'
import FrontLayoutVue from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontSelectInput from '@/Shared/FrontSelectInput.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    FrontLoadingButton,
    FrontSelectInput,
    FrontTextInput,
    SelectInput,
  },
  layout: [FrontLayoutVue, SettingsLayout],
  props: {
    profile: Object,
    regions: Array,
    timezones: Object,
    has_organization: Boolean,
    organization_location: String,
    user_organization_locations: Array,
  },
  data() {
    return {
      profileForm: useForm(
        {
          email: this.profile ? this.profile.email : null,
          username: this.profile ? this.profile.username : null,
          phone: this.profile ? this.profile.phone : null,
          address: this.profile ? this.profile.address : null,
          city: this.profile ? this.profile.city : null,
          region: this.profile ? this.profile.region : null,
          postal_code: this.profile ? this.profile.postal_code : null,
          timezone: this.profile ? this.profile.timezone : null,
          certificate_location_id: this.profile ? this.profile.certificate_location_id : null,
        },
        'profileForm'
      ),
    }
  },
  methods: {
    updateProfile() {
      this.profileForm.put(this.route('front.settings.profile.update'))
    },
  },
}
</script>
