<template>
  <div class="w-full">
    <breadcrumb class="hidden md:inline" :name="value" />
    <breadcrumb class="md:hidden" :name="value" />
    <div>
      <InertiaLink
        v-if="backUrl"
        class="text-darkGray flex items-center px-3 pt-4 hover:font-semibold md:px-0"
        :href="backUrl"
      >
        <icon class="block h-3 w-3 fill-current" name="arrow-left" />
        <span class="ml-1">{{ $t('Back') }}</span>
      </InertiaLink>
    </div>
  </div>
</template>

<script>
import Breadcrumb from './Breadcrumb.vue'
import Icon from './Icon.vue'

export default {
  components: {
    Breadcrumb,
    Icon,
  },
  props: {
    value: String,
    backUrl: String,
  },
}
</script>
