<template>
  <layout :title="$t('Merge Organization')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('organizations.index')">
        {{ $t('Organizations') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ sourceOrganization.name }}
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Merge') }}
    </h1>
    <div class="max-w-sm overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <dynamic-search-input
              v-model="toOrganization"
              class="w-full"
              :error="form.errors.organization"
              :label="$t('Merge Into Organization')"
              :search-by="['name', 'email']"
              track-by="id"
              :url="route('organizations.search')"
            >
              <div v-if="targetOrganization" class="flex items-center justify-between">
                <div class="truncate">{{ targetOrganization.name }}</div>
              </div>
              <template #option="{ option }">
                <div class="flex items-center justify-between">
                  <div>{{ option.name }}</div>
                </div>
              </template>
            </dynamic-search-input>
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :disabled="!targetOrganization" :loading="form.processing" type="submit">
            {{ $t('Merge') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import DynamicSearchInput from '@/Shared/DynamicSearchInput.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    DynamicSearchInput,
    Layout,
    LoadingButton,
  },
  props: {
    sourceOrganization: Object,
    targetOrganization: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      toOrganization: this.targetOrganization,
      form: useForm({}, 'OrganizationMerge'),
    }
  },
  watch: {
    toOrganization: function (newOrganization) {
      if (newOrganization?.id === this.targetOrganization?.id) return
      if (newOrganization) {
        return this.$inertia.get(this.route('organizations.merge', this.sourceOrganization), {
          to: newOrganization.id,
        })
      }
      return this.$inertia.get(this.route('organizations.merge', this.sourceOrganization))
    },
  },
  methods: {
    submit() {
      if (
        this.targetOrganization &&
        confirm(
          this.$t(
            'Please confirm that you would like to complete this organization merge. This action is irreversible.'
          )
        )
      ) {
        this.$inertia.post(
          this.route('organizations.merge.store', {
            sourceOrganization: this.sourceOrganization.id,
            targetOrganization: this.targetOrganization.id,
          })
        )
      }
    },
  },
}
</script>
