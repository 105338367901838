<template>
  <dropdown
    :auto-close="autoClose"
    :boundary="boundary"
    :class="isTenantWorksite ? 'btn-red-gradient' : 'tenant-btn-1'"
    :opacity="opacity"
    :placement="placement"
    @before-toggle="beforeToggle"
  >
    <slot />
    <template #dropdown>
      <slot name="dropdown" />
    </template>
  </dropdown>
</template>

<script>
import Dropdown from './Dropdown.vue'
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  components: {
    Dropdown,
  },
  props: {
    placement: {
      type: String,
      default: 'bottom-end',
    },
    boundary: {
      type: String,
      default: 'scrollParent',
    },
    beforeToggle: {
      type: Function,
      default: () => {},
    },
    opacity: {
      type: String,
      default: 'opacity-25',
    },
    autoClose: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isTenantWorksite,
  },
}
</script>
