<template>
  <layout :title="$t('Resell Course')">
    <h1 class="text-xl">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('courses')">
        {{ $t('Courses') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Resell') }}
    </h1>
    <div v-if="courses.data.length">
      <div class="mt-4 text-lg text-gray-600">{{ $t("Please select a course you'd like to resell:") }}</div>
      <div class="-mb-8 -mr-8 mt-8 flex flex-wrap">
        <div v-for="course in courses.data" :key="course.id" class="flex w-1/2 pb-8 pr-8 xl:w-1/3">
          <div class="flex w-full flex-col justify-between rounded bg-white p-8 shadow">
            <div>
              <div class="text-lg font-bold leading-tight">{{ course.name }}</div>
              <div class="mt-2 text-sm text-gray-600">{{ course.tenant.name }}</div>
              <div class="mt-8">
                <div v-if="course.price" class="mt-2">{{ $t('Price: :price', { price: course.price }) }}</div>
                <div v-if="course.resell_commission" class="mt-2">
                  {{ $t('Commission rate: :commission%', { commission: course.resell_commission }) }}
                </div>
                <div v-if="course.resell_seats_percentage" class="mt-2">
                  {{ $t('Seats that can be resold: :percentage%', { percentage: course.resell_seats_percentage }) }}
                </div>
                <div v-if="course.resell_seats_quantity" class="mt-2">
                  {{ $t('Seats that can be resold: :quantity', { quantity: course.resell_seats_quantity }) }}
                </div>
                <div class="mt-2">{{ $t('Upcoming classes: :count', { count: course.classes_count }) }}</div>
              </div>
            </div>
            <div v-if="course.reselling === null" class="mt-8 flex">
              <button class="btn-blue" @click="submitRequest(course)">{{ $t('Submit Request') }}</button>
            </div>
            <div v-else-if="course.reselling.approved === false" class="mt-8 flex">
              <div
                class="mr-4 flex rounded-full border-2 border-yellow-600 px-6 py-3 text-sm font-bold text-yellow-600"
              >
                {{ $t('Pending Approval') }}
              </div>
              <button class="text-sm text-gray-600 hover:underline" type="button" @click="cancelRequest(course)">
                {{ $t('Cancel') }}
              </button>
            </div>
            <div v-else-if="course.reselling.approved" class="mt-8 flex">
              <div class="mr-4 flex rounded-full border-2 border-green-600 px-12 py-3 text-sm font-bold text-green-600">
                {{ $t('Reselling') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <pagination :links="courses.links" />
    </div>
    <div v-else class="mt-8 flex max-w-3xl flex-col items-center justify-center rounded bg-white py-48 shadow">
      <div class="text-center text-lg text-gray-600">{{ $t('No courses are available to resell.') }}</div>
    </div>
  </layout>
</template>

<script>
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'

export default {
  components: {
    Layout,
    Pagination,
  },
  props: {
    courses: Object,
  },
  methods: {
    submitRequest(course) {
      if (
        confirm(this.this.$t('Send request to resell the course, :course_name?', { course_name: `${course.name}` }))
      ) {
        this.$inertia.post(this.route('reselling_courses.store', course.id))
      }
    },
    cancelRequest(course) {
      if (confirm(this.$t('Are you sure you want to cancel this request?'))) {
        this.$inertia.delete(this.route('reselling_courses.destroy', course.reselling.id))
      }
    },
  },
}
</script>
