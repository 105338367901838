<template>
  <layout :title="$t('Edit Certificate')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('organizations.edit', organization)">
        {{ $t('Organizations') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Edit Certificate') }}
    </h1>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.years_valid" :error="form.errors.years_valid" :label="$t('Years Valid')" />
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Update Certificate') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Layout,
    LoadingButton,
    TextInput,
  },
  props: {
    organization: Object,
    certificate_template: Object,
  },
  data() {
    return {
      form: useForm({
        years_valid: this.certificate_template.years_valid,
      }),
    }
  },
  methods: {
    submit() {
      if (
        confirm(
          this.$t(
            'Are you sure you want to update this certificate? This will update expiry dates on the existing related certificates.'
          )
        )
      ) {
        this.form.put(
          this.route('organizations.certificate-templates.update', [this.organization, this.certificate_template])
        )
      }
    },
  },
}
</script>
