<template>
  <guest-layout :tenant="tenant" :title="`${user.name}'s Profile`">
    <div class="container m-auto md:w-5/6 xl:w-auto">
      <div class="mb-6 flex items-center justify-between border-b-2 border-ts-gray-500 pb-4">
        <h1 class="mr-4 text-xl font-semibold text-ts-gray-700 sm:mr-6 md:mr-8">{{ $t('Certification Profile') }}</h1>
        <front-button-link v-if="tenant.domain" :href="`https://${tenant.domain}`">
          {{ tenant.domain }}
        </front-button-link>
      </div>
      <div class="flex w-full flex-col items-center overflow-hidden rounded-lg bg-white shadow-lg lg:w-auto">
        <header
          class="w-full"
          :class="isTenantWorksite ? 'bg-gradient-to-r from-ts-orange-900 to-ts-red-900' : 'bg-blue-700'"
        >
          <div class="flex w-full flex-col items-center justify-center px-6 py-8">
            <img
              v-if="user.image_url"
              :alt="$t('Profile pic...')"
              class="h-40 w-40 cursor-pointer rounded-full border-8 border-white bg-white object-cover"
              :src="user.image_url"
            />
            <div
              v-else
              class="flex h-40 w-40 items-center justify-center rounded-full border-8 border-white bg-gray-500 text-6xl font-bold tracking-wider text-white"
            >
              {{ firstInitial }}
            </div>
            <h2 class="mb-3 mt-4 text-2xl font-bold text-white">{{ user.name }}</h2>
            <h3 v-if="user.organization" class="text-lg font-semibold text-white opacity-75">
              {{ user.organization.name }}
            </h3>
          </div>
        </header>
        <main class="flex w-full flex-col items-center p-4 lg:max-w-2xl lg:p-6">
          <div class="mx-auto w-full lg:hidden">
            <div class="grid w-full gap-4">
              <p class="mb-2 mt-4 text-center text-2xl font-semibold">{{ $t('Certificates') }}</p>
              <div v-if="!certificates.data.length">{{ $t('No certificates earned') }}</div>
              <certificate-card
                v-for="certificate in certificates.data"
                v-else
                :key="certificate.id"
                :certificate="certificate"
              />
            </div>
          </div>

          <div v-if="training_records.data.length > 0" class="mx-auto mt-10 w-full lg:hidden">
            <p class="mb-6 text-center text-2xl font-semibold">{{ $t('Training Records') }}</p>
            <div class="grid w-full gap-4">
              <certificate-card
                v-for="trainingRecord in training_records.data"
                :key="trainingRecord.id"
                :certificate="trainingRecord"
              />
            </div>
          </div>

          <div class="hidden w-full overflow-x-auto rounded-md shadow lg:block">
            <table class="front-table w-full">
              <thead>
                <tr>
                  <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left text-ts-gray-text">
                    {{ $t('Certification') }}
                  </th>
                  <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left text-ts-gray-text">{{ $t('Issue Date') }}</th>
                  <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left text-ts-gray-text">{{ $t('Expiry Date') }}</th>
                  <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left text-ts-gray-text">{{ $t('Cert. ID') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="certificates.data.length === 0">
                  <td class="border-t px-6 py-4" colspan="5">{{ $t('No certificates found.') }}</td>
                </tr>
                <tr
                  v-for="(certificate, key) in certificates.data"
                  v-else
                  :key="key"
                  class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
                >
                  <td class="font-semibold text-ts-gray-text-bold">{{ certificate.name }}</td>
                  <td class="w-min border-t px-4 align-middle">
                    <front-date :timestamp="certificate.issued_at" />
                  </td>
                  <td class="w-min border-t px-4 align-middle">
                    <front-date :timestamp="certificate.expires_at" />
                  </td>
                  <td class="w-min border-t px-4 align-middle">{{ certificate.certificate_number }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="w-full"><front-pagination :links="certificates.links" /></div>

          <div
            v-if="training_records.data.length > 0"
            class="mt-8 hidden w-full overflow-x-auto rounded-md shadow lg:block"
          >
            <table class="front-table w-full">
              <thead>
                <tr>
                  <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left text-ts-gray-text">
                    {{ $t('Training Record') }}
                  </th>
                  <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left text-ts-gray-text">{{ $t('Issue Date') }}</th>
                  <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left text-ts-gray-text">{{ $t('Expiry Date') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(trainingRecord, key) in training_records.data"
                  :key="key"
                  class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
                >
                  <td class="font-semibold text-ts-gray-text-bold">{{ trainingRecord.name }}</td>
                  <td class="w-min border-t px-4 align-middle">
                    <front-date :timestamp="trainingRecord.issued_at" />
                  </td>
                  <td class="w-min border-t px-4 align-middle">
                    <front-date :timestamp="trainingRecord.expires_at" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="training_records.data.length > 0" class="w-full">
            <front-pagination :links="training_records.links" />
          </div>
        </main>
      </div>
    </div>
  </guest-layout>
</template>

<script>
import CertificateCard from './CertificateCard.vue'
import FrontButtonLink from '@/Shared/FrontButtonLink.vue'
import FrontDate from '@/Shared/FrontDate.vue'
import FrontPagination from '@/Shared/FrontPagination.vue'
import GuestLayout from '@/Shared/GuestLayout.vue'
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  components: { CertificateCard, FrontButtonLink, FrontDate, FrontPagination, GuestLayout },
  props: {
    tenant: Object,
    user: Object,
    certificates: Object,
    training_records: Object,
  },
  computed: {
    isTenantWorksite,
    firstInitial: function () {
      return this.user?.name?.charAt(0)
    },
  },
}
</script>
