<template>
  <svg
    height="55.661"
    viewBox="0 0 237 55.661"
    width="237"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient id="linear-gradient" gradientUnits="objectBoundingBox" x1="0.5" x2="0.5" y1="0.948" y2="0.416">
        <stop offset="0" stop-color="#760027" />
        <stop offset="0.013" stop-color="#770027" />
        <stop offset="0.445" stop-color="#9e002b" />
        <stop offset="0.789" stop-color="#b7002e" />
        <stop offset="1" stop-color="#c0002f" />
      </linearGradient>
      <linearGradient id="linear-gradient-2" gradientUnits="objectBoundingBox" x1="0.5" x2="0.5" y1="0.982" y2="0.113">
        <stop offset="0" stop-color="#d73136" />
        <stop offset="0.046" stop-color="#d52d35" />
        <stop offset="0.405" stop-color="#c91431" />
        <stop offset="0.734" stop-color="#c2052f" />
        <stop offset="1" stop-color="#c0002f" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3"
        gradientUnits="objectBoundingBox"
        x1="0.789"
        x2="0.319"
        y1="0.825"
        y2="0.328"
      >
        <stop offset="0" stop-color="#d73136" />
        <stop offset="0.154" stop-color="#d02233" />
        <stop offset="0.429" stop-color="#c70f31" />
        <stop offset="0.709" stop-color="#c1032f" />
        <stop offset="1" stop-color="#c0002f" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-4"
        x1="0.198"
        x2="0.886"
        xlink:href="#linear-gradient-3"
        y1="0.826"
        y2="0.119"
      />
    </defs>
    <g id="Group_5058" data-name="Group 5058" transform="translate(23.775 -320.882)">
      <g id="Group_5053" data-name="Group 5053" transform="translate(102.433 367.669)">
        <path
          id="Path_5907"
          d="M121.8,381.728a4.167,4.167,0,0,1-1.165-.146,3.557,3.557,0,0,1-.858-.366,2.686,2.686,0,0,1-.593-.473,5.264,5.264,0,0,1-.38-.452l.959-.759a2.082,2.082,0,0,0,.313.326,2.582,2.582,0,0,0,.466.312,2.915,2.915,0,0,0,.606.24,2.658,2.658,0,0,0,.719.094,1.926,1.926,0,0,0,.758-.127,1.447,1.447,0,0,0,.459-.293.943.943,0,0,0,.227-.327.765.765,0,0,0,.06-.24.668.668,0,0,0-.328-.579,3.606,3.606,0,0,0-.811-.393q-.486-.173-1.052-.352a4.924,4.924,0,0,1-1.052-.473,2.7,2.7,0,0,1-.811-.746,1.924,1.924,0,0,1-.327-1.158,2.106,2.106,0,0,1,.113-.626,1.777,1.777,0,0,1,.412-.679,2.447,2.447,0,0,1,.812-.546,3.315,3.315,0,0,1,1.326-.227,3.87,3.87,0,0,1,1.1.14,3.385,3.385,0,0,1,.792.34,2.546,2.546,0,0,1,.533.42,2.606,2.606,0,0,1,.3.366l-.944.705a1.941,1.941,0,0,0-.865-.572,3.214,3.214,0,0,0-1.013-.173,1.485,1.485,0,0,0-.466.074,1.379,1.379,0,0,0-.405.206,1.248,1.248,0,0,0-.293.3.642.642,0,0,0-.113.366.809.809,0,0,0,.327.686,3.133,3.133,0,0,0,.811.426c.324.12.678.233,1.06.34a5.01,5.01,0,0,1,1.059.426,2.57,2.57,0,0,1,.812.692,1.818,1.818,0,0,1,.326,1.132,1.87,1.87,0,0,1-.139.645,1.935,1.935,0,0,1-.473.692,2.735,2.735,0,0,1-.886.553A3.662,3.662,0,0,1,121.8,381.728Z"
          data-name="Path 5907"
          fill="#abbfc9"
          transform="translate(-118.807 -373.739)"
        />
        <path
          id="Path_5908"
          d="M146.084,373.89l2.809,7.709h-1.411l-.505-1.371h-3.688L142.8,381.6h-1.411l2.808-7.709Zm-2.356,5.126h2.809l-1.4-3.848Z"
          data-name="Path 5908"
          fill="#abbfc9"
          transform="translate(-121.4 -373.756)"
        />
        <path
          id="Path_5909"
          d="M167.568,377.511h3.568v1.225h-3.568V381.6h-1.3V373.89h5.165v1.225h-3.861Z"
          data-name="Path 5909"
          fill="#abbfc9"
          transform="translate(-124.257 -373.756)"
        />
        <path
          id="Path_5910"
          d="M193.99,380.374V381.6h-5.164V373.89h5.164v1.225h-3.86v2.1H193.7v1.224H190.13v1.93Z"
          data-name="Path 5910"
          fill="#abbfc9"
          transform="translate(-126.848 -373.756)"
        />
        <path
          id="Path_5911"
          d="M211.072,375.115V373.89h6.284v1.225h-2.49V381.6h-1.3v-6.484Z"
          data-name="Path 5911"
          fill="#abbfc9"
          transform="translate(-129.403 -373.756)"
        />
        <path
          id="Path_5912"
          d="M239.569,373.89h1.491L238.277,379v2.6h-1.3V379l-2.783-5.113h1.491l1.944,3.608Z"
          data-name="Path 5912"
          fill="#abbfc9"
          transform="translate(-132.058 -373.756)"
        />
      </g>
      <g id="Group_5054" data-name="Group 5054" transform="translate(40.422 337.443)">
        <path
          id="Path_5913"
          d="M67.129,339.98q.915,3.469,1.888,7.34T70.9,354.66l3.241-14.68h6.1L74.6,362.058H67.586q-.763-2.821-1.544-5.681T64.5,350.656q-.763,2.861-1.544,5.721t-1.545,5.681H54.393L48.751,339.98h6.1l3.241,14.68q.915-3.469,1.887-7.341t1.888-7.34Z"
          data-name="Path 5913"
          fill="#2e383d"
          transform="translate(-48.751 -339.637)"
        />
        <path
          id="Path_5914"
          d="M85.454,351.012a10.963,10.963,0,0,1,.915-4.473,11.387,11.387,0,0,1,6.2-6.052,12.28,12.28,0,0,1,9.208,0,11.436,11.436,0,0,1,6.177,6.052,11.389,11.389,0,0,1,0,8.946,11.443,11.443,0,0,1-6.177,6.052,12.291,12.291,0,0,1-9.208,0,11.395,11.395,0,0,1-6.2-6.052A10.964,10.964,0,0,1,85.454,351.012Zm17.616,0a6.39,6.39,0,0,0-.459-2.428,6.216,6.216,0,0,0-1.258-1.97,6.058,6.058,0,0,0-1.887-1.338,5.6,5.6,0,0,0-4.614,0,6.047,6.047,0,0,0-1.887,1.338,6.234,6.234,0,0,0-1.259,1.97,6.672,6.672,0,0,0,0,4.857,6.233,6.233,0,0,0,1.259,1.97,6.075,6.075,0,0,0,1.887,1.338,5.608,5.608,0,0,0,4.614,0,6.086,6.086,0,0,0,1.887-1.338,6.215,6.215,0,0,0,1.258-1.97A6.391,6.391,0,0,0,103.07,351.012Z"
          data-name="Path 5914"
          fill="#2e383d"
          transform="translate(-52.966 -339.592)"
        />
        <path
          id="Path_5915"
          d="M127.485,362.063l-4.9-5.91h-1.721v5.91h-5.606v-22.04h8.922a12.17,12.17,0,0,1,4.347,1.028,7.571,7.571,0,0,1,1.811,1.047,6.261,6.261,0,0,1,1.221,1.389,7.969,7.969,0,0,1,.915,1.98,8.852,8.852,0,0,1,.361,2.663,8.5,8.5,0,0,1-.363,2.609,6.921,6.921,0,0,1-.975,1.961,7.017,7.017,0,0,1-1.377,1.426,8.609,8.609,0,0,1-1.568.971l5.5,6.966Zm-6.617-11.1h3.376a3.006,3.006,0,0,0,1.918-.634,2.651,2.651,0,0,0,.806-2.207,2.767,2.767,0,0,0-.806-2.264,2.955,2.955,0,0,0-1.918-.652h-3.376Z"
          data-name="Path 5915"
          fill="#2e383d"
          transform="translate(-56.389 -339.642)"
        />
        <path
          id="Path_5916"
          d="M170.093,354.654a10.452,10.452,0,0,0,2.154,1.754,5.817,5.817,0,0,0,3.146.8,4.753,4.753,0,0,0,1.411-.171,2.881,2.881,0,0,0,.838-.4,1.2,1.2,0,0,0,.42-.514,1.393,1.393,0,0,0,.114-.513c0-.432-.28-.786-.838-1.066a16.49,16.49,0,0,0-2.1-.837q-1.259-.417-2.745-.931a11.984,11.984,0,0,1-2.745-1.352,7.279,7.279,0,0,1-2.1-2.13,5.831,5.831,0,0,1-.839-3.234,6.735,6.735,0,0,1,.343-1.922,5.609,5.609,0,0,1,1.259-2.131,7.384,7.384,0,0,1,2.478-1.712,9.682,9.682,0,0,1,3.966-.7,10.672,10.672,0,0,1,3.965.644,11.019,11.019,0,0,1,2.632,1.438,8.113,8.113,0,0,1,1.869,2.082l-3.851,3.043a6.953,6.953,0,0,0-1.3-1.163,5.032,5.032,0,0,0-1.2-.59,4.8,4.8,0,0,0-1.163-.229q-.572-.037-1.183-.038a2.748,2.748,0,0,0-1.506.4,1.263,1.263,0,0,0-.629,1.124,1.386,1.386,0,0,0,.839,1.2,11.859,11.859,0,0,0,2.1.9q1.259.421,2.745.953a13.753,13.753,0,0,1,2.746,1.335,7.12,7.12,0,0,1,2.1,2.04,5.419,5.419,0,0,1,.839,3.107,5.6,5.6,0,0,1-.438,2,6.376,6.376,0,0,1-1.43,2.136,8.079,8.079,0,0,1-2.65,1.715,10.608,10.608,0,0,1-4.137.705,12.19,12.19,0,0,1-3.756-.514,11.208,11.208,0,0,1-2.688-1.24A7.543,7.543,0,0,1,167,359.115a9.059,9.059,0,0,1-.952-1.373Z"
          data-name="Path 5916"
          fill="#2e383d"
          transform="translate(-62.222 -339.592)"
        />
        <path
          id="Path_5917"
          d="M190.946,339.98h5.606v22.078h-5.606Z"
          data-name="Path 5917"
          fill="#2e383d"
          transform="translate(-65.081 -339.637)"
        />
        <path
          id="Path_5918"
          d="M201.759,345.209v-5.186h18.569v5.186h-6.482v16.853h-5.6V345.209Z"
          data-name="Path 5918"
          fill="#2e383d"
          transform="translate(-66.323 -339.642)"
        />
        <path
          id="Path_5919"
          d="M241.95,356.876v5.186h-15.6v-22.04h15.6v5.186h-9.99V348.6h9.189v5.185H231.96v3.088Z"
          data-name="Path 5919"
          fill="#2e383d"
          transform="translate(-69.147 -339.642)"
        />
        <rect
          id="Rectangle_6779"
          data-name="Rectangle 6779"
          fill="#2e383d"
          height="22.167"
          transform="translate(81.363 0.254)"
          width="6.156"
        />
        <path
          id="Path_5920"
          d="M162.656,339.937h-6.949l-8.075,11.032L155.707,362h6.949l-8.075-11.032Z"
          data-name="Path 5920"
          fill="#2e383d"
          transform="translate(-60.107 -339.632)"
        />
      </g>
      <g id="Group_5057" data-name="Group 5057" transform="translate(-23.775 320.882)">
        <path
          id="Path_5921"
          d="M23.066,351.618l-1.262-.379a.484.484,0,0,1-.335-.553l.868-4.6a.483.483,0,0,0-.608-.554l-3.939,1.133a.484.484,0,0,1-.584-.288l-.492-1.259a.522.522,0,0,0-.852-.182c-.227.225-4.4,4.488-4.4,4.488s1.164-7.421,1.574-10.011a.47.47,0,0,0-.674-.5l-2.622,1.3a.482.482,0,0,1-.635-.2l-3.339-5.929a.484.484,0,0,0-.843,0L1.586,340.04a.484.484,0,0,1-.638.2l-2.61-1.307a.474.474,0,0,0-.682.5c.223,1.426,1.569,10,1.569,10s-4.192-4.266-4.419-4.487a.508.508,0,0,0-.824.171c-.233.563-.517,1.269-.517,1.269a.484.484,0,0,1-.584.288l-3.938-1.133a.484.484,0,0,0-.609.554l.868,4.6a.484.484,0,0,1-.335.553l-1.263.379a.473.473,0,0,0-.2.79l2.818,2.777,6.831,6.831a1.33,1.33,0,0,0,1.883,0l6.406-6.406,6.406,6.407a1.332,1.332,0,0,0,1.883,0l8.084-8.084,1.548-1.525A.473.473,0,0,0,23.066,351.618Z"
          data-name="Path 5921"
          fill="url(#linear-gradient)"
          transform="translate(22.507 -322.37)"
        />
        <g id="Group_5056" data-name="Group 5056">
          <g id="Group_5055" data-name="Group 5055">
            <path
              id="Path_5922"
              d="M4.063,376.543a3.655,3.655,0,0,1-2.588-1.069L-22.7,351.292a3.633,3.633,0,0,1-1.071-2.588,3.633,3.633,0,0,1,1.071-2.588L1.479,321.934a3.709,3.709,0,0,1,5.176,0l24.179,24.182a3.66,3.66,0,0,1,0,5.176L6.651,375.472A3.647,3.647,0,0,1,4.063,376.543Zm-.768-2.891a1.087,1.087,0,0,0,1.535,0L29.014,349.47a1.084,1.084,0,0,0,0-1.535L4.834,323.754a1.145,1.145,0,0,0-1.536,0l-24.183,24.181a1.078,1.078,0,0,0-.317.767,1.078,1.078,0,0,0,.317.767L3.295,373.652Z"
              data-name="Path 5922"
              fill="url(#linear-gradient-2)"
              transform="translate(23.775 -320.882)"
            />
          </g>
        </g>
        <path
          id="Path_5923"
          d="M4.3,361.846"
          data-name="Path 5923"
          fill="#fff"
          transform="translate(20.551 -325.586)"
        />
        <path
          id="Path_5924"
          d="M-5.2,346.356a.522.522,0,0,0-.852.182l-.492,1.259a.484.484,0,0,1-.584.288l-3.938-1.133a.484.484,0,0,0-.609.554l.868,4.6a.483.483,0,0,1-.335.553l-1.261.379a.472.472,0,0,0-.2.79l1.547,1.525,8.108,8.086a1.332,1.332,0,0,0,1.882,0l6.4-6.4Z"
          data-name="Path 5924"
          fill="url(#linear-gradient-3)"
          transform="translate(22.508 -323.79)"
        />
        <path
          id="Path_5925"
          d="M18.215,346.356a.522.522,0,0,1,.852.182l.492,1.259a.484.484,0,0,0,.584.288l3.938-1.133a.484.484,0,0,1,.609.554l-.869,4.6a.485.485,0,0,0,.336.553l1.261.379a.473.473,0,0,1,.2.79l-1.547,1.525-8.108,8.086a1.332,1.332,0,0,1-1.882,0l-6.4-6.4Z"
          data-name="Path 5925"
          fill="url(#linear-gradient-4)"
          transform="translate(20.162 -323.79)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {}
</script>
