<template>
  <report-layout>
    <!-- CARD ROW -->
    <div class="-mx-2 flex flex-wrap">
      <!-- Total -->
      <div class="flex-1 px-2 py-4">
        <div class="flex h-full items-center justify-between rounded bg-white p-10 shadow-lg">
          <div>
            <div class="flex h-18 w-18 items-center justify-center rounded-full p-2" style="background-color: #f8f8f8">
              <icon class="h-12 w-12" name="graduation-cap" style="fill: #9fa0a1" />
            </div>
          </div>
          <div>
            <div class="-pr-2 mb-2 pl-2 text-3xl text-black">{{ form_aggregates.total }}</div>
            <div class="-pr-2 pl-2" style="color: #696a6c">{{ $t('Total') }}</div>
          </div>
        </div>
      </div>

      <!-- Started -->
      <div class="flex-1 px-2 py-4">
        <div class="flex h-full items-center justify-between rounded bg-white p-10 shadow-lg">
          <div>
            <div
              class="flex h-18 w-18 items-center justify-center rounded-full bg-ts-orange-100 p-2 text-3xl text-ts-orange-600"
            >
              <span>{{ form_aggregates.started || 0 }}</span>
            </div>
          </div>
          <div>
            <div class="-pr-2 mb-2 pl-2" style="color: #696a6c">{{ $t('Started') }}</div>
          </div>
        </div>
      </div>

      <!-- Completed -->
      <div class="flex-1 px-2 py-4">
        <div class="flex h-full items-center justify-between rounded bg-white p-10 shadow-lg">
          <div>
            <div
              class="flex h-18 w-18 items-center justify-center rounded-full bg-ts-blue-100 p-2 text-3xl text-ts-blue-600"
            >
              <span>{{ form_aggregates.completed || 0 }}</span>
            </div>
          </div>
          <div>
            <div class="-pr-2 mb-2 pl-2" style="color: #696a6c">{{ $t('Completed') }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Forms Report Section -->
    <section class="mt-16 w-full">
      <div class="flex justify-between">
        <h3 class="mb-6 text-xl" style="color: #404624">{{ $t('Forms Report') }}</h3>
        <div>
          <button style="color: #0c5e85" @click="resetFormsFilters()">{{ $t('Reset all') }}</button>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th
                      class="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                    >
                      {{ $t('Person') }}
                    </th>
                    <th
                      class="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                    >
                      <front-select-input v-model="form.table_filters.formTemplate">
                        <option :value="null">{{ $t('Form') }}</option>
                        <option
                          v-for="form_template in form_templates"
                          :key="form_template.id"
                          :value="form_template.id"
                        >
                          {{ form_template.title }}
                        </option>
                      </front-select-input>
                    </th>
                    <th
                      class="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                    >
                      {{ $t('Started At') }}
                    </th>
                    <th
                      class="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                    >
                      {{ $t('Last Updated') }}
                    </th>
                    <th
                      class="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                    >
                      {{ $t('Completed At') }}
                    </th>
                    <th
                      class="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                    />
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="forms.data.length === 0">
                    <td
                      class="whitespace-nowrap bg-white px-6 py-4 text-center text-sm leading-5 text-ts-front-label"
                      colspan="6"
                    >
                      {{ $t('No forms found.') }}
                    </td>
                  </tr>
                  <tr v-for="formObject in forms.data" :key="formObject.id" class="bg-white">
                    <td class="whitespace-nowrap px-6 py-4 text-sm leading-5 text-ts-front-label">
                      <span>{{ formObject.user ?? '-' }}</span>
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm leading-5 text-ts-front-label">
                      {{ formObject.name }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm leading-5 text-ts-front-label">
                      {{ formObject.started_at }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm leading-5 text-ts-front-label">
                      {{ formObject.last_updated ?? '-' }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm leading-5 text-ts-front-label">
                      {{ formObject.completed_at ?? '-' }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm leading-5 text-ts-front-label">
                      <InertiaLink :href="route('front.manager.reports.ehs-forms.view', formObject.id)">
                        {{ $t('View') }}
                      </InertiaLink>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <front-pagination :links="forms.links" :preserve-scroll="true" :preserve-state="true" />
    </section>
  </report-layout>
</template>

<script>
import ReportLayout from '../ReportLayout.vue'
import FrontPagination from '@/Shared/FrontPagination.vue'
import FrontSelectInput from '@/Shared/FrontSelectInput.vue'
import Icon from '@/Shared/Icon.vue'
import _debounce from 'lodash/debounce'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'

export default {
  components: { ReportLayout, FrontPagination, FrontSelectInput, Icon },
  props: {
    form_aggregates: Object,
    forms: Object,
    table_filters: Object,
    form_templates: Object,
  },
  data() {
    return {
      form: {
        table_filters: {
          formTemplate: this.filters?.table_filters?.formTemplate || null,
        },
      },
    }
  },
  watch: {
    form: {
      handler: _debounce(function () {
        let query = {
          table_filters: _pickBy(this.form.table_filters),
        }

        this.$inertia.get(
          this.route('front.manager.reports.forms.show'),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveScroll: true,
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
  },
  methods: {
    resetFormsFilters() {
      this.form.table_filters = _mapValues(this.form.table_filters, () => null)
    },
  },
}
</script>
