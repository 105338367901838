<template>
  <report-layout>
    <div class="w-full">
      <!-- FILTER ROW -->
      <div class="flex justify-end">
        <!-- right -->
        <div>
          <select
            v-model="form.table_filters.time_period"
            class="form-select mt-1 rounded-lg border border-ts-front-border-light bg-ts-front-field-light text-gray-700 focus:border-ts-front-border-dark focus:text-gray-800 focus:outline-none"
          >
            <option :value="null">{{ $t('All') }}</option>
            <option v-for="(time_period, key, i) in time_periods" :key="i" :value="key">
              {{ time_period }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <!-- CARD ROW -->
    <div class="-mx-2 flex flex-wrap">
      <!-- Assigned -->
      <div class="flex-1 px-2 py-4">
        <div class="flex h-full items-center justify-between rounded bg-white p-10 shadow-lg">
          <div>
            <div class="flex h-18 w-18 items-center justify-center rounded-full p-2" style="background-color: #f8f8f8">
              <icon class="h-12 w-12" name="graduation-cap" style="fill: #9fa0a1" />
            </div>
          </div>
          <div>
            <div class="mb-2 text-3xl text-black">{{ enrolment_aggregates.assigned }}</div>
            <div style="color: #696a6c">{{ $t('Assigned') }}</div>
          </div>
        </div>
      </div>

      <!-- Incomplete -->
      <div class="flex-1 px-2 py-4">
        <div class="h-full rounded bg-white p-10 shadow-lg">
          <div class="mb-4 w-full">
            <span class="text-3xl text-black">{{ percentOfEnrolments('new') }}%</span>
            <span style="color: #696a6c">{{ $t('Incomplete') }}</span>
          </div>
          <div class="flex w-full items-center">
            <progress-bar
              class="mr-2 w-3/4"
              fill-bg-class="bg-ts-orange-600"
              :model-value="percentOfEnrolments('new')"
              track-bg-class="bg-ts-orange-100"
            />
            <span style="color: #9fa0a1">{{ enrolment_aggregates.new }}/{{ enrolment_aggregates.assigned }}</span>
          </div>
        </div>
      </div>

      <!-- In Progress -->
      <div class="flex-1 px-2 py-4">
        <div class="h-full rounded bg-white p-10 shadow-lg">
          <div class="mb-4 w-full">
            <span class="text-3xl text-black">{{ percentOfEnrolments('in progress') }}%</span>
            <span style="color: #696a6c">{{ $t('In progress') }}</span>
          </div>
          <div class="flex w-full items-center">
            <progress-bar
              class="mr-2 w-3/4"
              fill-bg-class="bg-ts-blue-600"
              :model-value="percentOfEnrolments('in progress')"
              track-bg-class="bg-ts-blue-100"
            />
            <span style="color: #9fa0a1">
              {{ enrolment_aggregates['in progress'] }}/{{ enrolment_aggregates.assigned }}
            </span>
          </div>
        </div>
      </div>

      <!-- Completed -->
      <div class="flex-1 px-2 py-4">
        <div class="h-full rounded bg-white p-10 shadow-lg">
          <div class="mb-4 w-full">
            <span class="text-3xl text-black">{{ percentOfEnrolments('complete') }}%</span>
            <span style="color: #696a6c">{{ $t('Complete') }}</span>
          </div>
          <div class="flex w-full items-center">
            <progress-bar
              class="mr-2 w-3/4"
              fill-bg-class="bg-ts-gray-text-bold"
              :model-value="percentOfEnrolments('complete')"
              track-bg-class="bg-ts-gray-100"
            />
            <span style="color: #9fa0a1">{{ enrolment_aggregates.complete }}/{{ enrolment_aggregates.assigned }}</span>
          </div>
        </div>
      </div>
    </div>

    <section class="mt-16 w-full">
      <div class="flex justify-between">
        <h3 class="mb-6 text-xl" style="color: #404624">{{ $t('Active Enrolments') }}</h3>
        <div>
          <button class="pr-4" style="color: #0c5e85" @click="resetActiveEnrolmentFilters()">
            {{ $t('Reset Filters') }}
          </button>
          <dropdown :auto-close="true" class="pl-1" placement="bottom-end" style="color: #0c5e85">
            <div class="flex cursor-pointer select-none items-center">
              <div>
                <span>{{ $t('Bulk Actions') }}</span>
              </div>
              <icon class="-my-1 h-5 w-5" name="chevron-down" style="fill: #0c5e85" />
            </div>
            <template #dropdown>
              <div class="mt-2 rounded border bg-white py-2 text-sm shadow-lg">
                <button class="block px-4 py-2" style="color: #0c5e85" @click="sendTrainingReminder()">
                  {{ $t('Send Reminder') }}
                </button>
                <button
                  v-if="$page.props.auth.user.email"
                  class="block px-4 py-2"
                  style="color: #0c5e85"
                  @click="showRecipientsModal = true"
                >
                  {{ $t('Download Report') }}
                </button>
              </div>
            </template>
          </dropdown>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th
                      class="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                    >
                      {{ $t('Person') }}
                    </th>
                    <th
                      class="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                    >
                      <front-select-input v-model="form.table_filters.course">
                        <option :value="null">{{ $t('All courses') }}</option>
                        <option v-for="course in courses" :key="course.id" :value="course.id">
                          {{ course.name }}
                        </option>
                      </front-select-input>
                    </th>
                    <th
                      class="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                    >
                      <front-select-input v-model="form.table_filters.group">
                        <option :value="null">{{ $t('Group') }}</option>
                        <option v-for="group in groups" :key="group.id" :value="group.id">
                          {{ group.name }}
                        </option>
                      </front-select-input>
                    </th>
                    <th
                      class="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                    >
                      <front-select-input v-model="form.table_filters.class_location">
                        <option :value="null">{{ $t('Location') }}</option>
                        <option v-for="location in locations" :key="location.id" :value="location.id">
                          {{ location.name }}
                        </option>
                      </front-select-input>
                    </th>
                    <th
                      class="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                    >
                      {{ $t('Enrol Date') }}
                    </th>
                    <th
                      class="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                    >
                      <front-select-input v-model="form.table_filters.status">
                        <option :value="null">{{ $t('Status') }}</option>
                        <option v-for="(status, val, i) in statuses" :key="i" :value="val">
                          {{ status }}
                        </option>
                      </front-select-input>
                    </th>
                    <th
                      class="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                      colspan="2"
                    >
                      {{ $t('Score') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="active_enrolments.data.length === 0">
                    <td
                      class="whitespace-nowrap bg-white px-6 py-4 text-center text-sm leading-5 text-ts-front-label"
                      colspan="6"
                    >
                      {{ $t('No enrolments found.') }}
                    </td>
                  </tr>
                  <tr v-for="enrolment in active_enrolments.data" :key="enrolment.id" class="bg-white">
                    <td class="whitespace-nowrap px-6 py-4 text-base font-medium leading-5 text-ts-gray-text-bold">
                      {{ enrolment.user.name }}
                    </td>
                    <td
                      class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm leading-5 text-ts-front-label hover:underline"
                      @click="onClickEnrolment(enrolment)"
                    >
                      {{ enrolment.course.name }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm leading-5 text-ts-front-label">
                      <span v-if="enrolment.user.groups.length === 0">-</span>
                      <span v-if="enrolment.user.groups.length > 0">
                        {{ enrolment.user.groups[0].name }}
                      </span>
                      <span v-if="enrolment.user.groups.length > 1">
                        and {{ enrolment.user.groups.length - 1 }} more
                      </span>
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm leading-5 text-ts-front-label">
                      <template v-for="(enrolmentContent, index) in enrolment.enrolmentContents" :key="index">
                        <span v-if="enrolmentContent.content.type === 'instructor-led-classroom'">
                          {{ enrolmentContent.content.location.name }}
                        </span>
                      </template>
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm leading-5 text-ts-front-label">
                      {{ enrolment.enrolled_at }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm leading-5 text-ts-front-label">
                      <span class="rounded-sm p-1 uppercase" :class="statusBadgeStyle(enrolment.status)">
                        {{ enrolment.status }}
                      </span>
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm leading-5 text-ts-front-label">
                      {{ enrolment.score || '-' }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm leading-5 text-ts-front-label">
                      <button v-if="enrolment.can.unenrol" class="underline" @click="unenrol(enrolment)">
                        Unenrol
                      </button>
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm leading-5 text-ts-front-label">
                      <template v-for="(enrolmentContent, index) in enrolment.enrolmentContents" :key="index">
                        <InertiaLink
                          v-if="
                            enrolment.can.changeClass && enrolmentContent.content.type === 'instructor-led-classroom'
                          "
                          class="underline"
                          :href="route('front.manager.contents.classes', [enrolment.id, enrolmentContent.id])"
                        >
                          {{ enrolmentContent.content.dates ? $t('Edit Class') : $t('Select Class') }}
                        </InertiaLink>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <front-pagination :links="active_enrolments.links" :preserve-scroll="true" :preserve-state="true" />
    </section>
    <front-modal
      class="rounded text-base"
      :show="showRecipientsModal"
      width="auto"
      @close="showRecipientsModal = false"
    >
      <div class="max-w-md overflow-hidden rounded bg-white p-6 shadow md:p-10 lg:p-12">
        <div class="mb-4 text-center">
          <h1 class="mt-4 text-3xl font-bold">{{ $t('Export Recipients') }}</h1>
        </div>
        <div class="py-4">
          {{ $t('Please enter any additional emails this report should be sent to.') }}
          <form class="">
            <text-input v-model="$page.props.auth.user.email" class="mt-6" disabled label="Recipient 1" />
            <label class="form-label mt-5" for="last-name">{{ $t('Recipient 2:') }}</label>
            <input id="last-name" v-model="form.additional_recipients[0]" class="form-input" type="email" />
            <div v-if="form.errors['additional_recipients.0']" class="form-error">
              {{ $t('This email must be valid.') }}
            </div>
            <label class="form-label mt-5" for="last-name">{{ $t('Recipient 3:') }}</label>
            <input id="last-name" v-model="form.additional_recipients[1]" class="form-input" type="email" />
            <div v-if="form.errors['additional_recipients.1']" class="form-error">
              {{ $t('This email must be valid.') }}
            </div>
          </form>
        </div>
        <div class="flex items-center justify-center bg-white px-2 pt-8">
          <button
            class="mx-2 rounded-full border border-qualify-red-500 px-12 py-4 text-lg font-semibold text-qualify-red-500"
            type="button"
            @click=";(form.additional_recipients = []), (showRecipientsModal = false)"
          >
            <slot name="cancel-button">{{ $t('Cancel') }}</slot>
          </button>
          <loading-button
            class="rounded-full bg-qualify-red-500 px-12 py-4 text-lg font-semibold text-white"
            type="button"
            @click="exportReport()"
          >
            <slot name="confirm-button">{{ $t('Export') }}</slot>
          </loading-button>
        </div>
      </div>
    </front-modal>
  </report-layout>
</template>

<script>
import ReportLayout from '../ReportLayout.vue'
import Dropdown from '@/Shared/Dropdown.vue'
import FrontModal from '@/Shared/FrontModal.vue'
import FrontPagination from '@/Shared/FrontPagination.vue'
import FrontSelectInput from '@/Shared/FrontSelectInput.vue'
import Icon from '@/Shared/Icon.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import ProgressBar from '@/Shared/ProgressBar.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'
import _debounce from 'lodash/debounce'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'

export default {
  components: {
    ReportLayout,
    Dropdown,
    FrontModal,
    FrontPagination,
    FrontSelectInput,
    Icon,
    LoadingButton,
    ProgressBar,
    TextInput,
  },
  props: {
    filters: Object,
    courses: Array,
    time_periods: Object,
    groups: Array,
    locations: Array,
    statuses: Object,
    active_enrolments: Object,
    enrolment_aggregates: Object,
  },
  data() {
    return {
      showRecipientsModal: false,
      showBulkActionsDropdown: false,
      form: useForm({
        additional_recipients: [],
        table_filters: {
          time_period: this.filters?.table_filters?.time_period || null,
          course: this.filters?.table_filters?.course || null,
          group: this.filters?.table_filters?.group || null,
          class_location: this.filters?.table_filters?.class_location || null,
          status: this.filters?.table_filters?.status || null,
        },
      }),
    }
  },
  watch: {
    form: {
      handler: _debounce(function () {
        let query = {
          table_filters: _pickBy(this.form.table_filters),
        }
        this.$inertia.get(
          this.route('front.manager.reports.courses.show'),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveScroll: true,
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
  },
  methods: {
    resetActiveEnrolmentFilters() {
      this.form.table_filters = _mapValues(this.form.table_filters, () => null)
    },
    percentOfEnrolments(status) {
      if (this.enrolment_aggregates[status] === 0) {
        return 0
      }

      return ((this.enrolment_aggregates[status] / this.enrolment_aggregates.assigned) * 100).toFixed(0)
    },
    statusBadgeStyle(status) {
      const styles = {
        complete: 'bg-gray-100 text-ts-gray-text',
        new: 'bg-ts-orange-100 text-ts-orange-600',
        'in progress': 'bg-ts-blue-100 text-ts-blue-600',
      }

      return styles[status] || ''
    },
    exportReport() {
      this.form.post(this.route('front.manager.reports.courses.download'))
      this.showRecipientsModal = false
    },
    unenrol(enrolment) {
      if (confirm('Are you sure you want to unenrol this user?')) {
        this.$inertia.delete(this.route('front.manager.enrol.user.destroy', enrolment.id))
      }
    },
    sendTrainingReminder() {
      this.form.post(this.route('front.manager.reports.courses.send-reminder'))
    },
    onClickEnrolment(enrolment) {
      this.$inertia.get(this.route('front.manager.enrolment.show', enrolment.id))
    },
  },
}
</script>
