<template>
  <front-layout :title="$t('Quiz Result')">
    <div class="mb-8">
      <breadcrumb class="hidden md:inline" :name="quiz.title" />
      <breadcrumb class="md:hidden" :name="quiz.title" />
      <InertiaLink
        class="text-darkGray flex items-center px-3 pt-4 hover:font-semibold"
        :href="route('front.manager.enrolment.show', enrolmentContent.enrolment_id)"
      >
        <icon class="block h-3 w-3 fill-current" name="arrow-left" />
        <span class="ml-1">{{ $t('Back') }}</span>
      </InertiaLink>
    </div>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <div class="flex w-full flex-col items-center justify-center px-4 sm:px-8 lg:flex-row lg:px-20">
        <div class="flex-1 pt-6 text-center lg:py-8 lg:text-left">
          <div class="pb-1 text-ts-gray-400">{{ $t('Attempt :index', { index: attempt.index }) }}</div>
          <h3 class="text-empty-state-header text-xl font-semibold leading-6">{{ quiz.title }}</h3>
        </div>
        <div class="flex items-center justify-center pb-4 pt-4">
          <div class="px-2 py-1 lg:px-8">
            <QuizStatus :status="attempt.status" />
            <div class="pt-1 text-ts-gray-400 text-center">{{ $t('Status') }}</div>
          </div>
          <div class="flex-col py-1">
            <div
              class="text-xl font-semibold"
              :class="{
                'text-gray-600': true,
                'text-green-600': attempt.status === 'passed',
                'text-qualify-red-500': !attempt.status === 'failed',
              }"
            >
              {{ attempt.scorePercent }}%
            </div>
            <div class="pt-1 text-ts-gray-400">{{ $t('Final') }}</div>
          </div>
        </div>
      </div>
      <div class="border-t border-gray-300">
        <div v-for="(question, questionKey) in attempt.questions" :key="questionKey" class="px-4 py-6">
          <question :question="question" :question-key="questionKey" :show-answers="attempt.can.viewAnswers" />
        </div>
      </div>
    </div>
  </front-layout>
</template>

<script>
import Question from '../../User/Quizzes/Question.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import Icon from '@/Shared/Icon.vue'
import QuizStatus from '@/Shared/Quiz/QuizStatus.vue'

export default {
  components: { Question, QuizStatus, Breadcrumb, FrontLayout, Icon },
  props: {
    enrolmentContent: Object,
    quiz: Object,
    attempt: Object,
  },
  computed: {
    passingScorePercentage() {
      return Math.round((this.quiz.passing_score / this.quiz.total_score) * 100) + '%'
    },
  },
}
</script>
