<template>
  <front-modal class="rounded text-base" :show="show" width="auto" @close="close">
    <div class="max-w-sm overflow-hidden rounded bg-white p-6 shadow md:p-10">
      <div class="mb-4 text-center">
        <h1 class="mt-4 text-3xl font-bold">{{ $t('Edit Expiry') }}</h1>
        <h3 class="mt-4 text-xl font-semibold leading-snug">
          {{ $t('Use the form below to update the validity period for this certificate.') }}
        </h3>
      </div>
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap py-8 md:px-12">
          <div class="w-full pb-8 pr-6">
            <front-text-input
              v-model="form.years_valid"
              :error="form.errors.years_valid"
              :label="$t('Years Valid (optional)')"
              min="1"
              type="number"
            />
          </div>
        </div>
        <div class="flex items-center justify-center bg-white px-8 pb-8 pt-3">
          <front-secondary-button @click="close">{{ $t('Close') }}</front-secondary-button>
          <front-loading-button class="ml-4" :loading="form.processing" type="submit">
            {{ $t('Update') }}
          </front-loading-button>
        </div>
      </form>
    </div>
  </front-modal>
</template>

<script>
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontModal from '@/Shared/FrontModal.vue'
import FrontSecondaryButton from '@/Shared/FrontSecondaryButton.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import { query } from '@/Utils/Helpers'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    FrontLoadingButton,
    FrontModal,
    FrontSecondaryButton,
    FrontTextInput,
  },
  props: {
    show: Boolean,
    training_record: Object,
  },
  data() {
    return {
      form: useForm({
        years_valid: this.training_record.years_valid,
      }),
    }
  },
  methods: {
    query,
    close() {
      this.$emit('closeEdit')
    },
    submit() {
      this.form.put(this.route('front.manager.training-record-templates.update', this.training_record.id), {
        onSuccess: () => this.close(),
      })
    },
  },
}
</script>
