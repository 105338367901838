<template>
  <div>
    <div class="mt-8 flex items-end justify-between">
      <h2 class="text-lg">{{ $t('Content Progress') }}</h2>
    </div>
    <div class="mt-4 overflow-x-auto rounded bg-white shadow">
      <table class="front-table w-full">
        <thead>
          <tr>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Id') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Name') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Type') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Required') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Progress') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Finished At') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Result') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold" />
          </tr>
        </thead>
        <tbody>
          <tr v-if="enrolmentContents.length === 0">
            <td class="border-t px-6 py-4" colspan="5">
              {{
                $t(
                  'This user has not made any progress with the content in this course, or the course is missing content.'
                )
              }}
            </td>
          </tr>
          <tr
            v-for="(enrolmentContent, index) in enrolmentContents"
            :key="enrolmentContent.id"
            class="hover:bg-gray-100 focus:bg-gray-100"
            :class="{
              'cursor-pointer': enrolmentContent.contentable_type == 'quiz',
            }"
            @click="showContentDetails(enrolmentContent)"
          >
            <td class="whitespace-nowrap border-t px-6 py-4 capitalize">{{ index + 1 }}</td>
            <td class="flex h-auto flex-col whitespace-nowrap border-t px-6 py-4">
              <div>{{ enrolmentContent.name }}</div>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4 capitalize">{{ enrolmentContent.contentable_type }}</td>
            <td class="whitespace-nowrap border-t px-6 py-4">{{ enrolmentContent.is_required ? 'Yes' : '' }}</td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <div v-if="enrolmentContent.progress" class="m-1 flex flex-wrap space-x-2 text-xs">
                <div
                  v-if="enrolmentContent.progress.time_tracked"
                  class="flex rounded-full bg-gray-500 px-2 text-white"
                >
                  <span>{{ $t('Time tracked') }} :</span>
                  <span>{{ enrolmentContent.progress.time_tracked }}</span>
                </div>
                <div v-if="enrolmentContent.progress.url">
                  <a class="link" :href="enrolmentContent.progress.url" target="_blank">Link</a>
                </div>
              </div>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <show-date :timestamp="enrolmentContent.finished_at" />
            </td>
            <td class="border-t px-6 py-4 leading-6">
              <passed-status :finished-at="enrolmentContent.finished_at" :passed="enrolmentContent.passed" />
            </td>
            <td class="border-t px-6 py-4 leading-6">
              <template v-if="enrolmentContent.contentable_type === 'quiz'">
                <InertiaLink
                  class="rounded border-2 px-3 py-1"
                  :href="route('instructor.enrolment-content.attempts', enrolmentContent)"
                >
                  More
                </InertiaLink>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import PassedStatus from '@/Shared/PassedStatus.vue'
import ShowDate from '@/Shared/ShowDate.vue'

export default {
  components: {
    PassedStatus,
    ShowDate,
  },
  props: {
    enrolmentContents: {
      type: Array,
      required: true,
    },
  },
  methods: {
    showContentDetails(enrolmentContent) {
      if (enrolmentContent.contentable_type === 'quiz')
        this.$inertia.get(this.route('instructor.enrolment-content.attempts', enrolmentContent))
    },
  },
}
</script>
