<template>
  <layout :title="$t('Create Access Code')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('organizations.edit', organization.id)">
        {{ $t('Organization') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Create Access Code') }}
    </h1>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.code" :error="form.errors.code" :label="$t('Access Code (optional)')" />
          </div>
          <div class="w-full pb-8 pr-6">
            <select-input
              v-model="form.organization_id"
              disabled
              :error="form.errors.organization_id"
              :label="$t('Organization')"
            >
              <option :value="null" />
              <option v-for="organizationObject in organizations" :key="organizationObject.id" :value="organization.id">
                {{ organizationObject.name }}
              </option>
            </select-input>
          </div>
          <div class="w-full pb-8 pr-6">
            <user-search-input
              v-model="issuer"
              class="w-full"
              :error="form.errors.issuer_id"
              :label="$t('Issued By (This should be the organization user who has the enrolments)')"
              :organization-id="form.organization_id"
              @click.stop="true"
            />
          </div>
          <div class="w-full pb-8 pr-6">
            <select-input v-model="form.group_id" :error="form.errors.group_id" :label="$t('Group (optional)')">
              <option :value="null" />
              <option v-for="group in groups" :key="group.id" :value="group.id">
                {{ group.name }}
              </option>
            </select-input>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input
              v-model="form.usage_limit"
              :error="form.errors.usage_limit"
              :label="$t('Usage Limit (optional)')"
              min="0"
              type="number"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <radio-input
              v-model="form.is_archived"
              :error="form.errors.is_archived"
              :label="$t('Archive')"
              type="radio"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <span class="form-label">{{ $t('Courses') }}:</span>
            <div v-for="course in form.courses" :key="course.id" class="my-6 flex items-center">
              <input
                :id="`course-${course.id}`"
                v-model="course.selected"
                class="form-checkbox mr-4"
                type="checkbox"
                :value="course.id"
              />
              <label :for="`course-${course.id}`">{{ course.name }}</label>
            </div>
            <div v-if="form.errors.courses" class="form-error">
              {{ form.errors.courses }}
            </div>
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Create Access Code') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import RadioInput from '@/Shared/RadioInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import UserSearchInput from '@/Shared/UserSearchInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Layout,
    LoadingButton,
    RadioInput,
    SelectInput,
    TextInput,
    UserSearchInput,
  },
  props: {
    code: String,
    courses: Array,
    groups: Array,
    organization: Object,
    organizations: Array,
  },
  data() {
    return {
      form: useForm({
        code: this.code,
        organization_id: this.organization.id,
        issuer_id: null,
        group_id: null,
        usage_limit: null,
        courses: this.courses,
        is_archived: false,
      }),
      issuer: null,
    }
  },
  watch: {
    issuer(newIssuer) {
      this.form.issuer_id = newIssuer?.id
    },
  },
  methods: {
    submit() {
      this.form.post(this.route('access-codes.store', this.organization.id))
    },
  },
}
</script>
