<template>
  <front-modal class="rounded text-base" :show="show" width="auto" @close="close">
    <div class="max-w-lg overflow-hidden rounded bg-white p-6 shadow md:p-10">
      <div class="mb-4 text-center">
        <h1 class="mt-4 text-3xl font-bold">{{ $t('Create a Training Record') }}</h1>
        <h3 class="mt-4 text-xl font-semibold md:px-8">
          {{
            $t(
              'Create a new Training Record type to create Training Records and track completion in your Organization for individuals.'
            )
          }}
        </h3>
      </div>
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap py-8 md:px-12">
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <front-text-input
              v-model="form.name"
              :error="form.errors.name"
              :label="$t('Training Record Name')"
              :placeholder="$t('ie. New Hire Training')"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <front-text-input
              v-model="form.years_valid"
              :error="form.errors.years_valid"
              :label="$t('Years Valid (optional)')"
            />
          </div>
          <div class="w-full pb-8">
            <front-textarea-input
              v-model="form.description"
              :autosize="true"
              class="w-full pb-8 pr-6"
              :error="form.errors.description"
              :label="$t('Description')"
              :rows="5"
            />
          </div>
        </div>
        <div class="flex items-center justify-center bg-white px-8 pb-8 pt-1">
          <front-secondary-button type="button" @click="close">{{ $t('Close') }}</front-secondary-button>
          <front-loading-button class="ml-4" :loading="form.processing" type="submit">
            {{ $t('Create') }}
          </front-loading-button>
        </div>
      </form>
    </div>
  </front-modal>
</template>

<script>
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontModal from '@/Shared/FrontModal.vue'
import FrontSecondaryButton from '@/Shared/FrontSecondaryButton.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import FrontTextareaInput from '@/Shared/FrontTextareaInput.vue'
import { query } from '@/Utils/Helpers'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    FrontLoadingButton,
    FrontModal,
    FrontSecondaryButton,
    FrontTextInput,
    FrontTextareaInput,
  },
  props: {
    show: Boolean,
  },
  data() {
    return {
      form: useForm(
        {
          name: null,
          description: null,
          years_valid: null,
        },
        'CreateModal'
      ),
    }
  },
  methods: {
    query,
    close() {
      this.$emit('close')
    },
    submit() {
      this.form.post(this.route('front.manager.training-record-templates.store'), {
        onSuccess: () => this.close(),
      })
    },
  },
}
</script>
