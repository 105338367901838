<template>
  <front-modal class="rounded text-base" :show="show" width="auto" @close="close">
    <div class="flex flex-wrap bg-white px-8 pt-8">
      <breadcrumb :name="$t('Edit') + ` : ${group.name}`" />
    </div>
    <div class="w-full overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" />
          </div>
          <div class="w-full pb-8 pr-6">
            <textarea-input
              v-model="form.description"
              :error="form.errors.description"
              :label="$t('Description')"
              :rows="6"
            />
          </div>
        </div>
        <div class="flex items-center justify-between bg-white px-8 pb-8 pt-1">
          <button v-if="!group.archived_at" class="text-red-500 hover:underline" type="button" @click.stop="archive">
            {{ $t('Archive') }}
          </button>
          <button v-else class="text-red-500 hover:underline" type="button" @click.stop="unarchive">
            {{ $t('Unarchive') }}
          </button>
          <div class="flex items-center">
            <front-secondary-button type="button" @click="close">{{ $t('Close') }}</front-secondary-button>
            <front-loading-button class="ml-4" :loading="form.processing" type="submit">
              {{ $t('Update') }}
            </front-loading-button>
          </div>
        </div>
      </form>
    </div>
  </front-modal>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontModal from '@/Shared/FrontModal.vue'
import FrontSecondaryButton from '@/Shared/FrontSecondaryButton.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    FrontLoadingButton,
    FrontModal,
    FrontSecondaryButton,
    TextInput,
    TextareaInput,
  },
  props: {
    group: Object,
    show: Boolean,
  },
  data() {
    return {
      form: useForm(
        {
          name: this.group.name,
          description: this.group.description,
        },
        'GroupEdit'
      ),
    }
  },
  methods: {
    archive() {
      this.$inertia.put(this.route('front.manager.groups.archive', this.group.id))
      this.close()
    },
    unarchive() {
      this.$inertia.put(this.route('front.manager.groups.unarchive', this.group.id))
      this.close()
    },
    close() {
      this.$emit('close')
    },
    submit() {
      this.form.put(this.route('front.manager.groups.update', this.group), {
        onSuccess: () => this.close(),
      })
    },
  },
}
</script>
