<template>
  <front-layout :title="$t('Organization Settings')">
    <div class="mb-10">
      <breadcrumb class="ts-gray-text-bold text-3xl font-semibold" :name="$t('Organization Settings')" />
    </div>
    <div class="flex max-w-2xl flex-wrap">
      <div class="w-full">
        <div class="flex flex-col xl:flex-row">
          <div class="mb-4 mr-0 xl:mb-0 xl:mr-6">
            <div
              class="flex w-full flex-col items-center justify-center rounded-lg border border-ts-gray-500 bg-white px-20 py-8 xl:py-12"
            >
              <input ref="file" class="hidden" type="file" @change="uploadProfileImage($event)" />
              <div class="relative flex h-40 w-40 justify-center rounded-full">
                <img
                  v-if="organization.logo || imagePath"
                  alt="$t('Image preview...')"
                  class="h-40 w-40 cursor-pointer rounded-full object-cover"
                  :src="imagePath || organization.logo"
                  @click="$refs.file.click()"
                />
                <div
                  v-else
                  class="flex h-40 w-40 cursor-pointer items-center justify-center rounded-full bg-gray-400 text-6xl font-bold tracking-wider text-white"
                  @click="$refs.file.click()"
                >
                  {{ informationForm.name.charAt(0) }}
                </div>
                <div v-if="!organization.logo && !imageChanged" @click="$refs.file.click()">
                  <icon
                    class="absolute bottom-0 right-0 mx-auto mb-1 mr-1 h-10 w-10 flex-shrink-0 cursor-pointer rounded-full border-4 border-white stroke-white p-1"
                    :class="isTenantWorksite ? 'bg-qualify-red-500' : 'bg-blue-700'"
                    name="camera"
                  />
                </div>
                <div v-if="organization.logo && !imageChanged" @click="removeSavedProfileImage">
                  <icon
                    class="absolute bottom-0 right-0 mx-auto mb-1 mr-1 h-10 w-10 flex-shrink-0 cursor-pointer rounded-full border-4 border-white fill-white stroke-white p-2"
                    :class="isTenantWorksite ? 'bg-qualify-red-500' : 'bg-blue-700'"
                    name="close"
                  />
                </div>
              </div>
              <div v-if="imageChanged" class="-mx-64 mb-2 mt-6 text-center">
                <front-solid-button @click="updateProfileImage">{{ $t('Save') }}</front-solid-button>
                <button class="ml-2 text-sm text-gray-700 hover:underline" @click="resetUpload">
                  {{ $t('Cancel') }}
                </button>
              </div>
              <div v-if="profileImageForm.errors.image" class="-mx-64 my-2 whitespace-nowrap text-center">
                {{ profileImageForm.errors.image }}
              </div>
              <div class="-mx-64 mt-10 text-center text-lg">{{ organization.name }}</div>
            </div>
          </div>
          <div class="w-full overflow-hidden rounded-lg border border-ts-gray-500 bg-white">
            <div>
              <div class="border-b border-gray-200 p-4 lg:hidden">
                <select
                  v-model="dataSelected"
                  class="form-select focus:ring-blue mt-1 block w-full border-gray-200 bg-ts-front-field-light py-2 pl-3 pr-10 text-base leading-6 text-black transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none lg:text-sm lg:leading-5"
                >
                  <option value="information">{{ $t('Information') }}</option>
                  <option value="options">{{ $t('Options') }}</option>
                </select>
              </div>
              <div class="hidden lg:block">
                <div class="border-b border-gray-200">
                  <nav class="-mb-px flex px-10 pt-4">
                    <button
                      class="whitespace-nowrap border-b-4 px-1 py-4 font-bold leading-5 focus:outline-none"
                      :class="dataSelected === 'information' ? activeCssClass : cssClass"
                      @click.prevent="dataSelected = 'information'"
                    >
                      {{ $t('Information') }}
                    </button>
                    <button
                      class="ml-10 whitespace-nowrap border-b-4 px-1 py-4 font-bold leading-5 focus:outline-none"
                      :class="dataSelected === 'options' ? activeCssClass : cssClass"
                      @click.prevent="dataSelected = 'options'"
                    >
                      {{ $t('Options') }}
                    </button>
                  </nav>
                </div>
              </div>
            </div>
            <div v-if="dataSelected === 'information'">
              <form @submit.prevent="updateInformation">
                <div class="-mb-8 -mr-6 flex flex-wrap p-6 lg:p-10">
                  <div class="w-full pb-8 pr-6 sm:w-1/2">
                    <front-text-input
                      v-model="informationForm.name"
                      :error="informationForm.errors.name"
                      :label="$t('Name')"
                    />
                  </div>
                  <div class="w-full pb-8 pr-6 sm:w-1/2">
                    <canada-post-address-complete-input
                      v-if="$page.props.app.tenant.has_address_complete_api_enabled && canFindAddress"
                      ref="address_complete"
                      v-model="address_complete"
                      class="w-full"
                      :label="$t('Address')"
                      label-class="form-label text-ts-front-label"
                      :placeholder="informationForm.address"
                      required
                      :search-by="['Text']"
                      search-input-class="mt-1 w-full rounded-lg border border-ts-front-border-light bg-ts-front-field-light p-3 text-ts-front-label focus:border-ts-front-border-dark focus:text-gray-800 focus:outline-none"
                      track-by="Id"
                      @update:enable-a-p-i="canFindAddress = false"
                    >
                      <div v-if="informationForm.address" class="flex items-center justify-between">
                        <div class="truncate">{{ informationForm.address }}</div>
                      </div>
                      <template #option="{ option, selected: isSelected }">
                        <div class="flex items-center justify-between">
                          <div class="text-md" :class="{ 'text-gray-600': !isSelected, 'text-white': selected }">
                            {{ option.Text.concat(' ', option.Description) }}
                          </div>
                          <icon
                            v-if="option.Next == 'Find'"
                            class="ml-2 h-3 w-3 flex-shrink-0 fill-gray-400"
                            name="chevron-right"
                          />
                        </div>
                      </template>
                    </canada-post-address-complete-input>
                    <template v-else>
                      <front-text-input
                        v-model="informationForm.address"
                        :error="informationForm.errors.address"
                        :label="$t('Address')"
                      />
                    </template>
                  </div>
                  <div class="w-full pb-8 pr-6 sm:w-1/2">
                    <front-text-input
                      v-model="informationForm.city"
                      :error="informationForm.errors.city"
                      :label="$t('City')"
                    />
                  </div>
                  <div class="w-full pb-8 pr-6 pt-1 sm:w-1/2">
                    <front-select-input
                      v-model="informationForm.region"
                      :error="informationForm.errors.region"
                      :label="$t('Province/State')"
                    >
                      <option :value="null" />
                      <option v-for="(region, index) in getRegions" :key="index" :value="region.name">
                        {{ region.name }}
                      </option>
                    </front-select-input>
                  </div>
                  <div class="w-full pb-8 pr-6 sm:w-1/2">
                    <front-text-input
                      v-model="informationForm.postal_code"
                      :error="informationForm.errors.postal_code"
                      :label="$t('Postal Code/ZIP')"
                    />
                  </div>
                  <div class="w-full pb-8 pr-6 pt-1 sm:w-1/2">
                    <front-select-input
                      v-model="informationForm.country"
                      :error="informationForm.errors.country"
                      :label="$t('Country')"
                    >
                      <option :value="null" />
                      <option value="CA">{{ $t('Canada') }}</option>
                      <option value="US">{{ $t('United States') }}</option>
                    </front-select-input>
                  </div>
                </div>
                <div class="flex items-center justify-between border-t border-gray-200 px-6 py-5 lg:px-10 lg:py-8">
                  <front-loading-button :loading="informationForm.processing" type="submit">
                    {{ $t('Update Information') }}
                  </front-loading-button>
                </div>
              </form>
            </div>
            <div v-if="dataSelected === 'options'">
              <form @submit.prevent="updateOptions">
                <div class="-mr-6 flex flex-wrap border-b p-10">
                  <div class="flex w-full items-center justify-between px-2">
                    <div class="text-lg">{{ $t('Access Codes') }}</div>
                    <div class="pr-6">
                      <front-toggle-input
                        v-model="optionsForm.access_codes_enabled"
                        @update:model-value="updateOptions"
                      />
                    </div>
                  </div>
                </div>
                <div class="-mr-6 flex flex-wrap p-10">
                  <div class="flex w-full items-center justify-between px-2">
                    <div class="text-lg">{{ $t('Training Records') }}</div>
                    <div class="pr-6">
                      <front-toggle-input
                        v-model="optionsForm.training_records_enabled"
                        @update:model-value="updateOptions"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import CanadaPostAddressCompleteInput from '@/Shared/CanadaPostAddressCompleteInput.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontSelectInput from '@/Shared/FrontSelectInput.vue'
import FrontSolidButton from '@/Shared/FrontSolidButton.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import FrontToggleInput from '@/Shared/FrontToggleInput.vue'
import Icon from '@/Shared/Icon.vue'
import { isTenantWorksite } from '@/Utils/Helpers'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    CanadaPostAddressCompleteInput,
    FrontLayout,
    FrontLoadingButton,
    FrontSelectInput,
    FrontSolidButton,
    FrontTextInput,
    FrontToggleInput,
    Icon,
  },
  props: {
    regions: Array,
    selected: String,
    organization: Object,
  },
  data() {
    return {
      dataSelected: this.selected || 'profile',
      imagePath: this.organization.logo,
      imageChanged: false,
      informationForm: useForm(
        {
          name: this.organization.name,
          address: this.organization.address,
          region: this.organization.region,
          city: this.organization.city,
          postal_code: this.organization.postal_code,
          country: this.organization.country,
        },
        'InformationForm'
      ),
      optionsForm: useForm(
        {
          access_codes_enabled: this.organization.access_codes_enabled,
          training_records_enabled: this.organization.training_records_enabled,
        },
        'OptionsForm'
      ),
      profileImageForm: useForm(
        {
          image: null,
        },
        'ProfileImageForm'
      ),
      address_complete: null,
      canFindAddress: true,
    }
  },
  computed: {
    isTenantWorksite,
    cssClass() {
      return 'border-transparent text-ts-gray-text-bold hover:text-gray-700 hover:border-gray-200 focus:text-gray-700 focus:border-gray-200'
    },
    activeCssClass() {
      return this.isTenantWorksite
        ? 'border-qualify-red-500 text-gray-800 focus:text-gray-800'
        : 'border-blue-700 text-gray-800 focus:text-gray-800'
    },
    getRegions() {
      return this.regions.filter((region) => region.country === this.informationForm.country)
    },
  },
  watch: {
    'optionsForm.access_codes_enabled'() {
      this.updateOptions()
    },
    'optionsForm.training_records_enabled'() {
      this.updateOptions()
    },
    'informationForm.country'() {
      this.informationForm.region = null
    },
    address_complete: function (newVal, oldVal) {
      this.informationForm.address = newVal?.Line1
      this.informationForm.city = newVal?.City
      this.informationForm.postal_code = newVal?.PostalCode
      this.informationForm.region = newVal?.ProvinceName
      this.informationForm.country = newVal ? newVal.CountryIso2 : oldVal.CountryIso2
    },
  },
  methods: {
    updateInformation() {
      this.informationForm.put(this.route('front.admin.settings.update.information'))
    },
    updateOptions() {
      this.optionsForm.put(this.route('front.admin.settings.update.options'))
    },
    uploadProfileImage(event) {
      this.profileImageForm.image = event.target.files[0]
      if (this.profileImageForm.image?.type.startsWith('image')) {
        let reader = new FileReader()
        reader.onload = (e) => (this.imagePath = e.target.result)
        reader.readAsDataURL(this.profileImageForm.image)
        this.imageChanged = true
      } else {
        this.profileImageForm.image = null
      }
    },
    resetUpload() {
      this.imagePath = null
      this.imageChanged = false
      this.profileImageForm.reset()
    },
    updateProfileImage() {
      this.profileImageForm.post(this.route('front.admin.settings.update.profile-image'), {
        onSuccess: () => (this.imageChanged = false),
      })
    },
    removeSavedProfileImage() {
      this.$inertia.delete(this.route('front.admin.settings.destroy.profile-image'))
      this.resetUpload()
    },
  },
}
</script>
