<template>
  <InertiaLink
    v-if="routeLink"
    v-bind="$attrs"
    class="group relative -ml-3 mb-0 flex items-center whitespace-nowrap text-lg hover:text-white focus:text-white"
    :class="{
      'hover:bg-qualify-red-500 focus:bg-qualify-red-500': isTenantWorksite,
      'hover:bg-blue-600 focus:bg-blue-600': !isTenantWorksite,
      'fill-qualify-red-500 text-qualify-red-500': isActive && isTenantWorksite,
      'fill-blue-500 text-blue-500': isActive && !isTenantWorksite,
      'text-ts-gray-text-bold': !isActive,
    }"
    :href="routeLink"
    :method="method"
  >
    <div
      v-if="isActive"
      class="absolute ml-0 h-full w-1"
      :class="isTenantWorksite ? 'bg-qualify-red-500' : 'bg-blue-600'"
    >
      &nbsp;
    </div>
    <div v-else class="absolute ml-0 h-full w-1">&nbsp;</div>
    <div class="ml-10 flex items-center py-2">
      <slot />
      <span :class="{ 'border-white': !isUrl(url) }">
        {{ $t(name) }}
      </span>
    </div>
  </InertiaLink>
  <div
    v-else
    class="group -ml-3 flex items-center whitespace-nowrap border-l-4 border-white px-8 py-2"
    :class="isUrl(url) ? 'text-ts-gray-text-bold' : 'text-gray-600'"
  >
    <slot />
    <span>
      {{ $t(name) }}
    </span>
  </div>
</template>

<script>
import { isTenantWorksite, isUrl } from '@/Utils/Helpers'
import { Link as InertiaLink } from '@inertiajs/vue3'

export default {
  components: {
    InertiaLink,
  },
  inheritAttrs: false,
  props: {
    name: String,
    routeLink: String,
    url: {
      type: [String, Array],
      default: '',
    },
    method: {
      type: String,
      default: 'get',
    },
  },
  computed: {
    isTenantWorksite,
    isActive() {
      return this.isUrl(this.url)
    },
  },
  methods: {
    isUrl,
  },
}
</script>
