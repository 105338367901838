<template>
  <div v-if="activeOrganization">
    <app-head title="Organization" />
    <div class="mb-8 flex items-center justify-between">
      <h1 class="text-xl font-bold">
        <breadcrumb-admin :path="breadCrumbs" />
      </h1>
      <dropdown class="btn-blue" placement="bottom-end">
        <div class="flex cursor-pointer select-none items-center">
          <div>
            <span>{{ $t('Actions') }}</span>
          </div>
          <icon class="-my-1 h-5 w-5 fill-white" name="chevron-down" />
        </div>
        <template #dropdown>
          <div class="mt-2 min-w-32 rounded border bg-white py-2 text-sm shadow-lg">
            <InertiaLink
              v-if="activeOrganization.can.merge"
              class="block w-full px-4 py-2 text-left hover:bg-gray-200 hover:text-gray-700"
              :href="route('organizations.merge', activeOrganization.id)"
              type="button"
            >
              {{ $t('Merge') }}
            </InertiaLink>
            <button
              v-if="activeOrganization.deleted_at"
              class="block w-full px-4 py-2 text-left text-red-500 hover:bg-gray-200 hover:text-gray-700"
              @click="restore"
            >
              {{ $t('Restore') }}
            </button>
            <button
              v-else
              class="block w-full px-4 py-2 text-left text-red-500 hover:bg-gray-200 hover:text-gray-700"
              type="button"
              @click="destroy"
            >
              {{ $t('Delete') }}
            </button>
          </div>
        </template>
      </dropdown>
    </div>
    <deleted-message v-if="activeOrganization.deleted_at" class="mb-6" />
    <archived-message v-if="activeOrganization.archived_at" class="mb-6">
      <div class="flex w-full justify-between">
        <div>{{ $t('This user has been archived.') }}</div>
        <button
          v-if="activeOrganization.archived_at"
          class="ml-4 text-yellow-800 hover:underline"
          tabindex="-1"
          type="button"
          @click="unArchive"
        >
          {{ $t('Restore') }}
        </button>
      </div>
    </archived-message>
    <div class="flex w-full flex-none flex-col gap-x-6 md:flex-row">
      <div class="flex-1 overflow-hidden">
        <tab-layout :menu-list="menuList">
          <slot />
        </tab-layout>
      </div>
      <div class="mt-8 w-full space-y-8 md:mt-0 md:w-3/12">
        <!-- Created By -->
        <div
          v-if="activeOrganization?.createdUser"
          class="w-full rounded bg-gray-400 px-4 py-4 text-sm font-semibold text-gray-700"
        >
          {{ $t('Created by : :created_by', { created_by: activeOrganization.createdUser.name }) }}
        </div>

        <!-- Notes -->
        <div class="w-full">
          <div class="overflow-hidden rounded bg-white shadow">
            <div class="flex flex-wrap">
              <div class="w-full bg-gray-400 px-4 py-4 text-sm font-bold text-gray-700">{{ $t('Notes') }}</div>
              <div class="relative w-full">
                <button
                  v-show="organizationStore.isFetchingNotes"
                  class="absolute w-full bg-blue-500 text-center text-white"
                >
                  Loading
                </button>
                <div
                  v-if="sortedNoteKeys?.length > 0"
                  ref="notesContainer"
                  class="flex h-64 w-full flex-col-reverse overflow-y-auto px-6"
                  :class="{ 'py-4': organizationStore.notes?.length > 0 }"
                  @scroll="onScroll"
                >
                  <div v-for="noteKey in sortedNoteKeys" :key="noteKey" class="w-full py-2">
                    <div
                      class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-2 py-1 text-sm leading-normal transition duration-150 ease-in-out"
                      type="text"
                    >
                      {{ organizationStore.notes[noteKey].content }}
                    </div>
                    <div class="mt-1 flex items-center justify-between px-1">
                      <span class="text-xs text-gray-700">{{ organizationStore.notes[noteKey].user.name || '-' }}</span>
                      <span class="text-xs text-gray-700">{{ organizationStore.notes[noteKey].created_at }}</span>
                    </div>
                  </div>
                </div>
                <div class="flex w-full items-end space-x-1 bg-gray-200 px-4 py-4">
                  <div class="flex-1">
                    <textarea-input
                      v-model="noteForm.content"
                      :autosize="true"
                      :error="noteForm.errors.content"
                      :placeholder="$t('New note..')"
                      :rows="1"
                      @keydown.enter.prevent="addNote"
                    />
                  </div>
                  <button class="btn-blue" @click.stop="addNote">></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Subscription -->
        <div class="w-full">
          <tabs
            class="overflow-hidden rounded shadow"
            nav-class="flex items-center text-gray-700 text-sm font-bold bg-gray-400"
            nav-item-active-class="bg-gray-300"
            nav-item-class="p-4"
          >
            <tab class="overflow-hidden rounded bg-white shadow" :name="$t('Subscriptions')">
              <div class="flex flex-wrap">
                <div class="flex w-full items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
                  <InertiaLink
                    class="btn-blue ml-auto"
                    :href="route('organizations.subscriptions.index', activeOrganization.id)"
                    type="button"
                  >
                    <span v-if="activeOrganization.subscriptions.length">{{ $t('View') }}</span>
                    <span v-else>{{ $t('Create') }}</span>
                  </InertiaLink>
                </div>
              </div>
            </tab>
          </tabs>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="organizationStore.isLoading">Loading</div>
  <div v-else>Some error in loading resource</div>
</template>

<script setup>
import {
  activeOrganization,
  fetchOrganization,
  loadMoreNotes,
  store as organizationStore,
  updateOrganizationId,
} from '@/Stores/ActiveOrganization'
import Http from '@/Utils/Http'
import { router, useForm } from '@inertiajs/vue3'
import { computed, onMounted, ref, useTemplateRef, watch } from 'vue'
import { Tab, Tabs } from 'vue3-tabs-component'
import { route } from 'ziggy-js'

const notesContainerRef = useTemplateRef('notesContainer')
const menuList = ref([])
const noteForm = useForm({
  content: null,
})

const sortedNoteKeys = computed(() => {
  const keys = Object.keys(organizationStore.notes)
  keys.sort((a, b) => b - a)
  return keys
})

watch(activeOrganization, async () => {
  if (activeOrganization.value?.id) {
    updateMenu()
  }
})

onMounted(() => {
  updateOrganizationId(route().params.organization)
  if (activeOrganization.value?.id) {
    updateMenu()
  }
})

const breadCrumbs = computed(() => [
  {
    name: trans('Organizations'),
    url: route('organizations.index'),
  },
  {
    name: activeOrganization.value.name,
  },
])

const updateMenu = () => {
  menuList.value = [
    {
      name: trans('Profile'),
      url: route('organizations.edit', activeOrganization.value.id),
      matchUrl: '*/edit',
    },
    {
      name: trans('Users'),
      url: route('organizations.users', activeOrganization.value.id),
      matchUrl: '*/users',
    },
    {
      name: trans('Structure'),
      url: route('organizations.structure', activeOrganization.value.id),
      matchUrl: '*/structure',
    },
    {
      name: trans('Access Codes'),
      url: route('organizations.access-codes', activeOrganization.value.id),
      matchUrl: '*/access-codes',
    },
    {
      name: trans('Certificate Settings'),
      url: route('organizations.certificate-settings', activeOrganization.value.id),
      matchUrl: '*/certificate-settings',
    },
    {
      name: trans('EHS'),
      url: route('organizations.ehs', activeOrganization.value.id),
      matchUrl: '*/ehs',
    },
  ]
}

const addNote = () => {
  Http.post(
    route('api.notes.store', { noteTypesEnum: 'organization', id: activeOrganization.value.id }),
    noteForm
  ).then((response) => {
    noteForm.content = null
    if (response.data?.data) {
      organizationStore.notes[response.data.data.id] = response.data.data
    }
  })
}

const onScroll = () => {
  if (
    organizationStore.hasMoreNotes &&
    notesContainerRef.value.clientHeight + -1 * notesContainerRef.value.scrollTop ===
      notesContainerRef.value.scrollHeight
  ) {
    loadMoreNotes()
  }
}

const destroy = () => {
  if (confirm(trans('Are you sure you want to delete this organization?'))) {
    router.delete(route('organizations.destroy', activeOrganization.value.id, { onSuccess: fetchOrganization }))
  }
}

const restore = () => {
  if (confirm(trans('Are you sure you want to restore this organization?'))) {
    router.post(route('organizations.restore', activeOrganization.value.id), {}, { onSuccess: fetchOrganization })
  }
}
</script>
<script>
import AppHead from '@/Shared/AppHead.vue'
import ArchivedMessage from '@/Shared/ArchivedMessage.vue'
import BreadcrumbAdmin from '@/Shared/BreadcrumbAdmin.vue'
import DeletedMessage from '@/Shared/DeletedMessage.vue'
import Dropdown from '@/Shared/Dropdown.vue'
import Icon from '@/Shared/Icon.vue'
import TabLayout from '@/Shared/TabLayout.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { trans } from 'laravel-vue-i18n'

export default {
  components: {
    AppHead,
    ArchivedMessage,
    BreadcrumbAdmin,
    DeletedMessage,
    Dropdown,
    Icon,
    TextareaInput,
  },
  layout: [TabLayout],
}
</script>
