<template>
  <layout :title="$t('Edit Certificate')">
    <div class="mb-8">
      <breadcrumb
        :middle-name="certificate_template.name"
        :middle-url="route('certificate-templates.edit', certificate_template)"
        :name="$t(':format_name Format', { format_name: _startCase(format.style) })"
        :previous-name="$t('Certificate Templates')"
        :previous-url="route('certificate-templates')"
      />
    </div>
    <div class="flex flex-col lg:flex-row">
      <div class="lg:w-2/3">
        <div class="overflow-x-scroll rounded bg-white shadow">
          <form @submit.prevent="submit">
            <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Format Settings') }}</div>
            <div class="px-8 py-4">
              <p class="mb-4">{{ $t('Preview') }}</p>
              <div v-if="!is_plastic_card" class="relative w-full overflow-auto" style="height: 38rem">
                <iframe
                  v-if="form.preview_as_pdf"
                  id="test"
                  ref="preview_frame"
                  class="h-full w-full overflow-auto"
                  :src="preview_url"
                  @load="attachScrollListener()"
                />
                <pdf-preview
                  v-else
                  :background-url="format.background_url"
                  :highlight="form.highlight_fields"
                  :is-landscape="isLandscape"
                  :pdf-field-setting="form.pdf_settings"
                  :pdf-fields="pdf_setting_fields"
                  :zoom="form.zoom"
                />
              </div>
              <div v-else class="w-full">
                <plastic-card-preview
                  :background-url="format.background_url"
                  :crop="parseInt(form.crop)"
                  :is-edit="true"
                  :pdf-settings="pdf_setting_keys"
                  type="number"
                />
              </div>
              <div v-if="!is_plastic_card" class="-mx-4 mt-4">
                <div class="flex flex-wrap items-center">
                  <div class="px-4">
                    <input id="preview-as-pdf" v-model="form.preview_as_pdf" type="checkbox" />
                    <label for="preview-as-pdf">{{ $t('Preview as PDF') }}</label>
                  </div>
                  <div class="px-4">
                    <div v-if="!form.preview_as_pdf">
                      <input id="zoom" v-model="form.zoom" max="1" min="0" step="0.05" type="range" />
                      <label for="zoom">{{ $t('Zoom: :zoom%', { zoom: Math.floor(form.zoom * 100) }) }}</label>
                    </div>
                  </div>
                  <div class="px-4">
                    <div v-if="!form.preview_as_pdf">
                      <input id="highlight-fields" v-model="form.highlight_fields" type="checkbox" />
                      <label for="highlight-fields">{{ $t('Highlight Fields') }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="-mx-4 mt-4">
                <div class="flex flex-wrap items-center">
                  <div class="px-4">
                    <div v-if="!form.preview_as_pdf">
                      <input id="crop" v-model="form.crop" min="0" step="1" type="range" />
                      <label for="crop">{{ $t('Crop: :crop px', { crop: form.crop }) }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="-mb-8 -mr-6 flex w-full flex-wrap p-8">
              <div class="w-full pb-8 pr-6">
                <table>
                  <thead>
                    <tr>
                      <th class="whitespace-nowrap px-2 py-2 text-left font-bold">{{ $t('Name') }}</th>
                      <th
                        v-for="(field, key, i) in pdf_setting_fields"
                        :key="i"
                        class="whitespace-nowrap px-2 py-2 text-left font-bold"
                      >
                        {{ _startCase(key) }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(meta_key, meta_key_name, i) in form.pdf_settings" :key="i" class="mt-2">
                      <td class="whitespace-nowrap px-2 py-0">{{ _startCase(meta_key_name) }}</td>
                      <td
                        v-for="(field, field_key, filedIndex) in pdf_setting_fields"
                        :key="filedIndex"
                        class="px-2 py-0"
                      >
                        <select
                          v-if="field.type === 'select'"
                          v-model="form.pdf_settings[meta_key_name][field_key]"
                          class="mb-2 rounded border px-3 py-2"
                          @keydown.enter.prevent
                        >
                          <option value="" />
                          <option v-for="(option, index) in field.options" :key="index" :value="option">
                            {{ _startCase(option) }}
                          </option>
                        </select>
                        <input
                          v-else
                          v-model="form.pdf_settings[meta_key_name][field_key]"
                          class="mb-2 w-20 rounded border px-3 py-2"
                          :class="{ 'text-right': field.type === 'number' }"
                          step="any"
                          :type="field.type"
                          @keydown.enter.prevent
                        />
                      </td>
                      <td class="px-2 py-0">
                        <button class="btn-blue" type="button" @click="reset(meta_key_name)">{{ $t('Clear') }}</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
              <div v-if="!certificate_template.deleted_at" class="flex justify-end">
                <button class="text-red-500 hover:underline" tabindex="-1" type="button" @click="destroy">
                  {{ $t('Delete') }}
                </button>
                <button class="ml-4 hover:underline" tabindex="-1" type="button" @click="openReplaceBackgroundModal">
                  {{ $t('Replace Background') }}
                </button>
              </div>
              <loading-button class="btn-blue" :loading="form.processing" type="submit">
                {{ $t('Update Certificate') }}
              </loading-button>
            </div>
          </form>
        </div>
        <replace-background-modal
          :format="format"
          :show="show_replace_background_modal"
          @close="closeReplaceBackgroundModal"
        />
      </div>
      <div class="mt-8 w-full lg:mt-0 lg:w-1/3 lg:pl-8">
        <div class="overflow-hidden rounded bg-white shadow">
          <div class="bg-gray-400 px-4 py-4 text-sm font-bold text-gray-700">{{ $t('Copy Settings') }}</div>
          <div class="-mb-8 -mr-6 flex flex-wrap p-4">
            <div class="w-full pb-8 pr-6">
              <certificate-template-format-search-input
                v-model="selectedCertificateTemplateFormat"
                :format-style="format.style"
              />
            </div>
          </div>
          <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
            <button class="btn-blue" :disabled="!selectedCertificateTemplateFormat" @click="copySettings">
              {{ $t('Copy settings') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import CertificateTemplateFormatSearchInput from './CertificateTemplateFormatSearchInput.vue'
import PdfPreview from './PdfPreview.vue'
import ReplaceBackgroundModal from './ReplaceBackgroundModal.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import PlasticCardPreview from '@/Shared/PlasticCardPreview.vue'
import { query } from '@/Utils/Helpers'
import { useForm } from '@inertiajs/vue3'
import _debounce from 'lodash/debounce'
import _startCase from 'lodash/startCase'

export default {
  components: {
    ReplaceBackgroundModal,
    CertificateTemplateFormatSearchInput,
    PdfPreview,
    Breadcrumb,
    Layout,
    LoadingButton,
    PlasticCardPreview,
  },
  props: {
    isLandscape: Boolean,
    certificate_template: Object,
    pdf_setting_keys: Object,
    pdf_setting_fields: Object,
    plastic_card_formats: Array,
    format: Object,
  },
  data() {
    return {
      preview_url: null,
      selectedCertificateTemplateFormat: null,
      form: useForm({
        pdf_settings: this.pdf_setting_keys,
        zoom: '0.65',
        crop: this.format.crop ?? 0,
        preview_as_pdf: false,
        highlight_fields: true,
      }),
      frameScrollPosition: [0, 0],
      show_replace_background_modal: false,
    }
  },
  computed: {
    is_plastic_card() {
      return this.plastic_card_formats.includes(this.format.style)
    },
  },
  watch: {
    form: {
      handler: _debounce(function () {
        if (!this.is_plastic_card) {
          this.preview_url = this.generatePreviewUrl()
        }
      }, 500),
      deep: true,
    },
  },
  mounted() {
    if (!this.is_plastic_card) {
      this.preview_url = this.generatePreviewUrl()
    }
  },
  methods: {
    _startCase,
    submit() {
      this.form.put(this.route('certificate-template-format.update', this.format))
    },
    reset(meta_key_name) {
      Object.keys(this.pdf_setting_fields).forEach((key) => (this.form.pdf_settings[meta_key_name][key] = null))
    },
    generatePreviewUrl() {
      return (
        this.route('certificate-template-format-preview', this.format) +
        query({
          ...this.form.data(),
          pdf_settings: JSON.stringify(this.form.pdf_settings),
        })
      )
    },
    openReplaceBackgroundModal() {
      this.show_replace_background_modal = true
    },
    closeReplaceBackgroundModal() {
      this.show_replace_background_modal = false
    },
    attachScrollListener() {
      const frameWindow = this.$refs.preview_frame.contentWindow

      frameWindow.onscroll = _debounce(() => {
        this.frameScrollPosition = [frameWindow.scrollX, frameWindow.scrollY]
      }, 300)

      frameWindow.scrollTo(...this.frameScrollPosition)

      // store the position again, just incase the user goes to a smaller size.
      this.frameScrollPosition = [frameWindow.scrollX, frameWindow.scrollY]
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to permanently delete this?'))) {
        this.$inertia.delete(this.route('certificate-template-format.delete', this.format.id))
      }
    },
    copySettings() {
      if (this.selectedCertificateTemplateFormat) {
        this.form.crop = this.selectedCertificateTemplateFormat.crop
        this.form.pdf_settings = this.selectedCertificateTemplateFormat.pdf_setting_keys
      }
    },
  },
}
</script>
