<template>
  <a
    class="block capitalize text-ts-blue-500 no-underline hover:text-ts-blue-600 hover:underline"
    :class="{ 'border-t border-gray-200': i > 0 }"
    :href="route('front.training-records.download', [trainingRecord])"
  >
    <icon class="h-8 w-8 text-sm text-ts-blue-500" name="download" />
  </a>
</template>
<script>
import Icon from './Icon.vue'

export default {
  components: {
    Icon,
  },
  props: {
    trainingRecord: {
      type: Object,
      required: true,
    },
  },
}
</script>
