<template>
  <layout :title="form.name">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('banks.edit', question.bank.id)">
        {{ question.bank.name }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ form.name }}
    </h1>
    <archived-message v-if="question.archived_at" class="mb-6">
      <div class="flex w-full justify-between">
        <div>{{ $t('This question has been archived.') }}</div>
        <button
          v-if="question.archived_at"
          class="ml-4 text-yellow-800 hover:underline"
          tabindex="-1"
          type="button"
          @click="unArchive"
        >
          {{ $t('Restore') }}
        </button>
      </div>
    </archived-message>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <select-input v-model="form.bank_id" :error="form.errors.bank_id" :label="$t('Banks')">
              <option v-for="value in banks" :key="value.id" :value="value.id">{{ value.name }}</option>
            </select-input>
          </div>
          <div class="w-full pb-8 pr-6">
            <textarea-input
              v-model="form.name"
              :autosize="true"
              :error="form.errors.name"
              :label="$t('Question')"
              rows="2"
            />
          </div>
          <div class="w-full pb-8 pr-6">
            <textarea-input
              v-model="form.explanation"
              :autosize="true"
              :error="form.errors.explanation"
              :label="$t('Explanation')"
              rows="2"
            />
          </div>
          <div class="w-full pb-8 pr-6">
            <search-input
              v-model="form.file"
              :data="files"
              :error="form.errors.file_id"
              :label="$t('Files')"
              search-by="name"
              track-by="id"
            >
              <div v-if="form.file" class="flex items-center justify-between">
                <div class="truncate">{{ form.file.name }}</div>
              </div>
              <template #option="{ option }">
                <div class="flex items-center justify-between">
                  <div class="truncate">{{ option.name }}</div>
                </div>
              </template>
            </search-input>
          </div>
          <div class="w-full pb-8 pr-6">
            <div>{{ $t('Type:') }}</div>
            <div class="mt-4 flex items-center">
              <label class="flex items-center">
                <input v-model="type" class="mr-2" type="checkbox" @change="checked($event)" />
                <span class="">{{ $t('True / False') }}</span>
              </label>
            </div>
          </div>
          <template v-for="(value, key) in form.options" :key="key">
            <div class="w-full pb-8 pr-6">
              <textarea-input
                v-model="form.options[key]"
                :autosize="true"
                :error="form.errors['option' + key]"
                :label="$t('Option :key', { key: key + 1 })"
                rows="2"
              />
            </div>
          </template>
          <div class="pb-8 pr-6">
            <div>{{ $t('Answer') }}</div>
            <div v-if="type" class="mt-4 flex items-center">
              <label class="mr-2 inline-flex items-center">
                <input v-model="form.correct" class="form-radio" name="option" type="radio" :value="[0]" />
                <span class="ml-2">{{ $t('True') }}</span>
              </label>
              <label class="inline-flex items-center">
                <input v-model="form.correct" class="form-radio" name="option" type="radio" :value="[1]" />
                <span class="ml-2">{{ $t('False') }}</span>
              </label>
            </div>
            <div v-else class="mt-4 flex items-center">
              <label v-for="(value, key) in form.options" :key="key" class="mr-2 flex items-center">
                <input v-model="form.correct" class="mr-2" type="checkbox" :value="key" />
                {{ key + 1 }}
              </label>
            </div>
            <div v-if="form.errors.correct" class="form-error">
              {{ form.errors.correct }}
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
          <div class="">
            <button
              v-if="question.can.archive"
              class="mr-3 text-red-500 hover:underline"
              tabindex="-1"
              type="button"
              @click="archive"
            >
              {{ $t('Archive') }}
            </button>
            <button
              v-if="question.can.delete"
              class="text-red-500 hover:underline"
              tabindex="-1"
              type="button"
              @click="destroy"
            >
              {{ $t('Delete') }}
            </button>
          </div>
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Update Question') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import ArchivedMessage from '@/Shared/ArchivedMessage.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import SearchInput from '@/Shared/SearchInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    ArchivedMessage,
    Layout,
    LoadingButton,
    SearchInput,
    SelectInput,
    TextareaInput,
  },
  props: {
    banks: Array,
    question: Object,
    files: Array,
  },
  data() {
    return {
      type: this.question.options[0] === 'True' ? true : false,
      form: useForm(
        {
          bank_id: this.question.bank.id,
          name: this.question.name,
          explanation: this.question.explanation,
          file: this.question.file,
          options: this.question.options,
          correct: this.question.correct,
        },
        'QuestionEdit'
      ),
    }
  },
  methods: {
    submit() {
      this.form
        .transform((data) => ({
          ...data,
          file_id: data.file ? data.file.id : null,
        }))
        .put(this.route('questions.update', this.question.id))
    },
    archive() {
      if (confirm(this.$t('Are you sure you want to archive this question?'))) {
        this.$inertia.post(this.route('questions.archive', this.question.id))
      }
    },
    unArchive() {
      this.$inertia.post(this.route('questions.restore', this.question.id))
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this question?'))) {
        this.$inertia.delete(this.route('questions.destroy', this.question.id))
      }
    },
    checked($event) {
      if ($event.target.checked) {
        this.form.options = ['True', 'False']

        return
      }
      this.form.options = Array(6)
    },
  },
}
</script>
