<template>
  <content-layout :enrolment-content="enrolmentContent" :is-last="isLast">
    <div class="mb-2 grid grid-cols-2 gap-4">
      <div v-if="enrolmentContent.content.dates">
        <strong class="block">{{ $t('Date') }}</strong>
        <span class="block pt-1">{{ enrolmentContent.content.dates }}</span>
        <InertiaLink
          v-if="enrolmentContent.content.can.changeClass"
          class="hover:underline"
          :href="route('front.contents.classes', [enrolment.id, enrolmentContent.id])"
        >
          {{ $t('(Edit)') }}
        </InertiaLink>
      </div>
      <div v-else class="my-4">
        <front-button-link
          v-if="enrolmentContent.content.can.changeClass"
          :href="route('front.contents.classes', [enrolment.id, enrolmentContent.id])"
        >
          {{ $t('Select Class') }}
        </front-button-link>
      </div>
      <div v-if="enrolmentContent.content.times && enrolmentContent.content.times.length > 0">
        <strong class="block">{{ $t('Time') }}</strong>
        <div class="block pt-1">
          <div v-for="(time, index) in enrolmentContent.content.times" :key="index">{{ time }}</div>
        </div>
      </div>
      <div v-if="enrolmentContent.content.location && enrolmentContent.content.location.address">
        <strong class="block">{{ $t('Location') }}</strong>
        <span class="block pt-1">{{ enrolmentContent.content.location.address }}</span>
        <div v-if="enrolmentContent.content.location.room && enrolmentContent.content.location.room.name">
          {{ enrolmentContent.content.location.room.name }}
        </div>
      </div>
      <div v-if="enrolmentContent.content.locale">
        <strong class="block">{{ $t('Language') }}</strong>
        <span class="capitaize block pt-1">{{ enrolmentContent.content.locale }}</span>
      </div>
      <div v-if="enrolmentContent.content.instructors.names">
        <strong class="block">{{ $t('Instructors') }}</strong>
        <span class="block pt-1">{{ enrolmentContent.content.instructors.names }}</span>
      </div>
    </div>
    <div>
      <div
        v-if="enrolmentContent.content.locale_short && enrolment.locale !== enrolmentContent.content.locale_short"
        class="mb-4 mt-6 flex items-center md:mb-0"
      >
        <icon class="mr-2 h-6 w-6 flex-shrink-0 fill-qualify-red-500" name="exclamation-circle" />
        <span class="leading-5">
          {{ $t('This class is taught in a language different from the other course contents.') }}
        </span>
      </div>
      <div
        v-if="
          enrolmentContent.content.instructor_led_classroom &&
          enrolmentContent.content.instructor_led_classroom.description
        "
        class="mt-6"
      >
        <strong class="block">{{ $t('Instructions') }}</strong>
        <span class="block pt-1">
          {{ enrolmentContent.content.instructor_led_classroom.description }}
        </span>
      </div>
    </div>
    <template #actions>
      <front-button-link
        v-if="enrolmentContent.finished_at"
        class="w-full px-12 text-center"
        :class="{ disabled: isDisabled }"
        :href="enrolmentContent.content.route"
        :target="enrolmentContent.content.type === 'link' ? '_blank' : ''"
      >
        {{ buttonText }}
      </front-button-link>
      <front-outline-button-link
        v-else-if="enrolmentContent.content.route || enrolmentContent.content.is_webinar_type"
        class="inline-block w-full px-12 text-center"
        :class="{ disabled: isDisabled }"
        :disabled="isDisabled"
        :href="!isDisabled ? enrolmentContent.content.route : '#'"
      >
        {{ buttonText }}
      </front-outline-button-link>
    </template>
  </content-layout>
</template>
<script>
import ContentLayout from './ContentLayout.vue'
import FrontButtonLink from '@/Shared/FrontButtonLink.vue'
import FrontOutlineButtonLink from '@/Shared/FrontOutlineButtonLink.vue'
import Icon from '@/Shared/Icon.vue'
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  components: { ContentLayout, FrontButtonLink, FrontOutlineButtonLink, Icon },
  props: {
    enrolment: Object,
    enrolmentContent: Object,
    isLast: Boolean,
  },
  computed: {
    isTenantWorksite,
    buttonText() {
      return !(this.enrolmentContent.content.student?.checked_in_at && this.enrolmentContent.content.is_webinar_type)
        ? this.$t('Check In')
        : this.$t('Join Class')
    },
    isDisabled() {
      return !this.enrolmentContent.prerequisite_complete || !this.enrolmentContent.content.route
    },
  },
}
</script>
