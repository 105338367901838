<template>
  <styled-modal class="rounded text-base" max-width="md" :show="show" @close="close">
    <template #title>
      <h3 class="mt-4 text-center text-xl font-semibold leading-snug">{{ $t('Create New User') }}</h3>
    </template>
    <template #content>
      <user-create-form v-model="form" @submit="submit" />
    </template>
    <template #footer>
      <front-loading-button :loading="form.processing" type="submit" @click="submit">
        {{ $t('Create User') }}
      </front-loading-button>
    </template>
  </styled-modal>
</template>

<script>
import UserCreateForm from '../Forms/UserCreateForm.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import StyledModal from '@/Shared/StyledModal.vue'
import { query } from '@/Utils/Helpers'
import Http from '@/Utils/Http'
import { useForm } from '@inertiajs/vue3'

export default {
  components: { UserCreateForm, FrontLoadingButton, StyledModal },
  props: {
    user: Object,
    show: Boolean,
  },
  data() {
    return {
      form: useForm({
        first_name: null,
        last_name: null,
        email: null,
        username: null,
        phone: null,
        password: null,
        groups: null,
        notify_manager: false,
        organization_role: 'basic',
      }),
    }
  },
  methods: {
    query,
    close(user = null) {
      this.form.reset()
      this.$emit('close', user)
    },
    submit() {
      let data = this.form.data()
      data.groups = data.groups ? data.groups.map((group) => group.id) : []

      this.form.processing = true
      Http.post(this.route('front.manager.users.store'), data)
        .then((response) => {
          if (response.data?.user) this.close(response.data.user)
        })
        .catch((error) => {
          if (error.response?.data?.errors) {
            let errorData = {}
            Object.keys(error.response?.data?.errors).forEach((key) => {
              errorData[key] = error.response.data.errors[key][0]
            })
            this.form.errors = errorData
          }
        })
        .finally(() => {
          this.form.processing = false
        })
    },
  },
}
</script>
