<template>
  <front-layout :title="$t('Create Enrolment')">
    <div class="mb-8">
      <breadcrumb
        :middle-name="$t('My Course Enrolments')"
        :middle-url="route('front.courses.enrol.step1')"
        :name="$t('Class Selection')"
        :previous-name="$t('Courses')"
        :previous-url="route('front.courses')"
      />
    </div>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="flex flex-wrap p-8">
          <div class="w-full space-y-8">
            <div v-for="(course, key) in courses" :key="course.id" class="flex flex-col">
              <div>
                <div class="mb-8 flex items-center justify-between">
                  <span class="font-bold">{{ course.name }}</span>
                </div>
                <div class="ml-4">
                  <label v-if="true || form.errors[`courses.${course.id}`]" class="form-error mb-4 block">
                    {{ form.errors[`courses.${course.id}`] }}
                  </label>
                  <div :key="course.id" class="flex">
                    <div class="w-1/2 pb-8 pr-6">
                      <front-class-search-input
                        v-if="courses[key].can.selectAllClasses"
                        v-model="form.courses[course.id].class"
                        :course-id="course.id"
                        :error="form.errors[`courses.${course.id} .class`]"
                        :label="$t('Class')"
                      />
                      <front-select-input
                        v-else
                        v-model="form.courses[course.id].class"
                        :error="form.errors[`courses.${course.id} .class`]"
                        :label="$t('Class')"
                      >
                        <option :value="null" />
                        <option v-for="classObject in course.classes" :key="classObject.id" :value="classObject.id">
                          {{ classObject.formatted_name }}
                        </option>
                      </front-select-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 px-8 py-4">
          <front-loading-button :loading="form.processing" type="submit">
            {{ $t('Assign Training') }}
          </front-loading-button>
        </div>
      </form>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontClassSearchInput from '@/Shared/FrontClassSearchInput.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontSelectInput from '@/Shared/FrontSelectInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    FrontClassSearchInput,
    FrontLayout,
    FrontLoadingButton,
    FrontSelectInput,
  },
  props: {
    courses: Array,
  },
  data() {
    var course_list = {}
    this.courses.map((course) => {
      course_list[course.id] = {
        id: course.id,
        class: null,
      }
    })

    return {
      class_: null,
      form: useForm({
        courses: course_list,
      }),
      isSearching: false,
    }
  },
  methods: {
    submit() {
      this.form
        .transform(function (data) {
          const courseIdArray = Object.keys(data.courses)
          const courseList = {}

          courseIdArray.forEach(function (courseId) {
            const classData = data.courses[courseId].class
            if (typeof classData === 'object' && classData !== null && !Array.isArray(classData)) {
              courseList[courseId] = {
                ...data.courses[courseId],
                class: classData.id,
              }
              return
            }
            courseList[courseId] = {
              ...data.courses[courseId],
            }
          })

          return { courses: courseList }
        })
        .post(this.route('front.courses.enrol.step2.store'))
    },
    onChange(courseId) {
      this.form.clearErrors(`courses.${courseId}`)
    },
  },
}
</script>
