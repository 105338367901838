<template>
  <div class="flex flex-col items-center px-3 pt-8 sm:justify-start sm:pt-0 lg:flex-row lg:px-0">
    <div class="w-full pt-10 lg:w-7/12">
      <svg
        class="mx-auto w-10/12 sm:w-2/3 lg:w-full"
        viewBox="0 0 995.815 675.451"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="objectBoundingBox"
            x1="0.436"
            x2="0.672"
            y1="1.136"
            y2="0.009"
          >
            <stop offset="0" stop-color="#383d73" />
            <stop offset="1" stop-color="#122845" />
          </linearGradient>
        </defs>
        <g id="Group_7218" data-name="Group 7218" transform="translate(-72.313 -215.387)">
          <path
            id="Path_8004"
            d="M-8364.895,18623.734c-137.578,1.613-280.292,15.1-191.795,94.326s274.633,28.063,409.828,46.307,435.949,67.461,520.556,27.561,31.221-157.508-190.791-148.244c-78.289,6.557-189.659-.008-297.643-7.332C-8208.968,18629.959-8300.642,18622.975-8364.895,18623.734Z"
            data-name="Path 8004"
            fill="#00a7e1"
            opacity="0.08"
            transform="translate(8656.368 -17916.287)"
          />
          <rect
            id="Rectangle_8249"
            data-name="Rectangle 8249"
            fill="#222f61"
            height="174.935"
            transform="translate(493.752 390.311)"
            width="174.934"
          />
          <rect
            id="Rectangle_8250"
            data-name="Rectangle 8250"
            fill="#dc3529"
            height="174.935"
            transform="translate(133.158 324.611)"
            width="174.934"
          />
          <rect
            id="Rectangle_8251"
            data-name="Rectangle 8251"
            fill="#0099db"
            height="174.935"
            transform="translate(749.814 463.867)"
            width="174.934"
          />
          <rect
            id="Rectangle_8253"
            data-name="Rectangle 8253"
            fill="#fc9200"
            height="174.935"
            transform="translate(512.756 565.246)"
            width="174.934"
          />
          <rect
            id="Rectangle_8252"
            data-name="Rectangle 8252"
            fill="#724896"
            height="174.935"
            transform="translate(368.258 215.387)"
            width="174.934"
          />
          <g id="Group_7129" data-name="Group 7129" transform="translate(847.944 499.545)">
            <path
              id="Path_8086"
              d="M1197.266,710.31l13.977-34.943-18-1.253-6.291,36.2Z"
              data-name="Path 8086"
              fill="#74301f"
              transform="translate(-1131.246 -443.45)"
            />
            <path
              id="Path_8087"
              d="M1316.255,702.58l-22.5-24.938,13.786-6.2,17.769,26.708Z"
              data-name="Path 8087"
              fill="#74301f"
              transform="translate(-1168.794 -442.512)"
            />
            <path
              id="Path_8088"
              d="M1286.5,370.407s-9.944,5.825-10.308,16.065l-.781,3.151-17.938,1.818s4.885-4.773,6.342-11c0,0,.906-6.657,1.18-6.657s13.706-5.154,14.035-5.208S1286.5,370.407,1286.5,370.407Z"
              data-name="Path 8088"
              fill="#74301f"
              transform="translate(-1156.039 -336.027)"
            />
            <path
              id="Path_8089"
              d="M1251.851,519.231l-32.412,83.117.374,28.956-28.209-.934,25.407-112.835Z"
              data-name="Path 8089"
              fill="#5d4084"
              transform="translate(-1132.88 -388.4)"
            />
            <path
              id="Path_8090"
              d="M1264.448,328.858s-3.956.124-7.64,9.093-4.513,22.086-.385,25.574,13.474-3.907,18.274-5.294,12.213.39,16.9-9.909-6.16-22.282-16.108-21.315S1264.448,328.858,1264.448,328.858Z"
              data-name="Path 8090"
              fill="#74301f"
              transform="translate(-1154.701 -321.395)"
            />
            <path
              id="Path_8091"
              d="M1253.577,356.946s-2.554,4.729-3.215,5.528,2.655,1.8,2.655,1.8l.576-5.579Z"
              data-name="Path 8091"
              fill="#74301f"
              transform="translate(-1153.508 -331.94)"
            />
            <ellipse
              id="Ellipse_498"
              cx="1.963"
              cy="1.266"
              data-name="Ellipse 498"
              rx="1.963"
              ry="1.266"
              transform="translate(100.217 24.713) rotate(-72.679)"
            />
            <path
              id="Path_8092"
              d="M1247.99,400.99s-3.613,5.573-5.084,7.536-9.224,9.616-9.322,14.032,1.275,12.756,1.177,13.051-4.263,32.211-4.263,32.211l35.028,8.588s-6.021-12.765-5.039-21.1,10.1-36.564,9.224-45.445a87.313,87.313,0,0,0-3-13.837S1262.662,393.927,1247.99,400.99Z"
              data-name="Path 8092"
              fill="#122845"
              transform="translate(-1146.555 -345.576)"
            />
            <circle
              id="Ellipse_499"
              cx="2.428"
              cy="2.428"
              data-name="Ellipse 499"
              fill="#ef9981"
              opacity="0.2"
              r="2.428"
              transform="translate(104.888 26.542)"
            />
            <path
              id="Path_8093"
              d="M1331.763,714.781l3.62,7.066a1.234,1.234,0,0,1-.471,1.624L1331.3,725.6a1.27,1.27,0,0,1-.158.078l-.73.3a1.228,1.228,0,0,0-.427.293l-8.789,9.292a1.235,1.235,0,0,1-.831.384l-10.658-.03a1.234,1.234,0,0,1-.606-2.34c.9-.458,6.5-1.929,6.971-2.345,1.74-1.544,5-9.453,5.329-10.753a1.163,1.163,0,0,0,.038-.314l-.031-3.109,9.055-4.427,1.257,2.067C1331.735,714.727,1331.75,714.754,1331.763,714.781Z"
              data-name="Path 8093"
              transform="translate(-1173.947 -456.993)"
            />
            <path
              id="Path_8094"
              d="M1186.61,733.212l2.119,7.839a1.266,1.266,0,0,1-1.222,1.6h-32.236s-.978-1.82,3.128-2.626,15.357-6.517,15.357-6.517l2.024-3.568h10.31Z"
              data-name="Path 8094"
              transform="translate(-1120.071 -463.076)"
            />
            <path
              id="Path_8095"
              d="M1274.568,390.453a9.268,9.268,0,0,1,.86,4.324l-22.686,7.638a.433.433,0,0,1-.462-.7C1254.148,399.653,1259.667,395.1,1274.568,390.453Z"
              data-name="Path 8095"
              fill="#383d73"
              transform="translate(-1154.173 -343.721)"
            />
            <circle
              id="Ellipse_500"
              cx="4.179"
              cy="4.179"
              data-name="Ellipse 500"
              fill="#020202"
              r="4.179"
              transform="translate(129.944 20.571)"
            />
            <circle
              id="Ellipse_501"
              cx="4.179"
              cy="4.179"
              data-name="Ellipse 501"
              fill="#020202"
              r="4.179"
              transform="translate(99.357 2.984)"
            />
            <circle
              id="Ellipse_502"
              cx="4.179"
              cy="4.179"
              data-name="Ellipse 502"
              fill="#020202"
              r="4.179"
              transform="translate(105.223 10.322)"
            />
            <circle
              id="Ellipse_503"
              cx="4.179"
              cy="4.179"
              data-name="Ellipse 503"
              fill="#020202"
              r="4.179"
              transform="translate(112.521 12.933)"
            />
            <circle
              id="Ellipse_504"
              cx="4.179"
              cy="4.179"
              data-name="Ellipse 504"
              fill="#020202"
              r="4.179"
              transform="translate(116.097 23.846)"
            />
            <circle
              id="Ellipse_505"
              cx="4.179"
              cy="4.179"
              data-name="Ellipse 505"
              fill="#020202"
              r="4.179"
              transform="translate(120.97 28.152)"
            />
            <circle
              id="Ellipse_506"
              cx="8.959"
              cy="8.959"
              data-name="Ellipse 506"
              fill="#020202"
              r="8.959"
              transform="translate(113.932 12.747)"
            />
            <circle
              id="Ellipse_507"
              cx="5.355"
              cy="5.355"
              data-name="Ellipse 507"
              fill="#020202"
              r="5.355"
              transform="translate(105.463 6.818)"
            />
            <path
              id="Path_8096"
              d="M1289.167,327.24c0,2.793-8.326,9.763-11.12,9.763a5.059,5.059,0,0,1-5.058-5.058c0-2.794,3.791-8.741,6.585-8.741S1289.167,324.446,1289.167,327.24Z"
              data-name="Path 8096"
              fill="#020202"
              transform="translate(-1161.494 -320.077)"
            />
            <path
              id="Path_8097"
              d="M1230.234,506.906l-.307,2.864a31.738,31.738,0,0,0,.826,11.3l18.54,71.907,21.139,39.165,23.731-7.747-27.468-39.219,1.416-60.285a27.176,27.176,0,0,0-5.086-16.476l-.969-1.351Z"
              data-name="Path 8097"
              fill="#724896"
              transform="translate(-1146.291 -384.663)"
            />
            <path
              id="Path_8098"
              d="M1279.81,319.407c0,2.308-14.335,11.722-16.642,11.722s.278-5.235.278-7.544a4.178,4.178,0,0,1,4.178-4.178C1269.932,319.407,1279.81,317.1,1279.81,319.407Z"
              data-name="Path 8098"
              fill="#020202"
              transform="translate(-1157.704 -318.382)"
            />
            <path
              id="Path_8099"
              d="M1261.708,504.1a16.523,16.523,0,0,0,1.085,2.731h-32.539a9.412,9.412,0,0,1,.4-2.812Z"
              data-name="Path 8099"
              transform="translate(-1146.468 -383.648)"
            />
            <rect
              id="Rectangle_8317"
              data-name="Rectangle 8317"
              fill="#faa61a"
              height="3.317"
              rx="0.681"
              transform="translate(83.561 120.21)"
              width="1.361"
            />
            <path
              id="Path_8100"
              d="M1228.766,430.135l-36.548,29.591a6.973,6.973,0,0,1-3.113,1.912L1137.937,473.4v-6.9l45.447-15.509,30.395-32.956a11.8,11.8,0,0,1,5.69-4.319c4.986-1.768,12.937-2.723,12.271,9.552A10.908,10.908,0,0,1,1228.766,430.135Z"
              data-name="Path 8100"
              fill="#74301f"
              transform="translate(-1114.012 -351.534)"
            />
            <rect
              id="Rectangle_8318"
              data-name="Rectangle 8318"
              fill="#724896"
              height="2.996"
              transform="translate(108.741 120.278)"
              width="1.321"
            />
            <rect
              id="Rectangle_8319"
              data-name="Rectangle 8319"
              fill="#724896"
              height="2.996"
              transform="translate(91.677 120.37)"
              width="1.321"
            />
            <path
              id="Path_8101"
              d="M1244.463,506.829l5.119,3.987a1.471,1.471,0,0,0,1.944-.12h0a1.471,1.471,0,0,0-.147-2.209l-5.817-4.444-1.1-.026Z"
              data-name="Path 8101"
              transform="translate(-1151.464 -383.648)"
            />
            <path
              id="Path_8102"
              d="M1256.315,532.878H1238.53S1249.148,567.894,1256.315,532.878Z"
              data-name="Path 8102"
              fill="#5d4084"
              transform="translate(-1149.379 -393.794)"
            />
            <path
              id="Path_8103"
              d="M1248.234,411.837l7.357-2.9,10,1.66,1.883,7.666-2.033,8.58-13.39,15.592-20.855-11.2Z"
              data-name="Path 8103"
              fill="#122845"
              transform="translate(-1146.8 -350.22)"
            />
            <path
              id="Path_8104"
              d="M1131.416,502.586l-10.135,3.947-1.891-10.715,12.026-.135Z"
              data-name="Path 8104"
              fill="#74301f"
              transform="translate(-1107.491 -380.718)"
            />
            <rect
              id="Rectangle_8320"
              data-name="Rectangle 8320"
              fill="#74301f"
              height="12.018"
              rx="1.13"
              transform="matrix(-0.33, -0.944, 0.944, -0.33, 6.049, 128.525)"
              width="2.259"
            />
            <rect
              id="Rectangle_8321"
              data-name="Rectangle 8321"
              fill="#74301f"
              height="14.731"
              rx="1.13"
              transform="translate(2.8 127.45) rotate(-109.249)"
              width="2.259"
            />
            <rect
              id="Rectangle_8322"
              data-name="Rectangle 8322"
              fill="#74301f"
              height="16.133"
              rx="1.13"
              transform="translate(0.745 125.817) rotate(-109.249)"
              width="2.259"
            />
            <rect
              id="Rectangle_8323"
              data-name="Rectangle 8323"
              fill="#74301f"
              height="14.62"
              rx="1.13"
              transform="translate(1.75 123.19) rotate(-109.249)"
              width="2.259"
            />
            <path
              id="Path_8105"
              d="M1118.737,495.852l-7.543.1s-.863,1.137,1.074,1.874a12.665,12.665,0,0,0,4.955.352Z"
              data-name="Path 8105"
              fill="#74301f"
              transform="translate(-1104.542 -380.777)"
            />
            <circle
              id="Ellipse_508"
              cx="3.044"
              cy="3.044"
              data-name="Ellipse 508"
              fill="#020202"
              r="3.044"
              transform="translate(100.891 9.129)"
            />
            <circle
              id="Ellipse_509"
              cx="3.044"
              cy="3.044"
              data-name="Ellipse 509"
              fill="#020202"
              r="3.044"
              transform="translate(132.782 8.844)"
            />
            <circle
              id="Ellipse_510"
              cx="4.724"
              cy="4.724"
              data-name="Ellipse 510"
              fill="#020202"
              r="4.724"
              transform="translate(121.761 3.179)"
            />
            <circle
              id="Ellipse_511"
              cx="3.044"
              cy="3.044"
              data-name="Ellipse 511"
              fill="#020202"
              r="3.044"
              transform="translate(129.295 5.557)"
            />
            <circle
              id="Ellipse_512"
              cx="3.044"
              cy="3.044"
              data-name="Ellipse 512"
              fill="#020202"
              r="3.044"
              transform="translate(133.948 13.605)"
            />
            <circle
              id="Ellipse_513"
              cx="3.044"
              cy="3.044"
              data-name="Ellipse 513"
              fill="#020202"
              r="3.044"
              transform="translate(132.827 17.759)"
            />
            <circle
              id="Ellipse_514"
              cx="3.252"
              cy="3.252"
              data-name="Ellipse 514"
              fill="#020202"
              r="3.252"
              transform="translate(129.53 26.542)"
            />
            <circle
              id="Ellipse_515"
              cx="4.724"
              cy="4.724"
              data-name="Ellipse 515"
              fill="#020202"
              r="4.724"
              transform="translate(121.761 8.023)"
            />
            <circle
              id="Ellipse_516"
              cx="4.724"
              cy="4.724"
              data-name="Ellipse 516"
              fill="#020202"
              r="4.724"
              transform="translate(126.422 9.808)"
            />
            <circle
              id="Ellipse_517"
              cx="4.724"
              cy="4.724"
              data-name="Ellipse 517"
              fill="#020202"
              r="4.724"
              transform="translate(129.016 14.969)"
            />
            <circle
              id="Ellipse_518"
              cx="4.724"
              cy="4.724"
              data-name="Ellipse 518"
              fill="#020202"
              r="4.724"
              transform="translate(127.125 22.671)"
            />
            <circle
              id="Ellipse_519"
              cx="4.724"
              cy="4.724"
              data-name="Ellipse 519"
              fill="#020202"
              r="4.724"
              transform="translate(124.292 26.542)"
            />
            <circle
              id="Ellipse_520"
              cx="6.478"
              cy="6.478"
              data-name="Ellipse 520"
              fill="#020202"
              r="6.478"
              transform="translate(132.392 1.546)"
            />
            <circle
              id="Ellipse_521"
              cx="3.044"
              cy="3.044"
              data-name="Ellipse 521"
              fill="#020202"
              r="3.044"
              transform="translate(138.618 10.839)"
            />
            <circle
              id="Ellipse_522"
              cx="3.044"
              cy="3.044"
              data-name="Ellipse 522"
              fill="#020202"
              r="3.044"
              transform="translate(141.913 7.163)"
            />
            <circle
              id="Ellipse_523"
              cx="3.044"
              cy="3.044"
              data-name="Ellipse 523"
              fill="#020202"
              r="3.044"
              transform="translate(142.303 3.774)"
            />
            <path
              id="Path_8106"
              d="M1303.53,321.889l1.273-.276s2.094,8.987,8.455,14.779a1.556,1.556,0,0,1-1.162.884S1305.846,330.569,1303.53,321.889Z"
              data-name="Path 8106"
              fill="#faa61a"
              transform="translate(-1172.231 -319.518)"
            />
            <circle
              id="Ellipse_524"
              cx="3.346"
              cy="3.346"
              data-name="Ellipse 524"
              fill="#74301f"
              r="3.346"
              transform="translate(113.354 21.07)"
            />
            <path
              id="Path_8107"
              d="M1274.122,344.251a3.773,3.773,0,0,0-.158,2.2,1.906,1.906,0,0,0,.823.933c.245.175.816.479.759,1.013a1.021,1.021,0,0,1-.224.517c-.263.34-1.7,1.91-1.341,2.6a.318.318,0,0,0,.191.147c.534.2,2.214.548,2,1.9a.394.394,0,0,1-.374.314,2.765,2.765,0,0,0-2.014,1.166.325.325,0,0,0-.031.252c.091.257.5.18.65.19a3.036,3.036,0,0,1,.85.165c.3.113,1.139.558,1.107,1.2-.014.29-.485.541-.619.642-.336.256-.679.5-1,.787-.189.169-.565.508-.556.9a.8.8,0,0,0,.5.642"
              data-name="Path 8107"
              fill="none"
              stroke="#020202"
              stroke-miterlimit="10"
              stroke-width="3"
              transform="translate(-1161.756 -327.477)"
            />
            <path
              id="Path_8108"
              d="M1256.1,332.46a2.461,2.461,0,0,0-.39,1.384,1.233,1.233,0,0,0,.4.7c.132.144.458.413.351.746a.667.667,0,0,1-.211.3c-.212.182-1.335,1-1.2,1.48a.2.2,0,0,0,.1.119c.314.2,1.34.641,1.023,1.474a.256.256,0,0,1-.28.151,1.8,1.8,0,0,0-1.438.48.211.211,0,0,0-.053.157c.024.175.3.18.39.206a1.986,1.986,0,0,1,.521.217c.177.112.654.506.549.909-.047.183-.381.281-.479.329-.248.119-.5.227-.744.37-.143.084-.427.25-.472.5a.524.524,0,0,0,.237.476"
              data-name="Path 8108"
              fill="none"
              stroke="#020202"
              stroke-miterlimit="10"
              stroke-width="3"
              transform="translate(-1154.879 -323.332)"
            />
            <circle
              id="Ellipse_525"
              cx="1.946"
              cy="1.946"
              data-name="Ellipse 525"
              fill="#020202"
              r="1.946"
              transform="translate(135.826 15.525)"
            />
            <path
              id="Path_8109"
              d="M1253.908,373.549a4.055,4.055,0,0,1,2.556,0,4.193,4.193,0,0,0,2.59,0,8.779,8.779,0,0,1-4.85,1.264Z"
              data-name="Path 8109"
              fill="#561f16"
              transform="translate(-1154.785 -337.712)"
            />
          </g>
          <g id="Group_7140" data-name="Group 7140" transform="translate(348.857 390.322)">
            <g id="Group_7133" data-name="Group 7133" transform="translate(5.616 317.601)">
              <path
                id="Path_8133"
                d="M649.612,663.509a1.263,1.263,0,0,0-1.263-1.263H620.183a1.263,1.263,0,0,0-1.263,1.263v3.857h30.692Z"
                data-name="Path 8133"
                fill="#122845"
                transform="translate(-618.92 -662.246)"
              />
              <path
                id="Path_8134"
                d="M620.183,675.712h28.165a1.263,1.263,0,0,0,1.263-1.263v-2.78H618.92v2.78A1.263,1.263,0,0,0,620.183,675.712Z"
                data-name="Path 8134"
                fill="#081826"
                transform="translate(-618.92 -666.549)"
              />
            </g>
            <g id="Group_7134" data-name="Group 7134" transform="translate(0 0)">
              <path
                id="Path_8135"
                d="M647.063,154.068,655.8,115.3l-.216-32.368,6.6-.022,1.988,35.6a12.83,12.83,0,0,1-.149,2.765l-5.778,35.369Z"
                data-name="Path 8135"
                fill="#724896"
                transform="translate(-626.155 -80.113)"
              />
              <path
                id="Path_8136"
                d="M643.368,137.563s2.8,1.063,3.508,2.29a3.981,3.981,0,0,1,.677,1.926l-4.363-1.291-1.771-1.356Z"
                data-name="Path 8136"
                transform="translate(-623.577 -105.066)"
              />
              <path
                id="Path_8137"
                d="M654.109,513.267l.671,5.271h6.19v-6.989h-6.861Z"
                data-name="Path 8137"
                fill="#ab724e"
                transform="translate(-629.372 -275.834)"
              />
              <path
                id="Path_8138"
                d="M629.6,140.688s2.212-1.915,8.709,1.113,14.261,11.342,13.765,15.306-10.021,3.028-13.324,4.68-6.442,6.332-14.15,3.083-7.818-15.2-1.927-19.656S629.6,140.688,629.6,140.688Z"
                data-name="Path 8138"
                fill="#ab724e"
                transform="translate(-613.117 -106.221)"
              />
              <path
                id="Path_8139"
                d="M664.781,149.811s3.758,1.29,4.518,1.393-.508,2.26-.508,2.26l-3.143-2.7Z"
                data-name="Path 8139"
                fill="#ab724e"
                transform="translate(-634.245 -110.659)"
              />
              <ellipse
                id="Ellipse_533"
                cx="0.936"
                cy="1.451"
                data-name="Ellipse 533"
                rx="0.936"
                ry="1.451"
                transform="translate(26.859 39.237) rotate(-60)"
              />
              <path
                id="Path_8140"
                d="M670.537,330.4l28.254,25.524a4.915,4.915,0,0,1,.948,6.128l-19.55,33.431H671.14l14.464-34.16-32.562-15.649Z"
                data-name="Path 8140"
                fill="#122845"
                transform="translate(-628.885 -193.121)"
              />
              <path
                id="Path_8141"
                d="M641.354,321.116s-5.026,8.325-2.116,17.362,6.182,45.07,6.182,45.07l.915,42.764h7.532l12.778-103.68Z"
                data-name="Path 8141"
                fill="#122845"
                transform="translate(-622.168 -188.88)"
              />
              <path
                id="Path_8142"
                d="M652.123,202.064s2.67,4.12,3.758,5.571,6.819,7.109,6.891,10.373-.943,9.43-.871,9.647,7.133,45.672,7.133,45.672H634.7s3.627-19.825,2.9-25.991-6.165-27.2-4.594-33.607a133.337,133.337,0,0,1,5.275-15.339S641.276,196.844,652.123,202.064Z"
                data-name="Path 8142"
                fill="#724896"
                transform="translate(-619.609 -132.744)"
              />
              <path
                id="Path_8143"
                d="M645.323,219.654l14.917,32.293a5.154,5.154,0,0,0,1.735,2.069l27.671,19.275,7.563-.416-28.5-24.83L658.665,214.68a8.721,8.721,0,0,0-2.978-4.361c-3.081-2.407-8.433-4.94-10.841,3.823A8.064,8.064,0,0,0,645.323,219.654Z"
                data-name="Path 8143"
                fill="#724896"
                transform="translate(-625.015 -137.242)"
              />
              <path
                id="Path_8144"
                d="M672.562,160.216a11.243,11.243,0,0,1-3.391,1.148"
                data-name="Path 8144"
                fill="none"
                stroke="#763420"
                stroke-linecap="round"
                stroke-miterlimit="10"
                stroke-width="1"
                transform="translate(-636.25 -115.41)"
              />
              <path
                id="Path_8145"
                d="M687.022,450.178l-2.18,4.9h8.191l1.676-4.9Z"
                data-name="Path 8145"
                fill="#ab724e"
                transform="translate(-643.406 -247.811)"
              />
              <path
                id="Path_8146"
                d="M728.72,327.782l.624.447s-.529,1.7,1.874,1.7h19.223a6.6,6.6,0,0,0-2.644-.909c-1.826-.306-12.833-1.651-12.833-1.651Z"
                data-name="Path 8146"
                fill="#ab724e"
                transform="translate(-663.441 -191.733)"
              />
              <circle
                id="Ellipse_534"
                cx="1.795"
                cy="1.795"
                data-name="Ellipse 534"
                fill="#993622"
                opacity="0.32"
                r="1.795"
                transform="translate(26.789 43.143)"
              />
              <path
                id="Path_8147"
                d="M644.268,137.953s5.254,6.554,4.847,19.373c0,0,.721-7.185-5.568-16.489Z"
                data-name="Path 8147"
                transform="translate(-624.55 -105.244)"
              />
              <path
                id="Path_8148"
                d="M655.091,523.088l-.3,5.192,2.957.094.84-.094h.762l17.7.094s.419-1.161-1.386-1.573-14.385-3.713-14.385-3.713l-.16-1.574-6.2.28Z"
                data-name="Path 8148"
                transform="translate(-629.683 -280.385)"
              />
              <path
                id="Path_8149"
                d="M683.257,457.413l-1.886,6.975h25.053s.723-1.345-2.313-1.942-12.662-5.033-12.662-5.033l.6-1.749-7.846-.37Z"
                data-name="Path 8149"
                transform="translate(-641.821 -250.147)"
              />
              <path
                id="Path_8150"
                d="M661.76,80.56s1.823-2.167.893-2.492c-1.995-.7-15.038,0-15.038,0s-.679.827,1.836,1.034a35.279,35.279,0,0,1,6.352,1.48Z"
                data-name="Path 8150"
                fill="#ab724e"
                transform="translate(-626.369 -77.759)"
              />
              <path
                id="Path_8151"
                d="M657.48,174.412l-1.631-1.39s-5.47,1.343-5.806,1.39-8.157,2.112-8.157,2.112l-7.072,4.268a5.4,5.4,0,0,1,4.673,6.288c1.72-.746,6.211.718,9.549,2.018a33.349,33.349,0,0,0,7.081,6.047l-3.157-4.768C652.008,182.89,657.48,174.412,657.48,174.412Z"
                data-name="Path 8151"
                fill="#ab724e"
                transform="translate(-620.562 -121.257)"
              />
              <path
                id="Path_8152"
                d="M628.421,138.624s-2.273,2.539,1.131,10.861,8.056,10.862,7.934,16.09-6.522,5.3-8.808,9.891-4.288,21.891-4.933,28.815c-.71,7.612-8.065,3.38-11.59-6.971-2.265-6.649.345-17.1-1.83-21.844s-2.9-24.223,1.357-29.451S630.08,133.106,628.421,138.624Z"
                data-name="Path 8152"
                transform="translate(-608.586 -104.679)"
              />
              <circle
                id="Ellipse_535"
                cx="2.436"
                cy="2.436"
                data-name="Ellipse 535"
                fill="#ab724e"
                r="2.436"
                transform="translate(18.408 44.297)"
              />
            </g>
            <g id="Group_7135" data-name="Group 7135" transform="translate(32.344 214.24)">
              <path
                id="Path_8153"
                d="M698.8,473.292a1.264,1.264,0,0,0-1.264-1.263H669.372a1.263,1.263,0,0,0-1.263,1.263v3.857H698.8Z"
                data-name="Path 8153"
                fill="#122845"
                transform="translate(-668.109 -472.029)"
              />
              <path
                id="Path_8154"
                d="M669.372,485.5h28.165a1.264,1.264,0,0,0,1.264-1.263v-2.78H668.109v2.78A1.263,1.263,0,0,0,669.372,485.5Z"
                data-name="Path 8154"
                fill="#081826"
                transform="translate(-668.109 -476.332)"
              />
            </g>
            <g id="Group_7136" data-name="Group 7136" transform="translate(23.252 247.895)">
              <path
                id="Path_8155"
                d="M682.068,535.228a1.263,1.263,0,0,0-1.263-1.263H652.639a1.263,1.263,0,0,0-1.263,1.263v3.857h30.692Z"
                data-name="Path 8155"
                fill="#122845"
                transform="translate(-651.376 -533.965)"
              />
              <path
                id="Path_8156"
                d="M652.639,547.431H680.8a1.263,1.263,0,0,0,1.263-1.263v-2.779H651.376v2.779A1.263,1.263,0,0,0,652.639,547.431Z"
                data-name="Path 8156"
                fill="#081826"
                transform="translate(-651.376 -538.268)"
              />
            </g>
            <g id="Group_7137" data-name="Group 7137" transform="translate(14.159 281.55)">
              <path
                id="Path_8157"
                d="M665.334,597.163a1.263,1.263,0,0,0-1.263-1.263H635.905a1.263,1.263,0,0,0-1.263,1.263v3.857h30.692Z"
                data-name="Path 8157"
                fill="#122845"
                transform="translate(-634.642 -595.9)"
              />
              <path
                id="Path_8158"
                d="M635.905,609.367h28.166a1.263,1.263,0,0,0,1.263-1.263v-2.779H634.642V608.1A1.263,1.263,0,0,0,635.905,609.367Z"
                data-name="Path 8158"
                fill="#081826"
                transform="translate(-634.642 -600.203)"
              />
            </g>
            <g id="Group_7138" data-name="Group 7138" transform="translate(41.437 180.585)">
              <path
                id="Path_8159"
                d="M715.534,411.356a1.263,1.263,0,0,0-1.263-1.263H686.105a1.263,1.263,0,0,0-1.263,1.263v3.857h30.692Z"
                data-name="Path 8159"
                fill="#122845"
                transform="translate(-684.842 -410.093)"
              />
              <path
                id="Path_8160"
                d="M686.105,423.559h28.166a1.263,1.263,0,0,0,1.263-1.263v-2.78H684.842v2.78A1.263,1.263,0,0,0,686.105,423.559Z"
                data-name="Path 8160"
                fill="#081826"
                transform="translate(-684.842 -414.396)"
              />
            </g>
            <g id="Group_7139" data-name="Group 7139" transform="translate(56.783 138.193)">
              <path
                id="Path_8161"
                d="M757.409,333.235a1.158,1.158,0,0,0-1.158-1.158H714.242a1.158,1.158,0,0,0-1.158,1.158v7.489h44.325Z"
                data-name="Path 8161"
                fill="#122845"
                transform="translate(-713.084 -332.077)"
              />
              <path
                id="Path_8162"
                d="M714.242,356.636h42.009a1.158,1.158,0,0,0,1.158-1.157V347.99H713.084v7.489A1.158,1.158,0,0,0,714.242,356.636Z"
                data-name="Path 8162"
                fill="#081826"
                transform="translate(-713.084 -339.343)"
              />
            </g>
            <path
              id="Path_8163"
              d="M726.214,344.815a1.6,1.6,0,0,0-1.151-1.946l-6.3-1.617a1.6,1.6,0,0,0-1.946,1.151l-16.9,65.849h10.012Z"
              data-name="Path 8163"
              fill="#faa61a"
              transform="translate(-650.289 -198.051)"
            />
            <path
              id="Path_8164"
              d="M667.058,470.268l-37.37,142.874,9.393,2.411L677.07,470.268Z"
              data-name="Path 8164"
              fill="#faa61a"
              transform="translate(-618.221 -256.984)"
            />
            <path
              id="Path_8165"
              d="M837.141,611.885l-35.51-141.618H791.619L827.748,614.3Z"
              data-name="Path 8165"
              fill="#faa61a"
              transform="translate(-692.162 -256.984)"
            />
            <path
              id="Path_8166"
              d="M769.547,342.409a1.6,1.6,0,0,0-1.951-1.154l-6.289,1.614a1.6,1.6,0,0,0-1.154,1.951l16.284,63.434h10.012Z"
              data-name="Path 8166"
              fill="#faa61a"
              transform="translate(-677.771 -198.052)"
            />
            <path
              id="Path_8167"
              d="M717.678,464.6l-.791,3.081H757.5L756.7,464.6Z"
              data-name="Path 8167"
              fill="#122845"
              transform="translate(-658.038 -254.395)"
            />
            <path
              id="Path_8168"
              d="M699.252,464.6l-.791,3.081h10.012l.791-3.081Z"
              data-name="Path 8168"
              fill="#122845"
              transform="translate(-649.624 -254.395)"
            />
            <path
              id="Path_8169"
              d="M800.176,464.6H790.164l.791,3.081h10.012Z"
              data-name="Path 8169"
              fill="#122845"
              transform="translate(-691.497 -254.395)"
            />
            <rect
              id="Rectangle_8326"
              data-name="Rectangle 8326"
              fill="#122845"
              height="4.909"
              rx="2.454"
              transform="translate(131.279 353.652)"
              width="18.009"
            />
            <rect
              id="Rectangle_8327"
              data-name="Rectangle 8327"
              fill="#122845"
              height="4.909"
              rx="2.454"
              transform="translate(8.258 353.652)"
              width="18.009"
            />
            <circle
              id="Ellipse_536"
              cx="1.325"
              cy="1.325"
              data-name="Ellipse 536"
              fill="#081826"
              r="1.325"
              transform="translate(69.341 145.948)"
            />
            <circle
              id="Ellipse_537"
              cx="1.325"
              cy="1.325"
              data-name="Ellipse 537"
              fill="#081826"
              r="1.325"
              transform="translate(86.099 145.948)"
            />
            <circle
              id="Ellipse_538"
              cx="1.325"
              cy="1.325"
              data-name="Ellipse 538"
              fill="#081826"
              r="1.325"
              transform="translate(59.64 183.974)"
            />
            <circle
              id="Ellipse_539"
              cx="1.325"
              cy="1.325"
              data-name="Ellipse 539"
              fill="#081826"
              r="1.325"
              transform="translate(50.952 218.035)"
            />
            <circle
              id="Ellipse_540"
              cx="1.325"
              cy="1.325"
              data-name="Ellipse 540"
              fill="#081826"
              r="1.325"
              transform="translate(33.657 285.116)"
            />
            <circle
              id="Ellipse_541"
              cx="1.325"
              cy="1.325"
              data-name="Ellipse 541"
              fill="#081826"
              r="1.325"
              transform="translate(42.415 251.392)"
            />
            <circle
              id="Ellipse_542"
              cx="1.325"
              cy="1.325"
              data-name="Ellipse 542"
              fill="#081826"
              r="1.325"
              transform="translate(25.114 321.167)"
            />
          </g>
          <g id="Group_7143" data-name="Group 7143" transform="translate(171.523 499.545)">
            <circle
              id="Ellipse_543"
              cx="3.556"
              cy="3.556"
              data-name="Ellipse 543"
              fill="#f2d3bb"
              r="3.556"
              transform="translate(64.422 62.787)"
            />
            <ellipse
              id="Ellipse_544"
              cx="16.477"
              cy="21.545"
              data-name="Ellipse 544"
              fill="#6d3e06"
              rx="16.477"
              ry="21.545"
              transform="translate(36.944 34.929)"
            />
            <path
              id="Path_8170"
              d="M373.493,509.432,363.3,650.409l19.292,1.335,16.951-142.312Z"
              data-name="Path 8170"
              fill="#f7e3cd"
              transform="translate(-343.825 -336.59)"
            />
            <path
              id="Path_8171"
              d="M425.852,510.7v-8.271h-52.36v4.649L363.3,648.054l19.292,1.335,17.653-118.271,16.248,118.271,19.292-1.335Z"
              data-name="Path 8171"
              fill="url(#linear-gradient)"
              transform="translate(-343.825 -334.235)"
            />
            <path
              id="Path_8172"
              d="M371.073,725.61l-.892,5.292H363.76l-.231-1.688-7.26,1.824H333.975s-.411-1.631,2.888-2.179,20.318-6.471,20.318-6.471l13.892.6Z"
              data-name="Path 8172"
              transform="translate(-333.958 -408.208)"
            />
            <path
              id="Path_8173"
              d="M445.21,725.61l.892,5.292h6.421l.231-1.688,7.26,1.824h22.294s.411-1.631-2.888-2.179-20.318-6.471-20.318-6.471l-13.893.6Z"
              data-name="Path 8173"
              transform="translate(-371.372 -408.208)"
            />
            <g id="Group_7141" data-name="Group 7141" transform="translate(39.607 42.623)">
              <path
                id="Path_8174"
                d="M427.122,372.113l-4.514,2A13.032,13.032,0,0,0,427.122,372.113Z"
                data-name="Path 8174"
                fill="#f2d3bb"
                transform="translate(-403.379 -333.032)"
              />
              <path
                id="Path_8175"
                d="M417.38,352.529c.005-.075.009-.151.015-.225l-.016.007a13.032,13.032,0,0,1-4.514,2l4.514-2c3.418-2.43,6.247-7.3,6.537-17.461.265-9.3-4.948-17.7-9.432-20.348-3.075-1.817-8.183-1.563-11.4-.021-4.652,2.229-10.079,10.107-9.385,22.958,0,0,.395,7.685,3.977,11.915a6.674,6.674,0,0,1,1.081,2.071C407.973,356.557,414,354.822,417.38,352.529Z"
                data-name="Path 8175"
                fill="#f2d3bb"
                transform="translate(-393.635 -313.229)"
              />
              <path
                id="Path_8176"
                d="M401.976,406.331a5.389,5.389,0,0,1-.313.963l21.26-.056-.046-.175Z"
                data-name="Path 8176"
                fill="#f2d3bb"
                transform="translate(-396.335 -344.54)"
              />
              <path
                id="Path_8177"
                d="M419.973,371.882c-3.384,2.294-9.407,4.029-18.627-1.105,2.089,5.936,1.58,19.329.524,23.6l20.9.732C422.358,393.531,419.3,381.4,419.973,371.882Z"
                data-name="Path 8177"
                fill="#e8c8b3"
                transform="translate(-396.228 -332.583)"
              />
            </g>
            <path
              id="Path_8178"
              d="M394.469,316.536s-6.069,3.573-4.625,13.266,5.912-12.268,5.912-12.268Z"
              data-name="Path 8178"
              fill="#6d3e06"
              transform="translate(-352.678 -271.719)"
            />
            <g id="Group_7142" data-name="Group 7142" transform="translate(41.912 34.929)">
              <path
                id="Path_8179"
                d="M430.934,316.361c-1.91-3.432-8.955-14.219-22.318-14.724l-11.509,16.036a20.777,20.777,0,0,0,2.393,1.069C413.384,320.291,426.978,317.337,430.934,316.361Z"
                data-name="Path 8179"
                fill="#6d3e06"
                transform="translate(-397.108 -301.637)"
              />
              <path
                id="Path_8180"
                d="M432.689,324.864s-.179-.39-.542-1.042c-3.956.976-17.55,3.93-31.433,2.381C405.606,328.093,417.593,331.256,432.689,324.864Z"
                data-name="Path 8180"
                fill="#562f04"
                transform="translate(-398.32 -309.098)"
              />
            </g>
            <ellipse
              id="Ellipse_545"
              cx="1.366"
              cy="2.118"
              data-name="Ellipse 545"
              rx="1.366"
              ry="2.118"
              transform="translate(48.776 60.669)"
            />
            <circle
              id="Ellipse_546"
              cx="3.556"
              cy="3.556"
              data-name="Ellipse 546"
              fill="#f2d3bb"
              r="3.556"
              transform="translate(36.455 62.787)"
            />
            <ellipse
              id="Ellipse_547"
              cx="1.366"
              cy="2.118"
              data-name="Ellipse 547"
              rx="1.366"
              ry="2.118"
              transform="translate(60.249 60.174)"
            />
            <path
              id="Path_8181"
              d="M419.45,346.057s0,1.656.388,2.081,2.578,3.589,2.578,3.589a3.167,3.167,0,0,1-1.11,1.106c-.611.271-.007,1.173-.007,1.173"
              data-name="Path 8181"
              fill="none"
              stroke="#ddbca6"
              stroke-miterlimit="10"
              stroke-width="1"
              transform="translate(-362.709 -281.647)"
            />
            <path
              id="Path_8182"
              d="M423.3,361.667s-6.271,1.471-10.951-.847C412.354,360.82,415.448,371.917,423.3,361.667Z"
              data-name="Path 8182"
              fill="#fff"
              transform="translate(-360.323 -286.611)"
            />
            <path
              id="Path_8183"
              d="M367.479,256.7s3.607-5.207,1.066-6.883-21.948-.242-21.948-.242-1.568,1.755,2.973,1.917,9.352,1.838,9.946,2.973S367.479,256.7,367.479,256.7Z"
              data-name="Path 8183"
              fill="#f2d3bb"
              transform="translate(-338.121 -249.009)"
            />
            <path
              id="Path_8184"
              d="M456.293,256.864s-3.606-5.207-1.065-6.883,21.948-.242,21.948-.242,1.568,1.755-2.973,1.917-9.352,1.838-9.947,2.973S456.293,256.864,456.293,256.864Z"
              data-name="Path 8184"
              fill="#f2d3bb"
              transform="translate(-374.437 -249.063)"
            />
            <circle
              id="Ellipse_548"
              cx="2.62"
              cy="2.62"
              data-name="Ellipse 548"
              fill="#ef9981"
              opacity="0.2"
              r="2.62"
              transform="translate(45.077 67.28)"
            />
            <circle
              id="Ellipse_549"
              cx="2.62"
              cy="2.62"
              data-name="Ellipse 549"
              fill="#ef9981"
              opacity="0.2"
              r="2.62"
              transform="translate(62.012 66.689)"
            />
            <path
              id="Path_8185"
              d="M378.459,508.3l-.656,3.133s-.729,2.259,11.875.728,26.665.116,31.692.532,10.2.051,10.054-.968.729-2.4-.291-3.425-52.092-1.6-52.092-1.6Z"
              data-name="Path 8185"
              fill="#0f0c0c"
              transform="translate(-348.701 -335.669)"
            />
            <path
              id="Path_8186"
              d="M378.365,506.724l-.656,3.133s-.728,2.259,11.875.729,26.665.116,31.692.532,10.2.051,10.054-.969.729-2.4-.291-3.424-52.092-1.6-52.092-1.6Z"
              data-name="Path 8186"
              fill="#fff"
              transform="translate(-348.669 -335.14)"
            />
            <path
              id="Path_8187"
              d="M423.718,256.9l-6.634,2.41a2.526,2.526,0,0,0-1.582,3.012l13.445,39.629a12.5,12.5,0,0,1-1.445,9.7c-3.748,6.1-10.34,20.582-13.732,24.6a29.73,29.73,0,0,1-13.886,8.715,2.5,2.5,0,0,0-.636.275l-6.671,4.473a2.524,2.524,0,0,1-2.5.073l-8.184-4.736s-9.549-4.865-13.223-9.488c-1.643-2.066-8.242-15.35-12.849-21.877a17.375,17.375,0,0,1-2.617-14.407l12.747-36.953a2.526,2.526,0,0,0-1.582-3.012l-6.634-2.41a2.526,2.526,0,0,0-3.269,1.609l-16.261,39.146a23.732,23.732,0,0,0,1.58,18.187l25.528,52.853a2.52,2.52,0,0,1,.285,1.256l-1.773,51.893a2.526,2.526,0,0,0,2.525,2.612h24.376l25.261.221a2.526,2.526,0,0,0,2.548-2.5l.6-54.432a2.529,2.529,0,0,1,.251-1.071l23.842-53.345a17.436,17.436,0,0,0,.915-12.87L426.988,258.5A2.526,2.526,0,0,0,423.718,256.9Z"
              data-name="Path 8187"
              fill="#383d73"
              transform="translate(-335.011 -251.61)"
            />
            <path
              id="Path_8188"
              d="M395.217,310.49c-.154-.232-.6-.728-.6-.728s1.971-.129,1.885-.429-.686-2.356-.686-2.356l3.771.514-1.8,4.114-2.95-1.027"
              data-name="Path 8188"
              fill="#6d3e06"
              transform="translate(-354.358 -268.504)"
            />
            <rect
              id="Rectangle_8328"
              data-name="Rectangle 8328"
              fill="#724896"
              height="4.964"
              rx="2.482"
              transform="translate(52.759 99.536)"
              width="6.925"
            />
            <path
              id="Path_8189"
              d="M421.483,399.788l-2.985-2-2.76,1.041-2.442.79-4.493,1.9,3.8,3.586-3.8,45.01,6.935,12.059,5.745-12.059-2.81-45.01Z"
              data-name="Path 8189"
              fill="#724896"
              transform="translate(-359.129 -299.043)"
            />
            <path
              id="Path_8190"
              d="M392.911,382.727l17.19,9.7-5.516,6.052Z"
              data-name="Path 8190"
              fill="#fff"
              transform="translate(-353.784 -293.979)"
            />
            <path
              id="Path_8191"
              d="M418.811,393.385l4.918,6.279,8.863-13.443-4.111-.646Z"
              data-name="Path 8191"
              fill="#fff"
              transform="translate(-362.494 -294.936)"
            />
            <path
              id="Path_8192"
              d="M391.859,525.843s-9.411,3.029-14.064-1.108"
              data-name="Path 8192"
              fill="none"
              stroke="#383d73"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="1"
              transform="translate(-348.701 -341.737)"
            />
            <path
              id="Path_8193"
              d="M451.242,525.843s-9.411,3.029-14.064-1.108"
              data-name="Path 8193"
              fill="none"
              stroke="#383d73"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="1"
              transform="translate(-368.671 -341.737)"
            />
          </g>
        </g>
      </svg>
    </div>
    <div class="w-full lg:w-5/12 lg:pl-20">
      <div
        class="relative mt-24 rounded-b-xl rounded-t-xl py-10 pl-4 pr-4 lg:mt-0 lg:rounded-tl-none lg:pl-8"
        style="background-color: #122845"
      >
        <svg
          class="absolute hidden h-10 w-10 lg:inline"
          style="top: -14px; left: -14px"
          viewBox="0 0 63.57 63.57"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m21.191 21.189h21.19v21.19h-21.19z" fill="#222f61" />
          <path d="m0 21.189h21.19v21.19h-21.19z" fill="#dc3529" />
          <path d="m42.38 21.189h21.19v21.19h-21.19z" fill="#0099db" />
          <path d="m21.191 0h21.19v21.19h-21.19z" fill="#724896" />
          <path d="m21.191 42.379h21.19v21.19h-21.19z" fill="#fc9200" />
        </svg>
        <svg
          class="absolute h-16 w-16 lg:hidden"
          style="top: -42px; left: 50%; transform: translate(-50%, 0%)"
          viewBox="0 0 63.57 63.57"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m21.191 21.189h21.19v21.19h-21.19z" fill="#222f61" />
          <path d="m0 21.189h21.19v21.19h-21.19z" fill="#dc3529" />
          <path d="m42.38 21.189h21.19v21.19h-21.19z" fill="#0099db" />
          <path d="m21.191 0h21.19v21.19h-21.19z" fill="#724896" />
          <path d="m21.191 42.379h21.19v21.19h-21.19z" fill="#fc9200" />
        </svg>
        <p class="text-center font-heading text-5xl text-white lg:text-left">{{ title }}</p>
        <p class="mt-3 text-center leading-normal text-white lg:text-left">
          {{ $t('Rate limit exceeded, please try again later.') }}
        </p>
        <div class="mt-8 flex flex-col justify-center gap-2 md:flex-row">
          <button
            class="mx-auto flex cursor-pointer items-center justify-center rounded-full bg-white px-8 py-3 font-semibold md:mx-0"
            @click="goBack"
          >
            <ArrowLeftIcon class="mr-2 h-5 w-5 text-black" />
            {{ $t('Go Back') }}
          </button>
          <button
            class="mx-auto flex cursor-pointer items-center justify-center rounded-full border-2 border-white bg-transparent px-8 py-3 font-semibold text-white md:mx-0"
            @click="$inertia.visit('/')"
          >
            <HomeIcon class="mr-2 h-5 w-5" />
            {{ $t('Home') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowLeftIcon, HomeIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    ArrowLeftIcon,
    HomeIcon,
  },
  props: {
    title: String,
    description: String,
  },
  methods: {
    goBack() {
      this.$emit('goBack')
    },
  },
}
</script>
