<template>
  <content-layout :enrolment-content="enrolmentContent" :is-last="isLast">
    <div>
      <p v-if="enrolmentContent.content.type === 'file'" class="mb-2">
        <icon class="inline h-5 w-5 fill-gray-500" name="document" />
        <span class="uppercase">{{ enrolmentContent.content.file_extension }}</span>
        <span>| {{ enrolmentContent.content.file_size }}</span>
      </p>
      <p>{{ enrolmentContent.content.description }}</p>
    </div>
    <template #actions>
      <a
        :class="{
          'btn-red-gradient': isTenantWorksite && !enrolmentContent.finished_at,
          'tenant-btn-1': !isTenantWorksite && !enrolmentContent.finished_at,
          'btn-red-outline': isTenantWorksite && enrolmentContent.finished_at,
          'tenant-btn-1-outline': !isTenantWorksite && enrolmentContent.finished_at,
          disabled: isDisabled,
        }"
        :href="!isDisabled ? enrolmentContent.content.route : '#'"
        :target="!isDisabled ? '_blank' : null"
      >
        {{ $t('Open File') }}
      </a>
    </template>
  </content-layout>
</template>
<script>
import ContentLayout from './ContentLayout.vue'
import Icon from '@/Shared/Icon.vue'
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  components: { ContentLayout, Icon },
  props: {
    enrolment: Object,
    enrolmentContent: Object,
    isLast: Boolean,
  },
  computed: {
    isTenantWorksite,
    isDisabled() {
      return !this.enrolmentContent.prerequisite_complete || !this.enrolmentContent.content.route
    },
  },
}
</script>
