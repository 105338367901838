<template>
  <modal class="text-base" :show="show" width="400px" @close="close">
    <div class="flex rounded-lg bg-white">
      <div class="w-full px-8 pb-8">
        <img src="/images/no-credits.svg" />
        <div class="space-y-4 px-4 text-center">
          <div class="font-bold leading-snug">{{ $t("You don't have any credits available for the course.") }}</div>
          <div class="text-sm leading-snug text-gray-500">{{ $t('Purchase credits or contact your manager.') }}</div>
          <div class="flex justify-center space-x-2 text-sm">
            <purchase-link @click="close">{{ $t('Purchase Course') }}</purchase-link>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from './Modal.vue'
import PurchaseLink from './PurchaseLink.vue'

export default {
  components: {
    Modal,
    PurchaseLink,
  },
  props: {
    show: Boolean,
  },
  methods: {
    close() {
      this.$emit('close')
    },
    purchase() {
      console.log('purchase')
    },
  },
}
</script>
