<template>
  <layout :title="$t('Courses')">
    <div class="mb-8">
      <breadcrumb :name="$t('Course Categories')" />
    </div>
    <div class="mb-6 flex items-center justify-between">
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
        <label class="block text-gray-800">{{ $t('Deleted:') }}</label>
        <select v-model="form.deleted" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="with">{{ $t('With Deleted') }}</option>
          <option value="only">{{ $t('Only Deleted') }}</option>
        </select>
      </search-filter>
      <div class="flex cursor-pointer select-none items-center">
        <InertiaLink class="btn-blue" :href="route('course-categories.create')">
          {{ $t('Create New Category') }}
        </InertiaLink>
      </div>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow">
      <SimpleTable class="w-full">
        <template #head>
          <TableHead>{{ $t('Name') }}</TableHead>
          <TableHead>{{ $t('Courses') }}</TableHead>
          <TableHead />
        </template>
        <TableRow v-if="courseCategories.data.length === 0">
          <TableData colspan="5">{{ $t('No course parents found.') }}</TableData>
        </TableRow>
        <TableRow
          v-for="courseCategory in courseCategories.data"
          :key="courseCategory.id"
          :clickable="true"
          @click="$inertia.visit(route('course-categories.edit', courseCategory.id))"
        >
          <TableData>
            <div class="flex items-center">
              <span class="whitespace-nowrap">{{ courseCategory.name }}</span>
            </div>
          </TableData>
          <TableData class="text-right">
            {{ courseCategory.courses_count }}
          </TableData>
          <TableData class="w-min align-middle">
            <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
          </TableData>
        </TableRow>
      </SimpleTable>
    </div>
    <pagination :links="courseCategories.links" />
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
    Breadcrumb,
    Icon,
    Layout,
    Pagination,
    SearchFilter,
  },
  props: {
    filters: Object,
    courseCategories: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
      },
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(this.route('course-categories'), Object.keys(query).length ? query : { remember: 'forget' }, {
          preserveState: true,
          replace: true,
        })
      }, 300),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
