<template>
  <nav class="-mb-px flex border-b border-gray-200 bg-white px-10 pt-4">
    <InertiaLink
      v-for="(tab, i) in tabs"
      :key="i"
      class="hidden whitespace-nowrap border-b-4 px-1 py-4 font-bold leading-5 focus:outline-none lg:inline"
      :class="(href === tab.href ? activeClasses : inactiveClasses) + (i > 0 ? ' ml-8' : '')"
      :href="tab.href"
    >
      {{ tab.text }}
    </InertiaLink>

    <div class="w-full lg:hidden">
      <select
        v-model="selectedPage"
        class="form-select focus:ring-blue mt-1 block w-full border-gray-200 bg-ts-front-field-light py-2 pl-3 pr-10 text-base leading-6 text-black transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none lg:text-sm lg:leading-5"
        @change="$inertia.visit(selectedPage)"
      >
        <option v-for="(tab, i) in tabs" :key="i" :value="tab.href">
          {{ tab.text }}
        </option>
      </select>
    </div>

    <front-button-link
      class="relative mb-2 ml-auto hidden xl:inline"
      :class="{ disabled: !showTransferButton }"
      :disabled="!showTransferButton"
      :href="showTransferButton ? route('front.enrolment-transfers.step1.create') : '#'"
    >
      {{ $t('Transfer Enrolments') }}
    </front-button-link>
  </nav>
</template>

<script>
import FrontButtonLink from '@/Shared/FrontButtonLink.vue'
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  components: {
    FrontButtonLink,
  },
  props: {
    showTransferButton: Boolean,
  },
  data() {
    return {
      selectedPage: window.location.origin + window.location.pathname,
    }
  },
  computed: {
    isTenantWorksite,
    href() {
      return window.location.origin + window.location.pathname
    },
    activeClasses() {
      return this.isTenantWorksite
        ? 'border-qualify-red-500 text-gray-800 focus:text-gray-800 focus:border-gray-700 font-bold'
        : 'border-blue-700 text-gray-800 focus:text-gray-800 focus:border-gray-700 font-bold'
    },
    inactiveClasses() {
      return 'text-ts-gray-text-bold border-transparent hover:text-gray-700 hover:border-gray-200 focus:text-gray-700 focus:border-gray-200'
    },
    tabs() {
      return [
        { text: this.$t('My Purchases'), href: this.route('front.purchases') },
        { text: this.$t('My Enrolments'), href: this.route('front.enrolments.index') },
      ]
    },
  },
}
</script>
