<template>
  <div class="overflow-hidden rounded bg-white p-4 shadow">
    <h3 class="pb-2 text-lg font-semibold">{{ certificate.name }}</h3>
    <dl class="grid grid-cols-3 gap-2 text-sm">
      <dt>{{ $t('Issued at') }}</dt>
      <dd class="col-span-2">
        <span>:</span>
        <front-date class="inline" :timestamp="certificate.issued_at" />
      </dd>
      <dt>{{ $t('Expires at') }}</dt>
      <dd class="col-span-2">
        <span>:</span>
        <front-date class="inline" :timestamp="certificate.expires_at" />
      </dd>
      <dt v-if="certificate.certificate_number">{{ $t('Cert. Id') }}</dt>
      <dd v-if="certificate.certificate_number" class="col-span-2">
        <span>:</span>
        <span>{{ certificate.certificate_number }}</span>
      </dd>
    </dl>
  </div>
</template>

<script>
import FrontDate from '@/Shared/FrontDate.vue'

export default {
  components: {
    FrontDate,
  },
  props: {
    certificate: Object,
  },
}
</script>
