<template>
  <layout :title="$t('Files')">
    <h1 class="mb-8 text-xl font-bold">{{ $t('Files') }}</h1>
    <div class="mb-6 flex items-center justify-between">
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
        <label class="mt-4 block text-gray-800">{{ $t('Status:') }}</label>
        <select v-model="form.failed" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="only">{{ $t('Only Failed') }}</option>
        </select>
      </search-filter>
      <InertiaLink class="btn-blue" :href="route('files.create')">
        <span>{{ $t('Upload') }}</span>
        <span class="hidden md:inline">{{ $t('File') }}</span>
      </InertiaLink>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow">
      <SimpleTable>
        <template #head>
          <TableHead>{{ $t('Name') }}</TableHead>
          <TableHead>{{ $t('Title') }}</TableHead>
          <TableHead>{{ $t('Success') }}</TableHead>
          <TableHead />
        </template>
        <TableRow v-if="files.data.length === 0">
          <TableData colspan="4">{{ $t('No files found.') }}</TableData>
        </TableRow>
        <TableRow
          v-for="(file, key) in files.data"
          :key="key"
          :clickable="true"
          @click="$inertia.visit(route('files.edit', file.id))"
        >
          <TableData>{{ file.name }}</TableData>
          <TableData>{{ file.title }}</TableData>
          <TableData>{{ file.is_uploaded }}</TableData>
          <TableData class="w-min align-middle">
            <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
          </TableData>
        </TableRow>
      </SimpleTable>
    </div>
    <pagination :links="files.links" />
  </layout>
</template>

<script>
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    SimpleTable,
    TableData,
    TableRow,
    Icon,
    Layout,
    Pagination,
    SearchFilter,
    TableHead,
  },
  props: {
    files: Object,
    filters: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        failed: this.filters.failed,
      },
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(this.route('files'), Object.keys(query).length ? query : { remember: 'forget' }, {
          preserveState: true,
          replace: true,
        })
      }, 300),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
