<template>
  <front-layout :title="$t('Purchase Receipt')">
    <div class="no-print mb-8">
      <breadcrumb name="Receipt" :previous-name="$t('Purchases')" :previous-url="route('front.purchases')" />
    </div>
    <div id="receipt" class="max-w-xl rounded-lg bg-white px-8 py-8 text-sm md:px-16">
      <div class="no-print mb-6 flex justify-end" data-html2canvas-ignore="true">
        <button
          v-if="showPrintLink"
          class="no-print mr-1 rounded border bg-gray-100 p-2 hover:bg-gray-200"
          title="Print receipt"
          @click="printReceipt()"
        >
          <icon class="h-5 w-5 fill-gray-500" name="printer" />
        </button>
        <a
          v-if="transaction.can.email_purchase"
          class="no-print mx-1 rounded border bg-gray-100 p-2 hover:bg-gray-200"
          :href="route('front.purchases.receipt.email', transaction.id)"
          title="Email receipt"
        >
          <icon class="h-5 w-5 text-gray-500" name="email" />
        </a>
        <a
          class="no-print ml-1 rounded border bg-gray-100 p-2 hover:bg-gray-200"
          :href="route('front.purchases.receipt.download', transaction.id)"
          title="Download receipt"
        >
          <icon class="h-5 w-5 text-gray-500" name="download" />
        </a>
      </div>
      <div class="mb-4 flex items-center justify-between py-4 sm:flex-row">
        <div>
          <worksite-dark class="mx-auto sm:mx-0" />
        </div>
        <div>
          <div v-if="tenant.business_name" class="mb-2">{{ tenant.business_name }}</div>
          <div v-if="tenant.address">{{ tenant.address }}</div>
        </div>
      </div>
      <div class="flex justify-between border-t border-gray-200 py-4 sm:flex-row">
        <div class="mb-6 md:mb-0">
          <div class="mb-4 text-gray-400">{{ $t('Name') }}</div>
          <div>{{ transaction.name }}</div>
        </div>
        <div class="mb-6 md:mb-0">
          <div class="mb-4 text-gray-400">{{ $t('Order Date') }}</div>
          <div>{{ transaction.purchased_at }}</div>
        </div>
        <div class="mb-6 md:mb-0">
          <div class="mb-4 text-gray-400">{{ $t('Order No.') }}</div>
          <div>{{ transaction.order_no }}</div>
        </div>
        <div class="mb-6 md:mb-0">
          <div class="mb-4 text-gray-400">{{ $t('Payment Method') }}</div>
          <div class="flex items-center">{{ transaction.payment_method }}</div>
        </div>
        <div class="mb-6 md:mb-0">
          <div class="mb-4 text-gray-400">{{ $t('Billing Address') }}</div>
          <div v-if="transaction.organization_name" class="max-w-10 leading-snug">
            {{ transaction.organization_name }}
          </div>
          <div class="max-w-10 leading-snug">{{ transaction.billing_address }}</div>
        </div>
      </div>
      <div v-if="transaction.shipping_address" class="flex justify-between border-t border-gray-200 py-4 sm:flex-row">
        <div class="mb-6 md:mb-0">
          <div class="mb-4 text-gray-400">{{ $t('Shipping Address') }}</div>
          <div class="max-w-10 leading-snug">{{ transaction.shipping_address }}</div>
        </div>
      </div>
      <div class="border-b border-t border-gray-200 py-8">
        <table class="w-full">
          <thead>
            <tr>
              <th class="text-left font-semibold">{{ $t('Name') }}</th>
              <th class="text-left font-semibold">{{ $t('Quantity') }}</th>
              <th class="text-left font-semibold">{{ $t('Price') }}</th>
              <th class="text-right font-semibold">{{ $t('Total') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!getItems.length">
              <td class="px-6 py-4" colspan="6">{{ $t('No items found.') }}</td>
            </tr>
            <tr v-for="item in getItems" :key="item.id" style="padding-top: 10px; padding-bottom: 10px">
              <td class="pt-8">
                <span v-if="item.type == 'coupon_line'">{{ $t('Coupon:') }}</span>
                {{ item.name }}
              </td>
              <td class="pt-8">
                <span v-if="item.type != 'coupon_line'">
                  {{ item.quantity }}
                </span>
              </td>
              <td class="pt-8">{{ item.price }}</td>
              <td class="pt-8 text-right">
                <span v-if="item.type == 'coupon_line'">-&nbsp;</span>
                <span>${{ item.subtotal }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex justify-end pt-8">
        <div class="w-48 space-y-4">
          <div class="flex justify-between text-gray-500">
            <span>{{ $t('Subtotal') }}</span>
            <span>${{ transaction.subtotal }}</span>
          </div>
          <div class="flex justify-between text-gray-500">
            <span>{{ $t('Tax') }}</span>
            <span>${{ transaction.tax }}</span>
          </div>
          <div class="flex justify-between">
            <span>{{ $t('Total') }}</span>
            <span class="font-bold">${{ transaction.total }}</span>
          </div>
          <div v-if="getRefundTotal > 0" class="flex justify-between text-gray-500">
            <span>{{ $t('Refunded') }}</span>
            <span class="text-ts-red-500">-${{ getRefundTotal }}</span>
          </div>
        </div>
      </div>
      <div v-if="tenant.tax_number" class="pt-12 text-center">
        {{ $t('GST/HST Number : :tax_number', { tax_number: tenant.tax_number }) }}
      </div>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import Icon from '@/Shared/Icon.vue'
import WorksiteDark from '@/Shared/Logo/WorksiteDark.vue'

export default {
  components: {
    Breadcrumb,
    FrontLayout,
    Icon,
    WorksiteDark,
  },
  props: {
    tenant: Object,
    transaction: Object,
    items: Array,
  },
  data() {
    return {
      showPrintLink: typeof window.print === 'function',
    }
  },
  computed: {
    getItems() {
      return this.items.filter((item) => item.name !== 'Refund:')
    },
    getRefundTotal() {
      return this.items
        .filter((item) => item.name === 'Refund:')
        .reduce((total, item) => {
          return (total += Math.abs(item.subtotal))
        }, 0)
    },
  },
  methods: {
    printReceipt() {
      if (this.showPrintLink) {
        window.print()
      }
    },
  },
}
</script>
