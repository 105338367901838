<template>
  <modal class="rounded text-base" :show="show" style="z-index: 100000" width="auto" @close="close">
    <div class="max-h-screen max-w-lg overflow-auto rounded bg-white p-6 shadow md:p-10">
      <h3 class="mb-4 mt-4 text-center text-2xl text-black">{{ $t('Update Format Background') }}</h3>
      <div>
        <file-input
          id="logo"
          accept="image/*"
          class="pt-4"
          :error="form.errors.background"
          inner-classes="justify-center h-48"
          @input="form.background = $event"
        />
      </div>
      <div class="mt-12 flex items-center justify-end bg-white">
        <button class="btn-gray" type="button" @click="uploadBackground()">{{ $t('Upload') }}</button>
      </div>
    </div>
  </modal>
</template>

<script>
import FileInput from '@/Shared/FileInput.vue'
import Modal from '@/Shared/Modal.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    FileInput,
    Modal,
  },
  props: {
    show: Boolean,
    format: Object,
  },
  data() {
    return {
      form: useForm({
        background: null,
      }),
    }
  },
  methods: {
    close() {
      this.form.background = null
      this.$emit('close')
    },
    uploadBackground() {
      this.form.post(this.route('certificate-templates-format.replace-background', [this.format.id]))
      this.$emit('close')
    },
  },
}
</script>
