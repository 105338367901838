<template>
  <div class="mt-6 overflow-x-auto rounded bg-white px-6 py-2 shadow">
    <h2 class="text-m mt-2">{{ upcomingInvoice.title }}</h2>
    <div class="flex w-full items-center justify-between">
      <div class="whitespace-nowrap py-4">
        <div class="mb-2 font-bold">{{ $t('Billing Period') }}</div>
        <div class="flex">
          <show-date :timestamp="upcomingInvoice.period_start" />
          <span class="px-2">-</span>
          <show-date :timestamp="upcomingInvoice.period_end" />
        </div>
      </div>
      <div class="whitespace-nowrap py-4">
        <div class="mb-2 font-bold">{{ $t('Amount Due') }}</div>
        <div>
          <span class="capitalize">{{ upcomingInvoice.amount_due }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ShowDate from '@/Shared/ShowDate.vue'

export default {
  components: {
    ShowDate,
  },
  props: {
    upcomingInvoice: Object,
  },
}
</script>
