<template>
  <front-layout :title="$t('Quiz Result')">
    <div class="mb-8">
      <breadcrumb :previous-name="quiz.title" :previous-url="route('front.quizzes', [enrolmentContent.id, quiz.id])" />
    </div>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <div class="flex w-full flex-col items-center justify-center px-4 sm:px-8 lg:flex-row lg:px-20">
        <div class="flex-1 pt-6 text-center lg:py-8 lg:text-left">
          <div class="pb-1 text-ts-gray-400">{{ $t('Attempt :index', { index: attempt.index }) }}</div>
          <h3 class="text-empty-state-header text-xl font-semibold leading-6">{{ quiz.title }}</h3>
        </div>
        <div class="flex items-center justify-center pb-4 pt-4">
          <div class="px-2 py-1 lg:px-8">
            <QuizStatus :status="attempt.status" />
            <div class="pt-1 text-ts-gray-400 text-center flex items-center justify-center">
              {{ $t('Status') }}
              <Popper v-if="quiz.proctoring_enabled" :hover="true">
                <icon
                  class="ml-1 h-4 w-4 flex-shrink-0 rounded-full border-2 fill-current border-ts-gray-400"
                  name="info-2"
                />
                <template #content>
                  <div class="rounded border bg-white py-2 text-sm shadow-lg font-normal px-2">
                    {{ $t('The overall status of a quiz is determined by score and proctoring outcome.') }}
                  </div>
                </template>
              </Popper>
            </div>
          </div>
          <div class="flex-col py-1">
            <div
              class="text-xl font-semibold"
              :class="{
                'text-gray-600': true,
                'text-green-600': attempt.status === 'passed',
                'text-qualify-red-500': attempt.status === 'failed',
              }"
            >
              {{ attempt.scorePercent }}%
            </div>
            <div class="pt-1 text-ts-gray-400">{{ $t('Final') }}</div>
          </div>
        </div>
      </div>
      <div class="border-t border-gray-300">
        <div class="overflow-x-auto">
          <div v-for="(question, questionKey) in attempt.questions" :key="questionKey" class="px-4 py-6">
            <question :question="question" :question-key="questionKey" :show-answers="attempt.can.viewAnswers" />
          </div>
        </div>
        <div class="flex items-center justify-end px-4 py-6 md:pb-12 lg:pb-16 lg:pr-20">
          <front-button-link :href="route('front.quizzes', [enrolmentContent.id, quiz.id])">
            {{ $t('Exit') }}
          </front-button-link>
        </div>
      </div>
    </div>
  </front-layout>
</template>

<script>
import Question from './Question.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontButtonLink from '@/Shared/FrontButtonLink.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import Icon from '@/Shared/Icon.vue'
import QuizStatus from '@/Shared/Quiz/QuizStatus.vue'
import Popper from 'vue3-popper'

export default {
  components: {
    Icon,
    Popper,
    Question,
    Breadcrumb,
    QuizStatus,
    FrontButtonLink,
    FrontLayout,
  },
  props: {
    enrolmentContent: Object,
    quiz: Object,
    attempt: Object,
  },
  computed: {
    passingScorePercentage() {
      return Math.round((this.quiz.passing_score / this.quiz.total_score) * 100) + '%'
    },
  },
}
</script>
