<template>
  <front-layout :title="$t('Certificates')">
    <div class="flex flex-col xl:flex-row xl:justify-between">
      <div class="sm:no-flex mb-8 flex w-full items-center justify-between">
        <breadcrumb :name="$t('Certificates')" />
        <div class="">
          <front-outline-button
            v-if="tenant.plastic_card_price != null"
            @click="$inertia.visit(route('front.manager.plastic-cards'))"
          >
            <span>{{ $t('Order') }}</span>
            <span class="ml-1 hidden md:inline">{{ $t('Plastic Cards') }}</span>
          </front-outline-button>
          <front-solid-button v-if="training_records_enabled" class="ml-2" @click="openCreateModal">
            <span>{{ $t('+ Training Record') }}</span>
          </front-solid-button>
        </div>
      </div>
    </div>
    <div class="overflow-show rounded bg-white shadow-lg">
      <table class="front-table">
        <thead>
          <tr>
            <th class="whitespace-nowrap">{{ $t('Certificate Name') }}</th>
            <th>{{ $t('Earned') }}</th>
            <th>{{ $t('Active') }}</th>
            <th class="whitespace-nowrap">{{ $t('Expiring (90 Days)') }}</th>
            <th>{{ $t('Expired') }}</th>
            <th />
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
          <tr v-if="!certificates.data.length">
            <td class="border-t px-6 py-4" colspan="6">{{ $t('No certificates found.') }}</td>
          </tr>
          <tr
            v-for="(certificate, index) in certificates.data"
            :key="index"
            class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
          >
            <td class="flex items-center px-6 py-4" @click="openReport(certificate.id)">
              <span class="pr-2 font-semibold leading-5 text-ts-gray-text-bold">
                <front-truncate-text :text="certificate.name" />
              </span>
              <span
                v-if="certificate.is_training_record"
                class="cursor-pointer rounded bg-ts-blue-600 bg-opacity-8 p-1 text-xs font-semibold leading-5 text-ts-blue-600"
              >
                {{ $t('Training Record') }}
              </span>
              <span
                v-else
                class="cursor-pointer rounded bg-ts-blue-600 bg-opacity-8 p-1 text-xs font-semibold leading-5 text-ts-blue-600"
              >
                {{ $t('Course') }}
              </span>
            </td>
            <td
              class="cursor-pointer whitespace-nowrap px-6 py-4 leading-5 text-ts-gray-text"
              @click="openReport(certificate.id)"
            >
              {{ certificate.earned }}
            </td>
            <td>
              <span
                class="cursor-pointer rounded bg-ts-blue-600 bg-opacity-8 p-2 leading-5 text-ts-blue-600"
                @click="openReport(certificate.id, 'Active')"
              >
                {{ certificate.active }}
              </span>
            </td>
            <td @click="openReport(certificate.id, 'Expiring')">
              <span
                class="cursor-pointer rounded bg-ts-orange-600 bg-opacity-8 p-2 leading-5 text-ts-orange-600"
                @click="openReport(certificate.id, 'Expiring')"
              >
                {{ certificate.expiring }}
              </span>
            </td>
            <td>
              <span
                class="cursor-pointer rounded bg-qualify-red-500 bg-opacity-8 p-2 leading-5 text-qualify-red-500"
                @click="openReport(certificate.id, 'Expired')"
              >
                {{ certificate.expired }}
              </span>
            </td>
            <td class="flex items-center">
              <button
                v-if="certificate.is_training_record"
                class="font-semibold hover:underline"
                @click="openTrainingRecordEditModal(certificate)"
              >
                {{ $t('Edit Expiry') }}
              </button>
              <button
                v-else-if="!certificate.is_training_record && certificate.can.edit"
                class="font-semibold hover:underline"
                @click="openCertificateEditModal(certificate)"
              >
                {{ $t('Edit Expiry') }}
              </button>
              <div v-else />
              <div
                v-if="!certificate.years_valid"
                class="relative ml-2 flex justify-center"
                @mouseenter="current_hover_record = certificate.id"
                @mouseleave="current_hover_record = null"
              >
                <icon class="z-10 block h-5 w-5 fill-gray-300 stroke-white hover:fill-gray-400" name="info" />
                <div
                  v-if="current_hover_record === certificate.id"
                  class="absolute z-20 mt-8 w-48 cursor-pointer rounded bg-ts-front-table-header shadow-lg"
                >
                  <div class="relative flex justify-center">
                    <div class="absolute -mt-1 h-3 w-3 rotate-45 transform bg-ts-front-table-header" />
                  </div>
                  <div class="px-6 py-4">
                    <p class="text-center text-base text-ts-gray-text">
                      {{ $t('Expiry not set') }}
                    </p>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <front-pagination :links="certificates.links" />
    <create-modal :show="show_create_modal" @close="closeCreateModal" />
    <edit-training-record-modal
      v-if="current_record"
      :show="show_training_record_edit_modal"
      :training_record="current_record"
      @close-edit="closeTrainingRecordEditModal"
    />
    <edit-certificate-modal
      v-if="current_record"
      :certificate="current_record"
      :show="show_certificate_edit_modal"
      @close-edit="closeCertificateEditModal"
    />
  </front-layout>
</template>

<script>
import CreateModal from './CreateModal.vue'
import EditCertificateModal from './EditCertificateModal.vue'
import EditTrainingRecordModal from './EditTrainingRecordModal.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontOutlineButton from '@/Shared/FrontOutlineButton.vue'
import FrontPagination from '@/Shared/FrontPagination.vue'
import FrontSolidButton from '@/Shared/FrontSolidButton.vue'
import FrontTruncateText from '@/Shared/FrontTruncateText.vue'
import Icon from '@/Shared/Icon.vue'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    CreateModal,
    EditCertificateModal,
    EditTrainingRecordModal,
    Breadcrumb,
    FrontLayout,
    FrontOutlineButton,
    FrontPagination,
    FrontSolidButton,
    FrontTruncateText,
    Icon,
  },
  props: {
    tenant: Object,
    training_records_enabled: Boolean,
    certificates: Object,
  },
  data() {
    return {
      show_create_modal: false,
      show_training_record_edit_modal: false,
      show_certificate_edit_modal: false,
      current_record: null,
      current_hover_record: null,
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)

        this.$inertia.get(
          this.route('front.manager.certificates'),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
  },
  methods: {
    _pickBy,
    _throttle,
    _mapValues,
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
    openCreateModal() {
      this.show_create_modal = true
    },
    closeCreateModal() {
      this.show_create_modal = false
    },
    openTrainingRecordEditModal(certificate) {
      this.current_record = certificate
      this.show_training_record_edit_modal = true
    },
    closeTrainingRecordEditModal() {
      this.current_record = null
      this.show_training_record_edit_modal = false
    },
    openCertificateEditModal(certificate) {
      this.current_record = certificate
      this.show_certificate_edit_modal = true
    },
    closeCertificateEditModal() {
      this.current_record = null
      this.show_certificate_edit_modal = false
    },
    openReport(certificateTemplateId, status) {
      if (!status) {
        this.$inertia.visit(
          this.route('front.manager.reports.certificates.show', {
            table_filters: { certificateTemplate: certificateTemplateId },
          })
        )
      } else {
        this.$inertia.visit(
          this.route('front.manager.reports.certificates.show', {
            table_filters: { certificateTemplate: certificateTemplateId, status: status },
          })
        )
      }
    },
  },
}
</script>
