<template>
  <div class="w-full overflow-hidden rounded-lg border border-ts-gray-500 bg-white">
    <tab-menu :menu-list="menuList" />
    <div><slot /></div>
  </div>
</template>

<script>
import TabMenu from './TabMenu.vue'

export default {
  components: {
    TabMenu,
  },
  props: {
    menuList: Array,
  },
}
</script>
