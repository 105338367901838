<template>
  <front-layout :title="$t('Training Records')">
    <div class="mb-8">
      <breadcrumb
        class="font-heading text-2xl font-semibold"
        :name="$t('Edit')"
        :previous-name="$t('Training Records')"
        :previous-url="route('front.admin.training-record-templates')"
      />
      <archived-message v-if="template.archived_at" class="mb-6">
        <div class="flex w-full justify-between">
          <div>{{ $t('This record has been archived.') }}</div>
          <button
            v-if="template.archived_at"
            class="ml-4 text-yellow-800 hover:underline"
            tabindex="-1"
            type="button"
            @click="unArchive"
          >
            {{ $t('Restore') }}
          </button>
        </div>
      </archived-message>
    </div>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <front-text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <front-text-input
              v-model="form.years_valid"
              :error="form.errors.years_valid"
              :label="$t('Years Valid (optional)')"
              min="1"
              type="number"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <select-input
              v-model="form.source_type"
              :error="form.errors.source_type"
              :label="$t('Training Document Type')"
              :options="source_types"
            />
          </div>
          <div v-if="form.source_type == 'link'" class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.link" :error="form.errors.link" :label="$t('Training Link')" type="url" />
          </div>
          <div v-if="form.source_type == 'document'" class="w-full pb-8 pr-6 lg:w-1/2">
            <div class="w-full">
              <span>{{ $t('Training Document') }}</span>
              <div class="flex items-center justify-between pb-3 pt-6">
                <div class="flex">
                  <div v-if="form.training_document" class="flex items-center">
                    <div class="mr-2 w-40 truncate">{{ form.training_document.name }}</div>
                    <button @click="form.training_document = null">
                      <icon class="h-4 w-4 fill-gray-600 hover:fill-gray-800" name="close" />
                    </button>
                  </div>
                  <div v-else-if="template.training_document" class="flex items-center">
                    <a class="w-40 truncate underline" :href="template.training_document.url">
                      <span class="mr-2">{{ template.training_document.name }}</span>
                    </a>
                  </div>
                  <div v-else class="flex items-center">
                    <span>{{ $t('No file selected.') }}</span>
                  </div>
                  <div class="">
                    <label class="btn-gray-outline px-4 py-3" for="training_document">
                      {{
                        form.training_document || template.training_document
                          ? $t('Replace Document')
                          : $t('Upload Document')
                      }}
                    </label>
                    <input
                      id="training_document"
                      accept=".pdf,.jpg,.jpeg,.png,.gif,.doc,.docx,.xls"
                      style="display: none"
                      type="file"
                      @input="form.training_document = $event.target.files[0]"
                    />
                  </div>
                </div>
                <div v-if="form.errors.training_document" class="form-error text-ts-front-label-error">
                  {{ form.errors.training_document }}
                </div>
              </div>
            </div>
          </div>
          <div class="w-full">
            <front-textarea-input
              v-model="form.description"
              :autosize="true"
              class="w-full pb-2 pr-6"
              :error="form.errors.description"
              :label="$t('Description')"
              :rows="5"
            />
          </div>
        </div>
        <div class="mt-6 flex items-center justify-between border-t border-gray-100 px-8 py-8">
          <div class="flex justify-start">
            <button
              v-if="template.earned_count < 1"
              class="text-red-500 hover:underline"
              tabindex="-1"
              type="button"
              @click="destroy"
            >
              {{ $t('Delete') }}
            </button>
            <button
              v-if="template.archived_at"
              class="ml-4 text-red-500 hover:underline"
              tabindex="-1"
              type="button"
              @click="unArchive"
            >
              {{ $t('Restore Archived') }}
            </button>
            <button v-else class="ml-4 text-red-500 hover:underline" tabindex="-1" type="button" @click="archive">
              {{ $t('Archive') }}
            </button>
          </div>
          <front-loading-button :loading="form.processing" type="submit">{{ $t('Save') }}</front-loading-button>
        </div>
      </form>
    </div>
  </front-layout>
</template>

<script>
import ArchivedMessage from '@/Shared/ArchivedMessage.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import FrontTextareaInput from '@/Shared/FrontTextareaInput.vue'
import Icon from '@/Shared/Icon.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    ArchivedMessage,
    Breadcrumb,
    FrontLayout,
    FrontLoadingButton,
    FrontTextInput,
    FrontTextareaInput,
    Icon,
    SelectInput,
    TextInput,
  },
  props: {
    template: Object,
    source_types: Object,
  },
  data() {
    return {
      previous: {
        url: this.route('front.admin.training-record-templates'),
        name: this.$t('Training Records'),
      },
      form: useForm({
        name: this.template.name,
        description: this.template.description,
        years_valid: this.template.years_valid,
        source_type: this.template.source_type,
        link: this.template.link,
        training_document: null,
      }),
    }
  },
  methods: {
    submit() {
      this.form
        .transform((data) => ({
          ...data,
          _method: 'put',
          link: data.source_type === 'link' ? data.link : null,
          training_document: data.source_type === 'document' ? data.training_document : null,
        }))
        .post(this.route('front.admin.training-record-templates.update', this.template.id))
    },
    archive() {
      if (confirm(this.$t('Are you sure you want to archive this record?'))) {
        this.$inertia.post(this.route('front.admin.training-record-templates.archive', this.template.id))
      }
    },
    unArchive() {
      if (confirm(this.$t('Are you sure you want to restore this archived record?'))) {
        this.$inertia.post(this.route('front.admin.training-record-templates.unarchive', this.template.id))
      }
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this template?'))) {
        this.$inertia.delete(this.route('front.admin.training-record-templates.destroy', this.template.id))
      }
    },
  },
}
</script>
