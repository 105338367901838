<template>
  <front-layout :title="$t('Locations')">
    <div class="flex flex-col xl:flex-row xl:justify-between">
      <div class="sm:no-flex mb-8 flex w-full items-center justify-between">
        <breadcrumb :name="$t('Locations')" />
        <front-button-link v-if="can.createLocation" class="sm:hidden" :href="route('front.manager.users.create')">
          <span>{{ $t('+ Add') }}</span>
        </front-button-link>
      </div>
      <div class="mb-6 flex w-full items-center justify-end">
        <front-search-filter
          v-model="form.search"
          class="flex w-full flex-col sm:flex-row xl:max-w-sm"
          :class="{ 'sm:mr-4': can.createLocation }"
          :filter-show="false"
          @reset="reset"
        />
        <front-solid-button v-if="can.createLocation" class="hidden sm:inline" @click="showCreateLocation()">
          <span>{{ $t('+ Add') }}</span>
        </front-solid-button>
      </div>
    </div>

    <div class="overflow-x-auto rounded bg-white shadow-lg">
      <table class="front-table">
        <thead>
          <tr>
            <th class="font-semibold">{{ $t('Name') }}</th>
            <th>{{ $t('Members') }}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-if="locations.data.length === 0">
            <td colspan="4">{{ $t('No locations found.') }}</td>
          </tr>
          <tr
            v-for="location in locations.data"
            :key="location.id"
            class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
            @click="$inertia.visit(route('front.manager.locations.show', location.id))"
          >
            <td class="flex items-center font-semibold text-ts-gray-text-bold">
              <span class="">{{ location.name }}</span>
              <icon v-if="location.archived_at" class="ml-2 h-3 w-3 flex-shrink-0 fill-gray-400" name="archive" />
            </td>
            <td>{{ location.users_count }}</td>
            <td class="w-min">
              <a class="px-6 font-semibold hover:underline" :href="route('front.manager.locations.show', location.id)">
                {{ $t('View') }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <front-pagination v-if="locations && locations.links" :links="locations.links" />
    <create-modal :regions="regions" :show="showCreateModal" @close="hideCreateLocation" />
  </front-layout>
</template>

<script>
import CreateModal from './CreateModal.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontButtonLink from '@/Shared/FrontButtonLink.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontPagination from '@/Shared/FrontPagination.vue'
import FrontSearchFilter from '@/Shared/FrontSearchFilter.vue'
import FrontSolidButton from '@/Shared/FrontSolidButton.vue'
import Icon from '@/Shared/Icon.vue'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    CreateModal,
    Breadcrumb,
    FrontButtonLink,
    FrontLayout,
    FrontPagination,
    FrontSearchFilter,
    FrontSolidButton,
    Icon,
  },
  props: {
    filters: Object,
    organization: Object,
    locations: Object,
    regions: Array,
    can: Object,
  },
  data() {
    return {
      showCreateModal: false,
      form: {
        search: this.filters.search,
      },
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)
        query.organization = this.organization.id
        query = Object.keys(query).length ? query : { remember: 'forget' }

        this.$inertia.get(
          this.route('front.manager.locations'),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
  },
  methods: {
    showCreateLocation() {
      this.showCreateModal = true
    },
    hideCreateLocation() {
      this.showCreateModal = false
    },
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
    formatManagerList(managers) {
      if (!managers) return '-'
      if (managers.length === 0) return '-'
      if (managers.length === 1) return managers[0]
      return `${managers[0]} (${managers.length - 1} ${managers.length - 1 > 1 ? 'others' : 'other'})`
    },
  },
}
</script>
