<template>
  <layout :title="$t('Update Scores')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route(classRoute, class_.id)">
        {{ $t('Class') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Update Scores') }}
    </h1>
    <notice v-if="!class_.total_score" class="max-w-lg" type="danger">
      {{ 'The total score for this class needs to be set by an administrator. The form is temporarily disabled.' }}
    </notice>
    <div class="max-w-2xl overflow-x-auto rounded bg-white shadow">
      <form enctype="multipart/form-data" @submit.prevent="submit">
        <table class="w-full">
          <thead>
            <tr>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Name') }}</th>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">
                {{ $t('Score (Max: :total_score)', { total_score: class_.total_score }) }}
              </th>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Upload Record') }}</th>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Last Update') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="form.students.length === 0">
              <td class="border-t px-6 py-6 text-center" colspan="4">{{ $t('No students found.') }}</td>
            </tr>
            <tr v-for="(student, key) in form.students" :key="student.id">
              <td class="border-t px-6 py-4">
                <div class="flex items-center">
                  <span class="truncate">{{ students[key].user?.display_name }}</span>
                </div>
              </td>
              <td class="w-min whitespace-nowrap border-t px-6 py-2">
                <div class="flex items-center">
                  <text-input
                    v-model.number="form.students[key].score"
                    class="w-20 text-right"
                    :error="form.errors[`students.${key}.score`]"
                    :max="class_.total_score"
                    min="0"
                    type="number"
                  />
                  <div class="ml-2">
                    / {{ students[key].latest_score ? students[key].latest_score.total_score : class_.total_score }}
                  </div>
                </div>
              </td>
              <td class="border-t px-6 py-4">
                <div class="flex items-center justify-start">
                  <label class="btn-blue mr-3" :for="`record-upload-${key}`">{{ $t('Upload') }}</label>
                  <input
                    :id="`record-upload-${key}`"
                    accept="application/pdf"
                    style="display: none"
                    type="file"
                    @input="form.students[key].file = $event.target.files[0]"
                  />
                  <div v-if="form.students[key].file" class="flex items-center">
                    <span class="mr-2 text-gray-600">
                      {{ form.students[key].file.name }}
                    </span>
                    <button @click="form.students[key].file = null">
                      <icon class="h-4 w-4 fill-gray-600 hover:fill-gray-800" name="close" />
                    </button>
                  </div>
                  <a
                    v-if="!form.students[key].file && students[key].has_record"
                    class="flex items-center"
                    :href="route('offline-test-scores.download', students[key].score_id)"
                    target="_blank"
                  >
                    <icon class="mr-2 h-4 w-4 fill-gray-600" name="drag-and-drop" />
                    <span class="text-gray-600">{{ $t('Download') }}</span>
                  </a>
                </div>
              </td>
              <td class="border-t px-6 py-4">
                <div class="flex items-center">
                  <span class="truncate">{{ students[key].updated_at }}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button
            class="btn-blue"
            :disabled="!class_.total_score || students.length === 0"
            :loading="form.processing"
            type="submit"
          >
            {{ $t('Save Scores') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import Notice from '@/Shared/Notice.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Icon,
    Layout,
    LoadingButton,
    Notice,
    TextInput,
  },
  props: {
    class_: Object,
    students: Array,
    files: Array,
    userIsInstructor: Boolean,
  },
  data() {
    return {
      form: useForm(
        {
          students: this.students.map((student) => {
            return {
              id: student.id,
              score: student.score,
              file: null,
            }
          }),
        },
        'OfflineTestScoreEdit'
      ),
    }
  },
  computed: {
    classRoute: function () {
      return this.userIsInstructor ? 'instructor.classes.show' : 'classes.show'
    },
  },
  methods: {
    submit() {
      if (!this.class_.total_score) return

      this.form
        .transform((data) => ({
          ...data,
          _method: 'put',
        }))
        .post(this.route('offline-test-scores.update', this.class_.id))
    },
  },
}
</script>
