<template>
  <InertiaLink :class="isTenantWorksite ? 'btn-red-outline' : 'tenant-btn-1-outline'" :href="href">
    <slot />
  </InertiaLink>
</template>

<script>
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  props: {
    href: String,
  },
  computed: {
    isTenantWorksite,
  },
}
</script>
