<template>
  <styled-modal max-width="xl" :show="show" @close="close">
    <template #title>{{ $t('Confirm Name') }}</template>
    <template #content>
      <div class="max-h-[60vh] md:max-h-[75vh] overflow-y-auto px-4 sm:px-0">
        <div class="pb-36 sm:pb-0">
          <p class="mt-4 mb-8 text-small italic">
            {{ $t('Your name should match the photo ID that you upload in the next step.') }}
          </p>
          <div class="flex flex-wrap">
            <div class="w-full pb-4 sm:pb-8 pr-0 sm:pr-6 lg:w-1/2">
              <TextInput disabled :label="$t('First name')" :model-value="user.first_name" type="text" />
            </div>
            <div class="w-full pb-4 sm:pb-8 pr-0 sm:pr-6 lg:w-1/2">
              <TextInput disabled :label="$t('Last name')" :model-value="user.last_name" type="text" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        class="fixed sm:relative bottom-0 left-0 right-0 bg-white sm:bg-transparent border-t sm:border-t-0 border-gray-200 pb-[env(safe-area-inset-bottom)] sm:pb-0"
      >
        <div class="flex flex-col sm:flex-row justify-end items-center space-y-3 sm:space-y-0 sm:space-x-4 p-4 sm:p-0">
          <button class="px-6 py-3 sm:py-2 text-gray-500 hover:text-gray-700" @click.prevent="close">
            {{ $t('Nevermind') }}
          </button>
          <FrontLoadingButton class="px-6 py-3 sm:py-2" @click.prevent="confirm">
            <span>{{ $t('Confirm') }}</span>
          </FrontLoadingButton>
        </div>
      </div>
    </template>
  </styled-modal>
</template>
<script>
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import StyledModal from '@/Shared/StyledModal.vue'
import TextInput from '@/Shared/TextInput.vue'

export default {
  components: { FrontLoadingButton, StyledModal, TextInput },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  emits: ['confirm', 'close'],
  data() {
    return {}
  },
  methods: {
    close() {
      this.$emit('close')
    },
    confirm() {
      this.$emit('confirm')
    },
  },
}
</script>
