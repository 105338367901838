<template>
  <div class="h-12 w-12 cursor-pointer overflow-hidden rounded">
    <img
      v-if="media.type === 'image'"
      alt="Media"
      class="block h-full w-full object-cover"
      :src="thumbnailUrl"
      :title="media.file_name"
    />
    <div
      v-else
      class="block flex h-full w-full items-center justify-center rounded border bg-gray-200 object-cover text-xs uppercase"
      :title="media.file_name"
    >
      {{ media.type }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    media: Object,
  },
  data() {
    return {
      showImageModal: false,
    }
  },
  computed: {
    thumbnailUrl() {
      if (this.media.type === 'image') {
        return this.media.thumbnail_url ? this.media.thumbnail_url : this.media.url
      }
      return null
    },
  },
}
</script>
