<template>
  <layout :title="$t('Proctoring Recordings')">
    <div class="mb-8">
      <breadcrumb :name="$t('Proctoring Recordings')" />
    </div>
    <div class="mb-6 flex items-center justify-between">
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
        <label class="block text-gray-800">{{ $t('Course:') }}</label>
        <select v-model="form.course" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="course in courses" :key="course.id" :value="course.id">{{ course.name }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Proctoring Status:') }}</label>
        <select v-model="form.proctoring_status" class="form-select mt-1 w-full">
          <option :value="null"></option>
          <option v-for="(key, value) in proctoring_statuses" :key="key" :value="value">{{ key }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Between:') }}</label>
        <date-input v-model="form.start_date" class="mt-1 w-full" />
        <date-input v-model="form.end_date" class="mt-1 w-full" />
      </search-filter>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow">
      <table class="w-full">
        <thead>
          <tr>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Course') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('User') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Date') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">
              <div class="flex items-center">
                <span>{{ $t('Enrolment Status') }}</span>
                <Popper :hover="true">
                  <icon
                    class="ml-2 h-4 w-4 flex-shrink-0 rounded-full border-2 fill-current border-ts-gray-text-bold"
                    name="info-2"
                  />
                  <template #content>
                    <div class="rounded border bg-white py-2 text-sm shadow-lg font-normal px-2">
                      {{ $t('The overall current status of the enrolment (all contents).') }}
                    </div>
                  </template>
                </Popper>
              </div>
            </th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold" colspan="2">
              <div class="flex items-center">
                <span>{{ $t('Proctoring Status') }}</span>
                <Popper :hover="true">
                  <icon
                    class="ml-2 h-4 w-4 flex-shrink-0 rounded-full border-2 fill-current border-ts-gray-text-bold"
                    name="info-2"
                  />
                  <template #content>
                    <div class="rounded border bg-white py-2 text-sm shadow-lg font-normal px-2">
                      {{ $t('The current status of the proctoring analysis for the attempt.') }}
                    </div>
                  </template>
                </Popper>
              </div>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-if="proctored_attempts.data.length === 0">
            <td class="border-t px-6 py-4" colspan="6">{{ $t('No recordings found.') }}</td>
          </tr>
          <tr
            v-for="attempt in proctored_attempts.data"
            :key="attempt.id"
            class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
            @click="$inertia.visit(route('proctoring-recordings.show', attempt.id))"
          >
            <td class="whitespace-nowrap border-t px-6 py-4">
              <span>{{ attempt.course }}</span>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <span>{{ attempt.user }}</span>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <span>{{ attempt.created_at }}</span>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <EnrolmentStatus :status="attempt.enrolment_status" />
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <span>{{ attempt.proctoring_status }}</span>
            </td>
            <td class="w-min border-t px-4 align-middle">
              <Icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination :links="proctored_attempts.links" />
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import DateInput from '@/Shared/DateInput.vue'
import EnrolmentStatus from '@/Shared/Enrolment/EnrolmentStatus.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import _debounce from 'lodash/debounce'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import Popper from 'vue3-popper'

export default {
  components: {
    Icon,
    Popper,
    Breadcrumb,
    DateInput,
    EnrolmentStatus,
    Layout,
    Pagination,
    SearchFilter,
  },
  props: {
    proctored_attempts: Object,
    courses: Array,
    filters: Object,
    proctoring_statuses: Array,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        course: this.filters.course,
        start_date: this.filters.start_date,
        end_date: this.filters.end_date,
        proctoring_status: this.filters.proctoring_status,
      },
    }
  },
  watch: {
    form: {
      handler: _debounce(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(
          this.route('proctoring-recordings'),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveState: true,
            replace: true,
            only: ['proctored_attempts'],
          }
        )
      }, 300),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
