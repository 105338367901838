<template>
  <div class="w-full pb-8 pr-6">
    <div
      class="relative rounded border px-4 py-3"
      :class="{
        'border-gray-400 bg-gray-100 text-gray-700': type === 'info',
        'border-red-400 bg-red-100 text-red-700': type === 'danger',
        'border-green-400 bg-green-100 text-green-700': type === 'success',
      }"
      role="alert"
    >
      <strong v-if="title" class="font-bold">
        <slot name="title" />
      </strong>
      <span class="block sm:inline"><slot /></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Notice',
  props: {
    title: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: 'info',
    },
  },
}
</script>
