<template>
  <tabs
    ref="tabs"
    class="w-full overflow-hidden"
    nav-class="flex items-center text-gray-500 text-sm font-bold bg-gray-200"
    nav-item-active-class="bg-gray-100"
    nav-item-class="py-2 px-5"
    :options="{ useUrlFragment: false }"
  >
    <slot />
  </tabs>
</template>
<script>
import { Tabs } from 'vue3-tabs-component'

export default {
  components: {
    Tabs,
  },
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  mounted() {
    if (this.modelValue) {
      this.selectTab(this.modelValue.length - 1)
    }
  },
  methods: {
    add(logicData) {
      const newValue = [...this.modelValue, { ...logicData }]
      this.update(newValue)
      this.selectTab(newValue.length - 1)
    },
    remove(index) {
      const newValue = this.modelValue.filter((_, i) => i !== index)
      this.update(newValue)
      this.selectTab(index !== 0 ? index - 1 : 0)
    },
    update(newValue) {
      this.$emit('update:modelValue', newValue)
    },
    selectTab(index) {
      this.$nextTick(() => {
        this.$refs.tabs.selectTab(`#tab-${index + 1}`)
      })
    },
  },
}
</script>
