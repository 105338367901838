<template>
  <front-modal class="rounded text-base" :show="show" style="z-index: 100000" width="auto" @close="close">
    <div class="max-h-screen max-w-lg overflow-auto rounded bg-white p-6 shadow md:p-10">
      <div class="mb-6 flex items-center justify-evenly border-b border-ts-gray-quiz-progress-bar pb-10 pt-2">
        <div class="flex flex-col items-center justify-center px-2">
          <div class="mb-3 h-5 w-5 rounded-full border-4 border-darkGray-900 bg-white" />
          <span class="cursor-default text-center text-sm text-darkGray-900">{{ $t('Select Certificates') }}</span>
        </div>
        <div class="flex flex-col items-center justify-center px-2">
          <div
            class="mb-3 h-5 w-5 rounded-full border-4 bg-white"
            :class="form.step >= 2 ? 'border-darkGray-900' : 'border-ts-gray-300'"
          />
          <span
            class="cursor-default text-center text-sm"
            :class="form.step >= 2 ? 'text-darkGray-900' : 'text-darkGray-700'"
          >
            {{ $t('Contact Information') }}
          </span>
        </div>
        <div class="flex flex-col items-center justify-center px-2">
          <div
            class="mb-3 h-5 w-5 rounded-full border-4 bg-white"
            :class="form.step >= 3 ? 'border-darkGray-900' : 'border-ts-gray-300'"
          />
          <span
            class="cursor-default text-center text-sm"
            :class="form.step >= 3 ? 'text-darkGray-900' : 'text-darkGray-700'"
          >
            {{ $t('Employer Information') }}
          </span>
        </div>
        <div class="flex flex-col items-center justify-center px-2">
          <div
            class="mb-3 h-5 w-5 rounded-full border-4 bg-white"
            :class="form.step >= 4 ? 'border-darkGray-900' : 'border-ts-gray-300'"
          />
          <span
            class="cursor-default text-center text-sm"
            :class="form.step >= 4 ? 'text-darkGray-900' : 'text-darkGray-700'"
          >
            {{ tenant.plastic_card_price > 0 ? $t('Payment Method') : $t('Shipping Information') }}
          </span>
        </div>
      </div>

      <h3 class="mb-4 mt-8 text-center text-3xl font-semibold text-black">{{ $t('Order Plastic Card') }}</h3>

      <!-- CERTIFICATES -->
      <div v-show="form.step === 1">
        <h4 class="text-dark-gray-1000 mb-8 text-center text-lg">{{ $t('Select Certificates') }}</h4>
        <div class="overflow-x-scroll">
          <table class="front-table">
            <thead class="hidden cursor-default font-semibold md:table-header-group">
              <tr>
                <th>
                  <front-row-select-checkbox
                    id="selectAll"
                    v-model="selectAll"
                    @update:model-value="selectAllUpdated"
                  />
                </th>
                <th>{{ $t('Course Name') }}</th>
                <th>{{ $t('Language') }}</th>
                <th>{{ $t('Issue Date') }}</th>
                <th>{{ $t('Expiry Date') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="certificate in getCertificates()" :key="certificate.id">
                <td class="flex items-center md:table-cell">
                  <front-row-select-checkbox
                    v-if="!certificate.is_training_record"
                    :id="'checkbox-' + certificate.id"
                    v-model="form.selected_certificates"
                    :value="{ id: certificate.id, locale: certificate.locale }"
                  />
                </td>
                <td class="text-ts-gray-text-bold">{{ certificate.course.name }}</td>
                <td>
                  <front-select-input v-model="certificate.locale">
                    <option v-for="(locale, key) in locales" :key="key" :value="locale">
                      {{ key }}
                    </option>
                  </front-select-input>
                </td>
                <td><front-date :timestamp="certificate.issued_at" /></td>
                <td><front-date :timestamp="certificate.expires_at" /></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="form.errors.selected_certificates" class="form-error pt-2 text-base">
          {{ form.errors.selected_certificates }}
        </div>
      </div>

      <!-- PERSONAL INFO -->
      <div v-show="form.step === 2">
        <h4 class="text-dark-gray-1000 mb-8 text-center text-lg">{{ $t('Confirm Information') }}</h4>
        <div class="grid grid-cols-2">
          <div class="p-3">
            <label class="mb-2 block text-lg text-darkGray-700" for="first_name">{{ $t('First Name') }}</label>
            <input
              id="first_name"
              v-model="form.first_name"
              class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
              required
              type="text"
            />
            <div v-if="form.errors.first_name" class="form-error">{{ form.errors.first_name }}</div>
          </div>
          <div class="p-3">
            <label class="mb-2 block text-lg text-darkGray-700" for="last_name">{{ $t('Last Name') }}</label>
            <input
              id="last_name"
              v-model="form.last_name"
              class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
              required
              type="text"
            />
            <div v-if="form.errors.last_name" class="form-error">{{ form.errors.last_name }}</div>
          </div>
          <div class="col-span-2 p-3">
            <canada-post-address-complete-input
              v-if="$page.props.app.tenant.has_address_complete_api_enabled && canFindBillingAddress"
              ref="billing_address_complete"
              v-model="billing_address_complete"
              class="w-full"
              :label="$t('Address')"
              label-class="text-darkGray-700 text-lg"
              required
              :search-by="['Text', 'Description']"
              search-input-class="bg-gray-100 rounded-lg"
              track-by="Id"
              @update:enable-a-p-i="canFindBillingAddress = false"
            >
              <div v-if="form.address" class="flex items-center justify-between">
                <div class="truncate">{{ form.address }}</div>
              </div>
              <template #option="{ option, selected }">
                <div class="flex items-center justify-between">
                  <div class="text-md" :class="{ 'text-gray-600': !selected, 'text-white': selected }">
                    {{ option.Text.concat(' ', option.Description) }}
                  </div>
                  <icon
                    v-if="option.Next == 'Find'"
                    class="ml-2 h-3 w-3 flex-shrink-0 fill-gray-400"
                    name="chevron-right"
                  />
                </div>
              </template>
            </canada-post-address-complete-input>
            <template v-else>
              <label class="mb-2 block text-lg text-darkGray-700" for="shipping_address">{{ $t('Address') }}</label>
              <input
                id="address"
                v-model="form.address"
                class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                :placeholder="$t('Type your address...')"
                required
                type="text"
              />
            </template>
            <div v-if="form.errors.address" class="form-error">{{ form.errors.address }}</div>
          </div>

          <div class="p-3">
            <label class="mb-2 block text-lg text-darkGray-700" for="city">{{ $t('City') }}</label>
            <input
              id="city"
              v-model="form.city"
              class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
              required
              type="text"
            />
            <div v-if="form.errors.city" class="form-error">{{ form.errors.city }}</div>
          </div>
          <div class="p-3">
            <label class="mb-2 block text-lg text-darkGray-700" for="province">{{ $t('Province') }}</label>
            <select
              id="province"
              v-model="form.province"
              class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
            >
              <slot>
                <option v-for="(province, key) in getRegions" :key="key" :value="province.name">
                  {{ province.name }}
                </option>
              </slot>
            </select>
            <div v-if="form.errors.province" class="form-error">{{ form.errors.province }}</div>
          </div>
          <div class="p-3">
            <label class="mb-2 block text-lg text-darkGray-700" for="postal_code">{{ $t('Postal Code') }}</label>
            <input
              id="postal_code"
              v-model="form.postal_code"
              class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
              required
              type="text"
            />
            <div v-if="form.errors.postal_code" class="form-error">{{ form.errors.postal_code }}</div>
          </div>
          <div class="p-3">
            <label class="mb-2 block text-lg text-darkGray-700" for="country">{{ $t('Country') }}</label>
            <select
              id="country"
              v-model="form.country"
              class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
              @change="handleBillingCountryChange($event)"
            >
              <slot>
                <option v-for="(country, key) in getCountries" :key="key" :value="country.code">
                  {{ country.name }}
                </option>
              </slot>
            </select>
            <div v-if="form.errors.country" class="form-error">{{ form.errors.country }}</div>
          </div>
        </div>
      </div>

      <!-- EMPLOYER INFO -->
      <div v-show="form.step === 3">
        <h4 class="text-dark-gray-1000 mb-8 text-center text-lg">{{ $t('Employer Information') }}</h4>
        <div class="grid grid-cols-2">
          <div class="col-span-2 p-3 md:col-span-1">
            <label class="mb-2 block text-lg text-darkGray-700" for="first_name">{{ $t('Employer Name') }}</label>
            <input
              id="employer_name"
              v-model="form.employer_name"
              class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
              required
              type="text"
            />
            <div v-if="form.errors.employer_name" class="form-error">{{ form.errors.employer_name }}</div>
          </div>
          <div class="col-span-2 p-3 md:col-span-1">
            <label class="mb-2 block text-lg text-darkGray-700" for="employer_address">
              {{ $t('Employer Address') }}
            </label>
            <input
              id="employer_address"
              v-model="form.employer_address"
              class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
              required
              type="text"
            />
            <div v-if="form.errors.employer_address" class="form-error">{{ form.errors.employer_address }}</div>
          </div>
        </div>
      </div>

      <!-- PAYMENT METHOD -->
      <div v-show="form.step === 4">
        <h4 v-if="tenant.plastic_card_price > 0" class="text-dark-gray-1000 mb-8 text-center text-lg">
          {{ $t('Payment Method') }}
        </h4>
        <div v-if="tenant.plastic_card_price > 0" class="p-3">
          <div id="card-element" />
          <div v-if="stripe_error" class="form-error">{{ stripe_error }}</div>
        </div>
        <div class="grid grid-cols-2">
          <div class="col-span-2 p-3">
            <input
              id="ship_to_different_address"
              v-model="form.different_shipping_address"
              class="rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
              type="checkbox"
            />
            <label class="mb-2 ml-2 text-base text-darkGray-700" for="ship_to_different_address">
              {{ $t('Ship to Different Address') }}
            </label>
          </div>
          <div v-if="form.different_shipping_address" class="col-span-2 p-3">
            <canada-post-address-complete-input
              v-if="$page.props.app.tenant.has_address_complete_api_enabled && canFindShippingAddress"
              ref="shipping_address_complete"
              v-model="shipping_address_complete"
              class="w-full"
              :label="$t('Address')"
              label-class="text-darkGray-700 text-lg"
              required
              :search-by="['Text']"
              search-input-class="bg-gray-100 rounded-lg"
              track-by="Id"
              @update:enable-a-p-i="canFindShippingAddress = false"
            >
              <div v-if="form.shipping_address" class="flex items-center justify-between">
                <div class="truncate">{{ form.shipping_address }}</div>
              </div>
              <template #option="{ option, selected }">
                <div class="flex items-center justify-between">
                  <div class="text-md" :class="{ 'text-gray-600': !selected, 'text-white': selected }">
                    {{ option.Text.concat(' ', option.Description) }}
                  </div>
                  <icon
                    v-if="option.Next == 'Find'"
                    :class="{
                      'ml-2 h-3 w-3 flex-shrink-0 fill-gray-400': !selected,
                      'ml-2 h-3 w-3 flex-shrink-0 fill-white': selected,
                    }"
                    name="chevron-right"
                  />
                </div>
              </template>
            </canada-post-address-complete-input>
            <template v-else>
              <label class="mb-2 block text-lg text-darkGray-700" for="shipping_address">{{ $t('Address') }}</label>
              <input
                id="shipping_address"
                v-model="form.shipping_address"
                class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                :placeholder="$t('Type your address...')"
                required
                type="text"
              />
            </template>
            <div v-if="form.errors.shipping_address" class="form-error">{{ form.errors.shipping_address }}</div>
          </div>
          <div v-if="form.different_shipping_address" class="p-3">
            <label class="mb-2 block text-lg text-darkGray-700" for="shipping_city">{{ $t('City') }}</label>
            <input
              id="shipping_city"
              v-model="form.shipping_city"
              class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
              required
              type="text"
            />
            <div v-if="form.errors.shipping_city" class="form-error">{{ form.errors.shipping_city }}</div>
          </div>
          <div v-if="form.different_shipping_address" class="p-3">
            <label class="mb-2 block text-lg text-darkGray-700" for="shipping_province">{{ $t('Province') }}</label>
            <select
              id="shipping_province"
              v-model="form.shipping_province"
              class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
            >
              <slot>
                <option v-for="(province, key) in getShippingRegions" :key="key" :value="province.name">
                  {{ province.name }}
                </option>
              </slot>
            </select>
            <div v-if="form.errors.shipping_province" class="form-error">{{ form.errors.shipping_province }}</div>
          </div>
          <div v-if="form.different_shipping_address" class="p-3">
            <label class="mb-2 block text-lg text-darkGray-700" for="shipping_postal_code">
              {{ $t('Postal Code') }}
            </label>
            <input
              id="shipping_postal_code"
              v-model="form.shipping_postal_code"
              class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
              required
              type="text"
            />
            <div v-if="form.errors.shipping_postal_code" class="form-error">{{ form.errors.shipping_postal_code }}</div>
          </div>
          <div v-if="form.different_shipping_address" class="p-3">
            <label class="mb-2 block text-lg text-darkGray-700" for="shipping_country">{{ $t('Country') }}</label>
            <select
              id="shipping_country"
              v-model="form.shipping_country"
              class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
              @change="handleShippingCountryChange($event)"
            >
              <slot>
                <option v-for="(country, key) in getCountries" :key="key" :value="country.code">
                  {{ country.name }}
                </option>
              </slot>
            </select>
            <div v-if="form.errors.shipping_country" class="form-error">{{ form.errors.shipping_country }}</div>
          </div>
        </div>
      </div>

      <div class="mb-2 mt-12 flex items-center justify-center bg-white px-8">
        <front-secondary-button
          class="mx-2 transform px-12 py-4 transition duration-300 ease-in-out hover:scale-105"
          :disabled="form.processing"
          type="button"
          @click="form.step === 1 ? close() : back()"
        >
          {{ cancel_button_label }}
        </front-secondary-button>
        <front-loading-button
          class="mx-2 transform px-12 py-4 transition duration-300 ease-in-out hover:scale-105"
          :loading="form.processing"
          @click="next"
        >
          {{ next_button_label }}
        </front-loading-button>
      </div>
    </div>
  </front-modal>
</template>

<script>
import CanadaPostAddressCompleteInput from '@/Shared/CanadaPostAddressCompleteInput.vue'
import FrontDate from '@/Shared/FrontDate.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontModal from '@/Shared/FrontModal.vue'
import FrontRowSelectCheckbox from '@/Shared/FrontRowSelectCheckbox.vue'
import FrontSecondaryButton from '@/Shared/FrontSecondaryButton.vue'
import FrontSelectInput from '@/Shared/FrontSelectInput.vue'
import Icon from '@/Shared/Icon.vue'
import Http from '@/Utils/Http'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    CanadaPostAddressCompleteInput,
    FrontDate,
    FrontLoadingButton,
    FrontModal,
    FrontRowSelectCheckbox,
    FrontSecondaryButton,
    FrontSelectInput,
    Icon,
  },
  props: {
    show: Boolean,
    tenant: Object,
    regions: Array,
    locales: Object,
    certificates: Object,
  },
  data() {
    return {
      intent: null,
      stripe_error: null,
      form: useForm({
        step: 1,
        selected_certificates: [],
        first_name: null,
        last_name: null,
        address: null,
        city: null,
        province: null,
        postal_code: null,
        country: 'CA',
        employer_name: null,
        employer_address: null,
        card_name: null,
        card_number: null,
        card_expiry: null,
        card_cvv: null,
        different_shipping_address: this.tenant.plastic_card_price === 0,
        shipping_address: null,
        shipping_city: null,
        shipping_province: null,
        shipping_postal_code: null,
        shipping_country: 'CA',
      }),
      billing_address_complete: null,
      shipping_address_complete: null,
      selectAll: false,
      canFindBillingAddress: true,
      canFindShippingAddress: true,
    }
  },
  computed: {
    next_button_label() {
      if (this.form.step === 4 && this.tenant.plastic_card_price > 0 && this.form.selected_certificates.length > 0) {
        const price = Math.round((this.tenant.plastic_card_price / 100) * this.form.selected_certificates.length)
        return this.$t(`Confirm Order (${price} + tax)`)
      }
      return this.form.step < 4 ? this.$t('Next') : this.$t('Confirm Order')
    },
    cancel_button_label() {
      return this.form.step === 1 ? this.$t('Cancel') : this.$t('Back')
    },
    getRegions() {
      return this.regions.filter((region) => region.country === this.form.country)
    },
    getShippingRegions() {
      return this.regions.filter((region) => region.country === this.form.shipping_country)
    },
    getCountries() {
      return [
        { name: 'Canada', code: 'CA' },
        { name: 'United States', code: 'US' },
      ]
    },
  },
  watch: {
    show: function (newVal) {
      if (newVal && !this.intent) {
        this.setIntent()
      }
    },
    'form.different_shipping_address': function () {
      this.form.reset(
        'shipping_address',
        'shipping_city',
        'shipping_province',
        'shipping_postal_code',
        'shipping_country'
      )
    },
    'form.country': function () {
      this.form.reset('province')
    },
    'form.shipping_country': function () {
      this.form.reset('shipping_province')
    },
    billing_address_complete: function (newVal, oldVal) {
      this.form.address = newVal?.Line1
      this.form.city = newVal?.City
      this.form.postal_code = newVal?.PostalCode
      this.form.province = newVal?.ProvinceName
      this.form.country = newVal ? newVal.CountryIso2 : oldVal.CountryIso2
    },
    shipping_address_complete: function (newVal, oldVal) {
      this.form.shipping_address = newVal?.Line1
      this.form.shipping_city = newVal?.City
      this.form.shipping_postal_code = newVal?.PostalCode
      this.form.shipping_province = newVal?.ProvinceName
      this.form.shipping_country = newVal ? newVal.CountryIso2 : oldVal.CountryIso2
    },
    'form.selected_certificates': {
      handler() {
        if (
          this.getCertificates().every((certificate) =>
            this.form.selected_certificates.some((cert) => cert.id === certificate.id)
          )
        ) {
          return (this.selectAll = true)
        }
        this.selectAll = false
      },
      deep: true,
    },
  },
  async mounted() {
    if (!this.tenant.plastic_card_price || this.tenant.plastic_card_price == 0) return

    this.stripe = Stripe(import.meta.env.VITE_STRIPE_KEY)
    const elements = this.stripe.elements()
    this.cardElement = elements.create('card', {
      classes: {
        base: 'block w-full px-3 py-3 border border-gray-200 bg-gray-100 text-ts-front-label rounded-lg transition duration-150 ease-in-out',
      },
      style: {
        base: {
          fontWeight: '300',
          fontSize: '17px',
          color: '#2d3748',
        },
      },
    })

    this.cardElement.mount('#card-element')
  },
  methods: {
    close() {
      this.$emit('close')
      this.form.step = 1
      this.form.reset()
      this.form.clearErrors()
      this.billing_address_complete = null
      this.shipping_address_complete = null
    },
    back() {
      this.form.step--
    },
    next() {
      if (this.form.step === 4) return this.submit()
      this.form
        .transform((data) => ({
          ...data,
        }))
        .post(this.route('front.order.plastic.store'), {
          onSuccess: (response) => {
            this.form.step = response.props.flash.form_step
          },
        })
    },
    getCertificates() {
      return this.certificates.data.filter(
        (certificate) => !certificate.is_training_record && !!certificate.can_be_ordered
      )
    },
    setIntent() {
      Http.get(this.route('front.stripe.intent', this.$page.props.auth.user.id)).then(
        (response) => (this.intent = response.data)
      )
    },
    submitLastStep(setupIntent = null) {
      this.form
        .transform((data) => ({
          ...data,
          country: this.getCountries.find((country) => country.code == data.country).name,
          shipping_country: this.getCountries.find((country) => country.code == data.shipping_country).name,
          payment_method: setupIntent?.payment_method,
        }))
        .post(this.route('front.order.plastic.store'), {
          onSuccess: (response) => {
            this.form.step = response.props.flash.form_step
          },
        })
    },
    async submit() {
      if (this.tenant.plastic_card_price === 0) {
        this.submitLastStep()

        return
      }

      if (!this.intent) {
        this.stripe_error = this.$t('Something went wrong, order cannot be processed.')
      }

      const { setupIntent, error } = await this.stripe.confirmCardSetup(this.intent.client_secret, {
        payment_method: {
          card: this.cardElement,
          billing_details: { name: this.form.card_name },
        },
      })

      if (error) {
        this.stripe_error = error.message
      } else {
        this.submitLastStep(setupIntent)
      }
    },
    handleBillingCountryChange(event) {
      if (this.$refs.billing_address_complete && this.$refs.billing_address_complete._data)
        this.$refs.billing_address_complete._data.country = event.target.value
    },
    handleShippingCountryChange(event) {
      if (this.$refs.shipping_address_complete && this.$refs.shipping_address_complete._data)
        this.$refs.shipping_address_complete._data.country = event.target.value
    },
    selectAllUpdated() {
      if (this.selectAll) {
        this.selectAllCertificates()
      } else {
        this.unSelectAllCertificates()
      }
    },
    selectAllCertificates() {
      this.form.selected_certificates = this.getCertificates().map((certificate) => ({
        id: certificate.id,
        locale: certificate.locale,
      }))
    },
    unSelectAllCertificates() {
      this.form.selected_certificates = []
    },
  },
}
</script>
