<template>
  <div v-if="value === 'to-do'" :class="`${cssClasses} text-orange-500`">
    {{ $t('To do') }}
  </div>
  <div v-else-if="value === 'in-progress'" :class="`${cssClasses} text-yellow-500`">
    {{ $t('In progress') }}
  </div>
  <div v-else-if="value === 'completed'" :class="`${cssClasses} text-green-500`">
    {{ $t('Completed') }}
  </div>
  <div v-else-if="value === 'cannot-do'" :class="`${cssClasses} text-ts-red-500`">
    {{ $t("Can't do") }}
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cssClasses: 'inline-block px-1 py-1 rounded-md text-sm text-center',
    }
  },
}
</script>
