<template>
  <front-layout :title="$t('Edit Class')">
    <div class="mb-8 flex justify-between">
      <breadcrumb :name="$t(`Edit Class: ${course.name}`)" />
    </div>
    <div class="flex flex-wrap">
      <div class="flex w-full flex-col items-start xl:flex-row">
        <div class="mb-8 mr-0 rounded-md bg-white shadow lg:w-2/3 xl:mb-0 xl:w-1/3">
          <div class="border-t px-8 pb-10 pt-6">
            <dl class="grid w-full gap-y-8 overflow-hidden break-words text-ts-gray-text-bold">
              <div class="flex w-full overflow-hidden">
                <div>
                  <dt class="mb-2 font-semibold">{{ $t('Instructors') }}</dt>
                  <dd class="text-ts-gray-700">{{ instructors }}</dd>
                </div>
              </div>
              <div class="flex w-full overflow-hidden">
                <div>
                  <dt class="font-semibold">{{ $t('Dates') }}</dt>
                  <dd v-for="(date, index) in dates" :key="index" class="mt-2 text-ts-gray-700">{{ date }}</dd>
                </div>
              </div>
              <div class="flex w-full overflow-hidden">
                <div>
                  <dt class="mb-2 font-semibold">{{ $t('Location') }}</dt>
                  <dd class="text-ts-gray-700">{{ location.name }}</dd>
                  <dd class="mt-2 text-ts-gray-700">{{ location.address }}</dd>
                </div>
              </div>
              <div v-if="!!instructions['classroom'] || !!instructions['content']" class="flex w-full overflow-hidden">
                <div>
                  <dt class="mb-2 font-semibold">{{ $t('Instructions') }}</dt>
                  <dd class="text-ts-gray-700">{{ instructions['classroom'] }}</dd>
                  <dd class="mt-2 text-ts-gray-700">{{ instructions['content'] }}</dd>
                </div>
              </div>
            </dl>
          </div>
        </div>
        <div class="flex w-full flex-col xl:w-2/3 xl:pl-16">
          <span v-for="(error, index) in form.errors" :key="index" class="mb-2 rounded bg-red-500 p-4 text-white">
            {{ error }}
          </span>
          <div class="flex-1">
            <h2 class="text-md sr-only">{{ $t('Courses') }}</h2>
            <div class="mb-8 rounded-md bg-white shadow-md">
              <table class="front-table">
                <thead>
                  <tr>
                    <th>{{ $t('Name') }}</th>
                    <th>{{ $t('Email') }}</th>
                    <th>{{ $t('Phone') }}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="students.length === 0">
                    <td colspan="3">{{ $t('No students found.') }}</td>
                  </tr>
                  <tr v-for="student in students" :key="student.id">
                    <td>{{ student.name || '-' }}</td>
                    <td>{{ student.email || '-' }}</td>
                    <td>{{ student.phone || '-' }}</td>
                    <td v-if="student.enrolment.unassigned" class="flex items-center">
                      <dynamic-search-input
                        v-model="form.students[student.id].user"
                        class="w-full"
                        :search-by="['name', 'email']"
                        track-by="id"
                        :url="route('front.manager.users.search')"
                      >
                        <div v-if="form.students[student.id].user" class="flex items-center justify-between">
                          <div class="truncate">{{ form.students[student.id].user.name }}</div>
                          <div class="whitespace-nowrap text-xs text-gray-600">
                            {{ form.students[student.id].user.email }}
                          </div>
                        </div>
                        <template #option="{ option, selected }">
                          <div class="flex items-center justify-between">
                            <div>{{ option.name }}</div>
                            <div class="text-xs" :class="{ 'text-gray-600': !selected, 'text-white': selected }">
                              {{ option.email }}
                            </div>
                          </div>
                        </template>
                      </dynamic-search-input>
                      <button class="ml-2 font-semibold hover:underline" @click="enrol(student)">
                        {{ $t('Enrol') }}
                      </button>
                    </td>
                    <td v-else />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import DynamicSearchInput from '@/Shared/DynamicSearchInput.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    DynamicSearchInput,
    FrontLayout,
  },
  props: {
    dates: Array,
    course: Object,
    class_: Object,
    location: Object,
    instructors: String,
    instructions: Object,
    students: Array,
  },
  data() {
    var student_list = {}
    this.students.forEach((student) => {
      student_list[student['id']] = {
        id: student['id'],
        user: null,
      }
    })
    return {
      form: useForm({
        students: student_list,
      }),
    }
  },
  methods: {
    enrol(student) {
      this.form
        .transform((data) => {
          return {
            user_id: data.students[student.id]?.user?.id,
            enroller_id: this.$page.props.auth.user.id,
          }
        })
        .post(this.route('front.manager.enrolment.enrol', student.enrolment.id))
    },
  },
}
</script>
