<template>
  <form @submit.prevent="sendRequest">
    <styled-modal max-width="lg" :show="show" @close="$emit('close')">
      <template #title>{{ $t('Add Certificate') }}</template>

      <template #content>
        <div class="mt-6 flex flex-col space-y-4">
          <course-search-input v-model="form.course" :error="form.errors.course_id" :label="$t('Course')" required />
        </div>
        <div class="mt-6 flex flex-col space-y-4">
          <date-input
            v-model="form.issued_at"
            :error="form.errors.issued_at"
            :label="$t('Issued At')"
            :max-year="currentYear"
            :min-year="2000"
            :required="true"
          />
        </div>
        <div class="mt-6 flex flex-col space-y-4">
          <text-input
            v-model="form.score"
            :error="form.errors.score"
            :label="$t('Score')"
            placeholder="Between 0 to 100"
          />
        </div>
      </template>

      <template #footer>
        <div class="flex w-full justify-end gap-4">
          <button class="text-gray-500" @click.prevent="$emit('close')">{{ $t('Cancel') }}</button>
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Add') }}
          </loading-button>
        </div>
      </template>
    </styled-modal>
  </form>
</template>

<script>
import CourseSearchInput from '@/Shared/BackOffice/CourseSearchInput.vue'
import DateInput from '@/Shared/DateInput.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import StyledModal from '@/Shared/StyledModal.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    CourseSearchInput,
    DateInput,
    LoadingButton,
    StyledModal,
    TextInput,
  },
  name: 'AddCertificateModal',
  props: {
    show: Boolean,
    userId: Number,
  },
  data() {
    return {
      form: useForm({
        course: null,
        issued_at: null,
        score: null,
      }),
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
  },
  methods: {
    sendRequest() {
      this.form
        .transform(({ course, ...restOfData }) => ({
          ...restOfData,
          course_id: course?.id,
          user_id: this.userId,
        }))
        .post(this.route('certificates.store'), {
          preserveScroll: true,
          onSuccess: () => {
            this.$emit('close')
            this.$emit('certificateAdded')
          },
        })
    },
  },
}
</script>
