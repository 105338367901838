<template>
  <tr>
    <td class="w-2/3 whitespace-nowrap border-t px-6 py-4">
      <InertiaLink
        v-if="subscriptionItem.subscriptionPlan"
        class="link"
        :href="route('subscription-plans.edit', subscriptionItem.subscriptionPlan.id)"
      >
        {{ subscriptionItem.subscriptionPlan.title }}
      </InertiaLink>
    </td>
    <td class="flex w-1/3 items-center whitespace-nowrap border-t px-6 py-4">
      <text-input
        v-model="form.quantity"
        css-class="text-center w-20"
        :disabled="!subscriptionItem.can.update"
        min="1"
        type="number"
      />
      <button
        v-if="subscriptionItem.can.update"
        class="btn-blue mx-4 mt-2"
        :disabled="form.quantity <= subscriptionItem.quantity"
        @click="updateQuantity"
      >
        <icon class="h-4 w-4 fill-current text-white" name="checkmark" />
      </button>
    </td>
  </tr>
</template>
<script>
import Icon from '@/Shared/Icon.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Icon,
    TextInput,
  },
  props: {
    organization: Object,
    subscriptionItem: Object,
  },
  data() {
    return {
      form: useForm({
        quantity: this.subscriptionItem.quantity,
      }),
    }
  },
  methods: {
    updateQuantity() {
      this.form.post(
        this.route('organizations.subscription-plans.store', [
          this.organization.id,
          this.subscriptionItem.subscriptionPlan.id,
        ])
      )
    },
  },
}
</script>
