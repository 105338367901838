<template>
  <front-modal class="rounded text-base" :show="show" style="z-index: 100000" width="50rem" @close="close">
    <div class="max-h-screen max-w-lg overflow-auto rounded bg-white p-6 shadow md:p-10">
      <div class="w-full overflow-hidden">
        <div
          v-if="$page.props.flash.modal_success && showFlash"
          class="no-print mb-4 flex max-w-lg items-center justify-between rounded bg-green-400"
        >
          <div class="flex items-center">
            <icon class="ml-4 mr-2 h-4 w-4 flex-shrink-0 fill-white" name="checkmark" />
            <div class="py-4 text-sm font-medium text-white">{{ $page.props.flash.modal_success }}</div>
          </div>
          <button class="group mr-2 p-2" type="button" @click="showFlash = false">
            <icon class="block h-3 w-3 fill-green-300 group-hover:fill-green-100" name="close" />
          </button>
        </div>
        <div
          v-if="$page.props.flash.modal_error && showFlash"
          class="no-print mb-4 flex max-w-lg items-center justify-between rounded bg-red-400"
        >
          <div class="flex items-center">
            <icon class="ml-4 mr-2 h-4 w-4 flex-shrink-0 fill-white" name="close-outline" />
            <div class="py-4 text-sm font-medium text-white">{{ $page.props.flash.modal_error }}</div>
          </div>
          <button class="group mr-2 p-2" type="button" @click="showFlash = false">
            <icon class="block h-3 w-3 fill-red-300 group-hover:fill-red-100" name="close" />
          </button>
        </div>
      </div>
      <div v-if="signatureRequest">
        <h3 class="w-full border-b border-ts-gray-300 pb-5 text-center text-xl font-semibold text-black">
          {{ signatureRequest.accepted_at ? $t('Signature Completed!') : $t('Signature Requested') }}
        </h3>

        <div class="mt-4 w-full">
          <div v-if="!signatureRequest.accepted_at" class="w-full">
            <div class="mb-1 flex justify-between">
              <a v-if="!showSignaturePadUrl" class="link text-sm" @click="enableSignaturePadQrCode()">
                {{ $t('Sign on different device') }}
              </a>
              <a v-else class="link text-sm" @click="hideSignaturePadQrCode()">{{ $t('Sign on this device') }}</a>
              <button v-if="!showSignaturePadUrl" class="link text-sm" @click.prevent="clear">
                {{ $t('Clear Signature') }}
              </button>
            </div>

            <template v-if="!showSignaturePadUrl">
              <div class="h-32 w-full sm:h-64" style="max-height: 65vh">
                <vue-signature-pad ref="signaturePad" class="h-full w-full border" :options="{ onEnd }" />
              </div>
              <div class="mt-8 flex w-full items-center justify-between">
                <front-outline-button
                  class="inline-block px-12"
                  :disabled="!form.image"
                  type="button"
                  @click="acceptSignature"
                >
                  {{ $t('Approve and Sign') }}
                </front-outline-button>
              </div>
            </template>
            <div v-else class="flex flex-col items-center justify-center">
              <template v-if="signaturePadUrl">
                <qrcode-vue level="H" :size="200" :value="signaturePadUrl" />
                <span class="mt-2 text-sm">{{ $t('Use the QR code to sign from a different device') }}</span>
              </template>
              <div v-else class="flex h-32 w-full items-center justify-center sm:h-64" style="max-height: 65vh">
                <span>{{ $t('Loading...') }}</span>
              </div>
            </div>
          </div>
          <div v-else class="w-full text-center">
            <p>{{ $t('Thank you for completing the process.') }}</p>
          </div>
        </div>
      </div>
      <div v-else>
        <h3 class="w-full border-b border-ts-gray-300 pb-5 text-center text-xl font-semibold text-black">
          {{ $t('Request Signatures') }}
        </h3>

        <div class="mt-4 w-full">
          <div v-for="(signature, index) in formFilled.form_template.signatures" :key="index">
            <signature-type
              :form-filled="formFilled"
              :is-last="index === formFilled.form_template.signatures.length - 1"
              :signature="signature"
              @update-data="onDataUpdate"
            />
          </div>
        </div>
        <div v-if="isSignatureTypeRequiredNotAssigneeOnly">
          <div class="flex w-full items-center justify-between">
            <front-outline-button type="button" @click="close">
              {{ $t('Cancel') }}
            </front-outline-button>
            <front-solid-button class="ml-4" :disabled="!enableSendEmailButton" @click.prevent="sendEmail">
              <span>{{ $t('Send Email') }}</span>
            </front-solid-button>
          </div>
        </div>
      </div>
    </div>
  </front-modal>
</template>

<script>
import SignatureType from './SignatureType.vue'
import FrontModal from '@/Shared/FrontModal.vue'
import FrontOutlineButton from '@/Shared/FrontOutlineButton.vue'
import FrontSolidButton from '@/Shared/FrontSolidButton.vue'
import Icon from '@/Shared/Icon.vue'
import Http from '@/Utils/Http'
import { useForm } from '@inertiajs/vue3'
import QrcodeVue from 'qrcode.vue'
import trimCanvas from 'trim-canvas'
import { VueSignaturePad } from 'vue-signature-pad'

export default {
  components: {
    SignatureType,
    VueSignaturePad,
    QrcodeVue,
    FrontModal,
    FrontOutlineButton,
    FrontSolidButton,
    Icon,
  },
  props: {
    show: Boolean,
    formFilled: Object,
  },
  data() {
    return {
      showFlash: true,
      timer: null,
      showSignaturePadUrl: false,
      signaturePadUrl: null,
      form: useForm({
        image: null,
      }),
      signatureRequestForm: useForm({
        requests: [],
      }),
    }
  },
  beforeUnmount() {
    this.stopTimer()
  },
  watch: {
    '$page.props.flash': {
      handler() {
        this.showFlash = true
      },
      deep: true,
    },
    showSignaturePadUrl: function (newValue) {
      if (newValue) {
        this.startTimer()
      } else {
        this.stopTimer()
      }
    },
  },
  computed: {
    signatureRequest: function () {
      if (this.formFilled.signature_request_id) {
        return this.formFilled.signature_requests.filter((signatureRequest) => {
          return signatureRequest.id === Number(this.formFilled.signature_request_id)
        })[0]
      }
      return null
    },
    enableSendEmailButton() {
      if (this.formFilled.form_template.signatures.some((signature) => signature.signature_type === 'user')) {
        if (!this.formFilled.signature_requests.some((request) => request.signature_type === 'user')) {
          return true
        }
      }

      return this.signatureRequestForm.requests.length
    },
    isSignatureTypeRequiredNotAssigneeOnly() {
      return (
        this.formFilled.form_template.signatures.length > 1 ||
        this.formFilled.form_template.signatures[0].signature_type !== 'assignee'
      )
    },
  },
  methods: {
    close() {
      this.$emit('close')
      this.form.reset()
      this.form.clearErrors()
    },
    clear() {
      this.$refs.signaturePad.clearSignature()
      this.form.image = null
    },
    getTrimmedCanvas() {
      let copy = document.createElement('canvas')
      const signaturePadCanvas = this.$refs.signaturePad.$refs.signaturePadCanvas
      copy.width = signaturePadCanvas.width
      copy.height = signaturePadCanvas.height
      copy.getContext('2d').drawImage(signaturePadCanvas, 0, 0)
      return trimCanvas(copy)
    },
    submitSignature() {
      let trimmedCanvas = this.getTrimmedCanvas()
      this.form.image = trimmedCanvas.toDataURL('image/png')

      this.form.post(this.route('front.filledForms.signature.store', this.signatureRequest?.id), {
        preserveScroll: true,
        onSuccess: () => {
          this.form.reset()
          this.form.clearErrors()
        },
      })
    },
    enableSignaturePadQrCode() {
      this.showSignaturePadUrl = true
      this.getSignaturePadUrl()
    },
    hideSignaturePadQrCode() {
      this.showSignaturePadUrl = false
    },
    getSignaturePadUrl() {
      Http.get(this.route('front.filledForms.signature-url', this.signatureRequest?.id)).then((response) => {
        this.signaturePadUrl = response.data.url
      })
    },
    startTimer() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.getSignaturePadUrl()
        }, 240000)
      }
    },
    stopTimer() {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    onEnd() {
      let trimmedCanvas = this.getTrimmedCanvas()
      this.form.image = trimmedCanvas.toDataURL('image/png')
    },
    acceptSignature() {
      this.form.post(this.formFilled.signature_request_accept_url, {
        preserveScroll: true,
        onSuccess: () => {
          this.form.reset()
          this.form.clearErrors()
          this.clear()
        },
      })
    },
    onDataUpdate(data) {
      const index = this.signatureRequestForm.requests.findIndex((item) => item.signatureType === data.signatureType)
      if (index < 0) {
        this.signatureRequestForm.requests.push(data)
      } else {
        if (data.users.length) {
          this.signatureRequestForm.requests[index].users = data.users
        } else {
          this.signatureRequestForm.requests.splice(index, 1)
        }
      }
    },
    dataSubmitSignature() {
      this.form.image = trimmedCanvas.toDataURL('image/png')
      this.form.post(this.route('front.filledForms.signature.store', this.signatureRequest?.id), {
        preserveScroll: true,
        onSuccess: () => {
          this.hideSignaturePad()
          this.form.reset()
          this.form.clearErrors()
        },
      })
    },
    sendEmail() {
      if (!this.formFilled.signature_requests.some((request) => request.signature_type === 'user')) {
        let filteredUser = this.formFilled.form_template.signatures.filter((signature) => {
          return signature.signature_type === 'user'
        })[0]

        if (filteredUser?.users?.length) {
          this.signatureRequestForm.requests.push({
            users: filteredUser.users,
            signatureType: 'user',
          })
        }
      }

      this.signatureRequestForm.post(this.route('front.filledForms.signature-request.store', this.formFilled.id), {
        preserveScroll: true,
        onSuccess: () => {
          this.signatureRequestForm.requests.reset()
        },
        onError: (error) => {
          this.$page.props.flash.modal_error = Object.values(error)[0]
          this.signatureRequestForm.requests.reset()
        },
      })
    },
  },
}
</script>
