<template>
  <div class="h-screen w-screen bg-ts-front-bg lg:px-20 lg:pt-10">
    <InertiaLink
      class="mb-2 ml-auto flex h-10 w-10 items-center justify-center rounded-full border border-qualify-red-500 p-3 hover:cursor-pointer"
      :href="route('front.contents', enrolmentId)"
    >
      <icon class="h-full w-full fill-qualify-red-500" name="close" />
    </InertiaLink>
    <div class="w-full rounded-2xl bg-white px-12 py-8" style="height: 90% !important">
      <iframe class="h-full w-full" :src="link" />
    </div>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon.vue'
import zendesk from '@/mixins/zendesk.js'

export default {
  components: {
    Icon,
  },
  mixins: [zendesk],
  props: {
    enrolmentId: {
      type: Number,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
  mounted() {
    // Hide Zendesk Chat Widget
    this.zE_hideWidget()
  },
  unmounted() {
    this.zE_showWidget()
  },
}
</script>
