<template>
  <front-layout :title="$t('Manuals')">
    <div class="flex flex-col xl:flex-row xl:justify-between">
      <div class="sm:no-flex mb-8 flex w-full items-center justify-between">
        <breadcrumb :name="$t('Manuals')" />
        <front-button-link class="sm:hidden" :href="route('front.admin.manuals.create')">
          <span>{{ $t('+ Add') }}</span>
        </front-button-link>
      </div>
      <div class="mb-6 flex w-full items-center justify-end">
        <front-search-filter
          v-model="form.search"
          class="flex w-full flex-col sm:mr-4 sm:flex-row lg:max-w-sm"
          :filter-show="false"
          @reset="reset"
        />
        <front-button-link class="hidden sm:inline" :href="route('front.admin.manuals.create')">
          <span>{{ $t('+ Add') }}</span>
        </front-button-link>
      </div>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow-lg">
      <table class="front-table">
        <thead class="bg-ts-front-table-header">
          <tr>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
              {{ $t('Name') }}
            </th>
            <th class="whitespace-nowrap pb-4 pt-6 text-left font-semibold">
              {{ $t('Created') }}
            </th>
            <th class="whitespace-nowrap pb-4 pt-6 text-left font-semibold" />
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
          <tr v-if="!manuals.data.length">
            <td class="border-t px-6 py-4" colspan="6">
              {{ $t('No manuals found.') }}
            </td>
          </tr>
          <tr
            v-for="manual in manuals.data"
            :key="manual.id"
            @click="$inertia.visit(route('front.admin.manuals.edit', manual.id))"
          >
            <td class="font-semibold text-ts-gray-text-bold">
              {{ manual.name }}
            </td>
            <td>
              {{ manual.created_at }}
            </td>
            <td class="hover:underline">
              <InertiaLink class="font-semibold hover:underline" :href="route('front.admin.manuals.edit', manual.id)">
                {{ $t('Edit') }}
              </InertiaLink>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <front-pagination :links="manuals.links" />
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontButtonLink from '@/Shared/FrontButtonLink.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontPagination from '@/Shared/FrontPagination.vue'
import FrontSearchFilter from '@/Shared/FrontSearchFilter.vue'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    Breadcrumb,
    FrontButtonLink,
    FrontLayout,
    FrontPagination,
    FrontSearchFilter,
  },
  props: {
    filters: Object,
    organization: Object,
    manuals: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
      },
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(
          this.route('front.admin.manuals'),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
