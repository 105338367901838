<template>
  <div class="ts-bg flex min-h-screen items-start justify-center p-6">
    <DynamicHead :title="$t('Webinar Check-in') + ` | ${student.first_name} ${student.last_name}`" />
    <div class="w-full max-w-xl">
      <div class="mt-12 overflow-hidden rounded-lg bg-white shadow-lg">
        <div class="px-10 pb-6 pt-6">
          <h1 class="text-center text-xl font-bold">
            {{
              $t('Your :course_name webinar starts :starts_at.', {
                course_name: student.course.name,
                starts_at: session.starts_at,
              })
            }}
          </h1>
          <div class="mt-4 text-center text-xl text-gray-600">
            {{ $t('Please try this link closer to the start time.') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicHead from '@/Shared/DynamicHead.vue'

export default {
  components: {
    DynamicHead,
  },
  props: {
    session: Object,
    student: Object,
  },
}
</script>
