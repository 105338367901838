<template>
  <button class="rounded-full text-xs font-semibold" :class="{ 'bg-green-200 px-2 py-1': isEnabled }" @click="open">
    <CheckCircleIcon v-if="isEnabled" aria-hidden="true" class="mb-px mr-px inline h-4 w-4" />
    <PlusIcon v-else aria-hidden="true" class="mb-1 inline h-3 w-3" />
    <span>Evidence</span>
    <evidence-action-modal
      :evidence="evidence"
      :logic-name="logicName"
      :show="isShowModal"
      @close="close"
      @save="onSave"
    />
  </button>
</template>
<script>
import EvidenceActionModal from './ActionModals/EvidenceActionModal.vue'
import { CheckCircleIcon, PlusIcon } from '@heroicons/vue/20/solid'

export default {
  components: {
    EvidenceActionModal,
    CheckCircleIcon,
    PlusIcon,
  },
  props: {
    evidence: Object,
    logicName: String,
  },
  emits: ['update:evidence'],
  data() {
    return {
      isShowModal: false,
    }
  },
  computed: {
    isEnabled() {
      return this.evidence?.notes_required || this.evidence?.media_required
    },
  },
  methods: {
    open() {
      this.isShowModal = true
    },
    close() {
      this.isShowModal = false
    },
    onSave(evidence) {
      this.$emit('update:evidence', evidence)
      this.close()
    },
  },
}
</script>
