<template>
  <div class="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-3 xl:grid-cols-3">
    <div v-for="(payment, index) in paymentMethods" :key="index">
      <payment-card
        v-if="payment.type === 'card'"
        :can-Delete="paymentMethods.length > 1"
        :is-default="payment.id === defaultPaymentMethod?.id"
        :organization="organization"
        :payment="payment"
      />
    </div>
    <div class="h-32 w-2/3 cursor-pointer rounded-lg bg-gray-200/60 p-6" @click="showAddPaymentMethodModal">
      <div class="relative flex h-full w-full place-content-center">
        <PlusCircleIcon aria-hidden="true" class="absolute h-12 w-12 self-center text-gray-400" />
      </div>
    </div>

    <add-payment-method-modal
      :organization="organization"
      :show="isAddPaymentMethodModal"
      @close="hideAddPaymentMethodModal"
    />
  </div>
</template>
<script>
import AddPaymentMethodModal from '../PaymentMethods/AddPaymentMethodModal.vue'
import PaymentCard from './PaymentCard.vue'
import { PlusCircleIcon } from '@heroicons/vue/24/outline'
import { useForm } from '@inertiajs/vue3'

export default {
  components: { AddPaymentMethodModal, PaymentCard, PlusCircleIcon },
  props: {
    organization: Object,
    defaultPaymentMethod: Object,
    paymentMethods: Array,
  },
  data() {
    return {
      isAddPaymentMethodModal: false,
      form: useForm({
        paymentMethodId: this.paymentMethod?.id,
      }),
    }
  },
  methods: {
    showAddPaymentMethodModal() {
      this.isAddPaymentMethodModal = true
    },
    hideAddPaymentMethodModal() {
      this.isAddPaymentMethodModal = false
    },
  },
}
</script>
