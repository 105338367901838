<template>
  <div class="h-8 w-8 overflow-hidden rounded-full border-4" :class="tenantCss">
    <svg
      v-if="locked"
      id="lock-24px"
      class="h-full w-full border-2 border-transparent fill-white p-px"
      height="30"
      viewBox="0 0 32 32"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Path_5666"
        class="fill-white"
        d="M22.32,10.284H21.011V7.631a6.543,6.543,0,1,0-13.086,0v2.653H6.617A2.643,2.643,0,0,0,4,12.936V26.2a2.643,2.643,0,0,0,2.617,2.653h15.7A2.643,2.643,0,0,0,24.937,26.2V12.936A2.643,2.643,0,0,0,22.32,10.284ZM10.543,7.631a3.926,3.926,0,1,1,7.851,0v2.653H10.543ZM22.32,26.2H6.617V12.936h15.7ZM14.468,22.22a2.653,2.653,0,1,0-2.617-2.653A2.643,2.643,0,0,0,14.468,22.22Z"
        data-name="Path 5666"
        transform="translate(1.532 0.383)"
      />
    </svg>
    <div v-else-if="finished" class="h-full w-full fill-white p-px shadow">
      <icon class="border-2 border-transparent" name="checkmark" />
    </div>
  </div>
</template>
<script>
import Icon from '@/Shared/Icon.vue'
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  components: {
    Icon,
  },
  props: {
    locked: Boolean,
    finished: Boolean,
  },
  computed: {
    isTenantWorksite,
    tenantCss() {
      if (this.isTenantWorksite) {
        return this.locked || this.finished
          ? 'border-qualify-red-500 bg-qualify-red-500'
          : 'border-qualify-red-500 bg-white'
      } else {
        return this.locked || this.finished ? 'border-blue-700 bg-blue-700' : 'border-blue-700 bg-white'
      }
    },
  },
}
</script>
