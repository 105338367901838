<template>
  <div>
    <div class="mb-10">
      <breadcrumb class="ts-gray-text-bold text-3xl font-semibold" :name="$t('Account Settings')" />
    </div>
    <div class="flex max-w-2xl flex-wrap">
      <div class="w-full">
        <div class="flex flex-col xl:flex-row xl:items-start">
          <div class="mb-4 mr-0 xl:mb-0 xl:mr-6">
            <div
              class="flex w-full flex-col items-center justify-center rounded-lg border border-ts-gray-500 bg-white px-20 py-8 xl:py-12"
            >
              <front-profile-image
                :delete-url="route('front.settings.profile-image.destroy')"
                :update-url="route('front.settings.profile-image.update')"
                :user="user"
              />
              <div v-if="user" class="-mx-64 mt-6 text-center text-lg text-black xl:mt-10">
                {{ user.display_name }}
              </div>
            </div>
          </div>
          <div class="flex w-full flex-wrap">
            <tab-layout :menu-list="menuList">
              <slot />
            </tab-layout>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontProfileImage from '@/Shared/FrontProfileImage.vue'
import TabLayout from '@/Shared/TabLayout.vue'
import Http from '@/Utils/Http'

export default {
  components: {
    Breadcrumb,
    FrontProfileImage,
    TabLayout,
  },
  data() {
    return {
      user: null,
    }
  },
  computed: {
    menuList() {
      let menuList = [
        {
          name: this.$t('Account'),
          url: this.route('front.settings'),
          matchUrl: 'account/settings',
        },
        {
          name: this.$t('Password'),
          url: this.route('front.settings.password.edit'),
          matchUrl: 'account/settings/password',
        },
        {
          name: this.$t('Public Profile'),
          url: this.route('front.settings.public-profile.edit'),
          matchUrl: 'account/settings/public-profile',
        },
        {
          name: this.$t('Certificate'),
          url: this.route('front.settings.certificate-information.edit'),
          matchUrl: 'account/settings/certificate',
        },
        {
          name: this.$t('Notifications'),
          url: this.route('front.settings.notifications.edit'),
          matchUrl: 'account/settings/notifications',
        },
      ]

      if (this.$page.props.auth.user.organization) {
        menuList = menuList.filter(function (value) {
          return value.name != 'Certificate'
        })
      }

      return menuList
    },
  },
  created() {
    this.getUserData()
  },
  methods: {
    getUserData() {
      Http.get(this.route('front.settings.profile')).then((response) => {
        this.user = response.data
      })
    },
  },
}
</script>
