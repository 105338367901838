<template>
  <div class="p-8">
    <div>
      <div class="mt-4 flex items-end justify-between">
        <h2 class="text-lg">{{ $t('Classes') }}</h2>
      </div>
      <div class="mt-4 overflow-x-auto rounded bg-white shadow">
        <SimpleTable>
          <template #head>
            <TableHead>{{ $t('Name') }}</TableHead>
            <TableHead>{{ $t('Payment') }}</TableHead>
            <TableHead>{{ $t('Sessions') }}</TableHead>
            <TableHead>{{ $t('Attendance') }}</TableHead>
            <TableHead>{{ $t('Certificate #') }}</TableHead>
            <TableHead />
          </template>
          <TableRow v-if="students.data.length === 0">
            <TableData colspan="6">{{ $t('No class found.') }}</TableData>
          </TableRow>
          <TableRow
            v-for="student in students.data"
            :key="student.id"
            :clickable="true"
            @click="$inertia.visit(route('classes.show', student.class.id), { preserveScroll: true })"
          >
            <TableData>{{ student.class.name }}</TableData>
            <TableData>{{ student.payment_status }}</TableData>
            <TableData>
              <div v-if="student.class.sessions_count">
                <span class="mr-1 rounded bg-gray-400 px-2 py-1 text-sm text-white">
                  {{ student.class.sessions_count }}
                </span>
                {{ student.class.dates }}
              </div>
              <div v-else class="text-red-500">{{ $t('None set') }}</div>
            </TableData>
            <TableData>
              <span v-if="student.present !== null">{{ student.present ? $t('Present') : $t('Absent') }}</span>
              <span v-else class="text-sm text-gray-400">-</span>
            </TableData>
            <TableData>
              <span v-if="student.certificate_number">{{ student.certificate_number }}</span>
              <span v-else class="text-sm text-gray-400">-</span>
            </TableData>
            <TableData class="w-min align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </TableData>
          </TableRow>
        </SimpleTable>
      </div>
      <pagination :links="students.links" :preserve-scroll="true" />
    </div>
    <div>
      <div class="mt-4 flex items-end justify-between">
        <h2 class="text-lg">{{ $t('Reserved Classes') }}</h2>
      </div>
      <div class="mt-4 overflow-x-auto rounded bg-white shadow">
        <SimpleTable>
          <template #head>
            <TableHead>{{ $t('Name') }}</TableHead>
            <TableHead>{{ $t('Payment') }}</TableHead>
            <TableHead>{{ $t('Sessions') }}</TableHead>
            <TableHead>{{ $t('Attendance') }}</TableHead>
            <TableHead>{{ $t('Certificate #') }}</TableHead>
            <TableHead />
          </template>
          <TableRow v-if="reservedStudents.data.length === 0">
            <TableData colspan="6">{{ $t('No class found.') }}</TableData>
          </TableRow>
          <TableRow
            v-for="student in reservedStudents.data"
            :key="student.id"
            :clickable="true"
            @click="$inertia.visit(route('classes.show', student.class.id), { preserveScroll: true })"
          >
            <TableData>{{ student.class.name }}</TableData>
            <TableData>{{ student.payment_status }}</TableData>
            <TableData>
              <div v-if="student.class.sessions_count">
                <span class="mr-1 rounded bg-gray-400 px-2 py-1 text-sm text-white">
                  {{ student.class.sessions_count }}
                </span>
                {{ student.class.dates }}
              </div>
              <div v-else class="text-red-500">{{ $t('None set') }}</div>
            </TableData>
            <TableData>
              <span v-if="student.present !== null">{{ student.present ? $t('Present') : $t('Absent') }}</span>
              <span v-else class="text-sm text-gray-400">-</span>
            </TableData>
            <TableData>
              <span v-if="student.certificate_number">{{ student.certificate_number }}</span>
              <span v-else class="text-sm text-gray-400">-</span>
            </TableData>
            <TableData class="w-min align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </TableData>
          </TableRow>
        </SimpleTable>
      </div>
      <pagination :links="reservedStudents.links" :preserve-scroll="true" />
    </div>
  </div>
</template>

<script>
import UserEditLayoutVue from './UserEditLayout.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'

export default {
  components: {
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
    Icon,
    Pagination,
  },
  layout: [Layout, UserEditLayoutVue],
  props: {
    students: Object,
    reservedStudents: Object,
  },
}
</script>
