<template>
  <layout :title="$t('Reports')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('reports')">
        {{ $t('Reports') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Paid Hours') }}
    </h1>
    <div>
      <div class="mb-4 flex items-center">
        <date-input v-model="form.local_start_date" class="mr-4 w-64" />
        <span class="mr-4">~</span>
        <date-input v-model="form.local_end_date" class="mr-2 w-64" />
        <button class="ml-3 py-3 text-sm text-gray-600 hover:text-gray-700" type="button" @click="reset">
          {{ $t('Reset') }}
        </button>
        <a
          v-if="form.local_start_date && form.local_end_date"
          class="ml-4 py-3 text-sm text-gray-600 hover:text-gray-700"
          download
          :href="
            route('reports.instructors.hours.export', {
              local_start_date: form.local_start_date,
              local_end_date: form.local_end_date,
            })
          "
        >
          {{ $t('Download') }}
        </a>
      </div>
      <div v-for="instructor in instructors" :key="instructor.id" class="mt-4 overflow-x-auto rounded bg-white shadow">
        <table class="w-full">
          <thead>
            <tr>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ instructor.name }}</th>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Paid Time') }}</th>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Hours') }}</th>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Class') }}</th>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Location') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="session in instructor.sessions"
              :key="session.session_id"
              class="hover:cursor-pointer hover:bg-gray-100"
              @click="$inertia.visit(route('classes.show', session.class_id))"
            >
              <td class="whitespace-nowrap border-t px-6 py-4">
                {{ session.time }}
              </td>
              <td class="whitespace-nowrap border-t px-6 py-4">
                {{ session.paid_time_range }}
              </td>
              <td class="whitespace-nowrap border-t px-6 py-4">
                {{ session.subtotal_paid_time.display }}
              </td>
              <td class="whitespace-nowrap border-t px-6 py-4">{{ session.course_name }} {{ session.class_id }}</td>
              <td class="whitespace-nowrap border-t px-6 py-4">
                {{ session.location_name }} {{ session.room_name ? `(${session.room_name})` : null }}
              </td>
            </tr>
            <tr v-if="instructor.sessions.length !== 0">
              <td class="whitespace-nowrap border-t px-6 py-4" colspan="5">
                Total:
                {{
                  instructor.sessions
                    .map((session) => session.subtotal_paid_time.minutes)
                    .reduce((sum, minutes) => sum + minutes, 0) / 60
                }}
              </td>
            </tr>
            <tr v-else>
              <td class="px-6 py-4" colspan="3">{{ $t('No sessions found.') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="instructors.length === 0">
        <div class="py-4">{{ $t('No instructors found.') }}</div>
      </div>
    </div>
  </layout>
</template>

<script>
import DateInput from '@/Shared/DateInput.vue'
import Layout from '@/Shared/Layout.vue'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    DateInput,
    Layout,
  },
  props: {
    instructors: Array,
    filters: Object,
  },
  data() {
    return {
      form: {
        local_start_date: this.filters.local_start_date,
        local_end_date: this.filters.local_end_date,
      },
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)

        this.$inertia.get(
          this.route('reports.instructors.hours'),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
