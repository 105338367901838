<template>
  <layout :title="$t('Users')">
    <h1 class="mb-8 text-xl font-bold">{{ $t('Back-Office Users') }}</h1>
    <div class="mb-6 flex items-center justify-between">
      <search-filter
        v-model="form.search"
        class="mr-4 w-full max-w-md"
        :show-submit-button="true"
        @reset="reset"
        @submit="submitSearch"
      >
        <label class="block text-gray-800">{{ $t('Role') }}:</label>
        <select v-model="form.role" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="instructor">{{ $t('Instructor') }}</option>
          <option value="support">{{ $t('Support') }}</option>
          <option value="admin">{{ $t('Admin') }}</option>
          <option value="super">{{ $t('Super') }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Archived') }}:</label>
        <select v-model="form.archived" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="with">{{ $t('With') }} {{ $t('Archived') }}</option>
          <option value="only">{{ $t('Only') }} {{ $t('Archived') }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Deleted') }}:</label>
        <select v-model="form.deleted" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="with">{{ $t('With') }} {{ $t('Deleted') }}</option>
          <option value="only">{{ $t('Only') }} {{ $t('Deleted') }}</option>
        </select>
        <template #searchByField>
          <select v-model="form.searchBy" class="form-select w-auto border-none px-3 py-3 focus:ring">
            <option value="name">{{ $t('by') }} {{ $t('Name') }}</option>
            <option value="email">{{ $t('by') }} {{ $t('Email') }}</option>
            <option value="username">{{ $t('by') }} {{ $t('Username') }}</option>
          </select>
        </template>
      </search-filter>
      <InertiaLink class="btn-blue" :href="route('worksite-users.create')">
        <span>{{ $t('Create') }}</span>
        <span class="hidden md:inline">{{ $t('User') }}</span>
      </InertiaLink>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow">
      <SimpleTable>
        <template #head>
          <TableHead>{{ $t('Name') }}</TableHead>
          <TableHead>{{ $t('Email') }}</TableHead>
          <TableHead>{{ $t('Username') }}</TableHead>
          <TableHead>{{ $t('Role') }}</TableHead>
          <TableHead />
        </template>
        <TableRow v-if="users.data.length === 0">
          <TableData class="border-t px-6 py-4" colspan="5">{{ $t('No users found.') }}</TableData>
        </TableRow>
        <TableRow
          v-for="user in users.data"
          :key="user.id"
          class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
          @click="$inertia.visit(route('worksite-users.edit', user.id))"
        >
          <TableData>
            <div class="flex items-center">
              {{ user.first_name }} {{ user.last_name }}
              <archived-badge v-if="user.archived_at" />
              <deleted-badge v-if="user.deleted_at" />
            </div>
          </TableData>
          <TableData>{{ trimText(user.email) }}</TableData>
          <TableData>{{ trimText(user.username, 25) }}</TableData>
          <TableData>
            <div v-if="user.role">
              {{ user.role }}
            </div>
            <div v-else class="text-gray-600">{{ $t('None') }}</div>
          </TableData>
          <TableData class="w-min align-middle">
            <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
          </TableData>
        </TableRow>
      </SimpleTable>
    </div>
    <pagination :links="users.links" />
  </layout>
</template>

<script>
import ArchivedBadge from '@/Shared/ArchivedBadge.vue'
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'

export default {
  components: {
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
    ArchivedBadge,
    DeletedBadge,
    Icon,
    Layout,
    Pagination,
    SearchFilter,
  },
  props: {
    users: Object,
    filters: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        searchBy: this.filters.searchBy || 'name',
        role: this.filters.role,
        archived: this.filters.archived,
        deleted: this.filters.deleted,
      },
    }
  },
  methods: {
    submitSearch() {
      let query = _pickBy(this.form)
      this.$inertia.get(
        this.route('worksite-users.index'),
        Object.keys(query).length ? query : { remember: 'forget' },
        {
          preserveState: true,
          replace: true,
          only: ['users'],
        }
      )
    },
    reset() {
      this.form = _mapValues(this.form, () => null)
      this.submitSearch()
    },
    trimText(text, length = 35) {
      if (!text) return text
      if (text.length < length) return text
      return text.substr(0, length) + '...'
    },
  },
}
</script>
