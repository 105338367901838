<template>
  <div class="flex w-full flex-col items-center">
    <div class="ml-3 flex w-full text-sm">
      <button class="flex items-center justify-center" @click="addLogic">
        <Icon class="mr-2 h-5 w-5 fill-current text-gray-500" name="preferences" />
        <span>{{ $t('Add logic') }}</span>
      </button>
      <div class="mr-3 text-sm">
        <front-checkbox-input v-model="optionsData.is_required" :label="$t('Required')" />
      </div>
      <div class="mr-3 flex">
        <div class="inline-flex items-center border-l border-gray-300 px-4">
          <span class="text-md cursor-pointer text-qualify-red-default" @click="openFormModal">
            <template v-if="optionsData && optionsData.min != null && optionsData.max != null">
              {{ $t('Range: :min-:max', { min: optionsData.min, max: optionsData.max }) }}
            </template>
            <template v-else>{{ $t('Configure') }}</template>
          </span>
        </div>
      </div>
    </div>
    <div class="flex w-full items-center">
      <LogicTabs ref="logicTabs" v-model:model-value="logicsData">
        <LogicTab
          v-for="(logic, index) in logicsData"
          :id="`tab-${index + 1}`"
          :key="index"
          :name="logicNames[index]"
          @delete="removeLogic(index)"
        >
          {{ $t('If value') }}
          <select v-model="logic.operator" class="ml-2 mr-4">
            <option v-for="(value, key) in operators" :key="key" :value="key">{{ $t(value) }}</option>
          </select>
          <span v-if="logic.operator === 'between' || logic.operator === 'not_between'">
            <input v-model="logic.value_one" :max="logic.value_two" :min="min" type="number" />
            {{ $t('and') }}
            <input v-model="logic.value_two" :max="max" :min="logic.value_one" type="number" />
          </span>
          <span v-else>
            <input v-model="logic.value_one" :max="max" :min="min" type="number" />
          </span>
          <span class="pr-2">{{ $t('then') }}</span>
          <span>
            <TriggerAction v-model:action-required="logic.action_required" :logic-name="logicNames[index]" />
            <span class="pr-2">,</span>
            <TriggerEvidence v-model:evidence="logic.evidence" :logic-name="logicNames[index]" />
            <span class="px-2">or</span>
            <TriggerNotify v-model:notify="logic.notify" :logic-name="logicNames[index]" />
          </span>
        </LogicTab>
      </LogicTabs>
    </div>
    <form-modal :show="showFormModal" @close="closeFormModal">
      <template #content>
        <div class="px-4 py-8 sm:px-6">
          <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
            {{ $t('Slider') }}
          </DialogTitle>
          <p class="mt-3 text-sm">{{ $t('You can define the range of response with the slider.') }}</p>
        </div>
        <div class="border bg-white px-4 py-10 sm:px-6">
          <div class="">
            <p class="text-gray-600">{{ $t('Preview') }}</p>
            <p class="my-4 pb-2 pt-8 font-semibold">
              <Slider
                v-model="sliderValue"
                class="slider-red"
                :format="tooltipFormat"
                :max="max"
                :min="min"
                :step="increment"
              />
            </p>
          </div>
          <hr class="my-8" />
          <div class="mt-3">
            <p class="text-sm">{{ $t('Slider Boundaries') }}</p>
            <div class="mt-3 inline-flex items-center">
              <front-text-input
                v-model.number="min"
                class="mr-2 hover:border-red-500 focus:border-red-500 focus:outline-none"
                :increment="increment"
                :max="max"
                placeholder="Min"
                type="number"
              />
              <MinusIcon aria-hidden="true" class="ml-2 h-5 w-5" />
              <front-text-input
                v-model.number="max"
                class="mx-2 hover:border-red-500 focus:border-red-500 focus:outline-none"
                :increment="increment"
                :min="min"
                placeholder="Max"
                type="number"
              />
            </div>
            <p class="mt-3 text-sm">
              {{ $t('Customize the minimum and maximum boundaries of the slider.') }}
            </p>
          </div>
          <hr class="my-8" />
          <div class="mt-3">
            <p class="text-sm">{{ $t('Increments') }}</p>
            <front-text-input
              v-model.number="increment"
              class="mt-3 hover:border-red-500 focus:border-red-500 focus:outline-none"
            />
            <p class="mt-3 text-sm">
              {{ $t('Choose the preferred increment value for the slider movement.') }}
            </p>
          </div>
        </div>
        <div class="px-4 py-6 sm:px-6">
          <button
            class="mr-4 inline-flex items-center rounded-md bg-qualify-red-500 px-3 py-3 text-sm font-semibold leading-4 text-white shadow-sm hover:bg-qualify-red-600 focus:outline-none"
            type="button"
            @click="onSave()"
          >
            {{ $t('Save and apply') }}
          </button>
          <button
            class="mr-4 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-3 text-sm font-semibold leading-4 text-qualify-red-500 shadow-sm hover:bg-gray-50 focus:outline-none"
            type="button"
            @click="closeFormModal()"
          >
            {{ $t('Cancel') }}
          </button>
        </div>
      </template>
    </form-modal>
  </div>
</template>
<script>
import FormModal from './FormModal.vue'
import LogicTab from './LogicTab.vue'
import LogicTabs from './LogicTabs.vue'
import TriggerAction from './TriggerAction.vue'
import TriggerEvidence from './TriggerEvidence.vue'
import TriggerNotify from './TriggerNotify.vue'
import { getOperators } from '@/Enums/Operators'
import FrontCheckboxInput from '@/Shared/FrontCheckboxInput.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import Icon from '@/Shared/Icon.vue'
import { DialogTitle } from '@headlessui/vue'
import { MinusIcon } from '@heroicons/vue/24/outline'
import Slider from '@vueform/slider'

export default {
  components: {
    Icon,
    Slider,
    MinusIcon,
    FormModal,
    DialogTitle,
    LogicTab,
    LogicTabs,
    TriggerAction,
    TriggerEvidence,
    TriggerNotify,
    FrontCheckboxInput,
    FrontTextInput,
  },
  props: {
    options: Object,
    logics: Array,
  },
  emits: ['update:options', 'update:logics'],
  data() {
    return {
      operators: getOperators(['<', '>', '>=', '<=', '=', '!=', 'between', 'not_between']),
      optionsData: {
        is_required: this.options?.is_required ?? false,
        min: this.options?.min ?? 1,
        max: this.options?.max ?? 10,
        increment: this.options?.increment ?? 1,
      },
      logicsData: this.logics ?? [],
      showFormModal: false,
      min: this.options?.min ?? 1,
      max: this.options?.max ?? 10,
      increment: this.options?.increment ?? 1,
    }
  },
  computed: {
    sliderValue() {
      return (this.max - this.min) / 2
    },
    tooltipFormat() {
      return Number.isInteger(this.increment) ? { decimals: 0 } : { decimals: 2 }
    },
    logicNames() {
      return this.logicsData.map((logic) => {
        if (!logic) return ''

        let name = `value ${this.operators[logic.operator]} `

        if (logic.operator === 'between' || logic.operator === 'not_between') {
          name += `${logic.value_one} and ${logic.value_two}`
        } else {
          name += logic.value_one
        }

        return name
      })
    },
    defaultLogicData() {
      return {
        operator: '>',
        value_one: this.min,
        value_two: this.max,
        action_required: false,
        evidence: {
          notes_required: false,
          media_required: false,
        },
      }
    },
  },
  watch: {
    optionsData: {
      handler: function () {
        this.$emit('update:options', this.optionsData)
      },
      deep: true,
    },
    logicsData: {
      handler: function () {
        this.$emit('update:logics', this.logicsData)
      },
      deep: true,
    },
  },
  methods: {
    openFormModal() {
      this.min = this.optionsData.min
      this.max = this.optionsData.max
      this.increment = this.optionsData.increment
      this.showFormModal = true
    },
    closeFormModal() {
      this.showFormModal = false
    },
    onSave() {
      this.optionsData.min = this.min
      this.optionsData.max = this.max
      this.optionsData.increment = this.increment
      this.closeFormModal()
    },
    addLogic() {
      this.$refs.logicTabs.add(this.defaultLogicData)
    },
    removeLogic(index) {
      this.$refs.logicTabs.remove(index)
    },
  },
}
</script>

<style src="@vueform/slider/themes/default.css"></style>
<style scoped>
.slider-red {
  --slider-connect-bg: #dd163d;
  --slider-tooltip-bg: #dd163d;
  --slider-handle-ring-color: #ef444430;
}
</style>
