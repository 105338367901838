<template>
  <layout :title="form.name">
    <div class="mb-6 flex max-w-lg items-center justify-between">
      <h1 class="text-xl font-bold">
        <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('elearnings')">
          {{ $t('e-Learning') }}
        </InertiaLink>
        <span class="font-medium text-blue-600">/</span>
        {{ form.name }}
      </h1>
      <InertiaLink v-if="related_contentable" class="btn-blue" :href="route('elearnings.edit', related_contentable.id)">
        {{ $t('Edit Related e-Learning (:lang)', { lang: related_contentable.lang }) }}
      </InertiaLink>
      <InertiaLink v-else class="btn-blue" :href="route('elearnings.create', { contentable: elearning.id })">
        {{ $t('Create Related e-Learning') }}
      </InertiaLink>
    </div>
    <div class="flex w-full flex-col gap-x-8 md:flex-row">
      <div class="w-full max-w-lg overflow-hidden rounded bg-white shadow">
        <form @submit.prevent="submit">
          <div class="-mb-8 -mr-6 flex flex-wrap p-8">
            <div class="w-full pb-8 pr-6">
              <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name (Internal)')" />
            </div>
            <div class="w-full pb-8 pr-6">
              <text-input v-model="form.title" :error="form.errors.title" :label="$t('Title')" />
            </div>
            <div class="w-full pb-8 pr-6">
              <textarea-input
                v-model="form.description"
                :autosize="true"
                :error="form.errors.description"
                :label="$t('Description')"
                rows="2"
              />
            </div>
          </div>
          <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
            <button class="text-red-500 hover:underline" tabindex="-1" type="button" @click="destroy">
              {{ $t('Delete e-Learning') }}
            </button>
            <loading-button class="btn-blue" :loading="form.processing" type="submit">
              {{ $t('Update e-Learning') }}
            </loading-button>
          </div>
        </form>
      </div>
      <div class="mt-8 w-full space-y-4 md:mt-0 md:max-w-sm">
        <card class="p-0" name="Courses">
          <div class="flex w-full flex-col">
            <div v-for="course in courses" :key="course.id" class="border-b last:border-b-0">
              <InertiaLink class="block w-full p-4" :href="route('courses.edit', course.id)">
                {{ course.name }}
              </InertiaLink>
            </div>
          </div>
        </card>
      </div>
    </div>
  </layout>
</template>

<script>
import Card from '@/Shared/Card.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Card,
    Layout,
    LoadingButton,
    TextInput,
    TextareaInput,
  },
  props: {
    elearning: Object,
    courses: Array,
    related_contentable: Object,
  },
  data() {
    return {
      form: useForm({
        name: this.elearning.name,
        title: this.elearning.title,
        description: this.elearning.description,
      }),
    }
  },
  methods: {
    submit() {
      this.form.put(this.route('elearnings.update', this.elearning.id))
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this e-Learing?'))) {
        this.$inertia.delete(this.route('elearnings.destroy', this.elearning.id))
      }
    },
  },
}
</script>
