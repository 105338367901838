<template>
  <layout :title="subscription_banner.title">
    <div class="mb-8">
      <breadcrumb
        :name="subscription_banner.name"
        previous-name="Subscription Banners"
        :previous-url="route('subscription-banners')"
      />
    </div>
    <div class="mb-8 w-full">
      <subscription-banner :banner="form" :preview="true" />
    </div>
    <div class="flex max-w-3xl flex-wrap justify-between">
      <div class="w-full lg:w-3/5 xl:w-1/2">
        <div class="overflow-hidden rounded bg-white shadow">
          <form @submit.prevent="submit">
            <div class="-mb-8 -mr-6 flex flex-wrap p-8">
              <div class="flex w-full items-center justify-between pb-8 pr-6">
                <label class="mr-3 cursor-pointer">
                  {{ $t('Activate Banner') }}
                </label>
                <front-small-toggle-input v-model="form.is_active" :error="form.errors.is_active" />
              </div>
              <div class="w-full pb-8 pr-6">
                <text-input
                  v-model="form.name"
                  :error="form.errors.name"
                  :label="$t('Name')"
                  :placeholder="$t('Banner')"
                />
              </div>
              <div class="w-full pb-8 pr-6">
                <select-input
                  v-model="form.show_for_subscription_plan_type"
                  :error="form.errors.show_for_subscription_plan_type"
                  :label="$t('Organization Subscription Plan Type')"
                >
                  <option key="none" value="">
                    {{ $t('None') }}
                  </option>
                  <option v-for="(type, index) in banner_subscription_plan_types" :key="index" :value="index">
                    {{ type }}
                  </option>
                </select-input>
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <select-input v-model="form.column_type" :error="form.errors.column_type" :label="$t('Column Layout')">
                  <option
                    v-for="(subscription_banner_column_type, index) in subscription_banner_column_types"
                    :key="index"
                    :value="index"
                  >
                    {{ subscription_banner_column_type }}
                  </option>
                </select-input>
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <label class="form-label">{{ $t('Show on Page(s)') }}</label>
                <Multiselect
                  v-model="form.show_on_page_routes"
                  deselect-label=""
                  :hide-label="true"
                  :hide-selected="true"
                  :label="$t('label')"
                  :multiple="true"
                  name="FrontRoutes"
                  :options="front_routes"
                  :placeholder="$t('Select route(s)')"
                  select-label=""
                  track-by="label"
                >
                  <template #noResult>{{ $t('No route(s) found.') }}</template>
                  <template #noOptions>{{ $t('No route(s) found.') }}</template>
                </Multiselect>
                <div v-if="form.errors.show_on_page_routes" class="form-error">
                  <span v-for="(err, index) in form.errors.show_on_page_routes" :key="index">{{ err }}</span>
                </div>
              </div>
              <div class="w-full pb-8 pr-6">
                <text-input v-model="form.tag" :error="form.errors.tag" :label="$t('Tag')" :placeholder="$t('New')" />
              </div>
              <div class="w-full pb-8 pr-6">
                <text-input v-model="form.title" :error="form.errors.title" :label="$t('Title')" />
              </div>
              <div class="w-full pb-8 pr-6">
                <text-input v-model="form.subtitle" :error="form.errors.subtitle" :label="$t('Subtitle')" />
              </div>
              <div class="w-full pb-8 pr-6">
                <textarea-input
                  v-model="form.features"
                  :error="form.errors.features"
                  :label="$t('Features')"
                  :placeholder="$t('For eg: Feature One | Feature Two')"
                />
                <small class="text-xs text-ts-blue-500">
                  {{ $t("Type features separated by a '|'.") }}
                </small>
              </div>
              <div class="w-full pb-8 pr-6">
                <text-input v-model="form.button_text" :error="form.errors.button_text" :label="$t('Button Text')" />
              </div>
              <div class="w-full pb-8 pr-6">
                <select-input v-model="form.button_route" :error="form.errors.button_route" :label="$t('Button Route')">
                  <option v-for="(route, index) in front_routes" :key="index" :value="route.value">
                    {{ route.label }}
                  </option>
                </select-input>
              </div>
              <div class="w-full pb-8 pr-6">
                <text-input
                  v-model="form.button_description"
                  :error="form.errors.button_description"
                  :label="$t('Button Description')"
                />
              </div>
            </div>
            <div class="flex items-center border-t border-gray-100 bg-gray-100 px-8 py-4">
              <button
                v-if="subscription_banner.deleted_at"
                class="text-red-500 hover:underline"
                tabindex="-1"
                type="button"
                @click="restore"
              >
                {{ $t('Restore') }}
              </button>
              <button v-else class="text-red-500 hover:underline" tabindex="-1" type="button" @click="destroy">
                {{ $t('Delete') }}
              </button>
              <loading-button class="btn-blue ml-auto" :loading="form.processing" type="submit">
                {{ $t('Update Subscription Banner') }}
              </loading-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontSmallToggleInput from '@/Shared/FrontSmallToggleInput.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import SubscriptionBanner from '@/Shared/SubscriptionBanner.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect,
    Breadcrumb,
    FrontSmallToggleInput,
    Layout,
    LoadingButton,
    SelectInput,
    SubscriptionBanner,
    TextInput,
    TextareaInput,
  },
  props: {
    subscription_banner: Object,
    banner_subscription_plan_types: Object,
    front_routes: Array,
    subscription_banner_column_types: Object,
  },
  data() {
    return {
      form: useForm(
        {
          name: this.subscription_banner.name,
          show_for_subscription_plan_type: this.subscription_banner.show_for_subscription_plan_type,
          show_on_page_routes: this.subscription_banner.show_on_page_routes,
          column_type: this.subscription_banner.column_type,
          tag: this.subscription_banner.tag,
          title: this.subscription_banner.title,
          subtitle: this.subscription_banner.subtitle,
          features: this.subscription_banner.features,
          button_text: this.subscription_banner.button_text,
          button_route: this.subscription_banner.button_route,
          button_description: this.subscription_banner.button_description,
          is_active: this.subscription_banner.is_active,
        },
        'SubscriptionBannerEdit'
      ),
    }
  },
  methods: {
    submit() {
      this.form
        .transform((data) => ({
          ...data,
          features:
            data.column_type === this.subscription_banner_column_types.three.toLowerCase() ? data.features : null,
          show_on_page_routes: data.show_on_page_routes?.map((route) => route.value),
        }))
        .put(this.route('subscription-banners.update', this.subscription_banner.id))
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this subscription banner?'))) {
        this.$inertia.delete(this.route('subscription-banners.destroy', this.subscription_banner.id))
      }
    },
    restore() {
      if (confirm(this.$t('Are you sure you want to restore this subscription banner?'))) {
        this.$inertia.put(this.route('subscription-banners.restore', this.subscription_banner.id))
      }
    },
  },
}
</script>
