<template>
  <front-layout :title="$t('Create Enrolment')">
    <div class="mb-8">
      <breadcrumb
        class="font-heading text-2xl font-semibold"
        :name="$t('Assign Training')"
        :previous-name="$t('Courses')"
        :previous-url="route('front.manager.courses')"
      />
    </div>
    <div class="max-w-lg rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="flex flex-wrap p-8">
          <div class="mt-8 w-full">
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <div class="mb-1 flex items-center justify-between">
                <label class="form-label">{{ $t('Users:') }}</label>
                <a class="link block text-sm" @click="showCreateUserModal = true">{{ $t('Add a New User') }}</a>
              </div>
              <Multiselect
                v-model="form.users"
                deselect-label=""
                :disabled="!organization"
                :hide-label="true"
                :hide-selected="true"
                :label="$t('name')"
                :limit="3"
                :loading="isSearching"
                :multiple="true"
                :name="$t('Users')"
                :options="usersList"
                :placeholder="$t('Search users')"
                select-label=""
                track-by="id"
                @search-change="searchUsers"
              >
                <template #noResult>{{ $t('No users found.') }}</template>
                <template #noOptions>{{ $t('No users found.') }}</template>
              </Multiselect>
              <label v-if="form.errors.users" class="form-error mt-2 block">
                {{ form.errors.users }}
              </label>
            </div>
          </div>
          <div class="w-full">
            <div class="flex items-center justify-between">
              <div class="">
                <span class="mr-7">&nbsp;</span>
                <span class="font-bold">{{ $t('Courses') }}</span>
              </div>
              <div class="w-24 font-bold">{{ $t('Credits') }}</div>
            </div>
            <div v-for="course in courses" :key="course.id" class="mt-8 flex items-center justify-between">
              <div>
                <input
                  :id="'credit-' + course.id"
                  v-model="form.courses"
                  class="form-checkbox mr-4 bg-white"
                  :class="{ 'form-input.error': form.errors[`courses.${course.id}`] }"
                  type="checkbox"
                  :value="course.id"
                  @change="onChange(course.id)"
                />
                <label :for="'credit-' + course.id">{{ course.name }}</label>
                <br />
                <label v-if="true || form.errors[`courses.${course.id}`]" class="form-error mt-2 block">
                  {{ form.errors[`courses.${course.id}`] }}
                </label>
              </div>
              <div class="w-24">
                {{ course.unassigned_enrolments_count }}
              </div>
            </div>
          </div>
          <div v-if="courses.length === 0" class="mt-8">
            <div class="ml-9">{{ $t('No credits found.') }}</div>
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 px-8 py-4">
          <front-loading-button :loading="form.processing" type="submit">
            {{ $t('Assign Training') }}
          </front-loading-button>
        </div>
      </form>
      <create-user-modal :show="showCreateUserModal" @close="closeUserCreateModal" />
    </div>
  </front-layout>
</template>

<script>
import CreateUserModal from './CreateUserModal.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import Http from '@/Utils/Http'
import { useForm } from '@inertiajs/vue3'
import Multiselect from 'vue-multiselect'

export default {
  components: { Multiselect, CreateUserModal, Breadcrumb, FrontLayout, FrontLoadingButton },
  props: {
    courses: Array,
    organization: Object,
    users: Object,
  },
  data() {
    return {
      form: useForm({
        courses: [],
        users: [],
      }),
      isSearching: false,
      usersList: this.users.data,
      showCreateUserModal: false,
    }
  },
  methods: {
    submit() {
      this.form
        .transform((data) => {
          var transformedCourseData = {}
          var transformedUserData = {}
          data.courses.forEach((courseId) => {
            transformedCourseData[courseId] = { id: courseId }
          })
          data.users.forEach((user) => {
            transformedUserData[user.id] = { id: user.id }
          })
          return {
            courses: transformedCourseData,
            users: transformedUserData,
          }
        })
        .post(this.route('front.manager.courses.enrol.step1.store'))
    },
    onChange(courseId) {
      this.form.clearErrors(`courses.${courseId}`)
    },
    searchUsers(query) {
      this.isSearching = true
      Http.get(this.route('front.manager.users.search'), { params: { search: query } })
        .then((response) => {
          this.usersList = response.data.data
        })
        .finally(() => {
          this.isSearching = false
        })
    },
    closeUserCreateModal(user) {
      if (user) {
        this.usersList.push(user)
        this.form.users.push(user)
      }
      this.showCreateUserModal = false
    },
  },
}
</script>
