<template>
  <transition name="fade">
    <div v-show="show" class="fixed inset-0 z-50 flex max-h-screen w-full items-center justify-center p-4">
      <div class="absolute h-screen w-full bg-black opacity-25" @click.self="close" />
      <div
        class="relative w-full overflow-x-hidden rounded-xl bg-white shadow-lg"
        :class="{ 'f-gull p-4': fullHeight }"
        :style="{ width: width }"
      >
        <button class="absolute right-0 top-0 pb-1 pl-1 pr-3 pt-3" @click.stop="close">
          <icon class="block h-3 w-3 fill-gray-400" name="close" />
        </button>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import Icon from './Icon.vue'

export default {
  components: {
    Icon,
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    width: {
      required: true,
      type: String,
    },
    modalClasses: {
      type: String,
      default: '',
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  watch: {
    show() {
      this.updateBodyScroll()
    },
  },
  beforeMount() {
    this.updateBodyScroll()
  },
  beforeUnmount() {
    this.updateBodyScroll()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    updateBodyScroll() {
      if (this.show) {
        return document.querySelector('body').classList.add('overflow-hidden')
      }
      return document.querySelector('body').classList.remove('overflow-hidden')
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
