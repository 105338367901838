<template>
  <form @submit="onSubmit">
    <styled-modal max-width="sm" :show="show" @close="close">
      <template #title>{{ $t('Cancel Subscription') }}</template>

      <template #content>
        <div class="max-w-sm">
          <radio-input
            v-model="form.cancel_type"
            :data="{ Immediately: 'immediately', 'End of the current period': 'endOfBillingPeriod' }"
            label="When would you like to cancel?"
            :resolve="(value) => value"
            type="radio"
          />
        </div>
      </template>

      <template #footer>
        <div class="flex flex-wrap justify-end space-y-2 sm:space-x-4 sm:space-y-0">
          <button class="w-full px-6 py-2 text-gray-500 sm:w-auto" @click.prevent="close">{{ $t('Nevermind') }}</button>
          <loading-button class="btn-cancel" type="submit">
            <span>{{ $t('Cancel Subscription') }}</span>
          </loading-button>
        </div>
      </template>
    </styled-modal>
  </form>
</template>
<script>
import LoadingButton from '@/Shared/LoadingButton.vue'
import RadioInput from '@/Shared/RadioInput.vue'
import StyledModal from '@/Shared/StyledModal.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    LoadingButton,
    RadioInput,
    StyledModal,
  },
  props: {
    show: Boolean,
    organization: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: useForm({
        cancel_type: 'endOfBillingPeriod',
      }),
    }
  },
  methods: {
    onSubmit() {
      switch (this.form.cancel_type) {
        case 'endOfBillingPeriod':
          this.cancelAtTheEndOfBillingPeriod()
          break
        case 'immediately':
          this.cancelImmediately()
          break
      }
    },
    cancelAtTheEndOfBillingPeriod() {
      this.$inertia.post(this.route('organizations.subscriptions.cancel', [this.organization.id]))
    },
    cancelImmediately() {
      this.$inertia.post(this.route('organizations.subscriptions.stop', [this.organization.id]))
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>
