<template>
  <layout :title="$t('Create Course Category')">
    <div class="mb-8">
      <breadcrumb :name="$t('Create')" previous-name="Categories" :previous-url="route('course-categories')" />
    </div>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" />
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Create Course Category') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    Layout,
    LoadingButton,
    TextInput,
  },
  data() {
    return {
      form: useForm(
        {
          name: null,
        },
        'CourseCategoryCreate'
      ),
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('course-categories.store'))
    },
  },
}
</script>
