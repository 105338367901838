<template>
  <styled-modal class="rounded text-base" max-width="md" :show="show" @close="close">
    <template #title>
      <h3 class="mt-4 text-center text-xl font-semibold leading-snug">{{ $t('Move Pages') }}</h3>
    </template>
    <template #content>
      <p class="mb-5 text-sm text-gray-600">Drag and drop to reorder pages, and save</p>
      <Draggable :list="pages" tag="div" @end="onDragEnd">
        <div
          v-for="element in pages"
          :key="element.uuid"
          class="border-1 border-gray my-4 flex flex-row items-center bg-white py-2 text-gray-600 shadow"
        >
          <Bars3Icon aria-hidden="true" class="handle z-20 mx-3 flex h-6 w-6 cursor-move items-center justify-center" />
          <p class="text-sm">{{ element.title }}</p>
        </div>
      </Draggable>
    </template>
    <template #footer>
      <button @click="close">{{ $t('Close') }}</button>
    </template>
  </styled-modal>
</template>

<script>
import StyledModal from './StyledModal.vue'
import { Bars3Icon } from '@heroicons/vue/24/outline'
import { VueDraggableNext as Draggable } from 'vue-draggable-next'

export default {
  components: { Draggable, Bars3Icon, StyledModal },
  inject: ['saveChanges'],
  props: {
    pages: Array,
    show: Boolean,
  },
  methods: {
    close() {
      this.$emit('close')
    },
    onDragEnd() {
      this.saveChanges()
    },
  },
}
</script>
