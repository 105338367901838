<template>
  <front-layout :title="$t('Enrolment Transfer')">
    <div class="sm:no-flex mb-8 flex items-center justify-between">
      <breadcrumb :name="$t('Enrolment Transfer')" />
    </div>
    <div class="max-w-md overflow-hidden rounded-lg border border-gray-400 bg-white p-6 lg:p-10">
      <div class="flex flex-wrap">
        <div class="w-full pb-8 pr-6 sm:w-1/2">
          <front-text-show :label="$t('Sender Name')" :value="enrolmentTransfer.sender.name" />
        </div>
        <div class="w-full pb-8 pr-6 sm:w-1/2">
          <front-text-show :label="$t('Sender Email')" :value="enrolmentTransfer.sender.email" />
        </div>
        <div class="w-full pb-8 pr-6 sm:w-1/2">
          <front-text-show :label="$t('Date')" :value="enrolmentTransfer.created_at" />
        </div>
        <div class="w-full pb-8 pr-6">
          <span class="form-label mb-1 font-semibold text-ts-front-label">{{ $t('Enrolments') }}</span>
          <div
            v-for="(enrolment, key, i) in enrolmentTransfer.enrolments"
            :key="key"
            class="flex justify-start"
            :class="i !== Object.keys(enrolmentTransfer.enrolments).length - 1 ? 'pb-4' : ''"
          >
            <div>{{ enrolment[0].course.name }}</div>
            <div>
              <span class="rounded px-2 py-1">x {{ enrolment.length }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex w-full flex-row justify-end">
        <front-outline-button-link :href="route('front.enrolments.index')">
          {{ $t('Ignore') }}
        </front-outline-button-link>
        <front-loading-button class="ml-2" @click="acceptTransfer">{{ $t('Accept') }}</front-loading-button>
      </div>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontOutlineButtonLink from '@/Shared/FrontOutlineButtonLink.vue'
import FrontTextShow from '@/Shared/FrontTextShow.vue'

export default {
  components: {
    Breadcrumb,
    FrontLayout,
    FrontLoadingButton,
    FrontOutlineButtonLink,
    FrontTextShow,
  },
  props: {
    enrolmentTransfer: Object,
    acceptRoute: String,
  },
  methods: {
    acceptTransfer() {
      this.$inertia.post(this.acceptRoute)
    },
    status() {
      switch (this.enrolmentTransfer.transfer_status) {
        case 'pending':
          return 'Pending'
        case 'expired':
          return 'Expired'
      }
    },
  },
}
</script>
