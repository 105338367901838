<template>
  <transition name="modal">
    <div v-if="show" class="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-75 px-4">
      <div class="w-full max-w-lg rounded-lg bg-white p-6 shadow-lg">
        <div class="mb-4 flex items-center justify-between">
          <h2 class="w-full text-center text-xl font-semibold">{{ $t('Create a New Form') }}</h2>
          <button class="text-2xl text-gray-600 hover:text-gray-900" @click="closeModal">&times;</button>
        </div>
        <div class="mt-4 grid grid-cols-2 gap-4">
          <!-- Blank Form -->
          <div
            class="flex min-h-24 cursor-pointer flex-col items-center justify-center rounded-lg border px-4 py-8 text-center hover:bg-gray-100"
            @click="createBlankForm"
          >
            <icon class="mb-4 inline h-8 w-8" name="document" />
            <span>{{ $t('Blank Form') }}</span>
          </div>

          <!-- Select From Library -->
          <div
            class="flex min-h-24 cursor-pointer flex-col items-center justify-center rounded-lg border px-4 py-8 text-center hover:bg-gray-100"
            @click="openTemplateLibrary"
          >
            <icon class="mb-4 inline h-8 w-8" name="view-list" />
            <span>{{ $t('Select From Library') }}</span>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Icon from '@/Shared/Icon.vue'

export default {
  components: {
    Icon,
  },
  props: {
    show: Boolean,
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    createBlankForm() {
      this.$inertia.post(this.route('front.admin.form-templates.store'))
      this.closeModal()
    },
    openTemplateLibrary() {
      this.$emit('open-template-library')
      this.closeModal()
    },
  },
}
</script>
