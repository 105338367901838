<template>
  <front-layout :title="$t('Select a Class')">
    <div class="flex flex-col md:flex-row">
      <div class="w-full md:w-1/2">
        <breadcrumb class="hidden md:inline" :name="$t('Select a class from the list')" />
        <breadcrumb class="md:hidden" :name="$t('Select a class')" />
        <InertiaLink
          class="text-darkGray flex items-center px-3 pt-4 hover:font-semibold"
          :href="route('front.manager.users.show', enrolment.user_id)"
        >
          <icon class="block h-3 w-3 fill-current" name="arrow-left" />
          <span class="ml-1">{{ $t('Back') }}</span>
        </InertiaLink>
      </div>
      <div class="flex w-full items-center justify-end md:w-1/2">
        <div class="mt-6 w-full md:mt-0 md:w-1/2">
          <select
            v-model="searchFilters.location"
            class="form-select mt-1 inline w-full rounded-lg border border-ts-front-border-light bg-ts-front-field-light font-bold text-gray-700 focus:border-ts-front-border-dark focus:text-gray-800 focus:outline-none"
          >
            <option disabled selected :value="null">{{ $t('Filter Location') }}</option>
            <option :value="null">{{ $t('All') }}</option>
            <option v-for="location in locations" :key="location.id" :value="location.id">
              {{ location.city }}, {{ location.region }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div>
      <div class="-mb-8 -mr-8 mt-3 flex w-full flex-wrap md:mt-8">
        <div class="w-full overflow-x-auto rounded bg-white shadow-lg">
          <table class="front-table w-full">
            <thead>
              <tr>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold" />
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
                  {{ $t('Location') }}
                </th>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
                  {{ $t('Language') }}
                </th>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
                  {{ $t('Available Seats') }}
                </th>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">{{ $t('Sessions') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="classes.data.length === 0" class="h-24">
                <td class="border-t px-6 py-4" colspan="5">{{ $t('No results found.') }}</td>
              </tr>
              <tr
                v-for="class_ in classes.data"
                :key="class_.id"
                class="mt-6 h-24 align-top hover:bg-gray-100"
                @click="form.selectedClass = class_.id"
              >
                <td class="flex items-center md:table-cell">
                  <front-row-select-radiobox v-model="form.selectedClass" name="class-select" :value="class_.id" />
                </td>
                <td>
                  <div>
                    <span class="font-bold text-ts-gray-text-bold">{{ class_.location.name }}</span>
                    <span v-if="class_.room" class="font-semibold text-ts-gray-text-bold">
                      ({{ class_.room.name }})
                    </span>
                  </div>
                  <p class="pt-2">{{ class_.location.address }}</p>
                </td>
                <td class="capitalize text-ts-gray-text-bold">
                  <div>{{ class_.locale }}</div>
                </td>
                <td class="text-ts-gray-text-bold">
                  <div>{{ class_.available_seats }}</div>
                </td>
                <td class="-pb-2 text-ts-gray-text-bold">
                  <p v-for="(session, index) in class_.sessions" :key="index" class="pb-2">
                    {{ session.date }}
                    <span class="pl-2">{{ session.time }}</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <front-pagination class="flex w-full justify-end" :links="classes.links" :preserve-state="true" />
      </div>
    </div>
    <front-loading-button
      v-if="form.selectedClass"
      class="mt-16"
      :loading="form.processing"
      type="submit"
      @click="submit"
    >
      {{ $t('Confirm Selection') }}
    </front-loading-button>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontPagination from '@/Shared/FrontPagination.vue'
import FrontRowSelectRadiobox from '@/Shared/FrontRowSelectRadiobox.vue'
import Icon from '@/Shared/Icon.vue'
import { useForm } from '@inertiajs/vue3'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    Breadcrumb,
    FrontLayout,
    FrontLoadingButton,
    FrontPagination,
    FrontRowSelectRadiobox,
    Icon,
  },
  props: {
    filters: Object,
    student: Object,
    enrolment: Object,
    enrolmentContent: Object,
    content: Object,
    locations: Array,
    classes: Object,
  },
  data() {
    return {
      searchFilters: {
        location: this.filters.location,
      },
      form: useForm({
        selectedClass: this.student?.class_id,
      }),
    }
  },
  watch: {
    searchFilters: {
      handler: _throttle(function () {
        let query = _pickBy(this.searchFilters)
        this.$inertia.get(
          this.route('front.manager.contents.classes', [this.enrolment.id, this.enrolmentContent.id]),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
  },
  methods: {
    submit() {
      this.form.put(
        this.route('front.manager.contents.classes.update', [
          this.enrolment.id,
          this.enrolmentContent.id,
          this.form.selectedClass,
        ])
      )
    },
  },
}
</script>

<style scoped>
.front-table td {
  padding-top: 1.25rem !important;
}
</style>
