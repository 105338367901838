<template>
  <form @submit.prevent="submit">
    <div class="-mb-8 -mr-6 flex flex-wrap p-8">
      <div class="w-full pb-8 pr-6 lg:w-1/2">
        <text-input v-model="form.first_name" :error="form.errors.first_name" :label="$t('First name')" />
      </div>
      <div class="w-full pb-8 pr-6 lg:w-1/2">
        <text-input v-model="form.last_name" :error="form.errors.last_name" :label="$t('Last name')" />
      </div>
      <div class="w-full pb-8 pr-6 lg:w-1/2">
        <text-input v-model="form.email" :error="form.errors.email" :label="$t('Email')" />
      </div>
      <div class="w-full pb-8 pr-6 lg:w-1/2">
        <text-input v-model="form.username" :error="form.errors.username" :label="$t('Username')" />
      </div>
      <div class="w-full pb-8 pr-6 lg:w-1/2">
        <text-input v-model="form.phone" :error="form.errors.phone" :label="$t('Phone')" />
      </div>
      <div class="w-full pb-8 pr-6 lg:w-1/2">
        <text-input
          v-model="form.password"
          autocomplete="new-password"
          :error="form.errors.password"
          :label="$t('Password')"
          type="password"
        />
      </div>
      <div class="w-full pb-8 pr-6 lg:w-1/2">
        <label class="form-label">{{ $t('Groups:') }}</label>
        <Multiselect
          v-model="form.groups"
          deselect-label=""
          :disabled="!groups"
          :hide-label="true"
          :hide-selected="true"
          :label="$t('name')"
          :loading="isLoading"
          :multiple="true"
          :name="$t('Groups')"
          :options="groups"
          :placeholder="$t('Select groups')"
          select-label=""
          track-by="id"
          @search-change="searchGroups"
        >
          <template #noResult>{{ $t('No groups found.') }}</template>
          <template #noOptions>{{ $t('No groups found.') }}</template>
        </Multiselect>
      </div>
      <div class="w-full pb-8 pr-6 lg:w-1/2">
        <organization-role-dropdown v-model="form.organization_role" :error="form.errors.organization_role" />
      </div>
    </div>
    <div class="flex flex-col items-center justify-between border-t border-gray-100 px-8 py-6 md:flex-row md:py-4">
      <div class="flex items-center">
        <input id="notify-manager" v-model="form.notify_manager" class="form-checkbox mr-3" type="checkbox" />
        <label for="notify-manager">{{ $t('Email myself login details') }}</label>
      </div>
    </div>
  </form>
</template>

<script>
import OrganizationRoleDropdown from '@/Shared/OrganizationRoleDropdown.vue'
import TextInput from '@/Shared/TextInput.vue'
import Http from '@/Utils/Http'
import Multiselect from 'vue-multiselect'

export default {
  components: { Multiselect, OrganizationRoleDropdown, TextInput },
  props: {
    regions: {
      type: Array,
      default: function () {
        return ['Ontario']
      },
    },
    defaultRole: String,
    modelValue: {
      type: Object,
      validator: function (value) {
        return ['first_name', 'last_name', 'email'].every((key) => Object.prototype.hasOwnProperty.call(value, key))
      },
    },
  },
  emits: ['update:modelValue', 'submit'],
  data() {
    return {
      isLoading: false,
      groups: [],
      form: this.modelValue,
    }
  },
  watch: {
    form: function () {
      this.$emit('update:modelValue', this.form)
    },
  },
  methods: {
    submit() {
      this.$emit(
        'submit',
        this.form.transform((data) => ({
          ...data,
          groups: data.groups ? data.groups.map((group) => group.id) : [],
        }))
      )
    },
    searchGroups(query) {
      this.isLoading = true
      Http.get(this.route('front.manager.groups.search'), { params: { search: query } })
        .then((response) => {
          this.groups = response.data.data
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
