<template>
  <styled-modal max-width="sm" :show="show" @close="close">
    <template #title>{{ $t('Manager Signature Request') }}</template>

    <template #content>
      <div class="max-w-sm">
        <div class="-mb-8 -mr-6 flex flex-col p-2">
          <div class="w-full pb-8 pr-6">
            <div class="space-y-1">
              <form @submit="submit">
                <label class="form-label">{{ $t('User:') }}</label>
                <dynamic-search-input
                  v-model="form.user"
                  class="mb-2 w-full"
                  :error="form.errors.user"
                  required
                  :search-by="['name', 'email']"
                  track-by="id"
                  :url="route('front.users.search', { onlyManagers: true })"
                >
                  <div v-if="form.user" class="flex items-center justify-between">
                    <div class="truncate">{{ form.user.name }}</div>
                    <div class="whitespace-nowrap text-xs text-gray-600">
                      {{ form.user.email }}
                    </div>
                  </div>
                  <template #option="{ option, selected }">
                    <div class="flex items-center justify-between">
                      <div>{{ option.name }}</div>
                      <div class="text-xs" :class="{ 'text-gray-600': !selected, 'text-white': selected }">
                        {{ option.email }}
                      </div>
                    </div>
                  </template>
                </dynamic-search-input>
              </form>
            </div>
          </div>
          <div class="w-full pb-8 pr-6">
            <front-textarea-input v-model="form.message" :error="form.errors.message" :label="$t('Message')" />
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex flex-wrap justify-end space-y-2 sm:space-x-4 sm:space-y-0">
        <button class="w-full px-6 py-2 text-gray-500 sm:w-auto" @click.prevent="close">{{ $t('Nevermind') }}</button>
        <loading-button
          class="btn-red-gradient w-full justify-center rounded-full px-10 sm:w-auto"
          :loading="form.processing"
          type="button"
          @click="submit"
        >
          <span>{{ $t('Send') }}</span>
        </loading-button>
      </div>
    </template>
  </styled-modal>
</template>

<script>
import DynamicSearchInput from '@/Shared/DynamicSearchInput.vue'
import FrontTextareaInput from '@/Shared/FrontTextareaInput.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import StyledModal from '@/Shared/StyledModal.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    DynamicSearchInput,
    FrontTextareaInput,
    LoadingButton,
    StyledModal,
  },
  props: {
    show: Boolean,
    certificate: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: useForm({
        user: null,
        message: null,
      }),
    }
  },
  methods: {
    close() {
      this.form.reset()
      this.form.clearErrors()
      this.$emit('close')
    },
    submit() {
      this.form
        .transform((data) => {
          return {
            email: data.user?.email,
            message: data.message,
          }
        })
        .post(this.route('front.certificates.organization-signature-request.store', this.certificate?.id), {
          onSuccess: () => this.close(),
        })
    },
  },
}
</script>
