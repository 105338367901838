<template>
  <guest-layout :tenant="tenant" :title="$t('Certificate Validation')">
    <div class="w-full">
      <div class="flex justify-center py-3">
        <div v-if="!validated && !certificate" class="w-full md:w-2/3 lg:w-1/2">
          <div
            class="mb-8 flex w-full flex-col justify-center border-b-2 pb-5 md:flex-row md:items-center md:justify-between"
          >
            <div class="text-center text-2xl font-semibold text-gray-700 opacity-75 md:text-left">
              {{ $t('Certificate Validation') }}
            </div>
            <div class="mb-3 mt-5 flex justify-center md:mb-0 md:mt-0">
              <front-button-link class="font-bold" href="https://worksitesafety.ca">
                {{ $t('Back to Worksite.ca') }}
              </front-button-link>
            </div>
          </div>
          <div class="mt-8 w-full px-4 pb-20 md:mt-12 md:px-0">
            <div class="text-center text-3xl font-semibold leading-10 text-gray-800 md:text-4xl">
              {{ $t('Check Certification Validation and Expiry') }}
            </div>
            <div class="mt-6 text-center text-base font-semibold leading-8 text-gray-800 md:mt-8 md:px-12 md:text-lg">
              {{
                $t(
                  "Search for certification credentials for any certificates issued by Worksite Safety using the certificate owner's last name, course name and certification number."
                )
              }}
            </div>
            <div class="mx-auto mt-8 max-w-sm rounded-lg bg-white p-6 shadow-lg">
              <form class="flex w-full flex-col justify-center" @submit.prevent="submit">
                <div
                  v-if="$page.props.errors['token']"
                  class="mb-5 flex items-center justify-between rounded bg-red-400"
                >
                  <div class="flex items-center">
                    <icon class="ml-4 mr-2 h-4 w-4 flex-shrink-0 fill-white" name="close-outline" />
                    <div class="py-4 text-sm font-medium text-white">
                      <span>{{ $page.props.errors['token'] }}</span>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col px-6">
                  <div class="w-full p-3">
                    <label class="mb-2 block text-center text-darkGray-700" for="last-name">
                      {{ $t('Last Name*') }}
                    </label>
                    <input
                      id="last-name"
                      v-model="form.name"
                      class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 text-center transition duration-150 ease-in-out"
                      required
                      type="text"
                    />
                  </div>
                  <div class="w-full p-3">
                    <label class="mb-2 block text-center text-darkGray-700" for="certificate-number">
                      {{ $t('Certificate Number*') }}
                    </label>
                    <input
                      id="certificate-number"
                      v-model="form.certificate"
                      class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 text-center transition duration-150 ease-in-out"
                      required
                      type="text"
                    />
                  </div>
                </div>
                <div class="mt-6 flex justify-center px-12">
                  <front-loading-button :loading="form.processing" type="submit">
                    {{ $t('Search Certificates') }}
                  </front-loading-button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div v-else-if="validated && !!certificate" class="w-full md:w-2/3 lg:w-1/2">
          <div class="my-8 w-full border-b-2 pb-5 text-center text-3xl font-semibold text-gray-800">
            {{ certificate.is_expired ? $t('Inactive Certificate Found!') : $t('Active Certificate Found!') }}
          </div>
          <div class="mt-8 w-full px-4 pb-20 md:mt-12 md:px-0">
            <div class="mt-8 flex w-full rounded-xl bg-white shadow-lg">
              <div
                class="flex w-1/5 items-center rounded-l-xl"
                :class="certificate.is_expired ? 'bg-qualify-red-600' : 'bg-ts-blue-600'"
              >
                <icon
                  v-if="certificate.is_expired"
                  class="mx-auto h-10 w-10 flex-shrink-0 rounded-full border border-white fill-white p-1"
                  name="close"
                />
                <icon
                  v-else
                  class="mx-auto h-10 w-10 flex-shrink-0 rounded-full border border-white fill-white p-1"
                  name="checkmark"
                />
              </div>
              <div class="flex flex-1">
                <div class="w-full p-6">
                  <p class="mb-2 text-2xl font-semibold leading-10 text-gray-800">
                    {{ certificate.course.name }}
                  </p>
                  <p class="mb-5 text-lg leading-6 text-gray-700 opacity-75">
                    {{ $t('Certificate ID: :id', { id: certificate.id }) }}
                  </p>
                  <table>
                    <tbody>
                      <tr>
                        <td>{{ $t('First Name:') }}</td>
                        <td>
                          <span class="ml-2 text-gray-700 opacity-75">{{ certificate.user.first_name }}</span>
                        </td>
                      </tr>
                      <tr class="">
                        <td class="pt-2">{{ $t('Last Name:') }}</td>
                        <td class="pt-2">
                          <span class="ml-2 text-gray-700 opacity-75">{{ certificate.user.last_name }}</span>
                        </td>
                      </tr>
                      <tr v-if="certificate.expires_at">
                        <td class="pt-2">{{ $t('Expiry:') }}</td>
                        <td class="pt-2">
                          <span
                            class="ml-2 rounded px-1 pt-1 text-sm"
                            :class="certificate.is_expired ? 'text-red-600' : 'text-ts-blue-500'"
                            :style="certificate.is_expired ? 'background:#FAE8EB;' : 'background:#ECF0F2;'"
                          >
                            {{ certificate.expires_at }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="w-full md:w-2/3 lg:w-1/2">
          <div class="my-8 w-full border-b-2 pb-2 text-center text-3xl font-semibold text-gray-800">
            {{ $t('No Certificate Found!') }}
            <a
              class="my-3 flex cursor-pointer items-center justify-center text-base text-ts-blue-500 hover:underline"
              :href="route('certificate-validation.show')"
            >
              {{ $t('Try searching again') }}
              <icon class="block h-5 w-5 fill-ts-blue-500" name="chevron-right" />
            </a>
          </div>
          <div class="mt-8 w-full px-4 pb-20 md:mt-12 md:px-0">
            <div class="mt-8 flex w-full rounded-xl bg-white shadow-lg">
              <div
                class="flex w-1/5 items-center rounded-l-xl"
                :class="isTenantWorksite ? 'bg-qualify-red-500' : 'bg-blue-700'"
              >
                <icon
                  class="mx-auto h-10 w-10 flex-shrink-0 rounded-full border border-white fill-white p-1"
                  name="close"
                />
              </div>
              <div class="flex flex-1">
                <div class="w-full p-6">
                  <div class="mb-2 h-8 w-full rounded-full lg:w-3/4" style="background: #ced1d3" />
                  <div class="mb-5 h-5 w-64 rounded-full bg-gray-400" style="background: #ced1d3" />
                  <div class="flex w-full flex-col">
                    <div class="flex w-full items-center justify-start">
                      <div class="mb-2 h-5 w-24 rounded-full bg-gray-400" style="background: #ced1d3" />
                      <div class="mb-2 ml-6 h-5 w-24 rounded-full bg-gray-400" style="background: #ced1d3" />
                    </div>
                    <div class="flex items-center justify-start">
                      <div class="mb-2 h-5 w-24 rounded-full bg-gray-400" style="background: #ced1d3" />
                      <div class="mb-2 ml-6 h-5 w-24 rounded-full bg-gray-400" style="background: #ced1d3" />
                    </div>
                    <div class="flex items-center justify-start">
                      <div class="mb-2 h-5 w-24 rounded-full bg-gray-400" style="background: #ced1d3" />
                      <div class="mb-2 ml-6 h-5 w-24 rounded-full bg-gray-400" style="background: #ced1d3" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-10 mt-12 w-full text-center font-semibold">
              <p>
                {{ $t('Need some more help? Give us a call at') }}
                <a class="whitespace-nowrap text-ts-blue-500 hover:underline" :href="`tel:${tenant.phone}`">
                  {{ tenant.phone }}
                </a>
              </p>
              <p class="mt-4">Or click the button below to email our support team.</p>
              <front-solid-button class="mt-8" @click="showContactSupportModal = true">
                {{ $t('Email Support') }}
              </front-solid-button>
            </div>
          </div>
        </div>
      </div>
      <contact-support-modal
        :certificate_templates="certificate_templates"
        :show="showContactSupportModal"
        :tenant="tenant"
        @close="closeContactSupportModal"
      />
    </div>
  </guest-layout>
</template>

<script>
import ContactSupportModal from './ContactSupportModal.vue'
import FrontButtonLink from '@/Shared/FrontButtonLink.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontSolidButton from '@/Shared/FrontSolidButton.vue'
import GuestLayout from '@/Shared/GuestLayout.vue'
import Icon from '@/Shared/Icon.vue'
import { isTenantWorksite, query } from '@/Utils/Helpers'

export default {
  components: { ContactSupportModal, GuestLayout, FrontButtonLink, FrontLoadingButton, FrontSolidButton, Icon },
  props: {
    tenant: Object,
    certificate: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      validated: false,
      showContactSupportModal: false,
      form: {
        name: null,
        certificate: null,
      },
    }
  },
  computed: {
    isTenantWorksite,
  },
  mounted() {
    let urlParams = new URLSearchParams(window.location.search)
    this.validated = urlParams.has('certificate')
  },
  methods: {
    query,
    submit() {
      this.$inertia.get(this.route('certificate-validation.show') + this.query({ ...this.form }))
    },
    closeContactSupportModal() {
      this.showContactSupportModal = false
    },
  },
}
</script>
