<template>
  <front-layout :title="$t('Courses')">
    <div class="flex flex-col xl:flex-row xl:justify-between">
      <div class="sm:no-flex mb-8 flex w-full items-center justify-between">
        <breadcrumb :name="$t('Courses')" />
      </div>
      <div class="mb-6 flex items-center justify-between">
        <purchase-link class="hidden sm:mr-4 sm:inline">{{ $t('Purchase Training') }}</purchase-link>
        <front-button-link
          v-if="subscriptionPlans.length === 0"
          class="hidden sm:mr-4 sm:inline"
          :href="route('front.manager.courses.enrol.step1')"
        >
          <span>{{ $t('Assign Training') }}</span>
        </front-button-link>
        <front-dropdown v-else class="relative" placement="bottom-end">
          <div class="relative z-10 flex cursor-pointer select-none items-center">
            <span class="mr-3">{{ $t('Actions') }}</span>
            <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                clip-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                fill-rule="evenodd"
              />
            </svg>
          </div>
          <template #dropdown>
            <div class="relative">
              <div class="absolute right-0 z-20 mt-2 w-48 rounded-md bg-white py-2 shadow-xl">
                <front-dropdown-option>
                  <InertiaLink :href="route('front.manager.courses.enrol.step1')">
                    <span>{{ $t('Assign Training') }}</span>
                  </InertiaLink>
                </front-dropdown-option>
                <front-dropdown-option>
                  <InertiaLink :href="route('front.manager.subscriptions')">
                    <span>{{ $t('Assign Subscription') }}</span>
                  </InertiaLink>
                </front-dropdown-option>
              </div>
            </div>
          </template>
        </front-dropdown>
      </div>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow-lg">
      <table class="front-table">
        <thead>
          <tr>
            <th>{{ $t('Name') }}</th>
            <th>{{ $t('Total Enrolments') }}</th>
            <th>{{ $t('Assigned') }}</th>
            <th>{{ $t('Available') }}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-if="courses.data.length === 0">
            <td class="border-t px-6 py-4" colspan="5">{{ $t('No courses found.') }}</td>
          </tr>
          <tr
            v-for="course in courses.data"
            :key="course.id"
            class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
          >
            <td class="font-semibold text-ts-gray-text-bold" @click="openReport(course.id)">{{ course.name }}</td>
            <td @click="openReport(course.id)">{{ course.total_enrolments_count }}</td>
            <td @click="openReport(course.id)">{{ usedEnrolmentCount[course.id] }}</td>
            <td @click="openReport(course.id)">{{ course.available_enrolment_count }}</td>
            <td>
              <span
                v-if="course.classroom_contents_count > 0"
                class="font-semibold hover:underline"
                @click="$inertia.visit(route('front.manager.courses-classes', course.id))"
              >
                {{ $t('Manage Classes') }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination :links="courses.links" />
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontButtonLink from '@/Shared/FrontButtonLink.vue'
import FrontDropdown from '@/Shared/FrontDropdown.vue'
import FrontDropdownOption from '@/Shared/FrontDropdownOption.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import Pagination from '@/Shared/Pagination.vue'
import PurchaseLink from '@/Shared/PurchaseLink.vue'

export default {
  components: {
    Breadcrumb,
    FrontButtonLink,
    FrontDropdown,
    FrontDropdownOption,
    FrontLayout,
    Pagination,
    PurchaseLink,
  },
  props: {
    courses: Object,
    wc_shop_url: String,
    subscriptionPlans: Array,
  },
  computed: {
    usedEnrolmentCount() {
      return Object.fromEntries(
        this.courses.data.map((course) => [course.id, course.total_enrolments_count - course.available_enrolment_count])
      )
    },
  },
  methods: {
    openReport(courseId) {
      this.$inertia.visit(this.route('front.manager.reports.courses.show', { table_filters: { course: courseId } }))
    },
  },
}
</script>
