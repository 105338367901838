<template>
  <layout :title="$t('Reports')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('reports')">
        {{ $t('Reports') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Reserved Students(Paid)') }}
    </h1>
    <div class="overflow-x-auto rounded bg-white shadow">
      <table class="w-full">
        <thead>
          <tr>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Course') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Customer') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Date') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="students.length === 0">
            <td class="border-t px-6 py-4" colspan="3">{{ $t('No locations found.') }}</td>
          </tr>
          <tr
            v-for="student in students"
            :key="student.id"
            class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
            @click="$inertia.visit(route('students.edit', student.id))"
          >
            <td class="whitespace-nowrap border-t px-6 py-4">
              {{ student.course.name }}
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <div class="flex items-center">{{ student.customer.first_name }} {{ student.customer.last_name }}</div>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              {{ student.created_at.substring(0, 10) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </layout>
</template>

<script>
import Layout from '@/Shared/Layout.vue'

export default {
  components: {
    Layout,
  },
  props: {
    students: Array,
  },
}
</script>
