<template>
  <front-layout :title="$t('Transfers Sent')">
    <div class="mb-8">
      <breadcrumb class="font-heading text-2xl font-semibold" :name="$t('Transfer Enrolments')" />
    </div>

    <notice v-if="form.courses.length === 0" type="info">
      {{ $t('You do not have any enrolments eligible to transfer.') }}
    </notice>

    <div v-else class="max-w-lg overflow-hidden rounded-lg border border-ts-gray-500 bg-white p-6 lg:p-10">
      <form @submit.prevent="submit">
        <h3 class="mb-8 font-bold">{{ $t('Who do you want to send enrolments to?') }}</h3>
        <div class="mb-8 flex w-full flex-row flex-wrap">
          <div class="w-full pb-8 pr-6">
            <front-text-input v-model="form.name" :error="form.errors.name" :label="$t('Full Name')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <front-text-input
              v-model="form.email"
              :error="form.errors.email"
              :label="$t('Email Address')"
              type="email"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <front-text-input
              v-model="form.email_confirmation"
              :error="form.errors.email_confirmation"
              :label="$t('Confirm Email Address')"
              type="email"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <front-text-input v-model="form.phone" :error="form.errors.phone" :label="$t('Phone Number')" type="tel" />
          </div>
        </div>

        <h3 class="mb-8 font-bold">{{ $t('Select Course Enrolments') }}</h3>

        <notice v-if="form.errors.enrolments" type="danger">
          {{ form.errors.enrolments }}
        </notice>

        <div class="mb-8 w-full lg:w-1/2">
          <div
            v-for="(course, key) in courseList"
            :key="key"
            class="mb-2 flex h-full flex-wrap rounded-lg border border-gray-300 bg-white p-6 hover:cursor-pointer"
            :class="{ 'border-ts-red-500': form.courses[key].selected }"
            @click.stop="toggleSelected(key)"
          >
            <div class="flex w-2/3 items-center justify-start text-gray-600">
              {{ course.name }}
            </div>
            <div class="flex w-1/3 items-center justify-end">
              <div v-if="form.courses[key].selected">
                <span
                  class="px-2 hover:cursor-pointer"
                  :class="{ 'text-gray-300 hover:cursor-default': form.courses[key].quantity === 1 }"
                  @click.stop="decrement(key)"
                >
                  -
                </span>
                <input
                  v-model="form.courses[key].quantity"
                  class="w-6 rounded border border-gray-300 text-center"
                  type="text"
                  @click.stop
                />
                <span
                  class="px-2 hover:cursor-pointer"
                  :class="{ 'text-gray-300 hover:cursor-default': form.courses[key].quantity === course.available }"
                  @click.stop="increment(key)"
                >
                  +
                </span>
              </div>
            </div>
            <div v-if="form.errors[`courses.${key}.id`] && form.courses[key].selected" class="form-error">
              {{ form.errors[`courses.${key}.id`] }}
            </div>
            <div v-if="form.errors[`courses.${key}.quantity`] && form.courses[key].selected" class="form-error">
              {{ form.errors[`courses.${key}.quantity`] }}
            </div>
          </div>
        </div>

        <div class="w-full">
          <front-loading-button class="float-right" :loading="form.processing" type="submit">
            {{ $t('Transfer Enrolments') }}
          </front-loading-button>
        </div>
      </form>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import Notice from '@/Shared/Notice.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    FrontLayout,
    FrontLoadingButton,
    FrontTextInput,
    Notice,
  },
  props: {
    enrolments: Array,
  },
  data() {
    var course_list = {}
    var coursesFormList = {}

    this.enrolments.forEach((enrolment) => {
      course_list[enrolment.course_id] = {
        id: enrolment.course_id,
        name: enrolment.course.name,
        available: enrolment.available,
      }
      coursesFormList[enrolment.course_id] = {
        id: enrolment.course_id,
        quantity: 0,
        selected: false,
      }
    })
    return {
      courseList: course_list,
      form: useForm({
        name: null,
        phone: null,
        email: null,
        email_confirmation: null,
        courses: coursesFormList,
      }),
    }
  },
  methods: {
    submit() {
      if (
        confirm(
          this.$t(
            'Please note: You will not be able to redeem these enrolments for yourself after they have been transferred'
          )
        )
      ) {
        this.form
          .transform((data) => {
            var postCourseData = {}
            Object.keys(data.courses).forEach((key) => {
              if (data.courses[key].selected && data.courses[key].quantity > 0) {
                postCourseData[key] = data.courses[key]
              }
            })
            return {
              name: data.name,
              phone: data.phone,
              email: data.email,
              email_confirmation: data.email_confirmation,
              courses: postCourseData,
            }
          })
          .post(this.route('front.enrolment-transfers.step1.store'))
      }
    },
    toggleSelected(courseId) {
      var formCourse = this.form.courses[courseId]

      formCourse.selected = !formCourse.selected
      formCourse.quantity = formCourse.selected ? 1 : 0
    },
    decrement(courseId) {
      var formCourse = this.form.courses[courseId]

      if (formCourse.quantity > 1) {
        formCourse.quantity--
      }
    },
    increment(courseId) {
      var formCourse = this.form.courses[courseId]
      var course = this.courseList[courseId]

      if (formCourse.quantity < course.available) {
        formCourse.quantity++
      }
    },
  },
}
</script>
