<template>
  <transition name="fade">
    <div
      v-show="show"
      class="fixed bottom-0 z-20 mb-10 flex hidden w-full max-w-xs items-center rounded-xl bg-white shadow-lg lg:block"
      style="right: 10%"
    >
      <div
        v-if="!showQrCode"
        class="flex cursor-pointer flex-row items-center overflow-x-hidden p-4"
        @click.stop="openQrCode"
      >
        <div class="mr-4">
          <div class="rounded-md bg-black px-2 py-6">
            <icon class="h-6 w-6 text-white" name="qrcode" />
          </div>
        </div>
        <div class="pr-4">
          <h1 class="text-md font-semibold">{{ $t('Want to sign on mobile?') }}</h1>
          <p class="mt-2 text-sm">{{ $t('Click here for the QR code') }}</p>
        </div>
      </div>
      <div v-else class="flex w-full flex-col items-center p-4">
        <qrcode-vue class="mx-auto" level="H" :size="200" :value="currentUrl" />
        <span class="mt-2 text-center text-sm">{{ $t('Use the QR code to sign from a different device') }}</span>
      </div>
      <button class="absolute right-0 top-0 mr-3 mt-3" :title="$t('Close')" @click.stop="close">
        <icon class="block h-4 w-4 fill-gray-600 p-px" name="close" />
      </button>
    </div>
  </transition>
</template>

<script>
import Icon from './Icon.vue'
import QrcodeVue from 'qrcode.vue'

export default {
  components: { QrcodeVue, Icon },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      showQrCode: false,
      currentUrl: window.location.href,
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    openQrCode() {
      this.showQrCode = true
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
