<template>
  <div class="grid h-56 gap-6 rounded-lg bg-white p-6 shadow hover:shadow-lg">
    <div>
      <div class="relative flex items-start justify-between">
        <front-subscription-status v-if="subscription.id" :subscription="subscription" />
        <button @click.stop.prevent="onClickMoreDetails">
          <icon class="h-6 w-6 text-darkGray-400" name="cog" />
        </button>
        <front-extra-menu
          class="right-0 -mr-32 mt-4 border border-gray-100 bg-white p-2 text-sm shadow-sm"
          :show="showMenu"
          @close="hideMoreDetails"
        >
          <div class="cursor-pointer bg-white p-2" @click.stop.prevent="assignSubscription(subscription)">
            {{ $t('Assign Subscription') }}
          </div>
          <div class="cursor-pointer bg-white p-2" @click.stop.prevent="showEditSubscriptionModal(subscription)">
            {{ $t('Edit Subscription') }}
          </div>
          <div
            v-if="subscription.can.cancel"
            class="cursor-pointer bg-white p-2 text-ts-red-500"
            @click.stop.prevent="showCancelSubscriptionModal(subscription)"
          >
            {{ $t('Cancel Subscription') }}
          </div>
          <div
            v-if="subscription.can.resume"
            class="cursor-pointer bg-white p-2 text-ts-red-500"
            @click.stop.prevent="showResumeSubscriptionModal(subscription)"
          >
            {{ $t('Resume Subscription') }}
          </div>
        </front-extra-menu>
      </div>
      <div class="mt-2 font-semibold leading-normal">
        <span>{{ subscriptionTitle }}</span>
      </div>
    </div>
    <div class="flex flex-wrap items-center justify-between overflow-x-auto">
      <div class="whitespace-nowrap text-sm font-semibold">
        <div v-if="subscription.trialEndsAt">{{ $t('Trial Period') }}</div>
        <div v-else>{{ $t('Billing Period') }}</div>
        <div v-if="subscription.endsAt" class="mt-1 flex">
          <span class="mr-1 font-normal text-ts-gray-text">{{ $t('Cancels') }}</span>
          <show-date class="font-normal text-ts-gray-text" :timestamp="subscription.currentPeriodEnd" />
        </div>
        <div v-else class="mt-1 flex">
          <show-date class="font-normal text-ts-gray-text" :timestamp="subscription.currentPeriodStart" />
          <span class="px-1 font-normal text-ts-gray-text">-</span>
          <show-date class="font-normal text-ts-gray-text" :timestamp="subscription.currentPeriodEnd" />
        </div>
      </div>
      <div v-if="!subscription.trialEndsAt" class="text-xs text-black">
        <div class="flex">
          <span class="flex pr-1">{{ $t('Next Invoice on') }}</span>
          <show-date format="MMM DD" :timestamp="subscription.currentPeriodEnd" />
        </div>
        <div class="text-right">
          <span class="text-xl font-semibold text-qualify-red-500">{{ subscription.upcomingInvoiceAmount }}</span>
        </div>
      </div>
    </div>
    <edit-subscription-modal
      :show="isEditSubscriptionModal"
      :subscription="subscription"
      @close="hideEditSubscriptionModal"
    />
    <cancel-subscription-modal
      :show="isCancelSubscriptionModal"
      :subscription="subscription"
      @close="hideCancelSubscriptionModal"
    />
  </div>
</template>
<script>
import CancelSubscriptionModal from './CancelSubscriptionModal.vue'
import EditSubscriptionModal from './EditSubscriptionModal.vue'
import FrontExtraMenu from '@/Shared/FrontExtraMenu.vue'
import FrontSubscriptionStatus from '@/Shared/FrontSubscriptionStatus.vue'
import Icon from '@/Shared/Icon.vue'
import ShowDate from '@/Shared/ShowDate.vue'

export default {
  components: {
    EditSubscriptionModal,
    CancelSubscriptionModal,
    FrontExtraMenu,
    FrontSubscriptionStatus,
    Icon,
    ShowDate,
  },
  props: {
    subscription: Object,
  },
  data() {
    return {
      showMenu: false,
      isEditSubscriptionModal: false,
      isCancelSubscriptionModal: false,
      action: null,
    }
  },
  computed: {
    subscriptionTitle() {
      if (this.subscription.items.length > 2) {
        let lastItem = this.subscription.items[this.subscription.items.length - 1]
        return (
          this.subscription.items.map((item) => item.subscriptionPlan.title).join(', ') +
          ' and ' +
          lastItem.subscriptionPlan.title
        )
      } else if (this.subscription.items.length === 2) {
        return (
          this.subscription.items[0].subscriptionPlan.title +
          ' and ' +
          this.subscription.items[1].subscriptionPlan.title
        )
      } else {
        return this.subscription.items.reduce((title, item) => item.subscriptionPlan?.title, '')
      }
    },
  },
  methods: {
    onClickTile() {
      if (this.enrolment.user_id) {
        this.$inertia.visit(this.route('front.contents', this.enrolment.id))
      } else {
        this.$emit('open:unassignedEnrolmentModal', this.enrolment)
      }
    },
    showEditSubscriptionModal() {
      this.showMenu = false
      this.isEditSubscriptionModal = true
    },
    hideEditSubscriptionModal() {
      this.isEditSubscriptionModal = false
    },
    showCancelSubscriptionModal() {
      this.showMenu = false
      this.isCancelSubscriptionModal = true
    },
    hideCancelSubscriptionModal() {
      this.isCancelSubscriptionModal = false
    },
    editSubscription(subscription) {
      this.$inertia.post(this.route('front.admin.subscription.edit', [subscription.items[0].subscriptionPlan.id]))
    },
    stopSubscription(subscription) {
      this.$inertia.post(this.route('front.admin.subscription.stop', [subscription.items[0].subscriptionPlan.id]))
    },
    resumeSubscription(subscription) {
      this.$inertia.post(this.route('front.admin.subscription.resume', [subscription.items[0].subscriptionPlan.id]))
    },
    assignSubscription(subscription) {
      this.$inertia.get(this.route('front.manager.subscriptions.show', subscription.items[0].subscriptionPlan.id))
    },
    onClickMoreDetails() {
      this.showMenu = true
    },
    hideMoreDetails() {
      this.showMenu = false
    },
  },
}
</script>
