<template>
  <FormModal @close="close">
    <template #content>
      <div class="px-4 py-8 sm:px-6">
        <text-input v-model="form.name" class="w-full" :error="form.errors?.name" :label="$t('Name')" />
      </div>
      <div class="px-4 py-2 text-sm sm:px-6">
        <p>{{ $t('Response') }}</p>
      </div>
      <div class="relative bg-white">
        <div class="inline-flex w-full items-center">
          <Draggable class="list-group w-full" handle=".handle" :list="form.choices" tag="ul">
            <li v-for="(element, index) in form.choices" :key="index" class="border-b border-t py-1">
              <div class="flex w-full items-center">
                <Bars3Icon
                  aria-hidden="true"
                  class="handle z-20 mx-3 flex h-6 w-6 flex-none cursor-move items-center justify-center text-gray-400"
                />
                <div class="flex w-full items-center justify-between gap-2" @click="setActiveId(index)">
                  <div class="flex-1">
                    <front-text-input
                      v-model="element.name"
                      class="placeholder-shown:text-bold placeholder-shown:text-m m-0 mt-0 flex w-full border-0 bg-white ring-0 ring-inset placeholder-shown:border-0 placeholder-shown:ring-0 focus:outline-none focus:ring-2 focus:ring-qualify-red-600"
                      :error="form.errors[`choices.${index}.name`]"
                      :placeholder="$t('Response :num', { num: index + 1 })"
                    />
                  </div>
                  <button class="mr-2 cursor-pointer px-2 text-gray-500" :title="$t('Delete')">
                    <TrashIcon aria-hidden="true" class="h-5 w-5" @click="deleteOption(element)" />
                  </button>
                </div>
              </div>
            </li>
          </Draggable>
        </div>
      </div>
      <div class="px-4 py-5 text-sm sm:px-6">
        <button class="inline-flex items-center" @click="addOption">
          <PlusIcon aria-hidden="true" class="mr-1 h-3 w-3" />
          <p>{{ $t('Add Response') }}</p>
        </button>
        <p v-if="form.errors?.choices" class="form-error">{{ form.errors?.choices }}</p>
      </div>
      <div class="flex justify-start gap-2 px-4 py-5 sm:px-6">
        <button class="btn-red" @click="onSave">
          {{ $t('Save') }}
        </button>
        <button class="btn-light-gray-outline px-5" @click="close">
          {{ $t('Cancel') }}
        </button>
      </div>
    </template>
  </FormModal>
</template>
<script>
import FormModal from './Questionables/FormModal.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import { activeId, setActiveId } from '@/Stores/ActiveId'
import { PlusIcon, TrashIcon } from '@heroicons/vue/20/solid'
import { Bars3Icon } from '@heroicons/vue/24/outline'
import { useForm } from '@inertiajs/vue3'
import { VueDraggableNext as Draggable } from 'vue-draggable-next'

export default {
  components: {
    Draggable,
    Bars3Icon,
    PlusIcon,
    TrashIcon,
    FormModal,
    FrontTextInput,
    TextInput,
  },
  inject: {
    formTemplate: {},
  },
  props: {
    formContent: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      default: null,
    },
  },
  emits: ['update:modelValue', 'close'],
  data() {
    return {
      form: useForm({
        ...(this.value || {
          name: '',
          choices: [
            {
              name: '',
            },
            {
              name: '',
            },
          ],
        }),
      }),
      activeId,
    }
  },
  methods: {
    setActiveId,
    close() {
      this.$emit('close')
    },
    addOption() {
      this.form.choices.push({
        name: '',
      })
    },
    deleteOption(option) {
      this.form.choices = this.form.choices.filter((item) => item !== option)
    },
    onSave() {
      if (this.form.id) {
        this.update()
        return
      }
      this.create()
    },
    create() {
      this.form
        .transform((data) => {
          data.form_template_id = this.formTemplate.id
          return data
        })
        .post(this.route('front.admin.response-sets.store'), {
          preserveState: true,
          preserveScroll: true,
          onSuccess: () => {
            if (!this.form.hasErrors) {
              this.form.reset()
              this.$nextTick(() => {
                this.close()
              })
            }
          },
        })
    },
    update() {
      this.form
        .transform((data) => {
          data.form_template_id = this.formTemplate.id
          return data
        })
        .put(
          this.route('front.admin.response-sets.update', {
            select: this.value,
          }),
          {
            preserveState: true,
            preserveScroll: true,
            onSuccess: () => {
              if (!this.form.hasErrors) {
                this.form.reset()
                this.$nextTick(() => {
                  this.close()
                })
              }
            },
          }
        )
    },
  },
}
</script>
