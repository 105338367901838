<template>
  <layout :title="$t('Create Class')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('classes.show', parentClass.id)">
        {{ parentClass.instructor_led_classroom.name }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Create Subclass') }}
    </h1>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <search-input
              v-model="form.instructor_led_classroom"
              :data="contentsWithKeys()"
              :disabled="Boolean(selectedClassroomId && form.instructor_led_classroom)"
              :error="form.errors.instructor_led_classroom_id"
              :label="$t('Instructor Led Class')"
              :search-by="['name']"
              track-by="key"
            >
              <div v-if="form.instructor_led_classroom" class="flex items-center justify-between">
                <div class="truncate">{{ form.instructor_led_classroom.name }}</div>
              </div>
              <template #option="{ option, selected }">
                <div class="flex items-center justify-between">
                  <div>{{ option.name }}</div>
                  <div v-if="option.id" class="text-xs" :class="{ 'text-gray-600': !selected, 'text-white': selected }">
                    {{ $t('class') }}
                  </div>
                </div>
              </template>
            </search-input>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.capacity" :error="form.errors.capacity" :label="$t('Capacity')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.price" :error="form.errors.price" :label="$t('Price')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <radio-input
              v-model="form.test_scores_enabled"
              :error="form.errors.test_scores_enabled"
              :label="$t('Enable test scores')"
              type="radio"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <search-input
              v-model="form.offline_test"
              :data="offlineTestsWithKeys()"
              :disabled="
                !form.instructor_led_classroom || offlineTestsWithKeys().length === 0 || !form.test_scores_enabled
              "
              :error="form.errors.offline_test_id"
              :label="$t('Classroom Test')"
              :search-by="['name', 'total_score', 'passing_score']"
              track-by="key"
            >
              <div v-if="form.offline_test" class="flex items-center justify-between">
                <div class="truncate">
                  {{ getFormattedTestName(form.offline_test) }}
                </div>
              </div>
              <template #option="{ option, selected }">
                <div class="flex items-center justify-between">
                  <div>{{ getFormattedTestName(option) }}</div>
                  <div v-if="option.id" class="text-xs" :class="{ 'text-gray-600': !selected, 'text-white': selected }">
                    test
                  </div>
                </div>
              </template>
            </search-input>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <select-input v-model="form.locale" :error="form.errors.locale" :label="$t('Locale')">
              <option v-for="(locale, key) in locales" :key="key" :value="locale">
                {{ key }}
              </option>
            </select-input>
          </div>
          <div class="w-full pb-8 pr-6">
            <textarea-input
              v-model="form.instructor_notes"
              :autosize="true"
              :error="form.errors.instructor_notes"
              :label="$t('Instructor notes')"
              :rows="2"
            />
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Create Subclass') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import RadioInput from '@/Shared/RadioInput.vue'
import SearchInput from '@/Shared/SearchInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Layout,
    LoadingButton,
    RadioInput,
    SearchInput,
    SelectInput,
    TextInput,
    TextareaInput,
  },
  props: {
    locales: Object,
    parentClass: Object,
    selectedClassroomId: {
      type: Number,
      required: false,
      nullable: true,
    },
    offlineTests: Array,
    instructorLedClassrooms: Array,
  },
  data() {
    return {
      with_client_site: false,
      form: useForm(
        {
          instructor_led_classroom: this.selectedClassroomId
            ? this.instructorLedClassrooms.find((ilc) => ilc.id === this.selectedContentId)
            : null,
          capacity: null,
          test_scores_enabled: true,
          offline_test: null,
          price: null,
          locale: this.parentClass.locale,
          instructor_notes: null,
        },
        'SubclassCreate'
      ),
    }
  },
  watch: {
    'form.instructor_led_classroom'() {
      this.form.capacity = this.form.instructor_led_classroom ? this.form.instructor_led_classroom.capacity : null
      this.form.price = this.form.instructor_led_classroom ? this.form.instructor_led_classroom.price : null
      this.form.offline_test = null
    },
    'form.test_scores_enabled'() {
      this.form.offline_test = null
    },
    with_client_site() {
      this.locationData = this.locationsWithKeys(this.with_client_site)
    },
  },
  methods: {
    contentsWithKeys() {
      return this.instructorLedClassrooms.map((ilc, key) => {
        return { key: key, ...ilc }
      })
    },
    submit() {
      this.form
        .transform((data) => {
          return {
            parent_class_id: this.parentClass.id,
            instructor_led_classroom_id: data.instructor_led_classroom ? data.instructor_led_classroom.id : null,
            capacity: data.capacity,
            test_scores_enabled: data.test_scores_enabled,
            offline_test_id: data.offline_test?.id,
            price: data.price,
            locale: data.locale,
            instructor_notes: data.instructor_notes,
          }
        })
        .post(this.route('subclasses.store'))
    },
    offlineTestsWithKeys() {
      return (
        this.offlineTests
          .filter((offlineTest) => {
            return offlineTest.instructor_led_classroom_id === this.form.instructor_led_classroom?.id
          })
          .map((ilc, key) => {
            return { key: key, ...ilc }
          }) ?? {}
      )
    },
    getFormattedTestName(test) {
      if (test.name) {
        return `${test.name}, Total: ${test.total_score}, Passing: ${test.passing_score}`
      }
      return `Total: ${test.total_score}, Passing: ${test.passing_score}`
    },
  },
}
</script>
