<template>
  <front-layout :title="$t('Edit Manual Section')">
    <div class="item-center mb-8 flex max-w-2xl justify-between">
      <breadcrumb
        class="font-heading text-2xl font-semibold"
        :name="form.name ?? $t('Edit')"
        :previous-name="$t('Manuals')"
        :previous-url="route('front.admin.manuals.edit', manual)"
      />
    </div>
    <form @submit.prevent="submit">
      <div class="mt-8 flex max-w-2xl flex-col overflow-hidden rounded bg-white shadow">
        <div class="flex">
          <div class="w-full">
            <div class="-mb-8 -mr-6 flex flex-wrap p-8">
              <div class="w-full pb-8 pr-6">
                <front-text-input v-model="form.name" :error="form.errors.name" :label="$t('Title')" />
              </div>
              <div class="w-full pb-8 pr-6">
                <front-editor v-model="form.content" :error="form.errors.content" :label="$t('Content')" />
              </div>
              <div class="w-full pb-8 pr-6">
                <front-file-upload-input
                  v-model="form.attachments"
                  :initial-value="form.attachments"
                  label="Attachments"
                  :max-items="5"
                  :min-items="0"
                  ref-name="manualSectionAttachmentInput"
                  :validation-rules="{
                    maxSizeInKB: 1024 * 1000,
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-6 flex max-w-2xl justify-end">
        <front-loading-button :disabled="!form.isDirty" :loading="form.processing" @click="submit">
          {{ $t('Save') }}
        </front-loading-button>
      </div>
    </form>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontEditor from '@/Shared/FrontEditor.vue'
import FrontFileUploadInput from '@/Shared/FrontFileUploadInput.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    FrontEditor,
    FrontFileUploadInput,
    FrontLayout,
    FrontLoadingButton,
    FrontTextInput,
  },
  props: {
    manual: Object,
    manual_section: Object,
  },
  data() {
    return {
      existing_attachments: this.manual_section.existing_attachments,
      form: useForm({
        name: this.manual_section.name,
        content: this.manual_section.content,
        attachments: this.manual_section.existing_attachments,
      }),
    }
  },
  methods: {
    submit() {
      this.form.put(this.route('front.admin.manual-sections.update', [this.manual, this.manual_section]))
    },
  },
}
</script>
