<template>
  <front-full-screen-layout :title="$t('Forms')">
    <div class="sm:no-flex sticky top-0 z-10 mb-8 flex items-center justify-between bg-white px-8 py-4 shadow-md">
      <breadcrumb
        :name="formFilled.form_template?.title"
        :previous-name="$t('Forms')"
        :previous-url="route('front.filledForms.index')"
      />
      <a class="btn-red-outline" :href="route('front.filledForms.download', formFilled)" target="_blank">
        {{ $t('Download') }}
      </a>
      <InertiaLink :href="route('front.filledForms.index')">
        <icon class="h-4 w-4" name="close" />
      </InertiaLink>
    </div>
    <div
      v-for="page in pages"
      :key="page.id"
      class="mx-auto my-12 max-w-md rounded-xl border border-white bg-white p-4"
    >
      <Disclosure v-slot="{ open }" :default-open="true">
        <DisclosureButton class="w-full">
          <nav class="mx-auto flex items-center justify-between">
            <div class="text-left">
              <div class="mb-3 inline-flex items-center">
                <ChevronDownIcon aria-hidden="true" class="mr-1 h-5 w-5" :class="open ? 'rotate-180 transform' : ''" />
                <p class="text-sm text-gray-600">{{ page.name }}</p>
              </div>
              <p class="text-m font-semibold text-gray-900">{{ page.formAnswer.form_content.title }}</p>
            </div>
            <div class="text-right">
              <p class="mb-3 text-sm text-gray-600">{{ $t('Score') }}</p>
              <p class="text-m font-semibold text-gray-900">
                {{
                  $t(':filled_count / :total_items (:total_items_completed_percentage%)', {
                    filled_count: page.formAnswer.form_answerable.filled_count,
                    total_items: page.items.length,
                    total_items_completed_percentage: (0 / page.items.length).toFixed(0),
                  })
                }}
              </p>
            </div>
          </nav>
        </DisclosureButton>

        <DisclosurePanel class="mt-8 w-full border-none bg-white">
          <div v-for="formAnswer in page.items" :key="formAnswer.id" class="max-w-md">
            <front-form-item-view :form-answer="formAnswer" />
          </div>
        </DisclosurePanel>
      </Disclosure>
    </div>
    <footer class="mx-auto flex min-h-40 max-w-md">
      <div class="w-full text-center text-sm font-semibold italic text-gray-400">
        <p>{{ $t('Reviewed and Approved by:') }}</p>
        <p class="mt-2">
          {{ acceptedSignatures }}
        </p>
      </div>
    </footer>
  </front-full-screen-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontFormItemView from '@/Shared/FrontFormItemView.vue'
import FrontFullScreenLayout from '@/Shared/FrontFullScreenLayout.vue'
import Icon from '@/Shared/Icon.vue'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'

export default {
  components: {
    ChevronDownIcon,
    FrontFormItemView,
    Disclosure,
    DisclosurePanel,
    DisclosureButton,
    Icon,
    Breadcrumb,
    FrontFullScreenLayout,
  },
  props: {
    formFilled: Object,
    pages: Array,
  },
  computed: {
    acceptedSignatures() {
      // Filter accepted signatures and join their names with a comma
      return this.formFilled.signature_requests
        .filter((signature) => signature.accepted_at)
        .map((signature) => signature.receiver_name)
        .join(', ')
    },
  },
}
</script>
