<template>
  <layout :title="$t('Training Records')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink
        class="text-blue-600 hover:text-blue-700"
        :href="route('training-record-templates.edit', training_record.template_id)"
      >
        {{ $t('Training Record Template') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Edit Training Record') }}
    </h1>
    <deleted-message v-if="training_record.deleted_at" class="mb-6">
      {{ $t('This record has been deleted.') }}
    </deleted-message>
    <archived-message v-if="training_record.archived_at" class="mb-6">
      <div class="flex w-full justify-between">
        <div>{{ $t('This record has been archived.') }}</div>
        <button
          v-if="training_record.archived_at"
          class="ml-4 text-yellow-800 hover:underline"
          tabindex="-1"
          type="button"
          @click="unArchive"
        >
          {{ $t('Restore') }}
        </button>
      </div>
    </archived-message>
    <div class="flex w-full">
      <div class="w-full max-w-lg overflow-hidden rounded bg-white shadow">
        <form @submit.prevent="submit">
          <div class="-mb-8 -mr-6 flex flex-wrap p-8">
            <div class="w-full pb-8 pr-6">
              <date-input
                v-model="form.completed_at"
                :error="form.errors.completed_at"
                :label="$t('Completed At')"
                min-year="2011"
              />
            </div>
            <div class="w-full pb-8 pr-6">
              <select-input v-model="form.user_id" :error="form.errors.user_id" :label="$t('Student')">
                <option value="" />
                <option v-for="user in users" :key="user.id" :value="user.id">
                  {{ user.first_name }} {{ user.last_name }}
                </option>
              </select-input>
            </div>
          </div>
          <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
            <div class="flex justify-start">
              <button
                v-if="!training_record.deleted_at"
                class="text-red-500 hover:underline"
                tabindex="-1"
                type="button"
                @click="destroy"
              >
                {{ $t('Delete') }}
              </button>
              <button
                v-if="training_record.archived_at"
                class="ml-4 text-red-500 hover:underline"
                tabindex="-1"
                type="button"
                @click="unArchive"
              >
                {{ $t('Restore Archived') }}
              </button>
              <button v-else class="ml-4 text-red-500 hover:underline" tabindex="-1" type="button" @click="archive">
                {{ $t('Archive') }}
              </button>
            </div>
            <!-- <span v-else>&nbsp;</span> -->
            <loading-button class="btn-blue" :loading="form.processing" type="submit">
              {{ $t('Update Record') }}
            </loading-button>
          </div>
        </form>
      </div>
    </div>
  </layout>
</template>

<script>
import ArchivedMessage from '@/Shared/ArchivedMessage.vue'
import DateInput from '@/Shared/DateInput.vue'
import DeletedMessage from '@/Shared/DeletedMessage.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    ArchivedMessage,
    DateInput,
    DeletedMessage,
    Layout,
    LoadingButton,
    SelectInput,
  },
  props: {
    training_record: Object,
    users: Array,
  },
  data() {
    return {
      form: useForm({
        user_id: this.training_record.user_id,
        completed_at: this.training_record.completed_at,
      }),
    }
  },
  methods: {
    submit() {
      this.form.put(this.route('training-records.update', this.training_record.id))
    },
    archive() {
      if (confirm(this.$t('Are you sure you want to archive this record?'))) {
        this.$inertia.post(this.route('training-records.archive', this.training_record.id))
      }
    },
    unArchive() {
      if (confirm(this.$t('Are you sure you want to restore this archived record?'))) {
        this.$inertia.post(this.route('training-records.unarchive', this.training_record.id))
      }
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this training record?'))) {
        this.$inertia.delete(this.route('training-records.destroy', this.training_record.id))
      }
    },
  },
}
</script>
