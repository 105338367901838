<template>
  <menu-item
    v-if="$page.props.auth.can.access_admin_backoffice"
    class="hidden md:block"
    :name="$t('Admin')"
    :route-link="route('dashboard')"
  >
    <icon class="mr-3 h-5 w-5" name="setting" />
  </menu-item>
</template>
<script>
import Icon from './Icon.vue'
import MenuItem from './MenuItem.vue'
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  components: {
    Icon,
    MenuItem,
  },
  computed: {
    isTenantWorksite,
  },
}
</script>
