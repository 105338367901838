<template>
  <layout :title="$t('Edit enrolment')">
    <breadcrumb-admin :path="breadcrumb" />

    <div class="max-w-sm">
      <archived-message v-if="enrolment.archived_at" class="mb-6">
        {{ $t('This enrolment has been archived.') }}
      </archived-message>
      <cancelled-message v-if="enrolment.cancelled_at" class="mb-6">
        {{ $t('This enrolment subscription has been cancelled.') }}
      </cancelled-message>
      <deleted-message v-if="enrolment.deleted_at" class="mb-6">
        {{ $t('This enrolment has been deleted.') }}
      </deleted-message>
    </div>
    <div class="flex flex-wrap">
      <div class="flex w-full flex-col justify-between gap-x-6 md:flex-row">
        <div class="w-full md:w-1/2">
          <div class="overflow-hidden rounded bg-white shadow">
            <form @submit.prevent="submit">
              <div class="-mb-8 -mr-6 flex flex-wrap p-8">
                <div class="w-1/2 pb-8 pr-6">
                  <text-show :label="$t('Course')" :model-value="enrolment.course.name" />
                </div>
                <div class="w-1/2 pb-8 pr-6">
                  <text-show :label="$t('Language')" :model-value="enrolment.locale" />
                </div>
                <div class="w-1/2 pb-8 pr-6">
                  <div>
                    <label class="form-label font-semibold text-ts-front-label">{{ $t('Owner:') }}</label>
                    <InertiaLink
                      v-if="enrolment.owner"
                      class="text-blue-600 underline"
                      :href="route('users.edit', enrolment.owner.id)"
                    >
                      {{ enrolment.owner.name }}
                    </InertiaLink>
                    <span v-else>-</span>
                  </div>
                </div>
                <div class="w-1/2 pb-8 pr-6">
                  <div>
                    <label class="form-label font-semibold text-ts-front-label">{{ $t('User:') }}</label>
                    <InertiaLink
                      v-if="enrolment.user"
                      class="text-blue-600 underline"
                      :href="route('users.edit', enrolment.user.id)"
                    >
                      {{ enrolment.user.name }}
                    </InertiaLink>
                    <span v-else>-</span>
                  </div>
                </div>
                <div class="w-1/2 pb-8 pr-6">
                  <text-show
                    disabled
                    :label="$t('Enroller')"
                    :model-value="enrolment.enroller ? enrolment.enroller.name : ''"
                  />
                </div>
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <text-show :label="$t('Payment Status')" :model-value="enrolment.payment_status" />
                </div>
                <div v-if="students?.[0]?.equipment_types?.length" class="w-full pb-8 pr-6 lg:w-1/2">
                  <label class="form-label mb-2 block font-semibold text-ts-front-label">{{ $t('Equipment:') }}</label>
                  <div class="flex flex-col text-sm">
                    <span v-for="(equipment, index) in students?.[0]?.equipment_types" :key="index">
                      {{ equipment }}
                    </span>
                  </div>
                </div>
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <label class="form-label mb-2 block font-semibold text-ts-front-label">{{ $t('Status:') }}</label>
                  <front-course-status :status="enrolment.status" />
                </div>
                <div v-if="enrolment.can.displayMolStatus" class="w-full pb-8 pr-6 lg:w-1/2">
                  <label class="mb-2 block">{{ $t('MOL Submission:') }}</label>
                  <front-mol-submission-status :enrolment="enrolment" />
                </div>
                <div v-if="enrolment.payment_order_number" class="w-1/2 pb-8 pr-6">
                  <text-show :label="$t('Order Number')" :model-value="'#' + enrolment.payment_order_number" />
                </div>
                <div v-if="enrolment.payment_gateway?.value == 'Invoice'" class="w-1/2 pb-8 pr-6">
                  <text-show :label="$t('Invoice Number')" :model-value="enrolment.invoice_number" />
                </div>
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <text-show
                    v-if="enrolment.finished_at"
                    disabled
                    :label="$t('Finished At')"
                    :model-value="enrolment.finished_at"
                  />
                </div>
              </div>
            </form>
          </div>
          <model-timestamps :model="enrolment" />
          <div v-if="enrolment.reset_at" class="ml-2 mt-1 text-xs text-gray-600">
            {{ $t('Reset on :reset_at', { reset_at: enrolment.reset_at }) }}
          </div>
        </div>
        <div class="mt-8 w-full md:mt-0 md:w-1/2">
          <tabs
            class="overflow-hidden rounded shadow"
            nav-class="flex items-center text-gray-700 text-sm font-bold bg-gray-400"
            nav-item-active-class="bg-gray-300"
            nav-item-class="p-4"
          >
            <tab v-if="enrolment.certificate" :name="$t('Certificate')">
              <div class="flex w-full flex-col px-8 py-6">
                <div class="my-1 flex">
                  <div class="w-24">{{ $t('Number') }}</div>
                  <div>
                    <span class="mr-2">:</span>
                    <span>{{ enrolment.certificate.certificate_number }}</span>
                  </div>
                </div>
                <div class="my-1 flex">
                  <div class="w-24">{{ $t('User') }}</div>
                  <div>
                    <span class="mr-2">:</span>
                    <span>{{ enrolment.certificate.user.name }}</span>
                  </div>
                </div>
                <div class="my-1 flex">
                  <div class="w-24">{{ $t('Issue Date') }}</div>
                  <div class="flex items-center">
                    <span class="mr-2">:</span>
                    <show-date :timestamp="enrolment.certificate.created_at" />
                  </div>
                </div>
                <div class="my-1 flex">
                  <div class="w-24">{{ $t('Expire Date') }}</div>
                  <div class="flex items-center">
                    <span class="mr-2">:</span>
                    <show-date :timestamp="enrolment.certificate.expires_at" />
                  </div>
                </div>
                <template v-if="enrolment.certificate.archived_at">
                  <div class="my-1 flex">
                    <div class="w-24">{{ $t('Archived At') }}</div>
                    <div class="flex items-center">
                      <span class="mr-2">:</span>
                      <show-date :timestamp="enrolment.certificate.archived_at" />
                    </div>
                  </div>
                </template>
                <template v-if="enrolment.certificate.deleted_at">
                  <div class="my-1 flex">
                    <div class="w-24">{{ $t('Deleted At') }}</div>
                    <div class="flex items-center">
                      <span class="mr-2">:</span>
                      <show-date :timestamp="enrolment.certificate.deleted_at" />
                    </div>
                  </div>
                </template>
                <div class="my-1 flex">
                  <div class="w-24">{{ $t('Status') }}</div>
                  <div class="flex items-center">
                    <span class="mr-2">:</span>
                    <front-certificate-status class="-ml-2" :status="enrolment.certificate.status" />
                  </div>
                </div>
              </div>
            </tab>
            <tab :name="$t('Notes')">
              <div class="px-6" :class="{ 'py-4': enrolment.notes.length > 0 }">
                <div v-for="note in enrolment.notes" :key="note.id" class="py-3">
                  <div
                    class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 leading-normal transition duration-150 ease-in-out"
                    type="text"
                  >
                    <span v-html="note.content" />
                  </div>
                  <div class="mt-2 flex items-center justify-between">
                    <span class="text-gray-700">{{ note.user.name || '-' }}</span>
                    <span class="text-gray-700">{{ note.created_at }}</span>
                  </div>
                </div>
              </div>
              <div class="w-full px-6 py-3">
                <textarea-input
                  v-model="noteForm.content"
                  :autosize="true"
                  :error="noteForm.errors.content"
                  :placeholder="$t('New note') + '..'"
                  :rows="3"
                />
              </div>
              <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
                <div class="flex">
                  <button class="btn-blue" @click.stop="addNote">{{ $t('Add Note') }}</button>
                </div>
              </div>
            </tab>
          </tabs>
        </div>
      </div>
    </div>
    <Enrolment-contents :enrolment-contents="enrolmentContents" />
  </layout>
</template>

<script>
import EnrolmentContents from './EnrolmentContents.vue'
import ArchivedMessage from '@/Shared/ArchivedMessage.vue'
import BreadcrumbAdmin from '@/Shared/BreadcrumbAdmin.vue'
import CancelledMessage from '@/Shared/CancelledMessage.vue'
import DeletedMessage from '@/Shared/DeletedMessage.vue'
import FrontCertificateStatus from '@/Shared/FrontCertificateStatus.vue'
import FrontCourseStatus from '@/Shared/FrontCourseStatus.vue'
import FrontMolSubmissionStatus from '@/Shared/FrontMolSubmissionStatus.vue'
import Layout from '@/Shared/Layout.vue'
import ModelTimestamps from '@/Shared/ModelTimestamps.vue'
import ShowDate from '@/Shared/ShowDate.vue'
import TextShow from '@/Shared/TextShow.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'
import { Tab, Tabs } from 'vue3-tabs-component'

export default {
  components: {
    Tabs,
    Tab,
    EnrolmentContents,
    ArchivedMessage,
    BreadcrumbAdmin,
    CancelledMessage,
    DeletedMessage,
    FrontCertificateStatus,
    FrontCourseStatus,
    FrontMolSubmissionStatus,
    Layout,
    ModelTimestamps,
    ShowDate,
    TextShow,
    TextareaInput,
  },
  props: {
    enrolment: Object,
    students: Object,
    enrolmentContents: Array,
    class_: Object,
  },
  data() {
    return {
      noteForm: useForm({
        content: null,
      }),
    }
  },
  computed: {
    breadcrumb() {
      return [
        {
          name: this.$t('Classes'),
          url: this.route('instructor.classes'),
        },
        {
          name: this.$t(this.class_.name),
          url: this.route('instructor.classes.show', this.class_.id),
        },
        {
          name: `${this.$t('Enrolment (:id)', { id: this.enrolment.id })}`,
        },
      ]
    },
  },
}
</script>
