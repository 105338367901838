<template>
  <front-layout :title="$t('Home')">
    <div class="-mb-8 -mr-8 flex flex-wrap">
      {{ $t('Home') }}
      <div v-for="class_ in classes" :key="class_.id" class="flex w-full pb-8 pr-8 xl:w-1/2">
        <div class="flex w-full flex-col rounded bg-white p-8 text-left leading-tight shadow">
          <div>{{ class_.content.name }}</div>
          <div class="text-gray-600">
            <div>{{ class_.location.address }}</div>
            <div>{{ class_.location.city }}, {{ class_.location.region }} {{ class_.location.postal_code }}</div>
            <div v-if="class_.room">{{ $t('Room: :name', { name: class_.room.name }) }}</div>
            <div v-for="session in class_.sessions" :key="session.id" class="mt-4">
              <div class="">{{ session.date }}</div>
              <div class="">{{ session.time }}</div>
              <div v-if="session.instructors.length > 1">Instructors: {{ session.instructors.join(', ') }}</div>
              <div v-else>{{ $t('Instructor: :instructor', { instructor: session.instructors[0] }) }}</div>
            </div>
            <div v-if="class_.sessions.length === 0">
              <td class="" colspan="4">{{ $t('No sessions found.') }}</td>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ref="map" class="h-80 w-1/2" />
  </front-layout>
</template>

<script>
import FrontLayout from '@/Shared/FrontLayout.vue'
import Gmaps from '@/Utils/Gmaps'

export default {
  components: {
    FrontLayout,
  },
  props: {
    user: Object,
    classes: Array,
  },
  data() {
    return {
      google: null,
    }
  },
  async mounted() {
    this.google = await Gmaps()

    var worksite = new this.google.maps.LatLng(43.267767, -79.9743)

    let map = new this.google.maps.Map(this.$refs.map, {
      center: worksite,
      zoom: 15,
    })

    var marker = new this.google.maps.Marker({
      position: worksite,
      map: map,
    })

    var infoWindow = new this.google.maps.InfoWindow()
    infoWindow.setContent(this.createInfoWindowContent())
    infoWindow.open(map, marker)
    // infoWindow.setPosition(worksite)
    // infoWindow.open(map)

    this.google.maps.event.addListener(marker, 'click', function () {
      infoWindow.open(map, marker)
    })
  },
  methods: {
    createInfoWindowContent() {
      return ['Worksite Safety', '55 Head St #401, Dundas, ON L9H 3H8', '+18667565552'].join('<br>')
    },
  },
}
</script>
