<template>
  <front-layout :title="$t('Job Profiles')">
    <div class="mb-8">
      <breadcrumb
        class="font-heading text-2xl font-semibold"
        :name="$t('Edit')"
        :previous-name="$t('Job Profiles')"
        :previous-url="route('front.admin.job-profiles')"
      />
    </div>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-col p-8">
          <div class="w-full pb-8 pr-6 lg:w-2/3">
            <front-text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" />
          </div>
        </div>
        <div class="mt-6 flex items-center justify-between border-t border-gray-100 px-8 py-8">
          <button class="text-red-500 hover:underline" tabindex="-1" type="button" @click="destroy">
            {{ $t('Delete Profile') }}
          </button>
          <front-loading-button :loading="form.processing" type="submit">
            {{ $t('Update Profile') }}
          </front-loading-button>
        </div>
      </form>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    FrontLayout,
    FrontLoadingButton,
    FrontTextInput,
  },
  props: {
    jobProfile: Object,
  },
  data() {
    return {
      previous: {
        url: this.route('front.admin.job-profiles'),
        name: this.$t('Job Profiles'),
      },
      form: useForm({
        name: this.jobProfile.name,
      }),
    }
  },
  methods: {
    submit() {
      this.form.put(this.route('front.admin.job-profiles.update', this.jobProfile.id))
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this profile?'))) {
        this.$inertia.delete(this.route('front.admin.job-profiles.destroy', this.jobProfile.id))
      }
    },
  },
}
</script>
