<template>
  <guest-layout :tenant="tenant" :title="$t('Certificate signed!')">
    <front-signature-modal
      :certificates="certificates"
      role="manager"
      :show="true"
      :show-qr-code="false"
      @close="showSignaturePadModal = false"
      @signed="submitSignature"
    />
  </guest-layout>
</template>

<script>
import FrontSignatureModal from '@/Shared/FrontSignatureModal.vue'
import GuestLayout from '@/Shared/GuestLayout.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    FrontSignatureModal,
    GuestLayout,
  },
  props: {
    tenant: Object,
    user: Object,
    certificates: Array,
    signatureSubmitToUrl: String,
  },
  data() {
    return {
      form: useForm({
        image: null,
      }),
    }
  },
  methods: {
    submitSignature(signatureImage) {
      this.form.image = signatureImage
      this.form.post(this.signatureSubmitToUrl)
    },
  },
}
</script>
