<template>
  <quiz-clock-layout :proctoring-enabled="quiz.proctoring_enabled">
    <template #question-links>
      <InertiaLink
        v-for="q in questions"
        :key="q.id"
        class="mb-2 flex h-10 w-10 items-center justify-center rounded-lg border-2 border-transparent text-center shadow"
        :class="{
          'text-front-empty-state-header bg-white': !Boolean(q.user_answer),
          'border-ts-gray-400': q.id === question.id,
          'bg-ts-gray-quiz-question-number': Boolean(q.user_answer),
        }"
        :href="route('front.attempts.questions.show', [attempt, q])"
        preserve-state
      >
        <span>{{ q.number }}</span>
      </InertiaLink>
    </template>

    <template #progress>
      <div class="w-full rounded-xl bg-ts-gray-300" :title="`${$t('Exam')} ${progress}% ${$t('Complete')}`">
        <div
          class="flex h-5 w-min items-center justify-center rounded-xl bg-ts-gray-quiz-progress-bar text-xs font-semibold text-ts-blue-600"
          :style="{
            width: `${progress}%`,
            minWidth: `2rem`,
          }"
        >
          {{ progress }}%
        </div>
      </div>
    </template>

    <form @submit.prevent="submit">
      <div class="pb-4">
        <div ref="question" class="flex font-semibold leading-normal text-ts-front-empty-state-header" tabindex="-1">
          <div>{{ question.number }}.</div>
          <div class="ml-2 flex-1">{{ question.name }}</div>
        </div>
        <div v-if="question.file" class="mt-4 w-full">
          <img alt="question image" class="h-48" :src="question.file" />
        </div>
      </div>
      <div>
        <div v-for="(option, key) in question.options" :key="option" class="mt-2">
          <div>
            <front-radio-button
              v-if="!question.is_multiple_choice"
              v-model="form.answer"
              class="rounded bg-ts-gray-200 px-4 py-3"
              :correct="question.correct && question.correct.includes(key)"
              :label="option"
              name="options"
              :value="[key]"
            />
            <front-quiz-checkbox
              v-else
              v-model="form.answer"
              class="rounded bg-ts-gray-200 px-4 py-3"
              :correct="question.correct && question.correct.includes(key)"
              :label="option"
              :value="key"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-wrap items-center justify-end space-y-2 pt-12 sm:space-x-2 sm:space-y-0">
        <InertiaLink
          class="btn-link"
          :href="route('front.attempts.questions.skip', [attempt, question], { preserveState: true })"
          preserve-state
        >
          {{ $t('Skip') }}
        </InertiaLink>
        <front-loading-button
          v-if="hasNext"
          :disabled="form.answer.length === 0"
          :loading="form.processing"
          type="submit"
        >
          {{ $t('Save & Continue') }}
        </front-loading-button>
        <front-outline-button v-else :loading="form.processing" type="submit">
          {{ $t('Save') }}
        </front-outline-button>
        <front-solid-button
          v-if="!hasNext"
          :disabled="form.processing"
          type="button"
          @click="showConfirmQuizSubmitModal = true"
        >
          <span>{{ $t('Submit Quiz') }}</span>
        </front-solid-button>
      </div>
      <styled-modal max-width="md" :show="showConfirmQuizSubmitModal" @close="showConfirmQuizSubmitModal = false">
        <template #title>{{ $t('Quiz submit Confirmation') }}</template>
        <template #content>
          <div class="space-y-2">
            <p>
              <strong v-if="$page.props.hasUnansweredQuestions">
                {{ $t('You have unanswered questions in your quiz!') }}
              </strong>
              {{ $t('Are you sure you want to submit the quiz?') }}
            </p>
          </div>
        </template>
        <template #footer>
          <div class="flex flex-wrap justify-end space-y-2 sm:space-x-4 sm:space-y-0">
            <button
              class="w-full px-6 py-2 text-gray-500 sm:w-auto"
              @click.prevent="showConfirmQuizSubmitModal = false"
            >
              {{ $t('Nevermind') }}
            </button>
            <front-loading-button
              class="w-full sm:w-auto"
              :loading="quizSubmitForm.processing"
              type="button"
              @click="submitAttemptFinished"
            >
              <span>{{ $t('Confirm') }}</span>
            </front-loading-button>
          </div>
        </template>
      </styled-modal>
    </form>
  </quiz-clock-layout>
</template>

<script>
import QuizClockLayout from './QuizClockLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontOutlineButton from '@/Shared/FrontOutlineButton.vue'
import FrontQuizCheckbox from '@/Shared/FrontQuizCheckbox.vue'
import FrontRadioButton from '@/Shared/FrontRadioButton.vue'
import FrontSolidButton from '@/Shared/FrontSolidButton.vue'
import StyledModal from '@/Shared/StyledModal.vue'
import zendesk from '@/mixins/zendesk.js'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    QuizClockLayout,
    FrontLoadingButton,
    FrontOutlineButton,
    FrontQuizCheckbox,
    FrontRadioButton,
    FrontSolidButton,
    StyledModal,
  },
  name: 'AttemptQuestionsShow',
  mixins: [zendesk],
  props: {
    enrolmentContent: Object,
    quiz: Object,
    questions: Array,
    question: Object,
    progress: Number,
    attempt: Object,
    storedAnswer: Array,
    hasNext: Boolean,
  },
  data() {
    return {
      form: useForm({
        answer: this.storedAnswer ?? [],
      }),
      showConfirmQuizSubmitModal: false,
      quizSubmitForm: useForm({
        attempt_id: this.$page.props.attempt.id,
      }),
    }
  },
  mounted() {
    if (this.quiz.proctoring_enabled) {
      this.checkCameraAccess()
    }
    this.setFocus()
  },
  watch: {
    storedAnswer(newVal) {
      this.form.answer = newVal ?? []
    },
  },
  methods: {
    submit() {
      if (!this.form.answer) return

      this.form.post(this.route('front.attempts.questions.store', [this.attempt, this.question]), {
        preserveState: true,
        onSuccess: () => {
          this.form.answer = this.storedAnswer ?? []
        },
      })

      this.setFocus()
    },
    submitAttemptFinished() {
      this.zE_showWidget()
      this.quizSubmitForm.post(this.route('front.finished-attempts.store'), {
        onSuccess: () => (this.showConfirmQuizSubmitModal = false),
      })
    },
    setFocus() {
      this.$refs.question.focus()
    },
    async checkCameraAccess() {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true })
        this.cameraEnabled = true // Camera is accessible
      } catch {
        this.cameraEnabled = false // Camera is not accessible
      }
    },
  },
}
</script>

<style scoped>
@media only screen and (min-width: 1023px) {
  .lg\:grid-cols-16 {
    grid-template-columns: repeat(16, minmax(0, 1fr));
  }
}
</style>
