<template>
  <div>
    <AppHead :title="$t('Organization Profile')" />
    <div class="flex w-full flex-col gap-x-6 md:flex-row">
      <div class="max-w-lg overflow-hidden rounded bg-white shadow">
        <form @submit.prevent="submit">
          <div class="-mb-8 -mr-6 flex flex-wrap p-8">
            <div class="w-full pb-8 pr-6">
              <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input v-model="form.email" :error="form.errors.email" :label="$t('Email')" />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input v-model="form.phone" :error="form.errors.phone" :label="$t('Phone')" />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input v-model="form.address" :error="form.errors.address" :label="$t('Address')" />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input v-model="form.city" :error="form.errors.city" :label="$t('City')" />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <select-input
                v-model="form.region"
                :error="form.errors.region"
                :label="$t('Province/State')"
                :options="[null, ...regions]"
              />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input v-model="form.postal_code" :error="form.errors.postal_code" :label="$t('Postal code')" />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <radio-input
                v-model="form.access_codes_enabled"
                :error="form.errors.access_codes_enabled"
                :label="$t('Enable access codes')"
                type="radio"
              />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <radio-input
                v-model="form.training_records_enabled"
                :error="form.errors.training_records_enabled"
                :label="$t('Enable training records')"
                type="radio"
              />
            </div>
          </div>
          <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
            <loading-button class="btn-blue" :loading="form.processing" type="submit">
              {{ $t('Update') }}
            </loading-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import OrganizationEditLayout from './OrganizationEditLayout.vue'
import AppHead from '@/Shared/AppHead.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import RadioInput from '@/Shared/RadioInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import { fetchOrganization } from '@/Stores/ActiveOrganization'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    AppHead,
    LoadingButton,
    RadioInput,
    SelectInput,
    TextInput,
  },
  layout: [Layout, OrganizationEditLayout],
  props: {
    organization: Object,
    regions: Array,
    can: Object,
  },
  data() {
    return {
      form: useForm(
        {
          name: this.organization.name,
          email: this.organization.email,
          phone: this.organization.phone,
          address: this.organization.address,
          city: this.organization.city,
          region: this.organization.region,
          postal_code: this.organization.postal_code,
          access_codes_enabled: this.organization.access_codes_enabled,
          training_records_enabled: this.organization.training_records_enabled,
        },
        'OrganizationEdit'
      ),
    }
  },
  methods: {
    submit() {
      this.form.put(this.route('organizations.update', this.organization.id), {
        onSuccess: () => fetchOrganization(),
      })
    },
  },
}
</script>
