<template>
  <report-layout>
    <!-- CARD ROW -->
    <div class="-mx-2 flex flex-wrap">
      <!-- Total -->
      <div class="flex-1 px-2 py-4">
        <div class="flex h-full items-center justify-between rounded bg-white p-10 shadow-lg">
          <div>
            <div class="flex h-18 w-18 items-center justify-center rounded-full p-2" style="background-color: #f8f8f8">
              <icon class="h-12 w-12" name="graduation-cap" style="fill: #9fa0a1" />
            </div>
          </div>
          <div>
            <div class="mb-2 text-3xl text-black">{{ enrolment_aggregates.total }}</div>
            <div style="color: #696a6c">{{ $t('Total') }}</div>
          </div>
        </div>
      </div>

      <!-- Incomplete -->
      <div class="flex-1 px-2 py-4">
        <div class="h-full rounded bg-white p-10 shadow-lg">
          <div class="mb-4 w-full">
            <span class="text-3xl text-black">{{ percentOfEnrolments('new') }}%</span>
            <span style="color: #696a6c">{{ $t('Incomplete') }}</span>
          </div>
          <div class="flex w-full items-center">
            <progress-bar
              class="mr-2 w-3/4"
              fill-bg-class="bg-ts-orange-600"
              :model-value="percentOfEnrolments('new')"
              track-bg-class="bg-ts-orange-100"
            />
            <span style="color: #9fa0a1">{{ enrolment_aggregates.new }}/{{ enrolment_aggregates.total }}</span>
          </div>
        </div>
      </div>

      <!-- In Progress -->
      <div class="flex-1 px-2 py-4">
        <div class="h-full rounded bg-white p-10 shadow-lg">
          <div class="mb-4 w-full">
            <span class="text-3xl text-black">{{ percentOfEnrolments('in progress') }}%</span>
            <span style="color: #696a6c">{{ $t('In progress') }}</span>
          </div>
          <div class="flex w-full items-center">
            <progress-bar
              class="mr-2 w-3/4"
              fill-bg-class="bg-ts-blue-600"
              :model-value="percentOfEnrolments('in progress')"
              track-bg-class="bg-ts-blue-100"
            />
            <span style="color: #9fa0a1">
              {{ enrolment_aggregates['in progress'] }}/{{ enrolment_aggregates.total }}
            </span>
          </div>
        </div>
      </div>

      <!-- Completed -->
      <div class="flex-1 px-2 py-4">
        <div class="h-full rounded bg-white p-10 shadow-lg">
          <div class="mb-4 w-full">
            <span class="text-3xl text-black">{{ percentOfEnrolments('complete') }}%</span>
            <span style="color: #696a6c">{{ $t('Complete') }}</span>
          </div>
          <div class="flex w-full items-center">
            <progress-bar
              class="mr-2 w-3/4"
              fill-bg-class="bg-ts-gray-text-bold"
              :model-value="percentOfEnrolments('complete')"
              track-bg-class="bg-ts-gray-100"
            />
            <span style="color: #9fa0a1">{{ enrolment_aggregates.complete }}/{{ enrolment_aggregates.total }}</span>
          </div>
        </div>
      </div>
    </div>

    <section class="mt-16 w-full">
      <div class="flex justify-between">
        <h3 class="mb-6 text-xl" style="color: #404624">{{ $t('Active Enrolments') }}</h3>
        <div>
          <button style="color: #0c5e85" @click="resetActiveEnrolmentFilters()">{{ $t('Reset all') }}</button>
          <a class="pl-4" :href="downloadLink" style="color: #0c5e85">{{ $t('Download') }}</a>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th
                      class="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                    >
                      {{ $t('Person') }}
                    </th>
                    <th
                      class="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                    >
                      <front-select-input v-model="form.table_filters.course">
                        <option :value="null">{{ $t('All courses') }}</option>
                        <option v-for="course in courses" :key="course.id" :value="course.id">
                          {{ course.name }}
                        </option>
                      </front-select-input>
                    </th>
                    <th
                      class="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                    >
                      <front-select-input v-model="form.table_filters.group">
                        <option :value="null">{{ $t('Group') }}</option>
                        <option v-for="group in groups" :key="group.id" :value="group.id">
                          {{ group.name }}
                        </option>
                      </front-select-input>
                    </th>
                    <th
                      class="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                    >
                      <front-select-input v-model="form.table_filters.class_location">
                        <option :value="null">{{ $t('Location') }}</option>
                        <option v-for="location in locations" :key="location.id" :value="location.id">
                          {{ location.name }}
                        </option>
                      </front-select-input>
                    </th>
                    <th
                      class="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                    >
                      <front-select-input v-model="form.table_filters.code">
                        <option :value="null">{{ $t('Access Code') }}</option>
                        <option v-for="accessCode in accessCodes" :key="accessCode.id" :value="accessCode.id">
                          {{ accessCode.code }}
                        </option>
                      </front-select-input>
                    </th>
                    <th
                      class="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                    >
                      {{ $t('Enrol Date') }}
                    </th>
                    <th
                      class="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                    >
                      <front-select-input v-model="form.table_filters.status">
                        <option :value="null">{{ $t('Status') }}</option>
                        <option v-for="(status, val, i) in statuses" :key="i" :value="val">
                          {{ status }}
                        </option>
                      </front-select-input>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="enrolments.data.length === 0">
                    <td
                      class="whitespace-nowrap bg-white px-6 py-4 text-center text-sm leading-5 text-ts-front-label"
                      colspan="6"
                    >
                      {{ $t('No enrolments found.') }}
                    </td>
                  </tr>
                  <tr v-for="enrolment in enrolments.data" :key="enrolment.id" class="bg-white">
                    <td class="whitespace-nowrap px-6 py-4 text-base font-medium leading-5 text-ts-gray-text-bold">
                      <span v-if="enrolment.user">{{ enrolment.user.name }}</span>
                      <span v-else>-</span>
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm leading-5 text-ts-front-label">
                      {{ enrolment.course.name }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm leading-5 text-ts-front-label">
                      <template v-if="enrolment.user">
                        <span v-if="enrolment.user.groups.length === 1">
                          {{ enrolment.user.groups[0].name }}
                        </span>
                        <span v-if="enrolment.user.groups.length > 1">
                          {{
                            $t(':first_group_name and :group_user_length more', {
                              first_group_name: enrolment.user.groups[0].name,
                              group_user_length: enrolment.user.groups.length - 1,
                            })
                          }}
                        </span>
                      </template>
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm leading-5 text-ts-front-label">
                      <template v-if="enrolment.user">
                        <span v-if="enrolment.user.locations.length === 1">
                          {{ enrolment.user.locations[0].name }}
                        </span>
                        <span v-if="enrolment.user.locations.length > 1">
                          {{
                            $t(':first_location_name and :location_user_length more', {
                              first_location_name: enrolment.user.locations[0].name,
                              location_user_length: enrolment.user.locations.length - 1,
                            })
                          }}
                        </span>
                      </template>
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm leading-5 text-ts-front-label">
                      <span v-if="enrolment.accessCode">
                        {{ enrolment.accessCode.code }}
                      </span>
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm leading-5 text-ts-front-label">
                      {{ enrolment.enrolled_at }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm leading-5 text-ts-front-label">
                      <span class="rounded-sm p-1 uppercase" :class="statusBadgeStyle(enrolment.status)">
                        {{ enrolment.status }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <front-pagination :links="enrolments.links" :preserve-scroll="true" :preserve-state="true" />
    </section>
  </report-layout>
</template>

<script>
import ReportLayout from '../ReportLayout.vue'
import FrontPagination from '@/Shared/FrontPagination.vue'
import FrontSelectInput from '@/Shared/FrontSelectInput.vue'
import Icon from '@/Shared/Icon.vue'
import ProgressBar from '@/Shared/ProgressBar.vue'
import _debounce from 'lodash/debounce'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'

export default {
  components: { ReportLayout, FrontPagination, FrontSelectInput, Icon, ProgressBar },
  props: {
    filters: Object,
    courses: Array,
    time_periods: Object,
    groups: Array,
    locations: Array,
    accessCodes: Array,
    statuses: Object,
    enrolments: Object,
    enrolment_aggregates: Object,
  },
  data() {
    return {
      form: {
        table_filters: {
          course: this.filters?.table_filters?.course || null,
          group: this.filters?.table_filters?.group || null,
          class_location: this.filters?.table_filters?.class_location || null,
          code: this.filters?.table_filters?.code || null,
          status: this.filters?.table_filters?.status || null,
        },
      },
      downloadLink: this.route('front.manager.reports.access-codes.download', {
        filters: {
          course: this.filters?.table_filters?.course || null,
          group: this.filters?.table_filters?.group || null,
          class_location: this.filters?.table_filters?.class_location || null,
          code: this.filters?.table_filters?.code || null,
          status: this.filters?.table_filters?.status || null,
        },
      }),
    }
  },
  watch: {
    form: {
      handler: _debounce(function () {
        let query = {
          table_filters: _pickBy(this.form.table_filters),
        }

        this.downloadLink = this.route('front.manager.reports.access-codes.download', { filters: query.table_filters })

        this.$inertia.get(
          this.route('front.manager.reports.access-codes.show'),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveScroll: true,
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
  },
  methods: {
    resetActiveEnrolmentFilters() {
      this.form.table_filters = _mapValues(this.form.table_filters, () => null)
    },
    percentOfEnrolments(status) {
      if (this.enrolment_aggregates[status] === 0) {
        return 0
      }

      return ((this.enrolment_aggregates[status] / this.enrolment_aggregates.total) * 100).toFixed(0)
    },
    statusBadgeStyle(status) {
      const styles = {
        complete: 'bg-gray-100 text-ts-gray-text',
        new: 'bg-ts-orange-100 text-ts-orange-600',
        'in progress': 'bg-ts-blue-100 text-ts-blue-600',
      }

      return styles[status] || ''
    },
  },
}
</script>
