<template>
  <layout :title="form.name">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('quizzes')">
        {{ $t('Quizzes') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Create') }}
    </h1>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name (Internal)')" />
          </div>
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.title" :error="form.errors.title" :label="$t('Title')" />
          </div>
          <div class="w-full pb-8 pr-6">
            <textarea-input
              v-model="form.description"
              :autosize="true"
              :error="form.errors.description"
              :label="$t('Description')"
              rows="2"
            />
          </div>
          <div v-if="isCreatingRelatedRecord" class="w-full pb-8 pr-6">
            <select-input v-model="form.locale" :error="form.errors.locale" :label="$t('Locale')">
              <option v-for="(locale, key) in locales" :key="key" :value="locale">
                {{ key }}
              </option>
            </select-input>
          </div>
          <div class="w-full pb-8 pr-6">
            <text-input
              v-model="form.passing_score_percentage"
              :error="form.errors.passing_score_percentage"
              :label="$t('Passing score percent (%)')"
              type="number"
            />
          </div>
        </div>
        <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Virtual Proctoring') }}</div>
        <div class="-mb-8 -mr-6 flex w-full flex-wrap p-8">
          <div class="flex w-1/2 items-center justify-between pb-8 pr-6">
            <label class="mr-3 cursor-pointer text-ts-gray-text-bold" for="show_answers_to_users">
              {{ $t('Enable virtual proctoring') }}
            </label>
            <front-small-toggle-input
              id="show_answers_to_users"
              v-model="form.proctoring_enabled"
              :error="form.errors.proctoring_enabled"
            />
          </div>
          <div v-if="form.proctoring_enabled" class="w-full pb-8 pr-6">
            <textarea-input
              v-model="form.proctoring_rules"
              :autosize="true"
              :error="form.errors.proctoring_rules"
              :label="$t('Rules')"
              rows="4"
            />
          </div>
          <div v-if="form.proctoring_enabled" class="w-full pb-8 pr-6">
            <textarea-input
              v-model="form.proctoring_privacy_policy"
              :autosize="true"
              :error="form.errors.proctoring_privacy_policy"
              :label="$t('Privacy Policy')"
              rows="4"
            />
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Create Quiz') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import FrontSmallToggleInput from '@/Shared/FrontSmallToggleInput.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    FrontSmallToggleInput,
    Layout,
    LoadingButton,
    SelectInput,
    TextInput,
    TextareaInput,
  },
  props: {
    locales: Object,
    related_contentable_id: Number,
  },
  data() {
    return {
      form: useForm(
        {
          name: null,
          title: null,
          description: null,
          locale: 'fr',
          related_contentable_id: this.related_contentable_id,
          passing_score_percentage: this.passing_score_percentage,
          proctoring_enabled: false,
          proctoring_rules: null,
          proctoring_privacy_policy: null,
        },
        'QuizCreate'
      ),
    }
  },
  computed: {
    isCreatingRelatedRecord() {
      let urlParams = new URLSearchParams(window.location.search)
      return urlParams.has('contentable')
    },
  },
  watch: {
    'form.proctoring_enabled'(newValue) {
      if (!newValue) {
        this.form.proctoring_rules = null
        this.form.proctoring_privacy_policy = null
      }
    },
  },
  methods: {
    submit() {
      this.form.post(this.route('quizzes.store'))
    },
  },
}
</script>
