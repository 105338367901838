<template>
  <front-layout :title="$t('Action : :action_name', { action_name: action.title })">
    <div class="flex flex-col xl:flex-row xl:justify-between">
      <div class="sm:no-flex flex w-full items-center justify-between" :class="'mb-8'">
        <front-page-title
          :back-url="route('front.actions')"
          :value="$t('Action : :action_name', { action_name: action.title })"
        />
      </div>
    </div>
    <form>
      <div class="flex w-full items-start gap-x-8 gap-y-4">
        <div class="flex w-1/4 flex-col gap-4 rounded-xl border bg-white p-8">
          <div v-if="action.description">
            <h2 class="font-semibold">{{ $t('Description') }}</h2>
            <p>{{ action.description }}</p>
          </div>
          <div class="flex items-end gap-8">
            <select-input
              v-model="formAction.status"
              :error="formAction.errors.status"
              label="Status"
              :options="actionStatuses"
              @change="updateActionStatus"
            />
          </div>

          <text-show :label="$t('Assigned By')" :model-value="action.owner.display_name" />
          <text-show :label="$t('Priority')" :model-value="action.priority" />
          <div v-if="action.start_at">
            <label>
              <div class="form-label font-semibold text-ts-front-label">{{ $t('Starts at') }}</div>
              <show-date :timestamp="action.start_at" />
            </label>
          </div>
          <div v-if="action.due_at">
            <label>
              <div class="form-label font-semibold text-ts-front-label">{{ $t('Due at') }}</div>
              <show-date :timestamp="action.due_at" />
            </label>
          </div>
        </div>
        <div class="flex flex-1 flex-col overflow-hidden rounded-xl border bg-white">
          <div class="flex-1 bg-gray-100">
            <div v-if="loadingMoreComments" class="flex w-full items-center justify-center gap-4 py-8">
              <div class="spinner-red h-8 w-8" name="loading" />
              <div>Loading</div>
            </div>
            <div class="flex max-h-screen flex-col-reverse overflow-y-scroll px-8">
              <template v-if="commentsList.length === 0">
                <div class="flex flex-col border-b border-gray-400 px-4 py-12 first:border-b-0">
                  {{ $t('No comments.') }}
                </div>
              </template>
              <template v-else>
                <template v-for="comment in commentsList" :key="comment.id">
                  <div class="flex flex-col border-b border-gray-400 px-4 py-8 first:border-b-0">
                    <div v-if="comment.content">{{ comment.content }}</div>
                    <div
                      v-if="comment.attachments.length > 0"
                      class="flex flex-wrap items-center gap-2 rounded bg-white p-2"
                    >
                      <front-media-icon
                        v-for="attachment in comment.attachments"
                        :key="attachment.id"
                        class="h-16 w-16"
                        :media="attachment"
                        @click="openFile(attachment)"
                      />
                    </div>
                    <div class="mt-2 flex items-center gap-4 text-xs text-gray-600">
                      <div>{{ comment.user }}</div>
                      <show-date format=" DD MMM, YYYY hh:mm A" :timestamp="comment.created_at" />
                    </div>
                  </div>
                </template>
              </template>
              <div ref="loadMoreIntersect" />
            </div>
          </div>
          <div class="w-full">
            <form class="flex w-full items-center gap-4 p-8" @submit.prevent="submit">
              <button @click.prevent="() => (showCommentsAttachmentsModal = true)">
                <paper-clip-icon class="h-6 w-6" />
              </button>
              <div class="flex-1">
                <textarea-input v-model="form.content" rows="2" />
              </div>
              <front-solid-button type="submit">{{ $t('Submit') }}</front-solid-button>
            </form>
          </div>
        </div>
      </div>
    </form>
    <front-comment-attachments-modal
      :action="action"
      :show="showCommentsAttachmentsModal"
      @close="() => (showCommentsAttachmentsModal = false)"
    />
  </front-layout>
</template>

<script>
import FrontCommentAttachmentsModal from '@/Shared/FrontCommentAttachmentsModal.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontMediaIcon from '@/Shared/FrontMediaIcon.vue'
import FrontPageTitle from '@/Shared/FrontPageTitle.vue'
import FrontSolidButton from '@/Shared/FrontSolidButton.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import ShowDate from '@/Shared/ShowDate.vue'
import TextShow from '@/Shared/TextShow.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { PaperClipIcon } from '@heroicons/vue/24/solid'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    SelectInput,
    TextShow,
    ShowDate,
    FrontSolidButton,
    TextareaInput,
    FrontPageTitle,
    PaperClipIcon,
    FrontCommentAttachmentsModal,
    FrontMediaIcon,
    FrontLayout,
  },
  props: {
    action: Object,
    comments: Object,
    actionStatuses: Object,
  },
  data() {
    return {
      commentsList: this.comments.data,
      formAction: useForm({
        status: this.action.status,
      }),
      form: useForm({
        content: null,
      }),
      initialUrl: this.$page.url,
      loadingMoreComments: false,
      showCommentsAttachmentsModal: false,
    }
  },
  mounted() {
    const observer = new IntersectionObserver((entries) =>
      entries.forEach((entry) => entry.isIntersecting && this.loadMoreComments(), {
        rootMargin: '-150px 0px 0px 0px',
      })
    )

    observer.observe(this.$refs.loadMoreIntersect)
  },
  methods: {
    submit() {
      this.form.post(this.route('front.comments.store', this.action), {
        preserveState: false,
        preserveScroll: true,
      })
    },

    loadMoreComments() {
      if (this.comments.next_page_url === null) {
        return
      }

      this.$inertia.get(
        this.comments.next_page_url,
        {},
        {
          preserveState: true,
          preserveScroll: true,
          only: ['comments'],
          onSuccess: () => {
            this.commentsList = [...this.commentsList, ...this.comments.data]
            window.history.replaceState(null, null, this.initialUrl)
          },
        }
      )
    },
    openFile(file) {
      window.open(file.url, '_blank')
    },
    updateActionStatus() {
      this.formAction.put(this.route('front.actions.status.update', this.action))
    },
  },
}
</script>
