<template>
  <layout :title="form.name">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('quizzes.edit', quiz.id)">
        {{ $t('Banks') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Create') }}
    </h1>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" />
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Create Bank') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Layout,
    LoadingButton,
    TextInput,
  },
  props: {
    quiz: Object,
  },
  data() {
    return {
      sending: false,
      form: useForm(
        {
          quiz_id: this.quiz.id,
          name: null,
        },
        'QuizBankCreate'
      ),
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('banks.store', this.quiz.id), this.form)
    },
  },
}
</script>
