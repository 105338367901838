<template>
  <front-layout :title="$t('Access Codes')">
    <div class="mb-8">
      <breadcrumb
        class="font-heading text-2xl font-semibold"
        :name="$t('Edit')"
        :previous-name="$t('Access Codes')"
        :previous-url="route('front.admin.access-codes')"
      />
    </div>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-col p-8">
          <div class="w-full pb-8 pr-6 lg:w-2/3">
            <front-text-input disabled :error="form.errors.code" :label="$t('Access Code')" :value="code.code" />
          </div>
          <div v-if="groups.length > 0" class="w-full pb-8 pr-6 lg:w-2/3">
            <front-select-input
              v-model="form.group_id"
              :disabled="code.current_enrolments_count > 0"
              :error="form.errors.group"
              :label="$t('Group (optional)')"
            >
              <option :value="null" />
              <option v-for="group in groups" :key="group.id" :value="group.id">
                {{ group.name }}
              </option>
            </front-select-input>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-2/3">
            <front-text-input
              v-model="form.usage_limit"
              :error="form.errors.usage_limit"
              :label="$t('Usage Limit (optional)')"
              :min="code.current_enrolments_count"
              type="number"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-2/3">
            <front-select-input
              v-model="form.is_archived"
              class="pb-2"
              :error="form.errors.is_archived"
              :label="$t('Archived')"
            >
              <option :value="true">{{ $t('Yes') }}</option>
              <option :value="false">{{ $t('No') }}</option>
            </front-select-input>
          </div>
          <h2 class="py-5 text-xl">Courses</h2>
          <span class="mb-4 text-sm" :class="form.errors.courses ? 'text-qualify-red-500' : 'text-ts-front-label'">
            {{ $t('At least one course must be selected.') }}
          </span>
          <div v-for="(course, key) in form.courses" :key="key" class="my-3 w-full pr-6 lg:w-2/3">
            <div
              class="flex cursor-pointer items-center rounded-lg border px-4 py-4"
              :class="{
                'border-ts-red-500': course.selected,
                'hover:bg-ts-front-field-light': code.current_enrolments_count === 0,
              }"
              @click="selectCourse(course)"
            >
              <icon
                class="ml-2 mr-8 h-6 w-6 flex-shrink-0 rounded-full bg-gray-300 p-1"
                :class="course.selected ? 'bg-ts-red-500 fill-white' : 'fill-gray-300'"
                name="checkmark"
              />
              <label>{{ course.name }}</label>
            </div>
          </div>
        </div>
        <div class="mt-6 flex items-center justify-between border-t border-gray-100 px-8 py-8">
          <button
            v-if="code.current_enrolments_count < 1"
            class="text-red-500 hover:underline"
            tabindex="-1"
            type="button"
            @click="destroy"
          >
            {{ $t('Delete Code') }}
          </button>
          <span v-else>&nbsp;</span>
          <front-loading-button :loading="form.processing" type="submit">{{ $t('Update Code') }}</front-loading-button>
        </div>
      </form>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontSelectInput from '@/Shared/FrontSelectInput.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import Icon from '@/Shared/Icon.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    FrontLayout,
    FrontLoadingButton,
    FrontSelectInput,
    FrontTextInput,
    Icon,
  },
  props: {
    code: Object,
    courses: Array,
    groups: Array,
  },
  data() {
    return {
      previous: {
        url: this.route('front.admin.access-codes'),
        name: this.$t('Access Codes'),
      },
      form: useForm({
        code: this.code.code,
        usage_limit: this.code.usage_limit,
        group_id: this.code.group_id,
        courses: this.courses,
        is_archived: this.code.is_archived,
      }),
    }
  },
  methods: {
    submit() {
      this.form.put(this.route('front.admin.access-codes.update', this.code.id))
    },
    selectCourse(course) {
      if (this.code.current_enrolments_count === 0 && course.unassigned_enrolments_count > 0)
        course.selected = !course.selected
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this access code?'))) {
        this.$inertia.delete(this.route('front.admin.access-codes.destroy', this.code.id))
      }
    },
  },
}
</script>
