<template>
  <front-layout :title="$t('File Folders')">
    <div class="flex flex-col xl:flex-row xl:justify-between">
      <div class="sm:no-flex mb-8 flex w-full items-center justify-between">
        <breadcrumb :name="$t('Folders')" />
      </div>
      <div class="mb-6 flex w-full items-center justify-end">
        <front-search-filter
          v-model="form.search"
          class="flex w-full flex-col sm:flex-row lg:max-w-sm"
          :filter-show="false"
          @reset="reset"
        />
      </div>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow-lg">
      <table class="front-table w-full">
        <thead>
          <tr>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
              {{ $t('Name') }}
            </th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">{{ $t('Files') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">{{ $t('Created') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">{{ $t('Last Updated') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="folders.data.length === 0">
            <td class="border-t px-6 py-4" colspan="5">{{ $t('No folders found.') }}</td>
          </tr>
          <tr v-for="folder in folders.data" :key="folder.id" class="cursor-pointer">
            <td class="font-semibold text-ts-gray-text-bold">
              <front-truncate-text class="max-w-md" :text="folder.name" />
            </td>
            <td class="font-semibold text-ts-gray-text-bold">
              {{ folder.files_count }}
            </td>
            <td class="font-semibold text-ts-gray-text-bold">{{ folder.created_at }}</td>
            <td class="font-semibold text-ts-gray-text-bold">{{ folder.updated_at }}</td>
            <td class="font-semibold text-ts-gray-text-bold">
              <InertiaLink :href="route('front.folders.show', folder.id)">View Files</InertiaLink>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontSearchFilter from '@/Shared/FrontSearchFilter.vue'
import FrontTruncateText from '@/Shared/FrontTruncateText.vue'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    Breadcrumb,
    FrontLayout,
    FrontSearchFilter,
    FrontTruncateText,
  },
  props: {
    folders: Object,
    filters: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
      },
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(this.route('front.folders'), Object.keys(query).length ? query : { remember: 'forget' }, {
          preserveState: true,
          replace: true,
        })
      }, 300),
      deep: true,
    },
  },
}
</script>
