<template>
  <div class="w-full max-w-lg overflow-hidden rounded-lg border bg-white shadow">
    <div class="flex flex-col p-4">
      <h2 class="mb-5 mt-0 text-xl">{{ subscriptionPlan.title }}</h2>
      <p class="mb-1 text-sm leading-tight text-darkGray-800">
        {{ subscriptionPlan.description }}
      </p>
    </div>
    <div class="flex items-center justify-end bg-gray-100 p-4">
      <template v-if="!isSubscribed">
        <text-input
          v-model="form.quantity"
          css-class="text-center w-20 mx-4"
          :error="form.errors.quantity"
          type="number"
        />
        <button class="btn-blue" @click="subscribe">{{ $t('Subscribe') }}</button>
      </template>
      <div v-else class="float-right px-2 py-2 text-sm text-gray-600">{{ $t('Currently Subscribed') }}</div>
    </div>
  </div>
</template>
<script>
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    TextInput,
  },
  props: {
    organization: Object,
    subscriptionPlan: Object,
    isSubscribed: Boolean,
  },
  data() {
    return {
      form: useForm({
        quantity: 1,
      }),
    }
  },
  methods: {
    subscribe() {
      this.form.post(
        this.route('organizations.subscription-plans.store', [this.organization.id, this.subscriptionPlan.id])
      )
    },
  },
}
</script>
