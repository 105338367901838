const Operators = Object.freeze({
  '=': 'is',
  '!=': 'not is',
  '>': 'greater than',
  '<': 'less than',
  '>=': 'greater than or equal to',
  '<=': 'less than or equal to',
  between: 'is between',
  not_between: 'is not between',
  checked: 'is checked',
  unchecked: 'is not checked',
})

function getOperators(keysToKeep) {
  return Object.keys(Operators)
    .filter((key) => keysToKeep.includes(key))
    .reduce((result, key) => {
      result[key] = Operators[key]
      return result
    }, {})
}

export { getOperators }
