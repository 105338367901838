<template>
  <front-modal class="rounded text-base" :show="show && !certificate.is_training_record" width="auto" @close="close">
    <div class="max-w-lg overflow-hidden rounded bg-white p-6 shadow md:p-10">
      <div class="mb-4 text-center">
        <h1 class="mt-4 text-3xl font-bold text-black">{{ certificate.name }}</h1>
      </div>
      <div class="w-full" :class="{ 'py-4': certificate.plastic_card_preview_format }">
        <plastic-card-preview
          v-if="certificate.plastic_card_preview_format"
          :background-url="certificate.plastic_card_preview_format.background_url"
          class="mx-auto block w-120 max-w-xs rounded-xl bg-gray-100 px-0 shadow"
          :crop="certificate.plastic_card_preview_format.crop"
          :pdf-fields="certificate.pdf_fields"
          :pdf-images="certificate.pdf_images"
          :pdf-settings="certificate.plastic_card_preview_format.pdf_settings"
        />
      </div>
      <div class="flex w-full justify-around pt-4">
        <div class="flex flex-col px-2 text-center md:px-4">
          <span class="font-semibold text-ts-gray-text-bold">{{ formatDate(certificate.issued_at) }}</span>
          <span class="mt-2 text-sm">{{ $t('Issue date') }}</span>
        </div>
        <div class="flex flex-col px-2 text-center md:px-4">
          <span class="font-semibold text-ts-gray-text-bold">{{ formatDate(certificate.expires_at) }}</span>
          <span class="mt-2 text-sm">{{ $t('Expiry date') }}</span>
        </div>
        <div v-if="certificate.final_grade" class="flex flex-col px-2 text-center md:px-4">
          <span class="font-semibold text-ts-gray-text-bold">{{ certificate.final_grade }}%</span>
          <span class="mt-2 text-sm">{{ $t('Final grade') }}</span>
        </div>
      </div>
      <div
        v-if="!certificate.is_training_record && certificate.preview_image && !certificate.plastic_card_preview_format"
        class="h-64 pb-4 pt-12"
      >
        <img
          :alt="$t(':name Preview Image', { name: certificate.name })"
          class="object-fit min-w-fill mx-auto h-full"
          :src="certificate.preview_image"
        />
      </div>
      <div class="mt-8 grid grid-cols-2 gap-4">
        <button
          v-if="certificate.can.edit"
          class="flex items-center rounded-lg border-ts-gray-500 bg-ts-gray-200 px-6 py-3 font-semibold text-ts-gray-text-bold"
          @click="openEditExpiryModal()"
        >
          <icon class="mr-2 block h-6 w-6 text-black" name="cog" />
          {{ $t('Edit Expiry') }}
        </button>
        <a
          class="flex items-center rounded-lg border-ts-gray-500 bg-ts-gray-200 px-6 py-3 font-semibold text-ts-gray-text-bold"
          :href="route('front.certificates.download.all', [certificate])"
        >
          <icon class="mr-2 block h-6 w-6 text-black" name="download-modern" />
          {{ $t('Download') }}
        </a>
        <template v-if="!certificate.is_training_record">
          <button
            v-if="!certificate.user_signature_id"
            class="flex items-center rounded-lg border-ts-gray-500 bg-ts-gray-200 px-6 py-3 font-semibold text-ts-gray-text-bold"
            @click="openSignCertificateModal()"
          >
            <icon class="mr-2 block h-6 w-6 text-black" name="pencil" />
            {{ $t('Sign Certificate') }}
          </button>
          <button
            v-if="!certificate.manager_signature_id"
            class="flex items-center rounded-lg border-ts-gray-500 bg-ts-gray-200 px-6 py-3 font-semibold text-ts-gray-text-bold"
            @click="openRequestSignatureModal()"
          >
            <icon class="mr-2 block h-6 w-6 text-black" name="pencil" />
            {{ $t('Request Signature') }}
          </button>
          <button
            class="flex items-center rounded-lg border-ts-gray-500 bg-ts-gray-200 px-6 py-3 font-semibold text-ts-gray-text-bold"
            @click="openSendByEmailModal()"
          >
            <icon class="mr-2 block h-6 w-6 text-black" name="email" />
            {{ $t('Send by Email') }}
          </button>

          <button
            class="flex items-center rounded-lg border-ts-gray-500 bg-ts-gray-200 px-6 py-3 font-semibold text-ts-gray-text-bold"
            @click="$emit('recertify', certificate.id)"
          >
            <icon class="mr-2 block h-6 w-6 text-black" name="certificate" />
            {{ $t('Recertify') }}
          </button>
        </template>
      </div>
      <div class="flex items-center justify-center bg-white px-8 pt-8">
        <front-outline-button class="mx-2" type="button" @click="close">{{ $t('Close') }}</front-outline-button>
      </div>
    </div>
  </front-modal>
</template>

<script>
import FrontModal from '@/Shared/FrontModal.vue'
import FrontOutlineButton from '@/Shared/FrontOutlineButton.vue'
import Icon from '@/Shared/Icon.vue'
import PlasticCardPreview from '@/Shared/PlasticCardPreview.vue'
import { query } from '@/Utils/Helpers'
import moment from 'moment'

export default {
  components: {
    FrontModal,
    FrontOutlineButton,
    Icon,
    PlasticCardPreview,
  },
  props: {
    show: Boolean,
    certificate: Object,
    organization: Object,
  },
  methods: {
    query,
    close() {
      this.$emit('close')
    },
    openEditExpiryModal() {
      this.$emit('editExpiry')
    },
    openSendByEmailModal() {
      this.$emit('sendByEmail', this.certificate)
    },
    openSignCertificateModal() {
      this.$emit('signCertificate')
    },
    openRequestSignatureModal() {
      this.$emit('requestSignature')
    },
    formatDate(date) {
      if (!date) {
        return 'No Expiry Set'
      }
      return moment(date).format('Y-MM-DD')
    },
    getCertificateName() {
      return this.certificate.course ? this.certificate.course.name : this.certificate.name
    },
  },
}
</script>
