<template>
  <layout :title="$t('Edit Session')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('instructor.classes')">
        {{ $t('Classes') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Edit session') }}
    </h1>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.webinar_url" :error="form.errors.webinar_url" :label="$t('Webinar URL')" />
          </div>
        </div>
        <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Update Session') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Layout,
    LoadingButton,
    TextInput,
  },
  props: {
    session: Object,
    instructors: Array,
  },
  data() {
    return {
      form: useForm(
        {
          webinar_url: this.session.webinar_url,
        },
        'SessionEdit'
      ),
    }
  },
  methods: {
    submit() {
      this.form.put(this.route('instructor.sessions.update', this.session.id))
    },
  },
}
</script>
