<template>
  <layout :title="$t('Add Action')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('organizations.edit', organization)">
        {{ $t('Organizations') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Add Action') }}
    </h1>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pr-6">
            <text-input v-model="form.title" :error="form.errors.title" :label="$t('Title')" />
          </div>
        </div>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pr-6">
            <text-input v-model="form.description" :error="form.errors.description" :label="$t('Description')" />
          </div>
        </div>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pr-6">
            <front-user-search-input
              v-model="form.assignee"
              :error="form.errors.description"
              :label="$t('Assigned To')"
            />
          </div>
        </div>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pr-6">
            <select-input
              v-model="form.priority"
              :error="form.errors.priority"
              :label="$t('Priority')"
              :options="priority_options"
            />
          </div>
        </div>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pr-6">
            <date-input v-model="form.start_at" :error="form.errors.start_at" :label="$t('Starts at')" />
          </div>
        </div>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <date-input v-model="form.due_at" :error="form.errors.due_at" :label="$t('Due at')" />
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Add Action') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import DateInput from '@/Shared/DateInput.vue'
import FrontUserSearchInput from '@/Shared/FrontUserSearchInput.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    DateInput,
    FrontUserSearchInput,
    Layout,
    LoadingButton,
    SelectInput,
    TextInput,
  },
  props: {
    organization: Object,
    priority_options: Object,
  },
  data() {
    return {
      form: useForm({
        assignee: null,
        title: null,
        description: null,
        priority: 1,
        start_at: new Date().toDateString(),
        due_at: null,
        form_answer_id: this.actionable?.id,
      }),
    }
  },
  methods: {
    submit() {
      this.form
        .transform((data) => {
          return {
            ...data,
            assignee: data.assignee?.id,
          }
        })
        .post(this.route('organizations.actions.store', this.organization), {
          preserveState: true,
          onSuccess: () => {
            this.close()
          },
        })
    },
  },
}
</script>
