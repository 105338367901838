<template>
  <layout :title="form.name">
    <div class="mb-8">
      <breadcrumb
        :name="courseCategory.name"
        :previous-name="$t('Categories')"
        :previous-url="route('course-categories')"
      />
    </div>
    <deleted-message v-if="courseCategory.deleted_at" class="mb-6">
      {{ $t('This course category has been deleted.') }}
    </deleted-message>
    <div class="flex max-w-3xl flex-wrap">
      <div class="w-full lg:w-3/5 xl:w-2/3">
        <div class="overflow-hidden rounded bg-white shadow">
          <form @submit.prevent="submit">
            <div class="-mb-8 -mr-6 flex flex-wrap p-8">
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" />
              </div>
            </div>
            <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
              <button
                v-if="courseCategory.deleted_at"
                class="text-red-500 hover:underline"
                tabindex="-1"
                type="button"
                @click="restore"
              >
                {{ $t('Restore') }}
              </button>
              <button v-else class="text-red-500 hover:underline" tabindex="-1" type="button" @click="destroy">
                {{ $t('Delete') }}
              </button>
              <loading-button class="btn-blue" :loading="form.processing" type="submit">
                {{ $t('Update Course Category') }}
              </loading-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import DeletedMessage from '@/Shared/DeletedMessage.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    DeletedMessage,
    Layout,
    LoadingButton,
    TextInput,
  },
  props: {
    courseCategory: Object,
  },
  data() {
    return {
      form: useForm(
        {
          name: this.courseCategory.name,
        },
        'CourseCategoriesEdit'
      ),
    }
  },
  methods: {
    submit() {
      this.form.put(this.route('course-categories.update', this.courseCategory.id))
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this course category?'))) {
        this.$inertia.delete(this.route('course-categories.destroy', this.courseCategory.id))
      }
    },
    restore() {
      if (confirm(this.$t('Are you sure you want to restore this course category?'))) {
        this.$inertia.put(this.route('course-categories.restore', this.courseCategory.id))
      }
    },
  },
}
</script>
