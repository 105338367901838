<template>
  <front-modal class="rounded text-base" :show="show" width="auto" @close="close">
    <div class="flex flex-wrap bg-white px-8 pt-8">
      <breadcrumb :name="$t('Create new location')" />
    </div>
    <div class="max-w-sm overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-6 pr-6">
            <text-input v-model="form.name" :error="form.errors.name" label="Location name" />
          </div>
          <div class="w-full pb-6 pr-6">
            <canada-post-address-complete-input
              v-if="$page.props.app.tenant.has_address_complete_api_enabled"
              ref="billing_address_complete"
              v-model="billing_address_complete"
              class="w-full"
              :label="$t('Address')"
              label-class="text-darkGray-700 text-lg"
              required
              :search-by="['Text']"
              search-input-class="rounded"
              track-by="Id"
            >
              <div v-if="form.address" class="flex items-center justify-between">
                <div class="truncate">{{ form.address }}</div>
              </div>
              <template #option="{ option, selected }">
                <div class="flex items-center justify-between">
                  <div class="text-md" :class="{ 'text-gray-600': !selected, 'text-white': selected }">
                    {{ option.Text.concat(' ', option.Description) }}
                  </div>
                  <icon v-if="option.Next == 'Find'" class="ml-2 h-3 w-3 flex-shrink-0" name="chevron-right" />
                </div>
              </template>
            </canada-post-address-complete-input>
            <template v-else>
              <label class="mb-2 block text-lg text-darkGray-700" for="address">{{ $t('Address') }}</label>
              <input
                id="address"
                v-model="form.address"
                class="block w-full rounded border px-3 py-2 transition duration-150 ease-in-out"
                required
                type="text"
              />
            </template>
            <div v-if="form.errors.address" class="form-error">{{ form.errors.address }}</div>
          </div>
          <div class="w-1/2 pb-6 pr-6">
            <label class="mb-2 block text-lg text-darkGray-700" for="city">{{ $t('City') }}</label>
            <input
              id="city"
              v-model="form.city"
              class="block w-full rounded border px-3 py-2 transition duration-150 ease-in-out"
              required
              type="text"
            />
            <div v-if="form.errors.city" class="form-error">{{ form.errors.city }}</div>
          </div>
          <div class="w-1/2 pb-6 pr-6">
            <label class="mb-2 block text-lg text-darkGray-700" for="province">{{ $t('Province') }}</label>
            <select
              id="province"
              v-model="form.province"
              class="block w-full rounded border px-3 py-2 transition duration-150 ease-in-out"
            >
              <slot>
                <option v-for="(province, key) in getRegions" :key="key" :value="province.name">
                  {{ province.name }}
                </option>
              </slot>
            </select>
            <div v-if="form.errors.province" class="form-error">{{ form.errors.province }}</div>
          </div>
          <div class="w-1/2 pb-6 pr-6">
            <label class="mb-2 block text-lg text-darkGray-700" for="postal_code">{{ $t('Postal Code') }}</label>
            <input
              id="postal_code"
              v-model="form.postal_code"
              class="block w-full rounded border px-3 py-2 transition duration-150 ease-in-out"
              required
              type="text"
            />
            <div v-if="form.errors.postal_code" class="form-error">{{ form.errors.postal_code }}</div>
          </div>
          <div class="w-1/2 pb-6 pr-6">
            <label class="mb-2 block text-lg text-darkGray-700" for="country">{{ $t('Country') }}</label>
            <select
              id="country"
              v-model="form.country"
              class="block w-full rounded border px-3 py-2 transition duration-150 ease-in-out"
              @change="handleBillingCountryChange($event)"
            >
              <slot>
                <option v-for="(country, key) in getCountries" :key="key" :value="country.code">
                  {{ country.name }}
                </option>
              </slot>
            </select>
            <div v-if="form.errors.country" class="form-error">{{ form.errors.country }}</div>
          </div>
        </div>
        <div class="flex items-center justify-center bg-white px-8 pb-8 pt-4">
          <front-secondary-button type="button" @click="close">{{ $t('Close') }}</front-secondary-button>
          <front-loading-button class="ml-4" :loading="form.processing" type="submit">
            {{ $t('Create') }}
          </front-loading-button>
        </div>
      </form>
    </div>
  </front-modal>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import CanadaPostAddressCompleteInput from '@/Shared/CanadaPostAddressCompleteInput.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontModal from '@/Shared/FrontModal.vue'
import FrontSecondaryButton from '@/Shared/FrontSecondaryButton.vue'
import Icon from '@/Shared/Icon.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    CanadaPostAddressCompleteInput,
    FrontLoadingButton,
    FrontModal,
    FrontSecondaryButton,
    Icon,
    TextInput,
  },
  props: {
    show: Boolean,
    regions: Array,
  },
  data() {
    return {
      billing_address_complete: null,
      form: useForm(
        {
          name: null,
          address: null,
          city: null,
          province: null,
          postal_code: null,
          country: null,
        },
        'LocationCreateModal'
      ),
    }
  },
  computed: {
    getRegions() {
      return this.regions.filter((region) => region.country === this.form.country)
    },
    getCountries() {
      return [
        { name: this.$t('Canada'), code: 'CA' },
        { name: this.$t('United States'), code: 'US' },
      ]
    },
  },
  watch: {
    billing_address_complete: function (newVal, oldVal) {
      this.form.address = newVal?.Line1
      this.form.city = newVal?.City
      this.form.postal_code = newVal?.PostalCode
      this.form.province = newVal?.ProvinceName
      this.form.country = newVal ? newVal.CountryIso2 : oldVal.CountryIso2
    },
    'form.country': function () {
      this.form.reset('province')
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    submit() {
      this.form.post(this.route('front.manager.locations.store'), {
        onSuccess: () => this.close(),
      })
    },
    handleBillingCountryChange(event) {
      if (this.$refs.billing_address_complete) this.$refs.billing_address_complete._data.country = event.target.value
    },
    handleShippingCountryChange(event) {
      if (this.$refs.shipping_address_complete) this.$refs.shipping_address_complete._data.country = event.target.value
    },
  },
}
</script>
