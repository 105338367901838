<template>
  <layout :title="form.name">
    <div class="mb-8">
      <breadcrumb :name="course.name ?? ''" :previous-name="$t('Courses')" :previous-url="route('courses')" />
    </div>
    <deleted-message v-if="course.deleted_at" class="mb-6">{{ $t('This course has been deleted.') }}</deleted-message>
    <archived-message v-if="course.archived_at" class="mb-6">
      {{ $t('This course has been archived.') }}
    </archived-message>
    <div class="flex max-w-3xl flex-wrap">
      <div class="w-full lg:w-3/5 xl:w-2/3">
        <div class="overflow-hidden rounded bg-white shadow">
          <form @submit.prevent="submit">
            <div class="-mb-8 -mr-6 flex flex-wrap p-8">
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input v-model="form.name_en" :error="form.errors.name_en" :label="$t('Name')" />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input v-model="form.name_fr" :error="form.errors.name_fr" :label="$t('Name (FR)')" />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <textarea-input
                  v-model="form.description_en"
                  :autosize="true"
                  :error="form.errors.description_en"
                  :label="$t('Description')"
                  :rows="2"
                />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <textarea-input
                  v-model="form.description_fr"
                  :autosize="true"
                  :error="form.errors.description_fr"
                  :label="$t('Description (FR)')"
                  :rows="2"
                />
              </div>
              <div class="w-1/2 pb-8 pr-6">
                <label class="mb-2 block">{{ $t('Languages:') }}</label>
                <div class="flex">
                  <div class="mr-4">
                    <checkbox-input v-model="form.locale_en" :error="form.errors.locale_en" :label="$t('English')" />
                  </div>
                  <div class="mr-4">
                    <checkbox-input v-model="form.locale_fr" :error="form.errors.locale_fr" :label="$t('French')" />
                  </div>
                </div>
              </div>
              <div v-if="courseCategories.length > 0" class="w-full pb-8 pr-6 lg:w-1/2">
                <select-input
                  v-model="form.course_category_id"
                  :error="form.errors.course_category_id"
                  :label="$t('Course Category')"
                >
                  <option :value="null" />
                  <option v-for="category in courseCategories" :key="category.id" :value="category.id">
                    {{ category.name }}
                  </option>
                </select-input>
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <select-input v-model="form.type" :error="form.errors.type" :label="$t('Type')">
                  <option :value="null" />
                  <option v-for="(type, index) in types" :key="index" :value="type">
                    {{ type }}
                  </option>
                </select-input>
              </div>
              <div v-if="isMinistryOfLabourType" class="w-full pb-8 pr-6 lg:w-1/2">
                <select-input
                  v-model="form.ministry_of_labour_code"
                  :error="form.errors.ministry_of_labour_code"
                  :label="$t('Ministry of Labour code')"
                >
                  <option :value="null" />
                  <option
                    v-for="skillspassCourse in skillspassCourses"
                    :key="skillspassCourse.code"
                    :value="skillspassCourse.code"
                  >
                    {{ skillspassCourse.code }} - {{ skillspassCourse.name }}
                  </option>
                </select-input>
              </div>
              <div v-if="isMinistryOfLabourType" class="w-full pb-8 pr-6 lg:w-1/2">
                <select-input
                  v-model="form.collects_learner_status"
                  :error="form.errors.collects_learner_status"
                  :label="$t('Collect Learner Status')"
                >
                  <option :value="false">No</option>
                  <option :value="true">Yes</option>
                </select-input>
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input v-model="form.price" :error="form.errors.price" :label="$t('Price')" />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <select-input
                  v-model="form.certificate_template_id"
                  :error="form.errors.certificate_template_id"
                  :label="$t('Certificate Template')"
                >
                  <option :value="null" />
                  <option
                    v-for="certificateTemplate in certificateTemplates"
                    :key="certificateTemplate.id"
                    :value="certificateTemplate.id"
                  >
                    {{ certificateTemplate.name }}
                  </option>
                </select-input>
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <radio-input
                  v-model="form.resell"
                  :error="form.errors.resell"
                  :label="$t('Let others sell this course?')"
                  type="radio"
                />
              </div>
              <div v-if="form.resell" class="w-full pb-8 pr-6 lg:w-1/2">
                <label class="form-label" for="resell_seats">{{ $t('Seats that can be resold:') }}</label>
                <div class="flex">
                  <div class="w-2/3 pr-3">
                    <select-input id="resell_seats" v-model="form.resell_seats">
                      <option value="percentage">{{ $t('Percentage') }}</option>
                      <option value="quantity">{{ $t('Quantity') }}</option>
                    </select-input>
                  </div>
                  <div class="w-1/3">
                    <div v-if="form.resell_seats === 'percentage'" class="relative">
                      <text-input v-model="form.resell_seats_percentage" maxlength="3" />
                      <div class="absolute right-0 top-0 flex h-full items-center pr-3 text-gray-600">%</div>
                    </div>
                    <text-input v-if="form.resell_seats === 'quantity'" v-model="form.resell_seats_quantity" />
                  </div>
                </div>
                <div v-if="form.errors.resell_seats_percentage || form.errors.resell_seats_quantity" class="form-error">
                  {{ form.errors.resell_seats_percentage || form.errors.resell_seats_quantity }}
                </div>
              </div>
              <div v-if="form.resell" class="w-full pb-8 pr-6 lg:w-1/2">
                <label class="form-label" for="resell_commission">{{ $t('Commission rate:') }}</label>
                <div class="relative">
                  <text-input id="resell_commission" v-model="form.resell_commission" maxlength="3" />
                  <div class="absolute right-0 top-0 flex h-full items-center pr-3 text-gray-600">%</div>
                </div>
                <div v-if="form.errors.resell_commission" class="form-error">
                  {{ form.errors.resell_commission }}
                </div>
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <radio-input
                  v-model="form.reset_progresses"
                  :error="form.errors.reset_progresses"
                  :label="$t('Reset Progress?')"
                  type="radio"
                />
              </div>
              <div v-if="form.reset_progresses" class="w-full pb-8 pr-6">
                <textarea-input
                  v-model="form.progress_reset_explanation_en"
                  :autosize="true"
                  :error="form.errors.progress_reset_explanation_en"
                  :label="$t('Reset Explanation EN (Email/Notification)')"
                  :rows="2"
                />
              </div>
              <div v-if="form.reset_progresses" class="w-full pb-8 pr-6">
                <textarea-input
                  v-model="form.progress_reset_explanation_fr"
                  :autosize="true"
                  :error="form.errors.progress_reset_explanation_fr"
                  :label="$t('Reset Explanation FR (Email/Notification)')"
                  :rows="2"
                />
              </div>
              <div v-if="form.reset_progresses" class="w-full pb-8 pr-6 lg:w-1/2">
                <select-input
                  v-model="form.reset_content_id"
                  :error="form.errors.reset_content_id"
                  :label="$t('Content For Triggering Reset')"
                >
                  <option :value="null" />
                  <option v-for="content in course.contents" :key="content.id" :value="content.id">
                    {{ content.name }}
                  </option>
                </select-input>
              </div>
              <div v-if="form.reset_progresses" class="w-full pb-8 pr-6 lg:w-1/2">
                <label class="form-label" for="days_until_progress_reset">
                  {{ $t('Reset Content Progress After:') }}
                </label>
                <div class="relative">
                  <text-input id="days_until_progress_reset" v-model="form.days_until_progress_reset" maxlength="3" />
                  <div class="absolute right-0 top-0 flex h-full items-center pr-3 text-gray-600">days</div>
                </div>
                <div v-if="form.errors.days_until_progress_reset" class="form-error">
                  {{ form.errors.days_until_progress_reset }}
                </div>
              </div>
              <div v-if="form.reset_progresses" class="w-full pb-8 pr-6 lg:w-1/2">
                <label class="form-label" for="days_until_progress_reset_notification">
                  {{ $t('Send Progress Notification Before:') }}
                </label>
                <div class="relative">
                  <text-input
                    id="days_until_progress_reset_notification"
                    v-model="form.days_until_progress_reset_notification"
                    maxlength="3"
                  />
                  <div class="absolute right-0 top-0 flex h-full items-center pr-3 text-gray-600">days</div>
                </div>
                <div v-if="form.errors.days_until_progress_reset_notification" class="form-error">
                  {{ form.errors.days_until_progress_reset_notification }}
                </div>
              </div>
            </div>
            <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
              <div class="space-x-4">
                <button
                  v-if="course.deleted_at"
                  class="text-red-500 hover:underline"
                  tabindex="-1"
                  type="button"
                  @click="restore"
                >
                  {{ $t('Restore Course') }}
                </button>
                <button v-else class="text-red-500 hover:underline" tabindex="-1" type="button" @click="destroy">
                  {{ $t('Delete Course') }}
                </button>
                <button
                  v-if="course.archived_at"
                  class="text-red-500 hover:underline"
                  tabindex="-1"
                  type="button"
                  @click="unarchive"
                >
                  {{ $t('Unarchive') }}
                </button>
                <button v-else class="text-red-500 hover:underline" tabindex="-1" type="button" @click="archive">
                  {{ $t('Archive') }}
                </button>
              </div>
              <loading-button class="btn-blue" :loading="form.processing" type="submit">
                {{ $t('Update Course') }}
              </loading-button>
            </div>
          </form>
        </div>
      </div>
      <div class="flex w-full flex-col pt-12 lg:w-2/5 lg:pl-8 lg:pt-0 xl:w-1/3">
        <div class="mb-12 lg:mb-8">
          <calendar-card v-if="course.calendar_url" :calendar-url="course.calendar_url" />
        </div>
        <version-card
          :last-modified="course.versionSummary.last_modified"
          :link="route('courses.versions.show', course.id)"
        />
        <div class="flex w-full flex-col pt-8">
          <div class="mb-12 overflow-hidden rounded bg-white shadow lg:mb-8">
            <div class="flex items-center justify-between px-6 pb-3 pt-4">
              <div class="font-bold">{{ $t('Follow Up Courses') }}</div>
              <InertiaLink class="btn-blue py-2" :href="route('courses.follow-up.create', course.id)">
                {{ $t('Add') }}
              </InertiaLink>
            </div>
            <table class="w-full">
              <tbody>
                <tr
                  v-for="followUpCourse in followUpCourses"
                  :key="followUpCourse.id"
                  class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
                >
                  <TableData
                    class="border-t px-6 py-4"
                    @click="$inertia.visit(route('courses.edit', followUpCourse.id))"
                  >
                    {{ followUpCourse.name }}
                  </TableData>
                  <TableData class="w-min border-t px-4 align-middle">
                    <icon
                      class="block h-4 w-4 fill-gray-400"
                      name="trash"
                      @click="deleteFollowUpCourse(followUpCourse.id)"
                    />
                  </TableData>
                </tr>
                <tr v-if="followUpCourses.length === 0">
                  <TableData class="border-t px-6 py-4">{{ $t('No courses found.') }}</TableData>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-8 flex flex-col pb-4 md:flex-row md:items-center md:justify-between">
      <h2 class="mb-4 text-lg md:mb-0">{{ $t('Contents') }}</h2>
      <div class="flex flex-row items-center">
        <div v-if="!reordering" class="flex space-x-2 pr-2">
          <select v-model="filterForm.archived" class="form-select">
            <option :value="null">{{ $t('Without Archived') }}</option>
            <option value="with">{{ $t('With Archived') }}</option>
            <option value="only">{{ $t('Only Archived') }}</option>
          </select>
          <select v-model="filterForm.deleted" class="form-select">
            <option :value="null">{{ $t('Without Deleted') }}</option>
            <option value="with">{{ $t('With Deleted') }}</option>
            <option value="only">{{ $t('Only Deleted') }}</option>
          </select>
        </div>
        <InertiaLink class="btn-blue" :href="route('course.contents.edit', course.id)">
          <span>{{ $t('Edit') }}</span>
        </InertiaLink>
      </div>
    </div>
    <div class="mt-1 overflow-x-auto rounded bg-white shadow md:mt-4">
      <SimpleTable class="w-full">
        <template #head>
          <TableHead />
          <TableHead>{{ $t('Name (English)') }}</TableHead>
          <TableHead>{{ $t('Name (French)') }}</TableHead>
          <TableHead>{{ $t('Required for Completion') }}</TableHead>
          <TableHead>{{ $t('Prerequisite') }}</TableHead>
          <TableHead />
        </template>
        <TableRow v-if="course.contents.length === 0">
          <TableData :colspan="reordering ? 6 : 5">{{ $t('No contents found.') }}</TableData>
        </TableRow>
        <TableRow v-for="content in course.contents" :key="content.id">
          <TableData class="flex whitespace-nowrap">
            <archived-badge v-if="content.archived_at" />
            <deleted-badge v-if="content.deleted_at" />
          </TableData>
          <TableData>
            <div>{{ content.contentable.name }}</div>
            <div class="mt-1 text-xs capitalize text-gray-600">{{ content.contentable_type }}</div>
          </TableData>
          <TableData>
            <div v-if="content.contentable_fr && content.contentable_type !== 'instructor-led-classroom'">
              {{ content.contentable_fr.name }}
            </div>
            <div v-else>-</div>
          </TableData>
          <TableData>{{ content.is_required ? 'Yes' : '-' }}</TableData>
          <TableData class="capitalize">
            <span v-if="content.prerequisite">
              {{ content.prerequisite.contentable_type + ' : ' + content.prerequisite.name }}
            </span>
            <span v-else>-</span>
          </TableData>
          <TableData class="w-min align-middle" />
        </TableRow>
      </SimpleTable>
    </div>
  </layout>
</template>

<script>
import ArchivedBadge from '@/Shared/ArchivedBadge.vue'
import ArchivedMessage from '@/Shared/ArchivedMessage.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import CalendarCard from '@/Shared/CalendarCard.vue'
import CheckboxInput from '@/Shared/CheckboxInput.vue'
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import DeletedMessage from '@/Shared/DeletedMessage.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import RadioInput from '@/Shared/RadioInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import VersionCard from '@/Shared/VersionCard.vue'
import { useForm } from '@inertiajs/vue3'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
    ArchivedBadge,
    ArchivedMessage,
    Breadcrumb,
    CalendarCard,
    CheckboxInput,
    DeletedBadge,
    DeletedMessage,
    Icon,
    Layout,
    LoadingButton,
    RadioInput,
    SelectInput,
    TextInput,
    TextareaInput,
    VersionCard,
  },
  props: {
    course: Object,
    types: Array,
    courseCategories: Array,
    skillspassCourses: Array,
    followUpCourses: Array,
    certificateTemplates: Array,
    filters: Object,
  },
  data() {
    return {
      syncing: false,
      reordering: false,
      syncingError: false,
      form: useForm(
        {
          name_en: this.course.name_en,
          name_fr: this.course.name_fr,
          description_en: this.course.description_en,
          description_fr: this.course.description_fr,
          type: this.course.type,
          course_category_id: this.course.course_category_id,
          ministry_of_labour_code: this.course.ministry_of_labour_code,
          collects_learner_status: this.course.collects_learner_status,
          price: this.course.price,
          certificate_template_id: this.course.certificate_template_id,
          resell: this.course.resell,
          resell_seats: this.course.resell_seats_percentage ? 'percentage' : 'quantity',
          resell_seats_percentage: this.course.resell_seats_percentage,
          resell_seats_quantity: this.course.resell_seats_quantity,
          resell_commission: this.course.resell_commission,
          locale_en: this.course.locale_en,
          locale_fr: this.course.locale_fr,
          reset_progresses: this.course.reset_progresses,
          reset_content_id: this.course.reset_content_id,
          progress_reset_explanation_en: this.course.progress_reset_explanation_en,
          progress_reset_explanation_fr: this.course.progress_reset_explanation_fr,
          days_until_progress_reset: this.course.days_until_progress_reset,
          days_until_progress_reset_notification: this.course.days_until_progress_reset_notification,
        },
        'CoursesEdit'
      ),
      filterForm: {
        archived: this.filters.archived ?? null,
        deleted: this.filters.deleted ?? null,
      },
      nonDeletedContents: this.course.contents.filter((content) => !content.deleted_at),
    }
  },
  computed: {
    isMinistryOfLabourType() {
      return this.form.type === 'Ministry of Labour'
    },
  },
  watch: {
    'form.type': function () {
      this.form.ministry_of_labour_code = null
    },
    filterForm: {
      handler: _throttle(function () {
        let query = _pickBy(this.filterForm)
        this.$inertia.get(
          this.route('courses.edit', this.course.id),
          Object.keys(query).length ? { filters: query } : { remember: 'forget' },
          {
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
    course: function () {
      this.nonDeletedContents = this.course.contents.filter((content) => !content.deleted_at)
    },
  },
  methods: {
    _pickBy,
    _throttle,
    submit() {
      this.form
        .transform((data) => {
          return {
            name_en: data.name_en,
            name_fr: data.name_fr,
            description_en: data.description_en,
            description_fr: data.description_fr,
            locale_en: data.locale_en,
            locale_fr: data.locale_fr,
            type: data.type,
            course_category_id: data.course_category_id,
            ministry_of_labour_code: data.ministry_of_labour_code,
            collects_learner_status: data.collects_learner_status,
            price: data.price,
            certificate_template_id: data.certificate_template_id,
            resell: data.resell,
            resell_seats_percentage:
              data.resell && data.resell_seats === 'percentage' ? data.resell_seats_percentage : null,
            resell_seats_quantity: data.resell && data.resell_seats === 'quantity' ? data.resell_seats_quantity : null,
            resell_commission: data.resell ? data.resell_commission : null,
            reset_progresses: data.reset_progresses,
            progress_reset_explanation_en: data.reset_progresses ? data.progress_reset_explanation_en : null,
            progress_reset_explanation_fr: data.reset_progresses ? data.progress_reset_explanation_fr : null,
            days_until_progress_reset: data.reset_progresses ? data.days_until_progress_reset : null,
            reset_content_id: data.reset_progresses ? data.reset_content_id : null,
            days_until_progress_reset_notification: data.reset_progresses
              ? data.days_until_progress_reset_notification
              : null,
          }
        })
        .put(this.route('courses.update', this.course.id))
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this course?'))) {
        this.$inertia.delete(this.route('courses.destroy', this.course.id))
      }
    },
    restore() {
      if (confirm(this.$t('Are you sure you want to restore this course?'))) {
        this.$inertia.put(this.route('courses.restore', this.course.id))
      }
    },
    archive() {
      this.$inertia.post(this.route('courses.archive', this.course.id))
    },
    unarchive() {
      this.$inertia.post(this.route('courses.unarchive', this.course.id))
    },
    deleteFollowUpCourse(followUpCourse) {
      this.$inertia.delete(
        this.route('courses.follow-up.destroy', {
          course: this.course.id,
          followUpCourse,
        })
      )
    },
  },
}
</script>
