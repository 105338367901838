<template>
  <div class="mx-auto flex flex-col text-gray-400">
    <div class="flex">
      <!-- Timeline -->
      <div class="relative mr-8">
        <signature-type-progress-icon :finished="isSignatureRequestExist" />
        <div class="flex h-full w-8 items-center justify-center">
          <div v-if="!isLast" class="h-full w-1" :class="true ? 'bg-qualify-red-500' : 'bg-darkGray-500'"></div>
        </div>
      </div>
      <!-- content -->
      <div class="mb-4 w-full justify-between border-b border-ts-gray-300 pb-6">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
import SignatureTypeProgressIcon from './SignatureTypeProgressIcon.vue'

export default {
  components: { SignatureTypeProgressIcon },
  props: {
    signature: Object,
    formFilled: Object,
    isLast: Boolean,
  },
  computed: {
    isSignatureRequestExist() {
      return this.formFilled.signature_requests.some((signatureRequest) => {
        if (this.signature.signature_type === 'assignee') {
          return signatureRequest.signature_type === this.signature.signature_type && signatureRequest.accepted_at
        } else {
          return signatureRequest.signature_type === this.signature.signature_type
        }
      })
    },
  },
}
</script>
