<template>
  <div v-click-outside="close" @click.stop>
    <label v-if="label" class="form-label" @click="open">
      <span>{{ label }}</span>
      <span v-if="required" class="text-red-500">*</span>
      <span>:</span>
    </label>
    <div class="relative">
      <div
        ref="input"
        class="form-input pr-8"
        :class="{ focus: show, error: error, 'bg-gray-100 text-gray-500': disabled }"
        :disabled="disabled"
        :tabindex="show ? -1 : 0"
        @click.stop="open"
        @focus="open"
        @keydown.down.prevent="open"
        @keydown.up.prevent="open"
      >
        <slot v-if="modelValue" />
        <div v-else class="text-gray-600">{{ $t('Click to search…') }}</div>
      </div>
      <button
        v-if="modelValue && !disabled"
        class="group absolute inset-y-0 right-0 block px-4"
        tabindex="-1"
        type="button"
        @click.stop="clear"
      >
        <svg class="h-2 w-2 fill-gray-600 group-hover:fill-red-500" viewBox="278.046 126.846 235.908 235.908">
          <path
            d="M506.784 134.017c-9.56-9.56-25.06-9.56-34.62 0L396 210.18l-76.164-76.164c-9.56-9.56-25.06-9.56-34.62 0-9.56 9.56-9.56 25.06 0 34.62L361.38 244.8l-76.164 76.165c-9.56 9.56-9.56 25.06 0 34.62 9.56 9.56 25.06 9.56 34.62 0L396 279.42l76.164 76.165c9.56 9.56 25.06 9.56 34.62 0 9.56-9.56 9.56-25.06 0-34.62L430.62 244.8l76.164-76.163c9.56-9.56 9.56-25.06 0-34.62z"
          />
        </svg>
      </button>
    </div>
    <div v-if="error" class="form-error">{{ error }}</div>
    <div
      v-if="show"
      ref="dropdown"
      class="absolute left-0 top-0 my-1 overflow-hidden rounded bg-white shadow-lg"
      :style="{ width: inputWidth + 'px', zIndex: 2000 }"
    >
      <div class="bg-gray-100 p-2">
        <input
          ref="search"
          v-model="search"
          class="w-full rounded bg-white px-3 py-2 text-sm leading-normal text-gray-800 focus:outline-none"
          :disabled="disabled"
          :placeholder="$t('Search…')"
          spellcheck="false"
          tabindex="-1"
          type="text"
          @keydown.down.prevent="move(1)"
          @keydown.enter.prevent="chooseSelected"
          @keydown.up.prevent="move(-1)"
        />
      </div>
      <div
        ref="container"
        class="scrolling-touch relative overflow-y-auto overscroll-contain text-sm leading-tight"
        style="max-height: 240px"
        tabindex="-1"
      >
        <div
          v-for="(option, index) in filtered"
          :key="getTrackedByKey(option)"
          :ref="index === selected ? 'selected' : null"
          class="cursor-pointer px-4 py-2"
          :class="{
            'text-gray-700 hover:bg-gray-100': index !== selected,
            'bg-blue-500 text-white hover:bg-blue-600': index === selected,
          }"
          @click="choose(option)"
        >
          <slot name="option" :option="option" :selected="index === selected" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Http from '@/Utils/Http'
import vClickOutside from 'click-outside-vue3'
import Fuse from 'fuse.js'
import _debounce from 'lodash/debounce'
import _findIndex from 'lodash/findIndex'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import Popper from 'popper.js'

export default {
  directives: { clickOutside: vClickOutside.directive },
  inheritAttrs: false,
  props: {
    modelValue: Object,
    trackBy: String,
    searchBy: [String, Array],
    label: String,
    error: String,
    boundary: {
      type: String,
      default: 'scrollParent',
      validator: (value) => ['scrollParent', 'viewport', 'window'].indexOf(value) !== -1,
    },
    url: String,
    required: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      show: false,
      search: '',
      selected: 0,
      popper: null,
      inputWidth: null,
      data: [],
    }
  },
  computed: {
    filtered() {
      if (this.search) {
        let searchBy = !_isArray(this.searchBy) ? [this.searchBy] : this.searchBy

        var fuse = new Fuse(this.data, {
          keys: searchBy,
          includeScore: true,
          tokenize: true,
        })

        return fuse
          .search(this.search)
          .filter((result) => result.score < 0.75)
          .map((result) => result.item)
      }

      return this.data
    },
  },
  watch: {
    search: _debounce(function () {
      if (!this.$refs.container) return

      this.selected = 0
      this.$refs.container.scrollTop = 0

      if (!this.search) {
        return
      }

      this.getData()
    }, 300),
    url: _debounce(function () {
      this.getData()
    }, 300),
    show(show) {
      if (show) {
        let selected = _findIndex(this.data, [this.trackBy, _get(this.modelValue, this.trackBy)])
        if (selected !== -1) this.selected = selected
        this.inputWidth = this.$refs.input.offsetWidth

        this.$nextTick(() => {
          const vm = this

          this.popper = new Popper(this.$refs.input, this.$refs.dropdown, {
            placement: 'bottom-start',
            onCreate() {
              vm.$refs.container.scrollTop = vm.$refs.container.scrollHeight
              vm.updateScrollPosition()
              vm.$refs.search.focus()
            },
            modifiers: {
              preventOverflow: { boundariesElement: this.boundary },
            },
          })
        })
      } else {
        this.search = ''
        if (this.popper) this.popper.destroy()
      }
    },
  },
  mounted() {
    this.getData()
    if (this.disabled) return
    document.addEventListener('keydown', (e) => {
      if (this.show && (e.keyCode == 9 || e.keyCode == 27)) {
        setTimeout(() => this.close(), 50)
      }
    })
  },
  methods: {
    getData() {
      Http.get(this.url, { params: { search: this.search } }).then((response) => {
        this.data = response.data.data
      })
    },
    getTrackedByKey(option) {
      return _get(option, this.trackBy)
    },
    open() {
      if (this.disabled) return
      this.show = true
    },
    close() {
      this.show = false
    },
    clear() {
      if (this.disabled) return
      this.$emit('update:modelValue', null)
    },
    move(offset) {
      let newIndex = this.selected + offset

      if (newIndex >= 0 && newIndex < this.filtered.length) {
        this.selected = newIndex
        this.updateScrollPosition()
      }
    },
    updateScrollPosition() {
      this.$nextTick(() => {
        if (!this.$refs.selected) {
          return
        }

        if (
          this.$refs.selected[0]?.offsetTop >
          this.$refs.container.scrollTop + this.$refs.container.clientHeight - this.$refs.selected[0]?.clientHeight
        ) {
          this.$refs.container.scrollTop =
            this.$refs.selected[0].offsetTop + this.$refs.selected[0].clientHeight - this.$refs.container.clientHeight
        }

        if (this.$refs.selected[0]?.offsetTop < this.$refs.container.scrollTop) {
          this.$refs.container.scrollTop = this.$refs.selected[0].offsetTop
        }
      })
    },
    chooseSelected() {
      if (this.filtered[this.selected] !== undefined) {
        this.$emit('update:modelValue', this.filtered[this.selected])
        this.$refs.input.focus()
        this.$nextTick(() => this.close())
      }
    },
    choose(option) {
      this.selected = _findIndex(this.data, [this.trackBy, _get(option, this.trackBy)])
      this.$emit('update:modelValue', option)
      this.$refs.input.focus()
      this.$nextTick(() => this.close())
    },
    focus() {
      if (this.disabled) return
      this.$refs.input.focus()
    },
  },
}
</script>
