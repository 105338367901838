<template>
  <td class="px-6 leading-5" :class="{ 'py-4': borderPadding }"><slot /></td>
</template>
<script>
export default {
  name: 'TableData',
  props: {
    borderPadding: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
