<template>
  <front-layout :title="$t('Enrolment Balance')">
    <div class="sm:no-flex mb-8 flex items-center justify-between">
      <breadcrumb :name="$t('My Enrolments')" />
      <front-button-link class="xl:hidden" :href="route('front.enrolment-transfers.step1.create')">
        {{ $t('Transfer') }}
        <span class="hidden lg:inline">{{ $t('Enrolments') }}</span>
      </front-button-link>
    </div>

    <purchases-layout :can-show-transfer-button="user.can.transfer_enrolments">
      <div class="flex flex-col">
        <div>
          <table class="front-table">
            <thead class="bg-ts-front-table-header">
              <tr>
                <th class="font-semibold">{{ $t('Course') }}</th>
                <th>{{ $t('Purchased') }}</th>
                <th>{{ $t('Used') }}</th>
                <th>{{ $t('Remaining') }}</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr v-if="!enrolments.data.length">
                <td class="border-t px-6 py-4" colspan="4">{{ $t('No enrolments found.') }}</td>
              </tr>
              <tr v-for="course in enrolments.data" :key="course.id">
                <td class="font-semibold text-ts-gray-text-bold">{{ course.name }}</td>
                <td>{{ course.purchased_enrolment_count }}</td>
                <td>{{ course.used_enrolment_count }}</td>
                <td>{{ course.remaining_enrolment_count }}</td>
              </tr>
            </tbody>
          </table>

          <front-pagination :links="enrolments.links" />
        </div>

        <enrolment-transfers-received :enrolment-transfers="enrolmentTransfersReceived" />
        <enrolment-transfers-sent
          :enrolment-transfers="enrolmentTransfersSent"
          @cancel-enrolment-transfer="cancelEnrolmentTransfer"
        />
      </div>
    </purchases-layout>
  </front-layout>
</template>

<script>
import PurchasesLayout from '../PurchasesLayout.vue'
import EnrolmentTransfersReceived from './EnrolmentTransfersReceived.vue'
import EnrolmentTransfersSent from './EnrolmentTransfersSent.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontButtonLink from '@/Shared/FrontButtonLink.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontPagination from '@/Shared/FrontPagination.vue'

export default {
  components: {
    PurchasesLayout,
    EnrolmentTransfersReceived,
    EnrolmentTransfersSent,
    Breadcrumb,
    FrontButtonLink,
    FrontLayout,
    FrontPagination,
  },
  props: {
    enrolments: Object,
    enrolmentTransfersReceived: Object,
    enrolmentTransfersSent: Object,
    user: Object,
  },
  methods: {
    cancelEnrolmentTransfer(enrolmentTransfer) {
      this.$inertia.delete(this.route('front.enrolment-transfers.sent.cancel', enrolmentTransfer.id))
    },
  },
}
</script>
