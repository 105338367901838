<template>
  <div v-if="timestamp">
    <time :datetime="timestamp" :title="date.longFormatted">{{ date.formatted }}</time>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    timestamp: String,
    format: {
      type: String,
      default: 'MMM DD, YYYY',
    },
    hideTooltip: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    date: function () {
      if (!this.timestamp) return
      const momentdate = moment(this.timestamp, moment.ISO_8601)
      return {
        formatted: momentdate.format(this.format),
        longFormatted: !this.hideTooltip ? momentdate.format('lll') : null,
      }
    },
    title: function () {
      return !this.hideTooltip && this.timestamp ? moment(this.timestamp).format('MMMM Do YYYY, h:mm:ss a') : null
    },
  },
}
</script>
