<template>
  <front-layout :title="$t('Assign Training')">
    <div class="mb-8">
      <breadcrumb
        class="font-heading text-2xl font-semibold"
        :name="$t('Assign Training')"
        :previous-name="user.name"
        :previous-url="route('front.manager.users.show', user.id)"
      />
    </div>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="flex flex-wrap p-8">
          <div class="w-full">
            <div class="flex items-center justify-between">
              <div class="">
                <span class="mr-7">&nbsp;</span>
                <span class="font-bold">{{ $t('Courses') }}</span>
              </div>
              <div class="w-24 font-bold">{{ $t('Credits') }}</div>
            </div>
            <div v-for="course in courses" :key="course.id" class="mt-8 flex items-center justify-between">
              <div class="flex items-center">
                <input
                  :id="'credit-' + course.id"
                  v-model="form.courses"
                  class="form-checkbox mr-4 bg-white"
                  :class="{ 'form-input.error': form.errors[`courses.${course.id}`] }"
                  :disabled="course.has_unfinished_enrolments"
                  type="checkbox"
                  :value="course.id"
                  @change="onChange(course.id)"
                />
                <label :for="'credit-' + course.id">
                  {{ course.name }}
                  <span v-if="course.has_unfinished_enrolments" class="text-xs italic">
                    {{ $t('(User have an enrolment in progress)') }}
                  </span>
                  <template v-if="course.has_subscription_access">
                    <br />
                    <span class="text-xs italic">({{ $t('Subscription') }})</span>
                  </template>
                </label>
                <br />
                <label v-if="true || form.errors[`courses.${course.id}`]" class="form-error mt-2 block">
                  {{ form.errors[`courses.${course.id}`] }}
                </label>
              </div>
              <div class="w-24">
                {{ course.enrolments_count }}
              </div>
            </div>
          </div>
          <div v-if="courses.length === 0" class="mt-8">
            <div class="ml-9">{{ $t('No credits found.') }}</div>
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 px-8 py-4">
          <front-loading-button :loading="form.processing" type="submit">
            {{ $t('Assign Training') }}
          </front-loading-button>
        </div>
      </form>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    FrontLayout,
    FrontLoadingButton,
  },
  props: {
    courses: Array,
    user: Object,
  },
  data() {
    return {
      form: useForm({
        courses: [],
      }),
    }
  },
  methods: {
    submit() {
      this.form
        .transform((data) => {
          var tranformedData = {}
          data.courses.forEach((courseId) => {
            tranformedData[courseId] = { id: courseId }
          })
          return { courses: tranformedData }
        })
        .post(this.route('front.manager.enrol.user.step1.store', this.user.id))
    },
    onChange(courseId) {
      this.form.clearErrors(`courses.${courseId}.id`)
    },
  },
}
</script>
