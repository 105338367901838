<template>
  <signature-type-layout :form-filled="formFilled" :is-last="isLast" :signature="signature">
    <div class="mb-4 w-full flex-col justify-between xl:flex-row xl:items-center">
      <div>
        <small class="block uppercase text-gray-500">
          {{
            $t(':signature_type Signature', {
              signature_type: signature.signature_type,
            })
          }}
        </small>
        <div v-if="signatureRequests.length" class="mb-6 mt-2">
          <div v-for="(signatureRequest, index) in signatureRequests" :key="index" class="my-2">
            <div class="flex items-center">
              <p class="mr-2 text-sm font-semibold text-gray-500">
                {{
                  $t(':name (:email)', {
                    name: signatureRequest.receiver_name,
                    email: signatureRequest.receiver_email,
                  })
                }}
              </p>
              <signature-status :signature-request="signatureRequest" />
              <button
                v-if="signatureRequest.expired_at || isSignatureRequestPending(signatureRequest)"
                class="ml-2 cursor-pointer text-xs uppercase text-sky-700 hover:underline"
                @click="resendRequest(signatureRequest)"
              >
                {{ $t('Resend Request') }}
              </button>
            </div>
          </div>
        </div>

        <p class="text-sm font-semibold text-ts-gray-text-bold">
          {{ $t('Email will be sent to the below user(s) for a review.') }}
        </p>
      </div>
    </div>

    <div class="flex items-center justify-between">
      <div class="w-2/5 pr-2">
        <input
          id="name"
          v-model="name"
          class="block w-full rounded-lg border bg-ts-front-field-light p-2 text-sm text-ts-front-label transition duration-150 ease-in-out focus:text-gray-800 focus:outline-none"
          :class="{ 'border-ts-front-border-error': error.name }"
          placeholder="Name"
          required
          type="text"
        />
      </div>
      <div class="w-2/5 pl-2">
        <input
          id="email"
          v-model="email"
          class="block w-full rounded-lg border bg-ts-front-field-light p-2 text-sm text-ts-front-label transition duration-150 ease-in-out focus:text-gray-800 focus:outline-none"
          :class="{ 'border-ts-front-border-error': error.email }"
          placeholder="Email"
          required
          type="email"
        />
      </div>
      <div class="w-1/5 pl-2">
        <button class="flex items-center rounded-full bg-qualify-red-500" type="button" @click.stop="addUser">
          <Icon class="h-6 w-6 fill-white" name="plus" />
        </button>
      </div>
    </div>

    <div v-for="(user, index) in form.users" :key="index" class="mt-2 flex items-center justify-between">
      <div class="w-2/5 pr-2">
        <input
          id="name"
          v-model="user.name"
          class="block w-full rounded-lg border bg-ts-front-field-light p-2 text-sm text-ts-front-label transition duration-150 ease-in-out focus:text-gray-800 focus:outline-none"
          disabled
          type="text"
        />
      </div>
      <div class="w-2/5 pl-2">
        <input
          id="email"
          v-model="user.email"
          class="block w-full rounded-lg border bg-ts-front-field-light p-2 text-sm text-ts-front-label transition duration-150 ease-in-out focus:text-gray-800 focus:outline-none"
          disabled
          type="email"
        />
      </div>
      <div class="w-1/5 pl-2 text-right">
        <button
          class="flex items-center rounded-full border border-qualify-red-500 bg-white"
          type="button"
          @click.stop="removeUser"
        >
          <Icon class="h-6 w-6 rotate-45 fill-qualify-red-500" name="plus" />
        </button>
      </div>
    </div>
  </signature-type-layout>
</template>
<script>
import SignatureStatus from './SignatureStatus.vue'
import SignatureTypeLayout from './SignatureTypeLayout.vue'
import Icon from '@/Shared/Icon.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: { SignatureTypeLayout, SignatureStatus, Icon },
  props: {
    signature: Object,
    formFilled: Object,
    signatureRequests: Object,
    isLast: Boolean,
  },
  data() {
    return {
      name: null,
      email: null,
      error: {},
      resendRequestform: useForm({
        requests: [
          {
            users: [],
            signatureType: this.signature.signature_type,
          },
        ],
      }),
      form: {
        users: [],
        signatureType: this.signature.signature_type,
      },
    }
  },
  watch: {
    signatureRequests: {
      handler() {
        this.form.users = []
      },
      deep: true,
    },
  },
  methods: {
    resendRequest(signatureRequest) {
      this.resendRequestform.requests[0].users.push({
        name: signatureRequest.receiver_name,
        email: signatureRequest.receiver_email,
      })

      this.resendRequestform.post(this.route('front.filledForms.signature-request.store', this.formFilled?.id), {
        preserveScroll: true,
        onSuccess: () => {
          this.resendRequestform.reset()
          this.resendRequestform.clearErrors()
        },
      })
    },
    addUser() {
      if (!this.name) {
        this.error.name = 'Name is required'
        return
      } else {
        this.error.name = null
      }
      if (!this.email) {
        this.error.email = 'Email is required'
        return
      } else {
        this.error.email = null
      }

      this.form.users.push({
        name: this.name,
        email: this.email,
      })

      this.$emit('update-data', { users: this.form.users, signatureType: this.form.signatureType })

      this.name = null
      this.email = null
      this.error = {}
    },
    removeUser(index) {
      this.form.users.splice(index, 1)
      this.$emit('update-data', { users: this.form.users, signatureType: this.form.signatureType })
    },
    isSignatureRequestPending(request) {
      return !request.accepted_at && !request.expired_at
    },
  },
}
</script>
