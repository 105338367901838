<template>
  <div v-if="status === 'in progress'" :class="`${cssClasses} bg-ts-blue-500 bg-opacity-8 text-ts-blue-500`">
    {{ $t('In Progress') }}
  </div>
  <div v-else-if="status === 'transfer-pending'" :class="`${cssClasses} bg-yellow-500 bg-opacity-8 text-yellow-500`">
    {{ $t('Transfer: Pending') }}
  </div>
  <div v-else-if="status === 'complete'" :class="`${cssClasses} bg-ts-green-500 bg-opacity-8 text-ts-green-500`">
    {{ $t('Complete') }}
  </div>
  <div v-else-if="status === 'failed'" :class="`${cssClasses} bg-red-500 bg-opacity-8 text-ts-red-500`">
    {{ $t('Failed') }}
  </div>
  <div v-else-if="status === 'new'" :class="`${cssClasses} bg-gray-800 bg-opacity-8 text-gray-500`">
    {{ $t('New') }}
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cssClasses: 'inline-block px-2 py-2 rounded-md text-sm text-center font-semibold w-28',
    }
  },
}
</script>
