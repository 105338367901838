<template>
  <layout :title="$t('Live Classes Dashboard')">
    <div class="mb-8 flex items-center justify-between">
      <h1 class="flex text-xl font-bold">
        <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('reports')">
          {{ $t('Reports') }}
        </InertiaLink>
        <span class="ml-1 font-medium">/</span>
        <span class="ml-1 flex cursor-default">
          {{ $t('Current Classes') }}
          <div class="ml-3 flex animate-pulse items-center text-sm text-green-600">
            <span class="dot bg-green-600"></span>
            <span class="ml-1">Live</span>
          </div>
        </span>
      </h1>
      <div class="cursor-default rounded-lg bg-gray-400 p-2 font-semibold text-white">
        {{ totalStudentsCount }} in training
      </div>
    </div>
    <div class="mb-6 flex items-center justify-between">
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
        <label class="block text-gray-800">{{ $t('Instructor Led Class:') }}</label>
        <select v-model="form.instructor_led_classroom" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="ilc in instructor_led_classrooms" :key="ilc.id" :value="ilc.id">{{ ilc.name }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Access:') }}</label>
        <select v-model="form.access" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="public">{{ $t('Public') }}</option>
          <option value="private">{{ $t('Private') }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('MOL Status:') }}</label>
        <select v-model="form.mol" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="submitted">{{ $t('Submitted') }}</option>
          <option value="partially">{{ $t('Partially Submitted') }}</option>
          <option value="not">{{ $t('Not Submitted') }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Locale:') }}</label>
        <select v-model="form.locale" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="(locale, key) in locales" :key="key" :value="locale">
            {{ key }}
          </option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Fullness:') }}</label>
        <select v-model="form.fullness" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="(label, key) in fullness_values" :key="key" :value="label">{{ label }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Class Type:') }}</label>
        <select v-model="form.class_type" class="form-select mt-1 w-full">
          <option value="parent">{{ $t('Parent Classes') }}</option>
          <option value="subclass">{{ $t('Subclasses') }}</option>
        </select>
        <div class="mt-4 flex justify-between">
          <label class="block text-gray-800">{{ $t('Location:') }}</label>
          <div class="flex items-center">
            <span class="mr-2 text-sm">{{ $t('With client sites') }}</span>
            <input v-model="is_client_site_checked" type="checkbox" />
          </div>
        </div>
        <select v-model="form.location" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="location in locations" :key="location.id" :value="location.id">
            {{ location.name }}
          </option>
        </select>
        <template v-if="form.location && roomsForSelectedLocation.length > 0">
          <div class="mt-4 flex justify-between">
            <label class="block text-gray-800">{{ $t('Room:') }}</label>
          </div>
          <select v-model="form.room" class="form-select mt-1 w-full">
            <option :value="null" />
            <option v-for="room in roomsForSelectedLocation" :key="room.id" :value="room.id">
              {{ room.name }}
            </option>
          </select>
        </template>
        <label class="mt-4 block text-gray-800">{{ $t('Instructor:') }}</label>
        <select v-model="form.instructor" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="instructor in instructors" :key="instructor.id" :value="instructor.id">
            {{ instructor.last_name }}, {{ instructor.first_name }}
          </option>
        </select>
      </search-filter>
      <dropdown class="btn-blue hidden lg:flex" placement="bottom-end">
        <div class="flex cursor-pointer select-none items-center">
          <div>
            <span>{{ $t('Actions') }}</span>
          </div>
          <icon class="-my-1 h-5 w-5 fill-white" name="chevron-down" />
        </div>
        <template #dropdown>
          <div class="mt-2 rounded border bg-white py-2 text-sm shadow-lg">
            <InertiaLink class="block px-4 py-2 hover:bg-gray-200 hover:text-gray-700" :href="route('classes.create')">
              {{ $t('Create Class') }}
            </InertiaLink>
            <InertiaLink
              class="block px-4 py-2 hover:bg-gray-200 hover:text-gray-700"
              :href="route('classes.calendar')"
            >
              {{ $t('View Calendar') }}
            </InertiaLink>
          </div>
        </template>
      </dropdown>
      <dropdown class="btn-blue lg:hidden" placement="bottom-end">
        <div class="flex cursor-pointer select-none items-center">
          <div>
            <span>{{ $t('Actions') }}</span>
          </div>
          <icon class="-my-1 h-5 w-5 fill-white" name="chevron-down" />
        </div>
        <template #dropdown>
          <div class="mt-2 rounded border bg-white py-2 text-sm shadow-lg">
            <InertiaLink class="block px-4 py-2 hover:bg-gray-200 hover:text-gray-700" :href="route('classes.create')">
              <span>{{ $t('Create Class') }}</span>
            </InertiaLink>
            <InertiaLink
              class="block px-4 py-2 hover:bg-gray-200 hover:text-gray-700"
              :href="route('classes.calendar')"
            >
              <span>{{ $t('View Calendar') }}</span>
            </InertiaLink>
          </div>
        </template>
      </dropdown>
    </div>
    <div
      v-for="(class_, index) in classes"
      :key="index"
      class="mb-10 w-full rounded-xl bg-white p-6 shadow-lg transition-shadow duration-300 hover:shadow-xl"
    >
      <div class="mb-4 flex flex-col justify-between md:flex-row">
        <div class="flex flex-col">
          <h2 class="text-lg font-semibold text-gray-900 hover:cursor-pointer" @click="goToClassViewPage(class_.id)">
            {{ class_.instructor_led_classroom.name }}
          </h2>
          <Popper :hover="true">
            <div v-if="class_.sessions_count" class="mt-2 inline-flex items-center">
              <div class="mr-1 rounded bg-gray-400 px-1 text-sm text-white">
                {{ class_.sessions_count }}
              </div>
              <div class="">
                {{ class_.dates }}
                <span class="block text-sm text-gray-600">{{ showSessionTime(class_) }}</span>
              </div>
            </div>
            <div v-else class="text-red-500">{{ $t('None set') }}</div>
            <template #content>
              <div class="rounded border bg-white py-2 text-sm shadow-lg">
                <div v-for="session in class_.sessions" :key="session.id" class="px-4 py-2">
                  {{ session.date }}, {{ session.time }}
                </div>
              </div>
            </template>
          </Popper>
          <div class="mt-2 flex items-center">
            <span>{{ class_.location.name }}</span>
            <span v-if="class_.location.client_site" class="ml-2 rounded bg-gray-400 px-2 py-1 text-sm text-white">
              {{ $t('Client') }}
            </span>
            <div v-if="class_.room" class="ml-2">({{ class_.room }})</div>
          </div>
          <div class="mt-2 flex items-center">Instructors: {{ class_.instructors }}</div>
        </div>

        <p class="text-sm text-gray-700">
          <strong>Capacity:</strong>
          {{ class_.capacity }} students
          <br />
          <strong>Students Enrolled:</strong>
          {{ class_.total_students_count }} / {{ class_.capacity }}
          <br />
          <strong>Available Seats:</strong>
          {{ class_.available_seats }}
        </p>
      </div>

      <div class="mt-6 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        <div
          v-for="student in class_.students"
          :key="student.id"
          class="rounded bg-gray-100 p-4 shadow transition-colors duration-200 hover:cursor-pointer hover:bg-gray-200"
          @click="goToEnrolmentEditPage(student.enrolment_id)"
        >
          <p v-if="student.enrolment && student.enrolment.user" class="text-center font-semibold text-gray-700">
            {{ student.enrolment.user?.first_name }} {{ student.enrolment.user?.last_name }}
          </p>
          <span v-else>-</span>
          <p v-if="student.enrolment" class="mt-2 text-center text-sm text-gray-700">
            {{ student.enrolment.owner?.first_name }} {{ student.enrolment.owner?.last_name }}
          </p>
        </div>
      </div>
      <div v-if="class_.students.length === 0" class="py-4 text-center">No students found.</div>
    </div>
  </layout>
</template>

<script>
import Dropdown from '@/Shared/Dropdown.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import _debounce from 'lodash/debounce'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import Popper from 'vue3-popper'

export default {
  components: {
    Popper,
    Dropdown,
    Icon,
    Layout,
    SearchFilter,
  },
  props: {
    classes: Array,
    courses: Array,
    locations: Array,
    rooms: Array,
    instructors: Array,
    instructor_led_classrooms: Array,
    filters: Object,
    locales: Object,
    can: Object,
    fullness_values: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        instructor_led_classroom: this.filters.instructor_led_classroom,
        access: this.filters.access,
        mol: this.filters.mol,
        locale: this.filters.locale,
        location: this.filters.location,
        room: this.filters.room,
        instructor: this.filters.instructor,
        client_site: this.filters.client_site,
        class_type: this.filters.class_type ?? 'parent',
      },
      is_client_site_checked: false,
    }
  },
  computed: {
    totalStudentsCount() {
      const total = this.classes.reduce((sum, class_) => {
        return sum + class_.total_students_count
      }, 0)
      return total.toLocaleString()
    },
  },
  watch: {
    is_client_site_checked() {
      this.form.client_site = this.is_client_site_checked ? 'with' : null
    },
    'form.location'(newVal) {
      if (!newVal) {
        this.form.room = null
      }
    },
    form: {
      handler: _debounce(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(this.route('live-dashboard'), Object.keys(query).length ? query : { remember: 'forget' }, {
          preserveState: true,
          replace: true,
          only: ['classes', 'locations'],
        })
      }, 300),
      deep: true,
    },
  },
  methods: {
    goToClassViewPage(class_id) {
      this.$inertia.visit(this.route('classes.show', class_id))
    },
    goToEnrolmentEditPage(enrolmentId) {
      this.$inertia.visit(this.route('enrolments.edit', enrolmentId))
    },
    showSessionTime(class_) {
      let sessionStartTime = '',
        sessionEndTime = '',
        currentDate = ''

      for (let i = 0; i < class_.sessions.length; i++) {
        if (i === 0) {
          currentDate = class_.sessions[i].date
          sessionStartTime = class_.sessions[i].time.split('~').shift()
          sessionEndTime = class_.sessions[i].time.split('~').pop()
        } else {
          if (currentDate === class_.sessions[i].date) {
            sessionEndTime = class_.sessions[i].time.split('~').pop()
          }
        }
      }

      return sessionStartTime + ' ~ ' + sessionEndTime
    },
    reset() {
      this.form = _mapValues(this.form, () => null)
      this.form.class_type = 'parent'
    },
  },
}
</script>

<style scoped>
.dot {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  display: inline-block;
}
</style>
