<template>
  <layout :title="$t('Organizations')">
    <h1 class="mb-8 text-xl font-bold">{{ $t('Organizations') }}</h1>
    <div class="mb-6 flex items-center justify-between">
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" :filter-show="false" @reset="reset" />
      <InertiaLink class="btn-blue" :href="route('organizations.create')">
        <span>{{ $t('Create') }}</span>
        <span class="hidden md:inline">{{ $t('Organization') }}</span>
      </InertiaLink>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow">
      <SimpleTable>
        <template #head>
          <TableHead>{{ $t('Name') }}</TableHead>
          <TableHead>{{ $t('City') }}</TableHead>
          <TableHead>{{ $t('Phone') }}</TableHead>
          <TableHead />
        </template>
        <TableRow v-if="organizations.data.length === 0">
          <TableData class="border-t px-6 py-4" colspan="4">{{ $t('No organizations found.') }}</TableData>
        </TableRow>
        <TableRow
          v-for="organization in organizations.data"
          :key="organization.id"
          :clickable="true"
          @click="$inertia.visit(route('organizations.edit', organization.id))"
        >
          <TableData>{{ organization.name }}</TableData>
          <TableData>{{ organization.city }}</TableData>
          <TableData>{{ organization.phone }}</TableData>
          <TableData class="w-min align-middle">
            <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
          </TableData>
        </TableRow>
      </SimpleTable>
    </div>
    <pagination :links="organizations.links" />
  </layout>
</template>

<script>
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    SimpleTable,
    TableData,
    TableRow,
    Icon,
    Layout,
    Pagination,
    SearchFilter,
    TableHead,
  },
  props: {
    filters: Object,
    organizations: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
      },
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(
          this.route('organizations.index'),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
