<template>
  <front-layout :title="$t('Certificates')">
    <div class="flex flex-col xl:flex-row xl:justify-between">
      <div
        class="sm:no-flex flex w-full items-center justify-between"
        :class="tenant.plastic_card_price != null ? 'mb-6' : 'mb-4'"
      >
        <breadcrumb :name="$t('My Certificates')" />
        <front-solid-button
          v-if="certificates.data.length && tenant.plastic_card_price != null"
          class="sm:hidden"
          @click="openOrderPlasticCardsModal"
        >
          {{ $t('Order') }}
        </front-solid-button>
      </div>
      <div class="mb-6 flex w-full items-center justify-end">
        <front-solid-button
          v-if="certificates.data.length && tenant.plastic_card_price != null"
          class="hidden sm:inline"
          @click="openOrderPlasticCardsModal"
        >
          {{ $t('Order Plastic Card') }}
        </front-solid-button>
      </div>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow-lg">
      <table class="front-table w-full">
        <thead>
          <tr>
            <th v-if="isSelectingCertificates">
              <front-row-select-checkbox id="selectAll" v-model="selectAll" @update:model-value="selectAllUpdated" />
            </th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
              {{ $t('Certificate Name') }}
            </th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold" />
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">{{ $t('Number') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">{{ $t('Issue Date') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">{{ $t('Expiry Date') }}</th>
            <th class="w-4">
              <front-outline-button
                v-if="!isSelectingCertificates"
                class="float-right"
                @click="isSelectingCertificates = true"
              >
                {{ $t('Sign') }}
              </front-outline-button>
              <front-outline-button v-else class="float-right" @click="cancelCertificateSelection">
                {{ $t('Cancel') }}
              </front-outline-button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="certificates.data.length === 0">
            <td class="border-t px-6 py-4" colspan="5">{{ $t('No results found.') }}</td>
          </tr>
          <tr v-for="certificate in certificates.data" :key="certificate.certificate_number">
            <td v-if="isSelectingCertificates">
              <front-row-select-checkbox
                v-if="!certificate.is_training_record"
                :id="'checkbox-' + certificate.id"
                v-model="form.certificates"
                :disabled="certificate.manager_signature"
                :value="certificate.id"
              />
            </td>
            <td class="font-semibold text-ts-gray-text-bold">
              <front-truncate-text class="max-w-md" :text="certificate.name" />
            </td>
            <td class="hidden lg:table-cell">
              <span
                v-if="certificate.user_signature"
                class="mx-1 cursor-default rounded bg-ts-blue-600 bg-opacity-8 p-1 text-xs font-semibold leading-5 text-ts-blue-600"
              >
                {{ $t('Signed') }}
              </span>
            </td>
            <td>
              {{ certificate.certificate_number }}
            </td>
            <td>
              <front-date :correct-to-local="!certificate.is_training_record" :timestamp="certificate.issued_at" />
            </td>
            <td>
              <div v-if="certificate.years_valid" class="flex justify-center">
                <front-certificate-status :status="certificate.status">
                  <front-date :correct-to-local="!certificate.is_training_record" :timestamp="certificate.expires_at" />
                </front-certificate-status>
              </div>
              <div
                v-else
                class="relative mr-6 flex justify-center whitespace-normal"
                @mouseenter="current_hover_record = certificate.id"
                @mouseleave="current_hover_record = null"
              >
                <icon class="z-10 block h-5 w-5 fill-gray-300 stroke-white hover:fill-gray-400" name="info" />
                <div
                  v-if="current_hover_record === certificate.id"
                  class="absolute z-20 mt-8 w-48 cursor-pointer rounded bg-ts-front-table-header shadow-lg"
                >
                  <div class="relative flex justify-center">
                    <div class="absolute -mt-1 h-3 w-3 rotate-45 transform bg-ts-front-table-header" />
                  </div>
                  <div class="px-6 py-4">
                    <p class="text-center text-base text-ts-gray-text">
                      {{ $t('No expiry is set for this certificate.') }}
                    </p>
                  </div>
                </div>
              </div>
            </td>
            <td class="w-min whitespace-nowrap border-t px-6 py-4">
              <div class="flex items-center justify-start">
                <div v-if="!certificate.is_training_record" class="mr-6">
                  <a
                    v-if="certificate.formats"
                    class="text-sm text-ts-blue-500 no-underline"
                    @click="showEmailExportModal(certificate)"
                  >
                    <icon class="h-8 w-8" name="email" />
                  </a>
                  <span v-else class="text-sm text-gray-600 opacity-50 hover:cursor-not-allowed">
                    <icon class="h-8 w-8" name="email" />
                  </span>
                </div>
                <div v-else class="mx-2 mr-12" />

                <div v-if="certificate.formats" class="mr-6">
                  <front-certificate-download :certificate="certificate" route-name="user" />
                </div>
                <div v-else-if="certificate.is_training_record" class="mr-6">
                  <front-training-record-download :training-record="certificate" />
                </div>

                <button
                  v-if="certificate.status !== 'Active'"
                  class="mr-6 py-2 font-semibold text-ts-blue-500 no-underline"
                  @click="recertify(certificate.id)"
                >
                  {{ $t('Recertify') }}
                </button>
                <button
                  v-else-if="!certificate.is_training_record"
                  class="py-2 font-semibold text-ts-blue-500 no-underline"
                  @click="openViewOptionsModal(certificate)"
                >
                  {{ $t('View Options') }}
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex justify-between">
      <front-solid-button
        v-if="isSelectingCertificates"
        class="mt-4"
        :class="{ disabled: form.certificates.length == 0 }"
        :disabled="form.certificates.length == 0"
        @click="showSignaturePadModal = true"
      >
        {{ $t('Sign Now') }}
      </front-solid-button>
      <div v-else class="mt-4" />

      <front-pagination :links="certificates.links" />
    </div>
    <recertify :show="showRecertify" @close="close" />
    <view-options-modal
      v-if="selected_certificate"
      :certificate="selected_certificate"
      :organization="organization"
      :show="show_view_options_modal"
      @close="closeViewOptionsModal"
      @create-employer-signature-request="createEmployerSignatureRequest"
      @edit-expiry="openEditExpiryModal"
      @recertify="recertify"
      @request-signature="openRequestSignatureModal"
      @send-by-email="openSendByEmailModal"
      @sign-certificate="openSignCertificateModal"
    />
    <edit-expiry-modal
      v-if="selected_certificate"
      :certificate="selected_certificate"
      :show="show_edit_expiry_modal"
      @close-edit="closeEditExpiryModal"
    />
    <order-plastic-cards-modal
      :certificates="certificates"
      :locales="locales"
      :regions="regions"
      :show="show_order_plastic_card_modal"
      :tenant="tenant"
      @close="closeOrderPlasticCardsModal"
    />
    <front-signature-modal
      v-if="showSignaturePadModal"
      :certificates="form.certificates"
      role="user"
      :show="showSignaturePadModal"
      @close="showSignaturePadModal = false"
      @signed="submitSignature"
    />
    <create-employer-signature-request-for-organization-modal
      v-if="!!$page.props.auth.user.organization?.id && selected_certificate"
      :certificate="selected_certificate"
      :show="showCreateEmployerSignatureRequestModal"
      @close="showCreateEmployerSignatureRequestModal = false"
    />
    <create-employer-signature-request-modal
      v-else-if="selected_certificate"
      :certificate="selected_certificate"
      :show="showCreateEmployerSignatureRequestModal"
      @close="showCreateEmployerSignatureRequestModal = false"
    />
    <front-email-export-modal
      v-if="selected_certificate"
      :certificate="selected_certificate"
      :show="isEmailExportModal"
      @close="hideEmailExportModal"
    />
  </front-layout>
</template>

<script>
import CreateEmployerSignatureRequestForOrganizationModal from '../Contents/CreateEmployerSignatureRequestForOrganizationModal.vue'
import CreateEmployerSignatureRequestModal from '../Contents/CreateEmployerSignatureRequestModal.vue'
import EditExpiryModal from './EditExpiryModal.vue'
import OrderPlasticCardsModal from './OrderPlasticCardsModal.vue'
import ViewOptionsModal from './ViewOptionsModal.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontCertificateDownload from '@/Shared/FrontCertificateDownload.vue'
import FrontCertificateStatus from '@/Shared/FrontCertificateStatus.vue'
import FrontDate from '@/Shared/FrontDate.vue'
import FrontEmailExportModal from '@/Shared/FrontEmailExportModal.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontOutlineButton from '@/Shared/FrontOutlineButton.vue'
import FrontPagination from '@/Shared/FrontPagination.vue'
import FrontRowSelectCheckbox from '@/Shared/FrontRowSelectCheckbox.vue'
import FrontSignatureModal from '@/Shared/FrontSignatureModal.vue'
import FrontSolidButton from '@/Shared/FrontSolidButton.vue'
import FrontTrainingRecordDownload from '@/Shared/FrontTrainingRecordDownload.vue'
import FrontTruncateText from '@/Shared/FrontTruncateText.vue'
import Icon from '@/Shared/Icon.vue'
import Recertify from '@/Shared/Recertify.vue'
import { query } from '@/Utils/Helpers'
import { useForm } from '@inertiajs/vue3'
import _sumBy from 'lodash/sumBy'

export default {
  components: {
    ViewOptionsModal,
    EditExpiryModal,
    OrderPlasticCardsModal,
    FrontTrainingRecordDownload,
    CreateEmployerSignatureRequestForOrganizationModal,
    CreateEmployerSignatureRequestModal,
    Breadcrumb,
    FrontCertificateDownload,
    FrontCertificateStatus,
    FrontDate,
    FrontEmailExportModal,
    FrontLayout,
    FrontOutlineButton,
    FrontPagination,
    FrontRowSelectCheckbox,
    FrontSignatureModal,
    FrontSolidButton,
    FrontTruncateText,
    Icon,
    Recertify,
  },
  props: {
    regions: Array,
    locales: Object,
    tenant: Object,
    credits: Array,
    organization: Object,
    certificates: Object,
  },
  data() {
    return {
      showCreateEmployerSignatureRequestModal: false,
      isSelectingCertificates: false,
      selectAll: false,
      showRecertify: false,
      selected_certificate: null,
      show_edit_expiry_modal: false,
      show_view_options_modal: false,
      show_order_plastic_card_modal: false,
      showSignaturePadModal: false,
      current_hover_record: null,
      form: useForm({
        certificates: [],
        image: null,
      }),
      isEmailExportModal: false,
    }
  },
  computed: {
    hasNoCertificate() {
      return this.certificates.data.length === 0 || _sumBy(this.enrolments, 'finished_contents_count') === 0
    },
  },
  watch: {
    'form.certificates': {
      handler() {
        if (this.certificates.data.every((certificate) => this.form.certificates.includes(certificate.id))) {
          return (this.selectAll = true)
        }
        this.selectAll = false
      },
      deep: true,
    },
  },
  methods: {
    query,
    cancelCertificateSelection() {
      this.isSelectingCertificates = false
      this.form.certificates = []
    },
    selectAllUpdated() {
      if (this.selectAll) {
        this.selectAllCertificates()
      } else {
        this.unSelectAllCertificates()
      }
    },
    selectAllCertificates() {
      this.form.certificates = this.certificates.data
        .filter((certificate) => !certificate.is_training_record && !certificate.manager_signature)
        .map(function (certificates) {
          return certificates.id
        })
    },
    unSelectAllCertificates() {
      this.form.certificates = []
    },
    recertify(certificateId) {
      let certificate = this.certificates.data.find((certificate) => certificate.id === certificateId)

      if (certificate && certificate.can.recertify) {
        this.$inertia.visit(this.route('front.courses'))
      } else {
        this.show_view_options_modal = false
        this.showRecertify = true
      }
    },
    createEmployerSignatureRequest(certificateId) {
      let certificate = this.certificates.data.find((certificate) => certificate.id === certificateId)

      if (certificate) {
        this.showCreateEmployerSignatureRequestModal = true
      }
    },
    close() {
      this.showRecertify = false
    },
    openViewOptionsModal(certificate) {
      this.selected_certificate = certificate
      this.show_view_options_modal = true
    },
    closeViewOptionsModal() {
      this.show_view_options_modal = false
    },
    openEditExpiryModal() {
      this.show_view_options_modal = false
      this.show_edit_expiry_modal = true
    },
    closeEditExpiryModal() {
      this.show_edit_expiry_modal = false
    },
    openSendByEmailModal(certificate) {
      this.selected_certificate = certificate
      this.isEmailExportModal = true
      this.show_view_options_modal = false
    },
    openRequestSignatureModal() {
      this.show_view_options_modal = false
      this.showCreateEmployerSignatureRequestModal = true
    },
    openSignCertificateModal() {
      this.form.certificates = [this.selected_certificate?.id]
      this.show_view_options_modal = false
      this.showSignaturePadModal = true
    },
    openOrderPlasticCardsModal() {
      this.show_order_plastic_card_modal = true
    },
    closeOrderPlasticCardsModal() {
      this.show_order_plastic_card_modal = false
    },
    showEmailExportModal(certificate) {
      this.selected_certificate = certificate
      this.isEmailExportModal = true
    },
    hideEmailExportModal() {
      this.selected_certificate = null
      this.isEmailExportModal = false
    },
    submitSignature(image) {
      this.form.image = image
      this.form.post(this.route('front.certificates.signature.store'), {
        onSuccess: () => {
          this.form.reset()
          this.isSelectingCertificates = false
        },
      })
    },
  },
}
</script>
