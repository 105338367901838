<template>
  <layout title="Reseller">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('resellers')">
        {{ $t('Resellers') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ tenant.name }}
    </h1>
    <div class="overflow-x-auto rounded bg-white shadow">
      <SimpleTable>
        <template #head>
          <TableHead>{{ $t('Course') }}</TableHead>
          <TableHead>{{ $t('Status') }}</TableHead>
          <TableHead>{{ $t('Seats') }}</TableHead>
          <TableHead colspan="2">{{ $t('Commission') }}</TableHead>
        </template>
        <TableRow
          v-for="resellingCourse in tenant.resellingCourses"
          :key="resellingCourse.id"
          :clickable="true"
          @click="$inertia.visit(route('reselling_courses.edit', { resellingCourse: resellingCourse.id }))"
        >
          <TableData>{{ resellingCourse.course.name }}</TableData>
          <TableData>
            <span v-if="resellingCourse.approved" class="ml-1 rounded-full bg-green-500 px-3 py-1 text-sm text-white">
              {{ $t('Reselling') }}
            </span>
            <span v-else class="ml-1 rounded-full bg-orange-500 px-3 py-1 text-sm text-white">
              {{ $t('Pending Approval') }}
            </span>
          </TableData>
          <TableData>
            <template v-if="resellingCourse.seats_percentage">
              {{ $t(':percentage%', { percentage: resellingCourse.seats_percentage }) }}
            </template>
            <template v-else-if="resellingCourse.seats_quantity">
              {{ $t(':seats_quantity seats', { seats_quantity: resellingCourse.seats_quantity }) }}
            </template>
            <template v-else-if="resellingCourse.course.resell_seats_percentage">
              {{ $t(':percentage%', { percentage: resellingCourse.resell_seats_percentage }) }}
              <span class="text-sm text-gray-600">{{ $t('(default)') }}</span>
            </template>
            <template v-else-if="resellingCourse.course.resell_seats_quantity">
              {{ $t(':seats_quantity seats', { seats_quantity: resellingCourse.resell_seats_quantity }) }}
              <span class="text-sm text-gray-600">{{ $t('(default)') }}</span>
            </template>
          </TableData>
          <TableData>
            <template v-if="resellingCourse.commission">
              {{ $t(':commission%', { commission: resellingCourse.commission }) }}
            </template>
            <template v-else-if="resellingCourse.course.resell_commission">
              {{ $t(':commission%', { commission: resellingCourse.resell_commission }) }}
              <span class="text-sm text-gray-600">{{ $t('(default)') }}</span>
            </template>
          </TableData>
          <TableData class="w-min align-middle">
            <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
          </TableData>
        </TableRow>
      </SimpleTable>
    </div>
  </layout>
</template>
<script>
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'

export default {
  components: {
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
    Icon,
    Layout,
  },
  props: {
    tenant: Object,
  },
}
</script>
