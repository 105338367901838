<template>
  <layout :title="class_.instructorLedClassroom.name">
    <div class="-mt-4 mb-8 flex items-end justify-between">
      <breadcrumb
        :name="class_.instructorLedClassroom.name"
        :previous-name="$t('Classes')"
        :previous-url="route('instructor.classes')"
      />
      <div class="flex">
        <button class="btn-blue hidden xl:block" @click="create">{{ $t('Create check-in code') }}</button>
        <InertiaLink class="btn-blue ml-2 hidden xl:block" :href="route('instructor.checkin.class', class_.id)">
          {{ $t('Start check-in') }}
        </InertiaLink>
        <dropdown class="btn-blue xl:hidden" placement="bottom-end">
          <div class="flex cursor-pointer select-none items-center">
            <span>{{ $t('Actions') }}</span>
            <icon class="-my-1 h-5 w-5 fill-white" name="chevron-down" />
          </div>
          <template #dropdown>
            <div class="mt-2 rounded border bg-white py-2 text-sm shadow-lg">
              <button class="block px-4 py-2 hover:bg-gray-200 hover:text-gray-700" type="button" @click="create">
                {{ $t('Create check-in code') }}
              </button>
              <InertiaLink
                class="block px-4 py-2 hover:bg-gray-200 hover:text-gray-700"
                :href="route('instructor.checkin.class', class_.id)"
              >
                {{ $t('Start check-in') }}
              </InertiaLink>
            </div>
          </template>
        </dropdown>
      </div>
    </div>
    <div class="max-w-lg overflow-hidden rounded bg-white px-8 py-6 leading-normal shadow">
      <div v-if="class_.location" class="my-2">
        <strong>{{ $t('Location:') }}</strong>
        {{ class_.location.name }}
        <div>
          {{ class_.location.address }}
          <span v-if="class_.location.room">({{ class_.location.room.name }})</span>
        </div>
      </div>
      <div class="my-2">
        <strong>{{ $t('Capacity:') }}</strong>
        {{ class_.capacity }}
      </div>
      <div v-if="class_.private" class="my-2">
        <strong>{{ $t('Private:') }}</strong>
        {{ class_.private ? 'Yes' : 'No' }}
      </div>
      <div v-if="class_.price" class="my-2">
        <strong>{{ $t('Price:') }}</strong>
        ${{ class_.price }}
      </div>
      <div v-if="class_.is_webinar && class_.webinar_url" class="my-2">
        <strong>{{ $t('Webinar URL:') }}</strong>
        <a class="hover:underline" :href="class_.webinar_url" target="_blank">{{ class_.webinar_url }}</a>
      </div>
      <div v-if="class_.instructor_notes" class="my-2">
        <strong>{{ $t('Instructor notes:') }}</strong>
        {{ class_.instructor_notes }}
      </div>
      <div class="my-2">
        <strong>{{ $t('Locale:') }}</strong>
        <span class="uppercase">{{ class_.locale }}</span>
      </div>
      <div v-if="class_.attachments.length" class="my-2 flex flex-col">
        <strong>{{ $t('Attachments:') }}</strong>
        <a
          v-for="attachment in class_.attachments"
          :key="attachment.id"
          class="flex items-center"
          :href="attachment.original_url"
          target="_blank"
        >
          <icon class="mr-1 inline h-4 w-4 fill-gray-300" name="document" />
          <span class="text-sm text-blue-500 hover:underline">{{ attachment.name }}</span>
        </a>
      </div>
    </div>
    <div class="mt-12 flex items-end justify-between">
      <h2 class="text-lg">{{ $t('Sessions') }}</h2>
    </div>
    <div class="mt-4 overflow-x-auto rounded bg-white shadow">
      <SimpleTable>
        <template #head>
          <TableHead>{{ $t('Date') }}</TableHead>
          <TableHead>{{ $t('Time') }}</TableHead>
          <TableHead>{{ $t('Instructors') }}</TableHead>
        </template>
        <TableRow
          v-for="session in class_.sessions"
          :key="session.id"
          :class="class_.is_webinar ? 'cursor-pointer hover:bg-gray-100' : ''"
          @click="class_.is_webinar && $inertia.visit(route('instructor.sessions.edit', session.id))"
        >
          <TableData>{{ session.date }}</TableData>
          <TableData>{{ session.time }}</TableData>
          <TableData>
            <span v-if="session.instructors">{{ session.instructors }}</span>
            <span v-else class="text-sm text-gray-400">-</span>
          </TableData>
          <TableData class="w-min align-middle">
            <icon v-if="class_.is_webinar" class="block h-6 w-6 fill-gray-400" name="chevron-right" />
          </TableData>
        </TableRow>
      </SimpleTable>
    </div>
    <div class="mt-12 flex items-center justify-between">
      <h2 class="text-lg">{{ $t('Students') }}</h2>
      <div v-if="class_.test_scores_enabled" class="justify-content-end flex">
        <InertiaLink class="btn-blue ml-2" :href="route('offline-test-scores.edit', class_.id)">
          {{ $t('Update Scores') }}
        </InertiaLink>
      </div>
    </div>
    <div class="mt-4 overflow-x-auto rounded bg-white shadow">
      <SimpleTable>
        <template #head>
          <TableHead>{{ $t('Customer') }}</TableHead>
          <TableHead>{{ $t('Name') }}</TableHead>
          <TableHead>{{ $t('Check In') }}</TableHead>
          <TableHead>{{ $t('Attendance') }}</TableHead>
          <TableHead>{{ $t('Certificate #') }}</TableHead>
          <TableHead v-if="class_.equipment_enabled">{{ $t('Equipment') }}</TableHead>
          <TableHead />
        </template>
        <TableRow
          v-for="(student, key) in class_.students"
          :key="student.id"
          :class="
            form.students[key] && Object.keys(form.students[key].errors).length ? 'bg-red-100' : 'hover:bg-gray-300'
          "
        >
          <TableData>
            <div v-if="student.customer" class="text-nowrap">
              <div>{{ student.customer.name }}</div>
              <div v-if="student.customer && student.customer.organization" class="mt-1 text-sm text-gray-600">
                {{ student.customer.organization.name }}
              </div>
            </div>
            <span v-else class="text-gray-400">{{ $t('Reserved') }}</span>
            <template v-if="student.enrolment">
              <div class="mt-1 flex items-center">
                <div class="text-sm text-gray-600">{{ student.enrolment.payment_status }}</div>
                <span
                  v-if="student.enrolment.payment_status === 'Paid' && student.enrolment.payment_order_number"
                  class="ml-1 rounded-full bg-gray-400 px-3 py-1 text-xs text-white"
                >
                  #{{ student.enrolment.payment_order_number }}
                </span>
              </div>
            </template>
          </TableData>
          <TableData>
            <div class="flex items-center text-nowrap">
              <!-- Assigned enrolment -->
              <template v-if="student.user">{{ student.name }}</template>
              <!-- Unassigned enrolment and owner is part of an organization -->
              <template v-else-if="student.customer && student.customer.organization">
                <user-search-input
                  v-model="form.students[student.id].user"
                  label=""
                  :organization-id="student.customer.organization.id"
                />
                <button class="btn-blue ml-2 px-2" @click.stop="assignEnrolment(student)">
                  <icon class="inline w-5 fill-white px-1" name="checkmark" />
                </button>
              </template>
              <!-- Unassigned enrolment and owner is not part of an organization -->
              <template v-else>
                <span v-if="student.name">{{ student.name }}</span>
                <span v-else class="text-gray-600">
                  <span class="text-gray-400">{{ $t('Reserved') }}</span>
                  <span class="cursor-pointer text-gray-600 hover:underline" @click.prevent="selfAssign(student)">
                    {{ $t('(Self-Assign)') }}
                  </span>
                </span>
              </template>
            </div>
            <span class="text-nowrap text-sm text-gray-600">{{ student.instructor_led_classroom.name }}</span>
          </TableData>
          <TableData class="space-y-2 whitespace-nowrap">
            <div v-if="student.checked_in_at" class="h-6 w-6 rounded-full bg-green-500 p-1">
              <icon class="block h-full w-full fill-current text-white" name="checkmark" />
            </div>
            <template v-else>
              <button class="rounded border-2 px-4 py-2 tracking-wide" @click="openSendCheckinInviteModal(student)">
                {{ $t('Send Invite') }}
              </button>
              <div v-if="student.enrolment">{{ $t('Code: ') + student.enrolment.check_in_code }}</div>
            </template>
          </TableData>
          <TableData>
            <select
              v-model="students[key].present"
              class="form-select text-sm"
              :class="{ error: students[key].errors ? students[key].errors.present : null }"
              @change="save(key)"
            >
              <option disabled :value="null" />
              <option :value="true">{{ $t('Present') }}</option>
              <option :value="false">{{ $t('Absent') }}</option>
            </select>
            <select-input
              v-if="!class_.test_scores_enabled"
              v-model="students[key].passed"
              css-class="w-full text-sm mt-3"
              :disabled="!students[key].present"
              :error="students[key].errors ? students[key].errors.passed : null"
              @update:model-value="save(key)"
            >
              <option disabled :value="null" />
              <option :value="true">{{ $t('Passed') }}</option>
              <option :value="false">{{ $t('Failed') }}</option>
            </select-input>
            <div v-else class="pt-3">
              <span v-if="student.latest_score && student.latest_score.score">
                <span>{{ student.latest_score.score }}/{{ student.latest_score.total_score }}</span>
              </span>
              <span v-if="student.passed !== null">
                <span v-if="student.passed" class="ml-1 rounded-full bg-green-500 px-3 py-1 text-xs text-white">
                  {{ $t('Passed') }}
                </span>
                <span v-else class="ml-1 rounded-full bg-red-500 px-3 py-1 text-xs text-white">{{ $t('Failed') }}</span>
              </span>
              <span v-else class="text-sm text-gray-400">-</span>
            </div>
          </TableData>
          <TableData>
            <text-input
              v-model="students[key].certificate_number"
              :disabled="!students[key] || !students[key].present"
              :error="students[key].errors ? students[key].errors.certificate_number : null"
              @update:model-value="save(key)"
            />
          </TableData>
          <TableData v-if="class_.equipment_enabled">
            <div class="flex items-center">
              <div>
                <div v-if="student.equipment_types?.length" class="flex flex-col text-sm">
                  <span v-for="(equipment, index) in student?.equipment_types" :key="index">
                    {{ equipment }}
                  </span>
                </div>
                <span v-else>-</span>
              </div>
              <div>
                <icon
                  class="ml-2 block h-5 w-5 text-gray-500 hover:cursor-pointer hover:text-gray-600"
                  name="pencil"
                  @click="openEditEquipmentModal(student)"
                />
              </div>
            </div>
          </TableData>
          <TableData>
            <div class="flex flex-col space-y-2">
              <InertiaLink
                class="rounded border-2 px-3 py-1"
                :href="route('instructor.enrolments.show', student.enrolment)"
              >
                {{ $t('View Enrolment') }}
              </InertiaLink>
              <button
                v-if="!!students[key].enrolment"
                class="flex items-center justify-center rounded border-2 px-3 py-1"
                @click="openViewNotesModal(students[key])"
              >
                {{ $t('View Notes') }}
                <span
                  v-if="students[key].enrolment.notes_count > 0"
                  class="ml-1 flex h-5 w-5 items-center justify-center rounded-full bg-gray-100 text-xs"
                >
                  <span>{{ students[key].enrolment.notes_count }}</span>
                </span>
              </button>
            </div>
          </TableData>
        </TableRow>
        <tr v-if="class_.students.length === 0">
          <TableData class="border-t px-6 py-4" colspan="7">{{ $t('No students found.') }}</TableData>
        </tr>
      </SimpleTable>
    </div>
    <h2 v-if="class_.subclasses.length > 0" class="mt-12 text-lg">{{ $t('Subclasses') }}</h2>
    <div
      v-if="class_.subclasses.length > 0"
      class="mt-4 grid grid-cols-1 gap-4 overflow-x-auto sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
    >
      <div v-for="subclass in class_.subclasses" :key="subclass.id" class="flex flex-col rounded bg-white p-6 shadow">
        <span class="text-lg">{{ subclass.instructor_led_classroom.name }}</span>
        <span class="mt-2">{{ subclass.dates }}</span>
        <span class="mt-2">{{ subclass.time_range }}</span>
        <span class="mt-2">{{ $t('Capacity:') }} {{ subclass.capacity }}</span>
      </div>
    </div>
    <Modal class="text-base" :show="showModal" width="300px" @close="close">
      <div class="rounded-lg bg-white p-8">
        <div>
          <span class="font-bold">{{ $t('Code') }}</span>
        </div>
        <div class="mt-4">
          <span>{{ code }}</span>
        </div>
        <div class="mt-4 flex items-end justify-between text-xs">
          <span class="mr-10">{{ $t('Expires in :expires', { expire: expire }) }}</span>
          <button class="rounded border-2 px-4 py-2 uppercase tracking-wide" @click="close">{{ $t('Close') }}</button>
        </div>
      </div>
    </Modal>
    <view-notes-modal
      v-if="showViewNotesModal"
      :show="showViewNotesModal"
      :student="selectedStudent"
      @close="closeViewNotesModal"
    />
    <send-checkin-invite-modal
      v-if="showSendCheckinInviteModal"
      :show="showSendCheckinInviteModal"
      :student="selectedStudent"
      @close="closeSendCheckinInviteModal"
    />
    <edit-student-equipment-modal
      v-if="showEditEquipmentModal"
      :default-equipment-types="class_.default_equipment_types"
      :show="showEditEquipmentModal"
      :student="selectedStudent"
      @close="closeEditEquipmentModal"
    />
  </layout>
</template>

<script>
import EditStudentEquipmentModal from './EditStudentEquipmentModal.vue'
import SendCheckinInviteModal from './SendCheckinInviteModal.vue'
import ViewNotesModal from './ViewNotesModal.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import Dropdown from '@/Shared/Dropdown.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Modal from '@/Shared/Modal.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import TextInput from '@/Shared/TextInput.vue'
import UserSearchInput from '@/Shared/UserSearchInput.vue'
import Http from '@/Utils/Http'
import { useForm } from '@inertiajs/vue3'
import _map from 'lodash/map'
import _pick from 'lodash/pick'
import moment from 'moment'

export default {
  components: {
    ViewNotesModal,
    SendCheckinInviteModal,
    EditStudentEquipmentModal,
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
    Breadcrumb,
    Dropdown,
    Icon,
    Layout,
    SelectInput,
    TextInput,
    UserSearchInput,
    Modal,
  },
  props: {
    class_: Object,
    timestamp: Number,
  },
  data() {
    var student_list = {}
    this.class_.students.forEach((student) => {
      student_list[student['id']] = {
        id: student['id'],
        user: null,
      }
    })
    return {
      students: _map(this.class_.students, (student) => {
        return {
          ..._pick(student, ['id', 'present', 'passed', 'latest_score', 'certificate_number', 'enrolment']),
          errors: {},
        }
      }),
      showViewNotesModal: false,
      showSendCheckinInviteModal: false,
      showEditEquipmentModal: false,
      selectedStudent: null,
      userForEnrolment: null,
      intervalId: null,
      showModal: false,
      expire: 0,
      code: '',
      oneHourLater: null,
      processLogoutIntervalId: null,
      form: useForm({
        students: student_list,
      }),
    }
  },
  mounted() {
    this.oneHourLater = moment().add(1, 'hours')

    this.processLogoutIntervalId = setInterval(this.processLogout, moment.duration('10', 'minutes'))
  },
  beforeUnmount() {
    clearInterval(this.processLogoutIntervalId)

    clearInterval(this.intervalId)
  },
  methods: {
    processLogout() {
      if (moment().diff(this.oneHourLater, 'minutes') > 0) {
        clearInterval(this.processLogoutIntervalId)

        this.$inertia.post(this.route('logout'))
      }
    },
    save(key) {
      setTimeout(() => {
        let student = this.students[key]
        const data = {
          present: student.present,
          certificate_number: student.certificate_number,
        }

        if (!this.class_.test_scores_enabled) {
          if (!student.present) {
            this.students[key].passed = false
            student = this.students[key]
          }

          data.passed = student.passed
        }

        Http.put(this.route('instructor.students.update', student.id), data)
          .then(() => (this.students[key].errors = {}))
          .catch((error) => (this.students[key].errors = error.response.data.errors))
      }, 500)
    },
    create() {
      Http.post(this.route('instructor.checkin.code.store'), { class: this.class_.id }).then((response) => {
        if (this.intervalId) {
          clearInterval(this.intervalId)
        }

        this.showModal = true
        this.code = response.data
        this.expire = 60
        let vm = this

        this.intervalId = setInterval(
          function () {
            if (vm.expire <= 0) {
              clearInterval(vm.intervalId)

              vm.intervalId = null
              vm.showModal = false
            } else {
              --vm.expire
            }
          },
          moment.duration('1', 'minutes')
        )
      })
    },
    close() {
      this.showModal = false

      clearInterval(this.intervalId)

      Http.delete(this.route('instructor.checkin.code.destroy'), { params: { code: this.code } })
    },
    selfAssign(student) {
      if (!student.enrolment) {
        return alert(
          this.$t('This student (ID: :student_id) cannot be self-assigned because they are missing an enrolment.', {
            student_id: `${student.id}`,
          })
        )
      }

      if (confirm(this.$t('Are you sure you want to self-assign this student?'))) {
        this.$inertia.put(this.route('instructor.students.self-assign', student.id))
      }
    },
    assignEnrolment(student) {
      if (confirm(this.$t('Are you sure you want to self-assign this student?'))) {
        this.form
          .transform((data) => {
            return {
              user_id: data.students[student.id].user.id,
            }
          })
          .put(this.route('instructor.students.assign-enrolment', student.id))
      }
    },
    openViewNotesModal(student) {
      this.showViewNotesModal = true
      this.selectedStudent = student
    },
    closeViewNotesModal() {
      this.showViewNotesModal = false
      this.selectedStudent = null
    },
    openSendCheckinInviteModal(student) {
      this.showSendCheckinInviteModal = true
      this.selectedStudent = student
    },
    closeSendCheckinInviteModal() {
      this.showSendCheckinInviteModal = false
      this.selectedStudent = null
    },
    openEditEquipmentModal(student) {
      this.showEditEquipmentModal = true
      this.selectedStudent = student
    },
    closeEditEquipmentModal() {
      this.showEditEquipmentModal = false
      this.selectedStudent = null
    },
  },
}
</script>
