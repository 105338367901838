<template>
  <front-layout :title="$t('Users')">
    <div class="flex flex-col xl:flex-row xl:justify-between">
      <div class="sm:no-flex mb-8 flex w-full items-center justify-between">
        <breadcrumb name="People" />
        <front-button-link class="sm:hidden" :href="route('front.manager.users.create')">
          <span>{{ $t('+ Add') }}</span>
        </front-button-link>
      </div>
      <div class="mb-6 flex w-full items-center justify-between xl:justify-end">
        <front-search-filter
          v-model="form.search"
          class="flex w-full flex-col sm:mr-4 sm:flex-row"
          :filter-show="true"
          @reset="reset"
        >
          <div class="flex flex-col gap-4">
            <div v-if="groups">
              <label class="block text-gray-800">{{ $t('Group:') }}</label>
              <select v-model="selectedGroup" class="form-select mt-1">
                <option disabled :value="null">{{ $t('Filter Group') }}</option>
                <option v-for="group in groups" :key="group.id" :value="group.id">
                  {{ group.name }}
                </option>
                <option :value="null">{{ $t('All') }}</option>
              </select>
            </div>
            <div v-if="locations">
              <label class="block text-gray-800">{{ $t('Location:') }}</label>
              <select v-model="selectedLocation" class="form-select mt-1">
                <option disabled :value="null">{{ $t('Filter Location') }}</option>
                <option v-for="location in locations" :key="location.id" :value="location.id">
                  {{ location.name }}
                </option>
                <option :value="null">{{ $t('All') }}</option>
              </select>
            </div>

            <div>
              <label class="block text-gray-800">{{ $t('Deleted:') }}</label>
              <select v-model="form.archived" aria-placeholder="Show Deleted" class="form-select mt-1 w-full">
                <option value="with">{{ $t('Show Deleted') }}</option>
                <option :value="null">{{ $t('Hide Deleted') }}</option>
              </select>
            </div>

            <div>
              <label class="block text-gray-800">{{ $t('Job Profile:') }}</label>
              <select v-model="form.job_profile" aria-placeholder="Show Job Profile" class="form-select mt-1 w-full">
                <option disabled value="">{{ $t('Filter Profile') }}</option>
                <option selected :value="null">{{ $t('All') }}</option>
                <option v-for="option in job_profiles" :key="option.id" :value="option.id">{{ option.name }}</option>
              </select>
            </div>
          </div>
        </front-search-filter>
        <FrontDropdown :auto-close="true">
          <div class="relative z-10 flex cursor-pointer select-none items-center">
            <span class="mr-2">Actions</span>
            <ChevronDownIcon class="h-5 w-5 text-white" />
          </div>
          <template #dropdown>
            <div class="relative">
              <div class="absolute right-0 z-20 mt-2 w-48 rounded-md bg-white py-2 shadow-xl">
                <front-dropdown-option :href="route('front.manager.users.create')">
                  {{ $t('Create User') }}
                </front-dropdown-option>
                <front-dropdown-option @click.prevent="() => (showImportModal = true)">
                  {{ $t('Import Users') }}
                </front-dropdown-option>
              </div>
            </div>
          </template>
        </FrontDropdown>
      </div>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow-lg">
      <table class="front-table">
        <thead>
          <tr>
            <th class="font-semibold lg:w-1/3" scope="col">{{ $t('Name') }}</th>
            <th scope="col">{{ $t('Email') }}</th>
            <th scope="col">{{ $t('Phone') }}</th>
            <th scope="col">{{ $t('Group') }}</th>
            <th colspan="2" scope="col">
              {{ $t('Last Seen') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="users.data.length === 0">
            <td colspan="6">{{ $t('No users found.') }}</td>
          </tr>
          <tr
            v-for="user in users.data"
            :key="user.id"
            class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
            @click="$inertia.visit(route('front.manager.users.show', user.id))"
          >
            <td class="font-semibold text-ts-gray-text-bold">
              {{ user.name }}
              <icon
                v-if="user.archived_at"
                class="ml-1 inline-flex h-3 w-3 flex-shrink-0 fill-gray-500 align-baseline"
                name="trash"
              />
            </td>
            <td>{{ user.email || '-' }}</td>
            <td>{{ user.phone || '-' }}</td>
            <td>
              <span v-if="user.groups.length > 0">
                {{ user.groups[0].name }}
              </span>
              <span v-else>-</span>
              <span v-if="user.groups.length > 1">and {{ user.groups.length - 1 }} more</span>
            </td>
            <td class="text-center">
              <template v-if="user.last_login_at">
                <span>{{ user.last_login_at.date }}</span>
                <br />
                <span class="text-xs">{{ user.last_login_at.time }}</span>
              </template>
              <template v-else>-</template>
            </td>
            <td>
              <InertiaLink class="flex items-center justify-center" :href="route('front.manager.users.show', user.id)">
                <span class="font-semibold hover:underline">{{ $t('View') }}</span>
              </InertiaLink>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <front-pagination class="pt-1" :links="users.links" />
    <UserImportModal :show="showImportModal" @close="() => (showImportModal = false)" />
  </front-layout>
</template>

<script>
import UserImportModal from './UserImportModal.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontButtonLink from '@/Shared/FrontButtonLink.vue'
import FrontDropdown from '@/Shared/FrontDropdown.vue'
import FrontDropdownOption from '@/Shared/FrontDropdownOption.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontPagination from '@/Shared/FrontPagination.vue'
import FrontSearchFilter from '@/Shared/FrontSearchFilter.vue'
import Icon from '@/Shared/Icon.vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    UserImportModal,
    ChevronDownIcon,
    Breadcrumb,
    FrontButtonLink,
    FrontDropdown,
    FrontDropdownOption,
    FrontLayout,
    FrontPagination,
    FrontSearchFilter,
    Icon,
  },
  props: {
    filters: Object,
    groups: Array,
    locations: Array,
    users: Object,
    job_profiles: Array,
    hide: Boolean,
  },
  data() {
    return {
      selectedGroup: this.filters.groups ? this.filters.groups[0] : null,
      selectedLocation: this.filters.locations ? this.filters.locations[0] : null,
      form: {
        search: this.filters.search,
        groups: this.filters.groups,
        archived: this.filters.archived,
        locations: this.filters.locations,
        job_profile: this.filters.job_profile,
      },
      showImportModal: false,
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)

        this.$inertia.get(
          this.route('front.manager.users'),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
    selectedGroup: {
      handler: _throttle(function () {
        if (!this.selectedGroup) {
          this.form.groups = null
          return
        }
        this.form.groups = [this.selectedGroup]
      }, 300),
      deep: true,
    },
    selectedLocation: {
      handler: _throttle(function () {
        if (!this.selectedLocation) {
          this.form.locations = null
          return
        }
        this.form.locations = [this.selectedLocation]
      }, 300),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
