<template>
  <front-layout :title="folder.name">
    <div class="w-full max-w-5xl">
      <div class="flex flex-col xl:flex-row xl:justify-between">
        <div class="sm:no-flex mb-8 flex w-full items-center justify-between">
          <breadcrumb :name="folder.name" />
        </div>
        <div class="mb-6 flex w-full items-center justify-end">
          <front-search-filter
            v-model="form.search"
            class="flex w-full flex-col sm:flex-row lg:max-w-sm"
            :filter-show="false"
            @reset="reset"
          />
        </div>
      </div>
      <div class="overflow-x-auto rounded bg-white shadow-lg">
        <table class="front-table w-full">
          <thead>
            <tr>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
                {{ $t('Name') }}
              </th>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">{{ $t('Size') }}</th>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">{{ $t('Uploaded') }}</th>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold" />
            </tr>
          </thead>
          <tbody>
            <tr v-if="folder.files.length == 0">
              <td class="border-t px-6 py-4" colspan="5">{{ $t('No files found.') }}</td>
            </tr>
            <tr v-for="file in folder.files" :key="file.id" class="cursor-pointer">
              <td class="font-semibold text-ts-gray-text-bold">
                <front-truncate-text class="max-w-md" :text="file.name" />
              </td>
              <td class="font-semibold text-ts-gray-text-bold">
                {{ file.size }}
              </td>
              <td class="font-semibold text-ts-gray-text-bold">{{ file.created_at }}</td>
              <td class="font-semibold text-ts-gray-text-bold">
                <a :href="file.url" target="_blank">Open</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontSearchFilter from '@/Shared/FrontSearchFilter.vue'
import FrontTruncateText from '@/Shared/FrontTruncateText.vue'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    Breadcrumb,
    FrontLayout,
    FrontSearchFilter,
    FrontTruncateText,
  },
  props: {
    folder: Object,
    filters: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
      },
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(
          this.route('front.folders.show', this.folder),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
  },
}
</script>
