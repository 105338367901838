<template>
  <Disclosure v-slot="{ open }" as="div" class="relative flex w-full flex-col items-center" :default-open="true">
    <button class="absolute -right-8">
      <TrashIcon aria-hidden="true" class="h-10 w-5 cursor-pointer text-gray-500" @click="onDelete" />
    </button>

    <div
      class="text-m border-gray relative flex w-full flex-row items-center overflow-hidden border bg-white text-gray-500 shadow"
      :class="{ 'rounded-bl-lg': open }"
    >
      <div class="basis-2/3">
        <div class="flex">
          <div class="mt-3.5 flex text-gray-900">
            <DisclosureButton>
              <button
                class="mx-3 flex h-6 w-6 flex-none cursor-pointer items-center justify-center rounded-full bg-qualify-red-500 text-white"
              >
                <ChevronRightIcon aria-hidden="true" class="h-4 w-4 transition" :class="{ 'rotate-90': open }" />
              </button>
            </DisclosureButton>
            <Bars3Icon
              aria-hidden="true"
              class="handle z-20 mx-3 flex h-6 w-6 flex-none cursor-move items-center justify-center text-gray-400"
            />
          </div>
          <div class="w-full">
            <front-text-input
              v-model="sectionData.title"
              class="placeholder-shown:text-bold placeholder-shown:text-m m-0 mt-0 border-0 bg-white px-2 py-4 ring-0 ring-inset placeholder-shown:border-0 placeholder-shown:ring-0 focus:outline-none focus:ring-2 focus:ring-qualify-red-600"
              :placeholder="$t('Type section title')"
            />
            <button v-if="open" class="my-3 inline-flex cursor-pointer items-center" @click="onAddQuestion">
              <PlusIcon aria-hidden="true" class="mr-2 h-4 w-4 text-qualify-red-500" />
              <span class="text-sm text-qualify-red-500">Add question</span>
            </button>
          </div>
        </div>
      </div>
      <div class="basis-1/3">
        <div class="flex items-center px-4">
          <input
            id="required"
            aria-describedby="required-description"
            class="mr-4 h-4 w-4 rounded border-gray-600 text-gray-600 focus:ring-indigo-500"
            name="required"
            type="checkbox"
          />
          <label class="text-sm text-gray-600" for="required">Repeat this section</label>
        </div>
      </div>
    </div>
    <DisclosurePanel class="w-full pl-3">
      <div class="borer-gray-600 border-l-2 pl-3">
        <slot />
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>
<script>
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { Bars3Icon, TrashIcon } from '@heroicons/vue/20/solid'
import { ChevronRightIcon, PlusIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronRightIcon,
    PlusIcon,
    TrashIcon,
    Bars3Icon,
    FrontTextInput,
  },
  inject: ['addSection', 'addQuestion', 'updateFormContent', 'deleteFormContent'],
  props: {
    formContent: Object,
  },
  data() {
    return {
      sectionData: {
        title: this.formContent.title,
      },
    }
  },
  watch: {
    sectionData: {
      handler: function () {
        this.update()
      },
      deep: true,
    },
  },
  methods: {
    update() {
      this.updateFormContent(this.formContent, this.sectionData)
    },
    onAddQuestion() {
      this.addQuestion(this.formContent.children)
    },
    onDelete() {
      this.deleteFormContent(this.formContent)
    },
  },
}
</script>
