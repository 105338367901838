<template>
  <menu-item class="mb-1" :name="name" :route-link="routeLink" :url="url">
    <icon class="mr-3 h-5 w-5" fill="currentColor" :name="icon" :v-if="icon" />
  </menu-item>
</template>

<script>
import Icon from './Icon.vue'
import MenuItem from './MenuItem.vue'

export default {
  components: { MenuItem, Icon },
  props: {
    name: String,
    routeLink: String,
    icon: String,
    url: {
      type: [String, Array],
      default: '',
    },
  },
}
</script>
