<template>
  <layout :title="$t('Dashboard')">
    <div v-for="(classes, name) in { this: classesThisWeek, next: classesNextWeek }" :key="name" class="last:mt-12">
      <div class="flex justify-between">
        <h2 class="text-lg capitalize">{{ $t('Classes :name week', { name: name }) }}</h2>
      </div>
      <div class="mt-6 overflow-x-auto rounded bg-white shadow">
        <table class="w-full">
          <thead>
            <tr>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Name') }}</th>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Sessions') }}</th>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold" colspan="2">{{ $t('Location') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="class_ in classes"
              :key="class_.id"
              class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
              @click="$inertia.visit(route('instructor.classes.show', class_.id))"
            >
              <td class="whitespace-nowrap border-t px-6 py-4">
                <div class="max-w-15 truncate">{{ class_.instructorLedClassroom.name }}</div>
                <div class="mt-1 flex text-sm text-gray-600">
                  <div>
                    {{ $t('Capacity:') }}
                    <span class="ml-0.5">{{ class_.capacity }}</span>
                  </div>
                  <div class="ml-3">
                    {{ $t('Filled:') }}
                    <span class="ml-0.5">{{ class_.students }}</span>
                  </div>
                  <div class="ml-3">
                    {{ $t('Available:') }}
                    <span class="ml-0.5">{{ class_.available_seats }}</span>
                  </div>
                </div>
              </td>
              <td class="whitespace-nowrap border-t px-6 py-4">
                <div class="-mt-2">
                  <div v-for="session in class_.sessions" :Key="session.id" class="mt-2 flex">
                    <div>{{ session.date }}</div>
                    <div class="ml-1 text-sm text-gray-600">({{ session.time }})</div>
                  </div>
                </div>
              </td>
              <td class="whitespace-nowrap border-t px-6 py-4">
                <div>{{ class_.location.name }}</div>
                <div v-if="class_.room" class="mt-1 text-sm text-gray-600">({{ class_.room.name }})</div>
              </td>
              <td class="w-min border-t px-4 align-middle">
                <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
              </td>
            </tr>
            <tr v-if="classes.length === 0">
              <td class="border-t px-6 py-4" colspan="4">{{ $t('No classes :name week.', { name: name }) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </layout>
</template>

<script>
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'

export default {
  components: {
    Icon,
    Layout,
  },
  props: {
    classesThisWeek: Array,
    classesNextWeek: Array,
  },
}
</script>
