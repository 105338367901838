<template>
  <modal class="rounded text-base" :show="show" style="z-index: 100000" width="50rem" @close="close">
    <div class="max-h-screen max-w-lg overflow-auto rounded bg-white shadow">
      <div class="p-6 md:px-10 md:py-6">
        <h4 class="mb-4 mt-8 text-left text-2xl font-semibold text-black">
          {{ $t("You're about to cancel your subscription") }}
        </h4>
        <p>
          {{
            $t(
              'Your subscription will expire at the end of the current billing cycle. Any users under the subscription will no longer have access to these services. You will lose:'
            )
          }}
        </p>
      </div>
      <hr />
      <div class="px-10 py-8 md:px-14 md:py-8">
        <div class="grid content-center gap-3">
          <div v-for="(feature, index) in getFeatures" :key="index">
            <span class="inline-flex items-center">
              <XMarkIcon aria-hidden="true" class="mr-2 h-6 w-6 stroke-qualify-red-900" />
              {{ feature }}
            </span>
          </div>
        </div>
      </div>
      <hr />
      <div class="bg-gray-200/60 px-6 py-4 md:px-10 md:py-8">
        <div class="flex items-center justify-between">
          <div class="text-sm font-semibold">
            {{ $t('Your subscription expires on ') }}
            <span class="text-ts-red-500">{{ expiry_dt }}</span>
          </div>
          <front-solid-button class="transform px-12 py-4" @click="cancelSubscription()">
            {{ $t('Cancel subscription') }}
          </front-solid-button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import FrontSolidButton from '@/Shared/FrontSolidButton.vue'
import Modal from '@/Shared/Modal.vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import moment from 'moment'

export default {
  components: { XMarkIcon, FrontSolidButton, Modal },
  props: {
    show: Boolean,
    subscription: Object,
  },
  data() {
    return {
      expiry_dt: moment(this.subscription.currentPeriodEnd).format('YYYY-MM-DD'),
    }
  },
  computed: {
    getFeatures() {
      return this.subscription.items[0].subscriptionPlan.features?.split('|')
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    cancelSubscription() {
      this.$inertia.post(this.route('front.admin.subscription.stop', [this.subscription.items[0].subscriptionPlan.id]))
      this.close()
    },
  },
}
</script>
