<template>
  <div class="ts-bg flex min-h-screen items-start justify-center p-6">
    <div class="w-full max-w-sm">
      <div class="mt-20 rounded-lg bg-white shadow-lg">
        <div class="px-10 py-6">
          <div class="text-xl text-gray-600">
            {{ message }}
            <InertiaLink class="block pt-6 text-sm text-blue-600 hover:text-blue-700" :href="route('front.home')">
              {{ $t('Go to the site') }} &rarr;
            </InertiaLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: String,
  },
}
</script>
