<template>
  <front-layout :title="$t('Forms')">
    <div class="flex flex-col md:flex-row">
      <div class="sm:no-flex mb-8 flex w-full items-center justify-between">
        <breadcrumb :name="$t('Forms')" />
      </div>
      <div class="mb-6 flex w-full items-center justify-end space-x-4">
        <front-search-filter
          v-model="form.form_search"
          class="flex w-full flex-col sm:flex-row lg:max-w-sm"
          :filter-show="false"
        />
        <a class="btn-red" href="#" @click.prevent="showNewFormModal()">
          {{ $t('New Form') }}
        </a>
      </div>
    </div>
    <section class="space-y-2 overflow-x-auto">
      <div v-if="!filledForms.data.length">
        <span class="px-6 py-4" colspan="6">{{ $t('No inspections.') }}</span>
      </div>
      <template v-else>
        <div
          v-for="filledForm in filledForms.data"
          :key="filledForm.id"
          class="flex cursor-pointer flex-col rounded-lg border-x border-b bg-white px-6 pb-4 pt-6 lg:flex-row lg:items-start"
        >
          <div class="flex-1 space-y-4 lg:pr-8">
            <h3 class="font-semibold">{{ filledForm.form_template.title }}</h3>
            <div class="flex flex-col justify-start md:flex-row">
              <div class="flex gap-2 pr-4 md:w-1/3 md:flex-col md:gap-0">
                <span class="text-xs text-ts-gray-400">{{ $t('Started At') }}</span>
                <front-date class="text-xs" format="MMMM Do YYYY" :timestamp="filledForm.created_at" />
              </div>
              <div class="flex gap-2 pr-4 md:w-1/3 md:flex-col md:gap-0">
                <span class="text-xs text-ts-gray-400">{{ $t('Last Updated') }}</span>
                <front-date class="text-xs" format="MMMM Do YYYY" :timestamp="filledForm.updated_at" />
              </div>
              <div v-if="filledForm.completed_at" class="flex gap-2 pr-4 md:w-1/3 md:flex-col md:gap-0">
                <span class="text-xs text-ts-gray-400">{{ $t('Completed At') }}</span>
                <front-date class="text-xs" format="MMMM Do YYYY" :timestamp="filledForm.completed_at" />
              </div>
            </div>
          </div>
          <div
            class="flex justify-center whitespace-nowrap text-sm font-medium leading-5 lg:w-1/4 lg:justify-end xl:w-1/5"
          >
            <front-outline-button-link
              v-if="
                filledForm.signature_requests.length && filledForm.signature_requests[0].signature_type !== 'assignee'
              "
              class="my-2 inline-block"
              :href="
                route('front.filledForms.complete', {
                  formFilled: filledForm.id,
                  signatureRequestId: filledForm.signature_requests[0].id,
                })
              "
            >
              {{ $t('Review & Sign') }}
            </front-outline-button-link>
            <front-outline-button-link
              v-else-if="filledForm.completed_at"
              class="my-2 inline-block"
              :href="route('front.filledForms.view', filledForm.id)"
            >
              {{ $t('View Report') }}
            </front-outline-button-link>
            <front-outline-button-link
              v-else-if="filledForm.review_status === 'in-progress'"
              class="my-2 inline-block"
              :href="route('front.filledForms.complete', filledForm.id)"
            >
              {{ $t('In Review') }}
            </front-outline-button-link>
            <front-outline-button-link
              v-else-if="filledForm.review_status === 'completed'"
              class="my-2 inline-block"
              :href="route('front.filledForms.complete', filledForm.id)"
            >
              {{ $t('Review Done') }}
            </front-outline-button-link>
            <front-outline-button-link
              v-else
              class="my-2 inline-block"
              :href="route('front.filledForms.edit', filledForm.id)"
            >
              {{ $t('Continue') }}
            </front-outline-button-link>
          </div>
        </div>
      </template>
    </section>
    <NewFormModal :show="isShowNewFormModal" @close="hideNewFormModal" />
  </front-layout>
</template>

<script>
import NewFormModal from '../Forms/NewFormModal.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontDate from '@/Shared/FrontDate.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontOutlineButtonLink from '@/Shared/FrontOutlineButtonLink.vue'
import FrontSearchFilter from '@/Shared/FrontSearchFilter.vue'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: { FrontDate, FrontOutlineButtonLink, NewFormModal, Breadcrumb, FrontLayout, FrontSearchFilter },
  props: {
    filters: Object,
    filledForms: Object,
    filledFormsInReview: Object,
  },
  data() {
    return {
      form: {
        template_search: this.filters.template_search,
        form_search: this.filters.form_search,
      },
      isShowNewFormModal: false,
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(
          this.route('front.filledForms.index'),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveScroll: true,
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
  },
  methods: {
    showNewFormModal() {
      this.isShowNewFormModal = true
    },
    hideNewFormModal() {
      this.isShowNewFormModal = false
    },
  },
}
</script>
