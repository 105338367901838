<template>
  <layout :title="class_.name">
    <div class="-mt-4 mb-8 flex items-end justify-between">
      <div>
        <breadcrumb
          :name="class_.instructor_led_classroom.name"
          :previous-name="$t('Classes')"
          :previous-url="route('classes')"
        />
      </div>
      <dropdown class="btn-blue" placement="bottom-end">
        <div class="flex cursor-pointer select-none items-center">
          <div>
            <span>{{ $t('Actions') }}</span>
          </div>
          <icon class="-my-1 h-5 w-5 fill-white" name="chevron-down" />
        </div>
        <template #dropdown="{ toggle: toggleDropdown }">
          <div class="mt-2 rounded border bg-white py-2 text-sm shadow-lg">
            <a
              v-if="class_.can.update"
              class="block w-full px-4 py-2 text-left hover:bg-gray-200 hover:text-gray-700"
              :href="route('classes.edit', class_.id)"
            >
              {{ $t('Edit Class') }}
            </a>
            <a
              v-if="class_.can.duplicate"
              class="block w-full px-4 py-2 text-left hover:bg-gray-200 hover:text-gray-700"
              :href="route('classes.duplicate.show', class_.id)"
            >
              {{ $t('Duplicate Class') }}
            </a>
            <a
              v-if="class_.can.update"
              class="block w-full px-4 py-2 text-left hover:bg-gray-200 hover:text-gray-700"
              :href="route('subclasses.create', class_.id)"
            >
              {{ $t('Create Subclass') }}
            </a>
            <button
              v-if="class_.can.restore && class_.deleted_at"
              class="block w-full px-4 py-2 text-left hover:bg-gray-200 hover:text-gray-700"
              tabindex="-1"
              type="button"
              @click="restore"
            >
              {{ $t('Restore Class') }}
            </button>
            <button
              v-if="class_.can.delete && !class_.deleted_at"
              class="block w-full px-4 py-2 text-left hover:bg-gray-200 hover:text-gray-700"
              tabindex="-1"
              type="button"
              @click="destroy"
            >
              {{ $t('Delete Class') }}
            </button>
            <button
              v-if="class_.can.cancel && !class_.cancelled_at"
              class="block w-full px-4 py-2 text-left hover:bg-gray-200 hover:text-gray-700"
              tabindex="-1"
              type="button"
              @click="cancel"
            >
              {{ $t('Cancel Class') }}
            </button>
            <InertiaLink
              v-if="class_.can.postToMarketplace.length > 0"
              class="block w-full px-4 py-2 text-left hover:bg-gray-200 hover:text-gray-700"
              :href="route('classes.marketplace.index', class_.id)"
              type="button"
            >
              {{ $t('Marketplace / My Business') }}
            </InertiaLink>
            <button
              v-if="class_.can.requestStudentsReview"
              class="block w-full px-4 py-2 text-left hover:bg-gray-200 hover:text-gray-700"
              type="button"
              @click=";(showConfirmRequestModal = true), toggleDropdown()"
            >
              {{ $t('Request Location Review') }}
            </button>
            <a
              v-if="class_.can.exportClassList && class_.course"
              class="block w-full px-4 py-2 text-left hover:bg-gray-200 hover:text-gray-700"
              :href="route('classes.list.export', [class_.id, class_.course.id])"
            >
              {{ $t('Export Class List') }}
            </a>
            <a
              v-if="class_.can.exportEnergySafetyCanadaList && class_.course"
              class="block w-full px-4 py-2 text-left hover:bg-gray-200 hover:text-gray-700"
              :href="route('classes.energy-safety-list.export', [class_.id, class_.course.id])"
            >
              Export Energy Safety List
            </a>
          </div>
        </template>
      </dropdown>
    </div>
    <deleted-message v-if="class_.deleted_at" class="mb-6">{{ $t('This class has been deleted.') }}</deleted-message>
    <div class="flex flex-wrap">
      <div class="w-full lg:w-1/3">
        <div class="rounded bg-white p-8 shadow">
          <div class="flex">
            <div class="mr-1 font-medium">{{ $t('Seller:') }}</div>
            <div>{{ class_.tenant.name }}</div>
          </div>
          <div v-if="class_.capacity" class="mt-5 flex">
            <div class="mr-1 font-medium">{{ $t('Capacity:') }}</div>
            <div>{{ class_.capacity }}</div>
          </div>
          <div class="mt-5 flex">
            <div class="mr-1 font-medium">{{ $t('Access:') }}</div>
            <div>{{ class_.private ? 'Private' : 'Public' }}</div>
          </div>
          <div class="mt-5 flex">
            <div class="mr-1 font-medium">{{ $t('Locale:') }}</div>
            <div class="uppercase">{{ class_.locale }}</div>
          </div>
          <div v-if="class_.skillspass_status" class="mt-5">
            <class-ministry-of-labour-status-badge :status="class_.skillspass_status" />
          </div>
          <div v-if="class_.price" class="mt-5 flex">
            <div class="mr-1 font-medium">{{ $t('Price:') }}</div>
            <div>${{ class_.price }}</div>
          </div>
          <div v-if="class_.reselling && class_.reselling.seats_percentage" class="mt-5 flex">
            <div class="mr-1 font-medium">{{ $t('Reselling seats available:') }}</div>
            <div>
              {{ $t(':seats_percentage% of capacity', { seats_percentage: class_.reselling.seats_percentage }) }}
            </div>
          </div>
          <div v-if="class_.reselling && class_.reselling.seats_quantity" class="mt-5 flex">
            <div class="mr-1 font-medium">{{ $t('Reselling seats available:') }}</div>
            <div>{{ $t(':seats_quantity seats', { seats_quantity: class_.reselling.seats_quantity }) }}</div>
          </div>
          <div v-if="class_.reselling && class_.reselling.commission_amount" class="mt-5 flex">
            <div class="mr-1 font-medium">{{ $t('Reselling commission:') }}</div>
            <div>
              {{ $t(':commission_rate%', { commission_rate: class_.reselling.commission_rate }) }}
              <span class="text-gray-600">${{ class_.reselling.commission_amount }}</span>
            </div>
          </div>
          <div v-if="class_.tenant.has_reviews_io_enabled && !class_.location.client_site" class="mt-5">
            <div class="flex">
              <div class="mr-1 font-medium">{{ $t('Reviews.io Status:') }}</div>
              <div>
                {{
                  class_.course_location && class_.course_location.has_reviews_io_enabled
                    ? $t('Enabled')
                    : $t('Disabled')
                }}
              </div>
            </div>
          </div>
          <div
            v-if="
              class_.course_location &&
              !class_.course_location.has_reviews_io_enabled &&
              class_.can.updateCourseLocation
            "
            class="relative mt-4 rounded border border-gray-400 bg-gray-100 px-4 py-3 text-sm text-gray-700"
          >
            {{ $t("This location doesn't have the reviews.io integration enabled yet.") }}
            <InertiaLink
              v-if="class_.course_location && class_.can.updateCourseLocation"
              class="text-sm underline"
              :href="
                route('instructor-led-classrooms.locations.edit', [
                  class_.instructor_led_classroom.id,
                  class_.course_location.location_id,
                ])
              "
            >
              {{ $t('Click here to do so.') }}
            </InertiaLink>
          </div>
        </div>
      </div>
      <div v-if="class_.location" class="flex w-full flex-col pt-12 lg:w-1/3 lg:pl-8 lg:pt-0">
        <div class="rounded bg-white shadow">
          <div class="block px-4 py-6">
            <div class="font-bold">{{ $t('Location: :name', { name: class_.location.name }) }}</div>
            <div class="mt-4 leading-tight">
              <div v-if="class_.location.type === 'class'" class="inline-block text-gray-600">
                <div>{{ class_.location.address }}</div>
                <div>{{ class_.location.city }}, {{ class_.location.region }} {{ class_.location.postal_code }}</div>
              </div>
              <InertiaLink
                v-if="class_.course_location && class_.can.updateCourseLocation"
                class="text-sm underline"
                :href="
                  route('instructor-led-classrooms.locations.edit', [
                    class_.instructor_led_classroom.id,
                    class_.course_location.location_id,
                  ])
                "
              >
                <span>{{ $t('Edit') }}</span>
              </InertiaLink>
            </div>
            <div v-if="class_.room" class="mt-4">{{ $t('Room: :name', { name: class_.room.name }) }}</div>
            <div v-if="class_.reviews_request_status" class="mt-4">
              {{ $t('Review Status:') }}
              <span
                class="rounded px-2 py-1"
                :class="{
                  'bg-yellow-200': class_.reviews_request_status === 'sending',
                  'bg-green-200': class_.reviews_request_status === 'sent',
                }"
              >
                {{ class_.reviews_request_status }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="class_.parent_class"
        class="flex w-full flex-col pt-12 lg:w-1/3 lg:pl-8 lg:pt-0"
        @click="$inertia.visit(route('classes.show', class_.parent_class.id))"
      >
        <div class="rounded bg-white shadow">
          <div class="block px-4 py-6">
            <div class="font-bold">
              {{ $t('Parent: :classroom_name', { classroom_name: class_.parent_class.instructor_led_classroom.name }) }}
            </div>
            <div class="mt-4 leading-tight">
              <div class="inline-block text-gray-600">{{ class_.parent_class.dates }}</div>
            </div>
            <div class="mt-4">{{ $t('Capacity: :capacity', { capacity: class_.parent_class.capacity }) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex gap-8">
      <div class="md:w-1/2">
        <div class="mt-12 flex items-end justify-between">
          <h2 class="text-lg">{{ $t('Sessions') }}</h2>
          <InertiaLink v-if="class_.can.createSession" class="btn-blue" :href="route('sessions.create', class_.id)">
            <span>{{ $t('Create') }}</span>
            <span class="hidden md:inline">{{ $t('Session') }}</span>
          </InertiaLink>
        </div>
        <table class="mt-4 w-full overflow-x-auto rounded bg-white shadow">
          <thead>
            <tr>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Date') }}</th>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Time') }}</th>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Instructor') }}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr v-if="class_.sessions.length === 0">
              <td class="border-t px-6 py-4" colspan="4">{{ $t('No sessions found.') }}</td>
            </tr>
            <tr
              v-for="session in class_.sessions"
              :key="session.id"
              class="cursor-pointer hover:bg-gray-100"
              @click="$inertia.visit(route('sessions.edit', session.id))"
            >
              <td class="whitespace-nowrap border-t px-6 py-4">{{ session.date }}</td>
              <td class="whitespace-nowrap border-t px-6 py-4">{{ session.time }}</td>
              <td class="whitespace-nowrap border-t px-6 py-4">{{ session.instructors }}</td>
              <td class="w-min border-t px-4 align-middle">
                <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="class_.private" class="w-1/2">
        <div class="md:w-1/2">
          <div class="mt-12 flex items-end justify-between">
            <h2 class="text-lg">{{ $t('Coordinators') }}</h2>
            <button class="btn-blue" @click="openAddCoordinatorsModal">
              <span>{{ $t('Add') }}</span>
              <span class="hidden md:inline">{{ $t('Coordinators') }}</span>
            </button>
          </div>
          <table class="mt-4 w-full overflow-x-auto rounded bg-white shadow">
            <thead>
              <tr>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Users') }}</th>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold" />
              </tr>
            </thead>
            <tbody>
              <tr v-if="class_.coordinators.length === 0">
                <td class="whitespace-nowrap border-t px-6 py-4" colspan="4">{{ $t('No coordinators') }}</td>
              </tr>
              <tr v-for="coordinator in class_.coordinators" :key="coordinator.id">
                <td class="flex flex-col whitespace-nowrap border-t px-6 py-4">
                  <span>{{ coordinator.name }}</span>
                  <span class="italics text-sm">{{ coordinator.email }}</span>
                </td>
                <td class="w-min border-t px-4 align-middle">
                  <button @click="removeCoordinator(coordinator)">
                    <icon class="block h-4 w-4 fill-gray-400" name="close" />
                  </button>
                </td>
              </tr>
              <tr v-if="class_.sessions.length === 0">
                <td class="border-t px-6 py-4" colspan="4">{{ $t('No sessions found.') }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="mt-12 flex items-end justify-between">
      <div class="flex items-center">
        <h2 class="text-lg">{{ $t('Students') }}</h2>
        <select v-model="deleted" class="form-select ml-4 mt-1 w-full pr-10">
          <option :value="null" />
          <option value="with">{{ $t('With Deleted') }}</option>
          <option value="only">{{ $t('Only Deleted') }}</option>
        </select>
      </div>
      <div class="justify-content-end flex">
        <button
          v-if="class_.can.createStudent"
          class="btn-blue"
          :disabled="class_.available_seats <= 0"
          type="button"
          @click="link"
        >
          <span>{{ $t('Add') }}</span>
          <span class="hidden md:inline">{{ $t('Student') }}</span>
        </button>
        <dropdown
          v-if="class_.can.submitStudentsToMinistryOfLabour || class_.can.updateScores"
          class="btn-blue ml-2"
          placement="bottom-end"
        >
          <div class="flex cursor-pointer select-none items-center">
            <div>
              <span>{{ $t('Actions') }}</span>
            </div>
            <icon class="-my-1 h-5 w-5 fill-white" name="chevron-down" />
          </div>
          <template #dropdown="{ toggle: toggleDropdown }">
            <div class="mt-2 rounded border bg-white py-2 text-sm shadow-lg">
              <a
                v-if="class_.can.updateScores"
                class="block w-full px-4 py-2 text-left hover:bg-gray-200 hover:text-gray-700"
                :disabled="class_.students.length === 0"
                :href="route('offline-test-scores.edit', class_.id)"
              >
                {{ $t('Update Scores') }}
              </a>
              <button
                v-if="class_.can.submitStudentsToMinistryOfLabour"
                class="block w-full px-4 py-2 text-left hover:bg-gray-200 hover:text-gray-700"
                type="button"
                @click="(toggleDropdown(), (showSendToMinistryOfLabourModal = true))"
              >
                {{ $t('Send to Ministry of Labour') }}
              </button>
            </div>
          </template>
        </dropdown>
      </div>
    </div>
    <div class="mt-4 overflow-x-auto rounded bg-white shadow">
      <table class="w-full">
        <thead>
          <tr>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Customer') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Student') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Payment') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Check In') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Check-in Code') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Attendance') }}</th>
            <th v-if="class_.can.updateScores" class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">
              {{ $t('Score') }}
            </th>
            <th v-else class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Result') }}</th>
            <th
              v-if="class_.can.submitStudentsToMinistryOfLabour"
              class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold"
            >
              {{ $t('Ministry of Labour') }}
            </th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold" colspan="2">{{ $t('Certificate #') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="class_.students.length === 0">
            <td class="border-t px-6 py-4" :colspan="10">
              {{ $t('No students found.') }}
            </td>
          </tr>
          <tr
            v-for="student in class_.students"
            :key="student.id"
            class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
          >
            <td class="flex items-center whitespace-nowrap border-t px-6 py-4" @click="clickToStudent(student)">
              <div v-if="student.customer">
                <div>{{ student.customer.display_name }}</div>
                <div v-if="student.customer.organization" class="mt-1 text-sm text-gray-600">
                  {{ student.customer.organization.name }}
                </div>
              </div>
              <div v-else class="text-gray-400">{{ $t('Reserved') }}</div>
              <div v-if="student.reselling" class="ml-2 rounded bg-gray-400 px-2 py-1 text-sm text-white">
                {{ student.tenant.name }}
              </div>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4" @click="clickToStudent(student)">
              <div class="flex items-center">
                <template v-if="student.user">
                  <span v-if="student.user.display_name">{{ student.user.display_name }}</span>
                </template>
                <template v-else-if="student.customer && student.customer.organization && student.enrolment.id">
                  <user-search-input
                    v-model="form.students[student.id].user"
                    label=""
                    :organization-id="student.customer.organization.id"
                    @click.stop="true"
                  />
                  <button class="btn-blue ml-2 px-2" @click.stop="assignEnrolment(student)">
                    <icon class="inline w-5 fill-white p-1" name="checkmark" />
                  </button>
                </template>
                <template v-else>
                  <span class="text-gray-600">
                    <span class="text-gray-400">{{ $t('Reserved') }}</span>
                    <span class="text-gray-600 hover:underline" @click.stop="selfAssign(student)">
                      {{ $t('(Self-Assign)') }}
                    </span>
                  </span>
                </template>
                <deleted-badge v-if="student.deleted_at" />
              </div>
              <div class="mt-1 text-sm text-gray-600">
                {{ student.course.name }}
              </div>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4" @click="clickToStudent(student)">
              <template v-if="student.enrolment">
                <span
                  v-if="student.enrolment.payment_status === 'Paid'"
                  class="ml-1 rounded bg-green-400 px-3 py-1 text-xs text-white"
                >
                  {{ $t('Paid') }}
                </span>
                <span
                  v-else-if="student.enrolment.payment_status === 'Pending'"
                  class="ml-1 rounded bg-ts-gray-400 px-3 py-1 text-xs text-white"
                >
                  {{ $t('Pending') }}
                </span>
                <span v-else class="ml-1 rounded px-3 py-1 text-xs text-white">
                  {{ student.enrolment.payment_status }}
                </span>
                <span
                  v-if="student.enrolment.payment_order_number"
                  class="ml-1 rounded-full bg-gray-400 px-3 py-1 text-xs text-white"
                >
                  #{{ student.enrolment.payment_order_number }}
                </span>
              </template>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <div v-if="student.checked_in_at" class="h-6 w-6 rounded-full bg-green-500 p-1">
                <icon class="block h-full w-full fill-current text-white" name="checkmark" />
              </div>
              <template v-else>
                <button class="rounded border-2 px-4 py-2 tracking-wide" @click="openSendCheckinInviteModal(student)">
                  {{ $t('Send Invite') }}
                </button>
              </template>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4" @click="clickToStudent(student)">
              <div>{{ student.enrolment.check_in_code }}</div>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4" @click="clickToStudent(student)">
              <span v-if="student.present !== null">{{ student.present ? $t('Present') : $t('Absent') }}</span>
              <span v-else class="text-sm text-gray-400">-</span>
            </td>
            <td
              v-if="class_.can.updateScores"
              class="whitespace-nowrap border-t px-6 py-4"
              @click="clickToStudent(student)"
            >
              <span v-if="student.latest_score && student.latest_score.score">
                <span>{{ student.latest_score.score }}/{{ student.latest_score.total_score }}</span>
                <span v-if="student.passed" class="ml-1 rounded-full bg-green-500 px-3 py-1 text-xs text-white">
                  {{ $t('Passed') }}
                </span>
                <span v-if="student.passed === false" class="ml-1 rounded-full bg-red-500 px-3 py-1 text-xs text-white">
                  {{ $t('Failed') }}
                </span>
              </span>
              <span v-else class="text-sm text-gray-400">-</span>
            </td>
            <td v-else class="whitespace-nowrap border-t px-6 py-4" @click="clickToStudent(student)">
              <span v-if="student.passed" class="ml-1 rounded-full bg-green-500 px-3 py-1 text-white">
                {{ $t('Passed') }}
              </span>
              <span v-if="student.passed === false" class="ml-1 rounded-full bg-red-500 px-3 py-1 text-white">
                {{ $t('Failed') }}
              </span>
              <span v-else class="text-sm text-gray-400">-</span>
            </td>
            <td
              v-if="class_.can.submitStudentsToMinistryOfLabour"
              class="whitespace-nowrap border-t px-6 py-4"
              @click="clickToStudent(student)"
            >
              {{ student.submitted_to_ministry_of_labour ? 'Yes' : 'No' }}
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4" @click="clickToStudent(student)">
              <span v-if="student.certificate_number">{{ student.certificate_number }}</span>
              <span v-else class="text-sm text-gray-400">-</span>
            </td>
            <td class="w-min border-t px-4 align-middle" @click="clickToStudent(student)">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="class_.subclasses.length > 0" class="mb-8 mt-12 flex items-end justify-between">
      <div class="flex items-center">
        <h2 class="text-lg">{{ $t('Subclasses') }}</h2>
      </div>
    </div>
    <div
      v-if="class_.subclasses.length > 0"
      class="mt-4 grid grid-cols-1 gap-4 overflow-x-auto sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
    >
      <a
        v-for="subclass in class_.subclasses"
        :key="subclass.id"
        class="flex cursor-pointer flex-col rounded bg-white p-6 shadow hover:bg-gray-100 hover:shadow-lg focus:bg-gray-100"
        :href="route('classes.show', subclass.id)"
      >
        <span class="text-lg">{{ subclass.instructor_led_classroom.name }}</span>
        <span class="mt-2">{{ subclass.dates }}</span>
        <span class="mt-2">{{ subclass.time_range }}</span>
        <span class="mt-2">{{ $t('Capacity: :capacity', { capacity: subclass.capacity }) }}</span>
      </a>
    </div>
    <styled-modal
      class="overflow-auto"
      max-width="lg"
      :show="showAddCoordinatorsModal"
      @close="closeAddCoordinatorsModal"
    >
      <template #title>{{ $t('Add Coordinators') }}</template>

      <template #content>
        <div class="mt-6 flex flex-col space-y-4">
          <div>
            <form>
              <user-search-input v-model="coordinatorForm.user" label="" @click.stop="true" />
            </form>
          </div>
        </div>
      </template>

      <template #footer>
        <div class="flex w-full justify-end">
          <button class="text-gray-500" @click.prevent="closeAddCoordinatorsModal">{{ $t('Cancel') }}</button>
          <loading-button
            class="btn-blue ml-4"
            :disabled="!coordinatorForm.user"
            :loading="coordinatorForm.processing"
            @click.prevent="addCoordinator"
          >
            <span>{{ $t('Add') }}</span>
          </loading-button>
        </div>
      </template>
    </styled-modal>
    <confirm-request-review
      :class_="class_"
      :show="showConfirmRequestModal"
      @close="showConfirmRequestModal = false"
      @request-sent="handleReviewRequestSent"
    />
    <class-courses-select
      :courses="class_.courses.filter((c) => c.can.submitToMinistryOfLabour)"
      :show="showSendToMinistryOfLabourModal"
      :title="$t('Send Courses to Ministry of Labour')"
      @close="showSendToMinistryOfLabourModal = false"
      @submit="handleSendCourseStudentsToMinistryOfLabour"
    />
    <send-checkin-invite-modal
      v-if="showSendCheckinInviteModal"
      :show="showSendCheckinInviteModal"
      :student="selectedStudent"
      @close="closeSendCheckinInviteModal"
    />
  </layout>
</template>

<script>
import ClassCoursesSelect from './ClassCoursesSelect.vue'
import ConfirmRequestReview from './ConfirmRequestReview.vue'
import SendCheckinInviteModal from './SendCheckinInviteModal.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import ClassMinistryOfLabourStatusBadge from '@/Shared/ClassMinistryOfLabourStatusBadge.vue'
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import DeletedMessage from '@/Shared/DeletedMessage.vue'
import Dropdown from '@/Shared/Dropdown.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import StyledModal from '@/Shared/StyledModal.vue'
import UserSearchInput from '@/Shared/UserSearchInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    ConfirmRequestReview,
    ClassCoursesSelect,
    StyledModal,
    UserSearchInput,
    LoadingButton,
    SendCheckinInviteModal,
    ClassMinistryOfLabourStatusBadge,
    Breadcrumb,
    DeletedBadge,
    DeletedMessage,
    Dropdown,
    Icon,
    Layout,
  },
  props: {
    filters: Object,
    class_: Object,
    courses: Array,
  },
  data() {
    var student_list = {}
    this.class_.students.forEach((student) => {
      student_list[student['id']] = {
        id: student['id'],
        user: null,
      }
    })
    return {
      deleted: this.filters.deleted,
      showAddCoordinatorsModal: false,
      showConfirmRequestModal: false,
      showSendToMinistryOfLabourModal: false,
      showSendCheckinInviteModal: false,
      form: useForm({
        students: student_list,
      }),
      coordinatorForm: useForm({
        user: null,
      }),
    }
  },
  watch: {
    deleted() {
      this.$inertia.get(
        this.route('classes.show', this.class_.id),
        { deleted: this.deleted },
        { preserveState: true, replace: true }
      )
    },
  },
  methods: {
    handleReviewRequestSent() {
      this.showConfirmRequestModal = false
      this.$inertia.get(this.route('classes.show', this.class_.id), {}, { preserveState: true, replace: true })
    },
    handleSendCourseStudentsToMinistryOfLabour(courseIds) {
      this.$inertia.post(this.route('classes.skillspass.store', this.class_), {
        course_ids: courseIds,
      })

      this.showSendToMinistryOfLabourModal = false
    },
    openAddCoordinatorsModal() {
      this.showAddCoordinatorsModal = true
    },
    closeAddCoordinatorsModal() {
      this.showAddCoordinatorsModal = false
    },
    addCoordinator() {
      this.coordinatorForm
        .transform((data) => ({
          user: data.user?.id,
        }))
        .post(this.route('classes.coordinator.create', this.class_.id), {
          onFinish: () => this.closeAddCoordinatorsModal(),
        })
    },
    removeCoordinator(user) {
      this.$inertia.delete(
        this.route('classes.coordinator.destroy', {
          class: this.class_.id,
          user: user.id,
        })
      )
    },
    link() {
      this.$inertia.visit(this.route('enrolments.create', { class: this.class_.id }))
    },
    cancel() {
      if (confirm(this.$t('Are you sure you want to cancel this class?\nSessions will be deleted, too.'))) {
        this.$inertia.delete(this.route('classes.cancel', this.class_.id))
      }
    },
    destroy() {
      if (
        confirm(this.$t('Are you sure you want to delete this class?\nSessions and Students will be deleted, too.'))
      ) {
        this.$inertia.delete(this.route('classes.destroy', this.class_.id))
      }
    },
    restore() {
      if (confirm(this.$t('Are you sure you want to restore this class?'))) {
        this.$inertia.put(this.route('classes.restore', this.class_.id))
      }
    },
    selfAssign(student) {
      if (confirm(this.$t('Are you sure you want to self-assign this student?'))) {
        this.$inertia.put(this.route('enrolments.self-assign', student.enrolment.id))
      }
    },
    assignEnrolment(student) {
      if (confirm(this.$t('Are you sure you want to assign this student?'))) {
        this.form
          .transform((data) => {
            return {
              user_id: data.students[student.id].user.id,
            }
          })
          .post(this.route('enrolments.enrol', student.enrolment.id))
      }
    },
    openSendCheckinInviteModal(student) {
      this.showSendCheckinInviteModal = true
      this.selectedStudent = student
    },
    closeSendCheckinInviteModal() {
      this.showSendCheckinInviteModal = false
      this.selectedStudent = null
    },
    clickToStudent(student) {
      return !!student.enrolment && !!student.enrolment.id
        ? this.$inertia.visit(this.route('enrolments.edit', student.enrolment.id))
        : this.$inertia.visit(this.route('students.edit', student.id))
    },
  },
}
</script>
