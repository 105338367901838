<template>
  <front-layout :title="$t('Notifications')">
    <div class="mb-8">
      <breadcrumb :name="$t('Notifications')" />
    </div>
    <div class="mb-4 flex">
      <button
        v-if="!selectEnabled && notifications.data.length !== 0"
        class="mr-8 text-sm font-semibold uppercase text-ts-gray-text-bold opacity-100 hover:underline hover:opacity-50 md:hidden md:opacity-30"
        @click="selectEnabled = true"
      >
        {{ $t('Select') }}
      </button>
      <button
        class="mr-8 text-sm font-semibold uppercase text-ts-gray-text-bold opacity-100 hover:underline hover:opacity-50 md:inline md:opacity-30"
        :class="{ hidden: !selectEnabled }"
        :disabled="selectedNotifications.length === 0"
        @click="deleteNotifications()"
      >
        {{ $t('Delete') }}
      </button>
      <button
        class="mr-8 text-sm font-semibold uppercase text-ts-gray-text-bold opacity-100 hover:underline hover:opacity-50 md:inline md:opacity-30"
        :class="{ hidden: !selectEnabled }"
        :disabled="selectedNotifications.length === 0"
        @click="markAsRead()"
      >
        {{ $t('Mark As Read') }}
      </button>
      <button
        class="text-sm font-semibold uppercase text-ts-gray-text-bold opacity-100 hover:underline hover:opacity-50 md:inline md:opacity-30"
        :class="{ hidden: !selectEnabled }"
        :disabled="selectedNotifications.length === 0"
        @click="markAsUnread()"
      >
        {{ $t('Mark As Unread') }}
      </button>
    </div>
    <div class="-mx-4 overflow-x-auto rounded bg-white shadow-lg md:mx-0">
      <table class="front-table">
        <thead class="hidden md:table-header-group">
          <tr>
            <th
              class="md:table-cell"
              :class="{ hidden: !selectEnabled, 'md:hidden': notifications.data.length === 0 }"
            />
            <th class="font-semibold">{{ $t('Notification') }}</th>
            <th>{{ $t('Date') }}</th>
            <th>{{ $t('Actions') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="notifications.data.length === 0">
            <td :colspan="selectEnabled ? 4 : 3">{{ $t('No notifications found.') }}</td>
          </tr>
          <tr
            v-for="notification in notifications.data"
            :key="notification.id"
            @click="redirectToMessage(notification)"
          >
            <td class="flex items-center md:table-cell" :class="{ hidden: !selectEnabled }" @click.stop>
              <front-row-select-checkbox
                :id="'checkbox-' + notification.id"
                v-model="selectedNotifications"
                :value="notification.id"
              />
            </td>
            <td class="text-ts-gray-text-bold" :class="{ 'font-bold': !notification.is_read }">
              {{ notification.name }}
            </td>
            <td><front-date format="MMM DD, YYYY h:mm A" :timestamp="notification.created_at" /></td>
            <td>
              <InertiaLink
                class="font-semibold hover:underline"
                :href="route('front.notifications.show', notification.id)"
              >
                {{ $t('View') }}
              </InertiaLink>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <front-pagination :links="notifications.links" />
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontDate from '@/Shared/FrontDate.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontPagination from '@/Shared/FrontPagination.vue'
import FrontRowSelectCheckbox from '@/Shared/FrontRowSelectCheckbox.vue'

export default {
  components: {
    Breadcrumb,
    FrontDate,
    FrontLayout,
    FrontPagination,
    FrontRowSelectCheckbox,
  },
  props: {
    notifications: Object,
  },
  data() {
    return {
      selectEnabled: false,
      selectedNotifications: [],
    }
  },
  methods: {
    resetData() {
      this.selectEnabled = false
      this.selectedNotifications = []
    },
    deleteNotifications() {
      if (confirm(this.$t('Are you sure you want to delete the selected notifications?'))) {
        this.$inertia.post(this.route('front.notifications.destroy-multiple'), {
          notification_ids: this.selectedNotifications,
        })
        this.resetData()
      }
    },
    markAsRead() {
      if (confirm(this.$t('Are you sure you want to mark the selected notifications as read?'))) {
        this.$inertia.put(this.route('front.notifications.update-read-status'), {
          mark_as: true,
          notification_ids: this.selectedNotifications,
        })
        this.resetData()
      }
    },
    markAsUnread() {
      if (confirm(this.$t('Are you sure you want to mark the selected notifications as unread?'))) {
        this.$inertia.put(this.route('front.notifications.update-read-status'), {
          mark_as: false,
          notification_ids: this.selectedNotifications,
        })
        this.resetData()
      }
    },
    redirectToMessage(notification) {
      this.$inertia.visit(this.route('front.notifications.show', notification.id))
    },
  },
}
</script>
