<template>
  <layout :title="course.name">
    <div class="mb-8">
      <breadcrumb :name="course.name" :previous-name="$t('Courses')" :previous-url="route('courses')" />
    </div>
    <div class="flex max-w-3xl flex-wrap">
      <div class="w-full lg:w-3/5 xl:w-2/3">
        <div class="rounded bg-white p-8 shadow">
          <div v-if="course.description" class="leading-tight">
            {{ course.description }}
          </div>
          <div v-if="course.capacity" class="mt-8 flex">
            <div class="mr-1 font-medium">{{ $t('Capacity:') }}</div>
            <div>{{ course.capacity }}</div>
          </div>
          <div v-if="course.ministry_of_labour_code" class="mt-5 flex">
            <div class="mr-1 font-medium">{{ $t('Ministry of labour code:') }}</div>
            <div>{{ course.ministry_of_labour_code }}</div>
          </div>
          <div v-if="course.price" class="mt-5 flex">
            <div class="mr-1 font-medium">{{ $t('Price:') }}</div>
            <div>${{ course.price }}</div>
          </div>
          <div v-if="course.reselling?.seats_percentage" class="mt-5 flex">
            <div class="mr-1 font-medium">{{ $t('Reselling seats available:') }}</div>
            <div>
              {{ $t(':seats_percentage% of capacity', { seats_percentage: course.reselling?.seats_percentage }) }}
            </div>
          </div>
          <div v-if="course.reselling?.seats_quantity" class="mt-5 flex">
            <div class="mr-1 font-medium">{{ $t('Reselling seats available:') }}</div>
            <div>{{ $t(':seats_quantity seats', { seats_quantity: course.reselling?.seats_quantity }) }}</div>
          </div>
          <div v-if="course.reselling?.commission_amount" class="mt-5 flex">
            <div class="mr-1 font-medium">{{ $t('Reselling commission:') }}</div>
            <div>
              {{ $t(':commission_rate%', { commission_rate: course.reselling?.commission_rate }) }}
              <span class="text-gray-600">${{ course.reselling?.commission_amount }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex w-full flex-col pt-12 lg:w-2/5 lg:pl-8 lg:pt-0 xl:w-1/3">
        <div class="rounded bg-white shadow">
          <div class="block px-4 py-6">
            <div class="font-bold">{{ course.tenant.name }}</div>
          </div>
          <div v-if="course.tenant.owners.length" class="bg-gray-300 p-4 text-sm font-bold text-gray-700">
            {{ $t('Owners') }}
          </div>
          <div
            v-for="(owner, index) in course.tenant.owners"
            :key="owner.id"
            class="block px-4 py-4"
            :class="{ 'border-t': index }"
          >
            <div>{{ owner.first_name }} {{ owner.last_name }}</div>
            <div v-if="owner.email" class="mt-2 text-gray-600">{{ owner.email }}</div>
            <div v-if="owner.phone" class="mt-1 text-gray-600">{{ owner.phone }}</div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import Layout from '@/Shared/Layout.vue'

export default {
  components: {
    Breadcrumb,
    Layout,
  },
  props: {
    course: Object,
  },
}
</script>
