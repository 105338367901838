<template>
  <layout :title="$t('Edit File')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('organizations.edit', organization)">
        {{ $t('Organizations') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Edit File') }}
    </h1>
    <deleted-message v-if="file.deleted_at" class="mb-6">{{ $t('This file has been deleted.') }}</deleted-message>
    <div class="max-w-xl overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" />
          </div>
          <div class="w-full pb-8 pr-8">
            <file-upload-input
              v-model="form.file"
              :error="form.errors.file"
              :initial-value="form.file"
              label="Upload File"
              :max-items="1"
              ref-name="createFolderFileUploadInput"
              :validation-rules="{
                maxSizeInKB: 1024 * 100,
              }"
            />
          </div>
        </div>
        <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
          <div>
            <button
              v-if="file.deleted_at"
              class="text-red-500 hover:underline"
              tabindex="-1"
              type="button"
              @click="restore"
            >
              {{ $t('Restore') }}
            </button>
            <button v-else class="text-red-500 hover:underline" tabindex="-1" type="button" @click="destroy">
              {{ $t('Delete') }}
            </button>
          </div>
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Update File') }}
          </loading-button>
        </div>
      </form>
    </div>
    <!-- Files -->
    <div class="-mr-6 flex max-w-xl items-center justify-between pb-6 pt-8">
      <span class="font-heading text-xl">Versions</span>
    </div>
    <div v-for="(upload, index) in file.uploads" :key="upload.id">
      <div class="mr-3 flex max-w-xl flex-col overflow-hidden bg-white p-6 shadow">
        <div class="flex items-center">
          <div class="flex w-full flex-col pl-4 text-lg">
            <div class="flex items-center">
              <div>{{ upload.file_name }}</div>
              <div v-if="index == 0" class="ml-2 rounded-full px-2 py-1 text-xs text-qualify-red-500 outline outline-1">
                current
              </div>
            </div>
            <span class="text-sm">Uploaded on {{ upload.created_at }}</span>
          </div>
          <div class="flex items-center justify-end">
            <a class="flex justify-end text-gray-500 hover:text-gray-600" :href="upload.url" target="_blank">
              <icon class="inline h-6 w-6 rounded-full fill-darkGray-1000" name="download-modern" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import DeletedMessage from '@/Shared/DeletedMessage.vue'
import FileUploadInput from '@/Shared/FileUploadInput.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    DeletedMessage,
    FileUploadInput,
    Icon,
    Layout,
    LoadingButton,
    TextInput,
  },
  props: {
    organization: Object,
    file: Object,
    folder: Object,
  },
  data() {
    return {
      showColorPicker: false,
      form: useForm({
        name: this.file.name,
        file: this.file.file,
      }),
    }
  },
  methods: {
    submit() {
      this.form.put(this.route('organizations.folders.files.update', [this.organization, this.folder, this.file]))
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this file?'))) {
        this.$inertia.delete(
          this.route('organizations.folders.files.destroy', [this.organization, this.folder, this.file])
        )
      }
    },
    restore() {
      if (confirm(this.$t('Are you sure you want to restore this file?'))) {
        this.$inertia.put(
          this.route('organizations.folders.files.restore', [this.organization, this.folder, this.file])
        )
      }
    },
  },
}
</script>
