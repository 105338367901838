<template>
  <div class="mt-8 overflow-x-auto rounded bg-white px-6 py-6 shadow">
    <div v-if="locations.data.length === 0">{{ $t('No Result.') }}</div>
    <div v-else class="my-2">
      <table class="w-full">
        <thead>
          <tr>
            <th class="whitespace-nowrap px-6 py-4 text-left font-medium">{{ $t('Location') }}</th>
            <th class="whitespace-nowrap px-6 py-4 text-left font-medium">{{ $t('# of Classes') }}</th>
            <th class="whitespace-nowrap px-6 py-4 text-left font-medium">{{ $t('Average Enrolment Per Class') }}</th>
            <th class="whitespace-nowrap px-6 py-4 text-left font-medium">{{ $t('Highest') }}</th>
            <th class="whitespace-nowrap px-6 py-4 text-left font-medium">{{ $t('Lowest') }}</th>
            <th class="whitespace-nowrap px-6 py-4 text-left font-medium">{{ $t('Classes Below Threshold') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(classes, location) in locations.data" :key="location" class="odd:bg-gray-100">
            <td class="border-t px-6 py-4">{{ location }}</td>
            <td class="border-t px-6 py-4 text-center">{{ classes.length }}</td>
            <td class="border-t px-6 py-4 text-center">{{ averageEnrolmentPerClass(classes) }}</td>
            <td class="border-t px-6 py-4 text-center">{{ highest(classes) }}</td>
            <td class="border-t px-6 py-4 text-center">{{ lowest(classes) }}</td>
            <td class="border-t px-6 py-4 text-center">{{ classesBelowThreshold(classes) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    locations: Object,
    threshold: String,
  },
  methods: {
    averageEnrolmentPerClass(classes) {
      return Math.round(
        classes.reduce((accumulator, class_) => accumulator + class_.students_count, 0) / classes.length
      )
    },
    highest(classes) {
      return Math.max(...classes.map((class_) => class_.students_count))
    },
    lowest(classes) {
      return Math.min(...classes.map((class_) => class_.students_count))
    },
    classesBelowThreshold(classes) {
      return (
        Math.round((classes.filter((class_) => class_.students_count < this.threshold).length / classes.length) * 100) +
        '%'
      )
    },
  },
}
</script>
