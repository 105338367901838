<template>
  <div
    v-if="isShowSubscriptionBanner"
    class="relative mb-10 grid grid-cols-1 gap-6 rounded-xl bg-gradient-to-r from-[#3E484F] to-[#000000] px-16 py-8 text-white"
    :class="{
      'lg:grid-cols-3': banner.column_type === 'three',
      'grid-cols-2': banner.column_type === 'two',
    }"
  >
    <div class="grid grid-rows-1 gap-6">
      <div
        class="text-md w-fit rounded-md bg-gradient-to-r from-ts-orange-900 to-qualify-red-900 px-3 py-2 text-center font-semibold uppercase text-white"
      >
        {{ banner.tag }}
      </div>
      <div class="w-full text-center text-3xl font-semibold lg:w-2/3 lg:text-left">{{ banner.title }}</div>
      <div class="text-md w-full text-center leading-normal lg:w-2/3 lg:text-left">{{ banner.subtitle }}</div>
    </div>
    <div v-if="banner.column_type === 'three'" class="grid content-center gap-3 lg:block">
      <div v-for="(feature, index) in getFeatures" :key="index">
        <span class="inline-flex items-center">
          <CheckIcon aria-hidden="true" class="mr-2 h-6 w-6 stroke-qualify-red-900" />
          {{ feature }}
        </span>
      </div>
    </div>
    <div class="flex place-content-center items-center lg:place-content-end">
      <div class="w-4/5 text-center">
        <div v-if="preview" class="block rounded-full bg-qualify-red-600 px-8 py-5 font-semibold">
          {{ banner.button_text }}
        </div>
        <InertiaLink
          v-else
          class="block rounded-full bg-qualify-red-600 px-8 py-5 font-semibold"
          :href="route(banner.button_route)"
        >
          {{ banner.button_text }}
        </InertiaLink>
        <div class="mt-2 text-sm text-white/80">{{ banner.button_description }}</div>
      </div>
    </div>
    <div v-if="preview" class="absolute right-4 top-4">
      <XMarkIcon aria-hidden="true" class="h-8 w-8 stroke-white" />
    </div>
    <div v-else class="absolute right-4 top-4 cursor-pointer" @click="isShowSubscriptionBanner = false">
      <XMarkIcon aria-hidden="true" class="h-8 w-8 stroke-white" />
    </div>
  </div>
</template>

<script>
import { CheckIcon, XMarkIcon } from '@heroicons/vue/24/outline'

export default {
  components: { CheckIcon, XMarkIcon },
  props: {
    preview: Boolean,
    banner: Object,
  },
  data() {
    return {
      isShowSubscriptionBanner: true,
    }
  },
  computed: {
    getFeatures() {
      return this.banner.features?.split('|')
    },
  },
}
</script>
