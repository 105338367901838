<template>
  <button class="rounded-full text-xs font-semibold" :class="{ 'bg-green-200 px-2 py-1': !!notify }" @click="open">
    <CheckCircleIcon v-if="!!notify" aria-hidden="true" class="mb-px mr-px inline h-4 w-4" />
    <PlusIcon v-else aria-hidden="true" class="mb-1 inline h-3 w-3" />
    <span>Notify</span>
    <notify-action-modal :logic-name="logicName" :notify="notify" :show="isShowModal" @close="close" @save="onSave" />
  </button>
</template>
<script>
import NotifyActionModal from './ActionModals/NotifyActionModal.vue'
import { CheckCircleIcon, PlusIcon } from '@heroicons/vue/20/solid'

export default {
  components: {
    NotifyActionModal,
    CheckCircleIcon,
    PlusIcon,
  },
  props: {
    logicName: String,
    notify: Object,
  },
  emits: ['update:notify'],
  data() {
    return {
      isShowModal: false,
    }
  },
  methods: {
    open() {
      this.isShowModal = true
    },
    close() {
      this.isShowModal = false
    },
    onSave(action) {
      this.$emit('update:notify', action)
      this.close()
    },
  },
}
</script>
