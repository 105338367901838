<template>
  <front-layout :title="$t('Enrolment Transfer')">
    <div class="mb-8">
      <breadcrumb
        :name="$t('Class Selection')"
        :previous-name="$t('Transfer Enrolments')"
        :previous-url="route('front.enrolment-transfers.step1.create')"
      />
    </div>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="flex flex-wrap p-8">
          <div class="w-full space-y-8">
            <div v-for="course in courses" :key="course.id" class="flex flex-col">
              <div>
                <div class="mb-8 flex items-center justify-between">
                  <span class="font-bold">{{ course.name }}</span>
                </div>
                <div class="ml-4">
                  <label v-if="true || form.errors[`courses.${course.id}`]" class="form-error mb-4 block">
                    {{ form.errors[`courses.${course.id}`] }}
                  </label>
                  <div :key="course.id" class="flex w-1/2 flex-col">
                    <div v-for="index in course.quantity" :key="`${course.id}-${index}`" class="pb-8 pr-6">
                      <front-select-input
                        v-model="form.courses[course.id].class[index - 1]"
                        :error="form.errors[`courses.${course.id} .class.${index - 1}`]"
                        :label="$t(`Class ${index}`)"
                      >
                        <option :value="null" />
                        <option v-for="class_ in course.classes" :key="class_.id" :value="class_.id">
                          {{ class_.formatted_name }}
                        </option>
                      </front-select-input>
                      <label
                        v-if="form.errors[`courses.${course.id}.class.${index - 1}`]"
                        class="form-error mb-4 block"
                      >
                        {{ form.errors[`courses.${course.id}.class.${index - 1}`] }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 px-8 py-4">
          <front-loading-button :loading="form.processing" type="submit">
            {{ $t('Transfer Enrolments') }}
          </front-loading-button>
        </div>
      </form>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontSelectInput from '@/Shared/FrontSelectInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    FrontLayout,
    FrontLoadingButton,
    FrontSelectInput,
  },
  props: {
    courses: Array,
  },
  data() {
    var course_list = {}
    this.courses.map((course) => {
      course_list[course.id] = {
        id: course.id,
        class: [...Array(course.quantity)],
      }
    })

    return {
      form: useForm({
        courses: course_list,
      }),
      isSearching: false,
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('front.enrolment-transfers.step2.store'))
    },
    onChange(courseId) {
      this.form.clearErrors(`courses.${courseId}`)
    },
  },
}
</script>
