<template>
  <div class="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-3 xl:grid-cols-3">
    <div v-for="subscription in subscriptions" :key="subscription.id">
      <subscription-card :subscription="subscription" />
    </div>
    <div
      class="grid h-56 w-4/5 cursor-pointer gap-6 rounded-lg bg-gradient-to-t from-[#000000] to-[#3E484F] p-4"
      @click="showAddSubscriptionPlanModal"
    >
      <div class="flex items-start text-white">
        <PlusCircleIcon aria-hidden="true" class="h-6 w-6" />
        <span class="ml-2 text-base font-semibold">{{ $t('Add Plans') }}</span>
      </div>
      <div class="text-sm leading-normal text-white">
        {{ $t('Manage access to subscription tools, such as Safety Management or Online Training.') }}
      </div>
      <div class="text-center">
        <InertiaLink class="btn-red-gradient" :href="route('front.admin.subscription.plans')">
          {{ $t('Add Subscription Plan(s)') }}
        </InertiaLink>
      </div>
    </div>
  </div>
</template>
<script>
import SubscriptionCard from './SubscriptionCard.vue'
import { PlusCircleIcon } from '@heroicons/vue/24/outline'
import { useForm } from '@inertiajs/vue3'

export default {
  components: { SubscriptionCard, PlusCircleIcon },
  props: {
    subscriptions: Array,
    plans: Array,
  },
  data() {
    return {
      isAddPaymentMethodModal: false,
      form: useForm({
        paymentMethodId: this.paymentMethod?.id,
      }),
    }
  },
  methods: {
    showAddPaymentMethodModal() {
      this.isAddPaymentMethodModal = true
    },
    hideAddPaymentMethodModal() {
      this.isAddPaymentMethodModal = false
    },
    submit() {
      this.form.post(this.route('front.admin.payment-method.store'))
    },
  },
}
</script>
