<template>
  <div v-if="timestamp">
    <time :datetime="timestamp">{{ date }}</time>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    timestamp: String,
    correctToLocal: {
      type: Boolean,
      default: true,
    },
    format: {
      type: String,
      default: 'MMM DD, YYYY',
    },
  },
  computed: {
    date: function () {
      if (!this.timestamp) return null
      if (this.correctToLocal) return moment.utc(this.timestamp).local().format(this.format)

      return moment.utc(this.timestamp).format(this.format)
    },
  },
}
</script>
