import '../css/app.css'
import './bootstrap.js'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import { Link, createInertiaApp } from '@inertiajs/vue3'
import { i18nVue } from 'laravel-vue-i18n'
import { createApp, h } from 'vue'
import { ZiggyVue } from 'ziggy-js'

window.global ||= window

createInertiaApp({
  resolve: (name) => {
    const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
    return pages[`./Pages/${name}.vue`]
  },
  progress: {
    // The delay after which the progress bar will appear
    // during navigation, in milliseconds.
    delay: 250,
    // The color of the progress bar.
    color: '#29d',
    // Whether to include the default NProgress styles.
    includeCSS: true,
    // Whether the NProgress spinner will be shown.
    showSpinner: false,
  },
  setup: async ({ el, App, props, plugin }) => {
    const vueApp = createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(ZiggyVue)
      .use(i18nVue, {
        resolve: async (lang) => {
          console.log(lang)
          const langs = import.meta.glob('../lang/*.json')
          return await langs[`../lang/${lang}.json`]()
        },
      })
      .component('InertiaLink', Link)

    if (import.meta.env.VITE_BUGSNAG_API_KEY) {
      Bugsnag.start({
        apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
        plugins: [new BugsnagPluginVue()],
      })

      vueApp.use(Bugsnag.getPlugin('vue'))
    }

    vueApp.config.globalProperties.$filters = {
      translate(word) {
        return Lang.get(word)
      },
    }

    vueApp.mount(el)
  },
})
