<template>
  <layout :title="$t('Subscription Reports')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('reports')">
        {{ $t('Reports') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Subscription Reports') }}
    </h1>
    <div class="overflow-x-auto rounded bg-white shadow">
      <table class="w-full">
        <thead>
          <tr>
            <th class="cursor-pointer whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold" @click="sortBy('name')">
              {{ $t('Organization') }}
              <sort-column-icon :is-active="sortForm.sortBy === 'name'" :is-ascending="sortForm.isAscending" />
            </th>
            <th
              class="cursor-pointer whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold"
              @click="sortBy('created_at')"
            >
              {{ $t('Start Date') }}
              <sort-column-icon :is-active="sortForm.sortBy === 'created_at'" :is-ascending="sortForm.isAscending" />
            </th>
            <th
              class="flex cursor-pointer flex-row whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold"
              @click="sortBy('total_running_revenue')"
            >
              <div class="flex flex-col">
                <span>{{ $t('Total Revenue') }}</span>
                <small class="text-xs font-normal text-gray-500">(Includes Pending Invoice)</small>
              </div>
              <sort-column-icon
                :is-active="sortForm.sortBy === 'total_running_revenue'"
                :is-ascending="sortForm.isAscending"
              />
            </th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Monthly Revenue') }}</th>
            <th class="cursor-pointer whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold" @click="sortBy('quantity')">
              {{ $t('Seats') }}
              <sort-column-icon :is-active="sortForm.sortBy === 'quantity'" :is-ascending="sortForm.isAscending" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="subscriptions.data.length === 0">
            <td class="border-t px-6 py-4" colspan="3">{{ $t('No locations found.') }}</td>
          </tr>
          <tr
            v-for="subscription in subscriptions.data"
            :key="subscription.id"
            class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
            @click="$inertia.visit(route('organizations.subscriptions.index', subscription.id))"
          >
            <td class="whitespace-nowrap border-t px-6 py-4">
              {{ subscription.organization.name }}
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <div class="flex items-center">
                <show-date :timestamp="subscription.started_at" />
              </div>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <div class="flex items-center">
                <show-price :value="subscription.total_running_revenue" />
              </div>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <show-price :value="subscription.upcoming_invoice_amount" />
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              {{ subscription.number_of_seats }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination :links="subscriptions.links" />
  </layout>
</template>

<script>
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import ShowDate from '@/Shared/ShowDate.vue'
import ShowPrice from '@/Shared/ShowPrice.vue'
import SortColumnIcon from '@/Shared/SortColumnIcon.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Layout,
    Pagination,
    ShowDate,
    ShowPrice,
    SortColumnIcon,
  },
  props: {
    subscriptions: Object,
    sort: Object,
  },
  data() {
    return {
      sortForm: useForm({
        sortBy: this.sort.sortBy,
        isAscending: this.sort.isAscending,
      }),
    }
  },
  methods: {
    sortBy(column) {
      if (this.sortForm.sortBy === column) {
        this.sortForm.isAscending = !this.sortForm.isAscending
      } else {
        this.sortForm.sortBy = column
        this.sortForm.isAscending = false
      }
      this.$inertia.reload({
        replace: true,
        preserveState: true,
        preserveScroll: true,
        data: {
          sortBy: this.sortForm.sortBy,
          isAscending: this.sortForm.isAscending,
        },
      })
    },
  },
}
</script>
