<template>
  <div class="justify-left flex items-center space-x-1" :class="{ shakes: timeIsOver }">
    <span>
      <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </svg>
    </span>
    <span v-if="!timeIsOver">
      <span>{{ displayTime }}</span>
    </span>
    <span v-else>{{ $t('Time is up') }}!</span>
  </div>
</template>

<script>
import moment from 'moment/moment'

export default {
  name: 'QuizClock',
  props: {
    quiz: Object,
    enrolmentContent: Object,
    createdAt: Date,
    expiresAt: Date,
    timeLimitMinutes: Number,
  },
  data() {
    return {
      currentTime: new Date(),
    }
  },
  computed: {
    timeIsOver() {
      return this.expiresAt.getTime() <= this.currentTime.getTime()
    },
    timeLeft() {
      return this.expiresAt.getTime() - this.currentTime.getTime()
    },
    displayTime() {
      const duration = moment.duration(this.timeLeft)
      const hours = duration.hours()
      const minutes = duration.minutes()
      const seconds = duration.seconds()
      return (hours > 0 ? `${this.leftPad(hours)}:` : '') + `${this.leftPad(minutes)}:${this.leftPad(seconds)}`
    },
  },
  watch: {
    timeIsOver(isOver) {
      if (isOver) {
        this.handleQuizTimeout()
      }
    },
  },
  mounted() {
    if (!this.timeIsOver) {
      this.$options.clock = setInterval(() => {
        this.currentTime = new Date()
      }, 1000)
    } else {
      this.redirectBackToQuiz()
    }
  },
  beforeUnmount() {
    this.stopTimer()
  },
  methods: {
    leftPad(number, digit = 2) {
      return number.toString().padStart(digit, '0')
    },
    stopTimer() {
      if (this.$options.clock) {
        clearInterval(this.$options.clock)
        this.$options.clock = null
      }
    },
    redirectBackToQuiz() {
      this.$inertia.visit(
        this.route('front.quizzes', {
          enrolmentContent: this.enrolmentContent,
          quiz: this.quiz,
          'expired-attempt': 1,
        })
      )
    },
    handleQuizTimeout() {
      this.stopTimer()
      // Only redirect after 2 seconds, so students can see the shaking clock.
      setTimeout(() => {
        this.redirectBackToQuiz()
      }, 2000)
    },
  },
  clock: null,
}
</script>
