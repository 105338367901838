<template>
  <div class="form-group">
    <label>
      <span class="form-label">{{ label }}</span>
      <div class="form-input flex items-center justify-between">
        <flat-pickr
          :config="configData"
          :model-value="modelValue"
          name="date"
          placeholder="Select date"
          @change="$emit('update:modelValue', $event.target.value)"
        />
        <div class="input-group-append">
          <button
            v-if="modelValue"
            class="btn btn-default"
            title="Clear"
            type="button"
            @click="$emit('update:modelValue', null)"
          >
            <XMarkIcon class="h-4 w-4" />
            <span aria-hidden="true" class="sr-only">Clear</span>
          </button>
        </div>
      </div>
    </label>
    <div v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import { XMarkIcon } from '@heroicons/vue/24/outline'
import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/themes/material_blue.css'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: { XMarkIcon, flatPickr },
  props: {
    label: String,
    modelValue: [String, Object],
    error: String,
    config: Object,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      configData: {
        enableTime: true,
        wrap: true,
        altFormat: 'M j, Y h:i K',
        altInput: true,
        dateFormat: 'Y-m-d h:i K',
        ...this.config,
      },
    }
  },
}
</script>
