<template>
  <layout :title="$t('Create User')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('users')">{{ $t('Users') }}</InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Create') }}
    </h1>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.first_name" :error="form.errors.first_name" :label="$t('First name')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.last_name" :error="form.errors.last_name" :label="$t('Last name')" />
          </div>
          <div class="w-full pb-8 pr-6 xl:w-1/4">
            <text-input
              v-model="form.birth_day"
              :error="form.errors.birth_day"
              :label="$t('Birth Day')"
              max="31"
              min="1"
              type="number"
            />
            <span class="mt-2 inline-block text-xs text-gray-700">Required for Energy Safety Canada exports.</span>
          </div>
          <div class="w-full pb-8 pr-6 xl:w-1/4">
            <text-input
              v-model="form.birth_month"
              :error="form.errors.birth_month"
              :label="$t('Birth Month')"
              max="12"
              min="1"
              type="number"
            />
            <span class="mt-2 inline-block text-xs text-gray-700">Required for Energy Safety Canada exports.</span>
          </div>
          <div class="w-full pb-8 pr-6 xl:w-1/4">
            <text-input
              v-model="form.birth_year"
              :error="form.errors.birth_year"
              :label="$t('Birth Year')"
              min="1920"
              type="number"
            />
            <span class="mt-2 inline-block text-xs text-gray-700">Required to send to the Ministry of Labour</span>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <select-input v-model="form.locale" :error="form.errors.locale" :label="$t('Locale/Language')">
              <option :value="null" />
              <option v-for="(locale, key) in locales" :key="key" :value="locale">
                {{ key }}
              </option>
            </select-input>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <select-input v-model="form.timezone" :error="form.errors.timezone" :label="$t('Timezone')">
              <option :value="null" />
              <option v-for="(timezone, key) in timezones" :key="key" :value="timezone">
                {{ timezone }}
              </option>
            </select-input>
          </div>
        </div>
        <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Organization') }}</div>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <dynamic-search-input
              v-model="selectedOrganization"
              :error="form.errors.organization_id"
              :label="$t('Organization')"
              :search-by="['name']"
              track-by="id"
              :url="route('organizations.search')"
            >
              <div v-if="selectedOrganization" class="truncate">{{ selectedOrganization.name }}</div>
              <template #option="{ option }">
                {{ option.name }}
              </template>
            </dynamic-search-input>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <label class="form-label">{{ $t('Groups:') }}</label>
            <Multiselect
              v-model="form.groups"
              deselect-label=""
              :disabled="!selectedOrganization"
              :hide-label="true"
              :hide-selected="true"
              :label="$t('name')"
              :multiple="true"
              name="Groups"
              :options="organizationGroups"
              :placeholder="$t('Select groups')"
              select-label=""
              track-by="id"
            >
              <template #noResult>{{ $t('No groups found.') }}</template>
              <template #noOptions>{{ $t('No groups found.') }}</template>
            </Multiselect>
          </div>
        </div>
        <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Contact Information') }}</div>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.phone" :error="form.errors.phone" :label="$t('Phone')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.address" :error="form.errors.address" :label="$t('Address')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.city" :error="form.errors.city" :label="$t('City')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <select-input
              v-model="form.region"
              :error="form.errors.region"
              :label="$t('Province/State')"
              :options="[null, ...regions]"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.postal_code" :error="form.errors.postal_code" :label="$t('Postal code')" />
          </div>
        </div>
        <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Certificate Information') }}</div>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <notice v-if="selectedOrganization">
            {{ $t('The certificate fields are disabled for users within an organization.') }}
          </notice>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input
              v-model="form.employer_name"
              :disabled="selectedOrganization"
              :error="form.errors.employer_name"
              :label="$t('Employer Name')"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input
              v-model="form.employer_address"
              :disabled="selectedOrganization"
              :error="form.errors.employer_address"
              :label="$t('Employer Address')"
            />
          </div>
        </div>
        <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">Roles & Access</div>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.email" :error="form.errors.email" :label="$t('Email')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.username" :error="form.errors.username" :label="$t('Username')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input
              v-model="form.password"
              autocomplete="new-password"
              :error="form.errors.password"
              :label="$t('Password')"
              type="password"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <organization-role-dropdown v-model="form.organization_role" :error="form.errors.organization_role" />
          </div>
          <div v-if="selectedOrganization" class="w-full pb-8 pr-6 lg:w-1/2">
            <radio-input
              v-model="form.is_owner"
              :error="form.errors.is_owner"
              :label="$t('Is an organization owner')"
              type="radio"
            />
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Create User') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import DynamicSearchInput from '@/Shared/DynamicSearchInput.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import Notice from '@/Shared/Notice.vue'
import OrganizationRoleDropdown from '@/Shared/OrganizationRoleDropdown.vue'
import RadioInput from '@/Shared/RadioInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import Http from '@/Utils/Http'
import { useForm } from '@inertiajs/vue3'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect,
    DynamicSearchInput,
    Layout,
    LoadingButton,
    Notice,
    OrganizationRoleDropdown,
    RadioInput,
    SelectInput,
    TextInput,
  },
  props: {
    can: Object,
    regions: Array,
    locales: Object,
    timezones: Object,
    organization: Object,
  },
  data() {
    return {
      selectedOrganization: null,
      organizationGroups: [],
      form: useForm(
        {
          first_name: null,
          last_name: null,
          organization_id: this.organization?.id,
          groups: [],
          birth_year: null,
          email: null,
          username: null,
          phone: null,
          address: null,
          city: null,
          region: null,
          postal_code: null,
          password: null,
          locale: 'en',
          timezone: null,
          employer_name: null,
          employer_address: null,
          is_owner: false,
          organization_role: 'basic',
        },
        'UsersCreate'
      ),
    }
  },
  watch: {
    selectedOrganization: function (organization) {
      this.form.organization_id = organization ? organization.id : null

      this.form.groups = []
      if (organization) {
        this.form.employer_name = this.form.employer_address = null
        Http.get(this.route('groups.search'), { params: { search: '', organization: organization?.id } }).then(
          (response) => {
            this.organizationGroups = response.data.data
          }
        )
      }
    },
  },
  methods: {
    submit() {
      this.form
        .transform((data) => ({
          first_name: data.first_name,
          last_name: data.last_name,
          organization_id: this.organizationId ?? data.organization_id,
          organization_role: data.organization_role,
          birth_year: data.birth_year,
          email: data.email,
          username: data.username,
          phone: data.phone,
          address: data.address,
          city: data.city,
          region: data.region,
          postal_code: data.postal_code,
          password: data.password,
          locale: data.locale,
          timezone: data.timezone,
          employer_name: data.employer_name,
          employer_address: data.employer_address,
          is_owner: data.organization ? data.is_owner : false,
        }))
        .post(this.route('users.store'))
    },
  },
}
</script>
