<template>
  <modal class="rounded text-base" :show="show" style="z-index: 100000" width="auto" @close="close">
    <div class="max-h-screen max-w-3xl overflow-auto rounded bg-white p-6 shadow md:p-10">
      <h3 class="mb-4 mt-8 text-center text-4xl text-black">{{ $t('Fulfill Plastic Card Orders') }}</h3>
      <div>
        <h4 class="text-dark-gray-1000 mb-8 text-center text-lg">{{ $t('Selected Orders') }}</h4>
        <div class="mb-6 mt-6 flex items-center justify-center bg-white px-8">
          <a
            class="btn-gray"
            :href="
              route('plastic-card-orders.export', {
                order_ids: orders.map((order) => ({ id: order.id, locale: order.locale })),
              })
            "
          >
            {{ $t('Download Export') }}
          </a>
          <button v-if="allOrdersEligibleForFulfillment" class="btn-blue ml-3" type="button" @click="markAsFulfilled">
            {{ $t('Mark as Fulfilled') }}
          </button>
        </div>
        <div class="overflow-x-auto rounded bg-white shadow">
          <table class="w-full">
            <thead>
              <tr>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Name') }}</th>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Employer') }}</th>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Course') }}</th>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Locale') }}</th>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Created') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order in form.orders" :key="order.id">
                <td class="whitespace-nowrap border-t px-6 py-4">{{ order.first_name }} {{ order.last_name }}</td>
                <td class="whitespace-nowrap border-t px-6 py-4">{{ order.employer_name }}</td>
                <td class="whitespace-nowrap border-t px-6 py-4">{{ order.course_name }}</td>
                <td class="whitespace-nowrap border-t px-6 py-4">
                  <front-select-input v-model="order.locale">
                    <option v-for="(locale, key) in locales" :key="key" :value="locale">
                      {{ key }}
                    </option>
                  </front-select-input>
                </td>
                <td class="whitespace-nowrap border-t px-6 py-4">{{ order.created_at }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mb-2 mt-12 flex items-center justify-center bg-white px-8">
        <a
          class="btn-gray"
          :href="
            route('plastic-card-orders.export', {
              order_ids: orders.map((order) => ({ id: order.id, locale: order.locale })),
            })
          "
        >
          {{ $t('Download Export') }}
        </a>
        <button v-if="allOrdersEligibleForFulfillment" class="btn-blue ml-3" type="button" @click="markAsFulfilled">
          {{ $t('Mark as Fulfilled') }}
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import FrontSelectInput from '@/Shared/FrontSelectInput.vue'
import Modal from '@/Shared/Modal.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    FrontSelectInput,
    Modal,
  },
  props: {
    show: Boolean,
    orders: Array,
    locales: Object,
  },
  data() {
    return {
      form: useForm({
        orders: [],
      }),
    }
  },
  computed: {
    allOrdersEligibleForFulfillment() {
      return this.orders.length === this.orders.filter((order) => !order.processed && !order.deleted_at).length
    },
  },
  watch: {
    orders: function (newVal) {
      this.form.orders = newVal
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    markAsFulfilled() {
      if (confirm('This will mark the order(s) as fulfilled/processed. Are you sure?')) {
        this.$inertia.post(
          this.route('plastic-card-orders.mark-as-fulfilled'),
          {
            order_ids: this.orders.map((order) => order.id),
          },
          {
            onSuccess: () => this.close(),
          }
        )
      }
    },
  },
}
</script>
