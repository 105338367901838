<template>
  <layout title="Classes">
    <div class="mb-8">
      <breadcrumb :name="$t('Classes')" />
    </div>
    <div class="mb-6 flex items-center justify-between">
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
        <label class="block text-gray-800">{{ $t('Access:') }}</label>
        <select v-model="form.access" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="public">{{ $t('Public') }}</option>
          <option value="private">{{ $t('Private') }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Between:') }}</label>
        <date-input v-model="form.local_start_date" class="mt-1 w-full" />
        <date-input v-model="form.local_end_date" class="mt-1 w-full" />
        <label class="mt-4 block text-gray-800">{{ $t('Location:') }}</label>
        <select v-model="form.location" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="location in locations" :key="location.id" :value="location.id">{{ location.name }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Instructor:') }}</label>
        <select v-model="form.instructor" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="with">{{ $t('With Other Instructors') }}</option>
        </select>
      </search-filter>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow">
      <SimpleTable class="w-full">
        <template #head>
          <TableHead>{{ $t('Name') }}</TableHead>
          <TableHead>{{ $t('Sessions') }}</TableHead>
          <TableHead>{{ $t('Location') }}</TableHead>
          <TableHead>{{ $t('Students') }}</TableHead>
          <TableHead colspan="2">{{ $t('Instructors') }}</TableHead>
        </template>
        <TableRow v-if="classes.data.length === 0">
          <TableData colspan="6">{{ $t('No classes found.') }}</TableData>
        </TableRow>
        <TableRow
          v-for="class_ in classes.data"
          :key="class_.id"
          class="hover:bg-gray-100 focus:bg-gray-100"
          :clickable="!form.instructor"
          @click="move(class_.id)"
        >
          <TableData>
            <div class="flex items-center">
              <span class="max-w-15 truncate">
                {{ class_.instructorLedClassroom.name }}
              </span>
              <span v-if="class_.private" class="ml-2 rounded bg-gray-400 px-2 py-1 text-sm text-white">
                {{ $t('Private') }}
              </span>
              <span
                v-if="class_.locale !== 'en'"
                class="ml-2 rounded bg-gray-400 px-2 py-1 text-sm uppercase text-white"
              >
                {{ class_.locale }}
              </span>
            </div>
          </TableData>
          <TableData class="min-w-56">
            <div v-if="class_.sessions_count">
              <span class="mr-1 rounded bg-gray-400 px-2 py-1 text-sm text-white">{{ class_.sessions_count }}</span>
              {{ class_.dates }}
            </div>
            <div v-else class="text-red-500">{{ $t('None set') }}</div>
          </TableData>
          <TableData>
            {{ class_.location }}
            <span v-if="class_.room" class="text-sm">({{ class_.room }})</span>
          </TableData>
          <TableData>
            {{ class_.students_count }}
          </TableData>
          <TableData>{{ class_.instructors }}</TableData>
          <TableData class="w-min align-middle">
            <icon v-if="!form.instructor" class="block h-6 w-6 fill-gray-400" name="chevron-right" />
          </TableData>
        </TableRow>
      </SimpleTable>
    </div>
    <pagination :links="classes.links" />
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import DateInput from '@/Shared/DateInput.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import _debounce from 'lodash/debounce'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'

export default {
  components: {
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
    Breadcrumb,
    DateInput,
    Icon,
    Layout,
    Pagination,
    SearchFilter,
  },
  props: {
    classes: Object,
    locations: Array,
    filters: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        access: this.filters.access,
        local_start_date: this.filters.local_start_date,
        local_end_date: this.filters.local_end_date,
        location: this.filters.location,
        instructor: this.filters.instructor,
        deleted: this.filters.deleted,
      },
    }
  },
  watch: {
    form: {
      handler: _debounce(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(
          this.route('instructor.classes'),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveState: true,
            replace: true,
            only: ['classes'],
          }
        )
      }, 300),
      deep: true,
    },
  },
  methods: {
    move(classId) {
      if (!this.form.instructor) {
        this.$inertia.visit(this.route('instructor.classes.show', classId))
      }
    },
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
