<template>
  <div class="flex flex-col items-center px-3 pt-8 sm:justify-start sm:pt-0 lg:flex-row lg:px-0">
    <div class="w-full lg:w-7/12">
      <svg
        class="mx-auto w-10/12 sm:w-2/3 lg:w-full"
        viewBox="0 0 894.171 892.851"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="objectBoundingBox"
            x1="0.662"
            x2="-0.249"
            y1="0.032"
            y2="2.362"
          >
            <stop offset="0" stop-color="#383d73" stop-opacity="0" />
            <stop offset="1" stop-color="#122845" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1="0.693"
            x2="-0.219"
            xlink:href="#linear-gradient"
            y1="-0.045"
            y2="2.285"
          />
        </defs>
        <g id="Group_7219" data-name="Group 7219" transform="translate(-43.313 -95.86)">
          <path
            id="Path_8004"
            d="M-8387.265,18623.729c-123.535,1.449-251.682,13.563-172.218,84.7s246.6,25.2,368,41.58,391.452,60.576,467.422,24.748,28.034-141.432-171.317-133.113c-70.3,5.889-170.3-.006-267.262-6.582C-8247.253,18629.318-8329.569,18623.047-8387.265,18623.729Z"
            data-name="Path 8004"
            fill="#00a7e1"
            opacity="0.08"
            transform="translate(8627.368 -17799.689)"
          />
          <g id="Group_7173" data-name="Group 7173" transform="translate(-62.484 53.479)">
            <path
              id="Path_8204"
              d="M418.4,249.774c-4.737-15.05,3.4-30.5,9.167-44.028,2.735-6.409,5.253-13.312,4.167-20.2-.9-5.693-4.17-10.71-7.652-15.3-8.987-11.85-19.918-22.236-27.85-34.816s-12.641-28.438-7.519-42.4c3.028-8.257,9.194-15.025,16.066-20.513,18.994-15.164,43.818-21.9,62.035-37.995-12.268,13.76-29.958,21.39-43.782,33.587-5.944,5.246-11.3,11.593-13.42,19.231-2.792,10.035.436,20.966,6.157,29.67s13.729,15.607,21.535,22.5,15.642,14.057,20.866,23.07c3.674,6.339,5.972,13.669,5.323,20.967-1.229,13.815-12.278,24.417-22.441,33.857s-20.867,20.641-21.04,34.511"
              data-name="Path 8204"
              fill="url(#linear-gradient)"
              opacity="0.22"
              transform="translate(167.453 -103.656) rotate(17)"
            />
            <path
              id="Path_8205"
              d="M348.028,243.111c-3.932-12.5,2.82-25.327,7.612-36.554,2.271-5.322,4.361-11.053,3.46-16.769-.745-4.727-3.463-8.893-6.354-12.707-7.461-9.839-16.537-18.461-23.123-28.906s-10.5-23.611-6.243-35.206c2.515-6.855,7.633-12.474,13.34-17.03,15.77-12.59,36.38-18.186,51.5-31.546-10.185,11.424-24.874,17.759-36.35,27.887-4.935,4.356-9.378,9.626-11.143,15.967-2.318,8.332.363,17.407,5.114,24.633s11.4,12.959,17.88,18.686,12.987,11.672,17.323,19.154c3.051,5.263,4.959,11.35,4.42,17.407-1.021,11.472-10.194,20.274-18.632,28.112s-17.326,17.137-17.469,28.653"
              data-name="Path 8205"
              fill="url(#linear-gradient-2)"
              opacity="0.22"
              transform="translate(46.787 12.14)"
            />
            <path
              id="Path_8206"
              d="M590.6,609.394v18.563l10.37,1.5v-4.489s28.808,8.23,29.566,8.23,18.448,1.5,18.448,1.5,1.516-3.991-5.813-5.987-20.133-17.559-20.133-17.559Z"
              data-name="Path 8206"
              transform="translate(156.113 233.641)"
            />
            <path
              id="Path_8207"
              d="M542.122,609.4v16.388l10.37,1.5v-4.489s28.808,8.23,29.566,8.23,18.447,1.5,18.447,1.5,1.517-3.99-5.811-5.986-23.314-19.954-23.314-19.954Z"
              data-name="Path 8207"
              transform="translate(136.411 232.496)"
            />
            <ellipse
              id="Ellipse_550"
              cx="41.291"
              cy="49.487"
              data-name="Ellipse 550"
              rx="41.291"
              ry="49.487"
              transform="translate(677.467 279.002)"
            />
            <path
              id="Path_8208"
              d="M649.322,422.9V406.574l-115.686,2.4v9l16.2,137.385-5.69,136.766,36.614-.444L594.84,553.4l.217-90.2,23.163,89.681-5.758,141.12,35.064,1.168L660.2,545.814Z"
              data-name="Path 8208"
              fill="#383d73"
              transform="translate(132.962 151.21)"
            />
            <circle
              id="Ellipse_551"
              cx="7.536"
              cy="7.536"
              data-name="Ellipse 551"
              fill="#f2d3bb"
              r="7.536"
              transform="translate(744.977 334.422)"
            />
            <g id="Group_7162" data-name="Group 7162" transform="translate(692.394 291.692)">
              <path
                id="Path_8209"
                d="M590.518,276.26l-9.568,4.229A27.582,27.582,0,0,0,590.518,276.26Z"
                data-name="Path 8209"
                fill="#f2d3bb"
                transform="translate(-540.203 -193.444)"
              />
              <path
                id="Path_8210"
                d="M602.3,300.653c.011-.158.017-.319.029-.477l-.032.016a27.582,27.582,0,0,1-9.568,4.229l9.568-4.229c7.242-5.15,13.237-15.462,13.852-37,.564-19.718-10.485-37.508-19.985-43.12-6.517-3.848-17.341-3.312-24.167-.042-9.86,4.721-21.359,21.417-19.887,48.65,0,0,.837,16.285,8.426,25.248a14.116,14.116,0,0,1,2.29,4.388C582.361,309.192,595.124,305.515,602.3,300.653Z"
                data-name="Path 8210"
                fill="#f2d3bb"
                transform="translate(-551.978 -217.376)"
              />
              <path
                id="Path_8211"
                d="M560.668,310.478a11.37,11.37,0,0,1-.662,2.039l45.049-.118s-.035-.132-.1-.37Z"
                data-name="Path 8211"
                fill="#f2d3bb"
                transform="translate(-548.715 -179.537)"
              />
              <path
                id="Path_8212"
                d="M599.162,277.264c-7.172,4.862-19.935,8.538-39.474-2.34,4.426,12.578,3.35,40.961,1.11,50l44.29,1.551C604.215,323.142,597.73,297.444,599.162,277.264Z"
                data-name="Path 8212"
                fill="#e8c8b3"
                transform="translate(-548.844 -193.987)"
              />
            </g>
            <ellipse
              id="Ellipse_552"
              cx="2.896"
              cy="4.488"
              data-name="Ellipse 552"
              rx="2.896"
              ry="4.488"
              transform="translate(713.649 333.898)"
            />
            <circle
              id="Ellipse_553"
              cx="7.536"
              cy="7.536"
              data-name="Ellipse 553"
              fill="#f2d3bb"
              r="7.536"
              transform="translate(685.713 334.422)"
            />
            <ellipse
              id="Ellipse_554"
              cx="2.896"
              cy="4.488"
              data-name="Ellipse 554"
              rx="2.896"
              ry="4.488"
              transform="translate(737.96 332.849)"
            />
            <path
              id="Path_8213"
              d="M577.792,250.2s.006,3.508.821,4.411,5.464,7.6,5.464,7.6a6.7,6.7,0,0,1-2.352,2.345c-1.3.574-.015,2.485-.015,2.485"
              data-name="Path 8213"
              fill="none"
              stroke="#ddbca6"
              stroke-miterlimit="10"
              stroke-width="1"
              transform="translate(150.908 87.658)"
            />
            <path
              id="Path_8214"
              d="M570.707,268.518s8.42-5.617,22.892,3.671A114.48,114.48,0,0,0,570.707,268.518Z"
              data-name="Path 8214"
              fill="#dbbba9"
              transform="translate(148.028 94.417)"
            />
            <circle
              id="Ellipse_555"
              cx="5.552"
              cy="5.552"
              data-name="Ellipse 555"
              fill="#ef9981"
              opacity="0.2"
              r="5.552"
              transform="translate(703.985 343.942)"
            />
            <circle
              id="Ellipse_556"
              cx="5.552"
              cy="5.552"
              data-name="Ellipse 556"
              fill="#ef9981"
              opacity="0.2"
              r="5.552"
              transform="translate(739.871 342.691)"
            />
            <path
              id="Path_8215"
              d="M650.636,462.606,531.865,460.25l4.541-159.981,38.422-8.549H610.7l34.848,12.394Z"
              data-name="Path 8215"
              fill="#122845"
              transform="translate(132.242 104.531)"
            />
            <g id="Group_7170" data-name="Group 7170" transform="translate(206.485 253.734)">
              <g id="Group_7163" data-name="Group 7163" transform="translate(18.858 17.414)">
                <rect
                  id="Rectangle_8336"
                  data-name="Rectangle 8336"
                  height="572.31"
                  rx="9.332"
                  transform="translate(31.98)"
                  width="286.054"
                />
                <rect
                  id="Rectangle_8337"
                  data-name="Rectangle 8337"
                  fill="#122845"
                  height="572.31"
                  rx="9.332"
                  width="286.054"
                />
              </g>
              <rect
                id="Rectangle_8338"
                data-name="Rectangle 8338"
                fill="#122845"
                height="560.735"
                rx="9.808"
                transform="translate(36.056)"
                width="322.509"
              />
              <rect
                id="Rectangle_8339"
                data-name="Rectangle 8339"
                fill="#122845"
                height="560.735"
                rx="9.808"
                width="322.509"
              />
              <rect
                id="Rectangle_8340"
                data-name="Rectangle 8340"
                fill="#724896"
                height="76.677"
                rx="4.851"
                transform="translate(9.066 194.161)"
                width="304.378"
              />
              <rect
                id="Rectangle_8341"
                data-name="Rectangle 8341"
                fill="#724896"
                height="76.677"
                rx="4.851"
                transform="translate(9.066 286.355)"
                width="304.378"
              />
              <rect
                id="Rectangle_8342"
                data-name="Rectangle 8342"
                fill="#724896"
                height="76.677"
                rx="4.851"
                transform="translate(9.066 378.549)"
                width="304.378"
              />
              <rect
                id="Rectangle_8343"
                data-name="Rectangle 8343"
                fill="#724896"
                height="76.677"
                rx="4.851"
                transform="translate(9.066 470.743)"
                width="304.378"
              />
              <g id="Group_7164" data-name="Group 7164" transform="translate(9.066 9.774)">
                <rect
                  id="Rectangle_8344"
                  data-name="Rectangle 8344"
                  fill="#383d73"
                  height="76.677"
                  rx="4.851"
                  width="304.378"
                />
                <rect
                  id="Rectangle_8345"
                  data-name="Rectangle 8345"
                  fill="#724896"
                  height="16.862"
                  transform="translate(40.367 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8346"
                  data-name="Rectangle 8346"
                  fill="#122845"
                  height="16.862"
                  transform="translate(11.968 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8347"
                  data-name="Rectangle 8347"
                  fill="#00a7e1"
                  height="16.862"
                  transform="translate(97.165 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8348"
                  data-name="Rectangle 8348"
                  fill="#faa61a"
                  height="16.862"
                  transform="translate(68.766 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8349"
                  data-name="Rectangle 8349"
                  fill="#e54e39"
                  height="16.862"
                  transform="translate(125.564 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8350"
                  data-name="Rectangle 8350"
                  fill="#122845"
                  height="50.791"
                  rx="4.625"
                  transform="translate(167.307 12.943)"
                  width="121.9"
                />
                <rect
                  id="Rectangle_8351"
                  data-name="Rectangle 8351"
                  fill="#724896"
                  height="11.742"
                  rx="4.174"
                  transform="translate(231.886 32.468)"
                  width="48.181"
                />
              </g>
              <g id="Group_7165" data-name="Group 7165" transform="translate(9.066 101.968)">
                <rect
                  id="Rectangle_8352"
                  data-name="Rectangle 8352"
                  fill="#383d73"
                  height="76.677"
                  rx="4.851"
                  width="304.378"
                />
                <rect
                  id="Rectangle_8353"
                  data-name="Rectangle 8353"
                  fill="#724896"
                  height="16.862"
                  transform="translate(40.367 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8354"
                  data-name="Rectangle 8354"
                  fill="#122845"
                  height="16.862"
                  transform="translate(11.968 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8355"
                  data-name="Rectangle 8355"
                  fill="#00a7e1"
                  height="16.862"
                  transform="translate(97.165 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8356"
                  data-name="Rectangle 8356"
                  fill="#faa61a"
                  height="16.862"
                  transform="translate(68.766 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8357"
                  data-name="Rectangle 8357"
                  fill="#e54e39"
                  height="16.862"
                  transform="translate(125.564 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8358"
                  data-name="Rectangle 8358"
                  fill="#122845"
                  height="50.791"
                  rx="4.625"
                  transform="translate(167.307 12.943)"
                  width="121.9"
                />
                <rect
                  id="Rectangle_8359"
                  data-name="Rectangle 8359"
                  fill="#724896"
                  height="11.742"
                  rx="4.174"
                  transform="translate(231.886 32.468)"
                  width="48.181"
                />
              </g>
              <g id="Group_7166" data-name="Group 7166" transform="translate(9.066 194.161)">
                <rect
                  id="Rectangle_8360"
                  data-name="Rectangle 8360"
                  fill="#383d73"
                  height="76.677"
                  rx="4.851"
                  width="304.378"
                />
                <rect
                  id="Rectangle_8361"
                  data-name="Rectangle 8361"
                  fill="#724896"
                  height="16.862"
                  transform="translate(40.367 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8362"
                  data-name="Rectangle 8362"
                  fill="#122845"
                  height="16.862"
                  transform="translate(11.968 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8363"
                  data-name="Rectangle 8363"
                  fill="#00a7e1"
                  height="16.862"
                  transform="translate(97.165 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8364"
                  data-name="Rectangle 8364"
                  fill="#faa61a"
                  height="16.862"
                  transform="translate(68.766 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8365"
                  data-name="Rectangle 8365"
                  fill="#e54e39"
                  height="16.862"
                  transform="translate(125.564 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8366"
                  data-name="Rectangle 8366"
                  fill="#122845"
                  height="50.791"
                  rx="4.625"
                  transform="translate(167.307 12.943)"
                  width="121.9"
                />
                <rect
                  id="Rectangle_8367"
                  data-name="Rectangle 8367"
                  fill="#724896"
                  height="11.742"
                  rx="4.174"
                  transform="translate(231.886 32.468)"
                  width="48.181"
                />
              </g>
              <g id="Group_7167" data-name="Group 7167" transform="translate(9.066 286.239)">
                <rect
                  id="Rectangle_8368"
                  data-name="Rectangle 8368"
                  fill="#383d73"
                  height="76.677"
                  rx="4.851"
                  width="304.378"
                />
                <rect
                  id="Rectangle_8369"
                  data-name="Rectangle 8369"
                  fill="#724896"
                  height="16.862"
                  transform="translate(40.367 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8370"
                  data-name="Rectangle 8370"
                  fill="#122845"
                  height="16.862"
                  transform="translate(11.968 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8371"
                  data-name="Rectangle 8371"
                  fill="#00a7e1"
                  height="16.862"
                  transform="translate(97.165 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8372"
                  data-name="Rectangle 8372"
                  fill="#faa61a"
                  height="16.862"
                  transform="translate(68.766 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8373"
                  data-name="Rectangle 8373"
                  fill="#e54e39"
                  height="16.862"
                  transform="translate(125.564 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8374"
                  data-name="Rectangle 8374"
                  fill="#122845"
                  height="50.791"
                  rx="4.625"
                  transform="translate(167.307 12.943)"
                  width="121.9"
                />
                <rect
                  id="Rectangle_8375"
                  data-name="Rectangle 8375"
                  fill="#724896"
                  height="11.742"
                  rx="4.174"
                  transform="translate(231.886 32.468)"
                  width="48.181"
                />
              </g>
              <g id="Group_7168" data-name="Group 7168" transform="translate(9.066 378.549)">
                <rect
                  id="Rectangle_8376"
                  data-name="Rectangle 8376"
                  fill="#383d73"
                  height="76.677"
                  rx="4.851"
                  width="304.378"
                />
                <rect
                  id="Rectangle_8377"
                  data-name="Rectangle 8377"
                  fill="#724896"
                  height="16.862"
                  transform="translate(40.367 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8378"
                  data-name="Rectangle 8378"
                  fill="#122845"
                  height="16.862"
                  transform="translate(11.968 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8379"
                  data-name="Rectangle 8379"
                  fill="#00a7e1"
                  height="16.862"
                  transform="translate(97.165 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8380"
                  data-name="Rectangle 8380"
                  fill="#faa61a"
                  height="16.862"
                  transform="translate(68.766 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8381"
                  data-name="Rectangle 8381"
                  fill="#e54e39"
                  height="16.862"
                  transform="translate(125.564 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8382"
                  data-name="Rectangle 8382"
                  fill="#122845"
                  height="50.791"
                  rx="4.625"
                  transform="translate(167.307 12.943)"
                  width="121.9"
                />
                <rect
                  id="Rectangle_8383"
                  data-name="Rectangle 8383"
                  fill="#724896"
                  height="11.742"
                  rx="4.174"
                  transform="translate(231.886 32.468)"
                  width="48.181"
                />
              </g>
              <g id="Group_7169" data-name="Group 7169" transform="translate(9.066 470.859)">
                <rect
                  id="Rectangle_8384"
                  data-name="Rectangle 8384"
                  fill="#383d73"
                  height="76.677"
                  rx="4.851"
                  width="304.378"
                />
                <rect
                  id="Rectangle_8385"
                  data-name="Rectangle 8385"
                  fill="#724896"
                  height="16.862"
                  transform="translate(40.367 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8386"
                  data-name="Rectangle 8386"
                  fill="#122845"
                  height="16.862"
                  transform="translate(11.968 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8387"
                  data-name="Rectangle 8387"
                  fill="#00a7e1"
                  height="16.862"
                  transform="translate(97.165 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8388"
                  data-name="Rectangle 8388"
                  fill="#faa61a"
                  height="16.862"
                  transform="translate(68.766 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8389"
                  data-name="Rectangle 8389"
                  fill="#e54e39"
                  height="16.862"
                  transform="translate(125.564 29.908)"
                  width="16.862"
                />
                <rect
                  id="Rectangle_8390"
                  data-name="Rectangle 8390"
                  fill="#122845"
                  height="50.791"
                  rx="4.625"
                  transform="translate(167.307 12.943)"
                  width="121.9"
                />
                <rect
                  id="Rectangle_8391"
                  data-name="Rectangle 8391"
                  fill="#724896"
                  height="11.742"
                  rx="4.174"
                  transform="translate(231.886 32.468)"
                  width="48.181"
                />
              </g>
            </g>
            <path
              id="Path_8216"
              d="M561.846,293.019s19.224,25.145,37.19-.06C599.036,292.958,579.644,283.854,561.846,293.019Z"
              data-name="Path 8216"
              fill="#e8c8b3"
              transform="translate(144.427 103.397)"
            />
            <path
              id="Path_8217"
              d="M615.135,300.638s11.15,12.265,10.382,38.913S633,420.575,633,420.575H609.426S601.437,302.344,615.135,300.638Z"
              data-name="Path 8217"
              fill="#faa61a"
              transform="translate(162.823 108.155)"
            />
            <ellipse
              id="Ellipse_557"
              cx="16.572"
              cy="11.077"
              data-name="Ellipse 557"
              fill="#e8c8b3"
              rx="16.572"
              ry="11.077"
              transform="translate(770.27 530.064)"
            />
            <path
              id="Path_8218"
              d="M568.9,399.3s4.685,6.668,15.234,2.235,16.921,1.736,16.921,1.736,9.34,2.183,8.131-2.762-12.745-15.932-23.4-12.416-12.635-4.176-12.635-4.176Z"
              data-name="Path 8218"
              fill="#e8c8b3"
              transform="translate(147.292 142.002)"
            />
            <g id="Group_7171" data-name="Group 7171" transform="translate(694.217 465.849)">
              <rect
                id="Rectangle_8392"
                data-name="Rectangle 8392"
                fill="#999"
                height="6.774"
                transform="translate(0 74.948)"
                width="97.709"
              />
              <path
                id="Path_8219"
                d="M599.671,341.206l95.389,2.725-17.715,72.223H579.912Z"
                data-name="Path 8219"
                fill="#c6c6c6"
                transform="translate(-542.448 -341.206)"
              />
              <rect
                id="Rectangle_8393"
                data-name="Rectangle 8393"
                fill="#adadad"
                height="6.774"
                transform="translate(31.889 74.948)"
                width="103.008"
              />
              <ellipse
                id="Ellipse_558"
                cx="3.938"
                cy="4.767"
                data-name="Ellipse 558"
                fill="#383d73"
                rx="3.938"
                ry="4.767"
                transform="translate(91.1 37.474)"
              />
            </g>
            <path
              id="Path_8220"
              d="M545.885,297.8s-8.455,4.5-10.457,10.323c-1.764,5.132-24.469,81.063-26.711,98.367-.433,3.332-.374,8.091,3.619,9.232l81.333,20.843,7.2-16.447-57.517-22.482a3.523,3.523,0,0,1-1.712-3.931l17.414-61.6a22.021,22.021,0,0,0,.255-9.579C557.929,315.49,554.379,303.307,545.885,297.8Z"
              data-name="Path 8220"
              fill="#faa61a"
              transform="translate(122.761 107.002)"
            />
            <path
              id="Path_8221"
              d="M554.382,258.246c1.55-.384,6.4-11.971,19.485-10.244s29.571,17.534,60.96-6.062l-6.848-2.494,5.636-3.465s-18.1-33.218-59.195-28.089-21.707,50.354-21.707,50.354"
              data-name="Path 8221"
              transform="translate(138.857 70.242)"
            />
            <rect
              id="Rectangle_8394"
              data-name="Rectangle 8394"
              fill="#383d73"
              height="7.429"
              transform="translate(598.574 166.783)"
              width="7.429"
            />
            <rect
              id="Rectangle_8395"
              data-name="Rectangle 8395"
              fill="#122845"
              height="5.181"
              transform="translate(583.688 268.557)"
              width="5.181"
            />
            <rect
              id="Rectangle_8396"
              data-name="Rectangle 8396"
              fill="#724896"
              height="7.429"
              transform="translate(459.77 206.103)"
              width="7.429"
            />
            <rect
              id="Rectangle_8397"
              data-name="Rectangle 8397"
              fill="#00a7e1"
              height="7.429"
              transform="translate(544.483 206.103)"
              width="7.429"
            />
            <rect
              id="Rectangle_8398"
              data-name="Rectangle 8398"
              fill="#faa61a"
              height="7.429"
              transform="translate(598.574 332.756)"
              width="7.429"
            />
            <rect
              id="Rectangle_8399"
              data-name="Rectangle 8399"
              fill="#e54e39"
              height="4.266"
              transform="translate(549.78 120.022)"
              width="4.266"
            />
            <g id="Group_7172" data-name="Group 7172" transform="translate(499.362 774.071)">
              <rect
                id="Rectangle_8400"
                data-name="Rectangle 8400"
                fill="#e59117"
                height="73.736"
                rx="7.567"
                transform="translate(28.913 34.151)"
                width="133.832"
              />
              <rect
                id="Rectangle_8401"
                data-name="Rectangle 8401"
                fill="#faa61a"
                height="73.736"
                rx="6.802"
                transform="translate(0 34.151)"
                width="108.122"
              />
              <rect
                id="Rectangle_8402"
                data-name="Rectangle 8402"
                fill="#122845"
                height="7.139"
                transform="translate(0 56.922)"
                width="162.745"
              />
              <rect
                id="Rectangle_8403"
                data-name="Rectangle 8403"
                fill="#dbdbdb"
                height="17.398"
                rx="1.833"
                transform="translate(21.222 51.989)"
                width="10.287"
              />
              <rect
                id="Rectangle_8404"
                data-name="Rectangle 8404"
                fill="#dbdbdb"
                height="17.398"
                rx="1.833"
                transform="translate(62.314 51.989)"
                width="10.287"
              />
              <path
                id="Path_8222"
                d="M530.36,600.608v24.684H517.072V609.079c0-3.342-3.031-6.05-6.772-6.05H442.876c-3.741,0-6.773,2.709-6.773,6.05v16.213H422.816V600.608c0-5.228,4.742-9.464,10.593-9.464h86.357C525.617,591.144,530.36,595.38,530.36,600.608Z"
                data-name="Path 8222"
                fill="#dbdbdb"
                transform="translate(-395.335 -591.144)"
              />
            </g>
            <path
              id="Path_8223"
              d="M458.251,198.517l2.989-6.815,3.162,2.5,1.457-8.343,3.162,2.5,1.611-8.538,3.162,2.5,1.919-8.927,3.162,2.5,2.688-9.9,3.162,2.5,1.765-8.733,3.162,2.5,1.611-8.538,3.162,2.5"
              data-name="Path 8223"
              fill="none"
              stroke="#383d73"
              stroke-miterlimit="10"
              stroke-width="1"
              transform="translate(102.323 48.443)"
            />
            <path
              id="Path_8224"
              d="M333.772,196.677l-4.543-4.6,3.035-1.75-6.269-3.858,3.035-1.75-6.377-4.045,3.035-1.75-6.593-4.419,3.035-1.75L315,167.394l3.035-1.75-6.485-4.232,3.035-1.75-6.377-4.045,3.035-1.75"
              data-name="Path 8224"
              fill="none"
              opacity="0.52"
              stroke="#383d73"
              stroke-miterlimit="10"
              stroke-width="1"
              transform="translate(41.341 48.504)"
            />
            <path
              id="Path_8225"
              d="M388.524,196.271l-2.641-3.63,2.264-.887-3.9-3.3,2.264-.887-3.958-3.436,2.264-.887-4.067-3.715,2.264-.887-4.34-4.412,2.264-.887-4.013-3.576,2.264-.887-3.958-3.436,2.264-.887"
              data-name="Path 8225"
              fill="none"
              opacity="0.28"
              stroke="#e54e39"
              stroke-miterlimit="10"
              stroke-width="1"
              transform="translate(68.581 52.85)"
            />
            <path
              id="Path_8226"
              d="M388.758,148.913l-1.139-2.74,2.939.26-2.7-2.969,2.938.26-2.691-3.06,2.938.26-2.675-3.24,2.938.26-2.635-3.692,2.939.26-2.683-3.15,2.939.26-2.691-3.06,2.938.26"
              data-name="Path 8226"
              fill="none"
              stroke="#00a7e1"
              stroke-miterlimit="10"
              stroke-width="0.5"
              transform="translate(73.617 38.22)"
            />
            <rect
              id="Rectangle_8405"
              data-name="Rectangle 8405"
              fill="#724896"
              height="4.925"
              transform="translate(362.329 182.208)"
              width="4.925"
            />
            <rect
              id="Rectangle_8406"
              data-name="Rectangle 8406"
              fill="#faa61a"
              height="4.642"
              transform="translate(380.536 225.926)"
              width="4.642"
            />
          </g>
        </g>
      </svg>
    </div>
    <div class="w-full lg:w-5/12 lg:pl-20">
      <div
        class="relative mt-24 rounded-b-xl rounded-t-xl py-10 pl-4 pr-4 lg:mt-0 lg:rounded-tl-none lg:pl-8"
        style="background-color: #122845"
      >
        <svg
          class="absolute hidden h-10 w-10 lg:inline"
          style="top: -14px; left: -14px"
          viewBox="0 0 63.57 63.57"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m21.191 21.189h21.19v21.19h-21.19z" fill="#222f61" />
          <path d="m0 21.189h21.19v21.19h-21.19z" fill="#dc3529" />
          <path d="m42.38 21.189h21.19v21.19h-21.19z" fill="#0099db" />
          <path d="m21.191 0h21.19v21.19h-21.19z" fill="#724896" />
          <path d="m21.191 42.379h21.19v21.19h-21.19z" fill="#fc9200" />
        </svg>
        <svg
          class="absolute h-16 w-16 lg:hidden"
          style="top: -42px; left: 50%; transform: translate(-50%, 0%)"
          viewBox="0 0 63.57 63.57"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m21.191 21.189h21.19v21.19h-21.19z" fill="#222f61" />
          <path d="m0 21.189h21.19v21.19h-21.19z" fill="#dc3529" />
          <path d="m42.38 21.189h21.19v21.19h-21.19z" fill="#0099db" />
          <path d="m21.191 0h21.19v21.19h-21.19z" fill="#724896" />
          <path d="m21.191 42.379h21.19v21.19h-21.19z" fill="#fc9200" />
        </svg>
        <p class="text-center font-heading text-5xl text-white lg:text-left">{{ title }}</p>
        <p class="mt-3 text-center leading-normal text-white lg:text-left">{{ description }}</p>
        <div class="mt-8 flex flex-col justify-center gap-2 md:flex-row">
          <button
            class="mx-auto flex cursor-pointer items-center justify-center rounded-full bg-white px-8 py-3 font-semibold md:mx-0"
            @click="goBack"
          >
            <ArrowLeftIcon class="mr-2 h-5 w-5 text-black" />
            {{ $t('Go Back') }}
          </button>
          <button
            class="mx-auto flex cursor-pointer items-center justify-center rounded-full border-2 border-white bg-transparent px-8 py-3 font-semibold text-white md:mx-0"
            @click="$inertia.visit('/')"
          >
            <HomeIcon class="mr-2 h-5 w-5" />
            {{ $t('Home') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowLeftIcon, HomeIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    ArrowLeftIcon,
    HomeIcon,
  },
  props: {
    title: String,
    description: String,
  },
  methods: {
    goBack() {
      this.$emit('goBack')
    },
  },
}
</script>
