<template>
  <div class="p-8">
    <app-head title="Organization Access Codes" />
    <div v-if="!enabled">
      <p>Access codes are disabled for this organization</p>
    </div>
    <div v-else>
      <div class="mt-4 flex items-end justify-between">
        <h2 class="text-lg">{{ $t('Access Codes') }}</h2>
      </div>
      <div class="mt-4 overflow-x-auto rounded bg-white shadow">
        <SimpleTable>
          <template #head>
            <TableHead>{{ $t('Code') }}</TableHead>
            <TableHead>{{ $t('Courses') }}</TableHead>
            <TableHead>{{ $t('Group Assigned') }}</TableHead>
            <TableHead>{{ $t('Uses Remaining') }}</TableHead>
            <TableHead />
          </template>
          <TableRow v-if="accessCodes.data.length === 0">
            <TableData colspan="6">{{ $t('No access codes found.') }}</TableData>
          </TableRow>
          <TableRow
            v-for="accessCode in accessCodes.data"
            :key="accessCode.id"
            :clickable="true"
            @click="$inertia.visit(route('access-codes.edit', accessCode.id))"
          >
            <TableData class="space-x-1">
              {{ accessCode.code }}
              <archived-badge v-if="accessCode.archived_at" />
              <deleted-badge v-if="accessCode.deleted_at" />
            </TableData>
            <TableData>{{ formatCourseList(accessCode.courses) }}</TableData>
            <TableData>
              {{ accessCode.group ? accessCode.group : '-' }}
            </TableData>
            <TableData>
              {{ getRemainingUses(accessCode.remaining_uses) }}
            </TableData>
            <TableData class="w-min align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </TableData>
          </TableRow>
        </SimpleTable>
      </div>
      <pagination :links="accessCodes.links" :preserve-scroll="true" />
    </div>
  </div>
</template>

<script>
import OrganizationEditLayout from './OrganizationEditLayout.vue'
import AppHead from '@/Shared/AppHead.vue'
import ArchivedBadge from '@/Shared/ArchivedBadge.vue'
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'

export default {
  components: {
    AppHead,
    ArchivedBadge,
    DeletedBadge,
    Icon,
    Pagination,
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
  },
  layout: [Layout, OrganizationEditLayout],
  props: {
    enabled: Boolean,
    accessCodes: Object,
  },
  methods: {
    formatCourseList(courses) {
      if (!courses || courses.length === 0) return '-'
      if (courses.length === 1) return courses[0]
      return `${courses[0]} (${courses.length - 1} ${courses.length - 1 > 1 ? 'others' : 'other'})`
    },
    getRemainingUses(remainingUses) {
      return remainingUses ?? '-'
    },
  },
}
</script>
