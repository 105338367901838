<template>
  <layout :title="pageTitle">
    <div class="flex max-w-3xl">
      <success-message v-if="plasticCardOrder.processed" class="mb-6 w-full lg:w-3/5 xl:w-2/3">
        {{ $t('This plastic card order has been fulfilled.') }}
      </success-message>
      <deleted-message v-if="plasticCardOrder.deleted_at" class="mb-6 w-full lg:w-3/5 xl:w-2/3">
        {{ $t('This plastic card order has been deleted.') }}
      </deleted-message>
    </div>
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('plastic-card-orders')">
        {{ $t('Plastic Card Orders') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ form.first_name }} {{ form.last_name }}
    </h1>
    <div class="flex max-w-3xl flex-wrap">
      <div class="w-full lg:w-3/5 xl:w-2/3">
        <div class="overflow-hidden rounded bg-white shadow">
          <form @submit.prevent="submit">
            <div class="-mb-8 -mr-6 flex flex-wrap p-8">
              <div class="flex flex-wrap">
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <text-input
                    v-model="form.first_name"
                    :disabled="plasticCardOrder.deleted_at || plasticCardOrder.processed"
                    :error="form.errors.first_name"
                    :label="$t('First Name')"
                  />
                </div>
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <text-input
                    v-model="form.last_name"
                    :disabled="plasticCardOrder.deleted_at || plasticCardOrder.processed"
                    :error="form.errors.last_name"
                    :label="$t('Last Name')"
                  />
                </div>
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <text-input
                    v-model="form.employer_name"
                    :disabled="plasticCardOrder.deleted_at || plasticCardOrder.processed"
                    :error="form.errors.employer_name"
                    :label="$t('Employer Name')"
                  />
                </div>
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <text-input
                    v-model="form.employer_address"
                    :disabled="plasticCardOrder.deleted_at || plasticCardOrder.processed"
                    :error="form.errors.employer_address"
                    :label="$t('Employer Address')"
                  />
                </div>
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <text-input v-model="plasticCardOrder.course.name" disabled :label="$t('Course')" />
                </div>
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <text-input v-model="plasticCardOrder.certificate.created_at" disabled :label="$t('Issue Date')" />
                </div>
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <text-input v-model="plasticCardOrder.certificate.expires_at" disabled :label="$t('Expiry')" />
                </div>
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <radio-input
                    v-model="form.processed"
                    :disabled="plasticCardOrder.deleted_at || plasticCardOrder.processed"
                    :error="form.errors.processed"
                    :label="$t('Fulfilled')"
                    type="radio"
                  />
                </div>
                <div class="w-full pb-8 pr-6">
                  <textarea-input
                    v-model="form.notes"
                    :autosize="true"
                    class="w-full pb-8"
                    :disabled="plasticCardOrder.deleted_at || plasticCardOrder.processed"
                    :error="form.errors.notes"
                    :label="$t('Order Notes')"
                    :rows="3"
                  />
                </div>
              </div>
            </div>
            <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
              <div v-if="!plasticCardOrder.processed">
                <button
                  v-if="plasticCardOrder.deleted_at && plasticCardOrder.certificate.created_at"
                  class="text-red-500 hover:underline"
                  tabindex="-1"
                  type="button"
                  @click="restore"
                >
                  {{ $t('Restore Order') }}
                </button>
                <button
                  v-if="!plasticCardOrder.deleted_at"
                  class="text-red-500 hover:underline"
                  tabindex="-1"
                  type="button"
                  @click="destroy"
                >
                  {{ $t('Delete') }}
                </button>
              </div>
              <div v-else />
              <loading-button
                class="btn-blue"
                :disabled="plasticCardOrder.deleted_at || (plasticCardOrder.processed && form.processed)"
                :loading="form.processing"
                type="submit"
              >
                <span>{{ $t('Update') }}</span>
                <span class="ml-1 hidden md:inline">{{ $t('Order') }}</span>
              </loading-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import DeletedMessage from '@/Shared/DeletedMessage.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import RadioInput from '@/Shared/RadioInput.vue'
import SuccessMessage from '@/Shared/SuccessMessage.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    DeletedMessage,
    Layout,
    LoadingButton,
    RadioInput,
    SuccessMessage,
    TextInput,
    TextareaInput,
  },
  props: {
    plasticCardOrder: Object,
  },
  data() {
    return {
      form: useForm(
        {
          first_name: this.plasticCardOrder.first_name,
          last_name: this.plasticCardOrder.last_name,
          employer_name: this.plasticCardOrder.employer_name,
          employer_address: this.plasticCardOrder.employer_address,
          processed: this.plasticCardOrder.processed,
          notes: this.plasticCardOrder.notes,
        },
        'PlasticCardOrdersCreate'
      ),
    }
  },
  computed: {
    pageTitle() {
      return this.$t(':first_name :last_name | Plastic Card Order', {
        first_name: `${this.form.first_name}`,
        last_name: `${this.form.last_name}`,
      })
    },
  },
  methods: {
    submit() {
      this.form.put(this.route('plastic-card-orders.update', this.plasticCardOrder.id))
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this plastic card order?'))) {
        this.$inertia.delete(this.route('plastic-card-orders.destroy', this.plasticCardOrder.id))
      }
    },
    restore() {
      if (confirm(this.$t('Are you sure you want to restore this plastic card order?'))) {
        this.$inertia.put(this.route('plastic-card-orders.restore', this.plasticCardOrder.id))
      }
    },
  },
}
</script>
