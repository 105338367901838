<template>
  <component
    :is="componentType"
    v-if="formFilled"
    :form-filled="formFilled"
    :is-last="isLast"
    :signature="signature"
    :signature-requests="signatureRequests"
  />
</template>
<script>
import Assignee from './Assignee.vue'
import Attendee from './Attendee.vue'
import Manager from './Manager.vue'
import User from './User.vue'

export default {
  components: {
    Assignee,
    Manager,
    User,
    Attendee,
  },
  props: {
    signature: Object,
    formFilled: Object,
    isLast: Boolean,
  },
  computed: {
    componentType() {
      switch (this.signature.signature_type) {
        case 'assignee':
          return 'Assignee'
        case 'manager':
          return 'Manager'
        case 'user':
          return 'User'
        case 'attendee':
          return 'Attendee'
        default:
          return 'User' // or any default component
      }
    },
    signatureRequests() {
      return this.formFilled.signature_requests.filter((signatureRequest) => {
        return signatureRequest.signature_type === this.signature.signature_type
      })
    },
  },
}
</script>
