<template>
  <div>
    <div class="flex items-center pb-4 md:flex-row">
      <div class="w-12 md:w-16">
        <Icon v-if="answeredCorrect && !showAnswers" class="h-10 w-10 fill-current text-ts-blue-600" name="checkmark" />
        <Icon v-if="!answeredCorrect && !showAnswers" class="h-10 w-10 fill-current text-ts-red-600" name="close" />
      </div>
      <div class="flex-1 lg:pr-16">
        <div class="flex font-semibold leading-normal text-ts-front-empty-state-header">
          <div>{{ question.number }}.</div>
          <div class="ml-2">
            {{ question.name }}
          </div>
        </div>
      </div>
    </div>

    <div v-for="(option, key) in question.options" :key="key" class="mt-2 text-ts-front-empty-state-header">
      <div class="flex flex-row items-center">
        <div class="w-12 lg:w-16">
          <div v-if="question.answer && showAnswers">
            <Icon
              v-if="question.answer.includes(key) && question.correct.includes(key)"
              class="h-10 w-10 fill-current text-ts-blue-600"
              name="checkmark"
            />
            <Icon
              v-else-if="!isSingleAnswerQuestion && (question.answer.includes(key) || question.correct.includes(key))"
              class="h-10 w-10 fill-current p-2 text-qualify-red-500"
              name="close"
            />
            <Icon
              v-else-if="isSingleAnswerQuestion && question.answer.includes(key) && !question.correct.includes(key)"
              class="h-10 w-10 fill-current p-2 text-qualify-red-500"
              name="close"
            />
          </div>
        </div>
        <div
          class="flex-1 rounded px-4 py-3 lg:mr-16"
          :class="{
            'bg-ts-gray-200': optionStatus[key] === 'none',
            'bg-ts-gray-quiz': optionStatus[key] === 'correct',
            'bg-qualify-red-100': optionStatus[key] === 'wrong',
          }"
        >
          <div v-if="isSingleAnswerQuestion">
            <label class="flex leading-normal">
              <div class="flex">
                <icon
                  v-if="question.answer && question.answer[0] == key"
                  class="h-6 w-6 flex-1 rounded-full bg-ts-gray-text-bold fill-white"
                  name="checkmark"
                />
                <div v-else class="h-6 w-6 flex-1 rounded-full border-2 border-ts-gray-300 bg-white" />
              </div>
              <div class="ml-4 flex-1 leading-5">{{ option }}</div>
            </label>
          </div>
          <div v-else>
            <div class="flex items-center">
              <Icon
                v-if="question.answer && question.answer.includes(key)"
                class="h-6 w-6 rounded bg-ts-gray-text-bold fill-white"
                name="checkmark"
              />
              <div v-else class="h-6 w-6 rounded border-2 border-ts-gray-300 bg-white" />
              <div class="ml-4 flex-1 leading-5">{{ option }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex">
      <div class="w-12 lg:w-16">&nbsp;</div>
      <div
        v-if="question.explanation && showAnswers"
        class="mt-3 flex flex-1 items-start rounded px-4 py-3 leading-normal lg:mr-16"
        :class="{
          'bg-qualify-red-100 text-qualify-red-500': !answeredCorrect,
          'bg-ts-front-field-dark text-ts-gray-text-bold': answeredCorrect,
        }"
      >
        <icon
          class="mr-4 mt-1 h-6 w-6 flex-shrink-0 rounded-full border-2 fill-current"
          :class="{
            'border-qualify-red-500': !answeredCorrect,
            'border-ts-gray-text-bold': answeredCorrect,
          }"
          name="info-2"
        />
        <span class="mr-2">{{ question.explanation }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon.vue'

export default {
  components: {
    Icon,
  },
  props: {
    question: Object,
    questionKey: Number,
    showAnswers: Boolean,
  },
  data: function () {
    return {
      answeredCorrect: this.compareAnswerWithCorrect(this.question.correct, this.question.answer),
    }
  },
  computed: {
    optionStatus: function () {
      return this.question.options.map((option, key) => {
        const isChecked = this.question.answer && this.question.answer.includes(key)
        const isCorrect = this.question.answer && this.question.correct.includes(key)

        if (this.showAnswers) {
          if (isChecked && !isCorrect) {
            return 'wrong'
          }
          if (isCorrect) return 'correct'
        } else {
          if (isChecked) {
            return 'correct'
          }
        }

        return 'none'
      })
    },
    isSingleAnswerQuestion: function () {
      return this.question.correct?.length === 1
    },
  },
  methods: {
    compareAnswerWithCorrect(correct, answer) {
      if (!answer || correct.length !== answer.length) {
        return false
      }

      return (
        correct.filter((value) => {
          return answer.includes(value)
        }).length === correct.length
      )
    },
  },
}
</script>
