<template>
  <div>
    <div class="mt-8 flex items-end justify-between">
      <h2 class="text-lg">{{ $t('Enrolment Transfers') }}</h2>
    </div>
    <div class="mt-4 overflow-x-auto rounded bg-white shadow">
      <table class="w-full">
        <thead>
          <tr>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Sent To') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Receiver User') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Number of Enrolments') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Created At') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Status') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="transfer in enrolmentTransfers"
            :key="transfer.id"
            class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
          >
            <td class="flex flex-col space-y-1 whitespace-nowrap border-t px-6 py-4">
              <div v-if="transfer.receiver.email">
                <icon class="mr-1 inline-block h-4 w-4" name="email" />
                <span>{{ transfer.receiver.email }}</span>
              </div>
              <div v-if="transfer.receiver.phone">
                <icon class="mr-1 inline-block h-4 w-4" name="phone" />
                <span>{{ transfer.receiver.phone }}</span>
              </div>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              {{ transfer.receiver.name }}
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">{{ transfer.enrolments_count }}</td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <show-date :timestamp="transfer.created_at" />
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4 capitalize">{{ transfer.transfer_status }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import Icon from '@/Shared/Icon.vue'
import ShowDate from '@/Shared/ShowDate.vue'

export default {
  components: {
    Icon,
    ShowDate,
  },
  props: {
    enrolmentTransfers: {
      type: Array,
      required: true,
    },
  },
}
</script>
