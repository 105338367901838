<template>
  <front-modal class="rounded text-base" :show="show" width="auto" @close="close">
    <div class="max-w-sm overflow-hidden rounded bg-white p-6 shadow md:p-10">
      <div class="mb-4 text-center">
        <h1 class="mt-4 text-3xl font-bold">{{ $t('Available Enrolment') }}</h1>
        <h3 class="mt-4 text-lg leading-snug">
          {{ $t('Would you like to assign this enrolment to yourself to begin training?') }}
        </h3>
      </div>
      <div class="flex items-center justify-center bg-white px-8 pb-6 pt-4">
        <front-loading-button :disabled="processing" type="button" @click="selfEnrol">
          {{ $t('Enrol Myself') }}
        </front-loading-button>
        <front-secondary-button class="ml-4" @click="close">
          {{ $t('Close') }}
        </front-secondary-button>
      </div>
    </div>
  </front-modal>
</template>

<script>
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontModal from '@/Shared/FrontModal.vue'
import FrontSecondaryButton from '@/Shared/FrontSecondaryButton.vue'
import { query } from '@/Utils/Helpers'

export default {
  components: {
    FrontLoadingButton,
    FrontModal,
    FrontSecondaryButton,
  },
  props: {
    show: Boolean,
    enrolment: Object,
  },
  data() {
    return {
      processing: false,
    }
  },
  methods: {
    query,
    close() {
      this.$emit('close:unassignedEnrolmentModal')
    },
    selfEnrol() {
      this.processing = true
      this.$inertia.visit(this.route('front.enrolments.enrol', this.enrolment))
    },
  },
}
</script>
