import Http from '@/Utils/Http'
import { reactive, ref, watch } from 'vue'
import { route } from 'ziggy-js'

const organizationId = ref(null)
const activeOrganization = ref(null)
const store = reactive({
  notes: {},
  isLoading: false,
  isFetchingNotes: false,
  hasMoreNotes: true,
  notesPage: 0,
})

const updateOrganizationId = (newOrganizationId) => {
  organizationId.value = newOrganizationId
}

const fetchOrganization = async () => {
  if (organizationId.value) {
    store.isLoading = true
    return await Http.get(route('organizations.show', organizationId.value))
      .then((response) => {
        activeOrganization.value = response.data.organization
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        store.isLoading = false
      })
  }
  activeOrganization.value = null
}

const fetchNotes = async () => {
  if (organizationId.value) {
    store.isFetchingNotes = true
    return await Http.get(route('notes', ['organization', organizationId.value, { page: store.notesPage + 1 }]))
      .then((response) => {
        store.notes = { ...store.notes, ...response.data.data }
        store.notesPage++
        store.hasMoreNotes = response.data.links?.next != null ? true : false
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        store.isFetchingNotes = false
      })
  }
}

const loadMoreNotes = async () => {
  if (store.hasMoreNotes && !store.isFetchingNotes) {
    fetchNotes()
  }
}
watch(organizationId, async () => {
  fetchOrganization()
  store.notesPage = 0
  store.notes = {}
  fetchNotes()
})

export { activeOrganization, fetchNotes, fetchOrganization, loadMoreNotes, store, updateOrganizationId }
