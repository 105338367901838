<template>
  <layout :title="$t('Create Certificate')">
    <div class="mb-8">
      <breadcrumb :previous-name="$t('Certificates')" :previous-url="route('certificate-templates')" />
    </div>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form enctype="multipart/form-data" @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" type="text" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.title_en" :error="form.errors.title_en" :label="$t('Title (EN)')" type="text" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.title_fr" :error="form.errors.title_fr" :label="$t('Title (FR)')" type="text" />
          </div>
          <div class="w-full pb-8 pr-6">
            <select-input v-model="form.type" :error="form.errors.type" :label="$t('Template Type')">
              <option :value="null">None</option>
              <option v-for="(label, type) in types" :key="type" :value="type">
                {{ label }}
              </option>
            </select-input>
          </div>
          <div class="w-full pb-8 pr-6">
            <checkbox-input
              v-model="form.plastic_card_ordering"
              :error="form.errors.plastic_card_ordering"
              :label="$t('Plastic Card Ordering')"
            />
          </div>
          <div class="w-full pb-8 pr-6">
            <textarea-input v-model="form.description" :error="form.errors.description" :label="$t('Description')" />
          </div>
          <div class="w-full pb-8 pr-6">
            <label for="preview-image-file">{{ $t('Preview Image:') }}</label>
            <file-input
              id="preview-image-file"
              accept="image/*"
              class="mt-4"
              :error="form.errors.preview_image"
              @input="form.preview_image = $event"
            />
          </div>
        </div>
        <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Validity') }}</div>
        <div class="flex flex-wrap px-8 py-4">
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input
              v-model="form.years_valid"
              :error="form.errors.years_valid"
              :label="$t('Years')"
              type="number"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <select-input v-model="form.validity_type" :error="form.errors.validity_type" :label="$t('Type')">
              <option v-for="(label, type) in validity_types" :key="type" :value="type">
                {{ label }}
              </option>
            </select-input>
          </div>
        </div>
        <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Template Files') }}</div>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <div>
              <label for="wall-template-file">{{ $t('Wall Template File') }}</label>
              <file-input
                id="wall-template-file"
                accept="image/*"
                class="mt-4"
                :error="form.errors.wall_template_file"
                @input="form.wall_template_file = $event"
              />
            </div>
          </div>
        </div>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <div>
              <label for="wall-template-file">{{ $t('Wall Template File (French)') }}</label>
              <file-input
                id="wall-template-file-fr"
                accept="image/*"
                class="mt-4"
                :error="form.errors.wall_template_file_fr"
                @input="form.wall_template_file_fr = $event"
              />
            </div>
          </div>
        </div>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <div>
              <label for="wallet-template-file">{{ $t('Wallet Template File') }}</label>
              <file-input
                id="wallet-template-file"
                accept="image/*"
                class="mt-4"
                :error="form.errors.wallet_template_file"
                @input="form.wallet_template_file = $event"
              />
            </div>
          </div>
        </div>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <div>
              <label for="wallet-template-file">{{ $t('Wallet Template File (French)') }}</label>
              <file-input
                id="wallet-template-file-fr"
                accept="image/*"
                class="mt-4"
                :error="form.errors.wallet_template_file_fr"
                @input="form.wallet_template_file_fr = $event"
              />
            </div>
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Create Certificate') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import CheckboxInput from '@/Shared/CheckboxInput.vue'
import FileInput from '@/Shared/FileInput.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: { CheckboxInput, Breadcrumb, FileInput, Layout, LoadingButton, SelectInput, TextInput, TextareaInput },
  props: {
    types: Object,
    validity_types: Object,
  },
  data() {
    return {
      form: useForm({
        name: '',
        type: null,
        title_en: '',
        title_fr: '',
        description: '',
        years_valid: null,
        plastic_card_ordering: null,
        validity_type: null,
        preview_image: null,
        wall_template_file: null,
        wall_template_file_fr: null,
        wallet_template_file: null,
        wallet_template_file_fr: null,
      }),
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('certificate-templates.store'))
    },
  },
}
</script>
