<template>
  <FrontModal class="rounded-xl px-4 font-nexa text-base" :show="show" width="auto" @close="$emit('close')">
    <div class="max-h-screen max-w-lg overflow-auto rounded bg-white p-6 shadow md:p-10">
      <h3 class="mb-5 mt-1 w-full border-b border-ts-gray-300 text-center text-xl font-semibold text-black">
        {{ $t('Import Users') }}
      </h3>
      <form @submit.prevent="submitForm">
        <div class="mb-16">
          <label class="block mb-2 text-sm font-medium text-gray-900" for="file_input">
            Upload User List (CSV File)
          </label>
          <input
            id="csv_file"
            ref="csvFile"
            accept=".csv"
            class="w-full text-gray-500 font-medium text-base bg-gray-100 file:cursor-pointer cursor-pointer file:border-0 file:py-2 file:px-4 file:mr-4 file:bg-qualify-red-900 file:hover:bg-qualify-red-600 file:text-white rounded-md"
            required
            type="file"
            @change="handleFileUpload"
          />
        </div>
        <div class="flex justify-between items-center">
          <a
            class="block mb-4 text-sm font-medium text-gray-900 underline"
            href="/sample/user_list.csv"
            rel="noopener noreferrer"
            target="_blank"
          >
            Sample CSV File
          </a>
          <button class="btn-red float-right" :disabled="!form.csv_file" type="submit">Upload</button>
        </div>
      </form>
    </div>
  </FrontModal>
</template>

<script>
import FrontModal from '@/Shared/FrontModal.vue'
import { useForm } from '@inertiajs/vue3'
import { reactive, ref } from 'vue'
import { route } from 'ziggy-js'

export default {
  components: {
    FrontModal,
  },
  props: {
    show: Boolean,
  },
  emits: ['close'],
  setup(props, { emit }) {
    const csvFile = ref(null)
    const errors = reactive([])
    const successMessage = ref('')

    const form = useForm({
      csv_file: null,
    })

    const handleFileUpload = (event) => {
      form.csv_file = event.target.files[0]
    }

    const submitForm = () => {
      form.post(route('front.manager.users.import'), {
        onSuccess: (page) => {
          successMessage.value = page.props.flash.success
          errors.length = 0
        },
        onError: (page) => {
          errors.length = 0
          if (page.props.errors.csv_errors) {
            errors.push(...page.props.errors.csv_errors)
          }
        },
        onFinish: () => {
          csvFile.value.value = null
          form.csv_file = null
          emit('close')
        },
      })
    }

    return {
      csvFile,
      form,
      handleFileUpload,
      submitForm,
      errors,
      successMessage,
    }
  },
}
</script>
