<template>
  <front-modal class="rounded text-base" :show="show" width="auto" @close="close">
    <div class="flex flex-wrap bg-white px-8 pt-8">
      <breadcrumb :name="$t('Create new group')" />
    </div>
    <div class="max-w-sm overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.name" :error="form.errors.name" label="Group name" />
          </div>
          <div class="w-full pb-8 pr-6">
            <textarea-input
              v-model="form.description"
              :error="form.errors.description"
              :label="$t('Description (optional)')"
              :rows="6"
            />
          </div>
        </div>
        <div class="flex items-center justify-center bg-white px-8 pb-8 pt-1">
          <front-secondary-button type="button" @click="close">{{ $t('Close') }}</front-secondary-button>
          <front-loading-button class="ml-4" :loading="form.processing" type="submit">
            {{ $t('Create') }}
          </front-loading-button>
        </div>
      </form>
    </div>
  </front-modal>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontModal from '@/Shared/FrontModal.vue'
import FrontSecondaryButton from '@/Shared/FrontSecondaryButton.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    FrontLoadingButton,
    FrontModal,
    FrontSecondaryButton,
    TextInput,
    TextareaInput,
  },
  props: {
    show: Boolean,
  },
  data() {
    return {
      form: useForm(
        {
          name: null,
          description: null,
        },
        'GroupCreateModal'
      ),
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    submit() {
      this.form.post(this.route('front.manager.groups.store'), {
        onSuccess: () => this.close(),
      })
    },
  },
}
</script>
