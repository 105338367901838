<template>
  <div class="flex w-full flex-col items-center">
    <div class="ml-3 flex w-full items-center">
      <button class="mr-3 flex items-center justify-center" @click="addLogic">
        <Icon class="mr-2 h-5 w-5 fill-current text-gray-500" name="preferences" />
        <span>{{ $t('Add logic') }}</span>
      </button>
      <div class="mr-3 text-sm">
        <front-checkbox-input v-model="optionsData.is_required" :label="$t('Required')" />
      </div>
      <div class="mr-3">
        <div class="inline-flex items-center">
          <label class="text-m">
            {{ $t('Format') }}:
            <button class="cursor-pointer text-qualify-red-600 underline focus:outline-none" @click="openFormModal">
              {{ optionsData.format.replace('[number]', '000001') }}
            </button>
          </label>
        </div>
      </div>
    </div>
    <div class="flex w-full items-center">
      <LogicTabs ref="logicTabs" v-model:model-value="logicsData">
        <LogicTab
          v-for="(logic, index) in logicsData"
          :id="`tab-${index + 1}`"
          :key="index"
          :name="logicNames[index]"
          @delete="removeLogic(index)"
        >
          {{ $t('If value') }}
          <select v-model="logic.operator" class="ml-2 mr-4">
            <option v-for="(value, key) in operators" :key="key" :value="key">{{ $t(value) }}</option>
          </select>
          <span>
            <input v-model="logic.value_one" placeholder="blank" type="text" />
          </span>
          <span class="pr-2">{{ $t('then') }}</span>
          <span>
            <TriggerAction v-model:action-required="logic.action_required" :logic-name="logicNames[index]" />
            <span class="pr-2">,</span>
            <TriggerEvidence v-model:evidence="logic.evidence" :logic-name="logicNames[index]" />
            <span class="px-2">or</span>
            <TriggerNotify v-model:notify="logic.notify" :logic-name="logicNames[index]" />
          </span>
        </LogicTab>
      </LogicTabs>
    </div>
    <form-modal :show="showFormModal" @close="closeFormModal">
      <template #content>
        <div class="px-4 py-8 sm:px-6">
          <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
            {{ $t('Document number format') }}
          </DialogTitle>
          <p class="mt-3 text-sm">
            Each inspection generates an individual number. You can customize the format below:
          </p>
        </div>
        <div class="border bg-white px-4 py-10 sm:px-6">
          <div class="">
            <p class="text-gray-600">{{ $t('Preview') }}</p>
            <p class="my-4 font-semibold">{{ sequence }}</p>
          </div>
          <hr class="my-8" />
          <div class="mt-3">
            <p class="text-sm">{{ $t('Document number format') }}</p>
            <front-text-input
              v-model="format"
              class="mr-2 mt-3 w-2/3 hover:border-red-500 focus:border-red-500 focus:outline-none"
              placeholder="[number]"
            />
            <p class="mt-3 text-sm">
              Customize the document number format by inserting text. For example, "Worksite WS[number]" generates
              "Worksite WS000001, Worksite WS000002, ...".
            </p>
          </div>
        </div>
        <div class="px-4 py-6 sm:px-6">
          <button
            class="mr-4 inline-flex items-center rounded-md bg-qualify-red-500 px-3 py-3 text-sm font-semibold leading-4 text-white shadow-sm hover:bg-qualify-red-600 focus:outline-none"
            type="button"
            @click="onSave()"
          >
            {{ $t('Save and apply') }}
          </button>
          <button
            class="mr-4 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-3 text-sm font-semibold leading-4 text-qualify-red-500 shadow-sm hover:bg-gray-50 focus:outline-none"
            type="button"
            @click="closeFormModal()"
          >
            {{ $t('Cancel') }}
          </button>
        </div>
      </template>
    </form-modal>
  </div>
</template>
<script>
import FormModal from './FormModal.vue'
import LogicTab from './LogicTab.vue'
import LogicTabs from './LogicTabs.vue'
import TriggerAction from './TriggerAction.vue'
import TriggerEvidence from './TriggerEvidence.vue'
import TriggerNotify from './TriggerNotify.vue'
import { getOperators } from '@/Enums/Operators'
import FrontCheckboxInput from '@/Shared/FrontCheckboxInput.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import Icon from '@/Shared/Icon.vue'
import { DialogTitle } from '@headlessui/vue'

export default {
  components: {
    FormModal,
    DialogTitle,
    LogicTab,
    LogicTabs,
    TriggerAction,
    TriggerEvidence,
    TriggerNotify,
    FrontCheckboxInput,
    FrontTextInput,
    Icon,
  },
  props: {
    options: Object,
    logics: Array,
  },
  emits: ['update:options', 'update:logics'],
  data() {
    return {
      operators: getOperators(['=', '!=']),
      optionsData: {
        is_required: this.options?.is_required ?? false,
        format: this.options?.format ?? '[number]',
      },
      logicsData: this.logics ?? [],
      showFormModal: false,
      format: this.modelValue?.format ?? '[number]',
    }
  },
  computed: {
    sequence() {
      let seq = ''
      if (this.format === '') {
        seq = [...Array(3).keys()].map((val) => String(val + 1).padStart(5, '0')).join(', ')
      } else if (this.format.indexOf('[number]') >= 0) {
        seq = [...Array(3).keys()]
          .map((val) => this.format.replace('[number]', String(val + 1).padStart(5, '0')))
          .join(', ')
      } else {
        seq = [...Array(3).keys()].map(() => this.format.replace('[number]', '')).join(', ')
      }
      return seq
    },
    logicNames() {
      return this.logicsData.map((logic) => {
        return logic ? `value ${this.operators[logic.operator]} ${logic.value_one ?? 'blank'}` : ''
      })
    },
    defaultLogicData() {
      return {
        operator: '=',
        value: null,
        action_required: false,
        evidence: {
          notes_required: false,
          media_required: false,
        },
      }
    },
  },
  watch: {
    optionsData: {
      handler: function () {
        this.$emit('update:options', this.optionsData)
      },
      deep: true,
    },
    logicsData: {
      handler: function () {
        this.$emit('update:logics', this.logicsData)
      },
      deep: true,
    },
  },
  methods: {
    openFormModal() {
      this.format = this.optionsData.format
      this.showFormModal = true
    },
    closeFormModal() {
      this.format = this.options.format
      this.showFormModal = false
    },
    onSave() {
      this.optionsData.format = this.format
      this.closeFormModal()
    },
    addLogic() {
      this.$refs.logicTabs.add(this.defaultLogicData)
    },
    removeLogic(index) {
      this.$refs.logicTabs.remove(index)
    },
  },
}
</script>
