<template>
  <modal class="text-base" :show="!hide" width="800px" @close="close">
    <div class="flex rounded-lg bg-white">
      <div class="w-48 flex-shrink-0 space-y-5 rounded-l-lg bg-gray-200 px-3 py-8">
        <introduce-menu-item :is-active="introduction.team" @click="show('team')">
          {{ $t('Team') }}
        </introduce-menu-item>
        <introduce-menu-item :is-active="introduction.codes" @click="show('codes')">
          {{ $t('Access Codes') }}
        </introduce-menu-item>
        <introduce-menu-item :is-active="introduction.training" @click="show('training')">
          {{ $t('Training') }}
        </introduce-menu-item>
        <introduce-menu-item :is-active="introduction.certificates" @click="show('certificates')">
          {{ $t('Certificates') }}
        </introduce-menu-item>
        <introduce-menu-item :is-active="introduction.reports" @click="show('reports')">
          {{ $t('Reports') }}
        </introduce-menu-item>
      </div>
      <div class="w-full rounded-r-lg px-12 py-8">
        <div v-if="introduction.team" class="space-y-4">
          <div class="text-sm text-gray-400">{{ $t('Easy Compliance Management') }}</div>
          <div class="flex items-center justify-between">
            <div class="font-heading text-xl font-bold">{{ $t('Online Learning') }}</div>
            <div class="rounded bg-ts-blue-200">
              <div class="p-2 text-sm text-ts-blue-500">1/5</div>
            </div>
          </div>
          <div>
            <video class="w-full" controls>
              <source
                src="https://touchstone.s3.us-east-2.amazonaws.com/files/U871NLVlJ5g877w2zkfDZK4pul7GQT6tJsLy8QE1.mkv"
              />
            </video>
          </div>
          <div class="text-sm leading-snug text-gray-500">
            {{
              $t(
                'Learn how to view your employee and groups, add new employees, create new groups, and manage their learning outcomes.'
              )
            }}
          </div>
          <div class="flex justify-end text-sm">
            <front-secondary-button class="text-gray-500" @click="disable">
              {{ $t('Do not show again') }}
            </front-secondary-button>
            <front-solid-button class="ml-4" @click="show('codes')">{{ $t('Next') }}</front-solid-button>
          </div>
        </div>
        <div v-else-if="introduction.codes" class="space-y-4">
          <div class="text-sm text-gray-400">{{ $t('Easy Compliance Management') }}</div>
          <div class="flex items-center justify-between">
            <div class="font-heading text-xl font-bold">{{ $t('Online Learning') }}</div>
            <div class="rounded bg-ts-blue-200">
              <div class="p-2 text-sm text-ts-blue-500">2/5</div>
            </div>
          </div>
          <div>
            <video class="w-full" controls>
              <source
                src="https://touchstone.s3.us-east-2.amazonaws.com/files/U871NLVlJ5g877w2zkfDZK4pul7GQT6tJsLy8QE1.mkv"
              />
            </video>
          </div>
          <div class="text-sm leading-snug text-gray-500">
            {{ $t('Learn how to add access codes to your account, view current codes and edit as needed.') }}
          </div>
          <div class="flex justify-end text-sm">
            <front-secondary-button class="text-gray-500" @click="disable">
              {{ $t('Do not show again') }}
            </front-secondary-button>
            <front-solid-button class="ml-4" @click="show('training')">{{ $t('Next') }}</front-solid-button>
          </div>
        </div>
        <div v-else-if="introduction.training" class="space-y-4">
          <div class="text-sm text-gray-400">{{ $t('Easy Compliance Management') }}</div>
          <div class="flex items-center justify-between">
            <div class="font-heading text-xl font-bold">{{ $t('Online Learning') }}</div>
            <div class="rounded bg-ts-blue-200">
              <div class="p-2 text-sm text-ts-blue-500">3/5</div>
            </div>
          </div>
          <div>
            <video class="w-full" controls>
              <source
                src="https://touchstone.s3.us-east-2.amazonaws.com/files/U871NLVlJ5g877w2zkfDZK4pul7GQT6tJsLy8QE1.mkv"
              />
            </video>
          </div>
          <div class="text-sm leading-snug text-gray-500">
            {{
              $t(
                'Learn how to view your current training courses, assign training to individuals or groups and manage classroom courses your employees are attending.'
              )
            }}
          </div>
          <div class="flex justify-end text-sm">
            <front-secondary-button class="text-gray-500" @click="disable">
              {{ $t('Do not show again') }}
            </front-secondary-button>
            <front-solid-button class="ml-4" @click="show('certificates')">{{ $t('Next') }}</front-solid-button>
          </div>
        </div>
        <div v-else-if="introduction.certificates" class="space-y-4">
          <div class="text-sm text-gray-400">{{ $t('Easy Compliance Management') }}</div>
          <div class="flex items-center justify-between">
            <div class="font-heading text-xl font-bold">{{ $t('Online Learning') }}</div>
            <div class="rounded bg-ts-blue-200">
              <div class="p-2 text-sm text-ts-blue-500">4/5</div>
            </div>
          </div>
          <div>
            <video class="w-full" controls>
              <source
                src="https://touchstone.s3.us-east-2.amazonaws.com/files/U871NLVlJ5g877w2zkfDZK4pul7GQT6tJsLy8QE1.mkv"
              />
            </video>
          </div>
          <div class="text-sm leading-snug text-gray-500">
            {{ $t('Learn how to view your certificates, check their status, report, and create custom expiry dates.') }}
          </div>
          <div class="flex justify-end text-sm">
            <front-secondary-button class="text-gray-500" @click="disable">
              {{ $t('Do not show again') }}
            </front-secondary-button>
            <front-solid-button class="ml-4" @click="show('reports')">{{ $t('Next') }}</front-solid-button>
          </div>
        </div>
        <div v-else-if="introduction.reports" class="space-y-4">
          <div class="text-sm text-gray-400">{{ $t('Easy Compliance Management') }}</div>
          <div class="flex items-center justify-between">
            <div class="font-heading text-xl font-bold">{{ $t('Online Learning') }}</div>
            <div class="rounded bg-ts-blue-200">
              <div class="p-2 text-sm text-ts-blue-500">5/5</div>
            </div>
          </div>
          <div>
            <video class="w-full" controls>
              <source
                src="https://touchstone.s3.us-east-2.amazonaws.com/files/U871NLVlJ5g877w2zkfDZK4pul7GQT6tJsLy8QE1.mkv"
              />
            </video>
          </div>
          <div class="text-sm leading-snug text-gray-500">
            {{
              $t(
                'Learn how to navigate the reports page and view a summary of training progress via courses, certificates, and access codes.'
              )
            }}
          </div>
          <div class="flex justify-end text-sm">
            <front-secondary-button class="text-gray-500" @click="disable">
              {{ $t('Do not show again') }}
            </front-secondary-button>
            <front-solid-button class="ml-4" @click="close">{{ $t('Finish') }}</front-solid-button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import IntroduceMenuItem from './IntroduceMenuItem.vue'
import FrontSecondaryButton from '@/Shared/FrontSecondaryButton.vue'
import FrontSolidButton from '@/Shared/FrontSolidButton.vue'
import Modal from '@/Shared/Modal.vue'
import _mapValues from 'lodash/mapValues'

export default {
  components: { IntroduceMenuItem, FrontSecondaryButton, FrontSolidButton, Modal },
  data() {
    return {
      hide: localStorage.getItem('hideManagerIntroduction') || false,
      introduction: {
        team: true,
        codes: false,
        training: false,
        certificates: false,
        reports: false,
      },
    }
  },
  methods: {
    disable() {
      localStorage.setItem('hideManagerIntroduction', true)

      this.close()
    },
    close() {
      this.hide = true
    },
    show(category) {
      this.introduction = _mapValues(this.introduction, () => false)

      this.introduction[category] = true
    },
  },
}
</script>
