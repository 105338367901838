<template>
  <styled-modal class="rounded text-base" max-width="md" :show="show" @close="close">
    <template #title>
      <h3 class="mt-4 text-center text-xl font-semibold leading-snug">{{ $t('Create New Action') }}</h3>
    </template>
    <template #content>
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.title" :error="form.errors.title" :label="$t('Title')" />
          </div>
          <div class="w-full pb-8 pr-6">
            <textarea-input v-model="form.description" :error="form.errors.description" :label="$t('Description')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <front-user-search-input
              v-model="form.assignee"
              :error="form.errors.description"
              :label="$t('Assigned To')"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <select-input
              v-model="form.priority"
              :error="form.errors.priority"
              :label="$t('Priority')"
              :options="priorityOptions"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <front-date-input
              v-model="form.start_at"
              :config="{ defaultHour: 7, defaultMinute: 0 }"
              :error="form.errors.start_at"
              :label="$t('Starts at')"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <front-date-input
              v-model="form.due_at"
              :config="{ defaultHour: 23, defaultMinute: 59 }"
              :error="form.errors.due_at"
              :label="$t('Due at')"
            />
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <front-secondary-button type="button" @click="close">{{ $t('Close') }}</front-secondary-button>
      <front-loading-button class="float-right" :loading="form.processing" type="submit" @click="submit">
        {{ $t('Create') }}
      </front-loading-button>
    </template>
  </styled-modal>
</template>

<script>
import FrontDateInput from '@/Shared/FrontDateInput.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontSecondaryButton from '@/Shared/FrontSecondaryButton.vue'
import FrontUserSearchInput from '@/Shared/FrontUserSearchInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import StyledModal from '@/Shared/StyledModal.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    TextareaInput,
    SelectInput,
    FrontSecondaryButton,
    FrontDateInput,
    FrontLoadingButton,
    FrontUserSearchInput,
    StyledModal,
    TextInput,
  },
  props: {
    user: Object,
    show: Boolean,
    priorityOptions: Object,
    actionable: { default: null, type: Object },
  },
  data() {
    return {
      form: useForm({
        assignee: this.user,
        title: null,
        description: null,
        priority: 1,
        start_at: null,
        due_at: null,
        form_answer_id: this.actionable?.id,
      }),
    }
  },
  methods: {
    close() {
      this.form.reset()
      this.$emit('close')
    },
    submit() {
      this.form
        .transform((data) => {
          return {
            ...data,
            assignee: data.assignee?.id,
          }
        })
        .post(this.route('front.manager.actions.store'), {
          preserveState: true,
          onSuccess: () => {
            this.close()
          },
        })
    },
  },
}
</script>
