<template>
  <front-layout :title="$t('Create Folder')">
    <div class="item-center mb-8 flex max-w-lg justify-between">
      <breadcrumb
        class="font-heading text-2xl font-semibold"
        :name="form.name ?? $t('Create')"
        :previous-name="$t('Folders')"
        :previous-url="route('front.admin.folders')"
      />
    </div>
    <form @submit.prevent="submit">
      <div class="max-w-lg overflow-hidden rounded bg-white shadow">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <front-text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" />
          </div>
          <div class="flex w-full items-center justify-end pb-6 pr-6 pt-2">
            <front-loading-button :loading="form.processing" @click="submit">
              {{ $t('Create Folder') }}
            </front-loading-button>
          </div>
        </div>
      </div>
    </form>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    FrontLayout,
    FrontLoadingButton,
    FrontTextInput,
  },
  data() {
    return {
      form: useForm({
        name: null,
      }),
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('front.admin.folders.store'))
    },
  },
}
</script>
