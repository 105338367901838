<template>
  <div class="w-full">
    <label>
      <span v-if="label" class="form-label">
        <span>{{ label }}</span>
        <span v-if="required" class="text-red-500">*</span>
      </span>
      <select
        :id="id"
        ref="input"
        v-model="selected"
        v-bind="$attrs"
        :class="['form-select', cssClass, error ? 'error' : '']"
        name="nameAttr"
        :required="required"
        @change="$emit('update:modelValue', selected)"
      >
        <slot v-if="Array.isArray(options)">
          <option v-for="(value, key) in options" :key="key" :value="value">{{ value }}</option>
        </slot>
        <slot v-else>
          <option v-for="(name, value) in options" :key="value" :value="value">
            {{ name }}
          </option>
        </slot>
      </select>
    </label>
    <div v-if="error" class="form-error">
      <template v-if="Array.isArray(error)">{{ error[0] }}</template>
      <template v-else>{{ error }}</template>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
    },
    cssClass: String,
    modelValue: [String, Number, Boolean],
    options: [Array, Object],
    label: String,
    error: [String, Array],
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      selected: this.modelValue,
    }
  },
  watch: {
    modelValue() {
      this.selected = this.modelValue
    },
    selected(selected) {
      this.$emit('update:modelValue', selected)
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
  },
}
</script>
