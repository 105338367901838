<template>
  <front-layout :title="$t('Create File')">
    <div class="item-center mb-8 flex max-w-2xl justify-between">
      <breadcrumb
        class="font-heading text-2xl font-semibold"
        :name="form.name ?? $t('Create New File')"
        :previous-name="folder.name"
        :previous-url="route('front.admin.folders.edit', folder)"
      />
    </div>
    <form @submit.prevent="submit">
      <!-- Manual Name -->
      <div class="max-w-2xl overflow-hidden rounded bg-white shadow">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <front-text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" />
          </div>
          <div class="w-full pb-8 pr-6">
            <front-file-upload-input
              v-model="form.file"
              :initial-value="form.file"
              label="Upload File"
              :max-items="1"
              :min-items="1"
              ref-name="createFolderFileUploadInput"
              :validation-rules="{
                maxSizeInKB: 1024 * 100,
              }"
            />
          </div>
          <div class="flex w-full items-center justify-end">
            <front-loading-button
              class="mb-6 mr-6"
              :disabled="!form.isDirty"
              :loading="form.processing"
              @click="submit"
            >
              {{ $t('Save') }}
            </front-loading-button>
          </div>
        </div>
      </div>
    </form>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontFileUploadInput from '@/Shared/FrontFileUploadInput.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    FrontFileUploadInput,
    FrontLayout,
    FrontLoadingButton,
    FrontTextInput,
  },
  props: {
    folder: Object,
  },
  data() {
    return {
      form: useForm({
        name: null,
        file: null,
      }),
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('front.admin.files.store', this.folder))
    },
  },
}
</script>
