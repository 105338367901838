<template>
  <front-layout title="Quizzes">
    <div v-if="fromExpiredAttempt">
      <notice class="max-w-lg" type="danger">{{ $t('Your last attempt has expired.') }}</notice>
    </div>
    <div class="mb-8">
      <breadcrumb
        :middle-name="course.name"
        :middle-url="route('front.contents', [enrolmentContent.enrolment_id])"
        :name="quiz.title"
        :previous-name="$t('Courses')"
        :previous-url="route('front.courses')"
      />
    </div>
    <div class="mt-6 grid gap-6 xl:grid-cols-3">
      <div class="col-span-2 col-start-1">
        <div class="flex justify-between rounded-lg bg-white p-6 text-lg shadow sm:p-8">
          <div class="flex w-full flex-col space-y-4 sm:flex-row sm:space-x-2 sm:space-y-0 lg:space-x-6">
            <div class="flex items-center justify-center sm:items-start">
              <icon
                class="h-8 w-8 flex-shrink-0 rounded-full p-1"
                :class="isTenantWorksite ? 'fill-qualify-red-500' : 'fill-blue-700'"
                name="quiz"
              />
            </div>
            <div class="flex flex-1 flex-col">
              <div class="mb-1">
                <span class="leading-normal">{{ quiz.title }}</span>
              </div>
              <div class="flex flex-col flex-wrap sm:flex-row sm:space-x-4">
                <div v-if="quiz.time_limit_minutes" class="mb-1 text-sm text-gray-600">
                  <strong>{{ $t('Time limit:') }}</strong>
                  {{ $t(':minutes minutes', { minutes: quiz.time_limit_minutes }) }}
                </div>
                <div class="mb-1 text-sm text-gray-600">
                  <strong>{{ $t('Passing Score:') }}</strong>
                  {{ quiz.passing_score }} / {{ quiz.total_score }}
                </div>
                <div v-if="maxAttemptsCount" class="mb-1 text-sm text-gray-600">
                  <strong>{{ $t('Attempts:') }}</strong>
                  <span class="mx-1">
                    {{
                      $t(':attempts_count / :max_attempts_count', {
                        attempts_count: attemptsCount,
                        max_attempts_count: maxAttemptsCount,
                      })
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <front-solid-button v-if="can.resumeAttempt" class="w-full sm:w-auto" @click.prevent="resumeQuiz">
                {{ $t('Return to Attempt') }}
              </front-solid-button>
              <front-solid-button v-else-if="can.startAttempt" class="w-full sm:w-auto" @click.prevent="startQuiz">
                {{ $t('Start') }}
              </front-solid-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-12 flex justify-between">
      <h2 class="text-xl font-semibold">{{ $t('Results') }}</h2>
    </div>
    <div class="mt-6 w-full rounded bg-white shadow xl:w-2/3">
      <div class="overflow-x-auto">
        <table class="w-full min-w-[500px]">
          <thead>
            <tr>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">{{ $t('Attempt') }}</th>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
                {{ $t('Status') }}
              </th>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold" colspan="2">
                {{ $t('Score') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="attempt in attempts"
              :key="attempt.id"
              class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
              @click="$inertia.get(route('front.quizzes.show', [enrolmentContent.id, quiz.id, attempt.id]))"
            >
              <td class="whitespace-nowrap border-t px-6 py-4">
                <div>{{ attempt.count }}</div>
              </td>
              <td class="whitespace-nowrap border-t px-6 py-4">
                <QuizStatus :status="attempt.status" />
              </td>
              <td class="whitespace-nowrap border-t px-6 py-4">
                <div v-if="attempt.score">{{ attempt.score }} / {{ attempt.total_score }}</div>
                <div v-else>-</div>
              </td>
              <td class="w-min border-t px-4 align-middle">
                <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
              </td>
            </tr>
            <tr v-if="attempts.length === 0">
              <td class="border-t px-6 py-4" colspan="3">{{ $t('No results found.') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontSolidButton from '@/Shared/FrontSolidButton.vue'
import Icon from '@/Shared/Icon.vue'
import Notice from '@/Shared/Notice.vue'
import QuizStatus from '@/Shared/Quiz/QuizStatus.vue'
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  name: 'QuizIndex',
  components: {
    QuizStatus,
    Breadcrumb,
    FrontLayout,
    FrontSolidButton,
    Icon,
    Notice,
  },
  props: {
    fromExpiredAttempt: Boolean,
    user: {
      type: Object,
      required: true,
    },
    course: {
      type: Object,
      required: true,
    },
    quiz: {
      type: Object,
      required: true,
    },
    enrolmentContent: {
      type: Object,
      required: true,
    },
    attempts: {
      type: Array,
      required: true,
    },
    maxAttemptsCount: {
      type: Number,
      default: null,
    },
    attemptsCount: {
      type: Number,
      required: true,
    },
    can: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isTenantWorksite,
  },
  methods: {
    startQuiz() {
      if (this.quiz.proctoring_enabled || this.quiz.time_limit_minutes) {
        this.$inertia.visit(this.route('front.quizzes.verify', [this.enrolmentContent.id, this.quiz.id]))
        return
      }
      this.createQuizAttempt()
    },
    resumeQuiz() {
      this.$inertia.post(this.route('front.quizzes.attempts.resume', [this.enrolmentContent, this.quiz]))
    },
    createQuizAttempt() {
      this.$inertia.post(this.route('front.quizzes.attempts.store', [this.enrolmentContent, this.quiz]))
    },
  },
}
</script>
