<template>
  <div
    class="ts-bg flex min-h-screen items-start justify-center p-6"
    tabindex="-1"
    @keyup.esc="$refs.goBackLink.click()"
  >
    <DynamicHead :title="pageTitle" />
    <div class="w-full max-w-xl">
      <div class="mt-12 overflow-hidden rounded-lg bg-white px-6 pb-6 pt-12 shadow-lg md:px-10">
        <h1 class="text-center text-xl font-bold">{{ $t('Check-in') }}</h1>
        <div class="mt-4 text-center text-xl text-gray-600">{{ class_.instructor_led_classroom.name }}</div>

        <div class="mt-4 flex w-full items-center justify-center">
          <search-filter
            v-model="searchValue"
            :filter-show="false"
            placeholder="Search by Check-in code"
            :show-submit-button="true"
            @reset="reset"
            @submit="submitSearch"
          />
        </div>

        <div class="-mr-6 mt-12 flex flex-wrap">
          <div v-for="student in studentsList" :key="student.id" class="flex w-full pb-6 pr-6 sm:w-1/2 lg:w-1/3">
            <InertiaLink
              class="group relative block w-full rounded px-6 py-8"
              :class="{
                'bg-gray-300 hover:bg-gray-600': student.name,
                'border-2 border-dashed border-gray-300 hover:border-gray-600 hover:bg-gray-100': !student.name,
                'bg-yellow-300': student.student_update_requests_count > 0,
              }"
              :href="student.url"
            >
              <div
                v-if="student.present || student.student_update_requests_count > 0"
                class="absolute right-0 top-0 -mr-3 -mt-3 flex h-10 w-10 items-center justify-center rounded-full bg-green-500"
              >
                <icon class="block h-5 w-5 fill-white" name="checkmark" />
              </div>
              <div
                class="text-xl font-medium"
                :class="
                  student.name ? 'text-gray-800 group-hover:text-white' : 'text-gray-600 group-hover:text-gray-700'
                "
              >
                <span v-if="student.name">{{ student.name }}</span>
                <span v-else>{{ $t('Reserved') }}</span>
              </div>
              <div
                class="mt-2 text-sm"
                :class="
                  student.name ? 'text-gray-600 group-hover:text-gray-300' : 'text-gray-600 group-hover:text-gray-700'
                "
              >
                <div>{{ $t('Purchased by :customer_name', { customer_name: student.customer.name }) }}</div>
                <div v-if="showOrganizationName(student)" class="mt-1">{{ student.customer.organization.name }}</div>
              </div>
            </InertiaLink>
          </div>
        </div>
      </div>
      <div class="mb-6 mt-12 text-center">
        <InertiaLink
          v-if="exit"
          ref="goBackLink"
          class="text-white hover:underline"
          :href="route('instructor.classes.show', class_.id)"
          replace
        >
          {{ $t('Exit check-in') }}
        </InertiaLink>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicHead from '@/Shared/DynamicHead.vue'
import Icon from '@/Shared/Icon.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'

export default {
  components: {
    DynamicHead,
    Icon,
    SearchFilter,
  },
  props: {
    exit: Boolean,
    class_: Object,
  },
  data() {
    return {
      searchValue: '',
      studentsList: this.class_.students,
    }
  },
  computed: {
    pageTitle() {
      return this.$t('Check-in') + ` | ${this.class_.instructor_led_classroom.name}  | ${this.$page.props.app.name}`
    },
  },
  methods: {
    showOrganizationName(student) {
      return (
        student.customer.organization &&
        student.customer.organization.name.toLowerCase() !== student.customer.name.toLowerCase() &&
        student.customer.organization.name.toLowerCase() !==
          this.$t(':customer_name Org', { customer_name: student.customer.name }).toLowerCase()
      )
    },
    submitSearch() {
      let tempStudents = this.class_.students
      if (this.searchValue != '' && this.searchValue) {
        tempStudents = tempStudents.filter((student) => {
          return student.enrolment?.check_in_code?.toUpperCase() == this.searchValue.toUpperCase()
        })
        this.studentsList = tempStudents
      } else {
        this.studentsList = this.class_.students
      }
    },
    reset() {
      this.searchValue = ''
      this.submitSearch()
    },
  },
}
</script>
