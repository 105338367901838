<template>
  <button
    class="group -ml-3 flex items-center border-l-4 px-8 py-2"
    :class="cssClass"
    @click="(e) => $emit('click', e)"
  >
    <slot />
  </button>
</template>
<script>
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  props: {
    isActive: Boolean,
  },
  emits: ['click'],
  computed: {
    isTenantWorksite,
    cssClass() {
      if (this.isActive) {
        return this.isTenantWorksite ? 'border-ts-red-500 text-ts-red-500' : 'border-blue-700 text-blue-700'
      }
      return 'border-gray-200 text-gray-800'
    },
  },
}
</script>
