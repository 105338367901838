<template>
  <front-layout :title="$t('Groups :') + ` ${group.name}`">
    <div class="flex flex-col xl:flex-row xl:justify-between">
      <div class="flex flex-col lg:mb-8 lg:w-1/2">
        <div class="flex items-center space-x-2">
          <breadcrumb :name="group.name" />
          <icon v-if="group.archived_at" class="ml-2 h-6 w-6 flex-shrink-0 fill-gray-400" name="archive" />
          <front-outline-button v-if="can.editGroup" class="btn-sm" @click="showEditGroup()">
            <span>{{ $t('Edit') }}</span>
          </front-outline-button>
        </div>
        <div v-if="group.description" class="mb-4 max-w-md pt-2 text-left lg:mb-0">
          <p class="pb-2 leading-normal">{{ group.description }}</p>
        </div>
      </div>
      <div class="mb-6 flex w-full items-center justify-between xl:justify-end">
        <front-search-filter
          v-model="form.search"
          class="flex w-full flex-col sm:flex-row lg:w-auto xl:max-w-sm"
          :class="{ 'mr-4': can.addMember }"
          :filter-show="false"
          @reset="reset"
        />
        <front-solid-button v-if="can.addMember" @click="showAssignUser()">
          <span>{{ $t('Add Member') }}</span>
        </front-solid-button>
      </div>
    </div>
    <div class="overflow-x-auto overflow-y-hidden rounded bg-white shadow-lg">
      <table class="front-table">
        <thead>
          <tr>
            <th>{{ $t('Name') }}</th>
            <th>{{ $t('Email') }}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-if="users.data.length === 0">
            <td class="border-t px-6 py-4" colspan="5">{{ $t('No members yet.') }}</td>
          </tr>
          <tr v-for="user in users.data" :key="user.id">
            <td>
              <div class="flex items-center space-x-1">
                <InertiaLink
                  class="text-ts-gray-text-bold hover:underline focus:underline"
                  :href="route('front.manager.users.show', user)"
                >
                  <span class="whitespace-nowrap py-4 font-semibold text-ts-gray-text-bold">{{ user.name }}</span>
                </InertiaLink>
                <span v-if="user.organization_role === 'groupManager'" class="rounded bg-gray-300 px-2 py-1 text-xs">
                  {{ user.organization_role_label }}
                </span>
                <icon v-if="user.deleted_at" class="ml-2 h-3 w-3 flex-shrink-0 fill-gray-400" name="trash" />
                <icon v-if="user.archived_at" class="ml-2 h-3 w-3 flex-shrink-0 fill-gray-400" name="archive" />
              </div>
            </td>
            <td>{{ user.email }}</td>
            <td class="w-min">
              <button class="font-semibold hover:underline" @click="removeUserFromGroup(user)">
                {{ $t('Remove') }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <front-pagination v-if="users && users.links" :links="users.links" />
    <edit-modal :group="group" :show="showEditModal" @close="hideEditGroup" />
    <assign-users-modal v-if="can.addMember" :group="group" :show="showAssignUserModal" @close="hideAssignUser" />
  </front-layout>
</template>

<script>
import AssignUsersModal from './AssignUsersModal.vue'
import EditModal from './EditModal.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontOutlineButton from '@/Shared/FrontOutlineButton.vue'
import FrontPagination from '@/Shared/FrontPagination.vue'
import FrontSearchFilter from '@/Shared/FrontSearchFilter.vue'
import FrontSolidButton from '@/Shared/FrontSolidButton.vue'
import Icon from '@/Shared/Icon.vue'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    EditModal,
    AssignUsersModal,
    Breadcrumb,
    FrontLayout,
    FrontOutlineButton,
    FrontPagination,
    FrontSearchFilter,
    FrontSolidButton,
    Icon,
  },
  props: {
    filters: Object,
    organization: Object,
    group: Object,
    users: Object,
    usersCount: Number,
    managersCount: Number,
    can: Object,
  },
  data() {
    return {
      showEditModal: false,
      showAssignUserModal: false,
      form: {
        search: this.filters.search,
        role: this.filters.role,
        archived: this.filters.archived,
        deleted: this.filters.deleted,
      },
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)

        this.$inertia.get(
          this.route('front.manager.groups.show', this.group.id),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
  },
  methods: {
    showAssignUser() {
      this.showAssignUserModal = true
    },
    hideAssignUser() {
      this.showAssignUserModal = false
    },
    showEditGroup() {
      this.showEditModal = true
    },
    hideEditGroup() {
      this.showEditModal = false
    },
    removeUserFromGroup(user) {
      this.$inertia.delete(this.route('front.manager.groups.members.destroy', [this.group, user]))
    },
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
