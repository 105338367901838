<template>
  <layout :title="user.name">
    <div class="mb-8">
      <breadcrumb
        :middle-name="user.name"
        :middle-url="route('users.edit', user.id)"
        :name="$t('Versions')"
        :previous-name="$t('Users')"
        :previous-url="route('users')"
      />
    </div>
    <div class="flex flex-col flex-wrap">
      <div class="overflow-hidden rounded bg-white shadow">
        <div class="flex w-full items-center justify-between px-8 py-6">
          <div class="w-full text-xl font-medium">{{ $t('user Versions') }}</div>
        </div>
        <div class="flex w-full flex-wrap border-t">
          <table class="w-full">
            <thead>
              <tr>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('User') }}</th>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Diff') }}</th>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Date') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="revisions.data.length === 0">
                <td class="border-t px-6 py-4" colspan="3">{{ $t('No revisions found.') }}</td>
              </tr>
              <tr v-for="(revision, index) in revisions.data" :key="index" class="hover:bg-gray-100 focus:bg-gray-100">
                <td class="whitespace-nowrap border-t px-6 py-4">
                  <span v-if="revision.user">{{ `${revision.user.display_name}` }}</span>
                  <span else>-</span>
                </td>
                <td class="whitespace-nowrap border-t px-6 py-4">
                  <dl class="space-y-2">
                    <template v-for="key in Object.keys(revision.before)" :key="key">
                      <dt class="font-bold capitalize">{{ key }}</dt>
                      <dl class="space-x-1">
                        <span class="bg-red-100 p-1 line-through">{{ revision.before[key] || '(empty)' }}</span>
                        <span class="bg-green-100 p-1">{{ revision.after[key] || '(empty)' }}</span>
                      </dl>
                    </template>
                  </dl>
                </td>
                <td class="whitespace-nowrap border-t px-6 py-4">
                  <app-datetime :utc-date="revision.created_at" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <pagination :links="revisions.links" preserve-scroll />
    </div>
  </layout>
</template>

<script>
import AppDatetime from '@/Shared/AppDatetime.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'

export default {
  components: {
    AppDatetime,
    Breadcrumb,
    Layout,
    Pagination,
  },
  props: {
    user: Object,
    revisions: Object,
  },
}
</script>
