<template>
  <layout :title="$t('Add Certificate Settings')">
    <breadcrumb-admin :path="breadcrumb" />
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-1/2 pb-8 pr-6">
            <select-input
              v-model="form.certificate_template_id"
              :disabled="certificate_templates.length === 0"
              :error="form.errors.certificate_template_id"
              :label="$t('Certificate Template')"
            >
              <option :value="null" />
              <option
                v-for="certificate_template in certificate_templates"
                :key="certificate_template.id"
                :value="certificate_template.id"
              >
                {{ certificate_template.name }}
              </option>
            </select-input>
          </div>
          <div class="w-1/2 pb-8 pr-6">
            <text-input
              v-model="form.years_valid"
              :disabled="certificate_templates.length === 0"
              :error="form.errors.years_valid"
              :label="$t('Years Valid')"
            />
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Add Certificate Settings') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import BreadcrumbAdmin from '@/Shared/BreadcrumbAdmin.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    BreadcrumbAdmin,
    Layout,
    LoadingButton,
    SelectInput,
    TextInput,
  },
  props: {
    user: Object,
    certificate_templates: Array,
  },
  data() {
    return {
      form: useForm({
        certificate_template_id: null,
        years_valid: null,
      }),
      breadcrumb: [
        {
          name: this.$t('Users'),
          url: this.route('users'),
        },
        {
          name: this.user.name,
          url: this.route('users.edit', this.user.id),
        },
        {
          name: `${this.$t('Create')}`,
        },
      ],
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('users.certificate-templates.store', this.user))
    },
  },
}
</script>
