<template>
  <front-layout :title="$t('Certificate Signature Request')">
    <div class="flex flex-col justify-between">
      <div class="mb-8">
        <breadcrumb :name="$t('Signature Request')" />
      </div>
      <div class="flex w-full flex-col items-start xl:flex-row">
        <profile-card :user="user" />
        <div class="flex w-full flex-col xl:w-3/4 xl:pl-16">
          <p class="mb-4 font-sans text-lg font-semibold leading-relaxed text-darkGray-900">
            "{{ certificateSignatureRequest.sender.name }}" {{ $t('has requested your signature for') }}
            <br />
            "{{ certificate.name }}" {{ $t('Certificate') }}
          </p>
          <div class="mb-4 flex w-full max-w-sm">
            <plastic-card-preview
              v-if="certificate.plastic_card_preview_format"
              :background-url="certificate.plastic_card_preview_format.background_url"
              class="block max-w-sm rounded-xl bg-gray-100 px-0 shadow"
              :crop="certificate.plastic_card_preview_format.crop"
              :pdf-fields="certificate.pdf_fields"
              :pdf-images="certificate.pdf_images"
              :pdf-settings="certificate.plastic_card_preview_format.pdf_settings"
            />
            <div v-else class="w-full">
              <show-plastic-card-data :certificate="certificate" />
              <div class="h-6 pt-3 font-semibold tracking-wider text-ts-blue-600" />
            </div>
          </div>
          <div class="mb-4 mt-2 w-full max-w-sm lg:mt-0">
            <front-signature-pad :error="form.errors.signature_image" :show="true" @updated="signatureUpdate" />
          </div>

          <div class="mt-6 flex space-x-4">
            <front-outline-button @click.prevent="reject">Decline</front-outline-button>
            <front-loading-button :loading="form.loading" @click.prevent="submit">
              {{ $t('Approve and Sign') }}
            </front-loading-button>
          </div>
        </div>
      </div>
    </div>
    <front-qr-code-popup :show="showQrCodeComponent" @close="popupClose" />
  </front-layout>
</template>

<script>
import ProfileCard from './ProfileCard.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontOutlineButton from '@/Shared/FrontOutlineButton.vue'
import FrontQrCodePopup from '@/Shared/FrontQrCodePopup.vue'
import FrontSignaturePad from '@/Shared/FrontSignaturePad.vue'
import PlasticCardPreview from '@/Shared/PlasticCardPreview.vue'
import ShowPlasticCardData from '@/Shared/ShowPlasticCardData.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    ProfileCard,
    Breadcrumb,
    FrontLayout,
    FrontLoadingButton,
    FrontOutlineButton,
    FrontQrCodePopup,
    FrontSignaturePad,
    PlasticCardPreview,
    ShowPlasticCardData,
  },
  props: {
    tenant: Object,
    certificateSignatureRequest: Object,
    certificate: Object,
    user: Object,
  },
  data() {
    return {
      showQrCodeComponent: true,
      form: useForm(
        {
          signature_image: null,
        },
        'CertificateSignatureRequest'
      ),
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('front.manager.certificate-signature-request.accept', this.certificateSignatureRequest))
    },
    reject() {
      this.$inertia.post(
        this.route('front.manager.certificate-signature-request.decline', this.certificateSignatureRequest)
      )
    },
    signatureUpdate(signature) {
      this.form.signature_image = signature
    },
    popupClose() {
      this.showQrCodeComponent = false
    },
  },
}
</script>
