<template>
  <div class="ts-bg flex min-h-screen items-start justify-center p-6">
    <div class="w-full max-w-sm">
      <div class="mt-20 rounded-lg bg-white shadow-lg">
        <div class="px-10 py-6">
          <div class="text-xl text-gray-600">
            <form @submit.prevent="submit">
              <text-input v-model="form.code" autocomplete="off" :error="form.errors.code" :label="$t('Code')" />
              <div class="flex justify-end pt-4">
                <button class="btn-blue" type="submit">{{ $t('Verify') }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    TextInput,
  },
  props: {
    url: String,
  },
  data() {
    return {
      form: useForm({
        code: '',
      }),
    }
  },
  methods: {
    submit() {
      this.form.post(this.url, this.form)
    },
  },
}
</script>
