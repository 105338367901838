<template>
  <div v-if="title">
    <title>{{ title }}</title>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  mounted() {
    document.title = this.title
  },
  watch: {
    title(newValue) {
      document.title = newValue
    },
  },
}
</script>
