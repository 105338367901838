<template>
  <div id="certificate-wrapper" :style="{ transform: `scale(${zoom}`, 'transform-origin': 'top left' }">
    <div id="inner-wrap">
      <div :class="isLandscape ? 'landscape' : 'portrait'">
        <template v-if="backgroundUrl">
          <img alt="" :src="backgroundUrl" />
          <pdf-component
            v-for="(settings, key) in form.pdf_settings"
            :key="key"
            v-model="form.pdf_settings[key]"
            :highlight="highlight"
            :key-name="key"
            :pdf-fields="pdfFields"
          >
            <template v-if="pdfValues && pdfValues[key]">{{ pdfValues[key] }}</template>
            <template v-else>{{ key }}</template>
          </pdf-component>
        </template>
        <template v-else>
          <span style="font-size: 30px">{{ $t('Please upload a background.') }}</span>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import PdfComponent from './PdfComponent.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: { PdfComponent },
  props: {
    backgroundUrl: String,
    isLandscape: Boolean,
    pdfFields: Object,
    pdfFieldSetting: Object,
    pdfValues: Object,
    highlight: {
      type: Boolean,
      default: true,
    },
    zoom: String,
  },
  data() {
    return {
      form: useForm({
        pdf_settings: this.pdfFieldSetting,
      }),
    }
  },
}
</script>
<style scoped>
.landscape {
  width: 11in;
  height: 8.5in;
}

.portrait {
  width: 8.5in;
  height: 11in;
}

#inner-wrap {
  width: 100%;
  height: 100%;
}

#inner-wrap img {
  height: 100%;
  position: relative;
  z-index: 1;
}
</style>
