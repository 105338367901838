import Http from '@/Utils/Http'
import { reactive, ref, watch } from 'vue'
import { route } from 'ziggy-js'

const userId = ref(null)
const activeUser = ref(null)
const store = reactive({
  notes: {},
  isLoading: false,
  isFetchingNotes: false,
  hasMoreNotes: true,
  notesPage: 0,
})

const updateUserId = (newUserId) => {
  userId.value = newUserId
}

const fetchUser = async () => {
  if (userId.value) {
    store.isLoading = true
    return await Http.get(route('users.show', userId.value))
      .then((response) => {
        activeUser.value = response.data.user
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        store.isLoading = false
      })
  }
  activeUser.value = null
}

const fetchNotes = async () => {
  if (userId.value) {
    store.isFetchingNotes = true
    return await Http.get(route('notes', ['user', userId.value, { page: store.notesPage + 1 }]))
      .then((response) => {
        store.notes = { ...store.notes, ...response.data.data }
        store.notesPage++
        store.hasMoreNotes = response.data.links?.next != null ? true : false
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        store.isFetchingNotes = false
      })
  }
}

const loadMoreNotes = async () => {
  if (store.hasMoreNotes && !store.isFetchingNotes) {
    fetchNotes()
  }
}
watch(userId, async () => {
  fetchUser()
  store.notesPage = 0
  store.notes = {}
  fetchNotes()
})

export { activeUser, fetchNotes, fetchUser, loadMoreNotes, store, updateUserId }
