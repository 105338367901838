<template>
  <layout :title="$t('Edit Enrollment')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('enrolments')">
        {{ $t('Enrolments') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Edit') }}
    </h1>
    <div class="max-w-sm">
      <archived-message v-if="enrollment.archived_at" class="mb-6">
        {{ $t('This enrolment has been archived.') }}
      </archived-message>
      <cancelled-message v-if="enrollment.cancelled_at" class="mb-6">
        {{ $t('This enrolment subscription has been cancelled.') }}
      </cancelled-message>
      <deleted-message v-if="enrollment.deleted_at" class="mb-6">
        {{ $t('This enrolment has been deleted.') }}
      </deleted-message>
      <notice v-else-if="enrollment.has_pending_enrolment_transfer" type="danger">
        {{ $t('This enrolment is pending for transfer.') }}
      </notice>
      <notice v-else-if="enrollment.course.has_instructor_led_training && !enrollment.class_" type="danger">
        {{ $t('There is no class selected for this enrolment.') }}
      </notice>
    </div>
    <div class="flex flex-wrap">
      <div class="flex w-full flex-col justify-between gap-x-6 md:flex-row">
        <div class="w-full md:w-1/2">
          <div class="overflow-hidden rounded bg-white shadow">
            <form @submit.prevent="submit">
              <div class="-mb-8 -mr-6 flex flex-wrap p-8">
                <div class="w-1/2 pb-8 pr-6">
                  <text-input disabled :label="$t('Course')" :model-value="enrollment.course.name" />
                </div>
                <div class="w-1/2 pb-8 pr-6">
                  <select-input
                    v-if="enrollment.course.locales"
                    v-model="form.locale"
                    :error="form.errors.locale"
                    :label="$t('Language')"
                  >
                    <option
                      v-for="(localeKey, localeValue) in enrollment.course.locales"
                      :key="localeValue"
                      :value="localeValue"
                    >
                      {{ localeKey }}
                    </option>
                  </select-input>
                </div>
                <div v-if="enrollment.course.has_instructor_led_training" class="w-full pb-8 pr-6">
                  <div class="item-center flex justify-between pb-2">
                    <label>{{ $t('Class:') }}</label>
                    <div>
                      <input
                        id="private-classes-only"
                        v-model="access"
                        false-value="public"
                        true-value="private"
                        type="checkbox"
                      />
                      <label for="private-classes-only">{{ $t('Private Classes') }}</label>
                    </div>
                  </div>
                  <dynamic-search-input
                    v-model="class_"
                    :disabled="!enrollment.course"
                    :error="form.errors.class_id"
                    :search-by="['location.name', 'location.client_site', 'room', 'dates', 'available_seats']"
                    track-by="id"
                    :url="
                      route('classes.search', {
                        course: enrollment.course ? enrollment.course.id : null,
                        access,
                        locale: form.locale,
                      })
                    "
                  >
                    <div v-if="class_" class="flex items-center justify-between">
                      <div class="truncate">{{ class_.formatted_name }}</div>
                    </div>
                    <template #option="{ option, selected }">
                      <div class="flex items-center justify-between">
                        <div class="text-xs" :class="{ 'text-gray-600': !selected, 'text-white': selected }">
                          {{ option.formatted_name }}
                        </div>
                      </div>
                    </template>
                  </dynamic-search-input>
                </div>
                <div class="w-1/2 pb-8 pr-6">
                  <div>
                    <label class="form-label">{{ $t('Owner:') }}</label>
                    <InertiaLink
                      v-if="enrollment.owner"
                      class="text-blue-600 underline"
                      :href="route('users.edit', enrollment.owner.id)"
                    >
                      {{ enrollment.owner.name }}
                    </InertiaLink>
                    <span v-else>-</span>
                  </div>
                </div>
                <div class="w-1/2 pb-8 pr-6">
                  <div>
                    <label class="form-label">{{ $t('User:') }}</label>
                    <InertiaLink
                      v-if="enrollment.user"
                      class="text-blue-600 underline"
                      :href="route('users.edit', enrollment.user.id)"
                    >
                      {{ enrollment.user.name }}
                    </InertiaLink>
                    <span v-else>-</span>
                  </div>
                </div>
                <div class="w-1/2 pb-8 pr-6">
                  <text-input
                    disabled
                    :label="$t('Enroller')"
                    :model-value="enrollment.enroller ? enrollment.enroller.name : ''"
                  />
                </div>
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <radio-input
                    v-model="form.payment_status"
                    :data="{ Paid: $t('Paid'), Pending: $t('Pending') }"
                    :error="form.errors.payment_status"
                    :label="$t('Payment Status')"
                    :resolve="(value) => value"
                    type="radio"
                  />
                </div>
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <text-input disabled :label="$t('Paid Via')" :model-value="enrollment.payment_gateway?.name" />
                </div>
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <label class="mb-2 block">{{ $t('Status:') }}</label>
                  <front-course-status :status="enrollment.status" />
                </div>
                <div v-if="enrollment.can.displayMolStatus" class="w-full pb-8 pr-6 lg:w-1/2">
                  <label class="mb-2 block">{{ $t('MOL Submission:') }}</label>
                  <front-mol-submission-status :enrolment="enrollment" />
                </div>
                <div v-if="enrollment.payment_order_number" class="w-1/2 pb-8 pr-6">
                  <text-input
                    disabled
                    :label="$t('Order Number')"
                    :model-value="'#' + enrollment.payment_order_number"
                  />
                </div>
                <div v-if="enrollment.payment_gateway?.value == 'Invoice'" class="w-1/2 pb-8 pr-6">
                  <text-input disabled :label="$t('Invoice Number')" :model-value="enrollment.invoice_number" />
                </div>
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <text-input
                    v-if="enrollment.finished_at"
                    disabled
                    :label="$t('Finished At')"
                    :value="enrollment.finished_at"
                  />
                </div>
              </div>
              <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
                <div class="flex">
                  <button
                    v-if="enrollment.deleted_at"
                    class="text-red-500 hover:underline"
                    tabindex="-1"
                    type="button"
                    @click="restore"
                  >
                    {{ $t('Restore') }}
                  </button>
                  <button v-else class="text-red-500 hover:underline" tabindex="-1" type="button" @click="destroy">
                    {{ $t('Delete') }}
                  </button>
                  <button
                    v-if="enrollment.archived_at"
                    class="ml-4 text-red-500 hover:underline"
                    tabindex="-1"
                    type="button"
                    @click="restoreArchived"
                  >
                    {{ $t('Restore Archived') }}
                  </button>
                  <button v-else class="ml-4 text-red-500 hover:underline" tabindex="-1" type="button" @click="archive">
                    {{ $t('Archive') }}
                  </button>
                </div>
                <div class="flex pr-4">
                  <loading-button
                    class="btn-blue"
                    :disabled="enrollment.deleted_at"
                    :loading="form.processing"
                    type="submit"
                  >
                    {{ $t('Update') }}
                  </loading-button>
                </div>
              </div>
            </form>
          </div>
          <model-timestamps :model="enrollment" />
          <div v-if="enrollment.reset_at" class="ml-2 mt-1 text-xs text-gray-600">
            {{ $t('Reset on :reset_at', { reset_at: enrollment.reset_at }) }}
          </div>
        </div>
        <div class="mt-8 w-full md:mt-0 md:w-1/2">
          <tabs
            class="overflow-hidden rounded shadow"
            nav-class="flex items-center text-gray-700 text-sm font-bold bg-gray-400"
            nav-item-active-class="bg-gray-300"
            nav-item-class="p-4"
          >
            <tab v-if="enrollment.owner && enrollment.owner.organization_id" :name="$t('Enrolment')">
              <form @submit.prevent="enrol">
                <div class="w-full px-6 pb-5 pt-5">
                  <user-search-input
                    v-if="!enrollment.user"
                    v-model="user"
                    :error="enrolForm.errors.user_id"
                    :label="$t('User')"
                    :organization-id="enrollment.owner.organization_id"
                  />
                  <text-input v-else disabled="true" :model-value="enrollment.user.name" />
                </div>
                <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
                  <button v-if="enrollment.can.unenrol" class="text-red-500 hover:underline" @click.prevent="unenrol">
                    {{ $t('Unenrol') }}
                  </button>
                  <span v-else class="text-gray-500">{{ $t('Unenrol') }}</span>
                  <loading-button
                    class="btn-blue"
                    :disabled="!enrollment.can.enrol"
                    :loading="enrolForm.processing"
                    type="submit"
                  >
                    {{ $t('Enrol') }}
                  </loading-button>
                </div>
              </form>
            </tab>
            <tab v-else :name="$t('Enrolment')">
              <div v-if="enrollment.user" class="w-full px-6 pb-3 pt-4">
                <text-input disabled="true" :model-value="enrollment.user.name" />
              </div>
              <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
                <template v-if="enrollment.user">
                  <button v-if="enrollment.can.unenrol" class="text-red-500 hover:underline" @click.prevent="unenrol">
                    {{ $t('Unenrol') }}
                  </button>
                  <div v-else />
                  <button class="btn-blue" :disabled="true" type="submit">{{ $t('Self Enrol') }}</button>
                </template>
                <template v-else>
                  <InertiaLink
                    v-if="enrollment.owner"
                    class="hover:underline"
                    :href="route('users.enrolment-transfers.step1.create', enrollment.owner)"
                  >
                    {{ $t('Send to someone') }}
                  </InertiaLink>
                  <InertiaLink
                    as="button"
                    class="btn-blue"
                    :href="route('enrolments.self-assign', enrollment)"
                    method="put"
                  >
                    {{ $t('Self Enrol') }}
                  </InertiaLink>
                </template>
              </div>
            </tab>
            <tab v-if="enrollment.certificate" :name="$t('Certificate')">
              <div class="flex w-full flex-col px-8 py-6">
                <div class="my-1 flex">
                  <div class="w-24">{{ $t('Number') }}</div>
                  <div>
                    <span class="mr-2">:</span>
                    <span>{{ enrollment.certificate.certificate_number }}</span>
                  </div>
                </div>
                <div class="my-1 flex">
                  <div class="w-24">{{ $t('User') }}</div>
                  <div>
                    <span class="mr-2">:</span>
                    <span>{{ enrollment.certificate.user.name }}</span>
                  </div>
                </div>
                <div class="my-1 flex">
                  <div class="w-24">{{ $t('Issue Date') }}</div>
                  <div class="flex items-center">
                    <span class="mr-2">:</span>
                    <show-date :timestamp="enrollment.certificate.created_at" />
                  </div>
                </div>
                <div class="my-1 flex">
                  <div class="w-24">{{ $t('Expiry Date') }}</div>
                  <div class="flex items-center">
                    <span class="mr-2">:</span>
                    <show-date :timestamp="enrollment.certificate.expires_at" />
                    <svg
                      v-if="enrollment.certificate.expires_at"
                      class="ml-2 size-4 hover:cursor-pointer hover:text-blue-500"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      @click="resetExpiryDate"
                    >
                      <path
                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <template v-if="enrollment.certificate.archived_at">
                  <div class="my-1 flex">
                    <div class="w-24">{{ $t('Archived At') }}</div>
                    <div class="flex items-center">
                      <span class="mr-2">:</span>
                      <show-date :timestamp="enrollment.certificate.archived_at" />
                    </div>
                  </div>
                </template>
                <template v-if="enrollment.certificate.deleted_at">
                  <div class="my-1 flex">
                    <div class="w-24">{{ $t('Deleted At') }}</div>
                    <div class="flex items-center">
                      <span class="mr-2">:</span>
                      <show-date :timestamp="enrollment.certificate.deleted_at" />
                    </div>
                    <button class="mx-2 text-red-500 hover:underline" @click="certificateRestoreDelete()">
                      {{ $t('Restore') }}
                    </button>
                  </div>
                </template>
                <div class="my-1 flex">
                  <div class="w-24">{{ $t('Status') }}</div>
                  <div class="flex items-center">
                    <span class="mr-2">:</span>
                    <front-certificate-status class="-ml-2" :status="enrollment.certificate.status" />
                  </div>
                </div>
              </div>
              <div class="w-full px-8 pb-6">
                <user-search-input
                  v-model="certificateTransferForm.user"
                  :error="certificateTransferForm.errors.user"
                  :label="$t('Certificate Transfer')"
                  :organization-id="enrollment.owner.organization_id"
                />
              </div>
              <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
                <div>
                  <button
                    v-if="!enrollment.certificate.archived_at"
                    class="text-red-500 hover:underline"
                    @click="certificateArchive()"
                  >
                    {{ $t('Archive') }}
                  </button>
                  <button v-else class="text-red-500 hover:underline" @click="certificateUnArchive()">
                    {{ $t('unArchive') }}
                  </button>
                </div>
                <button class="btn-blue" :disabled="!certificateTransferForm.user" @click.prevent="transferCertificate">
                  {{ $t('Transfer Certificate') }}
                </button>
              </div>
            </tab>
            <tab v-if="enrollment.subscriptionPlanAccess" :name="$t('Subscription')">
              <div class="flex w-full flex-col px-8 py-6">
                <div class="my-1 flex">
                  <div class="w-24">{{ $t('Plan') }}</div>
                  <div class="flex items-center">
                    <span class="mr-2">:</span>
                    <span>{{ enrollment.subscriptionPlanAccess.subscriptionPlan.title }}</span>
                  </div>
                </div>
                <div class="my-1 flex">
                  <div class="w-24">{{ $t('Assigned At') }}</div>
                  <div class="flex items-center">
                    <span class="mr-2">:</span>
                    <show-date :timestamp="enrollment.subscriptionPlanAccess.assigned_at" />
                  </div>
                </div>
                <div v-if="enrollment.subscriptionPlanAccess.cancelled_at" class="my-1 flex">
                  <div class="w-24">{{ $t('Cancelled At') }}</div>
                  <div class="flex items-center">
                    <span class="mr-2">:</span>
                    <show-date :timestamp="enrollment.subscriptionPlanAccess.cancelled_at" />
                  </div>
                </div>
                <div v-if="enrollment.subscriptionPlanAccess.archived_at" class="my-1 flex">
                  <div class="w-24">{{ $t('Archived At') }}</div>
                  <div class="flex items-center">
                    <span class="mr-2">:</span>
                    <show-date :timestamp="enrollment.subscriptionPlanAccess.archived_at" />
                  </div>
                </div>
                <div v-if="enrollment.subscriptionPlanAccess.deleted_at" class="my-1 flex">
                  <div class="w-24">{{ $t('Deleted At') }}</div>
                  <div class="flex items-center">
                    <span class="mr-2">:</span>
                    <show-date :timestamp="enrollment.subscriptionPlanAccess.deleted_at" />
                  </div>
                </div>
              </div>
              <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
                <div class="flex">
                  <InertiaLink
                    class="btn-blue"
                    :href="route('subscription-plan-access.edit', enrollment.subscriptionPlanAccess.id)"
                  >
                    {{ $t('Open') }}
                  </InertiaLink>
                </div>
              </div>
            </tab>
            <tab :name="$t('Notes')">
              <div class="px-6" :class="{ 'py-4': enrollment.notes.length > 0 }">
                <div v-for="note in enrollment.notes" :key="note.id" class="py-3">
                  <div
                    class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 leading-normal transition duration-150 ease-in-out"
                    type="text"
                  >
                    <span v-html="note.content" />
                  </div>
                  <div class="mt-2 flex items-center justify-between">
                    <span class="text-gray-700">{{ note.user.name || '-' }}</span>
                    <span class="text-gray-700">{{ note.created_at }}</span>
                  </div>
                </div>
              </div>
              <div class="w-full px-6 py-3">
                <textarea-input
                  v-model="noteForm.content"
                  :autosize="true"
                  :error="noteForm.errors.content"
                  :placeholder="$t('New note') + '..'"
                  :rows="3"
                />
              </div>
              <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
                <div class="flex">
                  <button class="btn-blue" @click.stop="addNote">{{ $t('Add Note') }}</button>
                </div>
              </div>
            </tab>
            <tab v-if="enrollment.versionSummary.last_modified" :name="$t('Versions')">
              <div class="flex flex-wrap p-8">
                <div class="grid w-full grid-cols-1 gap-2 text-sm">
                  <div class="flex space-x-1">
                    <span><app-datetime class="text-base" :utc-date="enrollment.versionSummary.last_modified" /></span>
                    <span>
                      <InertiaLink
                        class="text-base text-blue-400 underline"
                        :href="route('enrolments.versions', enrollment.id)"
                      >
                        {{ $t('see more') }}
                      </InertiaLink>
                    </span>
                  </div>
                </div>
              </div>
            </tab>
          </tabs>
        </div>
      </div>
    </div>
    <students :students="students" />
    <enrollment-contents
      :enrollment-contents="enrollmentContents"
      :enrolment-contents-outdated="enrollment.is_outdated"
      :mark-all-compeleted-enabled="canBeCompleted"
      :mark-as-failed-enabled="enrollment.can.fail"
      @mark-complete="markComplete"
      @mark-failed="markFailed"
      @reset-contents="resetContents"
    />
    <enrollment-transfers
      v-if="enrollment.transfers && enrollment.transfers.length > 0"
      :enrolment-transfers="enrollment.transfers"
    />
  </layout>
</template>

<script>
import EnrollmentContents from './EnrollmentContents.vue'
import EnrollmentTransfers from './EnrollmentTransfers.vue'
import Students from './Students.vue'
import AppDatetime from '@/Shared/AppDatetime.vue'
import ArchivedMessage from '@/Shared/ArchivedMessage.vue'
import CancelledMessage from '@/Shared/CancelledMessage.vue'
import DeletedMessage from '@/Shared/DeletedMessage.vue'
import DynamicSearchInput from '@/Shared/DynamicSearchInput.vue'
import FrontCertificateStatus from '@/Shared/FrontCertificateStatus.vue'
import FrontCourseStatus from '@/Shared/FrontCourseStatus.vue'
import FrontMolSubmissionStatus from '@/Shared/FrontMolSubmissionStatus.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import ModelTimestamps from '@/Shared/ModelTimestamps.vue'
import Notice from '@/Shared/Notice.vue'
import RadioInput from '@/Shared/RadioInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import ShowDate from '@/Shared/ShowDate.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import UserSearchInput from '@/Shared/UserSearchInput.vue'
import { useForm } from '@inertiajs/vue3'
import { Tab, Tabs } from 'vue3-tabs-component'

export default {
  components: {
    EnrollmentContents,
    Students,
    EnrollmentTransfers,
    Tabs,
    Tab,
    AppDatetime,
    ArchivedMessage,
    CancelledMessage,
    DeletedMessage,
    DynamicSearchInput,
    FrontCertificateStatus,
    FrontCourseStatus,
    FrontMolSubmissionStatus,
    Layout,
    LoadingButton,
    ModelTimestamps,
    Notice,
    RadioInput,
    SelectInput,
    ShowDate,
    TextInput,
    TextareaInput,
    UserSearchInput,
  },
  props: {
    enrollment: Object,
    enrollmentContents: Array,
    students: Array,
  },
  data() {
    return {
      class_: this.enrollment.class_,
      user: this.enrollment.user,
      access: this.enrollment.class_?.private ? 'private' : 'public',
      form: useForm({
        locale: this.enrollment.locale,
        class_id: this.enrollment.class_?.id,
        payment_status: this.enrollment.payment_status,
        note: null,
        enrollment_contents: null,
      }),
      enrolForm: useForm({
        user_id: this.enrollment.user?.id,
      }),
      noteForm: useForm({
        content: null,
      }),
      certificateTransferForm: useForm({
        user: this.enrollment.user,
      }),
    }
  },
  computed: {
    canBeCompleted() {
      return !this.enrollment.archived_at && !this.enrollment.deleted_at && !!this.enrollment.user?.id
    },
  },
  watch: {
    user(newUser) {
      this.enrolForm.user_id = newUser?.id
    },
    class_(newClass) {
      this.form.class_id = newClass ? newClass.id : null
    },
    access() {
      this.class_ = null
    },
    'form.locale'() {
      this.class_ = null
    },
  },
  methods: {
    submit() {
      this.form.put(this.route('enrolments.update', this.enrollment))
    },
    enrol() {
      if (confirm(this.$t('Are you sure you want to update the user for this enrolment?'))) {
        this.enrolForm.post(this.route('enrolments.enrol', this.enrollment))
      }
    },
    unenrol() {
      if (
        confirm(this.$t('Are you sure you want to unenrol? The existing certificate will be deleted when you unenrol.'))
      ) {
        this.enrolForm.delete(this.route('enrolments.unenrol', this.enrollment))
      }
    },
    markComplete(enrollmentContents) {
      this.form.enrollment_contents = enrollmentContents
      if (this.canBeCompleted) {
        this.form.put(this.route('enrolments.completed', this.enrollment))
      }
    },
    markFailed() {
      if (confirm('Are you sure you want to fail this enrolment? All enrolment contents will be marked as failed.')) {
        this.form.put(this.route('enrolments.fail', this.enrollment))
      }
    },
    resetContents() {
      if (
        confirm(this.$t('Are you sure you want to reset the contents in this enrolment? All progress will be reset.'))
      ) {
        this.form.put(this.route('enrolments.reset-contents', this.enrollment))
      }
    },
    destroy() {
      if (
        confirm(
          this.$t('Are you sure you want to delete this enrollment?\nEnrollment progress will all be deleted if any.')
        )
      ) {
        this.$inertia.delete(this.route('enrolments.destroy', this.enrollment.id))
      }
    },
    restore() {
      if (confirm(this.$t('Are you sure you want to restore this enrollment?'))) {
        this.$inertia.put(this.route('enrolments.restore', this.enrollment.id))
      }
    },
    archive() {
      if (confirm(this.$t('Are you sure you want to archive this enrollment?'))) {
        this.$inertia.post(this.route('enrolments.archive.store', this.enrollment.id))
      }
    },
    restoreArchived() {
      if (confirm(this.$t('Are you sure you want to restore this archived enrollment?'))) {
        this.$inertia.delete(this.route('enrolments.archive.delete', this.enrollment.id))
      }
    },
    certificateArchive() {
      if (confirm(this.$t('Are you sure you want to archive this certificate?'))) {
        this.$inertia.post(this.route('certificates.archive', this.enrollment.certificate?.id))
      }
    },
    certificateRestoreDelete() {
      if (confirm(this.$t('Are you sure you want to restore this certificate?'))) {
        this.$inertia.post(this.route('certificates.delete.restore', this.enrollment.certificate?.id))
      }
    },
    certificateUnArchive() {
      if (confirm(this.$t('Are you sure you want to restore this archived certificate?'))) {
        this.$inertia.delete(this.route('certificates.unarchive', this.enrollment.certificate?.id))
      }
    },
    addNote() {
      this.noteForm.post(this.route('notes.store', { noteTypesEnum: 'enrolment', id: this.enrollment.id }), {
        onSuccess: () => (this.noteForm.content = null),
      })
    },
    transferCertificate() {
      this.certificateTransferForm
        .transform((data) => ({
          user: data?.user?.id,
        }))
        .post(this.route('certificates.transfer', this.enrollment.certificate.id))
    },
    resetExpiryDate() {
      if (
        this.enrollment.certificate?.expires_at &&
        confirm(this.$t("Are you sure you want to reset this certificate's expiry date?"))
      ) {
        this.$inertia.post(this.route('certificates.reset-expiry', this.enrollment.certificate?.id))
      }
    },
  },
}
</script>
