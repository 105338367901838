<template>
  <div class="w-full max-w-lg overflow-hidden rounded-lg border bg-white shadow">
    <div class="flex flex-col p-4">
      <p v-if="defaultPaymentMethod" class="mb-1 leading-tight text-darkGray-800">
        {{
          $t('Your payment method is card ending in :last_four_digits', {
            last_four_digits: defaultPaymentMethod.card.last4,
          })
        }}
      </p>
      <div class="flex items-center">
        <div>
          <front-select-input
            v-model="form.defaultPaymentMethodId"
            class="w-max-md"
            :error="form.errors.defaultPaymentMethodId"
          >
            <option v-for="paymentMethod in paymentMethods" :key="paymentMethod.id" :value="paymentMethod.id">
              <template v-if="paymentMethod.type === 'card'">
                {{
                  $t('xxxx xxxx xxxx xxxx :last_four_digits (:card_brand)', {
                    last_four_digits: paymentMethod.card.last4,
                    card_brand: paymentMethod.card.brand,
                  })
                }}
              </template>
            </option>
            <option value="add-new-payment">{{ $t('Add New Payment') }}</option>
          </front-select-input>
        </div>
        <button v-if="shouldShowSubmitButton" class="btn-blue ml-4" @click="submit">{{ $t('Submit') }}</button>
      </div>
    </div>
    <add-payment-method-modal
      :organization="organization"
      :show="isAddPaymentMethodModal"
      @close="hideAddPaymentMethodModal"
    />
  </div>
</template>
<script>
import AddPaymentMethodModal from '../PaymentMethods/AddPaymentMethodModal.vue'
import FrontSelectInput from '@/Shared/FrontSelectInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: { AddPaymentMethodModal, FrontSelectInput },
  props: {
    organization: Object,
    defaultPaymentMethod: Object,
    paymentMethods: Array,
  },
  data() {
    return {
      isAddPaymentMethodModal: false,
      form: useForm({
        defaultPaymentMethodId: this.defaultPaymentMethod?.id,
      }),
    }
  },
  computed: {
    shouldShowSubmitButton() {
      if (this.form.defaultPaymentMethodId !== 'add-new-payment') {
        return this.defaultPaymentMethod?.id !== this.form.defaultPaymentMethodId
      }
      return false
    },
  },
  watch: {
    'form.defaultPaymentMethodId'(newValue) {
      if (newValue === 'add-new-payment') {
        this.showAddPaymentMethodModal()
      }
    },
  },
  methods: {
    showAddPaymentMethodModal() {
      this.isAddPaymentMethodModal = true
    },
    hideAddPaymentMethodModal() {
      if (this.form.defaultPaymentMethodId == 'add-new-payment') {
        this.form.defaultPaymentMethodId = this.defaultPaymentMethod?.id
      }
      this.isAddPaymentMethodModal = false
    },
    submit() {
      this.form.post(this.route('organizations.payment-methods.default.store', this.organization.id))
    },
  },
}
</script>
