<template>
  <layout :title="$t('Upload e-Learning')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('elearnings')">
        {{ $t('e-Learning') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Upload') }}
    </h1>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name (Internal)')" />
          </div>
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.title" :error="form.errors.title" :label="$t('Title')" />
          </div>
          <div class="w-full pb-8 pr-6">
            <textarea-input
              v-model="form.description"
              :autosize="true"
              :error="form.errors.description"
              :label="$t('Description')"
              rows="2"
            />
          </div>
          <div v-if="isCreatingRelatedRecord" class="w-full pb-8 pr-6">
            <select-input v-model="form.locale" :error="form.errors.locale" :label="$t('Locale')">
              <option v-for="(locale, key) in locales" :key="key" :value="locale">
                {{ key }}
              </option>
            </select-input>
          </div>
          <div class="w-full pb-8 pr-6">
            <file-input accept="zip,application/zip" :error="form.errors.elearning" @input="form.elearning = $event" />
          </div>
          <div class="-mt-8 w-full pr-6">
            <div v-if="form.progress" class="rounded-full border">
              <div class="h-2 w-min rounded-full bg-green-500" :style="{ width: form.progress.percentage + '%' }" />
            </div>
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Upload e-Learning') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import FileInput from '@/Shared/FileInput.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    FileInput,
    Layout,
    LoadingButton,
    SelectInput,
    TextInput,
    TextareaInput,
  },
  props: {
    locales: Object,
    related_contentable_id: Number,
  },
  data() {
    return {
      form: useForm({
        name: null,
        title: null,
        description: null,
        elearning: null,
        locale: 'fr',
        related_contentable_id: this.related_contentable_id,
      }),
    }
  },
  computed: {
    isCreatingRelatedRecord() {
      let urlParams = new URLSearchParams(window.location.search)
      return urlParams.has('contentable')
    },
  },
  methods: {
    submit() {
      this.form.post(this.route('elearnings.store'))
    },
  },
}
</script>
