<template>
  <tab class="flex items-center justify-between p-3">
    <div>
      <slot />
    </div>
    <button :aria-label="$t('Delete')" @click="handleDelete">
      <Icon class="text-qualify-gray-500 h-3 w-3 fill-current" name="close" />
    </button>
  </tab>
</template>
<script>
import Icon from '@/Shared/Icon.vue'
import { Tab } from 'vue3-tabs-component'

export default {
  components: {
    Tab,
    Icon,
  },
  emits: ['delete'],
  methods: {
    handleDelete() {
      this.$emit('delete')
    },
  },
}
</script>
