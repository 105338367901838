<template>
  <layout title="Subscription Banners">
    <div class="mb-8">
      <breadcrumb name="Subscription Banners" />
    </div>
    <div class="mb-6 flex items-center justify-between">
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
        <label class="mt-4 block text-gray-800">{{ $t('Deleted:') }}</label>
        <select v-model="form.deleted" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="with">{{ $t('With Deleted') }}</option>
          <option value="only">{{ $t('Only Deleted') }}</option>
        </select>
      </search-filter>
      <div class="hidden lg:flex">
        <InertiaLink class="btn-blue ml-3" :href="route('subscription-banners.create')">
          <span>{{ $t('Create Subscription Banner') }}</span>
        </InertiaLink>
      </div>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow">
      <SimpleTable>
        <template #head>
          <TableHead>{{ $t('Name') }}</TableHead>
          <TableHead>{{ $t('Title') }}</TableHead>
          <TableHead>{{ $t('Organization Subscription Plan Type') }}</TableHead>
          <TableHead>{{ $t('Show On Page(s)') }}</TableHead>
          <TableHead>{{ $t('Column Layout') }}</TableHead>
          <TableHead>{{ $t('Status') }}</TableHead>
          <TableHead />
        </template>
        <TableRow v-if="subscription_banners.data.length === 0">
          <TableData colspan="6">{{ $t('No subscription banners found.') }}</TableData>
        </TableRow>
        <TableRow
          v-for="subscriptionBanner in subscription_banners.data"
          :key="subscriptionBanner.id"
          :clickable="true"
          @click="$inertia.visit(route('subscription-banners.edit', subscriptionBanner.id))"
        >
          <TableData>
            <div class="flex items-center">
              <div class="max-w-xs truncate leading-tight">
                {{ subscriptionBanner.name }}
              </div>
              <deleted-badge v-if="subscriptionBanner.deleted_at" />
            </div>
          </TableData>
          <TableData>
            <div class="max-w-xs truncate leading-tight">
              {{ subscriptionBanner.title }}
            </div>
          </TableData>
          <TableData>
            <div class="max-w-xs truncate leading-tight">
              {{ subscriptionBanner.show_for_subscription_plan_type }}
            </div>
          </TableData>
          <TableData>
            {{ subscriptionBanner.show_on_page_routes }}
          </TableData>
          <TableData>
            {{ subscriptionBanner.column_type }}
          </TableData>
          <TableData>
            <div
              v-if="subscriptionBanner.is_active"
              class="inline-block w-20 rounded-full bg-green-900 bg-opacity-8 px-1 py-1 text-center text-sm font-semibold text-green-900"
            >
              {{ $t('Active') }}
            </div>
            <div
              v-else
              class="inline-block w-20 rounded-full bg-red-900 bg-opacity-8 px-1 py-1 text-center text-sm font-semibold text-red-900"
            >
              {{ $t('Inactive') }}
            </div>
          </TableData>
          <TableData class="w-min align-middle">
            <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
          </TableData>
        </TableRow>
      </SimpleTable>
    </div>
    <pagination :links="subscription_banners.links" />
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import _debounce from 'lodash/debounce'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'

export default {
  components: {
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
    Breadcrumb,
    DeletedBadge,
    Icon,
    Layout,
    Pagination,
    SearchFilter,
  },
  props: {
    filters: Object,
    subscription_banners: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        archived: this.filters.archived,
        deleted: this.filters.deleted,
      },
    }
  },
  watch: {
    form: {
      handler: _debounce(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(
          this.route('subscription-banners'),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveState: true,
            replace: true,
            only: ['subscription_banners'],
          }
        )
      }, 300),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
