<template>
  <layout :title="$t('Enrollments')">
    <h1 class="mb-8 text-xl font-bold">{{ $t('Enrollments') }}</h1>
    <div class="mb-6 flex items-center justify-between">
      <search-filter
        v-model="form.search"
        class="mr-4 w-full max-w-md"
        :show-submit-button="true"
        @reset="reset"
        @submit="submitSearch"
      >
        <label class="block text-gray-800">{{ $t('Course:') }}</label>
        <select v-model="form.course" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="course in courses" :key="course.id" :value="course.id">{{ course.name }}</option>
          <optgroup v-if="archivedCourses.length > 0" :label="$t('Archived Courses')">
            <option v-for="course in archivedCourses" :key="course.id" :value="course.id">{{ course.name }}</option>
          </optgroup>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Between:') }}</label>
        <date-input v-model="form.start_date" class="mt-1 w-full" />
        <date-input v-model="form.end_date" class="mt-1 w-full" />
        <label class="mt-4 block text-gray-800">{{ $t('Status:') }}</label>
        <select v-model="form.status" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="(label, status) in statuses" :key="status" :value="status">{{ label }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Paid via:') }}</label>
        <select v-model="form.payment_gateway" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="(label, key) in paymentGateways" :key="key" :value="key">
            {{ label }}
          </option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Deleted:') }}</label>
        <select v-model="form.deleted" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="wiTableHead">{{ $t('WiTableHead Deleted') }}</option>
          <option value="only">{{ $t('Only Deleted') }}</option>
        </select>
        <label class="mt-4 block text-gray-800">
          <input v-model="is_unassigned_enrollments_checked" type="checkbox" />
          {{ $t('Only unassigned enrollments') }}
        </label>

        <template #searchByField>
          <select v-model="form.searchBy" class="form-select w-auto border-none px-3 py-3 focus:ring">
            <option value="name">{{ $t('by Name') }}</option>
            <option value="email">{{ $t('by Email') }}</option>
            <option value="username">{{ $t('by Username') }}</option>
          </select>
        </template>
      </search-filter>
      <div class="mb-6 flex items-center justify-between">
        <InertiaLink class="btn-blue" :href="route('enrolments.create')">
          <span>{{ $t('Create') }}</span>
          <span class="hidden md:inline">{{ $t('Enrollment') }}</span>
        </InertiaLink>
        <button v-if="!isSelectingEnrolments" class="btn-blue ml-2" type="button" @click="isSelectingEnrolments = true">
          {{ $t('Mark As Delete') }}
        </button>
        <button v-else class="btn-blue ml-2" type="button" @click="cancelEnrolmentsSelection">
          {{ $t('Cancel') }}
        </button>
        <InertiaLink
          as="button"
          class="btn-blue ml-2"
          :disabled="enrolments.data.length === 0"
          :href="route('enrolments.export', form)"
        >
          <span>{{ $t('Export to CSV') }}</span>
        </InertiaLink>
      </div>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow">
      <SimpleTable>
        <template #head>
          <TableHead v-if="isSelectingEnrolments">
            <row-select-checkbox id="selectAll" v-model="selectAll" @update:model-value="selectAllUpdated" />
          </TableHead>
          <TableHead>{{ $t('Course') }}</TableHead>
          <TableHead>{{ $t('Owner') }}</TableHead>
          <TableHead>{{ $t('User') }}</TableHead>
          <TableHead>{{ $t('Enrolled by') }}</TableHead>
          <TableHead>{{ $t('Code') }}</TableHead>
          <TableHead>{{ $t('Payment') }}</TableHead>
          <TableHead>{{ $t('Status') }}</TableHead>
          <TableHead>{{ $t('MOL') }}</TableHead>
          <TableHead colspan="2">{{ $t('Created at') }}</TableHead>
        </template>
        <TableRow v-if="enrolments.data.length === 0">
          <TableData colspan="5">{{ $t('No enrolments found.') }}</TableData>
        </TableRow>
        <TableRow
          v-for="enrolment in enrolments.data"
          :key="enrolment.id"
          :clickable="true"
          @click="$inertia.visit(route('enrolments.edit', enrolment.id))"
        >
          <TableData v-if="isSelectingEnrolments" @click.stop="">
            <row-select-checkbox
              v-if="canDeleteEnrollment(enrolment)"
              :id="'checkbox-' + enrolment.id"
              v-model="formDeleteEnrolments.enrolments"
              :value="enrolment.id"
            />
          </TableData>
          <TableData>{{ enrolment.course.name }}</TableData>
          <TableData>
            <span v-if="enrolment.owner">{{ enrolment.owner.name }}</span>
            <span v-else>-</span>
          </TableData>
          <TableData>
            <span v-if="enrolment.user">{{ enrolment.user.name }}</span>
            <span v-else>-</span>
          </TableData>
          <TableData>
            <span v-if="enrolment.enroller">{{ enrolment.enroller.name }}</span>
            <span v-else>-</span>
          </TableData>
          <TableData>{{ enrolment.access_code }}</TableData>
          <TableData :border-padding="false">
            <front-enrolment-payment-status :enrolment="enrolment" />
            <deleted-badge v-if="enrolment.deleted_at" />
          </TableData>
          <TableData :border-padding="false">
            <front-course-status v-if="enrolment.payment_status === 'Paid'" :status="enrolment.status" />
          </TableData>
          <TableData :border-padding="false">
            <front-mol-status :status="enrolment.skillspass_status" :submitted="enrolment.submitted" />
          </TableData>
          <TableData>
            <show-date :timestamp="enrolment.created_at" />
          </TableData>
          <TableData class="w-min align-middle">
            <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
          </TableData>
        </TableRow>
      </SimpleTable>
    </div>
    <div class="flex justify-between">
      <button
        v-if="isSelectingEnrolments"
        class="btn-blue mt-4"
        :class="{ disabled: formDeleteEnrolments.enrolments.length == 0 }"
        :disabled="formDeleteEnrolments.enrolments.length == 0"
        @click="deleteEnrolments"
      >
        {{ $t('Delete Enrollments') }}
      </button>
      <div v-else class="mt-4" />

      <pagination :links="enrolments.links" :preserve-state="true" />
    </div>
  </layout>
</template>

<script>
import DateInput from '@/Shared/DateInput.vue'
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import FrontCourseStatus from '@/Shared/FrontCourseStatus.vue'
import FrontEnrolmentPaymentStatus from '@/Shared/FrontEnrolmentPaymentStatus.vue'
import FrontMolStatus from '@/Shared/FrontMolStatus.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import RowSelectCheckbox from '@/Shared/RowSelectCheckbox.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import ShowDate from '@/Shared/ShowDate.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import { useForm } from '@inertiajs/vue3'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'

export default {
  components: {
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
    DateInput,
    DeletedBadge,
    FrontCourseStatus,
    FrontEnrolmentPaymentStatus,
    FrontMolStatus,
    Icon,
    Layout,
    Pagination,
    RowSelectCheckbox,
    SearchFilter,
    ShowDate,
  },
  props: {
    filters: Object,
    statuses: Object,
    courses: Array,
    archivedCourses: Array,
    enrolments: Object,
    paymentGateways: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        searchBy: this.filters.searchBy || 'name',
        course: this.filters.course,
        start_date: this.filters.start_date,
        end_date: this.filters.end_date,
        payment_gateway: this.filters.payment_gateway,
        status: this.filters.status,
        unassigned_enrollments: this.filters.unassigned_enrollments,
      },
      selectAll: false,
      formDeleteEnrolments: useForm({
        enrolments: [],
      }),
      isSelectingEnrolments: false,
      is_unassigned_enrollments_checked: this.filters.unassigned_enrollments === 'only',
    }
  },
  watch: {
    'formDeleteEnrolments.enrolments': {
      handler() {
        if (this.enrolments.data.every((enrolment) => this.formDeleteEnrolments.enrolments.includes(enrolment.id))) {
          return (this.selectAll = true)
        }
        this.selectAll = false
      },
      deep: true,
    },
    is_unassigned_enrollments_checked() {
      this.form.unassigned_enrollments = this.is_unassigned_enrollments_checked ? 'only' : null
    },
  },
  methods: {
    submitSearch() {
      let query = _pickBy(this.form)
      this.$inertia.get(this.route('enrolments'), Object.keys(query).length ? query : { remember: 'forget' }, {
        preserveState: true,
        replace: true,
      })
    },
    reset() {
      this.form = _mapValues(this.form, () => null)
      this.submitSearch()
    },
    cancelEnrolmentsSelection() {
      this.isSelectingEnrolments = false
      this.formDeleteEnrolments.enrolments = []
    },
    selectAllUpdated() {
      if (this.selectAll) {
        this.selectAllEnrolments()
      } else {
        this.unSelectAllEnrolments()
      }
    },
    selectAllEnrolments() {
      this.formDeleteEnrolments.enrolments = this.enrolments.data
        .filter((enrollment) => this.canDeleteEnrollment(enrollment))
        .map(function (enrollment) {
          return enrollment.id
        })
    },
    unSelectAllEnrolments() {
      this.formDeleteEnrolments.enrolments = []
    },
    deleteEnrolments() {
      if (
        confirm(
          this.$t('Are you sure you want to delete enrollment(s)?\nEnrollment progress will all be deleted if any.')
        )
      ) {
        this.$inertia.delete(
          this.route('enrolments.delete', {
            enrolment_ids: this.formDeleteEnrolments.enrolments,
          })
        )
      }
    },
    canDeleteEnrollment($enrolment) {
      if ($enrolment.deleted_at) {
        return false
      } else {
        return true
      }
    },
  },
}
</script>
