<template>
  <layout :title="$t('Form Templates')">
    <deleted-message v-if="formTemplate.deleted_at" class="mb-6">
      {{ $t('This form template has been deleted.') }}
    </deleted-message>
    <div class="mb-8 flex items-center justify-between">
      <div class>
        <breadcrumb-admin :path="breadcrumb" />
        <p class="text-sm font-semibold text-gray-600">
          {{ $t('Last published:') }}
          <front-date class="inline" format="MMMM Do YYYY, h:mm:ss a" :timestamp="formTemplate.last_published_at" />
        </p>
      </div>

      <div class="flex items-center gap-2">
        <button v-if="!formTemplate.deleted_at" class="btn-red-outline" @click="deleteFormTemplate()">
          <span>{{ $t('Delete') }}</span>
          <span class="sr-only">{{ $t('Template') }}</span>
        </button>
        <button v-else class="btn-blue" @click="restoreFormTemplate()">
          <span>{{ $t('Restore') }}</span>
          <span class="sr-only">{{ $t('Template') }}</span>
        </button>
        <front-loading-button
          :class="{ disabled: !formTemplate.can.publish }"
          :disabled="!formTemplate.can.publish"
          :loading="form.processing"
          type="button"
          @click="publish"
        >
          {{ $t('Publish') }}
        </front-loading-button>
      </div>
    </div>
    <div class="flex w-full flex-col-reverse md:flex-row">
      <div class="mb-4 mr-0 w-full md:w-2/3">
        <div class="overflow-hidden rounded bg-white shadow">
          <form @change="formChange" @submit.prevent="submit">
            <div class="flex flex-col xl:flex-row">
              <div class="flex flex-wrap px-8 pb-4 pt-8">
                <div class="w-full pb-4">
                  <front-text-input
                    v-model="form.title"
                    class="w-full"
                    :error="form.errors.title"
                    :placeholder="$t('Untitled template')"
                  />
                </div>
                <div class="w-full">
                  <front-textarea-input
                    v-model="form.description"
                    :autosize="true"
                    class="w-full pb-2 pr-6"
                    :error="form.errors.description"
                    :placeholder="$t('Add a description')"
                    :rows="5"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="mt-5">
          <FormPages :common="common" :form-template="formTemplate" :list="formTemplateContents" />
          <front-form-page-move-modal
            :pages="formTemplateContents"
            :show="isMovePageModal"
            @close="hideMovePageModal"
          />
          <div
            v-if="form.processing"
            class="fixed bottom-4 right-4 flex space-x-4 rounded-full bg-green-600 px-4 py-4 text-white"
          >
            <div class="spinner" />
            <span>Saving</span>
          </div>
        </div>
      </div>
      <div class="md:top-50 mb-4 w-full md:fixed md:right-0 md:mb-0 md:w-1/4">
        <div class="text-right">
          <button
            class="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold leading-4 text-qualify-red-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            type="button"
            @click="showMobilePreview = !showMobilePreview"
          >
            <DevicePhoneMobileIcon aria-hidden="true" class="mr-2 h-4 w-4 text-qualify-red-500" />
            {{ showMobilePreview ? $t('Hide mobile preview') : $t('Show mobile preview') }}
          </button>
        </div>
        <transition
          enter-active-class="transition ease-out duration-200"
          enter-from-class="opacity-100 translate-x-5"
          enter-to-class="opacity-100 translate-x-0"
          leave-active-class="transition ease-out duration-200"
          leave-from-class="opacity-100 translate-x-0"
          leave-to-class="opacity-100 translate-x-5"
        >
          <div
            v-if="showMobilePreview"
            class="mt-2 h-[36rem] overflow-hidden rounded-lg border border-ts-gray-500 bg-white p-4 shadow"
          >
            <header class="relative w-full border bg-gray-50">
              <nav aria-label="Global" class="max-w-7xl mx-auto flex items-center justify-between p-4">
                <div>
                  <Popover class="mb-3">
                    <PopoverButton class="inline-flex items-center text-sm text-gray-600">
                      <p class="text-sm">Page {{ templatePageIndex }} of {{ formTemplateContents.length }}</p>
                      <ChevronDownIcon
                        aria-hidden="true"
                        :class="!open ? 'ml-1 h-4 w-4 transition' : 'ml-1 h-4 w-4 rotate-180 transition'"
                      />
                    </PopoverButton>

                    <transition
                      enter-active-class="transition ease-out duration-200"
                      enter-from-class="opacity-0 translate-y-1"
                      enter-to-class="opacity-100 translate-y-0"
                      leave-active-class="transition ease-in duration-150"
                      leave-from-class="opacity-100 translate-y-0"
                      leave-to-class="opacity-0 translate-y-1"
                    >
                      <PopoverPanel class="absolute top-20 z-10 -ml-4 w-full bg-white shadow ring-1 ring-gray-900/5">
                        <div class="rounded bg-white text-sm">
                          <div class="p-2">
                            <div
                              v-for="(page, index) in formTemplateContents"
                              :key="page.id"
                              class="shrink rounded bg-white text-sm text-gray-600"
                            >
                              <a class="block cursor-pointer p-2 hover:bg-gray-100" @click="handleTemplatePage(index)">
                                {{ page.title ?? $t('Untitled page') }}
                              </a>
                            </div>
                          </div>
                        </div>
                      </PopoverPanel>
                    </transition>
                  </Popover>
                  <p class="text-m font-semibold text-gray-900">{{ formTemplate?.title }}</p>
                </div>
                <div class="text-right lg:gap-x-12">
                  <p class="mb-3 text-sm text-gray-600">{{ $t('Score') }}</p>
                  <p class="text-m font-semibold text-gray-900">{{ $t('0 / 1 (0%)') }}</p>
                </div>
              </nav>
            </header>
            <div class="h-[28.75rem] overflow-hidden overflow-y-scroll bg-gray-100 p-4">
              <front-form-item-mobile-view
                v-for="item in formTemplateContents[templatePageIndex - 1].children"
                :key="item.uuid"
                :item="item"
              />
              <div
                :class="[
                  'mb-16 mt-20 inline-flex w-full items-center',
                  templatePageIndex === 1
                    ? 'justify-end'
                    : templatePageIndex < formTemplateContents.length
                      ? 'justify-between'
                      : 'justify-start',
                ]"
              >
                <button
                  v-if="templatePageIndex > 1"
                  class="inline-flex items-center rounded-md bg-qualify-red-500 p-3 text-sm font-semibold text-white shadow-sm focus:outline-none"
                  type="button"
                  @click="gotoPreviousPage(templatePageIndex)"
                >
                  <ChevronLeftIcon aria-hidden="true" class="h-5 w-5" />
                  {{ $t('Previous Page') }}
                </button>
                <button
                  v-if="templatePageIndex < formTemplateContents.length"
                  class="inline-flex items-center rounded-md bg-qualify-red-500 p-3 text-sm font-semibold text-white shadow-sm focus:outline-none"
                  type="button"
                  @click="gotoNextPage(templatePageIndex)"
                >
                  {{ $t('Next Page') }}
                  <ChevronRightIcon aria-hidden="true" class="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <front-confirm-modal
      v-if="showOutOfSyncError"
      :show="showOutOfSyncError"
      @cancel="showOutOfSyncError = false"
      @confirm="reload"
    >
      <template #title>{{ $t('Unable to save') }}</template>
      <template #content>
        <div class="flex flex-col space-y-4">
          <div>{{ $t('Page is out of sync. Need reload the page for editing') }}</div>
          <div>{{ $t('Are you sure you want to reload the page?') }}</div>
        </div>
      </template>
    </front-confirm-modal>
  </layout>
</template>

<script>
import FormPages from '../Front/Admin/FormTemplates/Components/FormPages.vue'
import BreadcrumbAdmin from '@/Shared/BreadcrumbAdmin.vue'
import DeletedMessage from '@/Shared/DeletedMessage.vue'
import FrontConfirmModal from '@/Shared/FrontConfirmModal.vue'
import FrontDate from '@/Shared/FrontDate.vue'
import FrontFormItemMobileView from '@/Shared/FrontFormItemMobileView.vue'
import FrontFormPageMoveModal from '@/Shared/FrontFormPageMoveModal.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import FrontTextareaInput from '@/Shared/FrontTextareaInput.vue'
import Layout from '@/Shared/Layout.vue'
import { isTenantWorksite } from '@/Utils/Helpers'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { ChevronLeftIcon, ChevronRightIcon, DevicePhoneMobileIcon } from '@heroicons/vue/24/outline'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    Popover,
    PopoverButton,
    PopoverPanel,
    FormPages,
    DevicePhoneMobileIcon,
    BreadcrumbAdmin,
    DeletedMessage,
    FrontConfirmModal,
    FrontDate,
    FrontFormItemMobileView,
    FrontFormPageMoveModal,
    FrontLoadingButton,
    FrontTextInput,
    FrontTextareaInput,
    Layout,
  },
  provide() {
    return {
      saveChanges: this.saveChanges,
      showMovePageModal: this.showMovePageModal,
      isGlobalTemplate: true,
    }
  },
  props: {
    common: Object,
    formTemplate: Object,
  },
  data() {
    return {
      isMovePageModal: false,
      enabled: true,
      dragging: false,
      templatePageIndex: 1,
      previous: {
        url: this.route('form-templates'),
        name: this.$t('Form Templates'),
      },
      form: useForm({
        series_id: this.formTemplate.series_id,
        title: this.formTemplate.title,
        description: this.formTemplate.description,
        formContents: this.listToTree(this.formTemplate?.contents),
      }),
      globallyPublished: this.formTemplate.globally_published,
      showMobilePreview: false,
      showOutOfSyncError: false,
    }
  },
  computed: {
    isTenantWorksite,
    formTemplateContents() {
      return this.listToTree(this.formTemplate?.contents)
    },
    breadcrumb() {
      return [
        {
          name: this.$t('Form Templates'),
          url: this.route('form-templates'),
        },
        {
          name: this.formTemplate.title,
        },
      ]
    },
  },
  methods: {
    formChange() {
      this.saveChanges()
    },
    listToTree(listItems) {
      let pageList = []
      const parentMap = []

      listItems.forEach((formContent) => {
        formContent.children = []

        if (formContent.type === 'form-page') {
          parentMap[formContent.uuid] = formContent
          return pageList.push(formContent)
        }

        if (formContent.parent_id in parentMap) {
          parentMap[formContent.uuid] = formContent
          parentMap[formContent.parent_id].children.push(formContent)
        }
      })

      return pageList
    },
    treeToList(tree) {
      const treeList = []

      function transformToList(list, parentId = null) {
        list.forEach(({ children, ...node }) => {
          treeList.push({ ...node, parent_id: parentId })
          if (children) transformToList(children, node.uuid)
        })
      }

      transformToList(tree)

      return treeList
    },
    saveChanges() {
      this.form
        .transform((data) => {
          return {
            ...data,
            updated_at: this.formTemplate.updated_at,
            formContents: this.treeToList(this.formTemplateContents),
          }
        })
        .put(this.route('form-templates.update', this.formTemplate.series_id), {
          preserveScroll: true,
          onError: () => {
            this.showOutOfSyncError = true
          },
        })
    },
    hideMovePageModal() {
      this.isMovePageModal = false
    },
    showMovePageModal() {
      this.isMovePageModal = true
    },
    handleTemplatePage(index) {
      this.templatePageIndex = ++index
    },
    gotoPreviousPage() {
      this.templatePageIndex--
    },
    gotoNextPage() {
      this.templatePageIndex++
    },
    reload() {
      this.showOutOfSyncError = false
      window.location.reload()
    },
    publish() {
      this.$inertia.put(this.route('form-templates.publish', this.formTemplate.series_id), {
        updated_at: this.formTemplate.updated_at,
      })
    },
    deleteFormTemplate() {
      if (confirm(this.$t('Are you sure you want to delete this form template?'))) {
        this.$inertia.delete(this.route('form-templates.destroy', this.formTemplate.series_id))
      }
    },
    restoreFormTemplate() {
      if (confirm(this.$t('Are you sure you want to restore this form template?'))) {
        this.$inertia.put(this.route('form-templates.restore', this.formTemplate.series_id))
      }
    },
  },
}
</script>
<style scoped>
.ghost {
  opacity: 0.5;
}

.not-draggable {
  cursor: no-drop;
}
</style>
