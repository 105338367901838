import Http from '../Utils/Http'

export default {
  methods: {
    getContentsForType() {
      if (this.form.type === '') {
        return (this.contentList = [])
      }

      this.fetchingContents = true

      Http.get(this.route('contents.index'), {
        params: {
          type: this.form.type,
        },
      })
        .then((response) => {
          this.fetchingContents = false
          this.contentList = response.data
        })
        .catch((error) => {
          console.log(error)
          this.contentList = []
        })
        .finally(() => (this.fetchingContents = false))
    },
  },
}
