<template>
  <dynamic-search-input
    v-model="certificateTemplate"
    :error="error"
    :label="$t('Certificate Template Format')"
    :search-by="['name', 'title']"
    track-by="id"
    :url="route('certificate-templates-formats.search', { style: formatStyle })"
  >
    <div v-if="certificateTemplate" class="flex flex-col justify-between">
      <div class="truncate">{{ certificateTemplate.name }}</div>
      <div class="flex justify-between space-x-4 whitespace-nowrap text-xs text-gray-600">
        <span>{{ certificateTemplate.title ? certificateTemplate.title : '' }}</span>
        <span>{{ certificateTemplate.formatsCount }}</span>
      </div>
    </div>
    <template #option="{ option, selected }">
      <div class="flex flex-col items-start justify-between">
        <div>{{ option.name }}</div>
        <div
          class="flex w-full justify-between space-x-4 text-xs"
          :class="{ 'text-gray-600': !selected, 'text-white': selected }"
        >
          <span>{{ option.title }}</span>
          <span>{{ option.formatsCount }}</span>
        </div>
      </div>
    </template>
  </dynamic-search-input>
</template>

<script>
import DynamicSearchInput from '@/Shared/DynamicSearchInput.vue'

export default {
  components: {
    DynamicSearchInput,
  },
  props: {
    modelValue: [Object],
    error: String,
    formatStyle: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      certificateTemplate: this.modelValue,
    }
  },
  watch: {
    certificateTemplate(newCertificateTemplate) {
      this.$emit('update:modelValue', newCertificateTemplate)
    },
  },
}
</script>
