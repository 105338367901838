<template>
  <div>
    <label>
      <span v-if="label" class="form-label">{{ label }}:</span>
      <textarea
        ref="input"
        v-bind="$attrs"
        class="form-textarea"
        :class="{ error: error }"
        :rows="rows"
        type="text"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
      />
    </label>
    <div v-if="error" class="form-error">{{ error }}</div>
  </div>
</template>

<script>
import autosize from 'autosize'

export default {
  inheritAttrs: false,
  props: {
    modelValue: String,
    label: String,
    error: String,
    cssClass: String,
    autosize: Boolean,
    rows: {
      type: [String, Number],
      default: 4,
    },
  },
  emits: ['update:modelValue'],
  mounted() {
    if (this.autosize) {
      autosize(this.$refs.input)
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
  },
}
</script>
