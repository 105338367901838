<template>
  <front-modal class="rounded-xl px-4 font-nexa text-base" :show="show" width="auto" @close="close">
    <div
      v-if="!submitted"
      class="flex max-w-md flex-wrap px-16 pb-8 pt-10"
      :class="isTenantWorksite ? 'bg-qualify-red-500' : 'bg-blue-700'"
    >
      <span class="mx-auto text-3xl font-semibold text-white">{{ $t('Contact Us') }}</span>
    </div>
    <div v-if="!submitted" class="max-w-md overflow-hidden bg-white">
      <div class="flex flex-col-reverse items-center justify-between p-6 lg:flex-row">
        <form @submit.prevent="submit">
          <div class="flex flex-wrap p-4">
            <span class="mb-6 w-full text-lg font-semibold">{{ $t('Your Information') }}</span>
            <div class="w-full pb-4 md:w-1/2 md:pr-3">
              <front-text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" />
            </div>
            <div class="w-full md:w-1/2 md:pl-3">
              <front-text-input v-model="form.email" :error="form.errors.email" :label="$t('Email')" />
            </div>
          </div>
          <div class="flex flex-wrap px-4 pb-4">
            <span class="mb-6 w-full text-lg font-semibold">{{ $t('Certificate Information') }}</span>
            <div class="w-full pb-4 md:w-1/2 md:pr-3">
              <front-text-input
                v-model="form.cert_last_name"
                :error="form.errors.cert_last_name"
                :label="$t('Holder\'s Last Name')"
              />
            </div>
            <div class="w-full pb-4 md:w-1/2 md:pl-3">
              <front-text-input v-model="form.course" :error="form.errors.course" :label="$t('Course')" />
            </div>
            <div class="w-full pb-4 md:w-1/2 md:pr-3">
              <front-text-input
                v-model="form.certificate_number"
                :error="form.errors.certificate_number"
                :label="$t('Certification Number')"
              />
            </div>
            <div class="w-full">
              <front-textarea-input
                v-model="form.description"
                :error="form.errors.description"
                :label="$t('Description')"
                :rows="3"
              />
            </div>
          </div>
          <div class="flex items-center justify-center py-4">
            <front-outline-button class="mr-2" type="button" @click="close">{{ $t('Cancel') }}</front-outline-button>
            <front-loading-button class="ml-2" :loading="form.processing" type="submit">
              {{ $t('Send Email') }}
            </front-loading-button>
          </div>
        </form>
      </div>
    </div>
    <div v-else class="max-w-md overflow-hidden bg-white">
      <div class="flex flex-col items-center p-10">
        <icon
          class="mx-auto mb-10 h-16 w-16 flex-shrink-0 rounded-full bg-ts-blue-600 fill-white p-2"
          name="checkmark"
        />
        <span class="pb-4 text-center text-4xl font-semibold leading-10" style="color: #3e484f">
          {{ $t('Thank You For Contacting Us!') }}
        </span>
        <span class="pb-6 text-center text-xl leading-8 md:px-8" style="color: #6e767b">
          {{ $t('We have received your request and will get back to you within 24-48 hours.') }}
        </span>
        <span class="pb-8 text-center leading-6 md:px-16">
          {{ $t(`If you change your mind or need to contact us you can reach us at :telephone. Our hours of operation
          are 8:00am-8:00pm Monday to Friday with the exception of statutory holidays.`, { telephone: `
          <a href="tel:${tenant.phone}" class="whitespace-nowrap text-ts-blue-600 hover:underline">${tenant.phone}</a>
          ` }) }}
        </span>
        <div class="">
          <front-loading-button @click="close">{{ $t('Cancel') }}</front-loading-button>
        </div>
      </div>
    </div>
  </front-modal>
</template>

<script>
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontModal from '@/Shared/FrontModal.vue'
import FrontOutlineButton from '@/Shared/FrontOutlineButton.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import FrontTextareaInput from '@/Shared/FrontTextareaInput.vue'
import Icon from '@/Shared/Icon.vue'
import { query } from '@/Utils/Helpers'
import { isTenantWorksite } from '@/Utils/Helpers'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    FrontLoadingButton,
    FrontModal,
    FrontOutlineButton,
    FrontTextInput,
    FrontTextareaInput,
    Icon,
  },
  props: {
    show: Boolean,
    tenant: Object,
    certificate_templates: Array,
  },
  data() {
    return {
      submitted: false,
      form: useForm({
        name: null,
        email: null,
        cert_last_name: null,
        course: null,
        certificate_number: null,
        description: null,
      }),
    }
  },
  computed: {
    isTenantWorksite,
  },
  methods: {
    query,
    close() {
      this.form.reset()
      this.$emit('close')
    },
    submit() {
      this.form.post(this.route('contact.support'), {
        onSuccess: () => {
          this.form.reset()
          this.submitted = true
        },
      })
    },
  },
}
</script>
