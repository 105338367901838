<template>
  <modal class="rounded text-base" :show="show" style="z-index: 100000" width="auto" @close="close">
    <div class="max-h-screen max-w-lg overflow-auto rounded bg-white pt-6 shadow">
      <div class="flex flex-wrap">
        <h1 class="w-full px-6 pb-2 pt-4 text-center text-xl font-bold">{{ $t('Student Equipment') }}</h1>
        <div class="w-full text-center">{{ student.name }}</div>

        <form class="w-full" @submit.prevent="submit">
          <div class="flex flex-col p-12 md:flex-row">
            <div class="w-full pr-6 lg:w-1/2">
              <text-input
                v-model="form.equipment_1"
                :error="form.errors.default_equipment_types"
                :label="$t('Equipment #1')"
              />
            </div>
            <div class="mt-2 w-full pr-6 md:mt-0 lg:w-1/2">
              <text-input
                v-model="form.equipment_2"
                :error="form.errors.default_equipment_types"
                :label="$t('Equipment #2')"
              />
            </div>
            <div class="mt-2 w-full pr-6 md:mt-0 lg:w-1/2">
              <text-input
                v-model="form.equipment_3"
                :error="form.errors.default_equipment_types"
                :label="$t('Equipment #3')"
              />
            </div>
          </div>
          <small v-if="defaultEquipmentTypes && defaultEquipmentTypes.length > 0" class="p-12">
            Setting any of the above values will override the following values:
            <div class="mt-2 flex flex-col">
              <span v-for="(equipment, index) in defaultEquipmentTypes" :key="index">
                {{ equipment }}
              </span>
            </div>
          </small>
          <div class="flex w-full items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
            <loading-button class="btn-blue" :loading="form.processing" type="submit">{{ $t('Save') }}</loading-button>
          </div>
        </form>
      </div>
    </div>
  </modal>
</template>

<script>
import LoadingButton from '@/Shared/LoadingButton.vue'
import Modal from '@/Shared/Modal.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    LoadingButton,
    Modal,
    TextInput,
  },
  props: {
    show: Boolean,
    student: Object,
    defaultEquipmentTypes: Array,
  },
  data() {
    return {
      form: useForm({
        equipment_1: this.student.equipment_types?.[0],
        equipment_2: this.student.equipment_types?.[1],
        equipment_3: this.student.equipment_types?.[2],
      }),
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    submit() {
      this.form
        .transform((data) => ({
          equipment_types: [data.equipment_1, data.equipment_2, data.equipment_3],
        }))
        .put(this.route('instructor.students.equipment.update', this.student))
    },
  },
}
</script>
