<template>
  <layout :title="$t('My Profile')">
    <h1 class="mb-8 text-xl font-bold">{{ $t('My Profile') }}</h1>
    <div class="flex max-w-3xl flex-wrap">
      <div class="w-full lg:w-3/5 xl:w-2/3">
        <div class="overflow-hidden rounded bg-white shadow">
          <form @submit.prevent="submit">
            <div class="-mb-8 -mr-6 flex flex-wrap p-8">
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input v-model="form.first_name" :error="form.errors.first_name" :label="$t('First name')" />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input v-model="form.last_name" :error="form.errors.last_name" :label="$t('Last name')" />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <select-input v-model="form.timezone" :error="form.errors.timezone" :label="$t('Timezone')">
                  <option :value="null" />
                  <option v-for="(timezone, key) in timezones" :key="key" :value="timezone">
                    {{ timezone }}
                  </option>
                </select-input>
              </div>
            </div>
            <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Contact Information') }}</div>
            <div class="-mb-8 -mr-6 flex flex-wrap p-8">
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input v-model="form.email" :error="form.errors.email" :label="$t('Email')" />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input v-model="form.phone" :error="form.errors.phone" :label="$t('Phone')" />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input v-model="form.address" :error="form.errors.address" :label="$t('Address')" />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input v-model="form.city" :error="form.errors.city" :label="$t('City')" />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <select-input
                  v-model="form.region"
                  :error="form.errors.region"
                  :label="$t('Province/State')"
                  :options="[null, ...regions]"
                />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input v-model="form.postal_code" :error="form.errors.postal_code" :label="$t('Postal code')" />
              </div>
            </div>
            <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Access') }}</div>
            <div class="-mb-8 -mr-6 flex flex-wrap p-8">
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input
                  v-model="form.password"
                  autocomplete="new-password"
                  :error="form.errors.password"
                  :label="$t('Password')"
                  type="password"
                />
              </div>
            </div>
            <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
              <loading-button class="btn-blue" :loading="form.processing" type="submit">
                {{ $t('Save Changes') }}
              </loading-button>
            </div>
          </form>
        </div>
      </div>
      <div class="flex w-full flex-col pt-12 lg:w-2/5 lg:pl-8 lg:pt-0 xl:w-1/3">
        <organization-card v-if="user.organization" class="mb-12 lg:mb-8" :organization="user.organization" />
        <calendar-card v-if="user.is_instructor" :calendar-url="user.calendar_url" />
      </div>
    </div>
  </layout>
</template>

<script>
import CalendarCard from '@/Shared/CalendarCard.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import OrganizationCard from '@/Shared/OrganizationCard.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    CalendarCard,
    Layout,
    LoadingButton,
    OrganizationCard,
    SelectInput,
    TextInput,
  },
  props: {
    user: Object,
    regions: Array,
    timezones: Object,
  },
  data() {
    return {
      sending: false,
      form: useForm(
        {
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          email: this.user.email,
          phone: this.user.phone,
          address: this.user.address,
          city: this.user.city,
          region: this.user.region,
          postal_code: this.user.postal_code,
          password: this.user.password,
          timezone: this.user.timezone,
        },
        'ProfileEdit'
      ),
    }
  },
  methods: {
    submit() {
      this.form.put(this.route('profile.update'))
    },
  },
}
</script>
