<template>
  <layout :title="$t('Create Organization')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('organizations.index')">
        {{ $t('Organizations') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Create') }}
    </h1>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.email" :error="form.errors.email" :label="$t('Email')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.phone" :error="form.errors.phone" :label="$t('Phone')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.address" :error="form.errors.address" :label="$t('Address')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.city" :error="form.errors.city" :label="$t('City')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <select-input
              v-model="form.region"
              :error="form.errors.region"
              :label="$t('Province/State')"
              :options="[null, ...regions]"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.postal_code" :error="form.errors.postal_code" :label="$t('Postal code')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <radio-input
              v-model="form.access_codes_enabled"
              :error="form.errors.access_codes_enabled"
              :label="$t('Enable Access Codes')"
              type="radio"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <radio-input
              v-model="form.training_records_enabled"
              :error="form.errors.training_records_enabled"
              :label="$t('Enable Training Records')"
              type="radio"
            />
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Create Organization') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import RadioInput from '@/Shared/RadioInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Layout,
    LoadingButton,
    RadioInput,
    SelectInput,
    TextInput,
  },
  props: {
    regions: Array,
  },
  data() {
    return {
      form: useForm(
        {
          name: null,
          email: null,
          phone: null,
          address: null,
          city: null,
          region: null,
          postal_code: null,
          access_codes_enabled: true,
          training_records_enabled: true,
        },
        'OrganizationCreate'
      ),
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('organizations.store'))
    },
  },
}
</script>
