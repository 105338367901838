<template>
  <div v-if="signatureRequest.accepted_at" :class="`${cssClasses} text-green-700`">{{ $t('Completed') }}</div>
  <div v-else-if="signatureRequest.expired_at" :class="`${cssClasses} text-gray-400`">{{ $t('Expired') }}</div>
  <div v-else :class="`${cssClasses} text-orange-400`">{{ $t('Pending') }}</div>
</template>
<script>
export default {
  props: {
    signatureRequest: Object,
  },
  data() {
    return {
      cssClasses: 'text-xs uppercase text-center font-semibold w-20',
    }
  },
}
</script>
