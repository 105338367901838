<template>
  <div class="flex items-center">
    <div class="mr-3 text-sm">
      <front-checkbox-input :label="$t('Required')" />
    </div>
    <div class="text-sm">
      <front-checkbox-input v-model="optionsData.is_date" :label="$t('Date')" />
    </div>
    <div class="text-sm">
      <front-checkbox-input v-model="optionsData.is_time" :label="$t('Time')" />
    </div>
  </div>
</template>
<script>
import FrontCheckboxInput from '@/Shared/FrontCheckboxInput.vue'

export default {
  components: {
    FrontCheckboxInput,
  },
  props: {
    options: Object,
  },
  emits: ['update:options'],
  data() {
    return {
      optionsData: {
        is_date: this.options && Object.hasOwn(this.options, 'is_date') ? this.options.is_date : true,
        is_time: this.options?.is_time ?? false,
      },
    }
  },
  watch: {
    optionsData: {
      immediate: true,
      handler: function () {
        this.$emit('update:options', this.optionsData)
      },
      deep: true,
    },
  },
}
</script>
