<template>
  <layout :title="$t('Reseller')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('resellers')">
        {{ $t('Resellers') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('resellers.show', resellingCourse.tenant.id)">
        {{ resellingCourse.tenant.name }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ resellingCourse.course.name }}
    </h1>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <div class="w-full lg:w-1/2">
              <select-input v-model="form.approved" :error="form.errors.approved" :label="$t('Allow resale?')">
                <option :value="false">{{ $t('No') }}</option>
                <option :value="true">{{ $t('Yes') }}</option>
              </select-input>
            </div>
          </div>
          <div v-if="form.approved" class="w-full pb-8 pr-6 lg:w-1/2">
            <label class="form-label" for="seats">{{ $t('Seats that can be resold:') }}</label>
            <div class="flex">
              <div class="w-2/3 pr-3">
                <select-input id="seats" v-model="form.seats">
                  <option value="percentage">{{ $t('Percentage') }}</option>
                  <option value="quantity">{{ $t('Quantity') }}</option>
                </select-input>
              </div>
              <div class="w-1/3">
                <div v-if="form.seats === 'percentage'" class="relative">
                  <text-input v-model="form.seats_percentage" maxlength="3" />
                  <div class="absolute right-0 top-0 flex h-full items-center pr-3 text-gray-600">%</div>
                </div>
                <text-input v-if="form.seats === 'quantity'" v-model="form.seats_quantity" />
              </div>
            </div>
            <div v-if="form.errors.seats_percentage || form.errors.seats_quantity" class="form-error">
              {{ form.errors.seats_percentage || form.errors.seats_quantity }}
            </div>
          </div>
          <div v-if="form.approved" class="w-full pb-8 pr-6 lg:w-1/2">
            <label class="form-label" for="commission">{{ $t('Commission rate:') }}</label>
            <div class="relative">
              <text-input id="commission" v-model="form.commission" maxlength="3" />
              <div class="absolute right-0 top-0 flex h-full items-center pr-3 text-gray-600">%</div>
            </div>
            <div v-if="form.errors.commission" class="form-error">
              {{ form.errors.commission }}
            </div>
          </div>
          <div v-if="form.approved" class="pb-8 text-gray-600">
            {{ $t('Leave the above settings blank to use the defaults.') }}
          </div>
        </div>
        <div class="flex justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Update Course') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Layout,
    LoadingButton,
    SelectInput,
    TextInput,
  },
  props: {
    resellingCourse: Object,
  },
  data() {
    return {
      form: useForm({
        approved: this.resellingCourse.approved,
        seats: this.resellingCourse.seats_quantity ? 'quantity' : 'percentage',
        seats_percentage: this.resellingCourse.seats_percentage,
        seats_quantity: this.resellingCourse.seats_quantity,
        commission: this.resellingCourse.commission,
      }),
    }
  },
  methods: {
    submit() {
      this.form
        .transform((data) => ({
          ...data,
          seats_percentage: data.approved && data.seats === 'percentage' ? data.seats_percentage : null,
          seats_quantity: data.approved && data.seats === 'quantity' ? data.seats_quantity : null,
          commission: data.approved ? data.commission : null,
        }))
        .put(this.route('reselling_courses.update', this.resellingCourse.id))
    },
  },
}
</script>
