<template>
  <div class="grid h-32 cursor-pointer gap-6 rounded-lg bg-white p-6 shadow hover:shadow-lg">
    <div class="flex w-full items-center justify-between">
      <div class="flex items-center">
        <div class="pr-4">
          <img alt="`${payment.card.brand} card icon`" class="w-12" :src="`/images/${payment.card.brand}-icon.svg`" />
        </div>
        <div>
          <div class="font-semibold">{{ $t('Card ending in :last4', { last4: payment.card.last4 }) }}</div>
          <div class="mt-1 text-sm text-ts-gray-text">
            {{
              $t('Expires: :exp_month/:exp_year', {
                exp_month: String(payment.card.exp_month).padStart(2, '0'),
                exp_year: payment.card.exp_year,
              })
            }}
          </div>
        </div>
      </div>
      <div>
        <span
          v-if="isDefault"
          class="rounded-md bg-[#467082]/10 px-3 py-1 text-center text-sm font-semibold uppercase text-[#467082]"
        >
          {{ $t('DEFAULT') }}
        </span>
      </div>
    </div>

    <div class="inline-flex w-full items-center justify-between text-sm font-semibold text-[#467082]">
      <span v-if="canDelete" class="hover:opacity-60" @click.stop="showDeletePaymentMethodModal">
        {{ $t('Remove') }}
      </span>
      <span v-if="!isDefault" class="mr-2 hover:opacity-60" @click.stop="onUpdateDefault">
        {{ $t('Set as default ') }}
      </span>
    </div>

    <front-confirm-modal
      :show="isDeletePaymentMethodModal"
      @cancel="hideDeletePaymentMethodModal"
      @confirm="onConfirmDeletePaymentMethod"
    >
      <template #title>{{ $t('Remove payment method') }}</template>
      <template #content>
        <div class="flex flex-col items-center">
          <div>{{ $t('The payment method will no longer be usable.') }}</div>
          <div>{{ $t('Are you sure you want to remove it?') }}</div>
        </div>
      </template>
    </front-confirm-modal>
  </div>
</template>
<script>
import FrontConfirmModal from '@/Shared/FrontConfirmModal.vue'

export default {
  components: {
    FrontConfirmModal,
  },
  props: {
    payment: Object,
    isDefault: Boolean,
    canDelete: Boolean,
  },
  data() {
    return {
      isDeletePaymentMethodModal: false,
    }
  },
  methods: {
    paymentCardType() {
      return this.payment.card.funding === 'credit' ? 'Credit Card' : 'Debit Card'
    },
    onConfirmDeletePaymentMethod() {
      this.$inertia.delete(this.route('front.admin.payment-method.destroy', this.payment.id))
      this.hideDeletePaymentMethodModal()
    },
    showDeletePaymentMethodModal() {
      this.isDeletePaymentMethodModal = true
    },
    hideDeletePaymentMethodModal() {
      this.isDeletePaymentMethodModal = false
    },
    onUpdateDefault() {
      this.$inertia.put(this.route('front.admin.payment-method.update-default', this.payment.id))
    },
  },
}
</script>
