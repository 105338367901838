<template>
  <styled-modal max-width="lg" :show="show" @close="$emit('close')">
    <template #title>{{ $t(':title: Select Courses', { title: title }) }}</template>

    <template #content>
      <div class="mt-6 flex flex-col space-y-4">
        <div v-if="courses.length > 0">
          <form>
            <ul v-for="courseObject in courses" :key="courseObject.course_id" className="flex flex-row">
              <li>
                <div class="flex items-center rounded border p-3">
                  <input
                    :id="`course-select-${courseObject.id}`"
                    v-model="selected"
                    class="form-checkbox mr-4 h-5 w-5 rounded-full text-ts-red-500 outline-none"
                    type="checkbox"
                    :value="courseObject.id"
                  />
                  <label :for="`course-select-${courseObject.id}`">{{ courseObject.name }}</label>
                </div>
              </li>
            </ul>
          </form>
        </div>
        <div v-else>{{ $t('You cannot request reviews for this course location until the class has sessions.') }}</div>
      </div>
    </template>

    <template #footer>
      <button class="text-gray-500" @click.prevent="$emit('close')">{{ $t('Cancel') }}</button>
      <button class="btn-blue ml-4" :disabled="selected.length === 0" @click.prevent="submit">
        <span v-if="!requesting">{{ $t('Submit') }}</span>
        <span v-else>{{ $t('Requesting...') }}</span>
      </button>
    </template>
  </styled-modal>
</template>

<script>
import StyledModal from '@/Shared/StyledModal.vue'

export default {
  components: {
    StyledModal,
  },
  name: 'ClassCoursesSelect',
  props: {
    show: Boolean,
    title: String,
    courses: Array,
  },
  data() {
    return {
      requesting: false,
      selected: [],
    }
  },
  methods: {
    submit() {
      this.$emit('submit', this.selected)
    },
  },
}
</script>
``
