<template>
  <div v-if="!!students">
    <div class="mt-8 flex items-end justify-between">
      <h2 class="text-lg">{{ $t('Students') }}</h2>
    </div>
    <div class="mt-4 overflow-x-auto rounded bg-white shadow">
      <table class="w-full">
        <thead>
          <tr>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Name') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Sessions') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Check In') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Attendance') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Result') }}</th>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Certificate #') }}</th>
            <!-- Only show Equipment column if at least one student has equipment -->
            <th v-if="showEquipmentColumn" class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold" colspan="2">
              {{ $t('Equipment') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="students.length === 0">
            <td class="border-t px-6 py-4" colspan="6">{{ $t('No student records for this enrollment.') }}</td>
          </tr>
          <tr
            v-for="student in students"
            :key="student.id"
            class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
            :class="{ 'opacity-50': student.deleted_at }"
            @click="$inertia.visit(route('students.edit', student.id))"
          >
            <td class="whitespace-nowrap border-t px-6 py-4">
              <div>{{ student.class ? student.class.name : '-' }}</div>
              <deleted-badge v-if="student.deleted_at" />
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">{{ student.class ? student.class.dates : '-' }}</td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <div v-if="student.checked_in_at" class="h-6 w-6 rounded-full bg-green-500 p-1">
                <icon class="block h-full w-full fill-current text-white" name="checkmark" />
              </div>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <span v-if="student.present === true">{{ !!student.present ? 'Present' : 'Absent' }}</span>
              <span v-else class="text-sm text-gray-400">-</span>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <span v-if="student.passed !== null">{{ !!student.passed ? 'Passed' : 'Failed' }}</span>
              <span v-else class="text-sm text-gray-400">-</span>
            </td>
            <td class="whitespace-nowrap border-t px-6 py-4">
              <span v-if="student.certificate_number">{{ student.certificate_number }}</span>
              <span v-else class="text-sm text-gray-400">-</span>
            </td>
            <!-- Only show Equipment data if the column is displayed -->
            <td v-if="showEquipmentColumn" class="flex flex-col whitespace-nowrap border-t px-6 py-4 text-sm">
              <span v-for="(equipment, index) in student.equipment_types" :key="index">
                {{ equipment }}
              </span>
            </td>
            <td v-if="showEquipmentColumn" class="w-min border-t px-4 align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import Icon from '@/Shared/Icon.vue'

export default {
  components: {
    DeletedBadge,
    Icon,
  },
  props: {
    students: {
      type: Array,
    },
  },
  computed: {
    showEquipmentColumn() {
      return this.students.some((student) => student.equipment_types && student.equipment_types.length > 0)
    },
  },
}
</script>
