<template>
  <div class="mt-8 overflow-x-auto rounded bg-white px-6 py-6 shadow">
    <div v-if="locations.data.length === 0">{{ $t('No Result.') }}</div>
    <div v-else class="my-2">
      <div class="">
        <span class="font-medium">#</span>
        {{ $t(': Number of Classes') }}
      </div>
      <div class="mt-2">
        <span class="font-medium">{{ $t('Ave') }}</span>
        {{ $t(': Average Enrolment Per Class') }}
      </div>
      <div class="mt-2">
        <span class="font-medium">{{ $t('Thr') }}</span>
        {{ $t(': Classes Below Threshold') }}
      </div>
      <table class="w-full">
        <tbody>
          <tr class="border-b">
            <td class="px-4 font-medium">{{ $t('Location') }}</td>
            <td v-for="day in daysOfWeek" :key="day" class="last:pr-4">
              <div class="px-10 pt-4 text-center font-medium">{{ day }}</div>
              <div class="flex py-4">
                <span class="flex-1 text-center">#</span>
                <span class="flex-1 text-center">{{ $t('Ave') }}</span>
                <span v-if="threshold" class="flex-1 text-center">{{ $t('Thr') }}</span>
              </div>
            </td>
          </tr>
          <tr v-for="(days, location) in locations.data" :key="location" class="border-b odd:bg-gray-100">
            <td class="w-px whitespace-nowrap px-4">{{ location }}</td>
            <td v-for="(sessions, day) in days" :key="day" class="last:pr-4">
              <div v-if="isNaN(sessions)" class="flex">
                <span
                  class="flex-1 py-4 text-center"
                  :class="convertToClassName(location) + 'length'"
                  @mouseleave="left(convertToClassName(location) + 'length')"
                  @mouseover="hovered(convertToClassName(location) + 'length')"
                >
                  {{ sessions.length }}
                </span>
                <span
                  class="flex-1 py-4 text-center"
                  :class="convertToClassName(location) + 'average'"
                  @mouseleave="left(convertToClassName(location) + 'average')"
                  @mouseover="hovered(convertToClassName(location) + 'average')"
                >
                  {{ averageEnrolmentPerClass(sessions) }}
                </span>
                <span
                  v-if="threshold"
                  class="flex-1 py-4 text-center"
                  :class="convertToClassName(location) + 'threshold'"
                  @mouseleave="left(convertToClassName(location) + 'threshold')"
                  @mouseover="hovered(convertToClassName(location) + 'threshold')"
                >
                  {{ classesBelowThreshold(sessions) }}
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    locations: Object,
    threshold: String,
  },
  data() {
    return {
      daysOfWeek: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    }
  },
  methods: {
    averageEnrolmentPerClass(sessions) {
      if (sessions.length > 0) {
        return Math.round(
          sessions.reduce((accumulator, session) => accumulator + session.class.students_count, 0) / sessions.length
        )
      }

      return ''
    },
    classesBelowThreshold(sessions) {
      if (this.threshold) {
        return (
          Math.round(
            (sessions.filter((session) => session.class.students_count < this.threshold).length / sessions.length) * 100
          ) + '%'
        )
      }
    },
    hovered(className) {
      Array.prototype.forEach.call(document.getElementsByClassName(className), (element) => {
        element.classList.add('bg-gray-300')
      })
    },
    left(className) {
      Array.prototype.forEach.call(document.getElementsByClassName(className), (element) => {
        element.classList.remove('bg-gray-300')
      })
    },
    convertToClassName(location) {
      return location.toLowerCase().replace(' ', '-')
    },
  },
}
</script>
