<template>
  <div class="mt-6 overflow-x-auto rounded bg-white shadow">
    <table class="w-full">
      <thead>
        <tr>
          <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Invoice Number') }}</th>
          <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Billing Period') }}</th>
          <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Amount Due') }}</th>
          <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Amount Paid') }}</th>
          <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Status') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="invoiceData.length === 0" class="hover:bg-gray-100 focus:bg-gray-100">
          <td class="border-t px-6 py-4" colspan="5">{{ $t('No invoice found.') }}</td>
        </tr>
        <tr
          v-for="invoice in invoiceData"
          :key="invoice.number"
          class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
        >
          <td class="whitespace-nowrap border-t px-6 py-4">
            <span class="capitalize">{{ invoice.number }}</span>
          </td>
          <td class="whitespace-nowrap border-t px-6 py-4">
            <div class="flex">
              <show-date :timestamp="invoice.period_start" />
              <span class="px-2">-</span>
              <show-date :timestamp="invoice.period_end" />
            </div>
          </td>
          <td class="whitespace-nowrap border-t px-6 py-4">
            <span class="capitalize">{{ invoice.amount_due }}</span>
          </td>
          <td class="whitespace-nowrap border-t px-6 py-4">
            <span class="capitalize">{{ invoice.amount_paid }}</span>
          </td>
          <td class="whitespace-nowrap border-t px-6 py-4">
            <span class="capitalize">{{ invoice.status }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import ShowDate from '@/Shared/ShowDate.vue'

export default {
  components: {
    ShowDate,
  },
  props: {
    invoiceData: Array,
  },
}
</script>
