<template>
  <layout :title="$t('Dashboard')">
    <form class="max-w-md" @submit.prevent="submit">
      <input
        v-model="search"
        autocomplete="off"
        class="w-full rounded rounded-r bg-white px-6 py-3 leading-normal shadow focus:ring"
        name="search"
        :placeholder="$t('Search for user…')"
        type="text"
      />
    </form>
    <div class="mt-12 flex justify-between">
      <h2 class="text-lg">{{ $t('Upcoming Classes') }}</h2>
    </div>
    <div class="mt-6 overflow-x-auto rounded bg-white shadow">
      <SimpleTable>
        <template #head>
          <TableHead>{{ $t('Name') }}</TableHead>
          <TableHead>{{ $t('Date/Time') }}</TableHead>
          <TableHead>{{ $t('Location') }}</TableHead>
          <TableHead rowspan="2">{{ $t('Instructors') }}</TableHead>
          <TableHead />
        </template>
        <TableRow
          v-for="class_ in classes"
          :key="class_.id"
          :clickable="true"
          @click="$inertia.visit(route('classes.show', class_.id))"
        >
          <TableData>
            <div class="max-w-xs truncate leading-tight">{{ class_.instructorLedClassroom.name }}</div>
            <div class="mt-1 flex text-sm text-gray-600">
              <div>
                {{ $t('Capacity:') }}
                <span class="ml-0.5">{{ class_.capacity }}</span>
              </div>
              <div class="ml-3">
                {{ $t('Filled:') }}
                <span class="ml-0.5">{{ class_.students }}</span>
              </div>
              <div class="ml-3">
                {{ $t('Available:') }}
                <span class="ml-0.5">{{ class_.available_seats }}</span>
              </div>
            </div>
          </TableData>
          <TableData>
            <div>{{ class_.session.date }}</div>
            <div class="mt-1 text-sm text-gray-600">{{ class_.session.time }}</div>
          </TableData>
          <TableData>
            <div>{{ class_.location.name }}</div>
            <div v-if="class_.room" class="mt-1 text-sm text-gray-600">({{ class_.room.name }})</div>
          </TableData>
          <TableData>
            {{ class_.session.instructors }}
          </TableData>
          <TableData class="w-min border-t px-4 align-middle">
            <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
          </TableData>
        </TableRow>
        <TableRow v-if="classes.length === 0">
          <TableData colspan="5">{{ $t('No upcoming classes found.') }}</TableData>
        </TableRow>
      </SimpleTable>
    </div>
  </layout>
</template>

<script>
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'

export default {
  components: {
    SimpleTable,
    TableData,
    TableRow,
    Icon,
    Layout,
    TableHead,
  },
  props: {
    classes: Array,
  },
  data() {
    return {
      search: null,
    }
  },
  methods: {
    submit() {
      this.$inertia.visit(this.route('users', { search: this.search }))
    },
  },
}
</script>
