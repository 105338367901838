<template>
  <layout :title="class_.instructor_led_classroom.name">
    <div v-if="form.errors.overlap_error" class="mb-8">
      <div class="flex max-w-lg items-center rounded bg-red-500 p-4">
        <icon class="mr-2 h-4 w-4 flex-shrink-0 fill-red-100" name="exclamation-circle" />
        <div class="w-full text-sm font-medium text-red-100">
          <span v-html="form.errors.overlap_error" />
        </div>
      </div>
    </div>
    <div class="mb-8">
      <breadcrumb
        :middle-name="class_.instructor_led_classroom.name"
        :middle-url="route('classes.show', class_.id)"
        :name="$t('Edit')"
        :previous-name="$t('Classes')"
        :previous-url="route('classes')"
      />
    </div>
    <deleted-message v-if="class_.deleted_at" class="mb-6">{{ $t('This class has been deleted.') }}</deleted-message>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div v-if="!class_.parent_class" class="w-full pb-8 pr-6 lg:w-1/2">
            <div class="form-label flex justify-between">
              <label>{{ $t('Location:') }}</label>
              <div v-if="class_.has_subclasses" class="flex items-center">
                <span class="mr-2">{{ $t('With client sites') }}</span>
                <input v-model="with_client_site" type="checkbox" />
              </div>
            </div>
            <search-input
              v-model="form.location"
              :data="locationData"
              :error="form.errors.location_id"
              :search-by="['name', 'room.name']"
              track-by="key"
            >
              <div v-if="form.location" class="flex items-center justify-between">
                <div class="truncate">
                  {{ form.location.name }}
                  <span v-if="form.location.capacity">({{ form.location.capacity }})</span>
                </div>
                <div v-if="form.location.room" class="whitespace-nowrap text-xs text-gray-600">
                  {{ form.location.room.name }}
                  <span v-if="form.location.room.capacity">({{ form.location.room.capacity }})</span>
                </div>
              </div>
              <template #option="{ option, selected }">
                <div class="flex items-center justify-between">
                  <div class="truncate">
                    {{ option.name }}
                    <span v-if="option.capacity">({{ option.capacity }})</span>
                  </div>
                  <div
                    v-if="option.room"
                    class="whitespace-nowrap text-xs"
                    :class="{ 'text-gray-600': !selected, 'text-white': selected }"
                  >
                    {{ option.room.name }}
                    <span v-if="option.room.capacity">({{ option.room.capacity }})</span>
                  </div>
                </div>
              </template>
            </search-input>
            <div v-if="class_.parent_class || class_.has_subclasses" class="mt-1">
              <small v-if="class_.parent_class" class="text-xs text-ts-blue-500">
                {{ $t('This class has a parent class with a configured location.') }}
              </small>
              <small v-else-if="class_.has_subclasses" class="text-xs text-ts-blue-500">
                {{ $t('This class has subclasses that rely on this location.') }}
              </small>
            </div>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.capacity" :error="form.errors.capacity" :label="$t('Capacity')" />
          </div>
          <div v-if="!class_.has_subclasses" class="w-full pb-8 pr-6">
            <dynamic-search-input
              v-model="selected_parent_class"
              :error="form.errors.parent_class_id"
              :label="$t('Parent Class')"
              :search-by="['location.name', 'location.client_site', 'room', 'dates', 'available_seats']"
              track-by="id"
              :url="
                route('classes.search', {
                  location: class_.location.id,
                  room: class_.room_id,
                  instructor_led_classroom_id: class_.instructor_led_classroom.id,
                })
              "
            >
              <div v-if="selected_parent_class" class="flex items-center justify-between">
                <div class="truncate">{{ selected_parent_class.formatted_name }}</div>
              </div>
              <template #option="{ option, selected }">
                <div class="flex items-center justify-between">
                  <div class="text-xs" :class="{ 'text-gray-600': !selected, 'text-white': selected }">
                    {{ option.formatted_name }}
                  </div>
                </div>
              </template>
            </dynamic-search-input>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <radio-input
              v-model="form.test_scores_enabled"
              :error="form.errors.test_scores_enabled"
              :label="$t('Enable test scores')"
              type="radio"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <select-input
              v-model="form.offline_test_id"
              :disabled="!form.test_scores_enabled || offline_tests.length === 0"
              :error="form.errors.offline_test_id"
              :label="$t('Classroom Test')"
            >
              <option v-for="offline_test in offline_tests" :key="offline_test.id" :value="offline_test.id">
                {{ getFormattedTestName(offline_test) }}
              </option>
            </select-input>
          </div>
          <div v-if="class_.requires_course_id" class="w-full pb-8 pr-6 lg:w-1/2">
            <select-input v-model="form.course_id" :error="form.errors.course_id" :label="$t('Course')">
              <option :value="null" />
              <option v-for="(course, key) in courses" :key="key" :value="course.id">
                {{ course.name }}
              </option>
            </select-input>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.price" :error="form.errors.price" :label="$t('Price')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <select-input v-model="form.locale" :error="form.errors.locale" :label="$t('Locale')">
              <option v-for="(locale, key) in locales" :key="key" :value="locale">
                {{ key }}
              </option>
            </select-input>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <div>
              <div class="form-label">
                {{ $t('Private:') }}
                <span v-if="class_.private" class="ml-2">{{ $t('Yes') }}</span>
                <span v-else class="ml-2">{{ $t('No') }}</span>
              </div>
              <div v-if="!class_.parent_class">
                <button v-if="class_.private" class="btn-gray" type="button" @click="makePublic">
                  {{ $t('Make Public') }}
                </button>
                <button v-else class="btn-gray" type="button" @click="makePrivate">{{ $t('Make Private') }}</button>
              </div>
            </div>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <radio-input
              v-model="form.equipment_enabled"
              :error="form.errors.equipment_enabled"
              :label="$t('Equipment Tracking Enabled')"
              type="radio"
            />
          </div>
          <div v-if="form.equipment_enabled" class="flex w-full">
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input
                v-model="form.equipment_1"
                :error="form.errors.default_equipment_types"
                :label="$t('Equipment #1')"
              />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input
                v-model="form.equipment_2"
                :error="form.errors.default_equipment_types"
                :label="$t('Equipment #2')"
              />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input
                v-model="form.equipment_3"
                :error="form.errors.default_equipment_types"
                :label="$t('Equipment #3')"
              />
            </div>
          </div>
          <div class="w-full pb-8 pr-6">
            <textarea-input
              v-model="form.instructor_notes"
              :autosize="true"
              :error="form.errors.instructor_notes"
              :label="$t('Instructor notes')"
              :rows="2"
            />
            <p class="mt-1 text-xs text-ts-blue-500">
              {{ $t('The above notes are private and will not be shared with anyone.') }}
            </p>
          </div>
          <div v-if="class_.private" class="w-full pb-8 pr-6">
            <textarea-input
              v-model="form.coordinator_notes"
              :autosize="true"
              :error="form.errors.coordinator_notes"
              :label="$t('Client Coordinator Notes')"
              :rows="2"
            />
            <p class="mt-1 text-xs text-ts-blue-500">
              {{ $t('The above notes will be shared with the coordinator(s) one day before the class.') }}
            </p>
          </div>
          <div class="w-full pb-8 pr-6">
            <front-file-upload-input
              v-model="form.attachments"
              :initial-value="form.attachments"
              label="Attachments"
              :max-items="5"
              :min-items="1"
              ref-name="editClassUploadAttachmentsInput"
              :validation-rules="{
                maxSizeInKB: 1024 * 10,
              }"
            />
          </div>
        </div>
        <div class="flex items-center border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue ml-auto" :loading="form.processing" type="submit">
            {{ $t('Update Class') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import DeletedMessage from '@/Shared/DeletedMessage.vue'
import DynamicSearchInput from '@/Shared/DynamicSearchInput.vue'
import FrontFileUploadInput from '@/Shared/FrontFileUploadInput.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import RadioInput from '@/Shared/RadioInput.vue'
import SearchInput from '@/Shared/SearchInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { router, useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    DeletedMessage,
    DynamicSearchInput,
    FrontFileUploadInput,
    Icon,
    Layout,
    LoadingButton,
    RadioInput,
    SearchInput,
    SelectInput,
    TextInput,
    TextareaInput,
  },
  props: {
    class_: Object,
    courses: Array,
    locales: Object,
    locations: Array,
    offline_tests: Array,
  },
  data() {
    return {
      selected_parent_class: this.class_.parent_class,
      with_client_site: this.class_.location.client_site,
      locationData: this.locationsWithKeys(this.class_.location.client_site),
      form: useForm(
        {
          location: this.locationsWithKeys(this.class_.location.client_site).find((location) => {
            return (
              location.id === this.class_.location.id &&
              (this.class_.room_id === null || location.room.id === this.class_.room_id)
            )
          }),
          price: this.class_.price,
          course_id: this.class_.course_id,
          locale: this.class_.locale,
          capacity: this.class_.capacity,
          parent_class_id: this.class_.parent_class?.id,
          offline_test_id: this.class_.offline_test_id,
          instructor_notes: this.class_.instructor_notes,
          coordinator_notes: this.class_.coordinator_notes,
          test_scores_enabled: this.class_.test_scores_enabled,
          attachments: this.class_.attachments,
          equipment_enabled: this.class_.equipment_enabled,
          equipment_1: this.class_.default_equipment_types?.[0],
          equipment_2: this.class_.default_equipment_types?.[1],
          equipment_3: this.class_.default_equipment_types?.[2],
        },
        'ClassEdit'
      ),
    }
  },
  watch: {
    with_client_site() {
      this.locationData = this.locationsWithKeys(this.with_client_site)
    },
    selected_parent_class(parentClass) {
      this.form.parent_class_id = parentClass?.id
    },
    'form.equipment_enabled'(newVal) {
      if (!newVal) {
        this.form.equipment_1 = null
        this.form.equipment_2 = null
        this.form.equipment_3 = null
      }
    },
  },
  methods: {
    locationsWithKeys(withClientSite) {
      return this.locations
        .filter((location) => {
          return withClientSite === true || location.client_site === false
        })
        .map((location, key) => {
          return { key: key, ...location }
        })
    },
    submit() {
      if (
        this.class_.offline_test_id !== this.form.offline_test_id &&
        !confirm(
          this.$t(
            'By changing the test associated with this class, the total score and the passing score will change for students not yet submitted to the Ministry of Labour. Would you like to continue?'
          )
        )
      ) {
        return
      }

      if (
        this.class_.has_subclasses &&
        (this.class_.location?.id != this.form.location?.id || this.class_.room_id != this.form.location?.room?.id) &&
        !confirm(
          this.$t(
            'Changing the location of this class will also change the location of the subclasses to match the parent. Do you want to continue?'
          )
        )
      ) {
        return
      }

      if (
        this.class_.parent_class &&
        (this.class_.location?.id != this.form.location?.id || this.class_.room_id != this.form.location?.room?.id) &&
        !confirm(this.$t('Cannot change the location of this class because it has a parent class.'))
      ) {
        return
      }

      this.form
        .transform((data) => {
          return {
            location_id: data.location ? data.location.id : null,
            room_id: data.location && data.location.room ? data.location.room.id : null,
            capacity: data.capacity,
            price: data.price,
            instructor_notes: data.instructor_notes,
            coordinator_notes: data.coordinator_notes,
            test_scores_enabled: this.form.test_scores_enabled,
            equipment_enabled: this.form.equipment_enabled,
            default_equipment_types: this.form.equipment_enabled
              ? [this.form.equipment_1, this.form.equipment_2, this.form.equipment_3]
              : null,
            offline_test_id: this.form.offline_test_id,
            parent_class_id: this.form.parent_class_id,
            locale: this.form.locale,
            attachments: this.form.attachments,
            course_id: this.form.course_id,
          }
        })
        .put(this.route('classes.update', this.class_.id))
    },
    makePrivate() {
      router.post(this.route('classes.private.create', this.class_.id))
    },
    makePublic() {
      router.delete(this.route('classes.private.destroy', this.class_.id))
    },
    getFormattedTestName(test) {
      let testName = ''
      if (test.name) {
        testName = `${test.name}, `
      }
      testName += this.$t('Total: :total_score, Passing: :passing_score', {
        total_score: `${test.total_score}`,
        passing_score: `${test.passing_score}`,
      })

      return testName
    },
  },
}
</script>
