<template>
  <front-full-screen-layout :title="$t('Forms')">
    <div
      class="sm:no-flex sticky top-0 z-10 mb-8 flex w-full items-center justify-between border-b border-gray-100 bg-white px-8 py-4"
    >
      <breadcrumb
        :name="formFilled.form_template?.title"
        :previous-name="$t('Forms')"
        :previous-url="route('front.filledForms.index')"
      />
      <InertiaLink :href="route('front.filledForms.index')">
        <Icon class="h-4 w-4 fill-current text-gray-600" name="close" />
      </InertiaLink>
    </div>
    <div class="mx-auto max-w-md">
      <header class="relative mb-3 w-full cursor-pointer border bg-white px-4 py-4 hover:bg-gray-50">
        <Popover class="mb-3">
          <PopoverButton class="w-full">
            <nav class="mx-auto flex items-center justify-between">
              <div class="text-left">
                <div class="mb-3 inline-flex items-center">
                  <ChevronDownIcon aria-hidden="true" class="ui-open:rotate-180 mr-1 h-5 w-5 transition" />
                  <p class="text-sm text-gray-600">
                    {{
                      $t('Page :page_number of :total_pages', {
                        page_number: currentPage.number,
                        total_pages: totalNumberOfPages,
                      })
                    }}
                  </p>
                </div>
                <p class="text-m font-semibold text-gray-900">{{ currentPage.formAnswer.form_content.title }}</p>
              </div>
              <div class="text-right">
                <p class="mb-3 text-sm text-gray-600">{{ $t('Score') }}</p>
                <p class="text-m font-semibold text-gray-900">
                  {{
                    $t(':filled_count / :total_items (:total_items_completed_percentage%)', {
                      filled_count: currentPage.formAnswer.form_answerable.filled_count,
                      total_items: currentPage.items.length,
                      total_items_completed_percentage: (
                        (currentPage.formAnswer.form_answerable.filled_count * 100) /
                        currentPage.items.length
                      ).toFixed(2),
                    })
                  }}
                </p>
              </div>
            </nav>
            <transition
              enter-active-class="transition ease-out duration-200"
              enter-from-class="opacity-0 translate-y-1"
              enter-to-class="opacity-100 translate-y-0"
              leave-active-class="transition ease-in duration-150"
              leave-from-class="opacity-100 translate-y-0"
              leave-to-class="opacity-0 translate-y-1"
            >
              <PopoverPanel
                class="absolute top-24 z-10 -ml-4 mt-1 w-full rounded bg-white px-2 shadow ring-1 ring-gray-900/5"
              >
                <div class="rounded bg-white py-2">
                  <div
                    v-for="(page, key, index) in pages"
                    :key="page.id"
                    class="rounded text-left text-sm font-semibold text-gray-800"
                  >
                    <button
                      class="block w-full cursor-pointer px-2 py-3 text-left hover:bg-gray-100"
                      @click="updatePage(index)"
                    >
                      {{ page.form_content.title }}
                    </button>
                  </div>
                </div>
              </PopoverPanel>
            </transition>
          </PopoverButton>
        </Popover>
      </header>
      <main>
        <div v-for="formAnswer in formAnswers" :key="formAnswer.id" class="max-w-md">
          <front-form-fillable-item
            :error="getFormError(formAnswer.id)"
            :form-answer="formAnswer"
            :priority-options="priorityOptions"
          />
        </div>
      </main>

      <footer class="flex justify-between">
        <front-outline-button-link
          v-if="currentPage.can.accessPreviousPage"
          class="py-3"
          :href="route('front.filledForms.edit', { formFilled: formFilled.id, page: currentPage.number - 1 })"
        >
          {{ $t('Previous page') }}
        </front-outline-button-link>
        <span v-else />
        <front-button-link
          v-if="currentPage.can.accessNextPage"
          :href="route('front.filledForms.edit', { formFilled: formFilled.id, page: currentPage.number + 1 })"
        >
          {{ $t('Next page') }}
        </front-button-link>
        <front-solid-button v-if="currentPage.can.complete" @click="complete">
          {{ $t('Complete') }}
        </front-solid-button>
      </footer>
    </div>
  </front-full-screen-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontButtonLink from '@/Shared/FrontButtonLink.vue'
import FrontFormFillableItem from '@/Shared/FrontFormFillableItem.vue'
import FrontFullScreenLayout from '@/Shared/FrontFullScreenLayout.vue'
import FrontOutlineButtonLink from '@/Shared/FrontOutlineButtonLink.vue'
import FrontSolidButton from '@/Shared/FrontSolidButton.vue'
import Icon from '@/Shared/Icon.vue'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    FrontFormFillableItem,
    FrontSolidButton,
    FrontButtonLink,
    ChevronDownIcon,
    Icon,
    Popover,
    PopoverButton,
    PopoverPanel,
    FrontOutlineButtonLink,
    Breadcrumb,
    FrontFullScreenLayout,
  },
  provide() {
    return {
      getFormValue: this.getFormValue,
      updateFormValue: this.updateFormValue,
    }
  },
  props: {
    formFilled: Object,
    pages: Object,
    currentPage: Object,
    currentPageNumber: Number,
    totalNumberOfPages: Number,
    priorityOptions: Object,
  },
  data() {
    const newList = {}
    this.currentPage.items.forEach(
      (item) =>
        (newList[item.id] = {
          id: item.id,
          value: item.value ?? null,
          notes: item.notes ?? null,
          images: item.images,
        })
    )
    return {
      form: useForm(`FormFilled:${this.formFilled.id}-${this.currentPageNumber}`, { items: newList }),
    }
  },
  computed: {
    formAnswers() {
      return this.listToTree(this.currentPage.items, this.currentPage.formAnswer.form_content.uuid)
    },
  },
  methods: {
    listToTree(list, parent = null) {
      let tree = {}

      // Find all children of the current parent node
      let children = list.filter((node) => node.form_content.parent_id === parent)

      // Recursively build the tree for each child
      children.forEach((child) => {
        tree[child.id] = {
          ...child,
          children: this.listToTree(list, child.form_content.uuid),
        }
      })

      return tree
    },
    getFormValue(id) {
      return this.form.items[id]
    },
    getFormError(id) {
      return this.form.errors[`items.${id}.value`] || this.$inertia.page?.props?.errors[`items.${id}.value`] || null
    },
    updatePage(index) {
      this.$inertia.get(this.route('front.filledForms.edit', this.formFilled), { page: index + 1 })
    },
    updateFormValue(id, data) {
      this.form.items[id] = { ...this.form.items[id], ...data }
      this.save()
    },
    save() {
      this.form
        .transform((data) => ({ _method: 'put', ...data }))
        .post(this.route('front.filledForms.update', this.formFilled.id), { preserveScroll: true })
    },
    complete() {
      this.$inertia.get(
        this.route('front.filledForms.complete', this.formFilled.id),
        {},
        {
          preserveState: false,
        }
      )
    },
  },
}
</script>
