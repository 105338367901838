<template>
  <worksite-dark v-if="name === 'dark app.worksite.ca'" />
  <worksite-white v-else-if="name === 'white app.worksite.ca'" />
  <qualify v-else />
</template>

<script>
import Qualify from '@/Shared/Logo/Qualify.vue'
import WorksiteDark from '@/Shared/Logo/WorksiteDark.vue'
import WorksiteWhite from '@/Shared/Logo/WorksiteWhite.vue'

export default {
  components: {
    Qualify,
    WorksiteDark,
    WorksiteWhite,
  },
  props: {
    name: String,
  },
}
</script>
