<template>
  <signature-type-layout :form-filled="formFilled" :is-last="isLast" :signature="signature">
    <div class="mb-4 flex w-full flex-col justify-between xl:flex-row xl:items-center">
      <div>
        <small class="block font-semibold uppercase text-gray-500">
          {{
            $t(':signature_type Signature', {
              signature_type: signature.signature_type,
            })
          }}
        </small>
        <div class="mt-2 flex items-center">
          <p class="mr-4 text-sm font-semibold text-ts-gray-text-bold">
            {{ formFilled.user.name }}
          </p>
          <signature-status :signature-request="signatureRequest" />
        </div>
      </div>

      <div v-if="isSignatureRequestPending(signatureRequest)" class="mt-4 w-full pl-0 xl:mt-0 xl:w-auto xl:pl-4">
        <front-outline-button
          v-if="!showSignaturePad"
          class="inline-block w-full px-12 text-center"
          type="button"
          @click="openSignaturePad"
        >
          {{ $t('Sign') }}
        </front-outline-button>
        <front-outline-button
          v-else
          class="inline-block w-full px-12 text-center"
          type="button"
          @click="hideSignaturePad"
        >
          {{ $t('Close') }}
        </front-outline-button>
      </div>
    </div>
    <div v-if="showSignaturePad" class="w-full">
      <div class="mb-1 flex justify-between">
        <a v-if="!showSignaturePadUrl" class="link text-sm" @click="enableSignaturePadQrCode()">
          {{ $t('Sign on different device') }}
        </a>
        <a v-else class="link text-sm" @click="hideSignaturePadQrCode()">{{ $t('Sign on this device') }}</a>
        <button v-if="!showSignaturePadUrl" class="link text-sm" @click.prevent="clear">
          {{ $t('Clear Signature') }}
        </button>
      </div>

      <template v-if="!showSignaturePadUrl">
        <div class="h-32 w-full sm:h-64" style="max-height: 65vh">
          <vue-signature-pad ref="signaturePad" class="h-full w-full border" :options="{ onEnd }" />
        </div>
        <div class="mt-8 w-full">
          <front-outline-button
            class="inline-block px-12"
            :disabled="!form.image"
            type="button"
            @click="submitSignature"
          >
            {{ $t('Submit Signature') }}
          </front-outline-button>
        </div>
      </template>
      <div v-else class="flex flex-col items-center justify-center">
        <template v-if="signaturePadUrl">
          <qrcode-vue level="H" :size="200" :value="signaturePadUrl" />
          <span class="mt-2 text-sm">{{ $t('Use the QR code to sign from a different device') }}</span>
        </template>
        <div v-else class="flex h-32 w-full items-center justify-center sm:h-64" style="max-height: 65vh">
          <span>{{ $t('Loading...') }}</span>
        </div>
      </div>
    </div>
  </signature-type-layout>
</template>
<script>
import SignatureStatus from './SignatureStatus.vue'
import SignatureTypeLayout from './SignatureTypeLayout.vue'
import FrontOutlineButton from '@/Shared/FrontOutlineButton.vue'
import Http from '@/Utils/Http'
import { useForm } from '@inertiajs/vue3'
import QrcodeVue from 'qrcode.vue'
import trimCanvas from 'trim-canvas'
import { VueSignaturePad } from 'vue-signature-pad'

export default {
  components: { SignatureTypeLayout, SignatureStatus, QrcodeVue, VueSignaturePad, FrontOutlineButton },
  props: {
    signature: Object,
    formFilled: Object,
    signatureRequests: Object,
    isLast: Boolean,
  },
  data() {
    return {
      timer: null,
      showSignaturePad: false,
      showSignaturePadUrl: false,
      signaturePadUrl: null,
      form: useForm({
        image: null,
        signatureType: this.signature.signature_type,
      }),
    }
  },
  beforeUnmount() {
    this.stopTimer()
  },
  watch: {
    showSignaturePadUrl: function (newValue) {
      if (newValue) {
        this.startTimer()
      } else {
        this.stopTimer()
      }
    },
  },
  computed: {
    signatureRequest: function () {
      return this.signatureRequests.find((request) => request.signature_type === this.signature.signature_type)
    },
  },
  methods: {
    clear() {
      this.$refs.signaturePad.clearSignature()
      this.form.image = null
    },
    getTrimmedCanvas() {
      let copy = document.createElement('canvas')
      const signaturePadCanvas = this.$refs.signaturePad.$refs.signaturePadCanvas
      copy.width = signaturePadCanvas.width
      copy.height = signaturePadCanvas.height
      copy.getContext('2d').drawImage(signaturePadCanvas, 0, 0)
      return trimCanvas(copy)
    },
    submitSignature() {
      let trimmedCanvas = this.getTrimmedCanvas()
      this.form.image = trimmedCanvas.toDataURL('image/png')
      this.form.post(this.route('front.filledForms.signature.store', this.signatureRequest?.id), {
        preserveScroll: true,
        onSuccess: () => {
          this.hideSignaturePad()
          this.form.reset()
          this.form.clearErrors()
        },
      })
    },
    enableSignaturePadQrCode() {
      this.showSignaturePadUrl = true
      this.getSignaturePadUrl()
    },
    hideSignaturePadQrCode() {
      this.showSignaturePadUrl = false
    },
    getSignaturePadUrl() {
      Http.get(this.route('front.filledForms.signature-url', this.signatureRequest?.id)).then((response) => {
        this.signaturePadUrl = response.data.url
      })
    },
    startTimer() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.getSignaturePadUrl()
        }, 240000)
      }
    },
    stopTimer() {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    close() {
      this.$emit('close')
    },
    openSignaturePad() {
      this.showSignaturePad = true
    },
    hideSignaturePad() {
      this.showSignaturePad = false
    },
    onEnd() {
      let trimmedCanvas = this.getTrimmedCanvas()
      this.form.image = trimmedCanvas.toDataURL('image/png')
    },
    isSignatureRequestPending(request) {
      return !request.accepted_at && !request.expired_at
    },
  },
}
</script>
