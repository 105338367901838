<template>
  <front-layout :title="$t('Support')">
    <h1 class="mb-8 text-xl font-bold">
      {{ $t('We are here to') }}
      <span class="underline">{{ $t('help you.') }}</span>
      <div class="mt-8 text-2xl font-bold">
        {{ $t('Send us a message to get detailed help.') }}
      </div>
    </h1>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <label>
              {{ $t('What do you need help with?') }}
              <span class="ml-2 rounded-full bg-yellow-300 px-2 py-1 text-xs font-bold">
                {{ $t('REQUIRED') }}
              </span>
            </label>
            <select-input v-model="form.type" class="mt-4" :error="form.errors.type">
              <option :value="null">{{ $t('Please select one') }}...</option>
              <option value="certificate">{{ $t('I cannot get my certificate') }}</option>
              <option value="broken">{{ $t('I think something is broken') }}</option>
              <option value="confused">{{ $t('I’m confused about how something works') }}</option>
              <option value="feature">{{ $t('I want to request a feature') }}</option>
              <option value="other">{{ $t('Other') }}</option>
            </select-input>
          </div>
          <div class="w-full pb-8 pr-6">
            <label>
              {{ $t('What’s your question, comment, or issue?') }}
              <span class="ml-2 rounded-full bg-yellow-300 px-2 py-1 text-xs font-bold">
                {{ $t('REQUIRED') }}
              </span>
            </label>
            <textarea-input
              v-model="form.comment"
              :autosize="true"
              class="mt-4"
              :error="form.errors.comment"
              :rows="6"
            />
          </div>
          <div class="w-full pb-8 pr-6">
            <label>
              {{ $t('What’s your email address?') }}
              <span class="ml-2 rounded-full bg-yellow-300 px-2 py-1 text-xs font-bold">
                {{ $t('REQUIRED') }}
              </span>
            </label>
            <text-input v-model="form.email" class="mt-4" :error="form.errors.email" />
          </div>
          <div class="w-full pb-8 pr-6">
            <label>
              {{ $t('Send us a file, screenshot, or document.') }}
            </label>
            <input
              class="mt-4 block bg-gray-100 text-xs"
              multiple
              type="file"
              @change="form.files = $event.target.files"
            />
          </div>
        </div>
        <div class="flex items-center justify-between px-8 py-4">
          <loading-button class="btn-red-gradient text-base text-white" :loading="form.processing" type="submit">
            {{ 'Submit support request' }}
          </loading-button>
        </div>
      </form>
    </div>
  </front-layout>
</template>

<script>
import FrontLayout from '@/Shared/FrontLayout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    FrontLayout,
    LoadingButton,
    SelectInput,
    TextInput,
    TextareaInput,
  },
  props: {},
  data() {
    return {
      form: useForm({
        type: null,
        comment: null,
        files: null,
        email: this.$page.props.auth.user.email || null,
      }),
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('front.support.store'))
    },
  },
}
</script>
