<template>
  <div class="font-heading font-medium">
    <div class="mb-4">
      <menu-item class="text-ts-gray-bold mb-2 font-semibold" :name="$t('Team')" />
      <menu-item-manager
        icon="user"
        :name="$t('People')"
        :route-link="route('front.manager.users')"
        url="account/manager/users*"
      />
      <menu-item-manager
        icon="users"
        :name="$t('Groups')"
        :route-link="route('front.manager.groups')"
        url="account/manager/groups*"
      />
      <menu-item-manager
        v-if="canManageLocations"
        icon="office"
        :name="$t('Locations')"
        :route-link="route('front.manager.locations')"
        url="account/manager/locations*"
      />
      <menu-item-manager
        icon="key"
        :name="$t('Access Codes')"
        :route-link="route('front.manager.access-codes')"
        url="account/manager/access-codes*"
      />
    </div>
    <div class="mb-4">
      <menu-item class="text-ts-gray-bold mb-2 font-semibold" :name="$t('Management')" />
      <menu-item-manager
        icon="book"
        :name="$t('Courses')"
        :route-link="route('front.manager.courses')"
        url="account/manager/courses*"
      />
      <menu-item-manager
        icon="certificate"
        :name="$t('Certificates')"
        :route-link="route('front.manager.certificates')"
        url="account/manager/certificates*"
      />
      <menu-item-manager
        v-if="hasAssignedSubscription"
        icon="queue-list"
        :name="$t('Actions')"
        :route-link="route('front.manager.actions')"
        url="account/manager/actions*"
      />
      <menu-item-manager
        v-if="hasSubscription"
        icon="view-list"
        :name="$t('Subscriptions')"
        :route-link="route('front.manager.subscriptions')"
        url="account/manager/subscriptions*"
      />
      <menu-item-manager
        icon="graph"
        :name="$t('Reports')"
        :route-link="route('front.manager.reports.courses.show')"
        url="account/manager/reports*"
      />
    </div>
    <menu-item
      v-if="$page.props.app.tenant.phone && $page.props.app.tenant.email"
      class="text-ts-gray-bold mb-2 font-semibold"
      :name="$t('Support')"
    />
    <front-phone-menu-item />
    <front-email-menu-item />
    <front-divider-menu-item />
    <div>
      <front-back-office-admin-menu-item />
      <front-logout-menu-item />
    </div>
  </div>
</template>

<script>
import FrontBackOfficeAdminMenuItem from './FrontBackOfficeAdminMenuItem.vue'
import FrontDividerMenuItem from './FrontDividerMenuItem.vue'
import FrontEmailMenuItem from './FrontEmailMenuItem.vue'
import FrontLogoutMenuItem from './FrontLogoutMenuItem.vue'
import FrontPhoneMenuItem from './FrontPhoneMenuItem.vue'
import MenuItem from './MenuItem.vue'
import MenuItemManager from './MenuItemManager.vue'
import { isUrl } from '@/Utils/Helpers'

export default {
  components: {
    FrontBackOfficeAdminMenuItem,
    FrontDividerMenuItem,
    FrontEmailMenuItem,
    FrontLogoutMenuItem,
    FrontPhoneMenuItem,
    MenuItem,
    MenuItemManager,
  },
  computed: {
    hasSubscription() {
      return this.$page.props.auth.user.organization?.has_subscription
    },
    hasAssignedSubscription() {
      return this.$page.props.auth.user.has_ehs_access
    },
    canManageLocations() {
      return ['admin', 'locationManager'].includes(this.$page.props.auth.user.organization_role)
    },
  },
  methods: {
    isUrl,
  },
}
</script>
