<template>
  <div>
    <label>
      <span class="form-label">
        {{ label }}
        <span v-if="required" class="text-red-500">*</span>
      </span>
      <input
        ref="input"
        v-bind="$attrs"
        :autocomplete="autocomplete ? autocomplete : 'on'"
        class="form-input"
        :class="[cssClass, error ? 'error' : null]"
        :required="required"
        :type="type ? type : 'text'"
        :value="modelValue"
        @change="$emit('update:modelValue', $event.target.value)"
      />
    </label>
    <div v-if="error" class="form-error">
      <span v-if="Array.isArray(error)">{{ error[0] }}</span>
      <span v-else>{{ error }}</span>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    id: String,
    modelValue: [String, Number],
    label: String,
    cssClass: String,
    error: [String, Array],
    type: String,
    autocomplete: String,
    required: Boolean,
  },
  emits: ['update:modelValue'],
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
    setSelectionRange(start, end) {
      this.$refs.input.setSelectionRange(start, end)
    },
  },
}
</script>
