<template>
  <front-layout :title="$t('Order Plastic Cards')">
    <div class="max-w-4xl">
      <div
        v-if="form.step === 1 && form.errors.selected_certificates"
        class="mb-8 flex max-w-lg items-center justify-between rounded bg-red-400"
      >
        <div class="flex items-center">
          <icon class="ml-4 mr-2 h-4 w-4 flex-shrink-0 fill-white" name="close-outline" />
          <div class="py-4 text-sm font-medium text-white">{{ form.errors.selected_certificates }}</div>
        </div>
      </div>
      <div v-if="!organization.address" class="mb-8 flex max-w-lg items-center justify-between rounded bg-red-400">
        <div class="flex items-center">
          <icon class="ml-4 mr-2 h-4 w-4 flex-shrink-0 fill-white" name="close-outline" />
          <div class="py-4 text-sm font-medium text-white">
            {{ $t('An Organization address is required for order processing. You may specify one in your') }}
            <a class="underline" :href="route('front.admin.settings')" target="_blank">
              {{ $t('Organization Settings') }}
            </a>
            .
          </div>
        </div>
      </div>
      <div class="flex flex-col items-center xl:flex-row xl:justify-between">
        <div class="mb-8 flex w-full flex-col justify-start">
          <breadcrumb class="hidden md:inline" :name="$t('Order Plastic Certificate Cards')" />
          <breadcrumb class="md:hidden" :name="$t('Order Plastic Cards')" />
          <span v-if="form.step === 2" class="mt-3">{{ $t('Enter your information and payment method.') }}</span>
        </div>
        <div v-if="form.step === 1" class="mb-6 flex w-full items-center justify-between xl:justify-end">
          <front-search-filter
            v-model="filter_form.search"
            class="flex w-full max-w-md flex-col sm:flex-row"
            :filter-show="true"
            @reset="reset"
          >
            <div class="flex flex-col items-center justify-between md:flex-row">
              <div v-if="courses" class="ml-0 mt-2 w-full sm:ml-4 sm:mt-0 md:w-1/2">
                <label class="sr-only block text-gray-800">{{ $t('Course:') }}</label>
                <select
                  v-model="filter_form.course"
                  class="form-select w-full max-w-md rounded-lg border border-ts-gray-300 py-2 pl-2 pr-8 leading-normal text-ts-gray-text focus:ring"
                >
                  <option disabled :value="null">{{ $t('Filter Course') }}</option>
                  <option :value="null">{{ $t('All') }}</option>
                  <option v-for="course in courses" :key="course.id" :value="course.id">
                    {{ course.name }}
                  </option>
                </select>
              </div>
              <div v-if="groups" class="ml-0 mt-2 w-full sm:ml-4 sm:mt-0 md:w-1/2">
                <label class="sr-only block text-gray-800">{{ $t('Group:') }}</label>
                <select
                  v-model="filter_form.group"
                  class="form-select w-full max-w-md rounded-lg border border-ts-gray-300 py-2 pl-2 pr-8 leading-normal text-ts-gray-text focus:ring"
                >
                  <option disabled :value="null">{{ $t('Filter Group') }}</option>
                  <option :value="null">{{ $t('All') }}</option>
                  <option v-for="group in groups" :key="group.id" :value="group.id">
                    {{ group.name }}
                  </option>
                </select>
              </div>
            </div>
          </front-search-filter>
        </div>
      </div>
      <div v-if="form.step === 1" class="overflow-x-auto rounded bg-white shadow-lg">
        <table class="front-table">
          <thead>
            <tr>
              <th
                v-if="!allCertificatesSelected() && hasSelectableCertificates()"
                class="cursor-pointer whitespace-nowrap text-ts-blue-600"
                @click="selectAllCertificates"
              >
                {{ $t('Select All') }}
              </th>
              <th
                v-if="allCertificatesSelected() && hasSelectableCertificates()"
                class="cursor-pointer whitespace-nowrap text-ts-blue-600"
                @click="unselectAllCertificates"
              >
                {{ $t('Unselect All') }}
              </th>
              <th>{{ $t('Person') }}</th>
              <th>{{ $t('Cert. Name') }}</th>
              <th>{{ $t('Language') }}</th>
              <th>{{ $t('Issue Date') }}</th>
              <th>{{ $t('Expiry Date') }}</th>
              <th />
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr v-if="!certificates.data.length">
              <td class="border-t px-6 py-4" colspan="5">{{ $t('No certificates found.') }}</td>
            </tr>
            <tr v-for="(certificate, index) in certificates.data" :key="index">
              <td>
                <front-row-select-checkbox
                  v-if="certificate.status !== 'Expired'"
                  :id="'checkbox-' + certificate.id"
                  v-model="form.selected_certificates"
                  :value="certificate"
                />
              </td>
              <td class="cursor-default whitespace-nowrap px-6 py-4 leading-5 text-ts-gray-text">
                {{ certificate.user.name }}
              </td>
              <td class="cursor-default whitespace-nowrap px-6 py-4 leading-5 text-ts-gray-text">
                {{ certificate.name }}
              </td>
              <td class="cursor-default whitespace-nowrap px-6 py-4 leading-5 text-ts-gray-text">
                {{ certificate.issued_at }}
              </td>
              <td class="cursor-default whitespace-nowrap px-6 py-4 leading-5 text-ts-gray-text">
                {{ certificate.expires_at }}
              </td>
              <td class="cursor-default whitespace-nowrap px-6 py-4 leading-5 text-ts-gray-text">
                <span
                  class="cursor-default rounded bg-opacity-8 p-2 leading-5"
                  :class="{
                    'bg-ts-blue-600 text-ts-blue-600': certificate.status === 'Active',
                    'bg-ts-orange-600 text-ts-orange-600': certificate.status === 'Expiring',
                    'bg-qualify-red-500 text-qualify-red-500': certificate.status === 'Expired',
                  }"
                >
                  {{ certificate.status }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <front-pagination v-if="form.step === 1" :links="certificates.links" />
      <div v-show="form.step === 2" class="flex flex-wrap">
        <div class="mb-6 w-full md:mb-0 md:w-1/2 md:pr-3">
          <div class="mb-6 flex w-full flex-col rounded bg-white p-6 shadow-lg">
            <div class="grid w-full grid-cols-2">
              <div class="col-span-2 p-3">
                <p class="mb-3 text-xl font-semibold">{{ $t('Confirm Information') }}</p>
              </div>
              <div class="col-span-2 p-3 md:col-span-1">
                <label class="mb-2 block text-lg text-darkGray-700" for="first_name">{{ $t('First Name') }}</label>
                <input
                  id="first_name"
                  v-model="form.first_name"
                  class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                  required
                  type="text"
                />
                <div v-if="form.errors.first_name" class="form-error">{{ form.errors.first_name }}</div>
              </div>
              <div class="col-span-2 p-3 md:col-span-1">
                <label class="mb-2 block text-lg text-darkGray-700" for="last_name">{{ $t('Last Name') }}</label>
                <input
                  id="last_name"
                  v-model="form.last_name"
                  class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                  required
                  type="text"
                />
                <div v-if="form.errors.last_name" class="form-error">{{ form.errors.last_name }}</div>
              </div>
              <div class="col-span-2 p-3">
                <canada-post-address-complete-input
                  v-if="$page.props.app.tenant.has_address_complete_api_enabled && canFindBillingAddress"
                  ref="billing_address_complete"
                  v-model="billing_address_complete"
                  class="w-full"
                  :label="$t('Address')"
                  label-class="text-darkGray-700 text-lg"
                  required
                  :search-by="['Text']"
                  search-input-class="bg-gray-100 rounded-lg"
                  track-by="Id"
                  @update:enable-a-p-i="canFindBillingAddress = false"
                >
                  <div v-if="form.address" class="flex items-center justify-between">
                    <div class="truncate">{{ form.address }}</div>
                  </div>
                  <template #option="{ option, selected }">
                    <div class="flex items-center justify-between">
                      <div class="text-md" :class="{ 'text-gray-600': !selected, 'text-white': selected }">
                        {{ option.Text.concat(' ', option.Description) }}
                      </div>
                      <icon
                        v-if="option.Next == 'Find'"
                        class="ml-2 h-3 w-3 flex-shrink-0 fill-gray-400"
                        name="chevron-right"
                      />
                    </div>
                  </template>
                </canada-post-address-complete-input>
                <template v-else>
                  <label class="mb-2 block text-lg text-darkGray-700" for="address">{{ $t('Address') }}</label>
                  <input
                    id="address"
                    v-model="form.address"
                    class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                    required
                    type="text"
                  />
                </template>
                <div v-if="form.errors.address" class="form-error">{{ form.errors.address }}</div>
              </div>
              <div class="col-span-2 p-3 md:col-span-1">
                <label class="mb-2 block text-lg text-darkGray-700" for="city">{{ $t('City') }}</label>
                <input
                  id="city"
                  v-model="form.city"
                  class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                  required
                  type="text"
                />
                <div v-if="form.errors.city" class="form-error">{{ form.errors.city }}</div>
              </div>
              <div class="col-span-2 p-3 md:col-span-1">
                <label class="mb-2 block text-lg text-darkGray-700" for="province">{{ $t('Province') }}</label>
                <select
                  id="province"
                  v-model="form.province"
                  class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                >
                  <slot>
                    <option v-for="(province, key) in getRegions" :key="key" :value="province.name">
                      {{ province.name }}
                    </option>
                  </slot>
                </select>
                <div v-if="form.errors.province" class="form-error">{{ form.errors.province }}</div>
              </div>
              <div class="col-span-2 p-3 md:col-span-1">
                <label class="mb-2 block text-lg text-darkGray-700" for="postal_code">{{ $t('Postal Code') }}</label>
                <input
                  id="postal_code"
                  v-model="form.postal_code"
                  class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                  required
                  type="text"
                />
                <div v-if="form.errors.postal_code" class="form-error">{{ form.errors.postal_code }}</div>
              </div>
              <div class="col-span-2 p-3 md:col-span-1">
                <label class="mb-2 block text-lg text-darkGray-700" for="country">{{ $t('Country') }}</label>
                <select
                  id="country"
                  v-model="form.country"
                  class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                  @change="handleBillingCountryChange($event)"
                >
                  <slot>
                    <option v-for="(country, key) in getCountries" :key="key" :value="country.code">
                      {{ country.name }}
                    </option>
                  </slot>
                </select>
                <div v-if="form.errors.country" class="form-error">{{ form.errors.country }}</div>
              </div>
            </div>
            <div class="px-3 py-6">
              <input
                id="ship_to_different_address"
                v-model="form.different_shipping_address"
                class="rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                type="checkbox"
              />
              <label class="mb-2 ml-2 text-base text-darkGray-700" for="ship_to_different_address">
                {{ $t('Ship to Different Address') }}
              </label>
            </div>
            <div v-if="form.different_shipping_address" class="grid w-full grid-cols-2">
              <div v-if="form.different_shipping_address" class="col-span-2 p-3">
                <p class="mb-8 text-xl font-semibold">{{ $t('Shipping Address') }}</p>
                <canada-post-address-complete-input
                  v-if="$page.props.app.tenant.has_address_complete_api_enabled && canFindShippingAddress"
                  ref="shipping_address_complete"
                  v-model="shipping_address_complete"
                  class="w-full"
                  :label="$t('Address')"
                  label-class="text-darkGray-700 text-lg"
                  required
                  :search-by="['Text']"
                  search-input-class="bg-gray-100 rounded-lg"
                  track-by="Id"
                  @update:enable-a-p-i="canFindShippingAddress = false"
                >
                  <div v-if="form.shipping_address" class="flex items-center justify-between">
                    <div class="truncate">{{ form.shipping_address }}</div>
                  </div>
                  <template #option="{ option, selected }">
                    <div class="flex items-center justify-between">
                      <div class="text-md" :class="{ 'text-gray-600': !selected, 'text-white': selected }">
                        {{ option.Text.concat(' ', option.Description) }}
                      </div>
                      <icon
                        v-if="option.Next == 'Find'"
                        class="ml-2 h-3 w-3 flex-shrink-0 fill-gray-400"
                        name="chevron-right"
                      />
                    </div>
                  </template>
                </canada-post-address-complete-input>
                <template v-else>
                  <label class="mb-2 block text-lg text-darkGray-700" for="shipping_address">{{ $t('Address') }}</label>
                  <input
                    id="shipping_address"
                    v-model="form.shipping_address"
                    class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                    required
                    type="text"
                  />
                </template>
                <div v-if="form.errors.shipping_address" class="form-error">{{ form.errors.shipping_address }}</div>
              </div>
              <div v-if="form.different_shipping_address" class="col-span-2 p-3 md:col-span-1">
                <label class="mb-2 block text-lg text-darkGray-700" for="shipping_city">{{ $t('City') }}</label>
                <input
                  id="shipping_city"
                  v-model="form.shipping_city"
                  class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                  required
                  type="text"
                />
                <div v-if="form.errors.shipping_city" class="form-error">{{ form.errors.shipping_city }}</div>
              </div>
              <div v-if="form.different_shipping_address" class="col-span-2 p-3 md:col-span-1">
                <label class="mb-2 block text-lg text-darkGray-700" for="shipping_province">{{ $t('Province') }}</label>
                <select
                  id="shipping_province"
                  v-model="form.shipping_province"
                  class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                >
                  <slot>
                    <option v-for="(province, key) in getShippingRegions" :key="key" :value="province.name">
                      {{ province.name }}
                    </option>
                  </slot>
                </select>
                <div v-if="form.errors.shipping_province" class="form-error">{{ form.errors.shipping_province }}</div>
              </div>
              <div v-if="form.different_shipping_address" class="col-span-2 p-3 md:col-span-1">
                <label class="mb-2 block text-lg text-darkGray-700" for="shipping_postal_code">
                  {{ $t('Postal Code') }}
                </label>
                <input
                  id="shipping_postal_code"
                  v-model="form.shipping_postal_code"
                  class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                  required
                  type="text"
                />
                <div v-if="form.errors.shipping_postal_code" class="form-error">
                  {{ form.errors.shipping_postal_code }}
                </div>
              </div>
              <div v-if="form.different_shipping_address" class="col-span-2 p-3 md:col-span-1">
                <label class="mb-2 block text-lg text-darkGray-700" for="shipping_country">{{ $t('Country') }}</label>
                <select
                  id="shipping_country"
                  v-model="form.shipping_country"
                  class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                  @change="handleShippingCountryChange($event)"
                >
                  <slot>
                    <option v-for="(country, key) in getCountries" :key="key" :value="country.code">
                      {{ country.name }}
                    </option>
                  </slot>
                </select>
                <div v-if="form.errors.shipping_country" class="form-error">{{ form.errors.shipping_country }}</div>
              </div>
            </div>
          </div>
          <div v-if="tenant.plastic_card_price > 0" class="w-full rounded bg-white p-6 shadow-lg">
            <div class="p-3">
              <p class="mb-3 text-xl font-semibold">{{ $t('Payment Method') }}</p>
            </div>
            <div class="p-3">
              <div id="card-element" />
              <div v-if="stripe_error" class="form-error">{{ stripe_error }}</div>
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/2 md:pl-3">
          <div class="w-full overflow-scroll rounded bg-white shadow-lg md:overflow-x-auto" style="max-height: 60vh">
            <table class="front-table rounded">
              <thead class="bg-ts-front-table-header">
                <tr>
                  <th>{{ $t('Person') }}</th>
                  <th>{{ $t('Certificate') }}</th>
                  <th>{{ $t('Language') }}</th>
                  <th>{{ $t('Expires') }}</th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-if="!form.checkout_certificates.length">
                  <td class="animate-pulse border-t px-6 py-4" colspan="3">{{ $t('Loading...') }}</td>
                </tr>
                <tr v-for="certificate in form.checkout_certificates" :key="certificate.id">
                  <td>{{ certificate.user.name }}</td>
                  <td>{{ certificate.name }}</td>
                  <td>
                    <front-select-input v-model="certificate.locale">
                      <option v-for="(locale, key) in locales" :key="key" :value="locale">
                        {{ key }}
                      </option>
                    </front-select-input>
                  </td>
                  <td>{{ certificate.expires_at }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="mb-2 mt-10 flex items-center justify-end">
        <front-secondary-button
          v-if="!form.processing"
          class="mx-2 px-12 py-4 md:px-20"
          type="button"
          @click="form.step === 1 ? cancel() : back()"
        >
          {{ cancel_button_label }}
        </front-secondary-button>
        <front-loading-button
          class="mx-2 px-12 py-4 md:px-20"
          :disabled="!organization.address"
          :loading="form.processing"
          @click="next"
        >
          <span v-if="form.step === 2">
            {{ confirm_order_button_label }}
          </span>
          <span v-else>{{ $t('Next') }}</span>
        </front-loading-button>
      </div>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import CanadaPostAddressCompleteInput from '@/Shared/CanadaPostAddressCompleteInput.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontPagination from '@/Shared/FrontPagination.vue'
import FrontRowSelectCheckbox from '@/Shared/FrontRowSelectCheckbox.vue'
import FrontSearchFilter from '@/Shared/FrontSearchFilter.vue'
import FrontSecondaryButton from '@/Shared/FrontSecondaryButton.vue'
import FrontSelectInput from '@/Shared/FrontSelectInput.vue'
import Icon from '@/Shared/Icon.vue'
import Http from '@/Utils/Http'
import { useForm } from '@inertiajs/vue3'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    Breadcrumb,
    CanadaPostAddressCompleteInput,
    FrontLayout,
    FrontLoadingButton,
    FrontPagination,
    FrontRowSelectCheckbox,
    FrontSearchFilter,
    FrontSecondaryButton,
    FrontSelectInput,
    Icon,
  },
  props: {
    filters: Object,
    tenant: Object,
    courses: Array,
    groups: Array,
    regions: Array,
    locales: Object,
    organization: Object,
    certificates: Object,
    allCertificatesWithIds: Array,
  },
  data() {
    return {
      intent: null,
      stripe_error: null,
      setup_intent: null,
      form: useForm({
        step: 1,
        selected_certificates: [],
        checkout_certificates: [],
        first_name: null,
        last_name: null,
        address: null,
        city: null,
        province: null,
        postal_code: null,
        country: 'CA',
        different_shipping_address: false,
        shipping_address: null,
        shipping_city: null,
        shipping_province: null,
        shipping_postal_code: null,
        shipping_country: 'CA',
      }),
      filter_form: {
        search: this.filters.search,
        course: this.filters.course,
        group: this.filters.group,
      },
      billing_address_complete: null,
      shipping_address_complete: null,
      canFindBillingAddress: true,
      canFindShippingAddress: true,
    }
  },
  computed: {
    cancel_button_label() {
      return this.form.step === 1 ? 'Cancel' : 'Back'
    },
    confirm_order_button_label() {
      if (this.form.step === 2 && this.tenant.plastic_card_price > 0 && this.form.selected_certificates.length > 0) {
        const price = Math.round((this.tenant.plastic_card_price / 100) * this.form.selected_certificates.length)
        return `this.$t('Confirm Order') (${price} + tax)`
      }
      return this.$t('Confirm Order')
    },
    getRegions() {
      return this.regions.filter((region) => region.country === this.form.country)
    },
    getShippingRegions() {
      return this.regions.filter((region) => region.country === this.form.shipping_country)
    },
    getCountries() {
      return [
        { name: this.$t('Canada'), code: 'CA' },
        { name: this.$t('United States'), code: 'US' },
      ]
    },
  },
  watch: {
    filter_form: {
      handler: _throttle(function () {
        this.unselectAllCertificates()

        let query = _pickBy(this.filter_form)

        this.$inertia.get(
          this.route('front.manager.plastic-cards'),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
    'form.different_shipping_address': function () {
      this.form.reset(
        'shipping_address',
        'shipping_city',
        'shipping_province',
        'shipping_postal_code',
        'shipping_country'
      )
    },
    'form.country': function () {
      this.form.reset('province')
    },
    'form.shipping_country': function () {
      this.form.reset('shipping_province')
    },
    'form.selected_certificates': {
      handler(certificates, oldCertificates) {
        if (!localStorage.selectedCertificates) {
          // If there are no certificates selected, save all
          // selected certificates to local storage.
          localStorage.selectedCertificates = JSON.stringify(certificates.map((c) => c.id))
        } else if (oldCertificates.length > certificates.length) {
          // If a certificate was de-selected, remove it from local storage.
          this.removeCertificateFromLocalStorage(certificates, oldCertificates)
        } else {
          // Finally, add newly selected certificate to local storage.
          this.addCertificateToLocalStorage(certificates)
        }

        localStorage.selectedCertificatesExpiry = new Date().getTime() + 60 * 60 * 1000
      },
      deep: true,
    },
    billing_address_complete: function (newVal, oldVal) {
      this.form.address = newVal?.Line1
      this.form.city = newVal?.City
      this.form.postal_code = newVal?.PostalCode
      this.form.province = newVal?.ProvinceName
      this.form.country = newVal ? newVal.CountryIso2 : oldVal.CountryIso2
    },
    shipping_address_complete: function (newVal, oldVal) {
      this.form.shipping_address = newVal?.Line1
      this.form.shipping_city = newVal?.City
      this.form.shipping_postal_code = newVal?.PostalCode
      this.form.shipping_province = newVal?.ProvinceName
      this.form.shipping_country = newVal ? newVal.CountryIso2 : oldVal.CountryIso2
    },
  },
  async mounted() {
    // Setting the selected certificates from local storage.
    if (this.form.step === 1) {
      this.clearLocalStorageIfExpired()
      this.setSelectedCertificatesFromLocalStorage()
    }

    if (this.tenant.plastic_card_price > 0) {
      this.setIntent()
      this.stripe = Stripe(import.meta.env.VITE_STRIPE_KEY)
      const elements = this.stripe.elements()
      this.cardElement = elements.create('card', {
        classes: {
          base: 'block w-full px-3 py-3 border border-gray-200 bg-gray-100 text-ts-front-label rounded-lg transition duration-150 ease-in-out',
        },
        style: {
          base: {
            fontWeight: '300',
            fontSize: '17px',
            color: '#2d3748',
          },
        },
      })
      this.cardElement.mount('#card-element')
    }
  },
  methods: {
    reset() {
      this.filter_form = _mapValues(this.filter_form, () => null)
    },
    cancel() {
      this.$inertia.visit(this.route('front.manager.certificates'))
    },
    back() {
      this.form.step--
      // Reset checkout certificates since new selections
      // can be made in step 1 of the order process.
      this.form.checkout_certificates = []
    },
    next() {
      // If no organization address is set, don't allow.
      if (!this.organization.address) return

      // If no certificates are found in local storage, don't allow.
      if (!localStorage.selectedCertificates || JSON.parse(localStorage.selectedCertificates)?.length === 0) return

      // If already at final step, just submit.
      if (this.form.step === 2) return this.submit()

      let selected_certificates = JSON.parse(localStorage.selectedCertificates)

      this.form
        .transform((data) => ({
          ...data,
          selected_certificates: selected_certificates,
        }))
        .post(this.route('front.manager.order.plastic.store'), {
          onSuccess: (response) => {
            this.form.step = response.props.flash.form_step ?? 2
          },
        })

      // Get certificate data from IDs stored in local storage
      // for populating the plastic card order summary table.
      this.getSelectedCertificateData()
    },
    async submit() {
      if (!this.organization.address) return

      if (this.tenant.plastic_card_price > 0) {
        if (!this.intent) {
          this.stripe_error = this.$t('Something went wrong, order cannot be processed.')
        }

        if (!this.setup_intent?.payment_method) {
          const { setupIntent, error } = await this.stripe.confirmCardSetup(this.intent.client_secret, {
            payment_method: {
              card: this.cardElement,
              billing_details: { name: this.form.card_name },
            },
          })

          this.setup_intent = setupIntent
          this.stripe_error = error?.message
        }
      }

      if (!this.stripe_error) {
        this.form
          .transform((data) => ({
            ...data,
            selected_certificates: JSON.parse(localStorage.selectedCertificates),
            checkout_certificates: this.form.checkout_certificates.map((certificate) => ({
              id: certificate.id,
              locale: certificate.locale,
            })),
            country: this.getCountries.find((country) => country.code == data.country).name,
            shipping_country: this.getCountries.find((country) => country.code == data.shipping_country).name,
            payment_method: this.setup_intent?.payment_method,
          }))
          .post(this.route('front.manager.order.plastic.store'), {
            onSuccess: (response) => {
              this.form.step = response.props.flash.form_step
              this.clearSelections()
            },
          })
      }
    },
    setIntent() {
      Http.get(this.route('front.stripe.intent', this.$page.props.auth.user.id)).then(
        (response) => (this.intent = response.data)
      )
    },
    selectAllCertificates() {
      this.form.selected_certificates = this.certificates.data
      localStorage.selectedCertificatesExpiry = new Date().getTime() + 60 * 60 * 1000
      localStorage.selectedCertificates = JSON.stringify(this.allCertificatesWithIds)
    },
    unselectAllCertificates() {
      this.form.selected_certificates = []
      localStorage.removeItem('selectedCertificatesExpiry')
      localStorage.selectedCertificates = JSON.stringify([])
    },
    allCertificatesSelected() {
      return localStorage.selectedCertificates?.length >= this.allCertificatesWithIds.length
    },
    hasSelectableCertificates() {
      return (
        this.certificates.data.filter((certificate) => {
          return certificate.status != 'Expired'
        }).length > 0
      )
    },
    setSelectedCertificatesFromLocalStorage() {
      const selectedCertificates = localStorage.selectedCertificates
      this.certificates.data.forEach((certificate) => {
        if (selectedCertificates?.includes(certificate.id)) {
          this.form.selected_certificates.push(certificate)
        }
      })
    },
    addCertificateToLocalStorage(certificates) {
      // Get selected certificates from local storage.
      let selectedCertificates = JSON.parse(localStorage.selectedCertificates)

      // Loop over the selected certificates stored by the component
      // and add them if they are not found in local storage.
      certificates.forEach((cert) => {
        if (!selectedCertificates.includes(cert.id)) {
          selectedCertificates.push(cert.id)
        }
      })

      // Save entire array (stringified to JSON) back to local storage.
      localStorage.selectedCertificates = JSON.stringify(selectedCertificates)
    },
    removeCertificateFromLocalStorage(certificates, oldCertificates) {
      // Find which certificate was deselected on the page
      // by comparing new and old watcher arrays.
      let difference = oldCertificates.filter((x) => !certificates.includes(x))

      // If one is found to be deselected, find the index in the local
      // storage data and splice it out of the array before locally saving.
      if (difference.length > 0) {
        let selectedCertificates = JSON.parse(localStorage.selectedCertificates)
        const index = selectedCertificates.indexOf(difference[0].id)

        if (index > -1) {
          selectedCertificates.splice(index, 1)

          localStorage.selectedCertificates = JSON.stringify(selectedCertificates)
        }
      }
    },
    getSelectedCertificateData() {
      // Gets the certificate data required for populating the plastic card
      // order summary in step 2 (final checkout). This is required because
      // our source of truth on certificate selection is from local storage
      // where only the IDs of the certificates are stored.
      Http.post(this.route('front.manager.plastic-cards.certificates'), {
        certificate_ids: JSON.parse(localStorage.selectedCertificates),
      }).then((response) => {
        this.form.checkout_certificates = response.data
      })
    },
    clearLocalStorageIfExpired() {
      if (!localStorage.selectedCertificates) {
        return
      }

      if (new Date().getTime() > JSON.parse(localStorage.selectedCertificatesExpiry)) {
        this.clearSelections()
      }
    },
    clearSelections() {
      this.form.selected_certificates = []
      localStorage.removeItem('selectedCertificates')
      localStorage.removeItem('selectedCertificatesExpiry')
    },
    handleBillingCountryChange(event) {
      if (this.$refs.billing_address_complete) this.$refs.billing_address_complete._data.country = event.target.value
    },
    handleShippingCountryChange(event) {
      if (this.$refs.shipping_address_complete) this.$refs.shipping_address_complete._data.country = event.target.value
    },
  },
}
</script>
