<template>
  <layout :title="$t('Add Select')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink
        class="text-blue-600 hover:text-blue-700"
        :href="route('organizations.folders.edit', [organization, folder])"
      >
        {{ $t('Form Template Select') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Add Option') }}
    </h1>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" />
          </div>
          <div class="w-full pb-8 pr-8">
            <file-upload-input
              v-model="form.file"
              :error="form.errors.file"
              :initial-value="form.file"
              label="Upload File"
              :max-items="1"
              :min-items="1"
              ref-name="createFolderFileUploadInput"
              :validation-rules="{
                maxSizeInKB: 1024 * 100,
              }"
            />
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Add File') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import FileUploadInput from '@/Shared/FileUploadInput.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    FileUploadInput,
    Layout,
    LoadingButton,
    TextInput,
  },
  props: {
    organization: Object,
    folder: Object,
  },
  data() {
    return {
      form: useForm({
        name: null,
        file: null,
      }),
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('organizations.folders.files.store', [this.organization, this.folder]))
    },
  },
}
</script>
