<template>
  <layout :title="$t('Create Course')">
    <div class="mb-8">
      <breadcrumb :name="$t('Create')" :previous-name="$t('Courses')" :previous-url="route('courses')" />
    </div>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.name_en" :error="form.errors.name_en" :label="$t('Name')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.name_fr" :error="form.errors.name_fr" :label="$t('Name (FR)')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <textarea-input
              v-model="form.description_en"
              :autosize="true"
              :error="form.errors.description_en"
              :label="$t('Description')"
              :rows="2"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <textarea-input
              v-model="form.description_fr"
              :autosize="true"
              :error="form.errors.description_fr"
              :label="$t('Description (FR)')"
              :rows="2"
            />
          </div>
          <div class="w-1/2 pb-8 pr-6">
            <label class="mb-2 block">{{ $t('Languages:') }}</label>
            <div class="flex">
              <div class="mr-4">
                <checkbox-input v-model="form.locale_en" :error="form.errors.locale_en" :label="$t('English')" />
              </div>
              <div class="mr-4">
                <checkbox-input v-model="form.locale_fr" :error="form.errors.locale_fr" :label="$t('French')" />
              </div>
            </div>
          </div>
          <div v-if="courseCategories.length > 0" class="w-full pb-8 pr-6 lg:w-1/2">
            <select-input
              v-model="form.course_category_id"
              :error="form.errors.course_category_id"
              :label="$t('Course Category')"
            >
              <option :value="null" />
              <option v-for="category in courseCategories" :key="category.id" :value="category.id">
                {{ category.name }}
              </option>
            </select-input>
          </div>
          <div v-if="isMinistryOfLabourType" class="w-full pb-8 pr-6 lg:w-1/2">
            <select-input
              v-model="form.ministry_of_labour_code"
              :error="form.errors.ministry_of_labour_code"
              :label="$t('Ministry of Labour code')"
            >
              <option :value="null" />
              <option v-for="course in skillspassCourses" :key="course.code" :value="course.code">
                {{ course.code }} - {{ course.name }}
              </option>
            </select-input>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.price" :error="form.errors.price" :label="$t('Price')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <select-input
              v-model="form.certificate_template_id"
              :error="form.errors.certificate_template_id"
              :label="$t('Certificate Template')"
            >
              <option :value="null" />
              <option
                v-for="certificateTemplate in certificateTemplates"
                :key="certificateTemplate.id"
                :value="certificateTemplate.id"
              >
                {{ certificateTemplate.name }}
              </option>
            </select-input>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <radio-input
              v-model="form.resell"
              :error="form.errors.resell"
              :label="$t('Let others sell this course?')"
              type="radio"
            />
          </div>
          <div v-if="form.resell" class="w-full pb-8 pr-6 lg:w-1/2">
            <label class="form-label" for="resell_seats">{{ $t('Seats that can be resold:') }}</label>
            <div class="flex">
              <div class="w-2/3 pr-3">
                <select-input id="resell_seats" v-model="form.resell_seats">
                  <option value="percentage">{{ $t('Percentage') }}</option>
                  <option value="quantity">{{ $t('Quantity') }}</option>
                </select-input>
              </div>
              <div class="w-1/3">
                <div v-if="form.resell_seats === 'percentage'" class="relative">
                  <text-input v-model="form.resell_seats_percentage" maxlength="3" />
                  <div class="absolute right-0 top-0 flex h-full items-center pr-3 text-gray-600">%</div>
                </div>
                <text-input v-if="form.resell_seats === 'quantity'" v-model="form.resell_seats_quantity" />
              </div>
            </div>
            <div v-if="form.errors.resell_seats_percentage || form.errors.resell_seats_quantity" class="form-error">
              {{ form.errors.resell_seats_percentage || form.errors.resell_seats_quantity }}
            </div>
          </div>
          <div v-if="form.resell" class="w-full pb-8 pr-6 lg:w-1/2">
            <label class="form-label" for="resell_commission">{{ $t('Commission rate:') }}</label>
            <div class="relative">
              <text-input id="resell_commission" v-model="form.resell_commission" maxlength="3" />
              <div class="absolute right-0 top-0 flex h-full items-center pr-3 text-gray-600">%</div>
            </div>
            <div v-if="form.errors.resell_commission" class="form-error">
              {{ form.errors.resell_commission }}
            </div>
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Create Course') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import CheckboxInput from '@/Shared/CheckboxInput.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import RadioInput from '@/Shared/RadioInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    CheckboxInput,
    Layout,
    LoadingButton,
    RadioInput,
    SelectInput,
    TextInput,
    TextareaInput,
  },
  props: {
    types: Array,
    courseCategories: Array,
    skillspassCourses: Array,
    certificateTemplates: Array,
  },
  data() {
    return {
      form: useForm(
        {
          name_en: null,
          name_fr: null,
          description_en: null,
          description_fr: null,
          locale_en: false,
          locale_fr: false,
          type: null,
          course_category_id: null,
          ministry_of_labour_code: null,
          price: null,
          certificate_template_id: null,
          resell: false,
          resell_seats: 'percentage',
          resell_seats_percentage: null,
          resell_seats_quantity: null,
          resell_commission: null,
        },
        'BackOfficeUsersCreate'
      ),
    }
  },
  computed: {
    isMinistryOfLabourType() {
      return this.form.type === 'Ministry of Labour'
    },
  },
  watch: {
    'form.type'() {
      this.form.ministry_of_labour_code = null
    },
  },
  methods: {
    submit() {
      this.form
        .transform((data) => {
          return {
            name_en: data.name_en,
            name_fr: data.name_fr,
            description_en: data.description_en,
            description_fr: data.description_fr,
            locale_en: data.locale_en,
            locale_fr: data.locale_fr,
            type: data.type,
            course_category_id: data.course_category_id,
            ministry_of_labour_code: data.ministry_of_labour_code,
            price: data.price,
            certificate_template_id: data.certificate_template_id,
            resell: data.resell,
            resell_seats: data.resell_seats,
            resell_seats_percentage:
              data.resell && data.resell_seats === 'percentage' ? data.resell_seats_percentage : null,
            resell_seats_quantity: data.resell && data.resell_seats === 'quantity' ? data.resell_seats_quantity : null,
            resell_commission: data.resell ? data.resell_commission : null,
          }
        })
        .post(this.route('courses.store'))
    },
  },
}
</script>
