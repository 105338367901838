<template>
  <div>
    <div class="relative mb-4 overflow-hidden rounded-lg bg-gray-100">
      <video ref="video" autoplay class="h-full w-full" :class="{ 'transform scale-x-[-1]': mirrored }" playsinline />
      <canvas ref="canvas" class="hidden" />
      <div class="absolute inset-0 pointer-events-none">
        <svg class="w-full h-full" preserveAspectRatio="none" viewBox="0 0 100 100">
          <template v-if="type === 'selfie'">
            <ellipse
              class="opacity-70"
              cx="50"
              cy="50"
              fill="none"
              rx="25"
              ry="35"
              stroke="white"
              stroke-dasharray="2,2"
              stroke-width="0.5"
            />
          </template>
          <template v-else>
            <rect
              class="opacity-70"
              fill="none"
              height="60"
              stroke="white"
              stroke-dasharray="2,2"
              stroke-width="0.5"
              width="90"
              x="5"
              y="20"
            />
          </template>
        </svg>
      </div>
      <div v-if="capturedImage" class="absolute inset-0">
        <img :alt="altText" class="h-full w-full object-cover" :src="capturedImage" />
      </div>
    </div>
    <button
      class="inline-flex items-center justify-center rounded-md bg-ts-red-500 px-4 py-2 text-sm font-medium text-white hover:bg-ts-red-600 focus:outline-none focus:ring-2 focus:ring-ts-red-500 focus:ring-offset-2"
      type="button"
      @click="capturePhoto"
    >
      <span v-if="!capturedImage">{{ $t('Take Photo') }}</span>
      <span v-else>{{ $t('Retake Photo') }}</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    altText: {
      type: String,
      required: true,
    },
    mirrored: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'selfie',
      validator: (value) => ['selfie', 'id'].includes(value),
    },
  },
  data() {
    return {
      capturedImage: null,
      stream: null,
    }
  },
  mounted() {
    this.startCamera()
  },
  beforeUnmount() {
    this.stopCamera()
  },
  methods: {
    async startCamera() {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: 'user',
            width: { ideal: 1500 },
            height: { ideal: 1500 },
          },
        })
        const video = this.$refs.video
        video.srcObject = this.stream
      } catch (error) {
        this.$emit('error', error)
      }
    },
    stopCamera() {
      if (this.stream) {
        this.stream.getTracks().forEach((track) => track.stop())
      }
    },
    capturePhoto() {
      if (this.capturedImage) {
        this.capturedImage = null
        return
      }

      const video = this.$refs.video
      const canvas = this.$refs.canvas
      const context = canvas.getContext('2d')

      // Set canvas dimensions to match video
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight

      // Mirror effect only if mirrored prop is true
      if (this.mirrored) {
        // First translate to the right edge (this sets up the mirror effect)
        context.translate(canvas.width, 0)
        // Then scale horizontally by -1 (this creates the mirror effect)
        context.scale(-1, 1)
      }
      // Draw the video frame
      context.drawImage(video, 0, 0, canvas.width, canvas.height)
      // Reset the transformation matrix to not affect future draws
      context.setTransform(1, 0, 0, 1, 0, 0)

      // Convert to blob and emit
      canvas.toBlob(
        (blob) => {
          this.capturedImage = URL.createObjectURL(blob)
          this.$emit('photo-captured', blob)
        },
        'image/jpeg',
        0.8
      )
    },
  },
}
</script>
