<template>
  <layout :title="$t('Create Tenant')">
    <div class="mb-8">
      <breadcrumb :name="$t('Create')" previous-name="Tenants" :previous-url="route('tenants')" />
    </div>
    <div class="max-w-md overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 flex flex-wrap p-8">
          <div class="w-full pb-8">
            <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" />
          </div>
          <div class="w-full pb-8">
            <text-input
              v-model="form.plastic_card_price"
              :error="form.errors.plastic_card_price"
              :label="$t('Plastic Card Price')"
              min="0"
              step=".01"
              type="number"
            />
          </div>
          <div class="w-full pb-8">
            <text-input v-model="form.domain" :error="form.errors.domain" :label="$t('Domain')" />
          </div>
          <div class="w-full pb-8 md:w-1/2 md:pr-2">
            <select-input v-model="form.locale" :error="form.errors.locale" :label="$t('Locale/Language')">
              <option v-for="(locale, key) in locales" :key="key" :value="locale">
                {{ key }}
              </option>
            </select-input>
          </div>
          <div class="w-full pb-8 md:w-1/2 md:pl-2">
            <label class="mb-2 block">{{ $t('Available Languages:') }}</label>
            <div class="flex">
              <div class="mr-4">
                <checkbox-input v-model="form.locale_en" :error="form.errors.locale_en" :label="$t('English')" />
              </div>
              <div class="mr-4">
                <checkbox-input v-model="form.locale_fr" :error="form.errors.locale_fr" :label="$t('French')" />
              </div>
            </div>
          </div>
          <div class="w-full pb-8">
            <label for="logo">{{ $t('Logo') }}</label>
            <file-input id="logo" accept="image/*" class="mt-4" :error="form.errors.logo" @input="form.logo = $event" />
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Create Tenant') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import CheckboxInput from '@/Shared/CheckboxInput.vue'
import FileInput from '@/Shared/FileInput.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    CheckboxInput,
    FileInput,
    Layout,
    LoadingButton,
    SelectInput,
    TextInput,
  },
  props: {
    locales: Object,
  },
  data() {
    return {
      form: useForm(
        {
          name: null,
          plastic_card_price: null,
          domain: null,
          locale: this.$t('en'),
          locale_en: true,
          locale_fr: false,
          logo: null,
        },
        'TenantsCreate'
      ),
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('tenants.store'))
    },
  },
}
</script>
