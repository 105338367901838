<template>
  <div class="ts-bg flex min-h-screen items-center justify-center p-6">
    <DynamicHead :title="$t('Reset Password | :app_name', { app_name: $page.props.app.name })" />
    <div class="w-full max-w-sm">
      <form class="mt-8 overflow-hidden rounded-lg bg-white shadow-lg" @submit.prevent="submit">
        <div class="px-10 py-12">
          <h1 class="text-center text-2xl font-bold">{{ $t('Reset Password') }}</h1>
          <div class="mx-auto mt-6 w-24 border-b-2" />
          <text-input
            v-model="form.email"
            autocapitalize="off"
            autofocus
            class="mt-10"
            :error="form.errors.email"
            :label="$t('Email')"
            type="email"
          />
          <div v-if="success" class="mt-8 flex max-w-lg items-center rounded bg-green-500">
            <icon class="ml-4 mr-2 h-4 w-4 flex-shrink-0 fill-white" name="checkmark" />
            <div class="py-4 text-sm font-medium text-white">{{ success }}</div>
          </div>
        </div>
        <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-10 py-4">
          <InertiaLink class="text-gray-700 hover:underline" :href="route('login')">{{ $t('Cancel') }}</InertiaLink>
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Send Password Reset Link') }}
          </loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import DynamicHead from '@/Shared/DynamicHead.vue'
import Icon from '@/Shared/Icon.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    DynamicHead,
    Icon,
    LoadingButton,
    TextInput,
  },
  props: {
    success: String,
  },
  data() {
    return {
      form: useForm(
        {
          email: null,
          password: null,
          remember: null,
        },
        'ForgotPassword'
      ),
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('password.email'))
    },
  },
}
</script>
