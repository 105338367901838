<template>
  <layout :title="form.name">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('instructor-led-classrooms')">
        {{ $t('Instructor Led Classes') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Create') }}
    </h1>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name (Internal)')" />
          </div>
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.title" :error="form.errors.title" :label="$t('Title')" />
          </div>
          <div class="w-full pb-8 pr-6">
            <textarea-input
              v-model="form.description"
              :autosize="true"
              :error="form.errors.description"
              :label="$t('Description')"
              rows="2"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.total_score" :error="form.errors.total_score" :label="$t('Total score')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.passing_score" :error="form.errors.passing_score" :label="$t('Passing score')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.capacity" :error="form.errors.capacity" :label="$t('Capacity')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <radio-input
              v-model="form.need_preparation_time"
              :error="form.errors.need_preparation_time"
              :label="$t('Instructor need preparation time')"
              type="radio"
            />
          </div>
          <div class="w-1/2 pb-8 pr-6">
            <select-input
              v-model="form.blackout_period"
              :error="form.errors.blackout_period"
              :label="$t('Restrict booking in last (Days)')"
            >
              <option key="0" :value="0">{{ $t('No') }}</option>
              <option v-for="day in 10" :key="day" :value="day">
                {{ day }}
              </option>
            </select-input>
          </div>
          <div class="w-1/2 pb-8 pr-6">
            <select-input
              v-model="form.unenrol_period"
              :error="form.errors.unenrol_period"
              :label="$t('Restrict unenroling in last (Days)')"
            >
              <option key="0" :value="0">No</option>
              <option v-for="day in 10" :key="day" :value="day">
                {{ day }}
              </option>
            </select-input>
          </div>
          <div class="pb-8 pr-6 lg:w-full">
            <textarea-input
              v-model="form.email_content"
              :error="form.errors.email_content"
              :label="$t('Booking confirmation email content')"
              rows="5"
            />
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Create Class') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import RadioInput from '@/Shared/RadioInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Layout,
    LoadingButton,
    RadioInput,
    SelectInput,
    TextInput,
    TextareaInput,
  },
  data() {
    return {
      form: useForm(
        {
          name: null,
          title: null,
          description: null,
          total_score: null,
          passing_score: null,
          capacity: null,
          blackout_period: 0,
          unenrol_period: 0,
          need_preparation_time: true,
          email_content: null,
        },
        'ILCCreate'
      ),
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('instructor-led-classrooms.store'))
    },
  },
}
</script>
