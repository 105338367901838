<template>
  <layout title="Create Subscription Plan">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('subscription-plans')">
        {{ $t('Subscription Plans') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Create') }}
    </h1>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.identifier" :error="form.errors.identifier" :label="$t('Identifier')" />
          </div>
          <div class="w-full pb-8 pr-6">
            <text-input
              v-model="form.stripe_product"
              :error="form.errors.stripe_product"
              :label="$t('Stripe Product ID')"
            />
          </div>
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.stripe_price" :error="form.errors.stripe_price" :label="$t('Stripe Price ID')" />
          </div>
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.title" :error="form.errors.title" :label="$t('Title')" />
          </div>
          <div class="w-full pb-8 pr-6">
            <textarea-input
              v-model="form.description"
              :error="form.errors.description"
              :label="$t('Description')"
              :placeholder="$t('Plan description')"
            />
          </div>
          <div class="w-full pb-8 pr-6">
            <textarea-input
              v-model="form.features"
              :error="form.errors.features"
              :label="$t('Features')"
              :placeholder="$t('For eg: Feature One | Feature Two')"
            />
            <small class="text-xs text-ts-blue-500">
              {{ $t("Type features separated by a '|'.") }}
            </small>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input
              v-model="form.free_trial_days"
              :error="form.errors.free_trial_days"
              :label="$t('Free Trial Days')"
            />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.display_order" :error="form.errors.display_order" :label="$t('Display Order')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <select-input v-model="form.theme" :error="form.errors.theme" :label="$t('Theme')">
              <option v-for="(subscription_plan_theme, index) in subscription_plan_themes" :key="index" :value="index">
                {{ subscription_plan_theme }}
              </option>
            </select-input>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <select-input
              v-model="form.subscription_plan_type"
              :error="form.errors.subscription_plan_type"
              :label="$t('Subscription Plan Type')"
            >
              <option v-for="(subscription_plan_type, index) in subscription_plan_types" :key="index" :value="index">
                {{ subscription_plan_type }}
              </option>
            </select-input>
          </div>
          <div class="flex w-full items-center justify-between pb-8 pr-6">
            <label class="mr-3 cursor-pointer">
              {{ $t('Enable Subscription Plan') }}
            </label>
            <front-small-toggle-input v-model="form.is_active" :error="form.errors.is_active" />
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Create Subscription Plan') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import FrontSmallToggleInput from '@/Shared/FrontSmallToggleInput.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    FrontSmallToggleInput,
    Layout,
    LoadingButton,
    SelectInput,
    TextInput,
    TextareaInput,
  },
  props: {
    subscription_plan_types: Object,
    subscription_plan_themes: Object,
  },
  data() {
    return {
      form: useForm(
        {
          identifier: null,
          stripe_price: null,
          stripe_product: null,
          title: null,
          description: null,
          features: null,
          free_trial_days: 0,
          theme: null,
          subscription_plan_type: null,
          display_order: null,
          is_active: false,
        },
        'SubscriptionPlanCreate'
      ),
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('subscription-plans.store'))
    },
  },
}
</script>
