<template>
  <layout :title="form.name">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('quizzes.edit', bank.quiz.id)">
        {{ bank.quiz.name }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ form.name }}
    </h1>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.quantity" :error="form.errors.quantity" :label="$t('Quantity')" type="number" />
          </div>
        </div>
        <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
          <button class="text-red-500 hover:underline" tabindex="-1" type="button" @click="destroy">
            {{ $t('Delete Bank') }}
          </button>
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Update Bank') }}
          </loading-button>
        </div>
      </form>
    </div>
    <div class="mt-12 flex items-end justify-between">
      <div class="flex items-center">
        <h2 class="text-lg">{{ $t('Questions') }}</h2>
      </div>
      <InertiaLink class="btn-blue" :href="route('questions.create', bank.id)">
        <span>{{ $t('Create') }}</span>
        <span class="hidden md:inline">{{ $t('Question') }}</span>
      </InertiaLink>
    </div>
    <div class="mt-4 overflow-x-auto rounded bg-white shadow">
      <table class="w-full">
        <thead>
          <tr>
            <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold" colspan="2">{{ $t('Question') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="bank.questions.length === 0">
            <td class="border-t px-6 py-4" colspan="2">{{ $t('No questions found.') }}</td>
          </tr>
          <tr
            v-for="question in bank.questions"
            :key="question.id"
            class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
            :class="{ 'bg-orange-100': question.archived_at }"
            @click="$inertia.visit(route('questions.edit', question.id))"
          >
            <td class="max-w-px cursor-pointer border-t px-6">
              <InertiaLink class="flex items-center" :href="route('questions.edit', question.id)">
                <span class="truncate py-4">{{ question.name }}</span>
                <ArchivedBadge v-if="question.archived_at" />
              </InertiaLink>
            </td>
            <td class="w-min border-t px-4 align-middle">
              <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </layout>
</template>

<script>
import ArchivedBadge from '@/Shared/ArchivedBadge.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    ArchivedBadge,
    Icon,
    Layout,
    LoadingButton,
    TextInput,
  },
  props: {
    filters: Object,
    bank: Object,
  },
  data() {
    return {
      form: useForm(
        {
          quiz_id: this.bank.quiz.id,
          name: this.bank.name,
          quantity: this.bank.quantity,
        },
        'QuizBankEdit'
      ),
    }
  },
  methods: {
    submit() {
      this.form.put(this.route('banks.update', this.bank.id))
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this bank?\nQuestions will also be deleted.'))) {
        this.$inertia.delete(this.route('banks.destroy', this.bank.id))
      }
    },
  },
}
</script>
