<template>
  <content-layout :enrolment-content="enrolmentContent" :is-last="isLast">
    <div>
      <p>{{ enrolmentContent.content.description }}</p>
    </div>

    <template #actions>
      <front-button-link
        v-if="enrolmentContent.finished_at"
        class="w-full px-12 text-center"
        :class="{ disabled: !enrolmentContent.content.route }"
        :href="enrolmentContent.prerequisite_complete ? enrolmentContent.content.route : '#'"
      >
        {{ $t('View') }}
      </front-button-link>
      <front-outline-button-link
        v-else-if="enrolmentContent.content.route || enrolmentContent.content.is_webinar_type"
        class="inline-block w-full px-12 text-center"
        :class="{ disabled: isDisabled }"
        :disabled="isDisabled"
        :href="
          enrolmentContent.content.route && enrolmentContent.prerequisite_complete
            ? enrolmentContent.content.route
            : '#'
        "
        :title="isDisabled ? $t('Please complete the prerequisite first.') : $t('View Quiz')"
      >
        {{ $t('View') }}
      </front-outline-button-link>
    </template>
  </content-layout>
</template>
<script>
import ContentLayout from './ContentLayout.vue'
import FrontButtonLink from '@/Shared/FrontButtonLink.vue'
import FrontOutlineButtonLink from '@/Shared/FrontOutlineButtonLink.vue'
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  components: { ContentLayout, FrontButtonLink, FrontOutlineButtonLink },
  props: {
    enrolment: Object,
    enrolmentContent: Object,
    isLast: Boolean,
  },
  computed: {
    isTenantWorksite,
    isDisabled() {
      return !this.enrolmentContent.prerequisite_complete || !this.enrolmentContent.content.route
    },
  },
}
</script>
