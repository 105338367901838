<template>
  <front-layout :title="$t('Show Notification')">
    <div class="mb-8">
      <breadcrumb
        :name="notification.data.name"
        :previous-name="$t('Notifications')"
        :previous-url="route('front.notifications')"
      />
    </div>
    <div class="max-w-lg overflow-hidden rounded-lg border border-ts-gray-500 bg-white">
      <form @submit.prevent="submit">
        <div v-if="attachments" class="flex flex-wrap space-x-2 rounded bg-gray-300 px-5 py-8">
          <div>{{ $t('Attachments:') }}</div>
          <div>
            <div v-for="(attachment, index) in attachments" :key="index" class="inline-block">
              <a class="text-red-500 underline" :href="attachment.url" target="_blank">
                {{ attachment.file_name }}
              </a>
              <span class="last:hidden">,</span>
            </div>
          </div>
        </div>
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="email-content-wrapper w-full pb-8 pr-6 leading-normal" v-html="notification.data.body" />
        </div>
        <div class="flex items-center justify-between border-t border-gray-100 px-8 py-4">
          <button class="text-red-500 hover:underline" tabindex="-1" type="button" @click="destroy">
            {{ $t('Delete Message') }}
          </button>
          <div class="flex items-center">
            <front-secondary-button v-if="notification.link" type="submit">
              {{ $t('Close') }}
            </front-secondary-button>
            <front-solid-button v-else type="submit">{{ $t('Close') }}</front-solid-button>
            <front-button-link v-if="notification.action_route" class="ml-4" :href="notification.action_route.url">
              {{ notification.action_route.text }}
            </front-button-link>
          </div>
        </div>
      </form>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontButtonLink from '@/Shared/FrontButtonLink.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontSecondaryButton from '@/Shared/FrontSecondaryButton.vue'
import FrontSolidButton from '@/Shared/FrontSolidButton.vue'

export default {
  components: {
    Breadcrumb,
    FrontButtonLink,
    FrontLayout,
    FrontSecondaryButton,
    FrontSolidButton,
  },
  props: {
    notification: Object,
    attachments: Array,
  },
  methods: {
    submit() {
      this.$inertia.get(this.route('front.notifications'))
    },
    destroy() {
      this.$inertia.delete(this.route('front.notifications.destroy', this.notification.id))
    },
  },
}
</script>
