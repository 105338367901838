<template>
  <layout :title="form.name">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('locations')">
        {{ $t('Locations') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('locations.edit', room.location.id)">
        {{ room.location.name }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ form.name }}
    </h1>
    <deleted-message v-if="room.deleted_at" class="mb-6">{{ $t('This room has been deleted.') }}</deleted-message>
    <div class="max-w-md overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" />
          </div>
          <text-input
            v-model="form.capacity"
            class="w-full pb-8 pr-6 lg:w-1/2"
            :error="form.errors.capacity"
            :label="$t('Capacity')"
          />
        </div>
        <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
          <button
            v-if="room.deleted_at"
            class="text-red-500 hover:underline"
            tabindex="-1"
            type="button"
            @click="restore"
          >
            {{ $t('Restore Room') }}
          </button>
          <button v-else class="text-red-500 hover:underline" tabindex="-1" type="button" @click="destroy">
            {{ $t('Delete Room') }}
          </button>
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Update Room') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import DeletedMessage from '@/Shared/DeletedMessage.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    DeletedMessage,
    Layout,
    LoadingButton,
    TextInput,
  },
  props: {
    room: Object,
  },
  data() {
    return {
      form: useForm(
        {
          name: this.room.name,
          capacity: this.room.capacity,
        },
        'RoomsEdit'
      ),
    }
  },
  methods: {
    submit() {
      this.form.put(this.route('rooms.update', this.room.id))
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this room?'))) {
        this.$inertia.delete(this.route('rooms.destroy', this.room.id))
      }
    },
    restore() {
      if (confirm(this.destroy$t('Are you sure you want to restore this room?'))) {
        this.$inertia.put(this.route('rooms.restore', this.room.id))
      }
    },
  },
}
</script>
