<template>
  <layout :title="$t('Quiz Result')">
    <breadcrumb-admin :path="breadcrumb" />
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <div class="flex w-full flex-col items-center justify-center px-4 sm:px-8 lg:flex-row lg:px-20">
        <div class="flex-1 pt-6 text-center lg:py-8 lg:text-left">
          <div class="pb-1 text-ts-gray-400">{{ $t('Attempt :index', { index: attempt.index }) }}</div>
          <h3 class="text-empty-state-header text-xl font-semibold leading-6">{{ quiz.title }}</h3>
        </div>
        <div class="flex items-center justify-center pb-4 pt-4">
          <div class="px-2 py-1 lg:px-8">
            <QuizStatus :status="attempt.status" />
            <div class="pt-1 text-ts-gray-400 text-center">{{ $t('Status') }}</div>
          </div>
          <div v-show="quiz.proctoring_enabled" class="px-2 py-1 lg:px-8">
            <div v-if="attempt.proctoring_status" class="text-lg font-semibold text-gray-600">
              {{ attempt.proctoring_status }}
            </div>
            <div class="pt-1 text-ts-gray-400">{{ $t('Proctoring') }}</div>
          </div>
          <div class="flex-col py-1">
            <div
              class="text-xl font-semibold"
              :class="{
                'text-gray-600': true,
                'text-green-600': attempt.status === 'passed',
                'text-qualify-red-500': !attempt.status === 'failed',
              }"
            >
              {{ attempt.scorePercent }}%
            </div>
            <div class="pt-1 text-ts-gray-400">{{ $t('Final') }}</div>
          </div>
        </div>
      </div>
      <div class="border-t border-gray-300">
        <div v-for="(question, questionKey) in attempt.questions" :key="questionKey" class="px-4 py-6">
          <question :question="question" :question-key="questionKey" :show-answers="true" />
        </div>
        <div class="flex items-center justify-end px-4 py-6 md:pb-12 lg:pb-16 lg:pr-20">
          <front-button-link :href="route('enrolment-contents.attempts', [enrolmentContent.id])">
            {{ $t('Exit') }}
          </front-button-link>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Question from './Question.vue'
import BreadcrumbAdmin from '@/Shared/BreadcrumbAdmin.vue'
import FrontButtonLink from '@/Shared/FrontButtonLink.vue'
import Layout from '@/Shared/Layout.vue'
import QuizStatus from '@/Shared/Quiz/QuizStatus.vue'

export default {
  components: { Question, QuizStatus, BreadcrumbAdmin, FrontButtonLink, Layout },
  props: {
    class_: Object,
    enrolment: Object,
    enrolmentContent: Object,
    quiz: Object,
    attempt: Object,
  },
  computed: {
    passingScorePercentage() {
      return Math.round((this.quiz.passing_score / this.quiz.total_score) * 100) + '%'
    },
    breadcrumb() {
      return [
        {
          name: this.$t('Enrolments'),
          url: this.route('enrolments'),
        },
        {
          name: this.enrolment.id,
          url: this.route('enrolments.edit', this.enrolment.id),
        },
        {
          name: `${this.$t('Quiz Attempts')}`,
          url: this.route('enrolment-contents.attempts', this.enrolmentContent.id),
        },
        {
          name: this.attempt.index,
        },
      ]
    },
  },
}
</script>
