<template>
  <div v-if="activeUser">
    <div class="mb-8 flex items-center justify-between">
      <h1 class="text-xl font-bold">
        <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('users')">{{ $t('Users') }}</InertiaLink>
        <span class="font-medium text-blue-600">/</span>
        {{ activeUser.display_name }}
      </h1>
      <dropdown v-if="activeUser.can.impersonate || activeUser.can.merge" class="btn-blue" placement="bottom-end">
        <div class="flex cursor-pointer select-none items-center">
          <div>
            <span>{{ $t('Actions') }}</span>
          </div>
          <icon class="-my-1 h-5 w-5 fill-white" name="chevron-down" />
        </div>
        <template #dropdown>
          <div class="mt-2 rounded border bg-white py-2 text-sm shadow-lg">
            <InertiaLink
              v-if="activeUser.can.impersonate"
              as="button"
              class="block w-full px-4 py-2 text-left hover:bg-gray-200 hover:text-gray-700"
              :href="route('users.impersonate', activeUser.id)"
              method="post"
              type="button"
            >
              {{ $t('Impersonate User') }}
            </InertiaLink>
            <InertiaLink
              v-if="activeUser.can.merge"
              class="block w-full px-4 py-2 text-left hover:bg-gray-200 hover:text-gray-700"
              :href="route('users.merge', activeUser.id)"
              type="button"
            >
              {{ $t('Merge User') }}
            </InertiaLink>
            <button
              v-if="activeUser.deleted_at"
              class="block w-full px-4 py-2 text-left text-red-500 hover:bg-gray-200 hover:text-gray-700"
              @click="restore"
            >
              {{ $t('Restore User') }}
            </button>
            <button
              v-else
              class="block w-full px-4 py-2 text-left text-red-500 hover:bg-gray-200 hover:text-gray-700"
              @click="destroy"
            >
              {{ $t('Delete') }}
            </button>
            <button
              v-if="activeUser.archived_at"
              class="block w-full px-4 py-2 text-left text-red-500 hover:bg-gray-200 hover:text-gray-700"
              @click="unArchive"
            >
              {{ $t('Restore Archived') }}
            </button>
            <button
              v-else
              class="block w-full px-4 py-2 text-left text-red-500 hover:bg-gray-200 hover:text-gray-700"
              @click="archive"
            >
              {{ $t('Archive') }}
            </button>
          </div>
        </template>
      </dropdown>
    </div>
    <deleted-message v-if="activeUser.deleted_at" class="mb-6">
      {{ $t('This user has been deleted.') }}
    </deleted-message>
    <archived-message v-if="activeUser.archived_at" class="mb-6">
      <div class="flex w-full justify-between">
        <div>{{ $t('This user has been archived.') }}</div>
        <button
          v-if="activeUser.archived_at"
          class="ml-4 text-yellow-800 hover:underline"
          tabindex="-1"
          type="button"
          @click="unArchive"
        >
          {{ $t('Restore') }}
        </button>
      </div>
    </archived-message>
    <div class="flex w-full flex-none flex-col gap-x-6 md:flex-row">
      <div class="flex-1 overflow-hidden">
        <tab-layout :menu-list="menuList">
          <slot />
        </tab-layout>
      </div>
      <div class="mt-8 w-full space-y-8 md:mt-0 md:w-3/12">
        <div class="w-full">
          <div class="overflow-hidden rounded bg-white shadow">
            <div class="flex flex-wrap">
              <div class="w-full bg-gray-400 px-4 py-4 text-sm font-bold text-gray-700">{{ $t('Notes') }}</div>
              <div class="relative w-full">
                <button v-show="userStore.isFetchingNotes" class="absolute w-full bg-blue-500 text-center text-white">
                  Loading
                </button>
                <div
                  v-if="sortedNoteKeys?.length > 0"
                  ref="notesContainer"
                  class="flex h-64 w-full flex-col-reverse overflow-y-auto px-6"
                  :class="{ 'py-4': userStore.notes?.length > 0 }"
                  @scroll="onScroll"
                >
                  <div v-for="noteKey in sortedNoteKeys" :key="noteKey" class="w-full py-2">
                    <div
                      class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-2 py-1 text-sm leading-normal transition duration-150 ease-in-out"
                      type="text"
                    >
                      {{ userStore.notes[noteKey].content }}
                    </div>
                    <div class="mt-1 flex items-center justify-between px-1">
                      <span class="text-xs text-gray-700">{{ userStore.notes[noteKey].user.name || '-' }}</span>
                      <span class="text-xs text-gray-700">{{ userStore.notes[noteKey].created_at }}</span>
                    </div>
                  </div>
                </div>
                <div class="flex w-full items-end space-x-1 bg-gray-200 px-4 py-4">
                  <div class="flex-1">
                    <textarea-input
                      v-model="noteForm.content"
                      :autosize="true"
                      :error="noteForm.errors.content"
                      :placeholder="$t('New note..')"
                      :rows="1"
                      @keydown.enter.prevent="addNote"
                    />
                  </div>
                  <button class="btn-blue" @click.stop="addNote">></button>
                </div>
              </div>
            </div>
          </div>
          <organization-card
            v-if="activeUser.organization"
            class="mt-12 lg:mt-8"
            :organization="activeUser.organization"
          />
        </div>
        <version-card
          :last-modified="activeUser.versionSummary.last_modified"
          :link="route('users.versions.show', activeUser.id)"
        />
        <card v-if="activeUser.skillspass_id" :name="$t('Skillspass Unique Key')">
          {{ activeUser.skillspass_id }}
        </card>
      </div>
    </div>
  </div>
  <div v-else-if="userStore.isLoading">Loading</div>
  <div v-else>Some error in loading resource</div>
</template>

<script setup>
import { activeUser, fetchUser, loadMoreNotes, updateUserId, store as userStore } from '@/Stores/ActiveUser'
import Http from '@/Utils/Http'
import { router, useForm } from '@inertiajs/vue3'
import { computed, onMounted, ref, useTemplateRef, watch } from 'vue'
import { route } from 'ziggy-js'

const notesContainerRef = useTemplateRef('notesContainer')
const menuList = ref([])
const noteForm = useForm({
  content: null,
})

const sortedNoteKeys = computed(() => {
  const keys = Object.keys(userStore.notes)
  keys.sort((a, b) => b - a)
  return keys
})

watch(activeUser, async () => {
  if (activeUser.value?.id) {
    updateMenu()
  }
})

onMounted(() => {
  updateUserId(route().params.user)
  if (activeUser.value?.id) {
    updateMenu()
  }
})

const updateMenu = () => {
  menuList.value = [
    {
      name: trans('Personal'),
      url: route('users.edit', activeUser.value.id),
      matchUrl: '*/edit',
    },
    {
      name: trans('Organization'),
      url: route('users.organization', activeUser.value.id),
      matchUrl: '*/organization',
    },
    {
      name: trans('Enrolments'),
      url: route('users.enrolments', activeUser.value.id),
      matchUrl: '*/enrolments',
    },
    {
      name: trans('Certificates'),
      url: route('users.certificates', activeUser.value.id),
      matchUrl: '*/certificates',
    },
    {
      name: trans('Classes'),
      url: route('users.classes', activeUser.value.id),
      matchUrl: '*/classes',
    },
    {
      name: trans('Subscription'),
      url: route('users.subscriptions', activeUser.value.id),
      matchUrl: '*/subscriptions',
    },
  ]
}

const addNote = () => {
  Http.post(route('api.notes.store', { noteTypesEnum: 'user', id: activeUser.value.id }), noteForm).then((response) => {
    noteForm.content = null
    if (response.data?.data) {
      userStore.notes[response.data.data.id] = response.data.data
    }
  })
}

const onScroll = () => {
  if (
    userStore.hasMoreNotes &&
    notesContainerRef.value.clientHeight + -1 * notesContainerRef.value.scrollTop ===
      notesContainerRef.value.scrollHeight
  ) {
    loadMoreNotes()
  }
}

const archive = () => {
  if (confirm(trans('Are you sure you want to archive this user?'))) {
    router.put(route('users.archive', activeUser.value.id), {}, { onSuccess: fetchUser })
  }
}

const unArchive = () => {
  if (confirm(trans('Are you sure you want to restore this archived user?'))) {
    router.put(route('users.archive.restore', activeUser.value.id), {}, { onSuccess: fetchUser })
  }
}

const destroy = () => {
  if (confirm(trans('Are you sure you want to delete this user?'))) {
    router.delete(route('users.destroy', activeUser.value.id), { onSuccess: fetchUser })
  }
}

const restore = () => {
  if (confirm(trans('Are you sure you want to restore this user?'))) {
    router.put(route('users.restore', activeUser.value.id), {}, { onSuccess: fetchUser })
  }
}
</script>
<script>
import ArchivedMessage from '@/Shared/ArchivedMessage.vue'
import Card from '@/Shared/Card.vue'
import DeletedMessage from '@/Shared/DeletedMessage.vue'
import Dropdown from '@/Shared/Dropdown.vue'
import Icon from '@/Shared/Icon.vue'
import OrganizationCard from '@/Shared/OrganizationCard.vue'
import TabLayout from '@/Shared/TabLayout.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import VersionCard from '@/Shared/VersionCard.vue'
import { trans } from 'laravel-vue-i18n'

export default {
  components: {
    ArchivedMessage,
    Card,
    DeletedMessage,
    Dropdown,
    Icon,
    OrganizationCard,
    TextareaInput,
    VersionCard,
  },
  layout: [TabLayout],
}
</script>
