<template>
  <svg viewBox="0 0 267.71 62.81" xmlns="http://www.w3.org/2000/svg">
    <defs />
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M145.93,61.85a4.75,4.75,0,0,1-1.32-.17,4.35,4.35,0,0,1-1-.41,3.1,3.1,0,0,1-.67-.54c-.17-.19-.32-.36-.43-.51l1.09-.85a2,2,0,0,0,.35.36,2.73,2.73,0,0,0,.53.36,3.21,3.21,0,0,0,.68.27,3,3,0,0,0,.81.1,2.12,2.12,0,0,0,.86-.14,1.55,1.55,0,0,0,.52-.33,1.32,1.32,0,0,0,.26-.37,1,1,0,0,0,.06-.27.74.74,0,0,0-.37-.65,3.86,3.86,0,0,0-.91-.45l-1.19-.4a5.32,5.32,0,0,1-1.19-.53,3.08,3.08,0,0,1-.92-.84,2.23,2.23,0,0,1-.37-1.31,2.62,2.62,0,0,1,.13-.71,2,2,0,0,1,.47-.76,2.56,2.56,0,0,1,.92-.62,3.69,3.69,0,0,1,1.49-.26A4.58,4.58,0,0,1,147,53a4.2,4.2,0,0,1,.9.38,3.41,3.41,0,0,1,.6.48,3.14,3.14,0,0,1,.34.41l-1.07.8a2.2,2.2,0,0,0-1-.65,3.58,3.58,0,0,0-1.14-.19,1.59,1.59,0,0,0-.53.08,1.53,1.53,0,0,0-.46.23,1.76,1.76,0,0,0-.33.34.77.77,0,0,0-.13.42.93.93,0,0,0,.37.77,3.6,3.6,0,0,0,.92.48,12.21,12.21,0,0,0,1.2.38,5.72,5.72,0,0,1,1.19.49,2.9,2.9,0,0,1,.92.78,2.07,2.07,0,0,1,.37,1.28,2.25,2.25,0,0,1-.16.73,2.35,2.35,0,0,1-.53.78,3.28,3.28,0,0,1-1,.62A4.08,4.08,0,0,1,145.93,61.85Z"
        />
        <path
          class="cls-1"
          d="M170.43,53l3.17,8.71H172l-.57-1.55h-4.17l-.55,1.55h-1.6L168.29,53Zm-2.66,5.79h3.17l-1.58-4.34Z"
        />
        <path class="cls-1" d="M191.47,57.06h4v1.39h-4v3.23H190V53h5.84v1.39h-4.37Z" />
        <path class="cls-1" d="M218.4,60.3v1.38h-5.84V53h5.84v1.39H214v2.37h4v1.39h-4V60.3Z" />
        <path class="cls-1" d="M234.81,54.36V53h7.1v1.39h-2.82v7.32h-1.47V54.36Z" />
        <path class="cls-1" d="M264,53h1.69l-3.15,5.78v2.93h-1.47V58.75L257.93,53h1.68l2.2,4.08Z" />
        <path
          class="cls-1"
          d="M93.25,19.06c.69,2.62,1.4,5.38,2.13,8.3s1.45,5.68,2.13,8.29l3.67-16.59h6.89l-6.38,25H93.77Q92.9,40.82,92,37.59t-1.74-6.46q-.87,3.23-1.75,6.46T86.79,44H78.86L72.49,19.06h6.89L83,35.65c.69-2.61,1.4-5.38,2.13-8.29s1.45-5.68,2.14-8.3Z"
        />
        <path
          class="cls-1"
          d="M109.19,31.58a12.58,12.58,0,0,1,1-5.06,13.24,13.24,0,0,1,2.8-4.08,13,13,0,0,1,4.2-2.75,13.92,13.92,0,0,1,10.41,0,13,13,0,0,1,4.18,2.75,13.24,13.24,0,0,1,2.8,4.08,12.92,12.92,0,0,1,0,10.11,12.9,12.9,0,0,1-7,6.84,13.92,13.92,0,0,1-10.41,0,12.85,12.85,0,0,1-7-6.84A12.53,12.53,0,0,1,109.19,31.58Zm19.9,0a7.16,7.16,0,0,0-.52-2.75A6.85,6.85,0,0,0,125,25.1a6.24,6.24,0,0,0-5.21,0,6.76,6.76,0,0,0-2.13,1.51,7,7,0,0,0-1.43,2.22,7.64,7.64,0,0,0,0,5.49,7.1,7.1,0,0,0,1.43,2.23,6.76,6.76,0,0,0,2.13,1.51,6.34,6.34,0,0,0,5.21,0,6.76,6.76,0,0,0,2.13-1.51,6.9,6.9,0,0,0,1.42-2.23A7.11,7.11,0,0,0,129.09,31.58Z"
        />
        <path
          class="cls-1"
          d="M152.81,44l-5.53-6.68h-1.95V44H139V19.11h10.08A13.62,13.62,0,0,1,154,20.27a8.53,8.53,0,0,1,2,1.18A7.36,7.36,0,0,1,157.42,23a9.1,9.1,0,0,1,1,2.24,10.05,10.05,0,0,1,.41,3,9.66,9.66,0,0,1-.41,2.94,7.94,7.94,0,0,1-1.1,2.22A8.24,8.24,0,0,1,155.79,35a10.51,10.51,0,0,1-1.77,1.1L160.24,44Zm-7.48-12.54h3.82a3.37,3.37,0,0,0,2.16-.72,3,3,0,0,0,.91-2.49,3.12,3.12,0,0,0-.91-2.56,3.32,3.32,0,0,0-2.16-.73h-3.82Z"
        />
        <path
          class="cls-1"
          d="M194.35,35.69a11.44,11.44,0,0,0,2.44,2,6.48,6.48,0,0,0,3.55.91,5.38,5.38,0,0,0,1.6-.2,2.84,2.84,0,0,0,.94-.45,1.37,1.37,0,0,0,.48-.58,1.7,1.7,0,0,0,.13-.58c0-.49-.32-.89-1-1.2a18.14,18.14,0,0,0-2.37-1c-.95-.31-2-.66-3.1-1A13.56,13.56,0,0,1,194,32a8.22,8.22,0,0,1-2.37-2.4,6.53,6.53,0,0,1-.95-3.66,7.62,7.62,0,0,1,.39-2.17,6.18,6.18,0,0,1,1.42-2.4,8.32,8.32,0,0,1,2.8-1.94,10.93,10.93,0,0,1,4.48-.79,12.19,12.19,0,0,1,4.48.72,12.61,12.61,0,0,1,3,1.63,9.19,9.19,0,0,1,2.11,2.35L205,26.82a8.38,8.38,0,0,0-1.46-1.32,5.65,5.65,0,0,0-1.36-.66,5.24,5.24,0,0,0-1.31-.26c-.44,0-.88,0-1.34,0a3.11,3.11,0,0,0-1.7.46,1.41,1.41,0,0,0-.71,1.27,1.54,1.54,0,0,0,1,1.35,13.74,13.74,0,0,0,2.36,1c1,.31,2,.67,3.11,1.07a16.2,16.2,0,0,1,3.1,1.51A8,8,0,0,1,209,33.52,6.12,6.12,0,0,1,209.9,37a6.33,6.33,0,0,1-.49,2.26,7.11,7.11,0,0,1-1.62,2.41,9.05,9.05,0,0,1-3,1.94,12,12,0,0,1-4.67.8,13.71,13.71,0,0,1-4.25-.59,12.18,12.18,0,0,1-3-1.4,8.43,8.43,0,0,1-2-1.72,10.72,10.72,0,0,1-1.07-1.55Z"
        />
        <path class="cls-1" d="M214.68,19.06H221V44h-6.34Z" />
        <path class="cls-1" d="M225.5,25V19.11h21V25h-7.32V44h-6.33V25Z" />
        <path class="cls-1" d="M267.71,38.15V44H250.09V19.11h17.62V25H256.42V28.8H266.8v5.86H256.42v3.49Z" />
        <rect class="cls-1" height="25.04" width="6.95" x="164.41" y="18.96" />
        <polygon
          class="cls-1"
          points="188.34 19.02 180.49 19.02 171.37 31.48 180.49 43.95 188.34 43.95 179.22 31.48 188.34 19.02"
        />
        <path
          class="cls-1"
          d="M51.43,33,50,32.58a.54.54,0,0,1-.38-.63l1-5.19a.55.55,0,0,0-.69-.62l-4.44,1.28a.55.55,0,0,1-.66-.33l-.56-1.42a.59.59,0,0,0-1-.2c-.26.25-5,5.06-5,5.06l1.78-11.3a.54.54,0,0,0-.76-.56l-3,1.47a.55.55,0,0,1-.72-.23L31.9,13.22a.54.54,0,0,0-.95,0l-3.76,6.71a.55.55,0,0,1-.72.22l-2.95-1.47a.54.54,0,0,0-.77.56c.26,1.61,1.77,11.28,1.77,11.28l-5-5.06a.57.57,0,0,0-.93.19L18,27.09a.55.55,0,0,1-.65.33l-4.45-1.28a.55.55,0,0,0-.69.62l1,5.19a.54.54,0,0,1-.37.63L11.41,33a.53.53,0,0,0-.22.89L14.37,37l7.71,7.71a1.49,1.49,0,0,0,2.12,0l7.23-7.23,7.23,7.23a1.5,1.5,0,0,0,2.13,0l9.12-9.12,1.74-1.72A.53.53,0,0,0,51.43,33Z"
        />
        <path
          class="cls-1"
          d="M31.42,62.81a4.12,4.12,0,0,1-2.92-1.2L1.21,34.32a4.13,4.13,0,0,1,0-5.84L28.5,1.19a4.17,4.17,0,0,1,5.84,0L61.63,28.48a4.13,4.13,0,0,1,0,5.84L34.34,61.61A4.15,4.15,0,0,1,31.42,62.81Zm-.87-3.26a1.22,1.22,0,0,0,1.73,0L59.57,32.26a1.16,1.16,0,0,0,.36-.86,1.19,1.19,0,0,0-.36-.87L32.29,3.24a1.31,1.31,0,0,0-1.74,0L3.26,30.53a1.24,1.24,0,0,0,0,1.73L30.55,59.55Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    name: String,
  },
}
</script>

<style scoped>
.cls-1 {
  fill: #fff;
}
</style>
