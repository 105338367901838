<template>
  <front-layout :title="$t('Create Manual')">
    <div class="item-center mb-8 flex max-w-2xl justify-between">
      <breadcrumb
        class="font-heading text-2xl font-semibold"
        :name="form.name ?? $t('Create')"
        :previous-name="$t('Manuals')"
        :previous-url="route('front.admin.manuals')"
      />
      <front-loading-button :disabled="!form.isDirty" :loading="form.processing" @click="submit">
        {{ $t('Save') }}
      </front-loading-button>
    </div>
    <form @submit.prevent="submit">
      <!-- Manual Name -->
      <div class="max-w-2xl overflow-hidden rounded bg-white shadow">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <front-text-input v-model="form.name" :error="form.errors.name" :label="$t('Manual Name')" />
          </div>
        </div>
      </div>
      <!-- Sections -->
      <div class="pb-6 pt-8 font-heading text-xl">Sections</div>
      <Draggable v-model="form.sections">
        <div
          v-for="(section, index) in form.sections"
          :key="section.id"
          class="flex max-w-2xl flex-col overflow-hidden bg-white p-6 shadow"
        >
          <div class="flex items-center">
            <div class="">
              <icon class="h-8 w-8 text-gray-400" name="menu" />
            </div>
            <div class="w-full pl-4 text-lg">
              {{ section.name }}
            </div>
            <div class="flex items-center justify-end">
              <button class="flex justify-end text-gray-500 hover:text-gray-600" @click="removeSection(index)">
                Remove
              </button>
            </div>
          </div>
        </div>
      </Draggable>
    </form>
    <div class="mt-8 flex max-w-2xl justify-end">
      <front-outline-button class="flex" @click="addSection">
        <icon class="fill-qualify-red mr-2 h-4 w-4 flex-shrink-0" name="plus" />
        Add Section
      </front-outline-button>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontOutlineButton from '@/Shared/FrontOutlineButton.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import Icon from '@/Shared/Icon.vue'
import { useForm } from '@inertiajs/vue3'
import { VueDraggableNext as Draggable } from 'vue-draggable-next'

export default {
  components: {
    Draggable,
    Breadcrumb,
    FrontLayout,
    FrontLoadingButton,
    FrontOutlineButton,
    FrontTextInput,
    Icon,
  },
  data() {
    return {
      form: useForm({
        name: null,
        sections: [
          {
            name: 'New Section',
            content: null,
          },
        ],
      }),
    }
  },
  watch: {
    'form.name'(newVal) {
      if (newVal === '') this.form.name = null
    },
  },
  methods: {
    addSection() {
      this.form.sections.push({
        name: `New Section ${this.form.sections.length + 1}`,
        content: null,
      })
    },
    removeSection(index) {
      this.form.sections.splice(index, 1)
    },
    submit() {
      this.form.post(this.route('front.admin.manuals.store'))
    },
  },
}
</script>
