<template>
  <layout :title="$t('Manage Availability')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('users.edit', instructor.id)">
        {{ instructor.name }}
      </InertiaLink>
      <span class="ml-1 font-medium">/</span>
      {{ $t('Manage Availability') }}
    </h1>
    <div class="w-full overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div v-if="form.availabilities.length === 0" class="-mb-8 -mr-6 flex p-8">No scheduled availability set.</div>
        <template v-else>
          <div v-for="(availability, key) in form.availabilities" :key="key" class="-mb-8 -mr-6 flex p-8">
            <div class="flex-1 pb-8 pr-6">
              <select-input
                v-model="availability.is_recurring"
                :error="form.errors[`availabilities.${key}.is_recurring`]"
                :label="$t('Availability Type')"
                @change="resetFields(key, availability.is_recurring)"
              >
                <option :value="false">One-time</option>
                <option :value="true">Weekly</option>
              </select-input>
            </div>
            <div v-if="availability.is_recurring" class="flex-1 pb-8 pr-6">
              <select-input
                v-model="availability.start_weekday"
                :error="form.errors[`availabilities.${key}.start_weekday`]"
                :label="$t('Start Weekday')"
              >
                <option :value="null" />
                <option v-for="(label, index) in days_of_the_week" :key="index" :value="key">{{ label }}</option>
              </select-input>
            </div>
            <div v-if="availability.is_recurring" class="flex-1 pb-8 pr-6">
              <select-input
                v-model="availability.end_weekday"
                :error="form.errors[`availabilities.${key}.end_weekday`]"
                :label="$t('End Weekday')"
              >
                <option :value="null" />
                <option v-for="(label, index) in days_of_the_week" :key="index" :value="key">{{ label }}</option>
              </select-input>
            </div>
            <div v-if="!availability.is_recurring" class="flex-1 pb-8 pr-6">
              <date-input
                v-model="availability.start_date"
                :error="form.errors[`availabilities.${key}.start_date`]"
                :label="$t('Start Date')"
              />
            </div>
            <div v-if="!availability.is_recurring" class="flex-1 pb-8 pr-6">
              <date-input
                v-model="availability.end_date"
                :error="form.errors[`availabilities.${key}.end_date`]"
                :label="$t('End Date')"
              />
            </div>
            <div class="flex-1 pb-8 pr-6">
              <time-input
                v-model="availability.start_time"
                :error="form.errors[`availabilities.${key}.start_time`]"
                :label="$t('Start Time')"
              />
            </div>
            <div class="flex-1 pb-8 pr-6">
              <time-input
                v-model="availability.end_time"
                :error="form.errors[`availabilities.${key}.end_time`]"
                :label="$t('End Time')"
              />
            </div>
            <div v-if="availability.is_recurring" class="flex-1 pb-8 pr-6">
              <date-input
                v-model="availability.repetition_ends_at"
                :error="form.errors[`availabilities.${key}.repetition_ends_at`]"
                :label="$t('Repetition Ends')"
              />
            </div>
            <div class="pr-6 pt-6">
              <Icon class="h-5 w-5 fill-current" name="close-outline" @click.stop="removeAvailability(key)" />
            </div>
          </div>
        </template>
        <div class="flex justify-end pb-8 pr-8">
          <button class="flex items-center" type="button" @click.stop="newAvailability">
            <Icon class="h-6 w-6 fill-current" name="plus" />
            New Availability
          </button>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :disabled="!form.isDirty" :loading="form.processing" type="submit">
            {{ $t('Save') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import DateInput from '@/Shared/DateInput.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TimeInput from '@/Shared/TimeInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    DateInput,
    Icon,
    Layout,
    LoadingButton,
    SelectInput,
    TimeInput,
  },
  props: {
    instructor: Object,
    availabilities: Object,
    days_of_the_week: Object,
  },
  data() {
    return {
      empty_availability: {
        is_recurring: false,
        start_weekday: null,
        end_weekday: null,
        start_date: this.getFirstDayOfMonth(),
        end_date: this.getFirstDayOfMonth(),
        start_time: null,
        end_time: null,
        repetition_ends_at: null,
      },
      form: useForm({
        availabilities:
          this.availabilities.length > 0
            ? this.availabilities
            : [
                {
                  is_recurring: false,
                  start_weekday: null,
                  end_weekday: null,
                  start_date: this.getFirstDayOfMonth(),
                  end_date: this.getFirstDayOfMonth(),
                  start_time: null,
                  end_time: null,
                  repetition_ends_at: null,
                },
              ],
      }),
    }
  },
  methods: {
    submit() {
      this.form.put(this.route('users.availability.store', this.instructor.id))
    },
    newAvailability() {
      this.form.availabilities.push({ ...this.empty_availability })
    },
    removeAvailability(index) {
      if (index >= 0 && index < this.form.availabilities.length) {
        this.form.availabilities.splice(index, 1)
      }
    },
    resetFields(index, isRecurring) {
      this.form.availabilities[index] = { ...this.empty_availability }
      this.form.availabilities[index].is_recurring = isRecurring
    },
    getFirstDayOfMonth() {
      let date = new Date()
      return new Date(date.getFullYear(), date.getMonth(), 1)
    },
  },
}
</script>
