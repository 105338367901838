<template>
  <div class="flex items-center mb-8">
    <div class="w-full">
      <label class="block text-sm font-semibold text-gray-900 mb-3">
        {{ $t('Photo ID Verification') }}
        <span class="text-ts-red-500">*</span>
      </label>

      <!-- Initial Button -->
      <button
        v-if="!showOptions"
        class="px-6 py-2 bg-ts-red-500 text-white rounded-full font-semibold hover:bg-ts-red-600"
        @click="showOptions = true"
      >
        {{ $t('Take or Upload Identification') }}
      </button>

      <!-- ID Upload Methods -->
      <div v-else class="flex flex-col sm:flex-row sm:space-x-6 space-y-6 sm:space-y-0">
        <!-- Method Selection Buttons -->
        <div v-if="!selectedMethod" class="flex flex-col sm:flex-row sm:space-x-6 space-y-6 sm:space-y-0 w-full">
          <button
            class="flex-1 px-6 py-4 border rounded-lg text-center font-medium hover:bg-gray-50"
            @click="selectedMethod = 'camera'"
          >
            {{ $t('Take a Photo of Your ID') }}
          </button>
          <div class="flex items-center justify-center">
            <span class="text-gray-500 font-medium px-4">{{ $t('or') }}</span>
          </div>
          <button
            class="flex-1 px-6 py-4 border rounded-lg text-center font-medium hover:bg-gray-50"
            @click="selectedMethod = 'upload'"
          >
            {{ $t('Upload ID Photo') }}
          </button>
        </div>

        <!-- Camera Capture Option -->
        <div v-if="selectedMethod === 'camera'" class="border rounded-lg p-4 flex-1">
          <div class="flex justify-between items-center mb-3">
            <h4 class="font-medium">{{ $t('Take a Photo') }}</h4>
            <button class="text-sm text-gray-500 hover:text-gray-700" @click="resetSelection">
              {{ $t('Change Method') }}
            </button>
          </div>
          <div class="max-w-sm">
            <PhotoCapture
              :alt-text="$t('Captured ID')"
              :facing-mode="isMobile ? 'environment' : 'user'"
              :mirrored="false"
              type="id"
              @error="handleIdCameraError"
              @photo-captured="handleIdPhotoCapture"
            />
          </div>
        </div>

        <!-- File Upload Option -->
        <div v-if="selectedMethod === 'upload'" class="border rounded-lg p-4 flex-1">
          <div class="flex justify-between items-center mb-3">
            <h4 class="font-medium">{{ $t('Upload ID Photo') }}</h4>
            <button class="text-sm text-gray-500 hover:text-gray-700" @click="resetSelection">
              {{ $t('Change Method') }}
            </button>
          </div>
          <input
            id="id_file_input"
            accept="image/jpg,image/png"
            class="block w-full max-w-sm text-sm pointer file:pointer file:mr-3 pr-3 file:px-8 file:py-2 file:border-none file:font-bold file:rounded-full file:text-sm file:bg-ts-red-500 file:shadow-none file:text-white text-gray-900 border border-gray-300 rounded-full cursor-pointer bg-gray-50 focus:outline-none"
            type="file"
            @input="onIdFileInput"
          />
        </div>
      </div>

      <div
        v-if="error"
        class="max-w-content border rounded border-red-300 bg-red-100 border-1 px-3 py-1 form-error mt-2"
      >
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import PhotoCapture from './PhotoCapture.vue'

export default {
  components: {
    PhotoCapture,
  },
  props: {
    error: {
      type: String,
      default: null,
    },
  },
  emits: ['update:photo'],
  data() {
    return {
      isMobile: false,
      showOptions: false,
      selectedMethod: null,
    }
  },
  mounted() {
    this.detectDevice()
  },
  methods: {
    detectDevice() {
      this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },
    handleIdPhotoCapture(file) {
      this.$emit('update:photo', file)
    },
    handleIdCameraError(error) {
      console.error('ID camera error:', error)
    },
    onIdFileInput(event) {
      const file = event.target.files[0]
      if (!file) return

      if (file.size > 4194304) {
        alert(this.$t('File size is too large. File should be below 4 MB.'))
        return
      }

      this.$emit('update:photo', file)
    },
    resetSelection() {
      this.selectedMethod = null
    },
  },
}
</script>
