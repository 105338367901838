<template>
  <report-layout>
    <!-- CARD ROW -->
    <div class="-mx-2 flex flex-wrap">
      <!-- Total Certs-->
      <div class="flex-1 px-2 py-4">
        <div class="flex h-full items-center justify-between rounded bg-white p-10 shadow-lg">
          <div>
            <div class="flex h-18 w-18 items-center justify-center rounded-full p-2" style="background-color: #f8f8f8">
              <icon class="h-10 w-10" name="certificate" style="fill: #bababb" />
            </div>
          </div>
          <div>
            <div class="mb-2 text-3xl text-black">{{ training_record_aggregates.total || 0 }}</div>
            <div style="color: #696a6c">{{ $t('Total Records') }}</div>
          </div>
        </div>
      </div>

      <!-- Expired Certs-->
      <div class="flex-1 px-2 py-4">
        <div class="flex h-full items-center justify-between rounded bg-white p-10 shadow-lg">
          <div>
            <div
              class="flex h-18 w-18 items-center justify-center rounded-full bg-qualify-red-100 p-2 text-3xl text-qualify-red-500"
            >
              <span>{{ training_record_aggregates.expired || 0 }}</span>
            </div>
          </div>
          <div>
            <div class="mb-2" style="color: #696a6c">{{ $t('Records Expired') }}</div>
            <div class="text-black">{{ $t('To Date') }}</div>
          </div>
        </div>
      </div>

      <!-- Expiring Certs-->
      <div class="flex-1 px-2 py-4">
        <div class="flex h-full items-center justify-between rounded bg-white p-10 shadow-lg">
          <div>
            <div
              class="flex h-18 w-18 items-center justify-center rounded-full bg-ts-orange-100 p-2 text-3xl text-ts-orange-600"
            >
              <span>{{ training_record_aggregates.expiring || 0 }}</span>
            </div>
          </div>
          <div>
            <div class="mb-2" style="color: #696a6c">{{ $t('Records Expiring') }}</div>
            <div class="text-black">{{ $t('In 90 days') }}</div>
          </div>
        </div>
      </div>

      <!-- Active Certs-->
      <div class="flex-1 px-2 py-4">
        <div class="flex h-full items-center justify-between rounded bg-white p-10 shadow-lg">
          <div>
            <div
              class="flex h-18 w-18 items-center justify-center rounded-full bg-ts-blue-100 p-2 text-3xl text-ts-blue-600"
            >
              <span>{{ training_record_aggregates.active || 0 }}</span>
            </div>
          </div>
          <div>
            <div class="mb-2" style="color: #696a6c">{{ $t('Active Records') }}</div>
            <div class="text-black">{{ $t('To Date') }}</div>
          </div>
        </div>
      </div>
    </div>

    <section class="mt-16 w-full">
      <div class="flex justify-between">
        <h3 class="mb-6 text-xl" style="color: #404624">{{ $t('All Certificates') }}</h3>
        <div>
          <button class="pr-4" style="color: #0c5e85" @click="resetTableFilters()">{{ $t('Reset all') }}</button>
          <button v-if="$page.props.auth.user.email" style="color: #0c5e85" @click="showRecipientsModal = true">
            {{ $t('Download') }}
          </button>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table class="front-table min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th
                      class="cursor-pointer bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                      @click="updateSort('user')"
                    >
                      <span class="flex items-center">
                        {{ $t('Person') }}
                        <template v-if="form.sort_filters.sortOrder && form.sort_filters.sortBy === 'user'">
                          <icon
                            v-if="form.sort_filters.sortOrder === 'desc'"
                            class="ml-2 h-4 w-4 fill-white"
                            name="chevron-up"
                          />
                          <icon
                            v-else-if="form.sort_filters.sortOrder === 'asc'"
                            class="ml-2 h-4 w-4 fill-white"
                            name="chevron-down-wide"
                          />
                        </template>
                        <icon v-else class="ml-2 h-4 w-4" name="chevron-up-down" />
                      </span>
                    </th>
                    <th
                      class="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                    >
                      <front-select-input v-model="form.table_filters.trainingRecordTemplate">
                        <option :value="null">{{ $t('Training Record Name') }}</option>
                        <option
                          v-for="trainingRecord in training_records"
                          :key="trainingRecord.id"
                          :value="trainingRecord.id"
                        >
                          {{ trainingRecord.name }}
                        </option>
                      </front-select-input>
                    </th>
                    <th
                      class="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                    >
                      {{ $t('Number') }}
                    </th>
                    <th
                      class="cursor-pointer bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                      @click="updateSort('created_at')"
                    >
                      <span class="flex items-center">
                        {{ $t('Issue Date') }}
                        <template v-if="form.sort_filters.sortOrder && form.sort_filters.sortBy === 'created_at'">
                          <icon
                            v-if="form.sort_filters.sortOrder === 'desc'"
                            class="ml-2 h-4 w-4 fill-white"
                            name="chevron-up"
                          />
                          <icon
                            v-else-if="form.sort_filters.sortOrder === 'asc'"
                            class="ml-2 h-4 w-4 fill-white"
                            name="chevron-down-wide"
                          />
                        </template>
                        <icon v-else class="ml-2 h-4 w-4" name="chevron-up-down" />
                      </span>
                    </th>
                    <th
                      class="cursor-pointer bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                      @click="updateSort('expires_at')"
                    >
                      <span class="flex items-center">
                        {{ $t('Expiry Date') }}
                        <template v-if="form.sort_filters.sortOrder && form.sort_filters.sortBy === 'expires_at'">
                          <icon
                            v-if="form.sort_filters.sortOrder === 'desc'"
                            class="ml-2 h-4 w-4 fill-white"
                            name="chevron-up"
                          />
                          <icon
                            v-else-if="form.sort_filters.sortOrder === 'asc'"
                            class="ml-2 h-4 w-4 fill-white"
                            name="chevron-down-wide"
                          />
                        </template>
                        <icon v-else class="ml-2 h-4 w-4" name="chevron-up-down" />
                      </span>
                    </th>
                    <th
                      class="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                    >
                      <front-select-input v-model="form.table_filters.status">
                        <option :value="null">{{ $t('Status') }}</option>
                        <option v-for="(status, val, i) in statuses" :key="i" :value="status">
                          {{ status }}
                        </option>
                      </front-select-input>
                    </th>
                    <th
                      class="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-ts-front-label"
                    >
                      <!-- Download button -->
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="active_training_records.data.length === 0">
                    <td
                      class="whitespace-nowrap bg-white px-6 py-4 text-center text-sm leading-5 text-ts-front-label"
                      colspan="7"
                    >
                      {{ $t('No certificates found.') }}
                    </td>
                  </tr>
                  <tr v-for="trainingRecord in active_training_records.data" :key="trainingRecord.id" class="bg-white">
                    <td v-if="isSelectingCertificates">
                      <front-row-select-checkbox
                        v-if="!trainingRecord.is_training_record"
                        :id="'checkbox-' + trainingRecord.id"
                        v-model="trainingRecordForm.certificates"
                        :disabled="trainingRecord.manager_signature"
                        :value="trainingRecord.id"
                      />
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-base font-medium leading-5 text-ts-gray-text-bold">
                      {{ trainingRecord.user.name }}
                    </td>
                    <td class="px-6 py-4 text-sm leading-5 text-ts-front-label">
                      <front-truncate-text class="mr-2" :text="trainingRecord.name" />
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm leading-5 text-ts-front-label">
                      {{ trainingRecord.number }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm leading-5 text-ts-front-label">
                      {{ trainingRecord.created_at }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm leading-5 text-ts-front-label">
                      {{ trainingRecord.expires_at }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm leading-5 text-ts-front-label">
                      <span class="rounded-sm p-1 uppercase" :class="statusBadgeStyle(trainingRecord.status)">
                        {{ trainingRecord.status }}
                      </span>
                    </td>
                    <td class="hidden lg:table-cell">
                      <div class="flex items-center justify-center">
                        <a
                          v-if="trainingRecord.can.downloadProofOfTraining"
                          class="mr-2"
                          :href="route('front.manager.training-records.download', trainingRecord)"
                        >
                          <icon class="h-6 w-6 text-sm text-ts-blue-500" name="download" :title="$t('download')" />
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-between">
        <front-solid-button
          v-if="isSelectingCertificates"
          class="mt-4"
          :class="{ disabled: trainingRecordForm.training_records.length == 0 }"
          :disabled="trainingRecordForm.training_records.length == 0"
          @click="showSignaturePadModal = true"
        >
          {{ $t('Sign Now') }}
        </front-solid-button>
        <div v-else class="mt-4" />

        <front-pagination :links="active_training_records.links" :preserve-scroll="true" :preserve-state="true" />
      </div>
    </section>
    <front-modal
      class="rounded text-base"
      :show="showRecipientsModal"
      width="auto"
      @close="showRecipientsModal = false"
    >
      <div class="max-w-md overflow-hidden rounded bg-white p-6 shadow md:p-10 lg:p-12">
        <div class="mb-4 text-center">
          <h1 class="mt-4 text-3xl font-bold">{{ $t('Export Recipients') }}</h1>
        </div>
        <div class="py-4">
          {{ $t('Please enter any additional emails this report should be sent to.') }}
          <form class="">
            <text-input v-model="$page.props.auth.user.email" class="mt-6" disabled :label="$t('Recipient 1')" />
            <label class="form-label mt-5" for="last-name">{{ $t('Recipient 2:') }}</label>
            <input id="last-name" v-model="form.additional_recipients[0]" class="form-input" type="email" />
            <div v-if="form.errors['additional_recipients.0']" class="form-error">
              {{ $t('This email must be valid.') }}
            </div>
            <label class="form-label mt-5" for="last-name">{{ $t('Recipient 3:') }}</label>
            <input id="last-name" v-model="form.additional_recipients[1]" class="form-input" type="email" />
            <div v-if="form.errors['additional_recipients.1']" class="form-error">
              {{ $t('This email must be valid.') }}
            </div>
          </form>
        </div>
        <div class="flex items-center justify-center bg-white px-2 pt-8">
          <button
            class="mx-2 rounded-full border border-qualify-red-500 px-12 py-4 text-lg font-semibold text-qualify-red-500"
            type="button"
            @click=";(form.additional_recipients = []), (showRecipientsModal = false)"
          >
            <slot name="cancel-button">{{ $t('Cancel') }}</slot>
          </button>
          <loading-button
            class="rounded-full bg-qualify-red-500 px-12 py-4 text-lg font-semibold text-white"
            type="button"
            @click="exportReport()"
          >
            <slot name="confirm-button">{{ $t('Export') }}</slot>
          </loading-button>
        </div>
      </div>
    </front-modal>
  </report-layout>
</template>

<script>
import ReportLayout from '../ReportLayout.vue'
import FrontModal from '@/Shared/FrontModal.vue'
import FrontPagination from '@/Shared/FrontPagination.vue'
import FrontRowSelectCheckbox from '@/Shared/FrontRowSelectCheckbox.vue'
import FrontSelectInput from '@/Shared/FrontSelectInput.vue'
import FrontSolidButton from '@/Shared/FrontSolidButton.vue'
import FrontTruncateText from '@/Shared/FrontTruncateText.vue'
import Icon from '@/Shared/Icon.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'
import _debounce from 'lodash/debounce'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'

export default {
  components: {
    ReportLayout,
    FrontModal,
    FrontPagination,
    FrontRowSelectCheckbox,
    FrontSelectInput,
    FrontSolidButton,
    FrontTruncateText,
    Icon,
    LoadingButton,
    TextInput,
  },
  props: {
    groups: Array,
    statuses: Array,
    training_records: Array,
    active_training_records: Object,
    training_record_aggregates: Object,
    filters: Object,
    sort_filters: Object,
  },
  data() {
    return {
      showRecipientsModal: false,
      form: useForm({
        additional_recipients: [],
        table_filters: {
          trainingRecordTemplate: this.filters?.table_filters?.trainingRecordTemplate || null,
          status: this.filters?.table_filters?.status || null,
        },
        sort_filters: {
          sortBy: this.sort_filters?.sort_filters?.sortBy || null,
          sortOrder: this.sort_filters?.sort_filters?.sortOrder || null,
        },
      }),
      trainingRecordForm: useForm({
        trainingRecords: [],
        image: null,
      }),
    }
  },
  watch: {
    form: {
      handler: _debounce(function () {
        let query = {
          table_filters: _pickBy(this.form.table_filters),
          sort_filters: _pickBy(this.form.sort_filters),
        }
        this.$inertia.get(
          this.route('front.manager.reports.training-records.show'),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveScroll: true,
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
  },
  methods: {
    resetTableFilters() {
      this.form.table_filters = _mapValues(this.form.table_filters, () => null)
      this.form.sort_filters = _mapValues(this.form.sort_filters, () => null)
    },
    statusBadgeStyle(status) {
      const styles = {
        active: 'bg-gray-100 text-ts-gray-text',
        expiring: 'bg-ts-orange-100 text-ts-orange-600',
        expired: 'bg-qualify-red-100 text-qualify-red-500',
      }
      return styles[status.toLowerCase()] || ''
    },
    exportReport() {
      this.form.post(this.route('front.manager.reports.training-records.download'))
      this.showRecipientsModal = false
    },
    updateSort(column) {
      this.form.sort_filters.sortBy = column
      this.form.sort_filters.sortOrder = this.form.sort_filters.sortOrder === 'asc' ? 'desc' : 'asc'
    },
  },
}
</script>
