<template>
  <div class="bg-ts-front-bg">
    <DynamicHead :title="pageTitle" />
    <div id="dropdown-port" name="dropdown" slim />
    <div class="flex flex-col">
      <div class="flex min-h-screen flex-col">
        <div class="flex flex-grow">
          <div class="w-full">
            <div class="w-full overflow-hidden">
              <div
                v-if="$page.props.flash.success && showFlash"
                class="no-print mb-8 flex max-w-lg items-center justify-between rounded bg-green-400"
              >
                <div class="flex items-center">
                  <icon class="ml-4 mr-2 h-4 w-4 flex-shrink-0 fill-white" name="checkmark" />
                  <div class="py-4 text-sm font-medium text-white">{{ $page.props.flash.success }}</div>
                </div>
                <button class="group mr-2 p-2" type="button" @click="showFlash = false">
                  <icon class="block h-3 w-3 fill-green-300 group-hover:fill-green-100" name="close" />
                </button>
              </div>
              <div
                v-if="$page.props.flash.error && showFlash"
                class="no-print mb-8 flex max-w-lg items-center justify-between rounded bg-red-400"
              >
                <div class="flex items-center">
                  <icon class="ml-4 mr-2 h-4 w-4 flex-shrink-0 fill-white" name="close-outline" />
                  <div class="py-4 text-sm font-medium text-white">{{ $page.props.flash.error }}</div>
                </div>
                <button class="group mr-2 p-2" type="button" @click="showFlash = false">
                  <icon class="block h-3 w-3 fill-red-300 group-hover:fill-red-100" name="close" />
                </button>
              </div>
              <div
                v-if="$page.props.errors.length > 0 && showFlash"
                class="no-print mb-8 flex max-w-lg items-center justify-between rounded bg-red-400"
              >
                <div class="flex items-center">
                  <icon class="ml-4 mr-2 h-4 w-4 flex-shrink-0 fill-white" name="close-outline" />
                  <div class="py-4 text-sm font-medium text-white">
                    <span v-if="$page.props.errors.length === 1">{{ $t('There is one form error.') }}</span>
                    <span v-else>
                      {{ $t('There are :length form errors.', { length: $page.props.errors.length }) }}
                    </span>
                  </div>
                </div>
                <button class="group mr-2 p-2" type="button" @click="showFlash = false">
                  <icon class="block h-3 w-3 fill-red-300 group-hover:fill-red-100" name="close" />
                </button>
              </div>
            </div>
            <slot />
          </div>
        </div>
      </div>
    </div>
    <div v-if="$page.props.isImpersonating" class="no-print fixed bottom-0 left-0 mb-5 ml-8">
      <front-button-link as="button" class="btn-red" :href="route('stop-impersonate')" type="button">
        {{ $t('Stop Impersonating') }}
      </front-button-link>
    </div>
    <front-organization-create-modal :show="showOrganizationCreateModal" @close="showOrganizationCreateModal = false" />
  </div>
</template>
<script>
import DynamicHead from './DynamicHead.vue'
import FrontButtonLink from './FrontButtonLink.vue'
import FrontOrganizationCreateModal from './FrontOrganizationCreateModal.vue'
import Icon from './Icon.vue'
import { isNotUrl, isTenantWorksite, isUrl, logoName } from '@/Utils/Helpers'
import zendesk from '@/mixins/zendesk.js'

export default {
  components: {
    DynamicHead,
    FrontButtonLink,
    FrontOrganizationCreateModal,
    Icon,
  },
  mixins: [zendesk],
  props: {
    title: String,
  },
  data() {
    return {
      showFlash: true,
      open: false,
      showOrganizationCreateModal: false,
      accountLogo: this.$page.props.app.tenant.logo,
    }
  },
  computed: {
    isTenantWorksite,
    pageTitle() {
      return this.title ? `${this.title} | ${this.$page.props.app.name}` : this.$page.props.app.name
    },
  },
  watch: {
    '$page.props.flash': {
      handler() {
        this.showFlash = true
      },
      deep: true,
    },
  },
  mounted() {
    // Auth Check Zendesk Chat Widget
    this.zE_auth()
  },
  methods: {
    logoName,
    isUrl,
    isNotUrl,
  },
}
</script>
