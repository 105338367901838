<template>
  <layout :title="$t('Classes')">
    <div class="mb-8">
      <breadcrumb
        v-if="class_"
        :middle-name="class_.id"
        :middle-url="route('classes.show', class_)"
        :name="$t('Post')"
        :previous-name="$t('Classes')"
        :previous-url="route('classes')"
      />
      <breadcrumb v-else :name="$t('Post')" :previous-name="$t('Classes')" :previous-url="route('classes')" />
    </div>
    <div class="mb-6 flex items-center justify-between">
      <search-filter v-if="!class_" v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
        <label class="block text-gray-800">{{ $t('Course:') }}</label>
        <select v-model="form.course" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="course in courses" :key="course.id" :value="course.id">{{ course.name }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Between:') }}</label>
        <date-input v-model="form.local_start_date" class="mt-1 w-full" />
        <date-input v-model="form.local_end_date" class="mt-1 w-full" />
      </search-filter>

      <div class="flex justify-end space-x-3">
        <button class="btn-blue ml-3" type="button" @click="post">
          <span>{{ $t('Post to Marketplace') }}</span>
        </button>
        <button class="btn-blue ml-3" type="button" @click="remove">{{ $t('Remove from Marketplace') }}</button>
      </div>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow">
      <SimpleTable>
        <template #head>
          <TableHead><input v-model="selectAll" class="form-checkbox" type="checkbox" /></TableHead>
          <TableHead>{{ $t('Courses') }}</TableHead>
          <TableHead>{{ $t('Sessions') }}</TableHead>
          <TableHead>{{ $t('Location') }}</TableHead>
          <TableHead>{{ $t('Marketplace') }}</TableHead>
        </template>
        <TableRow v-if="marketplaceClasses.data.length === 0">
          <TableData colspan="5">{{ $t('No classes found.') }}</TableData>
        </TableRow>
        <TableRow
          v-for="(marketplaceClass, index) in marketplaceClasses.data"
          :key="makeId(marketplaceClass)"
          class="hover:bg-gray-100 focus:bg-gray-100"
        >
          <TableData>
            <input v-model="selected" class="form-checkbox" type="checkbox" :value="index" />
          </TableData>
          <TableData>
            {{ marketplaceClass.course.name }}
            <br />
            <small class="mt-1 inline-block">
              {{
                $t('ILC: :name, Class #:id', {
                  name: marketplaceClass.instructorLedClassroom.name,
                  id: marketplaceClass.class.id,
                })
              }}
            </small>
          </TableData>
          <TableData>
            <div v-if="marketplaceClass.class.sessions_count">
              <span class="mr-1 rounded bg-gray-400 px-2 py-1 text-sm text-white">
                {{ marketplaceClass.class.sessions_count }}
              </span>
              {{ marketplaceClass.class.dates }}
            </div>
            <div v-else class="text-red-500">{{ $t('None set') }}</div>
          </TableData>
          <TableData>
            {{ marketplaceClass.class.location.name }}
          </TableData>
          <TableData>
            <div class="flex justify-center">
              <icon
                class="h-3 w-3 flex-shrink-0 fill-gray-400"
                :name="marketplaceClass.is_posted_to_marketplace ? 'checkmark' : 'close'"
              />
            </div>
          </TableData>
        </TableRow>
      </SimpleTable>
    </div>
    <pagination :links="marketplaceClasses.links" />
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import DateInput from '@/Shared/DateInput.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
    Breadcrumb,
    DateInput,
    Icon,
    Layout,
    Pagination,
    SearchFilter,
  },
  props: {
    class_: [Object, null],
    marketplaceClasses: Object,
    courses: Array,
    locations: Array,
    filters: Object,
  },
  data() {
    return {
      selectAll: false,
      selected: [],
      form: {
        search: this.filters.search,
        course: this.filters.course,
        local_start_date: this.filters.local_start_date,
        local_end_date: this.filters.local_end_date,
      },
    }
  },
  computed: {
    makeId() {
      return (marketplaceClass) => {
        return `${marketplaceClass.class.id}_${marketplaceClass.course.id}_${marketplaceClass.instructorLedClassroom.id}`
      }
    },
    selectedMarketplaceClassesForForm() {
      return this.marketplaceClasses.data
        .filter((_, index) => this.selected.includes(index))
        .map((marketplace) => ({
          class_id: marketplace.class.id,
          course_id: marketplace.course.id,
          instructor_led_classroom: marketplace.instructorLedClassroom.id,
        }))
    },
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(this.route('masspost'), Object.keys(query).length ? query : { remember: 'forget' }, {
          preserveState: true,
          replace: true,
        })
      }, 300),
      deep: true,
    },
    selectAll() {
      if (this.selectAll) {
        // We are only interested in the keys here.
        this.selected = Object.keys(this.marketplaceClasses.data).map((index) => parseInt(index))
      } else {
        this.unselect()
      }
    },
  },
  methods: {
    post() {
      if (this.selected.length) {
        this.$inertia.post(this.route('masspost.marketplace.store'), {
          marketplace_classes: this.selectedMarketplaceClassesForForm,
        })

        this.unselect()
      }
    },
    remove() {
      if (this.selected.length) {
        this.$inertia.post(this.route('masspost.marketplace.destroy'), {
          marketplace_classes: this.selectedMarketplaceClassesForForm,
        })

        this.unselect()
      }
    },
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
    unselect() {
      this.selected = []

      this.selectAll = false
    },
    postToMyBusiness() {
      this.$inertia.post(this.route('masspost.mybusiness.store'), {
        marketplace_classes: this.selectedMarketplaceClassesForForm,
      })
    },
  },
}
</script>
