<template>
  <card name="Calendar Feed">
    <div class="w-full">
      <input class="form-input" disabled type="text" :value="calendarUrl" />
    </div>
  </card>
</template>

<script>
import Card from './Card.vue'

export default {
  components: {
    Card,
  },
  props: { calendarUrl: String },
}
</script>
