<template>
  <layout :title="$t('Create Course')">
    <div class="mb-8">
      <breadcrumb
        :name="$t('Create Follow Up Course')"
        :previous-name="course.name_en"
        :previous-url="route('courses.show', course.id)"
      />
    </div>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div v-if="courses.length > 0" class="w-full pb-8 pr-6">
            <select-input
              v-model="form.follow_up_course_id"
              :error="form.errors.follow_up_course_id"
              :label="$t('Course Category')"
            >
              <option :value="null" />
              <option v-for="courseObject in courses" :key="courseObject.id" :value="course.id">
                {{ courseObject.name }}
              </option>
            </select-input>
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Create Follow Up Course') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    Layout,
    LoadingButton,
    SelectInput,
  },
  props: {
    course: Object,
    courses: Array,
  },
  data() {
    return {
      form: useForm({
        follow_up_course_id: null,
      }),
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('courses.follow-up.store', this.course.id))
    },
  },
}
</script>
