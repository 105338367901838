<template>
  <layout :title="$t('Classes Calendar')">
    <div class="mb-8">
      <breadcrumb :name="$t('Classes')" />
    </div>
    <div class="mb-6 flex items-center justify-between">
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
        <label class="block text-gray-800">{{ $t('Instructor Led Class:') }}</label>
        <select v-model="form.instructor_led_classroom" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="ilc in instructor_led_classrooms" :key="ilc.id" :value="ilc.id">{{ ilc.name }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Access:') }}</label>
        <select v-model="form.access" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="public">{{ $t('Public') }}</option>
          <option value="private">{{ $t('Private') }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('MOL Status:') }}</label>
        <select v-model="form.mol" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="submitted">{{ $t('Submitted') }}</option>
          <option value="partially">{{ $t('Partially Submitted') }}</option>
          <option value="not">{{ $t('Not Submitted') }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Locale:') }}</label>
        <select v-model="form.locale" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="(locale, key) in locales" :key="key" :value="locale">
            {{ key }}
          </option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Start Date:') }}</label>
        <date-input v-model="form.local_start_date" class="mt-1 w-full" />
        <label class="mt-4 block text-gray-800">{{ $t('Fullness:') }}</label>
        <select v-model="form.fullness" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="(label, key) in fullness_values" :key="key" :value="label">{{ label }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Class Type:') }}</label>
        <select v-model="form.class_type" class="form-select mt-1 w-full">
          <option value="parent">{{ $t('Parent Classes') }}</option>
          <option value="subclass">{{ $t('Subclasses') }}</option>
        </select>
        <div class="mt-4 flex justify-between">
          <label class="block text-gray-800">{{ $t('Location:') }}</label>
          <div class="flex items-center">
            <span class="mr-2 text-sm">{{ $t('With client sites') }}</span>
            <input v-model="is_client_site_checked" type="checkbox" />
          </div>
        </div>
        <select v-model="form.location" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="location in locations" :key="location.id" :value="location.id">
            {{ location.name }}
          </option>
        </select>
        <template v-if="form.location && roomsForSelectedLocation.length > 0">
          <div class="mt-4 flex justify-between">
            <label class="block text-gray-800">{{ $t('Room:') }}</label>
          </div>
          <select v-model="form.room" class="form-select mt-1 w-full">
            <option :value="null" />
            <option v-for="room in roomsForSelectedLocation" :key="room.id" :value="room.id">
              {{ room.name }}
            </option>
          </select>
        </template>
        <label class="mt-4 block text-gray-800">{{ $t('Instructor:') }}</label>
        <select v-model="form.instructor" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="instructor in instructors" :key="instructor.id" :value="instructor.id">
            {{ instructor.last_name }}, {{ instructor.first_name }}
          </option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Cancelled:') }}</label>
        <select v-model="form.cancelled" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="with">{{ $t('With Cancelled') }}</option>
          <option value="only">{{ $t('Only Cancelled') }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Deleted:') }}</label>
        <select v-model="form.deleted" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="with">{{ $t('With Deleted') }}</option>
          <option value="only">{{ $t('Only Deleted') }}</option>
        </select>
      </search-filter>
      <div class="hidden lg:flex">
        <button class="btn-blue" @click="goToPrevious">
          <span>{{ $t('Previous') }}</span>
        </button>
        <button class="btn-blue ml-3" @click="goToNext">
          <span>{{ $t('Next') }}</span>
        </button>
      </div>
    </div>
    <div class="overflow-x-scroll rounded bg-white shadow">
      <div class="flex w-full">
        <div v-for="(day, key) in days" :key="key" class="border-1 min-w-fit flex-1 border">
          <div class="bg-gray-200 p-3">
            {{ key }}
          </div>
          <div
            v-if="leaves && leaves[key] && leaves[key]['hours'] > 0"
            class="m-1 cursor-pointer rounded-lg border border-yellow-200 bg-yellow-100 p-3"
          >
            <div class="m-1 mb-2 font-semibold">{{ leaves[key]['type'] }}</div>
            <div class="m-1 flex items-center text-sm">
              <icon class="mr-2 h-3 w-3 fill-gray-600" name="time" />
              <span>{{ leaves[key]['hours'] }}</span>
              <span>&nbsp;hours</span>
            </div>
            <div class="m-1 flex items-center text-sm">
              <icon class="mr-2 h-3 w-3 fill-gray-600" name="user" />
              <span>{{ leaves[key]['name'] }}</span>
            </div>
          </div>
          <div v-for="session in day" :key="session.id" class="m-1 cursor-pointer rounded-lg bg-gray-100 p-3">
            <div
              class="mb-2 flex items-center justify-between"
              @click="$inertia.visit(route('classes.show', session.class_id))"
            >
              <p class="font-semibold">{{ session.classroom_name }}</p>
              <icon v-if="session.private" class="h-3 w-3 fill-gray-500" name="lock" />
            </div>
            <div class="text-sm" @click="$inertia.visit(route('classes.show', session.class_id))">
              {{ session.location.name }}
            </div>
            <div
              v-if="session.location.room"
              class="mt-1 text-sm"
              @click="$inertia.visit(route('classes.show', session.class_id))"
            >
              {{ session.location.room }}
            </div>
            <div
              class="mt-1 flex items-center text-sm"
              @click="$inertia.visit(route('classes.show', session.class_id))"
            >
              <icon class="mr-2 h-4 w-4 fill-gray-600" name="users" />
              {{ session.student_count }} Filled | {{ session.capacity - session.student_count }} Available
            </div>
            <div
              class="mt-1 flex items-center text-sm"
              @click="$inertia.visit(route('classes.show', session.class_id))"
            >
              <icon class="mr-2 h-3 w-3 fill-gray-600" name="time" />
              {{ session.time }}
            </div>
            <div
              v-if="session.instructors"
              class="mt-1 flex items-center text-sm"
              @click="$inertia.visit(route('classes.show', session.class_id))"
            >
              <icon class="mr-2 h-4 w-4 fill-gray-600" name="users" />
              {{ session.instructors }}
            </div>
            <div v-if="session.subsessions.length > 0" class="mt-2 flex items-center text-sm">
              <details class="w-full">
                <summary class="text-gray-800">
                  {{ session.subsessions.length > 1 ? 'Subclasses' : 'Subclass' }}
                </summary>
                <div
                  v-for="sessionObject in session.subsessions"
                  :key="sessionObject.id"
                  class="mt-2 cursor-pointer rounded-lg bg-white p-3"
                >
                  <div
                    class="mb-2 font-semibold"
                    @click="$inertia.visit(route('classes.show', sessionObject.class_id))"
                  >
                    {{ sessionObject.classroom_name }}
                  </div>
                  <div class="text-sm" @click="$inertia.visit(route('classes.show', sessionObject.class_id))">
                    {{ sessionObject.location.name }}
                  </div>
                  <div
                    v-if="sessionObject.location.room"
                    class="mt-1 text-sm"
                    @click="$inertia.visit(route('classes.show', sessionObject.class_id))"
                  >
                    {{ sessionObject.location.room }}
                  </div>
                  <div
                    class="mt-1 flex items-center text-sm"
                    @click="$inertia.visit(route('classes.show', sessionObject.class_id))"
                  >
                    <icon class="mr-2 h-4 w-4 fill-gray-600" name="users" />
                    {{ sessionObject.student_count }} Filled |
                    {{ sessionObject.capacity - sessionObject.student_count }} Available
                  </div>
                  <div
                    class="mt-1 flex items-center text-sm"
                    @click="$inertia.visit(route('classes.show', sessionObject.class_id))"
                  >
                    <icon class="mr-2 h-3 w-3 fill-gray-600" name="time" />
                    {{ sessionObject.time }}
                  </div>
                  <div
                    v-if="sessionObject.instructors"
                    class="mt-1 flex items-center text-sm"
                    @click="$inertia.visit(route('classes.show', sessionObject.class_id))"
                  >
                    <icon class="mr-2 h-4 w-4 fill-gray-600" name="users" />
                    {{ sessionObject.instructors }}
                  </div>
                </div>
              </details>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import DateInput from '@/Shared/DateInput.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import _debounce from 'lodash/debounce'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'

export default {
  components: {
    Icon,
    Breadcrumb,
    DateInput,
    Layout,
    SearchFilter,
  },
  props: {
    days: Object,
    leaves: Object,
    courses: Array,
    locations: Array,
    rooms: Array,
    instructors: Array,
    instructor_led_classrooms: Array,
    filters: Object,
    locales: Object,
    can: Object,
    fullness_values: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        instructor_led_classroom: this.filters.instructor_led_classroom,
        access: this.filters.access,
        mol: this.filters.mol,
        locale: this.filters.locale,
        local_start_date: this.filters.local_start_date,
        location: this.filters.location,
        room: this.filters.room,
        instructor: this.filters.instructor,
        cancelled: this.filters.cancelled,
        deleted: this.filters.deleted,
        client_site: this.filters.client_site,
        class_type: this.filters.class_type ?? 'parent',
      },
      is_client_site_checked: false,
    }
  },
  computed: {
    roomsForSelectedLocation() {
      return this.rooms.filter((room) => room.location_id == this.form.location)
    },
  },
  watch: {
    is_client_site_checked() {
      this.form.client_site = this.is_client_site_checked ? 'with' : null
    },
    'form.location'(newVal) {
      if (!newVal) {
        this.form.room = null
      }
    },
    form: {
      handler: _debounce(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(this.route('classes.calendar'), Object.keys(query).length ? query : { remember: 'forget' }, {
          preserveState: true,
          replace: true,
          only: ['days'],
        })
      }, 300),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
      this.form.class_type = 'parent'
    },
    goToPrevious() {
      let date = new Date(this.form.local_start_date)
      date.setDate(date.getDate() - 7)

      this.setLocalStartsAt(date)
    },
    goToNext() {
      let date = new Date(this.form.local_start_date)
      date.setDate(date.getDate() + 7)

      this.setLocalStartsAt(date)
    },
    setLocalStartsAt(date) {
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

      this.form.local_start_date = `${days[date.getUTCDay()]}, ${
        months[date.getUTCMonth()]
      } ${date.getUTCDate()}, ${date.getUTCFullYear()}`
    },
  },
}
</script>
