<template>
  <TableRow @click="$inertia.visit(route('classes.show', class_.id))">
    <TableData :class="{ 'subclass-background-image': isShowSubClass }">
      <div class="flex items-center">
        <div class="max-w-xs truncate leading-tight">
          {{ class_.classroom_name ? `${class_.classroom_name}` : null }}
        </div>
        <class-ministry-of-labour-status-badge
          v-if="class_.skillspass_status"
          class="ml-2"
          :status="class_.skillspass_status"
        />
        <span v-if="class_.private" class="ml-2 rounded bg-gray-400 px-2 py-1 text-sm text-white">
          {{ $t('Private') }}
        </span>
        <span v-if="class_.locale !== 'en'" class="ml-2 rounded bg-gray-400 px-2 py-1 text-sm uppercase text-white">
          {{ class_.locale }}
        </span>
        <span v-if="class_.reselling" class="ml-2 rounded bg-gray-400 px-2 py-1 text-sm text-white">
          {{ $t('Reselling') }}
        </span>
        <span
          v-if="class_.cancelled_at"
          class="ml-2 whitespace-nowrap rounded bg-gray-400 px-2 py-1 text-sm text-white"
        >
          {{ $t('Cancelled at :cancelled_at', { cancelled_at: class_.cancelled_at }) }}
        </span>
        <deleted-badge v-if="class_.deleted_at" />
      </div>
      <div class="mt-1 flex text-sm text-gray-600">
        <div class="whitespace-nowrap">
          {{ $t('Capacity:') }}
          <span class="ml-0.5">{{ class_.capacity }}</span>
        </div>
        <div class="ml-3 whitespace-nowrap">
          {{ $t('Filled:') }}
          <span class="ml-0.5">{{ class_.total_students_count }}</span>
        </div>
        <div class="ml-3 whitespace-nowrap">
          {{ $t('Available:') }}
          <span class="ml-0.5">{{ class_.available_seats }}</span>
        </div>
      </div>
    </TableData>
    <TableData>
      <Popper :hover="true">
        <div v-if="class_.sessions_count" class="inline-flex items-start">
          <div class="mr-1 rounded bg-gray-400 px-2 py-1 text-sm text-white">
            {{ class_.sessions_count }}
          </div>
          <div class="">
            {{ class_.dates }}
            <span class="mt-1 block text-sm text-gray-600">{{ showSessionTime }}</span>
          </div>
        </div>
        <div v-else class="text-red-500">{{ $t('None set') }}</div>
        <template #content>
          <div class="mt-2 rounded border bg-white py-2 text-sm shadow-lg">
            <div v-for="session in class_.sessions" :key="session.id" class="px-4 py-2">
              {{ session.date }}, {{ session.time }}
            </div>
          </div>
        </template>
      </Popper>
    </TableData>
    <TableData>
      <div class="flex items-center">
        <span>{{ class_.location.name }}</span>
        <span v-if="class_.location.client_site" class="ml-2 rounded bg-gray-400 px-2 py-1 text-sm text-white">
          {{ $t('Client') }}
        </span>
      </div>
      <div v-if="class_.room" class="mt-1 text-sm text-gray-600">{{ class_.room }}</div>
    </TableData>
    <TableData class="text-right">
      <span v-if="!class_.reselling">
        {{
          $t(':filled_seats / :available_seats', {
            filled_seats: class_.total_students_count,
            available_seats: class_.available_seats,
          })
        }}
      </span>
      <span v-else-if="class_.reselling_courses.length === 1">
        {{
          $t(':filled_seats / :available_seats', {
            filled_seats: class_.reselling_courses[0].filled_seats,
            available_seats: class_.reselling_courses[0].available_seats,
          })
        }}
      </span>
      <div v-else>
        <div v-for="resellingCourse in class_.reselling_courses" :key="resellingCourse.name">
          {{
            $t(':name : :filled_seats / :available_seats', {
              name: resellingCourse.name,
              filled_seats: resellingCourse.filled_seats,
              available_seats: resellingCourse.available_seats,
            })
          }}
        </div>
      </div>
    </TableData>
    <TableData>{{ class_.instructors }}</TableData>
    <TableData class="w-min align-middle">
      <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
    </TableData>
  </TableRow>
</template>

<script>
import ClassMinistryOfLabourStatusBadge from '@/Shared/ClassMinistryOfLabourStatusBadge.vue'
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import Icon from '@/Shared/Icon.vue'
import TableData from '@/Shared/TableData.vue'
import TableRow from '@/Shared/TableRow.vue'
import Popper from 'vue3-popper'

export default {
  name: 'ClassIndexRow',
  components: {
    Popper,
    ClassMinistryOfLabourStatusBadge,
    DeletedBadge,
    Icon,
    TableData,
    TableRow,
  },
  props: {
    class_: Object,
    showSubclassHighlighting: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isShowSubClass() {
      return this.class_.parent_class_id && this.showSubclassHighlighting
    },
    showSessionTime() {
      let sessionStartTime = '',
        sessionEndTime = '',
        currentDate = ''

      for (let i = 0; i < this.class_.sessions.length; i++) {
        if (i === 0) {
          currentDate = this.class_.sessions[i].date
          sessionStartTime = this.class_.sessions[i].time.split('~').shift()
          sessionEndTime = this.class_.sessions[i].time.split('~').pop()
        } else {
          if (currentDate === this.class_.sessions[i].date) {
            sessionEndTime = this.class_.sessions[i].time.split('~').pop()
          }
        }
      }

      return sessionStartTime + ' ~ ' + sessionEndTime
    },
  },
}
</script>
<style scoped>
.subclass-background-image {
  background: linear-gradient(to right, #ccc 0%, #ccc 0.5rem, transparent 0.5rem, transparent 100%);
}
</style>
