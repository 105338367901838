<template>
  <layout :title="$t('Edit Student')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('classes')">
        {{ $t('Classes') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('classes.show', student.class.id)">
        {{ student.class.course.name }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Edit Student') }}
    </h1>
    <deleted-message v-if="student.deleted_at" class="mb-6">{{ $t('This student has been deleted.') }}</deleted-message>
    <div class="flex max-w-3xl flex-wrap">
      <div class="w-full lg:w-3/5 xl:w-2/3">
        <div class="max-w-lg overflow-hidden rounded bg-white shadow">
          <form @submit.prevent="submit">
            <div class="-mb-8 -mr-6 flex flex-wrap p-8">
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <label class="form-label">{{ $t('Student:') }}</label>
                <div class="form-input bg-gray-100">{{ student.first_name }}&nbsp;{{ student.last_name }}</div>
              </div>
            </div>
            <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Score & Attendance') }}</div>
            <div class="-mb-8 -mr-6 flex flex-wrap p-8">
              <div v-if="student.first_name && student.last_name" class="w-full pb-8 pr-6 lg:w-1/2">
                <radio-input
                  v-model="form.present"
                  :data="{ Present: true, Absent: false }"
                  :error="form.errors.present"
                  :label="$t('Attendance')"
                  type="radio"
                />
              </div>
              <div v-if="form.present && student.class.test_scores_enabled" class="w-full pb-8 pr-6 lg:w-1/2">
                <div class="flex justify-between">
                  <label class="form-label" for="score">{{ $t('Score:') }}</label>
                  <div class="text-sm text-gray-600">
                    <span>
                      {{ $t('Max: :total_score', { total_score: student.class.instructorLedClassroom.total_score }) }}
                    </span>
                    <span class="ml-2">
                      {{
                        $t('Passing: :passing_score', {
                          passing_score: student.class.instructorLedClassroom.passing_score,
                        })
                      }}
                    </span>
                  </div>
                </div>
                <text-input id="score" disabled :model-value="student.score" />
              </div>
              <div v-if="!student.class.test_scores_enabled" class="w-full pb-8 pr-6 lg:w-1/2">
                <radio-input
                  v-model="form.passed"
                  :data="{ Passed: true, Failed: false }"
                  :disabled="!form.present"
                  :error="form.errors.passed"
                  :label="$t('Result')"
                  type="radio"
                />
              </div>
              <div v-else-if="form.present" class="w-full pb-8 pr-6 lg:w-1/2">
                <radio-input
                  :data="{ Passed: true, Failed: false }"
                  :disabled="true"
                  :error="form.errors.passed"
                  :label="$t('Result')"
                  :model-value="student.passed"
                  type="radio"
                />
              </div>
              <div v-if="form.present && form.passed" class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input
                  v-model="form.certificate_number"
                  :error="form.errors.certificate_number"
                  :label="$t('Certificate number')"
                />
              </div>
              <div v-if="student.class.equipment_enabled" class="flex w-full flex-col pb-6">
                <div class="flex pb-2">
                  <div class="w-full pr-6 lg:w-1/2">
                    <text-input
                      v-model="form.equipment_1"
                      :error="form.errors.default_equipment_types"
                      :label="$t('Equipment #1')"
                    />
                  </div>
                  <div class="w-full pr-6 lg:w-1/2">
                    <text-input
                      v-model="form.equipment_2"
                      :error="form.errors.default_equipment_types"
                      :label="$t('Equipment #2')"
                    />
                  </div>
                  <div class="w-full pr-6 lg:w-1/2">
                    <text-input
                      v-model="form.equipment_3"
                      :error="form.errors.default_equipment_types"
                      :label="$t('Equipment #3')"
                    />
                  </div>
                </div>
                <small>
                  Setting any of the above values will override the following values:
                  <div class="mt-2 flex flex-col">
                    <span v-for="(equipment, index) in student.class.default_equipment_types" :key="index">
                      {{ equipment }}
                    </span>
                  </div>
                </small>
              </div>
            </div>
            <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('User Information') }}</div>
            <div class="-mb-8 -mr-6 flex flex-wrap p-8">
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input disabled :label="$t('First name')" :model-value="student.first_name" />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input disabled :label="$t('Last name')" :model-value="student.last_name" />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input disabled :label="$t('Email')" :model-value="student.email" />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input disabled :label="$t('Phone')" :model-value="student.phone" />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input disabled :label="$t('Address')" :model-value="student.address" />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input disabled :label="$t('City')" :model-value="student.city" />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <select-input
                  disabled
                  :label="$t('Province/State')"
                  :model-value="student.region"
                  :options="[null, ...regions]"
                />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input disabled :label="$t('Postal code')" :model-value="student.postal_code" />
              </div>
            </div>
            <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
              <div>
                <button
                  v-if="student.deleted_at"
                  class="text-red-500 hover:underline"
                  tabindex="-1"
                  type="button"
                  @click="restore"
                >
                  {{ $t('Restore Student') }}
                </button>
                <InertiaLink
                  v-if="student.can.move"
                  class="ml-8 text-gray-700 hover:underline"
                  :href="route('students.move', student.id)"
                >
                  {{ $t('Move Student') }}
                </InertiaLink>
              </div>
              <loading-button class="btn-blue" :loading="form.processing" type="submit">
                {{ $t('Update Student') }}
              </loading-button>
            </div>
          </form>
        </div>
        <model-timestamps :model="student" />
      </div>

      <div v-if="student.enrolment" class="flex w-full flex-col pt-12 lg:w-2/5 lg:pl-8 lg:pt-0 xl:w-1/3">
        <form @submit.prevent="true">
          <div class="mb-12 overflow-hidden rounded bg-white shadow lg:mb-8">
            <div class="bg-gray-400 px-4 py-4 text-sm font-bold text-gray-700">{{ $t('Enrolment') }}</div>
            <div class="grid w-full grid-cols-2 gap-4 px-6 pb-3 pt-4 capitalize">
              <div>{{ $t('Owner') }}</div>
              <div>
                :
                <InertiaLink
                  v-if="student.enrolment.owner"
                  class="text-blue-600 underline"
                  :href="route('users.edit', student.enrolment.owner.id)"
                >
                  {{ student.enrolment.owner.name }}
                </InertiaLink>
                <span v-else>-</span>
              </div>
              <div>{{ $t('User') }}</div>
              <div>
                :
                <InertiaLink
                  v-if="student.enrolment.user"
                  class="text-blue-600 underline"
                  :href="route('users.edit', student.enrolment.user.id)"
                >
                  {{ student.enrolment.user.name }}
                </InertiaLink>
                <span v-else>-</span>
              </div>
              <div>{{ $t('Payment status') }}</div>
              <div class="capitalize">: {{ student.enrolment.payment_status }}</div>
              <div>{{ $t('Order number') }}</div>
              <div>
                :
                <span v-if="student.enrolment.payment_order_number">#{{ student.enrolment.payment_order_number }}</span>
              </div>
            </div>
            <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-6 py-2">
              <InertiaLink class="btn-blue" :href="route('enrolments.edit', student.enrolment.id)">
                {{ $t('Go To Enrolment') }}
              </InertiaLink>
            </div>
          </div>
        </form>
      </div>
    </div>
  </layout>
</template>

<script>
import DeletedMessage from '@/Shared/DeletedMessage.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import ModelTimestamps from '@/Shared/ModelTimestamps.vue'
import RadioInput from '@/Shared/RadioInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    DeletedMessage,
    Layout,
    LoadingButton,
    ModelTimestamps,
    RadioInput,
    SelectInput,
    TextInput,
  },
  props: {
    student: Object,
    customer: Object,
    regions: Array,
    skillspass: Boolean,
  },
  data() {
    return {
      selectedCustomer: this.customer,
      form: useForm(
        {
          present: this.student.present,
          passed: this.student.passed,
          certificate_number: this.student.certificate_number,
          submitted: this.student.submitted,
          equipment_1: this.student.equipment_types?.[0],
          equipment_2: this.student.equipment_types?.[1],
          equipment_3: this.student.equipment_types?.[2],
        },
        'StudentEdit'
      ),
    }
  },
  computed: {
    certificateNumber: function () {
      return this.form.present && this.form.score >= this.student.class.instructorLedClassroom.passing_score
        ? this.form.certificate_number
        : null
    },
  },
  watch: {
    'form.present': function (newValue) {
      if (!newValue) this.form.passed = false
    },
    'form.equipment_enabled'(newVal) {
      if (!newVal) {
        this.form.equipment_1 = null
        this.form.equipment_2 = null
        this.form.equipment_3 = null
      }
    },
  },
  methods: {
    submit() {
      this.form
        .transform((data) => {
          let transformedData = {
            present: data.present,
            certificate_number: data.certificate_number,
            submitted: data.submitted,
            equipment_types:
              data.equipment_1 || data.equipment_2 || data.equipment_3
                ? [data.equipment_1, data.equipment_2, data.equipment_3]
                : null,
          }

          if (!this.student.class.test_scores_enabled) {
            transformedData.passed = data.passed
          }

          return transformedData
        })
        .put(this.route('students.update', this.student.id))
    },
    restore() {
      if (confirm(this.$t('Are you sure you want to restore this student?'))) {
        this.$inertia.put(this.route('students.restore', this.student.id))
      }
    },
  },
}
</script>
