<template>
  <layout :title="$t('Classes')">
    <div class="mb-8">
      <breadcrumb :name="$t('Classes')" />
    </div>
    <div class="mb-6 flex items-center justify-between">
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
        <label class="block text-gray-800">{{ $t('Instructor Led Class:') }}</label>
        <select v-model="form.instructor_led_classroom" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="ilc in instructor_led_classrooms" :key="ilc.id" :value="ilc.id">{{ ilc.name }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Access:') }}</label>
        <select v-model="form.access" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="public">{{ $t('Public') }}</option>
          <option value="private">{{ $t('Private') }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('MOL Status:') }}</label>
        <select v-model="form.mol" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="submitted">{{ $t('Submitted') }}</option>
          <option value="partially">{{ $t('Partially Submitted') }}</option>
          <option value="not">{{ $t('Not Submitted') }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Locale:') }}</label>
        <select v-model="form.locale" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="(locale, key) in locales" :key="key" :value="locale">
            {{ key }}
          </option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Between:') }}</label>
        <date-input v-model="form.local_start_date" class="mt-1 w-full" />
        <date-input v-model="form.local_end_date" class="mt-1 w-full" />
        <label class="mt-4 block text-gray-800">{{ $t('Fullness:') }}</label>
        <select v-model="form.fullness" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="(label, key) in fullness_values" :key="key" :value="label">{{ label }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Class Type:') }}</label>
        <select v-model="form.class_type" class="form-select mt-1 w-full">
          <option value="parent">{{ $t('Parent Classes') }}</option>
          <option value="subclass">{{ $t('Subclasses') }}</option>
        </select>
        <div class="mt-4 flex justify-between">
          <label class="block text-gray-800">{{ $t('Location:') }}</label>
          <div class="flex items-center">
            <span class="mr-2 text-sm">{{ $t('With client sites') }}</span>
            <input v-model="is_client_site_checked" type="checkbox" />
          </div>
        </div>
        <select v-model="form.location" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="location in locations" :key="location.id" :value="location.id">
            {{ location.name }}
          </option>
        </select>
        <template v-if="form.location && roomsForSelectedLocation.length > 0">
          <div class="mt-4 flex justify-between">
            <label class="block text-gray-800">{{ $t('Room:') }}</label>
          </div>
          <select v-model="form.room" class="form-select mt-1 w-full">
            <option :value="null" />
            <option v-for="room in roomsForSelectedLocation" :key="room.id" :value="room.id">
              {{ room.name }}
            </option>
          </select>
        </template>
        <label class="mt-4 block text-gray-800">{{ $t('Instructor:') }}</label>
        <select v-model="form.instructor" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="instructor in instructors" :key="instructor.id" :value="instructor.id">
            {{ instructor.last_name }}, {{ instructor.first_name }}
          </option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Cancelled:') }}</label>
        <select v-model="form.cancelled" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="with">{{ $t('With Cancelled') }}</option>
          <option value="only">{{ $t('Only Cancelled') }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Deleted:') }}</label>
        <select v-model="form.deleted" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="with">{{ $t('With Deleted') }}</option>
          <option value="only">{{ $t('Only Deleted') }}</option>
        </select>
      </search-filter>
      <dropdown class="btn-blue w-auto" placement="bottom-end">
        <div class="flex cursor-pointer select-none items-center">
          <div class="hidden sm:block">
            <span>{{ $t('Actions') }}</span>
          </div>
          <icon class="-my-1 h-5 w-5 fill-white" name="chevron-down" />
        </div>
        <template #dropdown>
          <div class="mt-2 rounded border bg-white py-2 text-sm shadow-lg">
            <InertiaLink class="block px-4 py-2 hover:bg-gray-200 hover:text-gray-700" :href="route('classes.create')">
              {{ $t('Create Class') }}
            </InertiaLink>
            <InertiaLink
              v-if="can.massPost"
              class="block px-4 py-2 hover:bg-gray-200 hover:text-gray-700"
              :href="route('masspost')"
            >
              {{ $t('Post to Marketplace') }}
            </InertiaLink>
            <InertiaLink
              class="block px-4 py-2 hover:bg-gray-200 hover:text-gray-700"
              :href="route('classes.calendar')"
            >
              {{ $t('View Calendar') }}
            </InertiaLink>
          </div>
        </template>
      </dropdown>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow">
      <SimpleTable>
        <template #head>
          <TableHead>{{ $t('Name') }}</TableHead>
          <TableHead>{{ $t('Sessions') }}</TableHead>
          <TableHead>{{ $t('Locations') }}</TableHead>
          <TableHead class="text-right">{{ $t('Filled/Available') }}</TableHead>
          <TableHead>{{ $t('Instructors') }}</TableHead>
          <TableHead />
        </template>
        <TableRow v-if="classes.data.length === 0">
          <TableData colspan="6">{{ $t('No classes found.') }}</TableData>
        </TableRow>
        <template v-for="class_ in classes.data" :key="class_.id">
          <class-index-row :class_="class_" />
          <template v-for="subclass in class_.subclasses" :key="subclass.id">
            <class-index-row :class_="subclass" :show-subclass-highlighting="true" />
          </template>
        </template>
      </SimpleTable>
    </div>
    <pagination :links="classes.links" />
  </layout>
</template>

<script>
import ClassIndexRow from './ClassIndexRow.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import DateInput from '@/Shared/DateInput.vue'
import Dropdown from '@/Shared/Dropdown.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import _debounce from 'lodash/debounce'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'

export default {
  components: {
    ClassIndexRow,
    Breadcrumb,
    DateInput,
    Dropdown,
    Icon,
    Layout,
    Pagination,
    SearchFilter,
    SimpleTable,
    TableData,
    TableHead,
    TableRow,
  },
  props: {
    classes: Object,
    courses: Array,
    locations: Array,
    rooms: Array,
    instructors: Array,
    instructor_led_classrooms: Array,
    filters: Object,
    locales: Object,
    can: Object,
    fullness_values: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        instructor_led_classroom: this.filters.instructor_led_classroom,
        access: this.filters.access,
        mol: this.filters.mol,
        locale: this.filters.locale,
        local_start_date: this.filters.local_start_date,
        local_end_date: this.filters.local_end_date,
        location: this.filters.location,
        room: this.filters.room,
        instructor: this.filters.instructor,
        cancelled: this.filters.cancelled,
        deleted: this.filters.deleted,
        client_site: this.filters.client_site,
        class_type: this.filters.class_type ?? 'parent',
      },
      is_client_site_checked: false,
    }
  },
  computed: {
    roomsForSelectedLocation() {
      return this.rooms.filter((room) => room.location_id == this.form.location)
    },
  },
  watch: {
    is_client_site_checked() {
      this.form.client_site = this.is_client_site_checked ? 'with' : null
    },
    'form.location'(newVal) {
      if (!newVal) {
        this.form.room = null
      }
    },
    form: {
      handler: _debounce(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(this.route('classes'), Object.keys(query).length ? query : { remember: 'forget' }, {
          preserveState: true,
          replace: true,
          only: ['classes', 'locations'],
        })
      }, 300),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
      this.form.class_type = 'parent'
    },
  },
}
</script>
