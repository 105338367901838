<template>
  <layout :title="$t('Create Response Set')">
    <div class="w-full">
      <breadcrumb-admin :path="breadcrumbs" />
      <div class="max-w-lg overflow-hidden rounded bg-white shadow">
        <form @submit.prevent="submit">
          <div class="px-4 py-8 sm:px-6">
            <text-input v-model="form.name" label="Name" :placeholder="$t('Untitled Set')" />
          </div>
          <div class="px-4 py-2 text-sm sm:px-6">
            <p>{{ $t('Response') }}</p>
          </div>
          <div class="relative bg-white">
            <div class="inline-flex w-full items-center">
              <Draggable class="list-group w-full" handle=".handle" :list="form.choices" tag="ul">
                <li
                  v-for="(element, index) in form.choices"
                  :key="index"
                  class="flex items-center border-b border-t py-1"
                >
                  <div class="flex-1">
                    <div class="flex w-full items-center">
                      <Bars3Icon
                        aria-hidden="true"
                        class="handle z-20 mx-3 flex h-6 w-6 flex-none cursor-move items-center justify-center text-gray-400"
                      />
                      <div
                        class="flex w-full items-center justify-between"
                        @click.prevent="element.focus = !element.focus"
                      >
                        <div class="flex-1">
                          <front-text-input
                            v-model="element.name"
                            class="placeholder-shown:text-bold placeholder-shown:text-m m-0 mt-0 w-full border-0 bg-white ring-0 ring-inset placeholder-shown:border-0 placeholder-shown:ring-0 focus:outline-none focus:ring-2 focus:ring-qualify-red-600"
                            :error="form.errors[`choices.${index}.name`]"
                            :placeholder="$t('Response :num', { num: index + 1 })"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="h-full">
                    <button
                      class="stretch h-full w-8 cursor-pointer text-gray-500"
                      type="button"
                      @click.prevent="deleteOption(element)"
                    >
                      <TrashIcon aria-hidden="true" class="h-4 w-4" />
                    </button>
                  </div>
                </li>
              </Draggable>
            </div>
          </div>
          <div class="px-4 py-5 text-sm sm:px-6">
            <button class="inline-flex items-center" type="button  " @click.prevent="addOption">
              <PlusIcon aria-hidden="true" class="mr-1 h-3 w-3" />
              <p>{{ $t('Add Response') }}</p>
            </button>
          </div>
          <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
            <loading-button class="btn-blue" :loading="form.processing" type="submit">
              {{ $t('Save') }}
            </loading-button>
          </div>
        </form>
      </div>
    </div>
  </layout>
</template>

<script>
import BreadcrumbAdmin from '@/Shared/BreadcrumbAdmin.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import TextInput from '@/Shared/TextInput.vue'
import { Bars3Icon, PlusIcon, TrashIcon } from '@heroicons/vue/20/solid'
import { useForm } from '@inertiajs/vue3'
import { VueDraggableNext as Draggable } from 'vue-draggable-next'

export default {
  components: {
    Draggable,
    Bars3Icon,
    TrashIcon,
    PlusIcon,
    BreadcrumbAdmin,
    FrontTextInput,
    Layout,
    LoadingButton,
    TextInput,
  },
  props: {
    organization: Object,
  },
  data() {
    return {
      scoring: true,
      form: useForm({
        scope_level: 'organization',
        name: null,
        choices: [],
      }),
      breadcrumbs: [
        {
          name: this.$t('Organizations'),
          url: this.route('organizations.index'),
        },
        {
          name: this.organization.name,
          url: this.route('organizations.ehs', this.organization),
        },
        {
          name: this.$t('Create: Response Set'),
        },
      ],
    }
  },
  mounted() {
    this.addOption()
    this.addOption()
  },
  methods: {
    submit() {
      this.form.post(this.route('organizations.response-sets.store', this.organization))
    },
    addOption() {
      this.form.choices.push({
        text: null,
      })
    },
  },
}
</script>
