<template>
  <layout :title="$t('Transfer Enrolments')">
    <div class="mb-8">
      <breadcrumb
        :middle-name="owner.display_name"
        :middle-url="route('users.edit', owner)"
        :name="$t('Enrolment Transfer')"
        :previous-name="$t('Users')"
        :previous-url="route('users')"
      />
    </div>
    <div class="max-w-md overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <label class="form-label">{{ $t('Transfer To:') }}</label>
            <dynamic-search-input
              v-model="user"
              class="mb-2 w-full"
              :error="form.errors.user_id"
              :search-by="['name', 'email']"
              track-by="id"
              :url="route('users.search', { organization: owner.organization_id })"
            >
              <div v-if="user" class="flex items-center justify-between">
                <div class="truncate">{{ user.name }}</div>
                <div class="whitespace-nowrap text-xs text-gray-600">
                  {{ user.email }}
                </div>
              </div>
              <template #option="{ option, selected }">
                <div class="flex items-center justify-between">
                  <div>{{ option.name }}</div>
                  <div class="text-xs" :class="{ 'text-gray-600': !selected, 'text-white': selected }">
                    {{ option.email }}
                  </div>
                </div>
              </template>
            </dynamic-search-input>
          </div>
        </div>

        <div class="mb-8 w-full px-8">
          <label class="form-label">{{ $t('Select Course Enrolments:') }}</label>

          <notice v-if="form.errors.courses && form.errors.courses.length > 0" class="mr-0 pr-0" type="danger">
            {{ form.errors.courses }}
          </notice>

          <div
            v-for="course in courses"
            :key="course.id"
            class="mb-2 flex h-full flex-wrap rounded border border-gray-300 bg-white p-4 hover:cursor-pointer"
            :class="{ 'border-ts-blue-500': form.courses[course.id].selected }"
            @click.stop="toggleSelected(course.id)"
          >
            <div class="flex w-2/3 items-center justify-start text-gray-600">
              {{ course.name }}
            </div>
            <div class="flex w-1/3 items-center justify-end">
              <div v-if="form.courses[course.id].selected">
                <span
                  class="px-2 hover:cursor-pointer"
                  :class="{ 'text-gray-300 hover:cursor-default': form.courses[course.id].quantity === 1 }"
                  @click.stop="decrement(course.id)"
                >
                  -
                </span>
                <input
                  v-model="form.courses[course.id].quantity"
                  class="w-6 rounded border border-gray-300 text-center"
                  type="text"
                  @click.stop
                />
                <span
                  class="px-2 hover:cursor-pointer"
                  :class="{
                    'text-gray-300 hover:cursor-default': form.courses[course.id].quantity === course.available,
                  }"
                  @click.stop="increment(course.id)"
                >
                  +
                </span>
              </div>
            </div>
            <div
              v-if="form.errors[`courses.${course.id}.quantity`] && form.courses[course.id].selected"
              class="form-error"
            >
              {{ form.errors[`courses.${course.id}.quantity`] }}
            </div>
          </div>
        </div>

        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Transfer') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import DynamicSearchInput from '@/Shared/DynamicSearchInput.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import Notice from '@/Shared/Notice.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Breadcrumb,
    DynamicSearchInput,
    Layout,
    LoadingButton,
    Notice,
  },
  props: {
    owner: null,
    courses: Array,
  },
  data() {
    var course_list = {}
    var coursesFormList = {}

    this.courses.forEach((course) => {
      course_list[course.id] = {
        id: course.id,
        name: course.name,
        available: course.available,
      }
      coursesFormList[course.id] = {
        course_id: course.id,
        quantity: 0,
        selected: false,
      }
    })
    return {
      user: null,
      courseList: course_list,
      form: useForm({
        user_id: null,
        courses: coursesFormList,
      }),
    }
  },
  watch: {
    user: function (user) {
      this.form.user_id = user.id
    },
  },
  methods: {
    submit() {
      this.form
        .transform((data) => {
          var postCourseData = {}
          Object.keys(data.courses).forEach((key) => {
            if (data.courses[key].selected && data.courses[key].quantity > 0) {
              postCourseData[key] = data.courses[key]
            }
          })
          return {
            user_id: data.user_id,
            courses: postCourseData,
          }
        })
        .post(this.route('users.enrolment-transfers.step1.store', this.owner.id))
    },
    toggleSelected(courseId) {
      var formCourse = this.form.courses[courseId]

      formCourse.selected = !formCourse.selected
      formCourse.quantity = formCourse.selected ? 1 : 0
    },
    decrement(courseId) {
      var formCourse = this.form.courses[courseId]

      if (formCourse.quantity > 1) {
        formCourse.quantity--
      }
    },
    increment(courseId) {
      var formCourse = this.form.courses[courseId]
      var course = this.courseList[courseId]

      if (formCourse.quantity < course.available) {
        formCourse.quantity++
      }
    },
  },
}
</script>
