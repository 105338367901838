<template>
  <div class="mt-8 overflow-x-auto rounded bg-white shadow">
    <table class="front-table w-full">
      <thead>
        <tr>
          <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Invoice') }}</th>
          <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Billing Period') }}</th>
          <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Amount') }}</th>
          <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Subscription Plan') }}</th>
          <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold" />
        </tr>
      </thead>
      <tbody>
        <tr v-if="invoiceData.length === 0">
          <td class="border-t px-6 py-4" colspan="5">{{ $t('No invoice found.') }}</td>
        </tr>
        <tr v-for="invoice in invoiceData" :key="invoice.id">
          <td class="whitespace-nowrap border-t px-6 py-4">
            <div class="inline-flex items-center font-semibold text-ts-gray-text-bold">
              <span>{{ $t('Invoice - :number', { number: invoice.number ?? invoice.id }) }}</span>
              <span
                class="ml-2 inline-block rounded-md bg-[#467082]/10 px-3 py-1 text-center text-sm font-semibold uppercase text-[#467082]"
              >
                {{ invoice.status }}
              </span>
            </div>
          </td>
          <td class="whitespace-nowrap border-t px-6 py-4">
            <div class="flex font-semibold">
              <show-date :timestamp="invoice.period_start" />
              <span class="px-1">-</span>
              <show-date :timestamp="invoice.period_end" />
            </div>
          </td>
          <td class="whitespace-nowrap border-t px-6 py-4">
            <span class="capitalize">{{ invoice.amount_due }}</span>
          </td>
          <td class="whitespace-nowrap border-t px-6 py-4">
            <span class="font-semibold capitalize">{{ invoice.subscription_plan }}</span>
          </td>
          <td class="whitespace-nowrap border-t px-6 py-4">
            <a class="flex cursor-pointer items-center" :href="route('front.admin.invoice', invoice)">
              <span class="text-md cursor-pointer py-4 font-semibold">{{ $t('View') }}</span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import ShowDate from '@/Shared/ShowDate.vue'

export default {
  components: {
    ShowDate,
  },
  props: {
    invoiceData: Array,
  },
}
</script>
