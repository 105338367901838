<template>
  <front-modal class="rounded text-base" :show="show" width="auto" @close="close">
    <div class="max-w-lg overflow-hidden rounded bg-white p-6 shadow md:p-10">
      <div class="mb-4 text-center">
        <h1 class="mt-4 text-3xl font-bold">{{ $t('Create an Organization') }}</h1>
        <p class="mt-4 text-left text-sm leading-5">
          {{
            $t(
              'By creating an Organization you will have immediate free access to a suite of management tools for your organization, including:'
            )
          }}
        </p>
        <ul class="mt-4 list-disc pl-12 text-left text-sm leading-5">
          <li>{{ $t('Creating user accounts for individuals') }}</li>
          <li>{{ $t('Granting individuals management and administrative access') }}</li>
          <li>{{ $t('Organizing individuals into groups (eg. sites, locations, teams, etc.)') }}</li>
          <li>{{ $t('Purchasing and assigning training for others') }}</li>
          <li>{{ $t('Progress and qualification reporting') }}</li>
        </ul>
      </div>
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap py-8 md:px-12">
          <div class="w-full pb-8 pr-6 lg:w-full">
            <front-text-input
              v-model="form.name"
              :error="form.errors.name"
              :label="$t('Organization Name')"
              placeholder="ie. ABC Ltd"
            />
          </div>
        </div>
        <div class="flex items-center justify-center bg-white px-8 pb-8 pt-1">
          <front-outline-button class="mx-2" type="button" @click="close">{{ $t('Close') }}</front-outline-button>
          <front-loading-button :loading="form.processing" type="submit">
            {{ $t('Create Organization') }}
          </front-loading-button>
        </div>
      </form>
    </div>
  </front-modal>
</template>

<script>
import FrontLoadingButton from './FrontLoadingButton.vue'
import FrontModal from './FrontModal.vue'
import FrontOutlineButton from './FrontOutlineButton.vue'
import FrontTextInput from './FrontTextInput.vue'
import { query } from '@/Utils/Helpers'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    FrontLoadingButton,
    FrontModal,
    FrontOutlineButton,
    FrontTextInput,
  },
  props: {
    show: Boolean,
  },
  data() {
    return {
      form: useForm(
        {
          name: null,
        },
        'OrganizationCreate'
      ),
    }
  },
  methods: {
    query,
    close() {
      this.$emit('close')
    },
    submit() {
      this.form.post(this.route('front.organization.create'), {
        onSuccess: () => this.close(),
      })
    },
  },
}
</script>
